:root {
  --oliver-tile-size: 0%;
  }
/*Setting on dashboard checkout button is going down, some height issue */
#root {
  width: 100%;
  height: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*Setting facebook button text backbround-color and font */
.user_login_fb_on {
  background-color: #4267b2;
  border-color: #4267b2;
  position: relative;
  color: #fff;
  justify-content: center;
  display: contents;
  font-size: 18px;
}

/* Setting apple login button background-color black */
#appleid-signin {
  background-color: #050505 !important;
}

/* Setting breadcrum width with 100% in a line, so next element come in new line */
.mod-product {
  width: 100%;
}

/* Setting for breadcrum link design */
.mod-product > .category-row > button {
  padding: 10px;
  background-color: transparent;
  border: 0;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: var(--oliver-blue);
}

.hide {
  display: none !important;
}

.unhide {
  display: block !important;
}

.display-flex {
  display: flex !important;
}

/* Setting loading modal loader */
.popup {
  box-sizing: border-box;
  position: fixed;
  background-color: #fff;
  border-radius: 1.85vw;
  z-index: 9;
  overflow-x: hidden;
  overflow-y: scroll;
}

/* Setting loader model (which comes in inner screens when data loads) */
.loaderThemeBg {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  margin: auto;
  background-color: rgba(81, 80, 80, 0.51);
}

/* Setting loader model background-color */
.preloaderbackground {
  background-color: #fff;
  text-align: center;
  border-radius: 14px;
  padding: 20px 40px;
}

/* Setting loader model text font family */
.fn-poppins {
  font-family: Poppins;
}

/* Setting small loader width */
.w-100 {
  width: 100% !important;
}

/* Setting animattion for the logo image START*/
.ologo-1 {
  animation: animate-svg-fill-1 3s ease-in 0.2s both infinite;
}

@keyframes animate-svg-fill-2 {
  0%,
  80%,
  100% {
    transform: scale(0.98);
    fill: transparent;
  }

  10%,
  70% {
    transform: scale(1);
    fill: #47d2a5;
  }
}

.ologo-2 {
  animation: animate-svg-fill-2 3s ease-in 0.4s both infinite;
}

@keyframes animate-svg-fill-3 {
  0%,
  80%,
  100% {
    transform: scale(0.98);
    fill: transparent;
  }

  10%,
  70% {
    transform: scale(1);
    fill: #1db9ca;
  }
}

.ologo-3 {
  animation: animate-svg-fill-3 3s ease-in 0.6s both infinite;
}

@keyframes animate-svg-fill-4 {
  0%,
  80%,
  100% {
    transform: scale(0.98);
    fill: transparent;
  }

  10%,
  70% {
    transform: scale(1);
    fill: #2797e8;
  }
}

.ologo-4 {
  animation: animate-svg-fill-4 3s ease-in 0.8s both infinite;
}

@keyframes animate-svg-fill-5 {
  0%,
  80%,
  100% {
    transform: scale(0.98);
    fill: transparent;
  }

  10%,
  70% {
    transform: scale(1);
    fill: #a568bc;
  }
}

.ologo-5 {
  animation: animate-svg-fill-5 3s ease-in 0.9s both infinite;
}

@keyframes animate-svg-fill-6 {
  0%,
  80%,
  100% {
    transform: scale(0.98);
    fill: transparent;
  }

  10%,
  70% {
    transform: scale(1);
    fill: #ff525f;
  }
}

.ologo-6 {
  animation: animate-svg-fill-6 3s ease-in 1.1s both infinite;
}

@keyframes animate-svg-fill-7 {
  0%,
  80%,
  100% {
    transform: scale(0.98);
    fill: transparent;
  }

  10%,
  70% {
    transform: scale(1);
    fill: #ff8552;
  }
}

.ologo-7 {
  animation: animate-svg-fill-7 3s ease-in 1.2s both infinite;
}

@keyframes animate-svg-fill-8 {
  0% {
    transform: translate(35px, -35px);
    fill: transparent;
  }

  10%,
  40% {
    transform: translate(0px, 0px);
    fill: #ffd026;
  }

  50%,
  100% {
    transform: translate(-35px, 35px);
    fill: transparent;
  }
}

.ologo-8 {
  animation: animate-svg-fill-8 3s ease-in-out 1.3s both infinite;
}

.st0 {
  fill: #a1e663;
}

.st1 {
  fill: #47d2a5;
}

.st2 {
  fill: #1db9ca;
}

.st3 {
  fill: #2797e8;
}

.st4 {
  fill: #a568bc;
}

.st5 {
  fill: #ff525f;
}

.st6 {
  fill: #ff8552;
}

.st7 {
  fill: #ffd026;
}

/* Setting animattion for the logo image END*/

/* Setting user info wrapper above the tile section so tile can not be selected while user info is open */
.user-info-wrapper {
  z-index: 1;
}

/* Setting the component disable and not clickable */
.btn-disable {
  opacity: 0.5;
  user-select: none;
}

/* Setting to cross the text like old price after discount etc */
.strike-through {
  text-decoration: line-through;
}

/* Setting center the text/content */
.doCenter {
  text-align: center;
}

.main-row > .text-group {
  margin-right: auto;
}

.input-col1 {
  width: 0;
  flex: 1 1;
  margin-left: 14px;
  display: flex;
  flex-direction: column;
}

.subwindow-wrapper
  > .subwindow.create-customer
  > .subwindow-body
  .input-row
  > .input-col
  > label {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--oliver-base-blue);
  margin-bottom: 4px;
}

.input-col1 > input {
  height: 42px;
  background-color: #fff;
  border: 1px solid var(--oliver-border);
  box-sizing: border-box;
  padding: 0 12px;
  border-radius: 6px;
  outline: 0;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--oliver-base-blue);
}

.text-center {
  text-align: center;
}

/* .transactions-search {
	z-index: unset;
} */

.refund-complete-txt {
  display: flex;
  align-items: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  color: #fff;
  margin-bottom: 43px;
}

.manual-payment {
  width: 424px;
}

.manual-payment > .input-row {
  width: 100%;
  display: flex;
  margin-bottom: 12px;
}

.manual-payment > .input-row > .input-col {
  width: 0;
  flex: 1 1;
  margin-left: 14px;
  display: flex;
  flex-direction: column;
}

.manual-payment > .input-row > .input-col > label {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--oliver-base-blue);
  margin-bottom: 4px;
}

.manual-payment > .input-row > .input-col > input {
  height: 42px;
  background-color: #fff;
  border: 1px solid var(--oliver-border);
  box-sizing: border-box;
  padding: 0 12px;
  border-radius: 6px;
  outline: 0;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--oliver-base-blue);
}

.manual-payment > .charge-card {
  width: 424px;
  height: 48px;
  border: 0;
  border-radius: 6px;
  background-color: var(--oliver-blue);
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #fff;
  margin-bottom: 10px;
}

#bottomText {
  cursor: pointer;
}

.cv-list > .body > .no-results {
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 58px;
  /* display: none; */
  border: 0 !important;
}

.cv-list > .body > .no-results > p.style1 {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  color: var(--oliver-base-grey);
  margin-bottom: 10px;
}

.cv-list > .body > .no-results > p.style2 {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: var(--oliver-base-grey);
}

.partial-text-row {
  width: 348px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-top: 21px; */
  /* border-top: 1px solid var(--oliver-border);
    margin-bottom: 18px; */
}

.partial-text-row > p.style1 {
  font-family: Poppins;
  font-size: 14px;
  /* font-weight: 500;
    line-height: 24px; */
  text-align: left;
  color: var(--oliver-base-grey);
}

.partial-text-row > p.style2 {
  font-family: Poppins;
  font-size: 14px;
  text-align: right;
  color: var(--oliver-base-blue);
  font-weight: 500;
}

.floatAmountfont {
  font-size: 20px !important;
}

.notification-card {
  cursor: pointer;
}

.close-register-wrapper > header > .col > .divider {
  width: auto;
}

.custom-error-message {
  padding: 11px 28px;
  background-color: #ffe7e8;
  border-radius: 8px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: var(--error);
  margin: -10px 0 20px 0;
}

/* Setting change border color of the Balance Due box when partial payment is done */
.payment-type-disable:hover {
  border: 1px solid var(--oliver-border) !important;
  cursor: unset !important;
}

/* Setting change text color of the "Click to make a partial payment" when partial payment is done */
.payment-type-disable-txt {
  color: var(--oliver-base-grey) !important;
  cursor: unset !important;
}

/* Setting rotating this "RefreshGrey.svg" image mean while inventory data is coming */
.rotate {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.transactions-detailed
  > .scrollable
  > .order-details
  > .item
  > .col
  > .main-row {
  align-items: start;
}

.transactions-detailed
  > .scrollable
  > .order-details
  > .item
  > .col
  > .main-row
  > p:first-of-type {
  flex: 1;
}

/* Setting border radius for inputbox in advanced search in Mobile View  */
#advSearchInputWrapper {
  border-radius: 10px;
}

.subwindow-wrapper
  > .subwindow.add-tile
  > .subwindow-body
  > .dropdown-search
  > input.error
  + .error-message {
  background-color: unset !important;
}

/* Setting error message for category already exist. */
.subwindow-wrapper
  > .subwindow.add-tile
  > .subwindow-body
  > .dropdown-search
  > input.error.duplicate-category
  + .error-message::after {
  content: "*This category has already been added";
}

/* Setting error message for attribute already exist. */
.subwindow-wrapper
  > .subwindow.add-tile
  > .subwindow-body
  > .dropdown-search
  > input.error.duplicate-attribute
  + .error-message::after {
  content: "*This attribute has already been added";
}

@media (max-width: 700px) {
  /* Setting error message for category already exist. Mobile View */
  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > input.error.duplicate-category
    + .error-message::after {
    content: "*Category already exists";
  }

  /* Setting error message for atrribute already exist. Mobile View*/
  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > input.error.duplicate-attribute
    + .error-message::after {
    content: "*Attribute already exists";
  }
}

div.is-disabled {
  pointer-events: none;
  opacity: 0.5;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

div.is-disabled-footer {
  pointer-events: none;
  opacity: 0.5;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

/* Seeting disable product view content form right bottom corner START */
.is-disabled-footer > div > button#addProductNote {
  border-color: var(--oliver-base-grey) !important;
  color: var(--oliver-base-grey) !important;
}

.is-disabled-footer > div > button#addProductNote > img {
  filter: var(--oliver-base-grey) !important;
}

.is-disabled-footer > div > button#addProductDiscount {
  border-color: var(--oliver-base-grey) !important;
  color: var(--oliver-base-grey) !important;
  opacity: unset;
}

.is-disabled-footer > div > button#addProductToCart {
  background-color: var(--oliver-base-grey) !important;
  border-color: var(--oliver-base-grey) !important;
}

.is-disabled-footer > div > div > button {
  background-color: var(--oliver-bg-grey) !important;
  border-color: var(--oliver-bg-grey) !important;
}

.is-disabled-footer > div > .increment-input > button > img {
  filter: var(--oliver-base-grey) !important;
  -webkit-filter: hue-rotate(70deg) saturate(100) !important;
  filter: brightness(0.5) sepia(1) hue-rotate(140deg) saturate(6) !important;
}

/* Seeting disable product view content form right bottom corner END */
@media (orientation: landscape) {
  .navbar.open ~ .nav-cover {
    display: none !important;
  }
}

.products-container {
  user-select: none;
}

.quantity-disable {
  color: var(--oliver-disabled) !important;
}

.product-wrapper > .mod-product > .img-container.no-var > button {
  display: unset;
}

.cv-detailed > .cust-totals > .col > button:before {
  content: unset;
}

.modifier:hover {
  color: var(--oliver-base-blue-dark) !important;
  text-decoration: none !important;
}

.mod-product-disable > * {
  pointer-events: none;
  opacity: 0.5;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.transactions-detailed
  > .scrollable
  > .order-details
  > .item
  > .col
  > .main-row
  > del
  > p {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--oliver-base-grey);
}

.activityDetailHeaderBlue {
  background-color: #e9f5fd !important;
}

/* Setting cloud printer popup START */
.subwindow-body > #cloud-printer-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: var(--oliver-base-blue);
  padding-bottom: 30px;
  justify-content: center;
}

.subwindow-body > #cloud-printer-wrapper > input {
  position: fixed;
  left: -10000px;
  opacity: 0;
}

.subwindow-body > #cloud-printer-wrapper > .custom-toggle {
  width: 40px;
  height: 25px;
  border-radius: 25px;
  background-color: var(--oliver-spacer);
  margin-left: 20px;
  position: relative;
}

label.cloud-printer-wrapper > .custom-toggle::after {
  position: absolute;
  top: calc(50% - 11px);
  left: 1.5px;
  content: "";
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 15%), 0px 2px 1px rgb(0 0 0 / 6%);
}

label.cloud-printer-wrapper > input:checked ~ .custom-toggle {
  background-color: var(--oliver-blue) !important;
}

/* Setting cloud printer popup END */

/* Setting error message on partial payment popup */
.partial_amount_err {
  color: red !important;
  margin-top: -15px;
}

/* Setting Adjust height if there is no user apps(My Apps sections) */
.user-info {
  height: auto !important;
}

.transactions-list > .header > .sort-wrapper {
  /* transaction page filter box width increse to fit all filter types  */
  width: 165px !important;
}

.devider-width {
  width: auto !important;
}

.add_border {
  /* border: 2px solid #1583d3 !important; */
  /* border: 2px solid red !important; */
  border: 2px solid #1583d3 !important;
}

.subwindow-wrapper
  > .subwindow.advanced-search
  > .subwindow-body
  > .right-col
  > .body
  > .search-result
  > .row
  > button.search-view:focus,
.subwindow-wrapper
  > .subwindow.advanced-search
  > .subwindow-body
  > .right-col
  > .body
  > .search-result
  > .row
  > button.search-add-to-sale:focus,
.subwindow-wrapper
  > .subwindow.advanced-search
  > .subwindow-body
  > .right-col
  > .body
  > .search-result
  > .row
  > button.search-transactions:focus {
  outline-color: #1583d3 !important;
}

.subwindow-wrapper
  > .subwindow.advanced-search
  > .subwindow-body
  > .right-col
  > .body
  > .search-result
  > .row
  > button.search-add-to-sale:focus,
.subwindow-wrapper
  > .subwindow.advanced-search
  > .subwindow-body
  > .right-col
  > .body
  > .search-result
  > .row
  > button.search-transactions:focus {
  outline-color: #1583d3 !important;
}

#selectDiv .search-result:nth-child(2) button:nth-child(1) {
  border: 2px solid #1583d3;
}

.statusClose {
  background-color: #ff525f !important;
}

.textcapital {
  text-transform: capitalize;
}

.idle-register-wrapper > header > .col > p {
  word-wrap: break-word !important;
  white-space: normal !important;
}

.hide_wrapper {
  display: none !important;
}

.trim-long-email {
  overflow: hidden;
  text-overflow: ellipsis;
}

.input-container-pin {
  display: flex;
  gap: 10px;
  margin-bottom: 1.4167vw;
}

.input-container-pin input {
  border-radius: 0.666vw;
  width: 82px;
  height: 82px;
  border: none;
}

.jc-center {
  justify-content: center !important;
}

.jc-center > button {
  font-family: Poppins;
  font-size: 2vw;
  color: var(--oliver-base-blue);
}

@media (orientation: portrait) and (max-width: 375px) {
    .jc-center > button {
    font-family: Poppins;
    font-size: 9.6vw;
    line-height: 9.6vw;
    color: var(--oliver-base-blue);
  }
 }

.pinCountinue {
  width: 261px;
  height: 50px;
  border: 0;
  border-radius: 4px;
  background-color: var(--oliver-base-blue);
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
}

.date-selected {
  background-color: var(--oliver-blue) !important;
  color: white !important;
  font-weight: 500 !important;
}

.user-info-wrapper > .user-info > .body {
  padding-bottom: 0px !important;
}

.date-selector {
  top: 35% !important;
  /* -ms-transform: translate(-50%,-50%);
    -moz-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
     transform: translate(-50%,-50%); */
}

/* .product-wrapper {
	z-index: 3;
} */

.page-options.hidden {
  display: none;
}

/* .product-footer {
	z-index: 9 !important;
	border: 1px solid crimson;
} */

/* .product-wrapper {
	z-index: 3;
} */

.dropdown-input-wrapper {
  z-index: 1;
}
.refreshBtn{
  background-color: #e7f4fe;
    width: 100px;
    height: 30px;
    border-radius: 4px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-blue);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}


.variation_out_of_stock{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 16px;
}

.variation_out_of_stock p{
  color: var(--oliver-base-grey) !important;
  margin-bottom: 16px;
  opacity: .6;
}

.variation_out_of_stock button{
  width: 160px;
  height: 36px;
  border: 0.0833vw solid var(--oliver-blue);
  border-radius: 0.6667vw;
  font-size: 14px;
  line-height: 1.75vw;
  color: var(--oliver-blue);
  background: transparent;
}

.checkout_page > .page-options > button{
  display: flex !important;
  width: auto;
  align-items: center;
}
.checkout_page > .page-options > button > .img-container{
  width: unset !important;
}
.checkout_page > .page-options > button > p {
  display: unset !important;
  font-size: 12px;
}


#parkSaleButton img{
  width: 100% !important;
  height: 100% !important;
}
#addNoteButton img{
  height: 70% !important;
  width: 100% !important;
}


@media not all and (min-resolution: 0.001dpcm) {
  #cashPaymentAmount {
    height: 60%;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > .input-container
    > label {
    margin-bottom: 0;
  }
}

.color-disable {
  color: var(--oliver-disabled) !important;
}

 /* AMELIA */

 .homepage-wrapper.amelia {
	grid-template-columns: 6vw 1fr;
  }
  .homepage-wrapper.amelia > .header {
	height: auto;
  }
  
  .amelia-content-container {
	background: linear-gradient(180deg, #eaf5fe 0%, #dbeefc 100%);
	padding: 15px 18px;
  }
  
  .amelia-content-container > .amelia-content {
	width: 100%;
	height: 100%;
	border-radius: 12px;
	background: #fff;
	box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
  }
  
  .amelia-content-container > .amelia-content > .amelia-content-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 18px;
  }
  
  .amelia-content-container
	> .amelia-content
	> .amelia-content-header
	> .amelia-logo {
	display: flex;
	align-items: center;
	gap: 11px;
  }
  
  .amelia-content-container
	> .amelia-content
	> .amelia-content-header
	> .amelia-logo
	> img {
	width: 28px;
	height: 28px;
  }
  
  .amelia-content-container
	> .amelia-content
	> .amelia-content-header
	> .amelia-logo
	> p {
	font-size: 18px;
	font-weight: 600;
	color: var(--oliver-base-blue);
  }
  
  .amelia-content-container
	> .amelia-content
	> .amelia-content-header
	> .amelia-header-end {
	display: flex;
	align-items: center;
	gap: 12px;
  }
  
  .amelia-content-container
	> .amelia-content
	> .amelia-content-header
	> .amelia-header-end
	> p {
	font-size: 14px;
	color: var(--oliver-base-blue);
  }
  
  .amelia-content-container
	> .amelia-content
	> .amelia-content-header
	> .amelia-header-end
	> button {
	width: 36px;
	height: 36px;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
  }
  
  .amelia-content-container
	> .amelia-content
	> .amelia-content-header
	> .amelia-header-end
	> .amelia-close
	> img {
	width: 12px;
	height: 12px;
	object-fit: cover;
  }
  
  .amelia-content-container
	> .amelia-content
	> .amelia-content-header
	> .amelia-header-end
	> .menu-wrapper {
	width: 155px;
	height: 36px;
	position: relative;
	user-select: none;
	line-height: 0;
  }
  
  .amelia-content-container
	> .amelia-content
	> .amelia-content-header
	> .amelia-header-end
	> .menu-wrapper.open {
	z-index: 10;
	box-shadow: 0px 6px 10px rgba(94, 94, 94, 0.1);
  }
  
  .amelia-content-container
	> .amelia-content
	> .amelia-content-header
	> .amelia-header-end
	> .menu-wrapper
	> img {
	position: absolute;
	top: calc(50% - 6px);
	right: 9px;
	width: 13px;
	height: 13px;
	filter: brightness(0) saturate(100%) invert(53%) sepia(37%) saturate(1027%)
	  hue-rotate(165deg) brightness(91%) contrast(99%);
  }
  
  .amelia-content-container
	> .amelia-content
	> .amelia-content-header
	> .amelia-header-end
	> .menu-wrapper.open
	> img {
	transform: rotate(180deg) translateY(-2px);
  }
  
  .amelia-content-container
	> .amelia-content
	> .amelia-content-header
	> .amelia-header-end
	> .menu-wrapper
	> input {
	width: 100%;
	height: 100%;
	border: 1px solid var(--oliver-border);
	border-radius: 8px;
	box-sizing: border-box;
	padding: 0 25px 0 10px;
	font-family: Poppins;
	font-size: 14px;
	line-height: 20px;
	color: var(--oliver-blue);
  }
  
  .amelia-content-container
	> .amelia-content
	> .amelia-content-header
	> .amelia-header-end
	> .menu-wrapper.open
	> input {
	border-color: var(--oliver-blue);
	border-radius: 8px 8px 0 0;
  }
  
  .amelia-content-container
	> .amelia-content
	> .amelia-content-header
	> .amelia-header-end
	> .menu-wrapper
	> input:hover {
	border-color: var(--oliver-blue);
	cursor: pointer;
  }
  
  .amelia-content-container
	> .amelia-content
	> .amelia-content-header
	> .amelia-header-end
	> .menu-wrapper
	> .option-container {
	width: 100%;
	display: none;
  }
  
  .amelia-content-container
	> .amelia-content
	> .amelia-content-header
	> .amelia-header-end
	> .menu-wrapper.open
	> .option-container {
	display: block;
  }
  
  .amelia-content-container
	> .amelia-content
	> .amelia-content-header
	> .amelia-header-end
	> .menu-wrapper
	> .option-container
	> .option {
	width: 100%;
	height: 36px;
	background-color: #fff;
	border: 1px solid var(--oliver-border);
	display: flex;
	align-items: center;
	box-sizing: border-box;
	padding: 0 10px;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	text-align: left;
	color: var(--oliver-base-blue);
  }
  
  .amelia-content-container
	> .amelia-content
	> .amelia-content-header
	> .amelia-header-end
	> .menu-wrapper
	> .option-container
	> .option:hover {
	cursor: pointer;
	color: var(--oliver-blue);
  }
  
  .amelia-content-container
	> .amelia-content
	> .amelia-content-header
	> .amelia-header-end
	> .menu-wrapper
	> .option-container
	> .option:last-child {
	border-radius: 0 0 8px 8px;
  }
  
  .amelia-content-container > .amelia-content > .amelia-iframe-container {
	padding: 5px;
	background-color: var(--oliver-bg-grey);
	height: 90%;
  }
  
  .amelia-content-container
	> .amelia-content
	> .amelia-iframe-container
	> iframe {
	width: 100%;
	height: 100%;
  }
  .amelia-content-container > .amelia-content {
    overflow: hidden;
}
  @media (orientation: portrait) {
	.amelia-content-container {
	  height: 100%;
	}

	.amelia-content-container > .amelia-content {
	  height: 82%;
	}
  
	.amelia-content-container > .amelia-content > .amelia-content-header {
	  flex-direction: column;
	  gap: 16px;
	  align-items: start;
	}
  
	.amelia-content-container
	  > .amelia-content
	  > .amelia-content-header
	  > .amelia-header-end
	  > .menu-wrapper {
	  flex: 1;
	}
  
	.amelia-content-container
	  > .amelia-content
	  > .amelia-content-header
	  > .amelia-header-end {
	  width: 100%;
	}
	
  }


.custom-tile-size {
	width: var(--oliver-tile-size) !important;
  }
.homepage-wrapper > .products > .products-container:not(.margin-script) > button:nth-of-type(4n) {
    margin-right: 2%;
}
.mr-right
{
	margin-right: 0% !important;
}
  @media (orientation: landscape){
	.transactions-detailed>.scrollable>.order-details>.item>.img-container>img {
   
    height: 100%;
   
    object-fit: contain;
  }
    }
  @media (orientation: landscape){
.product-wrapper > .detailed-product > .row > .product-image-container > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
  }
  @media (orientation: landscape){
.product-wrapper > .recommended-upsells > .button-row > button > .img-container > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}}
.custom-tile-size {
  width: var(--oliver-tile-size) !important;
  }

.homepage-wrapper
  > .products
  > .products-container:not(.margin-script)
  > button:nth-of-type(4n) {
  margin-right: 2%;
}

@media (orientation: portrait) {
  .homepage-wrapper
    > .products
    > .products-container:not(.margin-script)
    > button {
    margin-right: 2%;
    margin-bottom: 2%;
      }
}

.mr-right {
  margin-right: 0% !important;
}

@media (orientation: landscape) {
  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .img-container
    > img {
    height: 100%;

    object-fit: contain;
  }
}
@media (orientation: landscape) {
  .product-wrapper > .detailed-product > .row > .product-image-container > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
@media (orientation: landscape) {
  .product-wrapper
    > .recommended-upsells
    > .button-row
    > button
    > .img-container
    > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
}
.btm-boder
{
	padding: 1.8333vw 0 0.5vw 0;
	border-bottom: 0.0833vw solid var(--oliver-border);
}
.hgt-wdt-unset
{
	height: unset !important;
	width: unset !important;
}
  


/* Search button home */
@media (orientation: landscape) {
  .homepage-wrapper > .header > .row > button#searchButton {
    width: auto;
    padding: 0 8px;
  }
}

/* Customer page edit button */
@media (orientation: landscape) {
  .cv-detailed > .quick-info > button {
    width: auto;
    padding: 0 8px;
  }
}

@media (orientation: portrait) {
  .cv-detailed > .quick-info > button {
    width: auto;
    padding: 0 8px;
  }
}

/* Custom discount toggle container */

@media (orientation: landscape) {
  .subwindow.custom-fee > .subwindow-body > .toggle-container,
  .subwindow.cart-discount > .subwindow-body > .toggle-container {
    justify-content: center;
    gap: 8px;
    width: max-content;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > .toggle-container
    > label
    > .custom-radio
    > p,
  .subwindow.cart-discount
    > .subwindow-body
    > .toggle-container
    > label
    > .custom-radio
    > p {
    padding: 0 8px;
  }

  .subwindow.custom-fee > .subwindow-body > .toggle-container > label,
  .subwindow.cart-discount > .subwindow-body > .toggle-container > label {
    flex: unset;
  }
}

@media (orientation: portrait) {
  .subwindow.custom-fee > .subwindow-body > .toggle-container,
  .subwindow.cart-discount > .subwindow-body > .toggle-container {
    justify-content: center;
    gap: 8px;
    width: max-content;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > .toggle-container
    > label
    > .custom-radio
    > p,
  .subwindow.cart-discount
    > .subwindow-body
    > .toggle-container
    > label
    > .custom-radio
    > p {
    padding: 0 8px;
  }

  .subwindow.custom-fee > .subwindow-body > .toggle-container > label,
  .subwindow.cart-discount > .subwindow-body > .toggle-container > label {
    flex: unset;
  }
}

/* Discount toggle container tax fee switch */

@media (orientation: landscape) {
  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > label.custom-toggle-wrapper {
    justify-content: space-between;
    align-items: flex-start;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > label.custom-toggle-wrapper
    > p {
    max-width: 65%;
  }
}

@media (orientation: portrait) {
  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > label.custom-toggle-wrapper {
    justify-content: space-between;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > label.custom-toggle-wrapper
    > p {
    max-width: 70%;
  }
}

/* Button return to home */
@media (orientation: landscape) {
  .homepage-wrapper > .products > .category-header > button {
    width: auto;
    padding: 0 8px;
  }
}

@media (orientation: portrait) {
  .homepage-wrapper > .products > .category-header > button {
    width: auto;
    padding: 0 8px;
  }
  }
/* Checkbox Button */

@media (orientation: landscape) and (max-width: 1200px){
  .product-wrapper > .mod-product > .radio-group > label > input[type="checkbox"]:checked ~ .custom-radio {
    border: 0.1667vw solid var(--oliver-blue);
  }
}

@media (orientation: landscape) {
  .product-wrapper > .mod-product > .radio-group > label > input[type="checkbox"]:checked ~ .custom-radio {
    border: 2px solid var(--oliver-blue);
  }
  }

@media (orientation: landscape) and (max-width: 1200px){
  .product-wrapper > .mod-product > .radio-group > label > input[type="checkbox"]:checked ~ .custom-radio {
    border: 0.1667vw solid var(--oliver-blue);
  }
}

@media (orientation: landscape) {
  /* and (max-width: 1200px) */
  .product-wrapper > .mod-product > .radio-group > label > input[type="checkbox"] {
    display: none;
  }
}

/* Tile-cart-btn */

.tile-cart-btn.hide{
  display: none;
}

.tile-cart-btn{
  position: absolute;
  top: 2%;
  right: 3%;
  width: 15%;
  height: 11%;
  padding: 4%;
  border: none;
  background-color: #F8F9FA;
  border-radius: 12%;
  opacity: 0.85;
  box-shadow: 0px 4px 8px 0px #24324933;
  cursor: pointer;
  }

.tile-cart-btn img{
width: 100%;
height: 100%;
object-fit: contain;
}

.tile-cart-btn:active{
  scale: 0.90;
}

/* Page options position change */

@media (orientation: landscape){
  .homepage-wrapper > .header > .row > button#searchButton {
    margin-right: 16px;
 }

.page-options-wrapper {
    position: unset;
    margin-right: 16px;
  }
}

/* Demo Header */

.demo-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 8vh;
  background-color: var(--oliver-blue);
  color: #fff;
}

.demo-header.hide{
  display: none;
}

.demo-header-text{
  font-size: 14px;
  margin-left: 16px;
}

.demo-started-btn{
  background-color: #FFD026;
  color: var(--oliver-base-blue);
  border: none;
  border-radius: 6px;
  padding: 8px 40px;
  font-weight: 600;
  margin-right: 16px;
  text-decoration: none;
}

.demo-started-btn:active{
  scale: 0.95;
}


.content-wrapper.set{
  height: 92vh;
  width: 100vw;
}

.content-wrapper.unset{
  height: 100vh;
  width: 100vw;
}

/* Unlimited text */

.unlimited-text{
  font-size: 36px;
  font-weight: 600;
  margin: 16px 0;
}

@media (orientation: landscape){
  .login-wrapper {
    width: 100%;
    height: 100%;
 }
}

/* Split by product text overflow */

@media (orientation: landscape){
  .subwindow-wrapper > .subwindow.split-by-product > .subwindow-body > .body > .product-row > .main-row {
      align-items: self-start;
  }

  .subwindow-wrapper > .subwindow.split-by-product > .subwindow-body > .body > .product-row > .main-row > .text-group {
    max-width: 60%;
  }
}

/* Backorder Button */
.product-wrapper > .product-footer > .row > button#addProductToCart.back-order-btn{
  background-color: #FF525F;
}

/* Quantity Red */

.product-wrapper > .detailed-product > .row > .col > .group > .text-row > p.quantity.quantity-red {
  color: #FF525F;
}

.logout-confirm> .idle-register-wrapper {
  width: 0;
  height: 0;
  /* display: flex; */
  background-color: #f8fafc;
  /* /* overflow: auto; */
}
.logout-confirm>.idle-register-wrapper > #logoutRegisterButton{
color: var(--oliver-base-blue) !important;
}

/* ********************************************** */
/* Button click issue fix */

button{
  position: relative;
  transform-origin:center ;
  overflow: visible;
  transition: all 0.3s ease;
}

button::before{
  content: '';
  position: absolute;
  /* inset: -5px; */
  margin: auto;
  background-color: transparent;
  cursor: pointer;
}

button:active:not([disabled]):not(.selected):not(.active):not(.no-transform):not(.remove-state) {
  -webkit-transform: scale(.95);
  transform: scale(.95);
}

/* User info wrapper setting scroll */

@media (orientation: landscape){
  .user-info-wrapper>.user-info>.footer>.button-row {
    overflow-x: auto;
    padding-bottom: 20px;
  }
}

@media (orientation: landscape){
.subwindow.product-unsupported > .subwindow-body > p.style1 {
    margin: 0 0 20px 0;  
}
}
@media (orientation: landscape){
.subwindow.product-unsupported > .subwindow-body > button {
    margin-bottom: 0 !important; 
}}
/* Unsuported prodcut on tile*/
@media (orientation: landscape){
.subwindow-wrapper>.product-unsupported.subwindow > .subwindow-body {
    justify-content: center;
    padding: 0 14px;
}}
@media (orientation: portrait){
  .login-wrapper {
     
      height: 100% !important;
     
  }}
  @media (orientation:portrait) {
    .demo-started-btn{
      font-size: 14px;
      padding: 6px 16px;
    }
  }

  @media (orientation: landscape){
  .subwindow-wrapper > .subwindow.end-session > .subwindow-body {
     padding: 0 12px;
  }
}
/* Refresh btn position in mobile view  */
@media (orientation: portrait){
  .product-wrapper > .header > .main.main-grid-view {
    display: grid;
    gap: 12px;
    grid-template-columns: 1fr auto;
    align-items: center;
  }

  .product-wrapper > .header > .main.main-grid-view > p.prod-name {
    grid-column: 1/-1;
  }

  .product-wrapper > .header > .main.main-grid-view > p.route {
    margin-bottom: 0;
  }

  .product-wrapper > .header > .main.main-grid-view > button.refreshBtn {
    grid-column: 2/-1;
    grid-row: 1/2;
  }
}
.txt-base-grey{
  color: var(--oliver-base-grey) !important;
  font-weight: 400 !important;
  font-size: 13px !important;
}
/* .txt-base-grey:hover{
  color: var(--oliver-base-grey) !important;
  font-weight: 400 !important;
} */
.txt-modifier-fields{
  width: 100%;
    box-sizing: border-box;
    padding: 8px 0 0 13px;
}
/* Add note btn */
#addNoteButton{
  gap: 4px;
}

/* payment-type button disabled */
.payment-types > .button-container > button.disabled{
  filter: grayscale(100%);
  opacity: 0.4;
}

.payment-types > .button-container > button{
  scale: unset;
}

/* Brower not supported popup */
@media (orientation: landscape){
  .error-404-wrapper > .error-404-container > p.style4 {
    font-size: 1.1vw;
    margin-bottom: 1.6vw;
    color: var(--oliver-base-blue);
    text-align: center;
}

.error-404-wrapper .error-404-container p.style1.style1-md{
  font-size: 2.3vw;
}

}


@media (orientation: portrait){
  .error-404-wrapper > .error-404-container > p.style4 {
    font-size: 2.4vw;
    margin-bottom: 1.6vw;
    color: var(--oliver-base-blue);
    text-align: center;
}}

@media (orientation: landscape){
  .error-404-wrapper > .error-404-container {
    height: auto;
}}

.error-404-wrapper .error-404-container .buttons-container{
  display: flex;
  align-items: center;
  gap: 1vw;
  margin-bottom: 1vw;
}

.error-404-wrapper .error-404-container .download-app-btn{
  display: grid;
  background-color: var(--oliver-base-blue);
  width: unset;
  height: unset;
  padding: 0.4vw 0.8vw;
  grid-template-columns: auto 1fr;
  column-gap: 0.6vw;
  align-items: center;
  text-align: left;
  border: 0.15vw solid var(--oliver-grey-text) ;
  border-radius: 0.5vw;
  color: #fff;
  text-decoration: none;
}

.error-404-wrapper .error-404-container .download-app-btn .span--img{
  grid-row: 1/3;
  width: 2vw;
  display: grid;
  place-items: center;
}

.error-404-wrapper .error-404-container .download-app-btn .span--img img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.error-404-wrapper .error-404-container .download-app-btn .span-text-sm{
  font-size: 0.8vw;
  text-transform:uppercase;
}


.error-404-wrapper .error-404-container .download-app-btn .span-text-lg{
  font-size: 1.2vw;
  font-weight: 600;
  text-transform: capitalize;
  /* translate: 0 -0.2vw; */
}

@media (orientation: portrait){
  .error-404-wrapper .error-404-container .download-app-btn .span-text-lg{
    font-size: 3.2vw;
  }

  .error-404-wrapper .error-404-container .download-app-btn .span-text-sm{
    font-size: 1.6vw;
  }

  .error-404-wrapper .error-404-container .download-app-btn .span--img{
    width: 4.8vw;
  }

  .error-404-wrapper .error-404-container .download-app-btn{
    padding: 1.4vw 2.4vw;
    column-gap: 0.8vw;
    border: 0.4vw solid var(--oliver-grey-text) ;
    border-radius: 1.4vw;
  }

  .error-404-wrapper .error-404-container .buttons-container{
    gap: 1.6vw;
  }
}

@media (orientation: portrait) and (max-width: 375px){
  .error-404-wrapper .error-404-container .download-app-btn .span-text-lg{
    font-size: 4.8vw;
  }

  .error-404-wrapper .error-404-container .download-app-btn .span-text-sm{
    font-size: 2.4vw;
  }

  .error-404-wrapper .error-404-container .download-app-btn .span--img{
    width: 6.8vw;
  }

  .error-404-wrapper .error-404-container .download-app-btn{
    padding: 1.6vw 3.2vw;
    column-gap: 1.2vw;
    border: 0.6vw solid var(--oliver-grey-text) ;
    border-radius: 2vw;
  }

  .error-404-wrapper > .error-404-container > p.style4 {
    font-size: 3.6vw;
    margin-bottom: 2.4vw;
}

.error-404-wrapper .error-404-container p.style1.style1-md{
  font-size: 7vw;
}
}

.subwindow.delete-customer-confirm > .subwindow-body > button#blueButton {
  background-color: var(--oliver-blue);
  border: 0;
  color: #fff;
  margin-bottom: 14px;
}


#cancelcloseregister .close_register_logout {
    width: 2vw;
    height: 2vw;
    margin-right: 0.5833vw;
}
#cancelcloseregister {white-space: nowrap; margin-left: 5px;}
@media (orientation: portrait){
#cancelcloseregister .close_register_logout {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}
}

.close_register_div{
overflow-y: auto;
max-height: 420px;
padding: 4px;
}

.search_title {
  white-space: normal !important;
}
.content-wrapper.unset {
  height: 100dvh;
  width: 100vw;
}

.no-access{
  scale: 2.0;
  margin-bottom: 0 !important;
}
.nomargine{
  margin: 0  !important;
}
.margine16{
  margin-top: 16px  !important;
}
.gap16{
  gap:16px;
}
.noaccess-navBar{
  z-index: 8;
}

.location-refresh-button{
  background-color: #2797e8;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 8px 40px; 
  margin-right: 16px;
  text-decoration: none;
  font-family: 'Poppins';
  cursor: pointer;
}
.location-refresh-button:hover{
  filter: brightness(95%);
}
.order-error{
  background-color: #f1f4f6;
  padding: 16px 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  color: #3D4C66;
  margin: 8px 0 ;
  max-width: 300px;
}
.order-error h3{
  margin: 0;

}
.order-error p{
 font-size: 14px; 
 max-width: 90%;
}
.order-error button{
 background-color: #2797e8;
 color: white;
 border: none;
 font-family: inherit;
 padding: 8px 16px;
 border-radius: 8px;
width: 100%;
}
.order-error-heading{
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}
.bug-card {
  padding: 12px;
  border-radius: 8px;
  background-color: var(--oliver-bg-grey);
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 14px;
  margin-bottom: 16px;
  color: var(--oliver-base-blue);
  margin-top: auto;
}

.bug-card-text {
  color: var(--oliver-base-blue);
  font-size: 12px;
  max-width: 100%;
}

.bug-card-heading {
  font-size: 16px;
  font-weight: 500;
  display: none;
}

.bug-card-link {
  color: var(--oliver-blue);
  text-decoration: none;
}

.bug-card-link:hover {
  text-decoration: underline;
}

.bug-card-group {
  gap: 8px;
  align-items: center;
  display: none;
}

.bug-card-group-img {
  display: grid;
  place-content: center;
}

.bug-card-footer {
  display: none;
  /* flex-direction: column; */
  gap: 8px;
  font-size: 8px;

}
.navbar.open  .bug-card-footer{
  display: flex;
}
.navbar.open  .bug-card-heading{
  display: flex;
}
.navbar.open .bug-card-group {
  display: flex;
}
.bug-card-group.img-container{
display: flex;
}
.bug-card-group button{
 background: transparent;
 border: none;
}
.bug-card-group.img-container img{
  cursor: pointer;
}
.bug-card-footer .bug-card-text { 
  font-size: 8px;
}
.bug-card-footer  .bug-card-group {
  gap: 4px;
}

.bug-card-key {
  background-color: #ffffff;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  font-weight: 600;
  display: grid;
  place-content: center;
  color: inherit;
  padding: 4px;
  font-family: Poppins;
  text-transform: uppercase;
  font-size: 10px;
}

.bug-card-key img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
}
#resync{
  position: fixed;
  top: 20px;
  right: 20px;
background: transparent;
color: white;
border: 1px solid white;
padding: 12px 32px;
border-radius: 8px;
}

@media (orientation: portrait ){
  .idle-register-wrapper > header {
    margin-top: 30px;
  }
  #resync {
    top:12px;
    right: 12px;
  }
  #logoutRegisterButton{
    top:12px;
    left:12px !important;
  }

}