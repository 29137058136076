/* Version 0.9.18 */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&family=Roboto+Mono:wght@300;400;500;600;700&display=swap");

/* #region */

.test-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: fixed;
  top: 25px;
}

.test-row > button {
  padding: 5px;
}

:root {
  /* Customize Color */
  --oliver-primary: #2797e8;
  /* Oliver Colors */
  --oliver-blue: #2797e8;
  --oliver-red: #ff525f;
  --oliver-violet: #a568bc;
  --oliver-cyan: #1db9ca;
  --oliver-teal: #47d2a5;
  --oliver-lime: #a1e663;
  --oliver-yellow: #ffd026;
  --oliver-coral: #ff8552;
  /* Oliver Greys */
  --oliver-base-grey: #758696;
  --oliver-border: #d4dee5;
  --oliver-new-grey: #b0bec9;
  --oliver-grey-text: #a6aeb6;
  --oliver-disabled: #c8cbcf;
  --oliver-spacer: #e7e9ec;
  --oliver-bg-grey: #f8f9fa;
  --oliver-ui-bubble-grey: #e1e1e1;
  /* Oliver Default Font Colors */
  --oliver-base-blue: #3d4c66;
  --oliver-base-blue-dark: #243249;
  /* Alert Colors */
  --approval: #00b998;
  --error: #d51a52;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #b0bec9;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-button {
  height: 4px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 6px;
  background-color: #b0bec9;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  padding: 0;
}

p {
  margin: 0;
  font-family: Poppins;
  font-weight: 400;
}

button {
  padding: 0;
  font-family: Poppins;
  font-weight: 400;
  text-align: center;
}

button:hover:not([disabled]):not(.selected):not(.active) {
  cursor: pointer;
}

button:active:not([disabled]):not(.selected):not(.active):not(
    .no-transform
  ):not(.remove-state) {
  transform: scale(0.9);
}

label,
input,
textarea,
a {
  font-family: Poppins;
  font-weight: 400;
}

input {
  box-sizing: border-box;
  outline: 0;
}

input::placeholder,
textarea::placeholder {
  color: var(--oliver-new-grey) !important;
}

input:hover:not(.error),
textarea:hover:not(.error) {
  border-color: var(--oliver-blue) !important;
}

input:focus,
textarea:focus {
  border-color: var(--oliver-blue);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

* {
  flex-shrink: 0;
}

/* Background Colors */

.background-primary {
  background-color: var(--oliver-primary) !important;
  border-color: var(--oliver-primary) !important;
}

.background-blue {
  background-color: var(--oliver-blue) !important;
  border-color: var(--oliver-blue) !important;
}

.background-red {
  background-color: var(--oliver-red) !important;
  border-color: var(--oliver-red) !important;
}

.background-yellow {
  background-color: var(--oliver-yellow) !important;
  border-color: var(--oliver-yellow) !important;
}

.background-coral {
  background-color: var(--oliver-coral) !important;
  border-color: var(--oliver-coral) !important;
}

.background-lime {
  background-color: var(--oliver-lime) !important;
  border-color: var(--oliver-lime) !important;
}

.background-teal {
  background-color: var(--oliver-teal) !important;
  border-color: var(--oliver-teal) !important;
}

.background-cyan {
  background-color: var(--oliver-cyan) !important;
  border-color: var(--oliver-cyan) !important;
}

.background-violet {
  background-color: var(--oliver-violet) !important;
  border-color: var(--oliver-violet) !important;
}

/* Auto Margins */

.auto-margin-top {
  margin-bottom: auto;
}

.auto-margin-bottom {
  margin-top: auto;
}

/* Image Fits */

/* .width-fit {
	width: 100% !important;
	height: auto !important;
}

.height-fit {
	width: auto !important;
	height: 100% !important;
} */

/* #endregion */

/* --------------------Portrait (Mobile Mode) ---------------------------------------------------------------------------------------------------------------------------*/
@media (orientation: portrait) {
  *::-webkit-scrollbar {
    display: none;
  }

  * {
    scrollbar-width: none;
  }

  /* Avatar */
  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--oliver-blue);
    border-radius: 50%;
    font-family: Poppins;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    color: #fff;
    overflow: hidden;
  }

  .avatar > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  :disabled > .avatar {
    background-color: var(--oliver-new-grey);
  }

  /* Inputs and Text Areas */

  input:focus,
  textarea:focus {
    border-width: 2px !important;
  }

  /* --------------------Loading Page-------------------- */

  .loading-page-wrapper {
    width: 100%;
    height: 100%;
    background-color: #eaf5ff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 25px 0;
  }

  .loading-page-wrapper > img {
    width: 101px;
    height: 28px;
    margin-bottom: 60px;
  }

  .loading-page-wrapper > svg {
    width: 85px;
    height: 85px;
    margin-bottom: 60px;
  }

  .loading-page-wrapper > p.style1 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
    color: var(--oliver-base-blue-dark);
    margin-bottom: 10px;
  }

  .loading-page-wrapper > p.style2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue-dark);
    margin-bottom: 60px;
  }

  .loading-page-wrapper > p.style3 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-grey);
  }

  .ologo-1 {
    animation: animate-svg-fill-1 3s ease-in 0.2s both infinite;
    fill: #a1e663;
  }

  .ologo-2 {
    animation: animate-svg-fill-2 3s ease-in 0.4s both infinite;
    fill: #47d2a5;
  }

  .ologo-3 {
    animation: animate-svg-fill-3 3s ease-in 0.6s both infinite;
    fill: #1db9ca;
  }

  .ologo-4 {
    animation: animate-svg-fill-4 3s ease-in 0.8s both infinite;
    fill: #2797e8;
  }

  .ologo-5 {
    animation: animate-svg-fill-5 3s ease-in 0.9s both infinite;
    fill: #a568bc;
  }

  .ologo-6 {
    animation: animate-svg-fill-6 3s ease-in 1.1s both infinite;
    fill: #ff525f;
  }

  .ologo-7 {
    animation: animate-svg-fill-7 3s ease-in 1.2s both infinite;
    fill: #ff8552;
  }

  .ologo-8 {
    animation: animate-svg-fill-8 3s ease-in-out 1.3s both infinite;
    fill: #ffd026;
  }

  @keyframes animate-svg-fill-8 {
    0% {
      transform: translate(25px, -25px);
      fill: transparent;
    }

    10%,
    40% {
      transform: translate(0px, 0px);
      fill: #ffd026;
    }

    50%,
    100% {
      transform: translate(-25px, 25px);
      fill: transparent;
    }
  }

  /* --------------------Login-------------------- */

  .login-wrapper {
    width: 100%;
    height: 0;
    flex-grow: 1;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .login-wrapper > img {
    width: 136px;
    height: 47.2px;
    margin: 50px 0 18.88px 0;
  }

  .login-wrapper > p {
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 43.98px;
  }

  .login-wrapper > .login-form {
    width: 320px;
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;
  }

  .login-wrapper > .login-form > label {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .login-wrapper > .login-form > input {
    width: 100%;
    height: 44px;
    outline: 0;
    border: 1px solid var(--oliver-border);
    border-radius: 2px;
    padding: 0 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 28px;
  }

  .login-wrapper > .login-form > input:focus {
    border-color: var(--oliver-blue);
  }

  .login-wrapper > .login-form > .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;
  }

  .login-wrapper > .login-form > .row > a {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .login-wrapper > .login-form > .row > .custom-checkbox-wrapper {
    display: flex;
    align-items: center;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    color: var(--oliver-base-grey);
  }

  .login-wrapper
    > .login-form
    > .row
    > .custom-checkbox-wrapper
    > input[type="checkbox"] {
    position: fixed;
    left: -10000px;
    opacity: 0;
  }

  .login-wrapper
    > .login-form
    > .row
    > .custom-checkbox-wrapper
    > .custom-checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid var(--oliver-border);
    border-radius: 4px;
    margin-right: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login-wrapper
    > .login-form
    > .row
    > .custom-checkbox-wrapper
    > .custom-checkbox
    > img {
    width: 75%;
    height: auto;
    display: none;
  }

  .login-wrapper
    > .login-form
    > .row
    > .custom-checkbox-wrapper
    > input[type="checkbox"]:checked
    ~ .custom-checkbox {
    border-color: var(--oliver-blue);
  }

  .login-wrapper
    > .login-form
    > .row
    > .custom-checkbox-wrapper
    > input[type="checkbox"]:checked
    ~ .custom-checkbox
    > img {
    display: block;
  }

  .login-wrapper > .login-form > button {
    width: 100%;
    height: 48px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 4px;
    font-family: Poppins;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  .login-wrapper > .or-row {
    width: 320px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;
  }

  .login-wrapper > .or-row > .divider {
    width: 119.76px;
    height: 1px;
    background-color: var(--oliver-border);
  }

  .login-wrapper > .or-row > p {
    font-size: 20px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-border);
  }

  .login-wrapper > button {
    position: relative;
    box-sizing: border-box;
    width: 320px;
    height: 48px;
    padding-left: 48px;
    border: 0;
    border-radius: 4px;
    background-color: var(--oliver-blue);
    margin-bottom: 16px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: #fff;
  }

  .login-wrapper > button:last-of-type {
    margin-bottom: 21.07px;
  }

  .login-wrapper > button > .img-container {
    position: absolute;
    left: 2px;
    top: 2px;
    width: 44px;
    height: 44px;
    display: flex;
    background-color: #fff;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
  }

  .login-wrapper > #googleButton {
    background-color: var(--oliver-blue);
  }

  .login-wrapper > #googleButton > .img-container > img {
    width: 45.19px;
    height: 38.61px;
  }

  .login-wrapper > #facebookButton {
    background-color: #4267b2;
  }

  .login-wrapper > #facebookButton > .img-container > img {
    width: 26.52px;
    height: 21.29px;
  }

  .login-wrapper > #appleButton {
    background-color: #050505;
  }

  .login-wrapper > #appleButton > .img-container > img {
    width: 40.58px;
    height: 27.42px;
  }

  .login-wrapper > .row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
  }

  .login-wrapper > .row > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-right: 5px;
  }

  .login-wrapper > .row > a {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  /* --------------------Choose Pages-------------------- */

  .choose-wrapper {
    width: 100%;
    height: 100%;
    background-color: var(--oliver-bg-grey);
    display: flex;
    flex-direction: column;
  }

  .choose-wrapper > .choose-header {
    width: 100%;
    height: 64px;
    background-color: #fff;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 20px;
  }

  .choose-wrapper > .choose-header > button {
    width: 109px;
    height: 40px;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid var(--oliver-blue);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-blue);
    /* margin-right: auto; */
  }

  .choose-wrapper > .choose-header > button > img {
    width: 7.1px;
    height: 14.2px;
    margin-right: 14.9px;
  }

  .choose-wrapper > .choose-header > p {
    width: 0;
    flex-grow: 1;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--oliver-base-grey);
    text-align: right;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .choose-wrapper > .choose-body-default {
    width: 100%;
    height: 0;
    flex-grow: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: scroll;
    box-sizing: border-box;
    padding-top: 14px;
  }

  .choose-wrapper > .choose-body-default button:disabled::after {
    content: "";
    background-image: url("assets/images/svg/LockIcon.svg");
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
    right: 17px;
  }

  .choose-wrapper > .choose-body-default button:disabled > img {
    display: none;
  }

  .choose-wrapper > .choose-body-default button:disabled > .img-container {
    background-color: var(--oliver-border) !important;
  }

  .choose-wrapper > .choose-body-default button:disabled > .col > p.style1 {
    color: #b0bec9 !important;
  }

  .choose-wrapper > .choose-body-default button:disabled > .col > p.style2 {
    color: var(--oliver-border) !important;
  }

  .choose-wrapper > .choose-body-default > p {
    font-size: 22px;
    font-weight: 400;
    line-height: 32px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 12px;
  }

  .choose-wrapper > .choose-body-default > .divider {
    width: 60px;
    height: 1px;
    background-color: var(--oliver-border);
    margin-bottom: 26px;
  }

  .choose-wrapper > .choose-body-default > .button-container {
    width: 335px;
    display: flex;
    flex-direction: column;
  }

  .choose-wrapper > .choose-body-default button {
    width: 100%;
    height: 92px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 23.9px 0 17px;
    margin-bottom: 22px;
    background-color: #fff;
    border: 1px solid var(--oliver-border);
    border-radius: 8px;
  }

  .choose-wrapper > .choose-body-default button > .img-container {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-right: 18px;
  }

  .choose-wrapper > .choose-body-default button > .img-container > img {
    width: 100%;
    height: 100%;
  }

  .choose-wrapper
    > .choose-body-default
    button
    > .img-container
    > img.oliver-icon {
    width: 28px;
    height: 28px;
    transform: translateX(1px);
  }

  .choose-wrapper
    > .choose-body-default
    button
    > .img-container
    > img.www-icon {
    width: 36px;
    height: 36px;
  }

  .choose-wrapper
    > .choose-body-default
    button
    > .img-container
    > img.store-icon {
    width: 28.71px;
    height: 26.66px;
  }

  .choose-wrapper
    > .choose-body-default
    button
    > .img-container
    > img.register-icon {
    width: 27px;
    height: 27px;
  }

  .choose-wrapper
    > .choose-body-default
    button
    > .img-container
    > img.kiosk-icon {
    width: 37px;
    height: 37px;
  }

  .choose-wrapper > .choose-body-default button > .col {
    width: 0;
    flex-grow: 1;
  }

  .choose-wrapper > .choose-body-default button > .col > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    text-align: left;
    color: var(--oliver-base-blue);
    max-width: 90%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .choose-wrapper > .choose-body-default button > .col > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
    max-width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .choose-wrapper
    > .choose-body-default
    .button-group
    > button:not(.assigned)
    > .col
    > p.style2 {
    color: var(--oliver-blue);
  }

  .choose-wrapper > .choose-body-default button > img {
    width: 10.1px;
    height: 20.2px;
  }

  .choose-wrapper > .choose-body-default button > .fake-button {
    display: none;
  }

  .choose-wrapper > .choose-body-default button#addNew {
    justify-content: center;
    height: 40.4px;
    padding: 0;
    border: 0;
    background-color: transparent;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: var(--oliver-blue);
  }

  .choose-wrapper > .choose-body-default button#addNew > img.mobile {
    width: 24px;
    height: 24px;
    margin-right: 11px;
  }

  .choose-wrapper > .choose-body-default button#addNew > img.landscape {
    display: none;
  }

  .choose-wrapper > .choose-body-default > .button-container > p {
    display: none;
  }

  .choose-wrapper > .choose-body-default > .button-container > .divider {
    display: none;
  }

  .choose-wrapper > .choose-body-default > .button-container > .button-group {
    display: flex;
    flex-direction: column;
  }

  /* --------------------Subwindows-------------------- */

  .subwindow-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(61, 76, 102, 0.5);
    z-index: 5;
  }

  .subwindow-wrapper.hidden {
    display: none;
  }

  .subwindow-wrapper > .subwindow {
    position: absolute;
    background-color: #fff;
    border: 0;
    border-radius: 8px;
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.25));
    display: none !important;
    flex-direction: column;
  }

  .subwindow-wrapper > .subwindow.current {
    display: flex !important;
  }

  .subwindow-wrapper > .subwindow > .subwindow-header {
    position: relative;
    width: 100%;
    height: 54px;
    box-sizing: border-box;
    border-bottom: 1px solid var(--oliver-border);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .subwindow-wrapper > .subwindow > .subwindow-header > p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper > .subwindow > .subwindow-header > button.close-subwindow {
    position: absolute;
    top: 11px;
    right: 11px;
    width: 32px;
    height: 32px;
    background-color: #f1f4f6;
    border: 0;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .subwindow-wrapper
    > .subwindow
    > .subwindow-header
    > button.close-subwindow
    > img {
    width: 12px;
    height: 12px;
  }

  .subwindow-wrapper > .subwindow > .subwindow-body {
    width: 100%;
    height: 0;
    flex-grow: 1;
    overflow-y: scroll;
    background-color: #f8fafc;
    border-radius: 0 0 8px 8px;
    padding: 20px 0;
    box-sizing: border-box;
  }

  /* Update Plugin */

  .subwindow.update-plugin {
    width: min(344px, 95%);
    height: min(284px, 95%);
  }

  .subwindow.update-plugin > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
  }

  .subwindow.update-plugin > .subwindow-body > img {
    width: 40px;
    height: 40px;
    margin: auto 0 10px 0;
  }

  .subwindow.update-plugin > .subwindow-body > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 10px;
  }

  .subwindow.update-plugin > .subwindow-body > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 31px;
  }

  .subwindow.update-plugin > .subwindow-body > button {
    width: 306px;
    height: 50px;
    border: 0;
    border-radius: 6px;
    background-color: var(--oliver-blue);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Cart No Item */

  .subwindow.cart-no-item {
    width: min(344px, 95%);
    height: min(215px, 95%);
  }

  .subwindow.cart-no-item > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
  }

  .subwindow.cart-no-item > .subwindow-body > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin: auto 0 20px 0;
  }

  .subwindow.cart-no-item > .subwindow-body > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 43px;
  }

  .subwindow.cart-no-item > .subwindow-body > button {
    width: 306px;
    height: 50px;
    border: 0;
    border-radius: 6px;
    background-color: var(--oliver-blue);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Discount Error */

  .subwindow.discount-error {
    width: min(344px, 95%);
    height: min(325px, 95%);
  }

  .subwindow.discount-error > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
  }

  .subwindow.discount-error > .subwindow-body > p > br.landscape {
    display: none;
  }

  .subwindow.discount-error > .subwindow-body > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 20px;
    margin: auto 0 20px 0;
  }

  .subwindow.discount-error > .subwindow-body > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 20px;
  }

  .subwindow.discount-error > .subwindow-body > p:last-of-type {
    margin-bottom: 50px;
  }

  .subwindow.discount-error > .subwindow-body > button {
    width: 306px;
    height: 50px;
    border-radius: 6px;
    border: 0;
    background-color: var(--oliver-blue);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Product Unsupported */

  .subwindow.product-unsupported {
    width: min(344px, 95%);
    height: min(372px, 95%);
  }

  .subwindow.product-unsupported > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
  }

  .subwindow.product-unsupported > .subwindow-body > p.style1 {
    margin: auto 0 27px 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .subwindow.product-unsupported > .subwindow-body > img {
    width: 50px;
    height: 52px;
    margin-bottom: 27px;
  }

  .subwindow.product-unsupported > .subwindow-body > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 33px;
  }

  .subwindow.product-unsupported > .subwindow-body > p.style2 > br.landscape {
    display: none;
  }

  .subwindow.product-unsupported > .subwindow-body > button {
    width: 306px;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Refund Show Payments */

  .subwindow.refund-show-payments {
    width: min(344px, 95%);
  }

  .subwindow.refund-show-payments > .subwindow-body {
    height: fit-content;
    max-height: calc(95vh - 54px);
    padding: 20px 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    overflow: auto;
  }

  .subwindow.refund-show-payments > .subwindow-body > .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 11px;
  }

  .subwindow.refund-show-payments > .subwindow-body > .row:nth-child(1) {
    margin-top: 0;
  }

  .subwindow.refund-show-payments > .subwindow-body > .row > p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow.refund-show-payments > .subwindow-body > .row > p > b {
    font-weight: 500;
  }

  .subwindow.refund-show-payments > .subwindow-body > button {
    width: 100%;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin-top: 33px;
  }

  /* Knowledge Base */

  .subwindow.knowledge-base {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .subwindow.knowledge-base > .subwindow-header {
    height: 68px;
    justify-content: flex-start;
    padding: 0 50px 0 16px;
  }

  .subwindow.knowledge-base > .subwindow-header > .icon-container {
    width: 45px;
    height: 45px;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 14px;
  }

  .subwindow.knowledge-base > .subwindow-header > .icon-container > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .subwindow.knowledge-base > .subwindow-header > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-right: auto;
  }

  .subwindow.knowledge-base > .subwindow-header > button#kBRefresh {
    width: 34px;
    height: 34px;
    border-radius: 8px;
    border: 0;
    background-color: #d1ecff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .subwindow.knowledge-base > .subwindow-header > button#kBRefresh > img {
    width: 20px;
    height: 20px;
    filter: brightness(0) saturate(100%) invert(27%) sepia(15%) saturate(1200%)
      hue-rotate(179deg) brightness(91%) contrast(84%);
  }

  .subwindow.knowledge-base > .subwindow-header > button#kBRefresh > p {
    display: none;
  }

  .subwindow.knowledge-base > .subwindow-header > button.close-subwindow {
    top: calc(50% - 16px);
    width: 34px;
    height: 34px;
    border-radius: 8px;
    background-color: #d1ecff;
  }

  .subwindow.knowledge-base > .subwindow-body {
    padding: 0;
  }

  .subwindow.knowledge-base > .subwindow-body > iframe {
    width: 100%;
    height: 100%;
  }

  /* No Permissions*/

  .subwindow.no-permissions {
    width: min(344px, 95%);
    height: min(372px, 95%);
  }

  .subwindow.no-permissions > .subwindow-body {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow.no-permissions > .subwindow-body > img {
    width: 50px;
    height: 50px;
    padding-top: 25px;
    margin: auto 0 28px 0;
  }

  .subwindow.no-permissions > .subwindow-body > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 28px;
  }

  .subwindow.no-permissions > .subwindow-body > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 59px;
  }

  .subwindow.no-permissions > .subwindow-body > p.style2 > br.landscape {
    display: none;
  }

  .subwindow.no-permissions > .subwindow-body > button {
    width: 306px;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin-bottom: auto;
  }

  /* No Account */

  .subwindow.no-account {
    width: 100%;
    height: 100%;
  }

  .subwindow.no-account .landscape-only {
    display: none !important;
  }

  .subwindow.no-account > .subwindow-body {
    padding: 40px 0 33px 0;
    background-color: #fff;
  }

  .subwindow.no-account > .subwindow-body > img {
    display: none;
  }

  .subwindow.no-account > .subwindow-body > .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow.no-account > .subwindow-body > .content > img.mobile-only {
    width: 136px;
    height: 47px;
    margin-bottom: 19px;
  }

  .subwindow.no-account > .subwindow-body > .content > p.mobile-only {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 20px;
  }

  .subwindow.no-account > .subwindow-body > .content > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin: auto 0 50px 0;
  }

  .subwindow.no-account > .subwindow-body > .content > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 50px;
  }

  .subwindow.no-account > .subwindow-body > .content > button#createAccount {
    width: 246px;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin-bottom: auto;
  }

  .subwindow.no-account > .subwindow-body > .content > button#loginTryAgain {
    width: 173px;
    height: 50px;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid var(--oliver-blue);
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-blue);
    margin: 35px 0;
  }

  .subwindow.no-account > .subwindow-body > .content > .row {
    display: flex;
    align-items: center;
  }

  .subwindow.no-account > .subwindow-body > .content > .row > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .subwindow.no-account > .subwindow-body > .content > .row > a {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-blue);
    margin-left: 4px;
  }

  /* Take Over Register */

  .subwindow-wrapper > .subwindow.takeover-register {
    width: min(344px, 95%);
    height: min(336px, 95%);
  }

  .subwindow-wrapper > .subwindow.takeover-register > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper > .subwindow.takeover-register > .subwindow-body > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 39px;
  }

  .subwindow-wrapper > .subwindow.takeover-register > .subwindow-body > p > b {
    font-weight: 600px;
  }

  .subwindow-wrapper > .subwindow.takeover-register > .subwindow-body > button {
    width: 133px;
    height: 40px;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }

  .subwindow-wrapper
    > .subwindow.takeover-register
    > .subwindow-body
    > button#takeoverRegister {
    background-color: var(--oliver-red);
    color: #fff;
    border: 0;
    margin-bottom: 14px;
  }

  .subwindow-wrapper
    > .subwindow.takeover-register
    > .subwindow-body
    > button#cancelTakeover {
    background-color: #fff;
    color: var(--oliver-blue);
    border: 1px solid var(--oliver-blue);
  }

  /* Register Taken */

  .subwindow-wrapper > .subwindow.register-taken {
    width: min(344px, 95%);
    height: min(298px, 95%);
  }

  .subwindow-wrapper > .subwindow.register-taken > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .subwindow-wrapper > .subwindow.register-taken > .subwindow-body > img {
    width: 50px;
    height: 52px;
    margin-bottom: 30px;
  }

  .subwindow-wrapper > .subwindow.register-taken > .subwindow-body > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  /* Link Launcher */

  .subwindow-wrapper > .subwindow.link-launcher-subwindow {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .subwindow-wrapper > .subwindow.link-launcher-subwindow > .subwindow-header {
    justify-content: flex-start;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-header
    > img {
    margin: 0 14px 0 16px;
  }

  .subwindow-wrapper > .subwindow.link-launcher-subwindow > .subwindow-body {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-radius: 0;
    padding: 0;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header {
    width: 100%;
    height: 74px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid var(--oliver-border);
    padding: 0 16px;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > .link-wrapper {
    display: flex;
    width: 0;
    flex-grow: 1;
    align-items: center;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > .link-wrapper
    > img {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 6px;
    margin-right: 16px;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > .link-wrapper
    > .text-col {
    width: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > .link-wrapper
    > .text-col
    > p {
    max-width: 95%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > .link-wrapper
    > .text-col
    > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > .link-wrapper
    > .text-col
    > p.style2 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > button {
    width: 128px;
    height: 32px;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid var(--oliver-base-grey);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    text-align: center;
    color: var(--oliver-base-grey);
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > button
    > img {
    width: 13px;
    height: 13px;
    margin-right: 6px;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > iframe {
    width: 100%;
    height: 0;
    flex-grow: 1;
  }

  /* App Launcher Subwindow */

  .subwindow-wrapper > .subwindow.app-launcher-subwindow {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .subwindow-wrapper > .subwindow.app-launcher-subwindow > .subwindow-header {
    height: fit-content;
    justify-content: flex-start;
    padding: 16px 48px 16px 16px;
  }

  .subwindow-wrapper
    > .subwindow.app-launcher-subwindow
    > .subwindow-header
    > .app-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .subwindow-wrapper
    > .subwindow.app-launcher-subwindow
    > .subwindow-header
    > .app-wrapper
    > img {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 10px;
    margin-right: 19px;
  }

  .subwindow-wrapper
    > .subwindow.app-launcher-subwindow
    > .subwindow-header
    > .app-wrapper
    > .text-col {
    width: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .subwindow-wrapper
    > .subwindow.app-launcher-subwindow
    > .subwindow-header
    > .app-wrapper
    > .text-col
    > p {
    max-width: 95%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .subwindow-wrapper
    > .subwindow.app-launcher-subwindow
    > .subwindow-header
    > .app-wrapper
    > .text-col
    > p.style1 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.app-launcher-subwindow
    > .subwindow-header
    > .app-wrapper
    > .text-col
    > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .subwindow-wrapper > .subwindow.app-launcher-subwindow > .subwindow-body {
    display: flex;
    flex-direction: column;
    border: 0;
    padding: 0;
  }

  .subwindow-wrapper
    > .subwindow.app-launcher-subwindow
    > .subwindow-body
    > iframe {
    width: 100%;
    height: 0;
    flex-grow: 1;
  }

  /* Create Customer */

  .subwindow.create-customer {
    width: 100%;
    height: 100%;
    overflow: hidden;
    flex-direction: column;
    border-radius: 0;
  }

  .subwindow.create-customer > .subwindow-body {
    border: 0;
    box-sizing: border-box;
    width: 100%;
    height: 0;
    flex-grow: 1;
    padding: 20px;
    display: block;
    overflow-y: scroll;
    border-radius: 0;
  }

  .subwindow.create-customer > .subwindow-body > div:not(.button-row) {
    width: 100%;
    border-top: 1px solid var(--oliver-border);
    padding-top: 15px;
    margin-top: 16px;
  }

  .subwindow.create-customer > .subwindow-body > div:first-of-type {
    border-top: 0 !important;
    padding-top: 0 !important;
    margin-top: 0 !important;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div:first-of-type
    > .input-row:last-child
    > .input-col:last-child {
    margin-top: 0 !important;
  }

  .subwindow.create-customer > .subwindow-body > div > p {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 23px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow.create-customer > .subwindow-body > div > .input-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
  }

  .subwindow.create-customer > .subwindow-body > div > .input-row > .input-col {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    position: relative;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col:nth-child(1) {
    margin-top: 0 !important;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > label {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    padding-bottom: 4px;
    z-index: 2;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > label:hover {
    cursor: pointer;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input {
    height: 42px;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    padding: 0 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input:hover {
    border-color: var(--oliver-blue);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input:focus {
    border-width: 2px;
    border-color: var(--oliver-blue);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input.error {
    border-color: var(--error);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input.error
    ~ .error-wrapper {
    position: absolute;
    top: 0;
    right: 0;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input.error
    ~ .error-wrapper::after {
    content: "*Error";
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    color: var(--error);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input.error.invalid-input
    ~ .error-wrapper::after {
    content: "*Invalid Input";
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input.error.required
    ~ .error-wrapper::after {
    content: "*Required";
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper {
    width: 100%;
    height: 42px;
    position: relative;
    background-color: #fff;
    line-height: 0;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open {
    z-index: 3;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > input {
    width: 100%;
    height: 100%;
    z-index: 2;
    position: relative;
    background-color: transparent;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > input:focus {
    border-width: 1px;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > input:hover {
    cursor: pointer;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open
    > input {
    border-color: var(--oliver-blue);
    border-radius: 6px 6px 0 0;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > img {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 12px;
    top: calc(50% - 10px);
    z-index: 1;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open
    > img {
    transform: rotate(180deg) translateY(-2px);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container {
    display: none;
    box-sizing: border-box;
    width: 100%;
    background-color: #fff;
    max-height: 400%;
    border: 1px solid var(--oliver-blue);
    border-top: 0;
    border-radius: 0 0 6px 6px;
    overflow: auto;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open
    > .option-container {
    display: block;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option {
    box-sizing: border-box;
    width: 100%;
    height: 42px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    border-radius: 0 0 6px 6px;
    border-top: 1px solid var(--oliver-blue);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option:nth-child(1) {
    border-top: 0 !important;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option
    > p {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option:hover {
    cursor: pointer;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option:hover
    > p {
    color: var(--oliver-blue);
  }

  .subwindow.create-customer > .subwindow-body > div > .title-row {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .subwindow.create-customer > .subwindow-body > div > .title-row > p {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 23px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-right: auto;
  }

  .subwindow.create-customer > .subwindow-body > div > .title-row > label {
    display: flex;
    align-items: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .title-row
    > label:hover {
    cursor: pointer;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > input {
    position: fixed;
    left: -10000px;
    opacity: 0;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > .custom-checkbox {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 4px;
    margin-right: 12px;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > .custom-checkbox
    > img {
    width: 10px;
    height: 10px;
    display: none;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > input:checked
    ~ .custom-checkbox {
    border-color: var(--oliver-blue);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > input:checked
    ~ .custom-checkbox
    > img {
    display: block;
  }

  .subwindow.create-customer > .subwindow-body > .button-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }

  .subwindow.create-customer > .subwindow-body > .button-row > button {
    width: 100%;
    height: 44px;
    margin-top: 16px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  .subwindow.create-customer
    > .subwindow-body
    > .button-row
    > button:first-of-type {
    margin-top: 0 !important;
  }

  .subwindow.create-customer > .subwindow-body > div > .input-row.hidden {
    display: none;
  }

  /* Edit Customer */

  .subwindow.edit-customer {
    width: 100%;
    height: 100%;
    overflow: hidden;
    flex-direction: column;
    border-radius: 0;
  }

  .subwindow.edit-customer > .subwindow-body {
    border: 0;
    box-sizing: border-box;
    width: 100%;
    height: 0;
    flex-grow: 1;
    padding: 20px;
    display: block;
    overflow-y: scroll;
    border-radius: 0;
  }

  .subwindow.edit-customer > .subwindow-body > div:not(.button-row) {
    width: 100%;
    border-top: 1px solid var(--oliver-border);
    padding-top: 15px;
    margin-top: 16px;
  }

  .subwindow.edit-customer > .subwindow-body > div:first-of-type {
    border-top: 0 !important;
    padding-top: 0 !important;
    margin-top: 0 !important;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div:first-of-type
    > .input-row:last-child
    > .input-col:last-child {
    margin-top: 0 !important;
  }

  .subwindow.edit-customer > .subwindow-body > div > p {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 23px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow.edit-customer > .subwindow-body > div > .input-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
  }

  .subwindow.edit-customer > .subwindow-body > div > .input-row > .input-col {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    position: relative;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col:nth-child(1) {
    margin-top: 0 !important;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > label {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    padding-bottom: 4px;
    z-index: 2;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > label:hover {
    cursor: pointer;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input {
    height: 42px;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    padding: 0 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input:hover {
    border-color: var(--oliver-blue);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input:focus {
    border-width: 2px;
    border-color: var(--oliver-blue);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input.error {
    border-color: var(--error);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input.error
    ~ .error-wrapper {
    position: absolute;
    top: 0;
    right: 0;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input.error
    ~ .error-wrapper::after {
    content: "*Error";
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    color: var(--error);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input.error.invalid-input
    ~ .error-wrapper::after {
    content: "*Invalid Input";
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input.error.required
    ~ .error-wrapper::after {
    content: "*Required";
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper {
    width: 100%;
    height: 42px;
    position: relative;
    background-color: #fff;
    line-height: 0;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open {
    z-index: 3;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > input {
    width: 100%;
    height: 100%;
    z-index: 2;
    position: relative;
    background-color: transparent;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > input:focus {
    border-width: 1px;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > input:hover {
    cursor: pointer;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open
    > input {
    border-color: var(--oliver-blue);
    border-radius: 6px 6px 0 0;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > img {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 12px;
    top: calc(50% - 10px);
    z-index: 1;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open
    > img {
    transform: rotate(180deg) translateY(-2px);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container {
    display: none;
    box-sizing: border-box;
    width: 100%;
    background-color: #fff;
    max-height: 400%;
    border: 1px solid var(--oliver-blue);
    border-top: 0;
    border-radius: 0 0 6px 6px;
    overflow: auto;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open
    > .option-container {
    display: block;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option {
    box-sizing: border-box;
    width: 100%;
    height: 42px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    border-radius: 0 0 6px 6px;
    border-top: 1px solid var(--oliver-blue);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option:nth-child(1) {
    border-top: 0 !important;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option
    > p {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option:hover {
    cursor: pointer;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option:hover
    > p {
    color: var(--oliver-blue);
  }

  .subwindow.edit-customer > .subwindow-body > div > .title-row {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .subwindow.edit-customer > .subwindow-body > div > .title-row > p {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 23px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-right: auto;
  }

  .subwindow.edit-customer > .subwindow-body > div > .title-row > label {
    display: flex;
    align-items: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow.edit-customer > .subwindow-body > div > .title-row > label:hover {
    cursor: pointer;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > input {
    position: fixed;
    left: -10000px;
    opacity: 0;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > .custom-checkbox {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 4px;
    margin-right: 12px;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > .custom-checkbox
    > img {
    width: 10px;
    height: 10px;
    display: none;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > input:checked
    ~ .custom-checkbox {
    border-color: var(--oliver-blue);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > input:checked
    ~ .custom-checkbox
    > img {
    display: block;
  }

  .subwindow.edit-customer > .subwindow-body > .button-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }

  .subwindow.edit-customer > .subwindow-body > .button-row > button {
    width: 100%;
    height: 44px;
    margin-top: 16px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > .button-row
    > button:first-of-type {
    margin-top: 0 !important;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > .button-row
    > button#editCustomerDelete {
    background-color: #fff;
    color: var(--error);
    border: 1px solid var(--error);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > .button-row
    > button#editCustomerDelete
    > img {
    width: 20px;
    height: 21px;
    margin-right: 10px;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > .button-row
    > button#editCustomerDelete.disabled {
    border-color: var(--oliver-new-grey);
    color: var(--oliver-new-grey);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > .button-row
    > button#editCustomerDelete.disabled
    > img {
    filter: brightness(0) saturate(100%) invert(78%) sepia(14%) saturate(234%)
      hue-rotate(164deg) brightness(95%) contrast(92%);
  }

  .subwindow.edit-customer > .subwindow-body > div > .input-row.hidden {
    display: none;
  }

  /* Edit Order Status */

  .subwindow.edit-order-status {
    width: min(344px, 95%);
    height: min(564px, 95%);
  }

  .subwindow.edit-order-status > .subwindow-body > div.order-status-options{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 15px;
  }

  .subwindow.edit-order-status > .subwindow-body > div.order-status-options > button{
    width: 285px;
    height: 45px;
    box-sizing: border-box;
    border: 1px solid var(--oliver-blue);
    border-radius: 6px;
    background-color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .subwindow.edit-order-status > .subwindow-body > div.order-status-options > button.button-update-order-status{
    width: 285px;
    height: 44px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin-top: 13px;
    color: #fff;
    }

  /* Delete Customer Confirm */

  .subwindow.delete-customer-confirm {
    width: min(344px, 95%);
    height: min(292px, 95%);
  }

  .subwindow.delete-customer-confirm > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow.delete-customer-confirm > .subwindow-body > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 34px;
  }

  .subwindow.delete-customer-confirm > .subwindow-body > button {
    width: 133px;
    height: 40px;
    border-radius: 6px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  .subwindow.delete-customer-confirm > .subwindow-body > button#deleteCustomer {
    background-color: var(--oliver-red);
    border: 0;
    color: #fff;
    margin-bottom: 14px;
  }

  .subwindow.delete-customer-confirm
    > .subwindow-body
    > button#cancelDeleteCustomer {
    border: 1px solid var(--oliver-blue);
    background-color: #fff;
    color: var(--oliver-blue);
  }

  /* Custom Fee-Cart Discount */

  .subwindow.custom-fee {
    width: min(384px, 95%);
    height: min(535px, 95%);
  }

  .subwindow.cart-discount {
    width: min(384px, 95%);
    height: min(740px, 95%);
  }

  .subwindow.custom-fee > .subwindow-body,
  .subwindow.cart-discount > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow.cart-discount > .subwindow-body > .auto-margin-top {
    margin: 0 !important;
  }

  .subwindow.custom-fee > .subwindow-body > #cartDiscountDiv {
    display: none;
  }

  .subwindow.cart-discount > .subwindow-body > #customFeeDiv {
    display: none;
  }

  .subwindow.custom-fee > .subwindow-body > p,
  .subwindow.cart-discount > .subwindow-body > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-grey);
    margin-bottom: 9px;
  }

  .subwindow.custom-fee > .subwindow-body > .toggle-container,
  .subwindow.cart-discount > .subwindow-body > .toggle-container {
    width: 285px;
    height: 50px;
    background-color: #fff;
    border: 1px solid var(--oliver-border);
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 6px;
    box-sizing: border-box;
    margin-bottom: 24px;
  }

  .subwindow.custom-fee > .subwindow-body > .toggle-container > label,
  .subwindow.cart-discount > .subwindow-body > .toggle-container > label {
    flex: 1;
    height: 100%;
    border: 0;
  }

  .subwindow.custom-fee > .subwindow-body > .toggle-container > label:hover,
  .subwindow.cart-discount > .subwindow-body > .toggle-container > label:hover {
    cursor: pointer;
  }

  .subwindow.custom-fee > .subwindow-body > .toggle-container > label > input,
  .subwindow.cart-discount
    > .subwindow-body
    > .toggle-container
    > label
    > input {
    position: fixed;
    left: -10000px;
    opacity: 0;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > .toggle-container
    > label
    > .custom-radio,
  .subwindow.cart-discount
    > .subwindow-body
    > .toggle-container
    > label
    > .custom-radio {
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-grey);
  }

  .subwindow.custom-fee
    > .subwindow-body
    > .toggle-container
    > label
    > input:checked
    + .custom-radio,
  .subwindow.cart-discount
    > .subwindow-body
    > .toggle-container
    > label
    > input:checked
    + .custom-radio {
    background-color: var(--oliver-blue);
    color: #fff;
  }

  .subwindow.custom-fee > .subwindow-body > #customFeeDiv {
    width: 302px;
    display: flex;
    flex-direction: column;
  }

  .subwindow.custom-fee > .subwindow-body > #customFeeDiv > label {
    width: 100%;
    padding-bottom: 8px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .subwindow.custom-fee > .subwindow-body > #customFeeDiv > label:hover {
    cursor: pointer;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > input#customFeeLabel {
    width: 100%;
    height: 44px;
    margin-bottom: 14px;
    box-sizing: border-box;
    padding: 0 12px;
    border-radius: 6px;
    border: 1px solid var(--oliver-border);
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > input#customFeeLabel:hover {
    border-color: var(--oliver-blue);
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > input#customFeeLabel:focus {
    border-color: var(--oliver-blue);
    border-width: 2px;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > input#customFeeAmount {
    width: 100%;
    height: 83px;
    border-radius: 6px;
    border: 1px solid var(--oliver-border);
    padding: 0 12px;
    box-sizing: border-box;
    font-family: Poppins;
    font-size: 40px;
    font-weight: 400;
    line-height: 56px;
    text-align: center;
    color: var(--oliver-blue);
    margin-bottom: 8px;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > input#customFeeAmount:hover {
    border-color: var(--oliver-blue);
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > input#customFeeAmount:focus {
    border-color: var(--oliver-blue);
    border-width: 2px;
  }

  .subwindow.custom-fee > .subwindow-body > #customFeeDiv > p {
    width: 100%;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-grey);
    margin-bottom: 20px;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > .custom-toggle-wrapper {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > .custom-toggle-wrapper
    > input {
    position: fixed;
    left: -10000px;
    opacity: 0;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > .custom-toggle-wrapper
    > .custom-toggle {
    width: 40px;
    height: 25px;
    border-radius: 25px;
    background-color: var(--oliver-spacer);
    margin-left: 20px;
    position: relative;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > .custom-toggle-wrapper
    > .custom-toggle::after {
    content: "";
    position: absolute;
    left: 2px;
    top: calc(50% - 11px);
    width: 22px;
    height: 22px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15), 0px 2px 1px rgba(0, 0, 0, 0.06);
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > .custom-toggle-wrapper
    > input:checked
    ~ .custom-toggle {
    background-color: var(--oliver-blue);
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > .custom-toggle-wrapper
    > input:checked
    ~ .custom-toggle::after {
    left: auto;
    right: 2px;
  }

  .subwindow.custom-fee > .subwindow-body > #customFeeDiv > button {
    width: 100%;
    height: 50px;
    border-radius: 6px;
    border: 0;
    background-color: var(--oliver-blue);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  .subwindow.cart-discount > .subwindow-body > #cartDiscountDiv > .main {
    width: 302px;
    padding: 22px 0 30px 0;
    border-top: 1px solid var(--oliver-border);
    border-bottom: 1px solid var(--oliver-border);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > label {
    width: 100%;
    padding-bottom: 9px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > label:hover {
    cursor: pointer;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > label:hover
    + input {
    border-color: var(--oliver-blue);
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > input {
    width: 100%;
    height: 83px;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    padding: 0 12px;
    box-sizing: border-box;
    font-family: Poppins;
    font-size: 40px;
    font-weight: 400;
    line-height: 56px;
    text-align: center;
    color: var(--oliver-blue);
    margin-bottom: 23px;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > input:hover {
    border-color: var(--oliver-blue);
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > input:focus {
    border-color: var(--oliver-blue);
    border-width: 2px;
  }

  .subwindow.cart-discount > .subwindow-body > #cartDiscountDiv > .main > p {
    width: 100%;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-grey);
    margin-bottom: 12px;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > .button-row {
    width: 100%;
    display: flex;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > .button-row
    > button {
    flex: 1;
    height: 44px;
    border: 0;
    border-radius: 6px;
    background-color: var(--oliver-blue);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin-left: 12px;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > .button-row
    > button:first-of-type {
    margin-left: 0;
  }

  .subwindow.cart-discount > .subwindow-body > #cartDiscountDiv > .list {
    padding-top: 25px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow.cart-discount > .subwindow-body > #cartDiscountDiv > .list > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-grey);
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .list
    > button {
    width: 285px;
    height: 44px;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    margin-top: 15px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .list
    > button
    > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
    max-width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .list
    > button#clearCartDiscountDiv {
    background-color: transparent;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .list
    > button#clearCartDiscountDiv
    > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-grey);
  }

  /* Add Tile */

  .subwindow-wrapper > .subwindow.add-tile {
    width: min(384px, 95%);
    height: min(425px, 95%);
  }

  .subwindow-wrapper > .subwindow.add-tile > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper > .subwindow.add-tile > .subwindow-body > label {
    width: 306px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-bottom: 9px;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search {
    width: 306px;
    height: 44px;
    margin-bottom: 28px;
    z-index: 2;
    position: relative;
    line-height: 0;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > img.search {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 10px;
    top: 10px;
    filter: brightness(0) saturate(100%) invert(81%) sepia(4%) saturate(573%)
      hue-rotate(169deg) brightness(87%) contrast(85%);
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > button#cancelDropdownSearch {
    display: none;
    position: absolute;
    width: 18px;
    height: 18px;
    right: 15px;
    top: 14.5px;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 0;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > button#cancelDropdownSearch
    > img {
    width: 100%;
    height: 100%;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search.open
    > img.search {
    filter: none;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search.open
    > button#cancelDropdownSearch {
    display: flex;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > input {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    padding: 0 38px 0 40px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search.open
    > input {
    border-radius: 6px 6px 0 0;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > input.error {
    border-color: var(--error);
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .error-message {
    position: absolute;
    right: 0;
    top: -8px;
    transform: translateY(-100%);
    display: none;
    line-height: 0;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .error-message::after {
    content: "*Error";
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--error);
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > input.error
    + .error-message {
    display: block;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > input.error.duplicate
    + .error-message::after {
    content: "*Product already exists";
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .option-container {
    display: none;
    width: 100%;
    /* height: fit-content; */
    max-height: 300%;
    border-radius: 0 0 6px 6px;
    overflow-y: scroll;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search.open
    > .option-container {
    display: block;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .option-container:hover {
    cursor: pointer;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .option-container
    > .dropdown-option {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 12px;
    background-color: #fff;
    border: 1px solid var(--oliver-border);
    border-top: 0;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .option-container
    > .dropdown-option
    > p {
    max-width: 100%;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .option-container
    > .dropdown-option:hover {
    background-color: var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .option-container
    > .dropdown-option:hover
    > p {
    color: #fff;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .dropdown-option:last-child {
    border-radius: 0 0 6px 6px;
  }

  .subwindow-wrapper > .subwindow.add-tile > .subwindow-body > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-bottom: 11px;
  }

  .subwindow-wrapper > .subwindow.add-tile > .subwindow-body > .radio-group {
    width: 235.86px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 11.04px;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .radio-group
    > label {
    margin-right: 17.26px;
    margin: 0 17.26px 14.96px 0;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .radio-group
    > label:hover {
    cursor: pointer;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .radio-group
    > label:nth-child(4n) {
    margin-right: 0;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .radio-group
    > label
    > input[type="radio"] {
    position: fixed;
    left: -10000px;
    opacity: 0;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .radio-group
    > label
    > .custom-radio-button {
    box-sizing: border-box;
    width: 46px;
    height: 46px;
    border-radius: 7px;
    background-color: #fff;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .radio-group
    > label
    > input[type="radio"]:checked
    ~ .custom-radio-button {
    border: 2px solid #000 !important;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .radio-group
    > label
    > input[type="radio"]:disabled
    ~ .custom-radio-button {
    opacity: 0.25;
  }

  .subwindow-wrapper > .subwindow.add-tile > .subwindow-body > button {
    width: 306px;
    height: 50px;
    border: 0;
    border-radius: 8px;
    background-color: var(--oliver-blue);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Add Order Note */

  .subwindow-wrapper > .subwindow.add-order-note {
    width: min(384px, 95%);
    height: min(390px, 95%);
  }

  .subwindow-wrapper > .subwindow.add-order-note > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
  }

  .subwindow-wrapper > .subwindow.add-order-note > .subwindow-body > label {
    width: 306px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-bottom: 12px;
  }

  .subwindow-wrapper > .subwindow.add-order-note > .subwindow-body > textarea {
    box-sizing: border-box;
    width: 306px;
    height: 180px;
    outline: 0;
    resize: none;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    padding: 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 19px;
  }

  .subwindow-wrapper
    > .subwindow.add-order-note
    > .subwindow-body
    > textarea:focus {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.add-order-note > .subwindow-body > button {
    width: 306px;
    height: 50px;
    border: 0;
    border-radius: 6px;
    background-color: var(--oliver-blue);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Product not Found */

  .subwindow-wrapper > .subwindow.product-not-found {
    width: min(372px, 95%);
    height: min(372px, 95%);
  }

  .subwindow-wrapper > .subwindow.product-not-found > .subwindow-body {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper
    > .subwindow.product-not-found
    > .subwindow-body
    > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 20px;
    margin: 34px 0 20px 0;
  }

  .subwindow-wrapper > .subwindow.product-not-found > .subwindow-body > img {
    width: 81.17px;
    height: 51.5px;
    margin-bottom: 34.5px;
    transform: translateX(10px);
  }

  .subwindow-wrapper
    > .subwindow.product-not-found
    > .subwindow-body
    > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 51px;
  }

  .subwindow-wrapper > .subwindow.product-not-found > .subwindow-body > button {
    width: 306px;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Upgrade to Unlock */

  .subwindow-wrapper > .subwindow.upgrade-to-unlock {
    width: min(372px, 95%);
    height: min(372px, 95%);
  }

  .subwindow-wrapper > .subwindow.upgrade-to-unlock > .subwindow-body {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper > .subwindow.upgrade-to-unlock > .subwindow-body > img {
    width: 50px;
    height: 50px;
    margin: 26px 0 28px 0;
  }

  .subwindow-wrapper
    > .subwindow.upgrade-to-unlock
    > .subwindow-body
    > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 28px;
  }

  .subwindow-wrapper
    > .subwindow.upgrade-to-unlock
    > .subwindow-body
    > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 41px;
  }

  .subwindow-wrapper > .subwindow.upgrade-to-unlock > .subwindow-body > button {
    width: 306px;
    height: 50px;
    border: 0;
    border-radius: 6px;
    background-color: var(--oliver-blue);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Advanced Search */

  .subwindow-wrapper > .subwindow.advanced-search {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .subwindow-wrapper > .subwindow.advanced-search > .subwindow-header {
    position: relative;
    width: 100%;
    height: 90px;
    background-color: #f1f4f6;
    z-index: 2;
    align-items: center;
    justify-content: center;
    border-radius: 8px 8px 0 0;
  }

  .subwindow-wrapper > .subwindow.advanced-search > .subwindow-header > p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 21px;
    border-radius: 0;
  }

  .subwindow-wrapper > .subwindow.advanced-search > .subwindow-header > button {
    width: 32px;
    height: 32px;
    top: 11px;
    right: 11px;
    background-color: #fff;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper {
    position: absolute;
    width: 310px;
    height: 55px;
    top: 64px;
    left: calc(50% - 155px);
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.2);
    line-height: 0;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > #advSearchInputIcon {
    width: 30px;
    height: 29px;
    position: absolute;
    top: 13px;
    left: 11px;
    filter: brightness(0) saturate(100%) invert(72%) sepia(7%) saturate(349%)
      hue-rotate(169deg) brightness(95%) contrast(92%);
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > #advSearchInputCancel {
    opacity: 0;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 11px;
    top: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 0;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > #advSearchInputCancel:hover {
    cursor: default;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > #advSearchInputCancel
    > img {
    width: 17.5px;
    height: 17.5px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > input {
    width: 100%;
    height: 100%;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    padding: 0 52px 0 52px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > input:focus {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > input:focus
    ~ #advSearchInputIcon {
    filter: none;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > input:focus
    ~ #advSearchInputCancel {
    opacity: 1;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > input:focus
    ~ #advSearchInputCancel:hover {
    cursor: pointer;
  }

  .subwindow-wrapper > .subwindow.advanced-search > .subwindow-body {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col {
    width: 100%;
    box-sizing: border-box;
    padding-top: 51px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > p {
    display: none;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group {
    width: 310px;
    height: 40px;
    display: flex;
    flex-direction: column;
    margin-bottom: 19px;
    z-index: 2;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > .dropdown-input {
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    border: 1px solid var(--oliver-border);
    box-sizing: border-box;
    padding: 0 12px;
    border-radius: 8px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > .dropdown-input.open {
    border-radius: 8px 8px 0 0;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > .dropdown-input
    > p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-right: auto;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > .dropdown-input
    > p
    > b {
    font-weight: 600;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > label {
    box-sizing: border-box;
    display: none;
    width: 100%;
    height: 100%;
    align-items: center;
    border: 1px solid var(--oliver-border);
    border-top: 0;
    background-color: #fff;
    z-index: 2;
    padding: 0 12px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > .dropdown-input.open
    ~ label {
    display: flex;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > label:hover {
    cursor: pointer;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > label:last-of-type {
    border-radius: 0 0 8px 8px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > label
    > input[type="radio"] {
    position: fixed;
    left: -10000px;
    opacity: 0;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > label
    > .custom-radio {
    display: none;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > label
    > p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .recent-searches {
    display: none;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .start-searching {
    display: none;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -54px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .start-searching:only-child {
    display: flex;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .start-searching
    > img {
    width: 43px;
    height: 42px;
    margin-bottom: 30px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .start-searching
    > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 20px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .start-searching
    > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .header {
    width: 310px;
    border-bottom: 1px solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .header
    > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 5.69px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .header
    > p
    > b {
    font-weight: 600;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body {
    width: 100%;
    height: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 15px 0;
    overflow-y: scroll;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results {
    display: none;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results:only-child {
    display: flex;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results
    > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 23px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 5.69px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results
    > p.style2 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results
    > .divider {
    width: 100px;
    height: 1px;
    background-color: var(--oliver-border);
    margin: 30px 0;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results
    > button {
    width: 210px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--oliver-blue);
    border-radius: 6px;
    background-color: #fff;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: var(--oliver-blue);
    margin-top: 20px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results
    > button
    > img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result {
    width: 310px;
    height: 200px;
    border-radius: 6px;
    box-sizing: border-box;
    padding: 15px 16.5px 18px 16.5px;
    background-color: #ebf6ff;
    display: flex;
    flex-direction: column;
    margin-top: 13px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result:nth-child(2) {
    margin-top: 0;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result.customer {
    background-color: #ebf6ff;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result.product {
    height: 220px;
    background-color: #f1f4f6;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result.group {
    height: 220px;
    background-color: #fff5f1;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .col {
    width: 100%;
    margin-bottom: auto;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .col
    > p {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .col
    > p:last-of-type {
    margin-bottom: 0;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .col
    > p.style1 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-blue);
    margin-bottom: 4px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .col
    > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 4px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .col
    > p.style3 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 2px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button {
    width: 87px;
    height: 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    text-align: center;
    color: #fff;
    margin-right: 8px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button:last-of-type {
    margin-right: 0;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-view {
    font-size: 11px;
    font-weight: 500;
    line-height: 15px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-view
    > img {
    width: 20px;
    height: 20px;
    margin-bottom: 3px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-transactions {
    font-family: Poppins;
    font-size: 11px;
    font-weight: 500;
    line-height: 15px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-transactions
    > img {
    width: 24px;
    height: 24px;
    margin-bottom: 3px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-add-to-sale {
    font-size: 11px;
    font-weight: 500;
    line-height: 15px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-add-to-sale
    > img {
    width: 24px;
    height: 24px;
    margin-bottom: 3px;
  }

  /* Switch Users */

  .subwindow-wrapper > .subwindow.switch-user {
    position: fixed;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > button.mobile-close-subwindow {
    display: none;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > .group {
    display: flex;
    align-items: center;
    margin: 10px 0 17px 0;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > .group > img {
    width: 19.72px;
    height: 20px;
    margin-right: 11.28px;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > .group > p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > p.style1 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 24px;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > .divider {
    width: 65px;
    height: 1px;
    margin-bottom: 24px;
    background-color: var(--oliver-border);
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > p.style2 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 24px;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > .pinpad {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 278px;
    margin-bottom: 10px;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > p.error-message {
    padding: 11px 28px;
    background-color: #ffe7e8;
    border-radius: 8px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--error);
    margin: -10px 0 20px 0;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-entries {
    display: flex;
    margin-bottom: 17px;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-entries
    > .pin-entry {
    width: 16px;
    height: 16px;
    background-color: #b0bec9;
    margin-left: 10px;
    border-radius: 50%;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-entries
    > .pin-entry:first-child {
    margin: 0;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-entries
    > .pin-entry.entered {
    background-color: var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-entries.incorrect {
    animation: shake 0.3s;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-entries.incorrect
    > .pin-entry {
    background-color: var(--oliver-red);
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row {
    width: 100%;
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row
    > button {
    width: 82px;
    height: 82px;
    box-sizing: border-box;
    border: 1px solid #b0bec9;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row:last-child
    > button:first-child {
    margin-left: auto;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row
    > button.backspace {
    background-color: transparent;
    border: 0;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row
    > button
    > p {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row
    > button.backspace
    > img {
    width: 50px;
    height: 40px;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row
    > button
    > .text-row {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row
    > button
    > .text-row.no-opacity {
    opacity: 0;
  }

  /* End Session */

  .subwindow-wrapper > .subwindow.end-session {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .subwindow-wrapper > .subwindow.end-session > .subwindow-header {
    display: none;
  }

  .subwindow-wrapper > .subwindow.end-session > .subwindow-body {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0;
    padding: 0;
  }

  .subwindow-wrapper
    > .subwindow.end-session
    > .subwindow-body
    > button.mobile-close-subwindow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 109px;
    height: 40px;
    background-color: #fff;
    border: 1px solid var(--oliver-blue);
    border-radius: 6px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-blue);
    align-self: flex-start;
    margin: 20px 0 0 20px;
  }

  .subwindow-wrapper
    > .subwindow.end-session
    > .subwindow-body
    > button.mobile-close-subwindow
    > img {
    width: 7.1px;
    height: 14.2px;
    margin-right: 14.9px;
  }

  .subwindow-wrapper > .subwindow.end-session > .subwindow-body > p.style1 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin: 20px 0 17px 0;
  }

  .subwindow-wrapper > .subwindow.end-session > .subwindow-body > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 27px;
  }

  .subwindow-wrapper
    > .subwindow.end-session
    > .subwindow-body
    > button:not(.mobile-close-subwindow) {
    width: 230px;
    height: 50px;
    border: 0;
    background-color: var(--oliver-red);
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin-bottom: 20px;
  }

  /* Partial Payments */

  .subwindow-wrapper > .subwindow.partial-payment {
    width: min(400px, 95%);
    height: min(400px, 95%);
  }

  .subwindow-wrapper > .subwindow.partial-payment > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper > .subwindow.partial-payment > .subwindow-body > label {
    width: 306px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-bottom: 8px;
  }

  .subwindow-wrapper > .subwindow.partial-payment > .subwindow-body > input {
    width: 306px;
    height: 70px;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 2px;
    font-family: Poppins;
    font-size: 40px;
    font-weight: 500;
    line-height: 56px;
    text-align: center;
    color: var(--oliver-blue);
    margin-bottom: 28px;
  }

  .subwindow-wrapper
    > .subwindow.partial-payment
    > .subwindow-body
    > input:hover,
  .subwindow-wrapper
    > .subwindow.partial-payment
    > .subwindow-body
    > input:focus {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.partial-payment > .subwindow-body > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 12px;
  }

  .subwindow-wrapper
    > .subwindow.partial-payment
    > .subwindow-body
    > .payment-types {
    width: 306px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }

  .subwindow-wrapper
    > .subwindow.partial-payment
    > .subwindow-body
    > .payment-types
    > button {
    width: 94px;
    height: 94px;
    margin: 0 12px 12px 0;
    border: 0;
    border-radius: 8px;
    background-color: var(--oliver-primary);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #fff;
    overflow: hidden;
  }

  .subwindow-wrapper
    > .subwindow.partial-payment
    > .subwindow-body
    > .payment-types
    > button:nth-child(3n) {
    margin-right: 0;
  }

  .subwindow-wrapper
    > .subwindow.partial-payment
    > .subwindow-body
    > .payment-types
    > button
    > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* Product Discount */

  .subwindow-wrapper > .subwindow.product-discount {
    width: min(384px, 95%);
    height: min(344px, 95%);
  }

  .subwindow-wrapper > .subwindow.product-discount > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper > .subwindow.product-discount > .subwindow-body > label {
    width: 302px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-bottom: 9px;
  }

  .subwindow-wrapper > .subwindow.product-discount > .subwindow-body > input {
    width: 302px;
    height: 83px;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    outline: 0;
    font-family: Poppins;
    font-size: 40px;
    font-weight: 400;
    line-height: 56px;
    text-align: center;
    color: var(--oliver-blue);
    margin-bottom: 23px;
  }

  .subwindow-wrapper
    > .subwindow.product-discount
    > .subwindow-body
    > input:focus {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.product-discount > .subwindow-body > p {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-grey);
    margin-bottom: 12px;
  }

  .subwindow-wrapper
    > .subwindow.product-discount
    > .subwindow-body
    > .button-row {
    width: 302px;
    display: flex;
  }

  .subwindow-wrapper
    > .subwindow.product-discount
    > .subwindow-body
    > .button-row
    > button {
    flex: 1;
    height: 44px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  .subwindow-wrapper
    > .subwindow.product-discount
    > .subwindow-body
    > .button-row
    > button:first-of-type {
    margin-right: 14px;
  }

  /* Adjust Inventory */

  .subwindow-wrapper > .subwindow.adjust-inventory {
    width: min(384px, 95%);
    height: min(458px, 95%);
  }

  .subwindow-wrapper > .subwindow.adjust-inventory > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper > .subwindow.adjust-inventory > .subwindow-body > p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 15px;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > p:first-of-type {
    margin: 20px 0 6px 0;
  }

  .subwindow-wrapper > .subwindow.adjust-inventory > .subwindow-body > label {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 10px;
  }

  .subwindow-wrapper > .subwindow.adjust-inventory > .subwindow-body > input {
    outline: 0;
    width: 306px;
    height: 70px;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    font-family: Poppins;
    font-size: 40px;
    font-weight: 500;
    line-height: 56px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > input:focus {
    border-color: var(--oliver-blue);
    color: var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > button#editStockButton {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 0;
    background-color: transparent;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-blue);
    margin-bottom: 7px;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > button#editStockButton
    > img {
    width: 19px;
    height: 19px;
    margin-right: 5px;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > button#updateStockButton {
    width: 306px;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin-bottom: 32px;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > .text-row {
    display: flex;
    align-items: center;
    width: 306px;
    margin-bottom: 9px;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > :nth-last-child(2) {
    margin-bottom: 20px;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > .text-row
    > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > .text-row
    > p
    > b {
    font-weight: 600;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > .text-row
    > p:first-of-type {
    margin-right: 20px;
  }

  /* Product Out of Stock*/

  .subwindow-wrapper > .subwindow.out-of-stock {
    width: min(344px, 95%);
    height: min(323px, 95%);
  }

  .subwindow-wrapper > .subwindow.out-of-stock > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    box-sizing: border-box;
  }

  .subwindow-wrapper
    > .subwindow.out-of-stock
    > .subwindow-body
    > .auto-margin-bottom {
    margin: 0;
  }

  .subwindow-wrapper > .subwindow.out-of-stock > .subwindow-body > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 18px;
  }

  .subwindow-wrapper > .subwindow.out-of-stock > .subwindow-body > img {
    width: 69px;
    height: 69px;
    margin-bottom: 19px;
  }

  .subwindow-wrapper > .subwindow.out-of-stock > .subwindow-body > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 38px;
  }

  .subwindow-wrapper
    > .subwindow.out-of-stock
    > .subwindow-body
    > p.style2
    > button {
    background-color: transparent;
    border: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.out-of-stock
    > .subwindow-body
    > p.style2
    > button:hover {
    text-decoration: underline;
  }

  .subwindow-wrapper > .subwindow.out-of-stock > .subwindow-body > button {
    width: 306px;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Product Note*/

  .subwindow-wrapper > .subwindow.product-note {
    width: min(384px, 95%);
    height: min(400px, 95%);
  }

  .subwindow-wrapper > .subwindow.product-note > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper > .subwindow.product-note > .subwindow-body > label {
    width: 306px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-bottom: 12px;
  }

  .subwindow-wrapper > .subwindow.product-note > .subwindow-body > textarea {
    width: 306px;
    height: 180px;
    outline: 0;
    resize: none;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    padding: 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 19px;
  }

  .subwindow-wrapper
    > .subwindow.product-note
    > .subwindow-body
    > textarea:focus {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.product-note > .subwindow-body > button {
    width: 306px;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Park Sale */

  .subwindow-wrapper > .subwindow.park-sale {
    width: min(384px, 95%);
    height: min(392px, 95%);
  }

  .subwindow-wrapper > .subwindow.park-sale > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper > .subwindow.park-sale > .subwindow-body > label {
    width: 306px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-bottom: 9px;
  }

  .subwindow-wrapper > .subwindow.park-sale > .subwindow-body > textarea {
    width: 306px;
    height: 180px;
    resize: none;
    outline: 0;
    box-sizing: border-box;
    padding: 12px;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-bottom: 19px;
  }

  .subwindow-wrapper > .subwindow.park-sale > .subwindow-body > textarea:focus {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.park-sale > .subwindow-body > button {
    width: 306px;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Split by Group TODO */

  .subwindow-wrapper > .subwindow.split-by-people {
    width: min(400px, 95%);
    height: min(400px, 95%);
  }

  .subwindow-wrapper > .subwindow.split-by-people > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper
    > .subwindow.split-by-people
    > .subwindow-body
    > .input-numpad {
    width: 100%;
  }

  .subwindow-wrapper
    > .subwindow.split-by-people
    > .subwindow-body
    > .input-numpad
    > .input-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper
    > .subwindow.split-by-people
    > .subwindow-body
    > .input-numpad
    > .input-container
    > label {
    width: 306px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin: 20px 0 10px 0;
  }

  .subwindow-wrapper
    > .subwindow.split-by-people
    > .subwindow-body
    > .input-numpad
    > .input-container
    > input {
    width: 306px;
    height: 70px;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    font-family: Poppins;
    font-size: 40px;
    font-weight: 500;
    line-height: 56px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.split-by-people
    > .subwindow-body
    > .input-numpad
    > .input-container
    > input:focus {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.split-by-people
    > .subwindow-body
    > .input-numpad
    > #numpad {
    display: none;
  }

  .subwindow-wrapper > .subwindow.split-by-people > .subwindow-body > button {
    width: 306px;
    height: 50px;
    margin: 31px 0 20px 0;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Split by Product */

  .subwindow-wrapper > .subwindow.split-by-product {
    width: min(384px, 95%);
    height: min(425px, 95%);
  }

  .subwindow-wrapper > .subwindow.split-by-product > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .subwindow-wrapper > .subwindow.split-by-product > .subwindow-body > .body {
    width: 100%;
    height: 0;
    flex-grow: 1;
    overflow-y: scroll;
    padding: 6px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row {
    width: 306px;
    display: flex;
    flex-direction: column;
    padding: 14px 0;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .main-row {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .main-row
    > .text-group {
    margin-right: auto;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .main-row
    > .text-group
    > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: var(--oliver-base-blue);
    /* max-width: ; */
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .main-row
    > .increment-input {
    width: 143.85px;
    height: 47px;
    display: flex;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    background-color: #fff;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .main-row
    > .increment-input
    > button {
    border: 0;
    background-color: transparent;
    width: 25%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .main-row
    > .increment-input
    > button
    > img {
    width: 20px;
    height: 20px;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .main-row
    > .increment-input
    > input {
    width: 50%;
    border: 0;
    outline: 0;
    background-color: transparent;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .secondary-row {
    display: none;
  }

  .subwindow-wrapper > .subwindow.split-by-product > .subwindow-body > .footer {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-top: 1px solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .footer
    > button {
    width: 306px;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Cash Payment */

  .subwindow-wrapper > .subwindow.cash-payment.step-1 {
    width: min(384px, 95%);
    height: min(666px, 95%);
  }

  .subwindow-wrapper > .subwindow.cash-payment.step-2 {
    width: min(420px, 95%);
    height: 400px;
  }

  .subwindow-wrapper > .subwindow.cash-payment > .subwindow-body > .step1 {
    width: 100%;
    height: 100%;
    display: none;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment.step-1
    > .subwindow-body
    > .step1 {
    display: flex;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .text-row {
    width: 321px;
    display: flex;
    align-items: center;
    margin-bottom: 19px;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .text-row
    > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-right: auto;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .text-row
    > p.style2 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: right;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background-color: rgba(212, 222, 229, 0.5);
    margin-bottom: 14px;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > .input-container {
    width: 289px;
    height: 93px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 6px;
    margin-bottom: 9px;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > .input-container
    > label {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: center;
    color: var(--oliver-base-grey);
    margin-bottom: 11px;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > .input-container
    > input {
    width: 193px;
    height: 42px;
    outline: 0;
    border: 0;
    background-color: transparent;
    font-family: Poppins;
    font-size: 40px;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > #numpad2 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > #numpad2
    > .button-row {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 9px;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > #numpad2
    > .button-row:nth-child(1) {
    margin: 0;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > #numpad2
    > .button-row
    > button {
    width: 90px;
    height: 68px;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    margin-left: 9px;
    background-color: #fff;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 400;
    line-height: 45px;
    text-align: center;
    color: var(--oliver-base-blue);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > #numpad2
    > .button-row
    > button:first-of-type {
    margin-left: 0;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > #numpad2
    > .button-row
    > button.backspace
    > img {
    width: 40px;
    height: 40px;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > button {
    width: 320px;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > button:disabled {
    background-color: var(--oliver-border);
  }

  .subwindow-wrapper > .subwindow.cash-payment > .subwindow-body > .step2 {
    width: 100%;
    height: 100%;
    display: none;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment.step-2
    > .subwindow-body
    > .step2 {
    display: flex;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment.step-2
    > .subwindow-body
    > .step2
    > .text-row {
    width: 306px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.cash-payment.step-2
    > .subwindow-body
    > .step2
    > .text-row:nth-child(2) {
    border: 0;
    padding: 0;
    margin-top: 0;
    padding-top: 0;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment.step-2
    > .subwindow-body
    > .step2
    > .text-row
    > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.cash-payment.step-2
    > .subwindow-body
    > .step2
    > .text-row
    > p
    > b {
    font-weight: 600;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment.step-2
    > .subwindow-body
    > .step2
    > button {
    width: 306px;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    margin-top: 26px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Email Receipt */

  .subwindow-wrapper > .subwindow.email-receipt {
    width: min(384px, 95%);
    height: min(275px, 95%);
  }

  .subwindow-wrapper > .subwindow.email-receipt > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper > .subwindow.email-receipt > .subwindow-body > label {
    width: 306px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-bottom: 9px;
  }

  .subwindow-wrapper > .subwindow.email-receipt > .subwindow-body > input {
    width: 306px;
    height: 44px;
    box-sizing: border-box;
    padding: 0 12px;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 20px;
  }

  .subwindow-wrapper > .subwindow.email-receipt > .subwindow-body > button {
    width: 306px;
    height: 50px;
    border: 0;
    border-radius: 6px;
    background-color: var(--oliver-blue);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Quick Tax */

  .subwindow-wrapper > .subwindow.quick-tax {
    width: min(300px, 95%);
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-header {
    justify-content: space-between;
    height: 55px;
    padding: 0 11px 0 22px;
    min-height: 0;
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-header > P {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #243249;
    flex-grow: 1;
    width: 0;
    max-width: max-content;
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-header > button {
    border: 0;
    background-color: transparent;
    padding: 11px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-blue);
    text-decoration: underline;
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-body {
    height: fit-content;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 22px;
    box-sizing: border-box;
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-body > .row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
  }

  .subwindow-wrapper
    > .subwindow.quick-tax
    > .subwindow-body
    > .row:nth-child(1) {
    margin-top: 0;
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-body > .row > p {
    width: 0;
    flex-grow: 1;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.quick-tax
    > .subwindow-body
    > .row
    > label:hover {
    cursor: pointer;
  }

  .subwindow-wrapper
    > .subwindow.quick-tax
    > .subwindow-body
    > .row
    > label
    > input {
    opacity: 0;
    position: fixed;
    left: -10000px;
  }

  .subwindow-wrapper
    > .subwindow.quick-tax
    > .subwindow-body
    > .row
    > label
    > .custom-toggle {
    position: relative;
    width: 40px;
    height: 25px;
    background-color: #b0bec9;
    border-radius: 25px;
  }

  .subwindow-wrapper
    > .subwindow.quick-tax
    > .subwindow-body
    > .row
    > label
    > .custom-toggle
    > .knob {
    box-sizing: border-box;
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #fff;
    top: calc(50% - 11px);
    left: 1.5px;
    border: 0.392157px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0px 2.35294px 6.27451px rgba(0, 0, 0, 0.15),
      0px 2.35294px 0.784314px rgba(0, 0, 0, 0.06);
  }

  .subwindow-wrapper
    > .subwindow.quick-tax
    > .subwindow-body
    > .row
    > label
    > input:checked
    ~ .custom-toggle {
    background-color: var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.quick-tax
    > .subwindow-body
    > .row
    > label
    > input:checked
    ~ .custom-toggle
    > .knob {
    left: auto;
    right: 1.5px;
  }

  /* Detailed Tax */

  .subwindow-wrapper > .subwindow.detailed-tax {
    width: min(500px, 95%);
    height: min(678px, 95%);
  }

  .subwindow-wrapper > .subwindow.detailed-tax > .subwindow-body {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .subwindow-wrapper > .subwindow.detailed-tax > .subwindow-body > .header-row {
    width: 100%;
    height: 45px;
    box-sizing: border-box;
    padding: 0 22px;
    border-bottom: 1px solid var(--oliver-border);
    display: flex;
    align-items: center;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .header-row
    > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .header-row
    > :nth-child(1) {
    width: 45%;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .header-row
    > :nth-child(2) {
    width: 40%;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .header-row
    > :nth-child(3) {
    display: none;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .header-row
    > :nth-child(4) {
    display: none;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .header-row
    > :nth-child(5) {
    width: 15%;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container {
    width: 100%;
    height: 0;
    flex-grow: 1;
    overflow-y: scroll;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row {
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    padding: 0 22px;
    background-color: #f1f9ff;
    display: flex;
    align-items: center;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row:nth-child(2n) {
    background-color: transparent;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > :nth-child(1) {
    width: 45%;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > :nth-child(2) {
    width: 40%;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > :nth-child(3) {
    display: none;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > :nth-child(4) {
    display: none;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > :nth-child(5) {
    width: 15%;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > p {
    box-sizing: border-box;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-base-blue);
    padding-right: 15px;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > .checkbox-wrapper {
    display: flex;
    justify-content: center;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > .checkbox-wrapper:hover {
    cursor: pointer;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > .checkbox-wrapper
    > input {
    position: fixed;
    left: -10000px;
    opacity: 0;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > .checkbox-wrapper
    > .custom-checkbox {
    width: 18px;
    height: 18px;
    border: 1px solid var(--oliver-border);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > .checkbox-wrapper
    > .custom-checkbox
    > img {
    display: none;
    width: 12px;
    height: 9.46px;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > .checkbox-wrapper
    > input:checked
    ~ .custom-checkbox
    > img {
    display: block;
  }

  .subwindow-wrapper > .subwindow.detailed-tax > .subwindow-body > .footer {
    width: 100%;
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-top: 1px solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .footer
    > button {
    width: 280px;
    height: 44px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
  }

  /* Add Customer Note */

  .subwindow-wrapper > .subwindow.add-customer-note {
    width: min(384px, 95%);
    height: min(400px, 95%);
  }

  .subwindow-wrapper > .subwindow.add-customer-note > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper > .subwindow.add-customer-note > .subwindow-body > label {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    width: 306px;
    margin-bottom: 9px;
  }

  .subwindow-wrapper
    > .subwindow.add-customer-note
    > .subwindow-body
    > textarea {
    width: 306px;
    height: 180px;
    box-sizing: border-box;
    outline: 0;
    resize: none;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    padding: 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 19px;
  }

  .subwindow-wrapper
    > .subwindow.add-customer-note
    > .subwindow-body
    > textarea:focus {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.add-customer-note > .subwindow-body > button {
    width: 306px;
    height: 50px;
    box-sizing: border-box;
    border: 0;
    border-radius: 6px;
    background-color: var(--oliver-blue);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Add Cash */

  .subwindow-wrapper > .subwindow.add-cash {
    width: min(384px, 95%);
    height: min(445px, 95%);
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body > .text-row {
    width: 309px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 17px;
  }

  .subwindow-wrapper
    > .subwindow.add-cash
    > .subwindow-body
    > .text-row
    > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.add-cash
    > .subwindow-body
    > .text-row
    > p.style2 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
    color: var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body > .input-row {
    width: 309px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 26px;
    border-bottom: 1px solid var(--oliver-border);
    margin-bottom: 20px;
  }

  .subwindow-wrapper
    > .subwindow.add-cash
    > .subwindow-body
    > .input-row
    > label {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.add-cash
    > .subwindow-body
    > .input-row
    > input {
    width: 207px;
    height: 50px;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    padding: 0 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: right;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.add-cash
    > .subwindow-body
    > .input-row
    > input:focus {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body > label {
    width: 309px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-bottom: 12px;
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body > textarea {
    width: 306px;
    height: 96px;
    outline: 0;
    resize: none;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    padding: 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 23px;
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body > textarea:focus {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body > button {
    width: 306px;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Remove Cash */

  .subwindow-wrapper > .subwindow.remove-cash {
    width: min(384px, 95%);
    height: min(445px, 95%);
  }

  .subwindow-wrapper > .subwindow.remove-cash > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper > .subwindow.remove-cash > .subwindow-body > .text-row {
    width: 309px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 17px;
  }

  .subwindow-wrapper
    > .subwindow.remove-cash
    > .subwindow-body
    > .text-row
    > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.remove-cash
    > .subwindow-body
    > .text-row
    > p.style2 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
    color: var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.remove-cash > .subwindow-body > .input-row {
    width: 309px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 26px;
    border-bottom: 1px solid var(--oliver-border);
    margin-bottom: 20px;
  }

  .subwindow-wrapper
    > .subwindow.remove-cash
    > .subwindow-body
    > .input-row
    > label {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.remove-cash
    > .subwindow-body
    > .input-row
    > input {
    width: 174px;
    height: 50px;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    padding: 0 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: right;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.remove-cash
    > .subwindow-body
    > .input-row
    > input:focus {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.remove-cash > .subwindow-body > label {
    width: 309px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-bottom: 12px;
  }

  .subwindow-wrapper > .subwindow.remove-cash > .subwindow-body > textarea {
    width: 306px;
    height: 96px;
    outline: 0;
    resize: none;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    padding: 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 23px;
  }

  .subwindow-wrapper
    > .subwindow.remove-cash
    > .subwindow-body
    > textarea:focus {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.remove-cash > .subwindow-body > button {
    width: 306px;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* No Customer */

  .subwindow-wrapper > .subwindow.no-customer {
    width: min(384px, 95%);
    height: min(384px, 95%);
  }

  .subwindow-wrapper > .subwindow.no-customer > .subwindow-body {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper > .subwindow.no-customer > .subwindow-body > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 25px;
  }

  .subwindow-wrapper > .subwindow.no-customer > .subwindow-body > img {
    width: 52px;
    height: 52px;
    margin-bottom: 29px;
  }

  .subwindow-wrapper > .subwindow.no-customer > .subwindow-body > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 36px;
  }

  .subwindow-wrapper > .subwindow.no-customer > .subwindow-body > button {
    width: 306px;
    height: 50px;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }

  .subwindow-wrapper
    > .subwindow.no-customer
    > .subwindow-body
    > button:first-of-type {
    box-sizing: border-box;
    border: 1px solid var(--oliver-blue);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--oliver-blue);
    margin-bottom: 11px;
  }

  .subwindow-wrapper
    > .subwindow.no-customer
    > .subwindow-body
    > button:first-of-type
    > img {
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }

  .subwindow-wrapper
    > .subwindow.no-customer
    > .subwindow-body
    > button:nth-last-child(2) {
    background-color: var(--oliver-blue);
    border: 0;
    color: #fff;
  }

  /* Adjust Credit */

  .subwindow-wrapper > .subwindow.adjust-credit {
    width: min(400px, 95%);
    height: min(498px, 95%);
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-grey);
    margin-bottom: 10px;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .toggle-wrapper {
    width: 307px;
    height: 50px;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    padding: 6px;
    margin-bottom: 21px;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .toggle-wrapper
    > label {
    flex: 1;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .toggle-wrapper
    > label:hover {
    cursor: pointer;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .toggle-wrapper
    > label
    > input {
    position: fixed;
    left: -10000px;
    opacity: 0;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .toggle-wrapper
    > label
    > .custom-radio {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-grey);
    border-radius: 6px;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .toggle-wrapper
    > label
    > input:checked
    ~ .custom-radio {
    background-color: var(--oliver-blue);
    color: #fff;
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body > .text-row {
    width: 306px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .text-row
    > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .text-row
    > p.style2 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: right;
    color: var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .change-credit-row {
    width: 306px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .change-credit-row.hidden {
    display: none;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .change-credit-row
    > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .change-credit-row
    > input {
    width: 160px;
    height: 50px;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    padding: 0 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: right;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .change-credit-row
    > input:focus {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body > label {
    width: 306px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-bottom: 12px;
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body > textarea {
    width: 306px;
    height: 96px;
    outline: 0;
    resize: none;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    padding: 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 19px;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > textarea:focus {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body > button {
    width: 306px;
    height: 50px;
    border: 0;
    border-radius: 6px;
    background-color: var(--oliver-blue);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
  }

  /* Layaway */

  .subwindow-wrapper > .subwindow.layaway {
    width: min(384px, 95%);
    height: min(400px, 95%);
  }

  .subwindow-wrapper > .subwindow.layaway > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper > .subwindow.layaway > .subwindow-body > label {
    width: 306px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 12px;
  }

  .subwindow-wrapper > .subwindow.layaway > .subwindow-body > textarea {
    width: 306px;
    height: 180px;
    outline: 0;
    resize: none;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    padding: 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 20px;
  }

  .subwindow-wrapper > .subwindow.layaway > .subwindow-body > textarea:focus {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.layaway > .subwindow-body > button {
    width: 306px;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
  }

  /* Store Credit Pay */

  .subwindow-wrapper > .subwindow.store-credit-pay {
    width: min(384px, 95%);
    height: min(666px, 95%);
  }

  .subwindow-wrapper > .subwindow.store-credit-pay > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .text-row {
    width: 321px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .text-row
    > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .text-row
    > p.style2 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: right;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper {
    width: 321px;
    box-sizing: border-box;
    padding: 16px;
    background-color: rgba(212, 222, 229, 0.5);
    border-radius: 8px;
    margin-bottom: 14px;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .input-wrapper {
    width: 100%;
    height: 93px;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    background-color: #fff;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .input-wrapper
    > p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: center;
    color: var(--oliver-base-grey);
    margin-bottom: 11px;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .input-wrapper
    > input {
    width: 90%;
    height: 42px;
    border: 0;
    font-family: Poppins;
    font-size: 40px;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .numpad-row {
    width: 100%;
    height: 68px;
    margin-top: 9px;
    display: flex;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .numpad-row
    > button {
    flex: 1;
    margin-left: 9px;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .numpad-row
    > button:first-of-type {
    margin-left: 0;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .numpad-row
    > button
    > img {
    width: 40px;
    height: 40px;
  }

  .subwindow-wrapper > .subwindow.store-credit-pay > .subwindow-body > button {
    width: 321px;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Logout Confirmation */

  .subwindow-wrapper > .subwindow.logout-confirm {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .subwindow-wrapper > .subwindow.logout-confirm > .subwindow-body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0;
  }

  .subwindow-wrapper
    > .subwindow.logout-confirm
    > .subwindow-body
    > .auto-margin-bottom {
    display: none;
  }

  .subwindow-wrapper > .subwindow.logout-confirm > .subwindow-body > p.style1 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 29px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 53px;
  }

  .subwindow-wrapper > .subwindow.logout-confirm > .subwindow-body > p.style2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-top: 20px;
  }

  .subwindow-wrapper
    > .subwindow.logout-confirm
    > .subwindow-body
    > p.style2:first-of-type {
    margin-top: 0;
  }

  .subwindow-wrapper
    > .subwindow.logout-confirm
    > .subwindow-body
    > p.style2:last-of-type {
    margin-bottom: auto;
  }

  .subwindow-wrapper > .subwindow.logout-confirm > .subwindow-body > button {
    width: 343px;
    height: 54px;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }

  .subwindow-wrapper
    > .subwindow.logout-confirm
    > .subwindow-body
    > button#registerLogout {
    background-color: var(--oliver-blue);
    border: 0;
    color: #fff;
    margin: 53px 0 16px 0;
  }

  .subwindow-wrapper
    > .subwindow.logout-confirm
    > .subwindow-body
    > button#cancelRegisterLogout {
    background-color: #fff;
    border: 1px solid var(--oliver-blue);
    color: var(--oliver-blue);
  }

  /* Receipt */

  .subwindow-wrapper > .subwindow.receipt {
    width: min(384px, 95%);
    height: min(300px, 95%);
  }

  .subwindow-wrapper > .subwindow.receipt > .subwindow-body {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper > .subwindow.receipt > .subwindow-body > .receipt-options {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-options.hidden {
    display: none;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-options
    > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-grey);
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-options
    > button {
    width: 265px;
    height: 50px;
    margin-top: 17px;
    border-radius: 6px;
    border: 0;
    background-color: var(--oliver-blue);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-options
    > button:first-of-type {
    margin-top: 21px;
  }

  .subwindow-wrapper > .subwindow.receipt > .subwindow-body > .receipt-email {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-email.hidden {
    display: none;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-email
    > label {
    width: 306px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: var(--oliver-base-grey);
    padding-bottom: 5px;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-email
    > label:hover {
    cursor: pointer;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-email
    > label:hover
    + input {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-email
    > input {
    width: 309px;
    height: 50px;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    padding: 0 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 17px;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-email
    > input:hover {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-email
    > input:focus {
    border-width: 2px;
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-email
    > button {
    width: 306px;
    height: 50px;
    border: 0;
    background-color: var(--oliver-blue);
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* No Variations Selected */

  .subwindow-wrapper > .subwindow.no-variation-selected {
    width: 344px;
    height: min(323px, 95%);
  }

  .subwindow-wrapper > .subwindow.no-variation-selected > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
  }

  .subwindow-wrapper
    > .subwindow.no-variation-selected
    > .subwindow-body
    > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 23px;
  }

  .subwindow-wrapper
    > .subwindow.no-variation-selected
    > .subwindow-body
    > img {
    width: 61px;
    height: 61px;
    margin-bottom: 22px;
  }

  .subwindow-wrapper
    > .subwindow.no-variation-selected
    > .subwindow-body
    > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 38px;
  }

  .subwindow-wrapper
    > .subwindow.no-variation-selected
    > .subwindow-body
    > button {
    width: 306px;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  .subwindow-wrapper
    > .subwindow.no-variation-selected
    > .subwindow-body
    > .auto-margin-bottom {
    margin: 0;
  }

  /* Update Transaction Status */

  .subwindow-wrapper > .subwindow.update-transaction-status {
    width: min(344px, 95%);
    height: min(95%, 564px);
  }

  .subwindow-wrapper > .subwindow.update-transaction-status > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-grey);
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > label {
    margin-top: 15px;
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > label
    > input {
    position: fixed;
    left: -10000px;
    opacity: 0;
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > label
    > .custom-radio {
    width: 285px;
    height: 45px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid var(--oliver-border);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > label
    > input:not(:disabled):not(:checked)
    + .custom-radio:hover {
    cursor: pointer;
    border: 1px solid var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > label
    > input:checked
    + .custom-radio {
    border: 2px solid var(--oliver-blue);
    color: var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > label
    > input:disabled
    + .custom-radio {
    background-color: transparent;
    color: var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > button {
    margin-top: 28px;
    width: 285px;
    height: 45px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 8px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* --------------------Open Register-------------------- */

  .open-register-wrapper {
    overflow-y: scroll;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--oliver-base-blue);
  }

  .open-register-wrapper > #cancel,
  .open-register-wrapper > #goBack {
    align-self: flex-start;
    display: none;
    align-items: center;
    justify-content: center;
    width: 132.1px;
    height: 52px;
    border: 0;
    background-color: transparent;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    color: #fff;
  }

  .open-register-wrapper > #cancel > img,
  .open-register-wrapper > #goBack > img {
    width: 8.2px;
    height: 16.4px;
    margin-right: 10px;
  }

  .open-register-wrapper > #logout-landscape {
    display: none;
  }

  .open-register-wrapper > #logout-mobile {
    width: 131.1px;
    height: 52px;
    display: none;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 0;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    color: #fff;
    margin-top: 20px;
  }

  .open-register-wrapper > #logout-mobile > img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  .open-register-wrapper.state-1 > #logout-mobile,
  .open-register-wrapper.state-1 > #cancel {
    display: flex;
  }

  .open-register-wrapper.state-2 > #goBack,
  .open-register-wrapper.state-3 > #goBack {
    display: flex;
  }

  .open-register-wrapper > #logout.hidden {
    display: none;
  }

  .open-register-wrapper > header {
    width: 343px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0 30.28px 0;
    border-bottom: 1px solid #fff;
    margin-bottom: 20px;
  }

  .open-register-wrapper > header .col {
    max-width: 65%;
  }

  .open-register-wrapper > header > img {
    width: 106.86px;
    height: 97.72px;
    margin-right: 28.14px;
  }

  .open-register-wrapper > header p {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .open-register-wrapper > header p.style1 {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 500;
    line-height: 43px;
    text-align: left;
    color: #fff;
    margin-bottom: 5px;
  }

  .open-register-wrapper > header p.style2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
    color: #fff;
    margin-bottom: 4px;
  }

  .open-register-wrapper > header p.style3 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #fff;
  }

  .open-register-wrapper > header .divider {
    display: none;
  }

  .open-register-wrapper > main {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto 0;
  }

  .open-register-wrapper > main > *.hidden {
    display: none !important;
  }

  .open-register-wrapper > main > .step1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .open-register-wrapper > main > .step1 > p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
    text-align: left;
    color: #fff;
    margin-bottom: 26px;
  }

  .open-register-wrapper > main > .step1 > button {
    width: 343px;
    height: 54px;
    border: 0;
    border-radius: 8px;
    background-color: var(--oliver-blue);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  .open-register-wrapper > main > .step2 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .open-register-wrapper > main > .step2 > p {
    width: 343px;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 29px;
    text-align: left;
    color: #fff;
    margin-bottom: 23px;
    margin: 16px 0 23px 0;
  }

  .open-register-wrapper > main > .step2 > label {
    width: 343px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #fff;
    margin-bottom: 9px;
  }

  .open-register-wrapper > main > .step2 > input {
    width: 343px;
    height: 72px;
    border: 0;
    border-radius: 6px;
    outline: 0;
    background-color: #fff;
    font-family: Poppins;
    font-size: 32px;
    font-weight: 400;
    line-height: 45px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 26px;
  }

  .open-register-wrapper > main > .step2 > button {
    width: 343px;
    height: 54px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    border-radius: 8px;
  }

  .open-register-wrapper > main > .step2 > button#openCashDrawer {
    box-sizing: border-box;
    border: 1px solid #fff;
    background-color: transparent;
    margin-bottom: 23px;
  }

  .open-register-wrapper > main > .step2 > button#openFloatButton {
    border: 0;
    background-color: var(--oliver-blue);
    margin: auto 0 26px 0;
  }

  .open-register-wrapper > main > .step2 > textarea {
    width: 343px;
    height: 83px;
    outline: 0;
    resize: none;
    border-radius: 6px;
    border: 0;
    padding: 12px;
    box-sizing: border-box;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 23px;
  }

  .open-register-wrapper > main > .step3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .open-register-wrapper > main > .step3 > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    text-align: center;
    color: #fff;
    margin: 20px 0 19.47px 0;
  }

  .open-register-wrapper > main > .step3 > .error-message {
    width: 276px;
    height: 42px;
    box-sizing: border-box;
    background-color: #ffe7e8;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 18px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--error);
  }

  .open-register-wrapper > main > .step3 > .pinpad {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 276px;
  }

  .open-register-wrapper > main > .step3 > .pinpad > .pin-entries {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 14.89px;
  }

  .open-register-wrapper > main > .step3 > .pinpad > .pin-entries > .pin-entry {
    width: 11.3px;
    height: 11.3px;
    border-radius: 50%;
    background-color: #fff;
    margin-left: 6.78px;
  }

  .open-register-wrapper
    > main
    > .step3
    > .pinpad
    > .pin-entries
    > .pin-entry.entered {
    background-color: var(--oliver-blue);
  }

  .open-register-wrapper > main > .step3 > .pinpad > .pin-entries.incorrect {
    animation: shake 0.3s;
  }

  .open-register-wrapper
    > main
    > .step3
    > .pinpad
    > .pin-entries.incorrect
    > .pin-entry {
    background-color: var(--oliver-red);
  }

  .open-register-wrapper
    > main
    > .step3
    > .pinpad
    > .pin-entries
    > .pin-entry:nth-child(1) {
    margin-left: 0;
  }

  .open-register-wrapper > main > .step3 > .pinpad > .pin-button-row {
    width: 100%;
    display: flex;
    margin-bottom: 16.3px;
  }

  .open-register-wrapper > main > .step3 > .pinpad > .pin-button-row > button {
    width: 81.51px;
    height: 81.51px;
    border-radius: 8px;
    border: 0;
    background-color: #fff;
    margin-right: 16.3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .open-register-wrapper
    > main
    > .step3
    > .pinpad
    > .pin-button-row
    > button
    > p {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    color: var(--oliver-base-blue);
    transform: translateY(3px);
  }

  .open-register-wrapper
    > main
    > .step3
    > .pinpad
    > .pin-button-row
    > button
    > .text-row {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 300;
    line-height: 15px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .open-register-wrapper
    > main
    > .step3
    > .pinpad
    > .pin-button-row
    > button
    > .text-row.no-opacity {
    opacity: 0;
  }

  .open-register-wrapper
    > main
    > .step3
    > .pinpad
    > .pin-button-row
    > button:first-of-type {
    margin-left: auto;
  }

  .open-register-wrapper
    > main
    > .step3
    > .pinpad
    > .pin-button-row
    > button:last-of-type {
    margin-right: 0;
  }

  .open-register-wrapper
    > main
    > .step3
    > .pinpad
    > .pin-button-row
    > button.backspace {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
  }

  .open-register-wrapper
    > main
    > .step3
    > .pinpad
    > .pin-button-row
    > button.backspace
    > img {
    width: 50.38px;
    height: 40.09px;
  }

  /* --------------------Idle Register-------------------- */

  .idle-register-wrapper {
    width: 100%;
    height: 100%;
    background-color: var(--oliver-blue);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
  }

  .idle-register-wrapper > #logoutRegisterButton {
    position: absolute;
    left: 0;
    padding: 5px;
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    color: #fff;
  }

  .idle-register-wrapper > #logoutRegisterButton > img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .idle-register-wrapper > header {
    width: 343px;
    padding: 30px 0;
    border-bottom: 1px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .idle-register-wrapper > header > img {
    width: 106.86px;
    height: 97.72px;
    margin-right: 28.14px;
  }

  .idle-register-wrapper > header > .col {
    max-width: 65%;
  }

  .idle-register-wrapper > header > .col > p {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .idle-register-wrapper > header > .col > p.style1 {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 500;
    line-height: 43px;
    text-align: left;
    color: #fff;
  }

  .idle-register-wrapper > header > .col > p.style2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: #fff;
    margin-bottom: -8px;
  }

  .idle-register-wrapper > header > .col > p.style3 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #fff;
  }

  .idle-register-wrapper > header > .col > button#closeRegister1 {
    display: none;
  }

  .idle-register-wrapper > main {
    width: 100%;
    height: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .idle-register-wrapper > main > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    text-align: center;
    color: #fff;
    margin: 30px 0 19.47px 0;
  }

  .idle-register-wrapper > main > .pinpad {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 278px;
    margin-bottom: 10px;
  }

  .idle-register-wrapper > main > .pinpad > p.error-message {
    padding: 11px 28px;
    background-color: #ffe7e8;
    border-radius: 8px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--error);
    margin: -10px 0 20px 0;
  }

  .idle-register-wrapper > main > .pinpad > .pin-entries {
    display: flex;
    margin-bottom: 17px;
  }

  .idle-register-wrapper > main > .pinpad > .pin-entries > .pin-entry {
    width: 16px;
    height: 16px;
    background-color: #fff;
    margin-left: 10px;
    border-radius: 50%;
  }

  .idle-register-wrapper
    > main
    > .pinpad
    > .pin-entries
    > .pin-entry:first-child {
    margin: 0;
  }

  .idle-register-wrapper > main > .pinpad > .pin-entries > .pin-entry.entered {
    background-color: var(--oliver-base-blue);
  }

  .idle-register-wrapper > main > .pinpad > .pin-entries.incorrect {
    animation: shake 0.3s;
  }

  .idle-register-wrapper
    > main
    > .pinpad
    > .pin-entries.incorrect
    > .pin-entry {
    background-color: var(--oliver-red);
  }

  .idle-register-wrapper > main > .pinpad > .pin-button-row {
    width: 100%;
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
  }

  .idle-register-wrapper > main > .pinpad > .pin-button-row > button {
    width: 82px;
    height: 82px;
    box-sizing: border-box;
    border: 0;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .idle-register-wrapper
    > main
    > .pinpad
    > .pin-button-row:last-child
    > button:first-child {
    margin-left: auto;
  }

  .idle-register-wrapper > main > .pinpad > .pin-button-row > button.backspace {
    background-color: transparent;
    border: 0;
  }

  .idle-register-wrapper
    > main
    > .pinpad
    > .pin-button-row
    > button.backspace
    > img {
    width: 50px;
    height: 40px;
  }

  .idle-register-wrapper > main > .pinpad > .pin-button-row > button > p {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .idle-register-wrapper
    > main
    > .pinpad
    > .pin-button-row
    > button
    > .text-row {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .idle-register-wrapper
    > main
    > .pinpad
    > .pin-button-row
    > button
    > .text-row.no-opacity {
    opacity: 0;
  }

  .idle-register-wrapper > main > button#closeRegister2 {
    width: 337px;
    height: 50px;
    background-color: var(--oliver-base-blue);
    border: 0;
    border-radius: 8px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin: auto 0 20px 0;
  }

  /* --------------------Close Register-------------------- */

  .close-register-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--oliver-blue);
    overflow-y: scroll;
  }

  .close-register-wrapper > button {
    position: fixed;
    width: 78px;
    height: 52.5px;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 0;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;
    text-align: center;
    color: #fff;
  }

  .close-register-wrapper > button > img {
    width: auto;
    height: 12.5px;
    margin-right: 4px;
  }

  .close-register-wrapper > button.hidden {
    display: none;
  }

  .close-register-wrapper > header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 343px;
    padding: 30.28px 0;
    border-bottom: 1px solid #fff;
  }

  .close-register-wrapper > header.hidden {
    display: none;
  }

  .close-register-wrapper > header > img {
    width: 106.86px;
    height: 97.72px;
    margin-right: 28.14px;
  }

  .close-register-wrapper > header > .col {
    max-width: 65%;
  }

  .close-register-wrapper > header > .col > p {
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .close-register-wrapper > header > .col > p.style1 {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 500;
    line-height: 43px;
    text-align: left;
    color: #fff;
    margin-bottom: -2px;
  }

  .close-register-wrapper > header > .col > p.style2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: #fff;
    margin-bottom: -8px;
  }

  .close-register-wrapper > header > .col > p.style3 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #fff;
  }

  .close-register-wrapper > main {
    width: 100%;
    height: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .close-register-wrapper > main > *.hidden {
    display: none !important;
  }

  .close-register-wrapper > main > .step1 {
    width: 100%;
    height: 0;
    flex-grow: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .close-register-wrapper > main > .step1 > p.style1 {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    color: #fff;
    margin: 20px 0;
  }

  .close-register-wrapper > main > .step1 > p.style2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    text-align: center;
    color: #fff;
    margin-bottom: 19.47px;
  }

  .close-register-wrapper > main > .step1 > .pinpad {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 26px;
  }

  .close-register-wrapper > main > .step1 > .pinpad {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 278px;
    margin-bottom: 10px;
  }

  .close-register-wrapper > main > .step1 > .pinpad > p.error-message {
    padding: 11px 28px;
    background-color: #ffe7e8;
    border-radius: 8px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--error);
    margin: -10px 0 20px 0;
  }

  .close-register-wrapper > main > .step1 > .pinpad > .pin-entries {
    display: flex;
    margin-bottom: 17px;
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-entries
    > .pin-entry {
    width: 16px;
    height: 16px;
    background-color: #fff;
    margin-left: 10px;
    border-radius: 50%;
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-entries
    > .pin-entry:first-child {
    margin: 0;
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-entries
    > .pin-entry.entered {
    background-color: var(--oliver-base-blue);
  }

  .close-register-wrapper > main > .step1 > .pinpad > .pin-entries.incorrect {
    animation: shake 0.3s;
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-entries.incorrect
    > .pin-entry {
    background-color: var(--oliver-red);
  }

  .close-register-wrapper > main > .step1 > .pinpad > .pin-button-row {
    width: 100%;
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
  }

  .close-register-wrapper > main > .step1 > .pinpad > .pin-button-row > button {
    width: 82px;
    height: 82px;
    box-sizing: border-box;
    border: 0;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-button-row:last-child
    > button:first-child {
    margin-left: auto;
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-button-row
    > button.backspace {
    background-color: transparent;
    border: 0;
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-button-row
    > button.backspace
    > img {
    width: 50px;
    height: 40px;
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-button-row
    > button
    > p {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-button-row
    > button
    > .text-row {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-button-row
    > button
    > .text-row.no-opacity {
    opacity: 0;
  }

  .close-register-wrapper > main > .step2 {
    width: 100%;
    height: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .close-register-wrapper > main > .step2 > p.style1 {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    color: #fff;
    margin: 16px 0 18px 0;
  }

  .close-register-wrapper > main > .step2 > p.style2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin-bottom: 20px;
  }

  .close-register-wrapper > main > .step2 > p.style2 > b {
    font-weight: 500;
  }

  .close-register-wrapper > main > .step2 > button#openCashDrawer {
    width: 327px;
    height: 70px;
    box-sizing: border-box;
    border: 2px solid #fff;
    border-radius: 8px;
    background-color: transparent;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  .close-register-wrapper > main > .step2 > .input-column {
    width: 100%;
    padding: 29px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: auto;
  }

  .close-register-wrapper > main > .step2 > .input-column > .input-row {
    width: 327px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }

  .close-register-wrapper
    > main
    > .step2
    > .input-column
    > .input-row:nth-child(1) {
    margin-top: 0;
  }

  .close-register-wrapper > main > .step2 > .input-column > .input-row > label {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #fff;
  }

  .close-register-wrapper > main > .step2 > .input-column > .input-row > input {
    width: 183.79px;
    height: 50px;
    border: 0;
    outline: 0;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 18px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: auto;
  }

  .close-register-wrapper > main > .step2 > button#saveCount {
    width: 327px;
    height: 50px;
    border: 0;
    border-radius: 8px;
    background-color: var(--oliver-base-blue);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin-bottom: 26px;
    margin-top: 15px;
  }

  .close-register-wrapper > main > .step3 {
    width: 100%;
    height: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .close-register-wrapper > main > .step3 > p.style1 {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    color: #fff;
    margin: 16px 0 18px 0;
  }

  .close-register-wrapper > main > .step3 > p.style2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin-bottom: 30px;
  }

  .close-register-wrapper > main > .step3 > p.style2 > b {
    font-weight: 500;
  }

  .close-register-wrapper > main > .step3 > label:first-of-type {
    display: none;
  }

  .close-register-wrapper > main > .step3 > .closing-totals {
    width: 347px;
    border-radius: 8px;
    background-color: #fff;
    padding: 29px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }

  .close-register-wrapper > main > .step3 > .closing-totals .tablet-divider {
    display: none;
  }

  .close-register-wrapper > main > .step3 > .closing-totals > .tablet-row {
    display: none;
  }

  .close-register-wrapper > main > .step3 > .closing-totals p.style1 {
    font-family: Roboto Mono;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 22.68px;
  }

  .close-register-wrapper > main > .step3 > .closing-totals p.style2 {
    font-family: Roboto Mono;
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 10px;
  }

  .close-register-wrapper > main > .step3 > .closing-totals p.style3 {
    font-family: Roboto Mono;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .close-register-wrapper > main > .step3 > .closing-totals .divider {
    width: 100%;
    height: 1px;
    background-color: var(--oliver-base-blue);
    margin-bottom: 10px;
  }

  .close-register-wrapper > main > .step3 > .closing-totals > .col {
    width: 310.77px;
    margin-top: 33px;
  }

  .close-register-wrapper
    > main
    > .step3
    > .closing-totals
    > .col:nth-child(2) {
    margin-top: 0;
  }

  .close-register-wrapper > main > .step3 > .closing-totals > .col > .row {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 13px;
  }

  .close-register-wrapper
    > main
    > .step3
    > .closing-totals
    > .col
    > .row:nth-child(1) {
    margin-top: 0;
  }

  .close-register-wrapper
    > main
    > .step3
    > .closing-totals
    > .col
    > .row
    > p:last-of-type {
    margin-left: auto;
  }

  .close-register-wrapper
    > main
    > .step3
    > .closing-totals
    > .col.manual
    > .row:nth-child(3) {
    display: none;
  }

  .close-register-wrapper > main > .step3 > label {
    width: 343px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #fff;
  }

  .close-register-wrapper > main > .step3 > textarea {
    width: 343px;
    height: 143px;
    outline: 0;
    resize: none;
    box-sizing: border-box;
    padding: 12px;
    border: 0;
    border-radius: 8px;
    margin-bottom: 25px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .close-register-wrapper > main > .step3 > button {
    width: 343px;
    height: 54px;
    border-radius: 8px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin-bottom: 16px;
  }

  .close-register-wrapper > main > .step3 > button#printReport {
    background-color: transparent;
    box-sizing: border-box;
    border: 2px solid #fff;
    color: #fff;
  }

  .close-register-wrapper > main > .step3 > button#closeRegister {
    background-color: var(--oliver-base-blue);
    border: 0;
    color: #fff;
  }

  /* --------------------Create User ID-------------------- */

  .create-userid-wrapper {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    background-color: var(--oliver-base-blue);
  }

  .create-userid-wrapper > .header {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    justify-content: space-between;
  }

  .create-userid-wrapper > .header > #goBackPinSetup,
  .create-userid-wrapper > .header > #cancelPinSetup {
    background-color: transparent;
    border: 0;
    display: none;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    color: #fff;
  }

  .create-userid-wrapper > .header > #goBackPinSetup > img,
  .create-userid-wrapper > .header > #cancelPinSetup > img {
    width: 9px;
    height: 16px;
    margin-right: 8px;
  }

  .create-userid-wrapper > .header > #logoutPinSetup {
    background-color: transparent;
    border: 0;
    display: none;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    color: #fff;
  }

  .create-userid-wrapper > .header > #logoutPinSetup > img {
    width: 20px;
    height: 20px;
    margin-right: 7px;
  }

  .create-userid-wrapper > .header.var-1 > #cancelPinSetup {
    display: flex;
  }

  .create-userid-wrapper > .header.var-1 > #logoutPinSetup {
    display: flex;
  }

  .create-userid-wrapper > .header.var-2 > #goBackPinSetup {
    display: flex;
  }

  .create-userid-wrapper > .body {
    width: 100%;
    height: fit-content;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .create-userid-wrapper > .body > .col.static {
    display: flex;
    width: 343px;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;
    border-bottom: 1px solid #fff;
  }

  .create-userid-wrapper > .body > .col.static > img {
    width: 106.86px;
    height: 97.72px;
    margin-right: 28px;
  }

  .create-userid-wrapper > .body > .col.static > .text-group > p.style1 {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 500;
    line-height: 43px;
    text-align: left;
    color: #fff;
  }

  .create-userid-wrapper > .body > .col.static > .text-group > p.style2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: #fff;
    margin-bottom: 2px;
  }

  .create-userid-wrapper > .body > .col.static > .text-group > p.style3 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #fff;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 33px;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-1.hidden {
    display: none;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-1 > p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 35px;
    text-align: center;
    color: #fff;
    margin-bottom: 20px;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-1 > p > b {
    font-weight: 700;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-1 > button {
    width: 343px;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2.hidden {
    display: none;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 > p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
    text-align: center;
    color: #fff;
    margin-bottom: 23px;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 > .id-row {
    width: 276px;
    display: flex;
    margin-bottom: 25.33px;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .id-row
    > .id-entry {
    flex: 1;
    height: 44px;
    background-color: #fff;
    margin-left: 10px;
    box-shadow: inset 0px 4px 6px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 25px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .id-row
    > .id-entry:first-child {
    margin-left: 0;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 > .pin-key-row {
    width: 276px;
    display: flex;
    margin-bottom: 16px;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .pin-key-row
    > button {
    flex: 1;
    aspect-ratio: 1/1;
    margin-left: 16px;
    background-color: #fff;
    border: 0;
    border-radius: 8px;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .pin-key-row
    > button.backspace {
    background-color: transparent;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .pin-key-row
    > button.backspace
    > img {
    width: 50.38px;
    height: 40.09px;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .pin-key-row
    > button:first-of-type {
    margin-left: 0;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 > .pin-key-row.last {
    margin-bottom: 16px;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .pin-key-row.last
    > button {
    flex: 0.312759;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .pin-key-row.last
    > button:first-of-type {
    margin-left: auto;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .pin-key-row
    > button
    > p {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .pin-key-row
    > button
    > .letter-row
    > p {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 300;
    line-height: 15px;
    text-align: center;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 > button {
    width: 343px;
    height: 50px;
    border-radius: 6px;
    border: 0;
    background-color: var(--oliver-blue);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 > button:disabled {
    background-color: var(--oliver-disabled);
  }

  /* --------------------Navbar-------------------- */

  .navbar {
    position: fixed;
    display: flex;
    z-index: 4;
    background-color: #fff;
    flex-direction: column;
    overflow-y: scroll;
    overflow-y: scroll;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 17px 189px 17px;
    border-top: 1px solid var(--oliver-border);
    transform: translateX(-100%);
    transition: transform 0.3s;
  }

  .navbar.open {
    transform: translateX(0);
  }

  .navbar > .header-row {
    display: none;
  }

  .navbar > button {
    width: 100%;
    height: 47px;
    border-radius: 8px;
    background-color: transparent;
    border: 0;
    margin-bottom: 4.49px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 8.65px;
  }

  .navbar > button > .img-container {
    width: 30px;
    height: 30px;
    margin-right: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .navbar > button > .img-container > img {
    width: 100%;
    height: 100%;
  }

  .navbar > button#linkLauncherButton > .img-container > img {
    width: 70%;
    height: 70%;
  }

  .navbar > button > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .navbar > button.selected {
    background-color: var(--oliver-blue);
  }

  .navbar > button.selected > .img-container > img {
    filter: brightness(0) invert(1);
  }

  .navbar > button.selected > p {
    color: #fff;
  }

  .navbar > button > .f-key {
    display: none;
  }

  .navbar > button.launcher {
    display: none;
  }

  .navbar > button#navToggle {
    display: none;
  }

  .navbar > button#linkLauncherButton {
    display: flex;
  }

  /* --------------------App Launcher-------------------- */

  .app-launcher-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transform: translateX(0);
    transition: transform 0.3s;
  }

  .app-launcher-wrapper > .app-launcher {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .app-launcher-wrapper > .app-launcher > .header {
    display: flex;
    width: 100%;
    height: 72px;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1px solid var(--oliver-border);
  }

  .app-launcher-wrapper > .app-launcher > .header > button {
    width: 40px;
    height: 40px;
    margin: 0 8px;
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .app-launcher-wrapper > .app-launcher > .header > button > img {
    width: 10.64px;
    height: 18px;
  }

  .app-launcher-wrapper > .app-launcher > .header > p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .app-launcher-wrapper > .app-launcher > .body {
    width: 100%;
    height: 0;
    flex-grow: 1;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    background-color: #f1f9ff;
    box-sizing: border-box;
    padding: 20px;
  }

  .app-launcher-wrapper > .app-launcher > .body > img {
    width: 257px;
    height: 176px;
    margin: auto;
    display: none;
  }

  .app-launcher-wrapper > .app-launcher > .body > img:only-child {
    display: block;
  }

  .app-launcher-wrapper > .app-launcher > .body > button {
    width: 100%;
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .app-launcher-wrapper > .app-launcher > .body > button:last-of-type {
    margin-bottom: 0;
  }

  .app-launcher-wrapper > .app-launcher > .body > button > .img-container {
    width: 55px;
    height: 55px;
    border-radius: 10px;
    margin-right: 27px;
    overflow: hidden;
  }

  .app-launcher-wrapper
    > .app-launcher
    > .body
    > button
    > .img-container
    > img {
    width: 100%;
    height: 100%;
  }

  .app-launcher-wrapper > .app-launcher > .body > button > p {
    max-width: 100%;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .app-launcher-wrapper.hidden {
    transform: translateX(100%);
  }

  /* --------------------Link Launcher-------------------- */

  .link-launcher-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 6;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transform: translateX(0);
    transition: transform 0.3s;
  }

  .link-launcher-wrapper > .link-launcher {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .link-launcher-wrapper > .link-launcher > .header {
    display: flex;
    width: 100%;
    height: 72px;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1px solid var(--oliver-border);
  }

  .link-launcher-wrapper > .link-launcher > .header > button {
    width: 40px;
    height: 40px;
    margin: 0 8px;
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .link-launcher-wrapper > .link-launcher > .header > button > img {
    width: 10.64px;
    height: 18px;
  }

  .link-launcher-wrapper > .link-launcher > .header > p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .link-launcher-wrapper > .link-launcher > .body {
    width: 100%;
    height: 0;
    flex-grow: 1;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    background-color: #f1f9ff;
    box-sizing: border-box;
    padding: 20px;
  }

  .link-launcher-wrapper > .link-launcher > .body > img {
    width: 257px;
    height: 176px;
    margin: auto;
    display: none;
  }

  .link-launcher-wrapper > .link-launcher > .body > img:only-child {
    display: block;
  }

  .link-launcher-wrapper > .link-launcher > .body > button {
    width: 100%;
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .link-launcher-wrapper > .link-launcher > .body > button:last-of-type {
    margin-bottom: 0;
  }

  .link-launcher-wrapper > .link-launcher > .body > button > .img-container {
    width: 55px;
    height: 55px;
    border-radius: 10px;
    margin-right: 27px;
    overflow: hidden;
  }

  .link-launcher-wrapper
    > .link-launcher
    > .body
    > button
    > .img-container
    > img {
    width: 100%;
    height: 100%;
  }

  .link-launcher-wrapper > .link-launcher > .body > button > .col {
    width: calc(100% - 122px);
  }

  .link-launcher-wrapper > .link-launcher > .body > button > .col > p.style1 {
    max-width: 100%;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .link-launcher-wrapper > .link-launcher > .body > button > .col > p.style2 {
    max-width: 100%;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-base-grey);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .link-launcher-wrapper.hidden {
    transform: translateX(-100%);
  }

  /* --------------------User Info-------------------- */

  .user-info-wrapper {
    z-index: 5;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #f1f9ff;
    transform: translateY(100%);
    transition: transform 0.3s;
  }

  .navbar.open ~ .user-info-wrapper {
    transform: translateY(0);
  }

  .user-info-wrapper > .user-info {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }

  .user-info-wrapper > .user-info > .header {
    width: 100%;
    margin-bottom: 22px;
    display: flex;
    align-items: center;
  }

  .user-info-wrapper > .user-info > .header > .avatar {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    overflow: hidden;
    box-sizing: border-box;
    background-color: var(--oliver-base-blue);
    margin-right: 20px;
    font-size: 20px;
  }

  .user-info-wrapper > .user-info > .header > .avatar > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .user-info-wrapper > .user-info > .header > .avatar > img.default {
    display: none;
  }

  .user-info-wrapper > .user-info > .header > .avatar > img.default:only-child {
    display: block;
  }

  .user-info-wrapper > .user-info > .header > .col > p.style1 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .user-info-wrapper > .user-info > .header > .col > p.style2 {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .user-info-wrapper > .user-info > .body {
    width: 100%;
    flex-grow: 1;
  }

  .user-info-wrapper > .user-info > .body > .language-select {
    width: 80%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .user-info-wrapper > .user-info > .body > .language-select > p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-right: 10px;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper {
    position: relative;
    width: 0;
    flex-grow: 1;
    height: 35px;
    line-height: 0;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper:hover {
    cursor: pointer;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper:hover
    > img {
    filter: invert(43%) sepia(76%) saturate(756%) hue-rotate(168deg)
      brightness(103%) contrast(86%);
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > img {
    position: absolute;
    top: calc(50% - 7px);
    right: 12px;
    width: 14px;
    height: 14px;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper.open
    > img {
    transform: rotate(180deg);
    filter: invert(43%) sepia(76%) saturate(756%) hue-rotate(168deg)
      brightness(103%) contrast(86%);
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper.open
    > input {
    border-radius: 8px 8px 0 0;
    border-color: var(--oliver-blue);
    border-width: 2px;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > input {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid var(--oliver-base-grey);
    border-radius: 8px;
    outline: 0;
    padding: 0 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper:hover
    > input:hover {
    cursor: pointer;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > .option-container {
    width: 100%;
    max-height: 400%;
    overflow: auto;
    border-radius: 0 0 8px 8px;
    display: none;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper.open
    > .option-container {
    display: block;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > .option-container
    > .language-option {
    box-sizing: border-box;
    width: 100%;
    height: 35px;
    border: 1px solid var(--oliver-border);
    border-top: 0;
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 0 12px;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > .option-container
    > .language-option
    > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
    max-width: calc(100% - 16px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > .option-container
    > .language-option:hover {
    background-color: var(--oliver-blue);
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > .option-container
    > .language-option:hover
    > p {
    color: #fff;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > .option-container
    > .language-option:last-child {
    border-radius: 0 0 8px 8px;
  }

  .user-info-wrapper > .user-info > .body > button {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .user-info-wrapper > .user-info > .body > button:hover {
    color: var(--oliver-blue);
  }

  .user-info-wrapper
    > .user-info
    > .body
    > button:hover:not(:last-child)
    > .img-container
    > img {
    filter: invert(43%) sepia(76%) saturate(756%) hue-rotate(168deg)
      brightness(103%) contrast(86%);
  }

  .user-info-wrapper > .user-info > .body > button > .img-container {
    width: 55px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > button#knowledgeBaseButton
    > .img-container
    > img {
    width: 24px;
    height: 24px;
  }

  .user-info-wrapper > .user-info > .body > button#switchUserButton {
    margin-top: 10px;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > button#switchUserButton
    > .img-container
    > img {
    width: 16.96px;
    height: 17.5px;
  }

  .user-info-wrapper > .user-info > .body > button#endSessionButton {
    color: var(--oliver-red);
    margin-top: 10px;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > button#endSessionButton
    > .img-container
    > img {
    width: 24.06px;
    height: 24px;
  }

  .user-info-wrapper > .user-info > .footer {
    display: none;
  }

  /* --------------------Page Options-------------------- */

  .page-options-wrapper {
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;
    height: calc(100% - 70px);
    background-color: rgba(61, 76, 102, 0.4);
    z-index: 3;
  }

  .page-options-wrapper.hidden {
    z-index: 1;
    display: none;
  }

  .page-options-wrapper > .page-options {
    box-sizing: border-box;
    width: 100%;
    padding: 0 28px 16.5px 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 0 0 12px 12px;
  }

  .page-options-wrapper > .page-options > p {
    width: 100%;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-bottom: 12px;
  }

  .page-options-wrapper > .page-options > button {
    width: 100%;
    height: 47px;
    background-color: transparent;
    border: 0;
    border-radius: 8px;
    display: flex;
    align-items: center;
  }

  .page-options-wrapper > .page-options > button > .img-container {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 9px;
    position: relative;
  }

  .page-options-wrapper > .page-options > button > .img-container > img {
    width: 75%;
    height: 75%;
  }

  .page-options-wrapper
    > .page-options
    > button#notificationsButton
    > .img-container
    > img {
    width: 90%;
    height: 90%;
  }

  .page-options-wrapper
    > .page-options
    > button#notificationsButton
    > .img-container
    > img.highlight {
    filter: brightness(0) saturate(100%) invert(65%) sepia(43%) saturate(6218%)
      hue-rotate(180deg) brightness(94%) contrast(93%);
  }

  .page-options-wrapper
    > .page-options
    > button#customFeeDiscountButton
    > .img-container
    > img {
    width: 70%;
    height: 70%;
  }

  .page-options-wrapper
    > .page-options
    > button#addNoteButton
    > .img-container
    > img {
    width: 70%;
    height: 70%;
    transform: translateX(10%);
  }

  .page-options-wrapper
    > .page-options
    > button#clearCartButton
    > .img-container
    > img {
    width: 60%;
    height: 60%;
  }

  .page-options-wrapper > .page-options > button > .img-container > .badge {
    position: absolute;
    top: 0;
    right: 0;
    box-sizing: border-box;
    width: 50%;
    height: 50%;
    border-radius: 50%;
    border: 2px solid #fff;
    background-color: var(--oliver-blue);
  }

  .page-options-wrapper > .page-options > button > .img-container > .badge.red {
    background-color: var(--oliver-red);
  }

  .page-options-wrapper
    > .page-options
    > button
    > .img-container
    > .badge.green {
    background-color: var(--oliver-teal);
  }

  .page-options-wrapper
    > .page-options
    > button
    > .img-container
    > .badge.grey {
    background-color: #c4c4c4;
  }

  .page-options-wrapper > .page-options > button > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .page-options-wrapper > .page-options > button:disabled > p {
    color: var(--oliver-new-grey) !important;
  }

  .page-options-wrapper
    > .page-options
    > button:disabled
    > .img-container
    > img {
    filter: brightness(0) saturate(100%) invert(78%) sepia(14%) saturate(234%)
      hue-rotate(164deg) brightness(95%) contrast(92%) !important;
  }

  .page-options-wrapper > .page-options > button.hidden {
    display: none !important;
  }

  /* --------------------Notifications-------------------- */

  .notifications-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(123, 140, 152, 0.3);
    z-index: 5;
  }

  .notifications-wrapper.hidden {
    display: none;
  }

  .notifications-wrapper > .notifications {
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }

  .notifications-wrapper > .notifications > .noti-header {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;
    border-bottom: 1px solid var(--oliver-border);
  }

  .notifications-wrapper > .notifications > .noti-header > p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-right: auto;
  }

  .notifications-wrapper > .notifications > .noti-header > button {
    background-color: transparent;
    border: 0;
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #cfebff;
    border-radius: 8px;
    margin-left: 11px;
  }

  .notifications-wrapper
    > .notifications
    > .noti-header
    > button#notiSettingButton
    > img {
    width: 24px;
    height: 24px;
  }

  .notifications-wrapper
    > .notifications
    > .noti-header
    > button#mobileNotiExit
    > img {
    width: 12px;
    height: 12px;
  }

  .notifications-wrapper
    > .notifications.settings
    > .noti-header
    > button#notiSettingButton {
    background-color: var(--oliver-blue);
  }

  .notifications-wrapper
    > .notifications.settings
    > .noti-header
    > button#notiSettingButton
    > img {
    filter: brightness(0) invert(1);
  }

  .notifications-wrapper > .notifications > .noti-body {
    width: 100%;
    height: 0;
    flex-grow: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  .notifications-wrapper > .notifications > .noti-body > .date {
    width: 100%;
    box-sizing: border-box;
    padding: 9px 16px;
    background-color: #f1f4f6;
  }

  .notifications-wrapper > .notifications > .noti-body > .date > p {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    color: var(--oliver-base-grey);
  }

  .notifications-wrapper > .notifications > .noti-body > .notification-card {
    width: 100%;
    height: 76px;
    box-sizing: border-box;
    padding: 0 15px;
    display: flex;
    align-items: center;
    border-top: 1px solid var(--oliver-border);
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .date
    + .notification-card {
    border: 0;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .icon-wrapper {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .icon-wrapper
    > img {
    width: 32px;
    height: 32px;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .icon-wrapper.green {
    background-color: #dbf8f2;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .icon-wrapper.blue {
    background-color: #e7f4fe;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .icon-wrapper.red {
    background-color: #ffe7e9;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .icon-wrapper.red
    > img {
    width: 25px;
    height: 25px;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .icon-wrapper.grey {
    background-color: #f1f4f6;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .icon-wrapper.grey
    > img {
    width: 24px;
    height: 24px;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .col {
    width: 0;
    flex-grow: 1;
    box-sizing: border-box;
    padding-right: 12px;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .col
    > p {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .col
    > p.style1 {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    line-height: 17px;
    color: var(--oliver-base-blue);
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .col
    > p.style2 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: var(--oliver-base-grey);
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > p {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    text-align: center;
    color: var(--oliver-base-grey);
  }

  .notifications-wrapper > .notifications.settings > .noti-settings-background {
    position: fixed;
    top: 60px;
    background-color: rgba(61, 76, 102, 0.4);
    width: 100%;
    height: 100%;
  }

  .notifications-wrapper > .notifications > .noti-settings-wrapper {
    display: none;
    width: 100%;
    height: fit-content;
    flex-direction: column;
    position: absolute;
    background-color: transparent;
    top: 60px;
  }

  .notifications-wrapper > .notifications.settings > .noti-settings-wrapper {
    display: flex;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings {
    width: 100%;
    height: fit-content;
    border-radius: 0 0 15px 15px;
    overflow: auto;
    background-color: #fff;
    z-index: 2;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .header {
    width: 100%;
    box-sizing: border-box;
    padding: 12px 16px;
    background-color: #f1f4f6;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .header
    > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    color: var(--oliver-base-blue);
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 10px 16px;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px 0;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    display: flex;
    align-items: center;
    width: 200px;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label:hover {
    cursor: pointer;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > input {
    position: fixed;
    left: -10000px;
    opacity: 0;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > .toggle {
    position: relative;
    width: 64px;
    height: 32px;
    border-radius: 32px;
    background-color: var(--oliver-border);
    margin-left: auto;
    box-sizing: border-box;
    padding: 2px;
    display: flex;
    align-items: center;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > .toggle::before {
    content: "";
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > .toggle::after {
    content: "Off";
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--oliver-base-blue-dark);
    position: absolute;
    right: 8px;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > input:checked
    + .toggle {
    background-color: var(--oliver-blue);
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > input:checked
    + .toggle::before {
    right: 2px;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > input:checked
    + .toggle::after {
    content: "On";
    left: 8px;
    right: auto;
    color: #fff;
  }

  /* --------------------Homepage Header-------------------- */

  .homepage-wrapper > .header {
    z-index: 2;
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-top: 10.23px;
    background-color: #fff;
  }

  .homepage-wrapper > .header > .row {
    width: 100%;
    height: 60.46px;
    display: flex;
    align-items: center;
  }

  .homepage-wrapper > .header > .row > button#mobileNavToggle {
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 8px;
  }

  .homepage-wrapper > .header > .row > button#mobileNavToggle > img {
    content: url("assets/images/svg/Hamburger-Menu-Icon.svg");
    width: 20px;
    height: 13.33px;
  }

  .homepage-wrapper > .header > .row > button#mobileNavToggle.opened > img {
    content: url("assets/images/svg/Hamburger-Menu-Active-Icon.svg");
    width: 39px;
    height: 39px;
  }

  .homepage-wrapper > .header > .row > img {
    width: 64px;
    height: 18px;
    margin-right: auto;
  }

  .homepage-wrapper > .header > .row > button#searchButton {
    position: absolute;
    width: calc(100% - 32px);
    height: 46px;
    left: 16px;
    bottom: 15px;
    background-color: #d1ecff;
    border: 0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: var(--oliver-blue);
  }

  .homepage-wrapper > .header > .row > button#searchButton > div {
    display: none;
  }

  .homepage-wrapper > .header > .row > button#searchButton > img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  /* .homepage-wrapper > .header > .row > .options-container {
		display: ;
	} */

  .homepage-wrapper > .header > .row > button#userInfoButton {
    display: none;
  }

  .homepage-wrapper > .header > .row > button#mobileOptionsButton,
  .homepage-wrapper > .header > .row > button#mobileAppsButton {
    width: 34px;
    height: 34px;
    background-color: #d1ecff;
    border: 0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .homepage-wrapper > .header > .row > button#mobileOptionsButton {
    margin-right: 10px;
  }

  .homepage-wrapper > .header > .row > button#mobileOptionsButton > img {
    width: 29.79px;
    height: 29.79px;
  }

  .homepage-wrapper > .header > .row > button#mobileOptionsButton.filter {
    background-color: var(--oliver-blue);
  }

  .homepage-wrapper > .header > .row > button#mobileOptionsButton.filter > img {
    filter: brightness(0) invert(1);
  }

  .homepage-wrapper > .header > .row > button#mobileAppsButton {
    margin-right: 16px;
  }

  .homepage-wrapper > .header > .row > button#mobileAppsButton > img {
    width: 18.62px;
    height: 18.46px;
  }

  /* --------------------Homepage Products-------------------- */

  .homepage-wrapper > .products {
    height: 0;
    flex-grow: 1;
    width: 100%;
    box-sizing: border-box;
    padding: 4.266667% 4.266667% 0 4.266667%;
    overflow-y: scroll;
    background: linear-gradient(180deg, #eaf5fe 0%, #dbeefc 100%);
    display: flex;
    flex-direction: column;
  }

  .homepage-wrapper > .products > .change-row-count {
    width: 100%;
    display: none;
    align-items: center;
    /* justify-content: center; */
    margin-bottom: 16px;
  }

  .homepage-wrapper > .products.edit-screen > .change-row-count {
    display: flex;
  }

  .homepage-wrapper > .products > .change-row-count > p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .homepage-wrapper > .products > .change-row-count > button:not(#exitEditing) {
    width: 40px;
    height: 40px;
    border: 1px solid var(--oliver-blue);
    border-radius: 8px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 12px;
  }

  .homepage-wrapper > .products > .change-row-count > button:first-of-type {
    margin-left: 0 !important;
  }

  .homepage-wrapper > .products > .change-row-count > button > img {
    width: 13px;
    height: 28px;
  }

  .homepage-wrapper > .products > .change-row-count > button#exitEditing {
    height: 40px;
    padding: 0 12px;
    border: 1px solid var(--oliver-blue);
    background-color: transparent;
    border-radius: 8px;
    margin-left: auto;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    color: var(--oliver-blue);
  }

  .homepage-wrapper > .products > .category-header {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }

  .homepage-wrapper > .products > .category-header > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: var(--oliver-blue);
  }

  .homepage-wrapper > .products > .category-header > button {
    width: 120px;
    height: 30px;
    margin-left: auto;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    color: #fff;
  }

  .homepage-wrapper > .products > .category-header > button > img {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }

  .homepage-wrapper > .products > .products-container {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }

  .homepage-wrapper > .products > .products-container > .no-product-container {
    width: 100%;
    height: 100%;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > .no-product-container:only-child {
    display: flex;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > .no-product-container
    > img {
    width: 45px;
    height: 45px;
    margin-bottom: 13px;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > .no-product-container
    > p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    text-align: center;
    color: var(--oliver-blue);
    margin-bottom: 38px;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > .no-product-container
    > button {
    width: 140px;
    height: 37px;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid var(--oliver-blue);
    border-radius: 6px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .homepage-wrapper > .products > .products-container > button {
    position: relative;
    width: 47.8134109%;
    aspect-ratio: 1/1.29697;
    background-color: #1db9ca;
    margin: 0 4.3% 4.3% 0;
    box-sizing: border-box;
    border-radius: 8px;
    border: 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 6px 10px rgba(94, 94, 94, 0.1);
  }

  .homepage-wrapper > .products > .products-container > button:nth-of-type(2n) {
    margin-right: 0;
  }

  .homepage-wrapper > .products > .products-container > button.product > .body {
    display: flex;
    width: 100%;
    flex: 75;
    border-radius: 8px 8px 0 0;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button.product
    > .body
    > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button.product
    > .footer {
    width: 100%;
    flex: 32;
    background-color: #fff;
    border-radius: 0 0 8px 8px;
    box-sizing: border-box;
    border-top: 1px solid var(--oliver-border);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button.product
    > .footer
    > p {
    max-width: 90%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .homepage-wrapper > .products > .products-container > button.category {
    align-items: center;
    justify-content: center;
    background-color: #2797e8;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button.category
    > p.sub {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-align: center;
    color: #fff;
    margin-bottom: 20px;
    max-width: 80%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button.category
    > p.main {
    max-width: 80%;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #fff;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button.category.background-lime
    > p.sub,
  .homepage-wrapper
    > .products
    > .products-container
    > button.category.background-lime
    > p.main {
    color: var(--oliver-base-blue);
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button.category.background-yellow
    > p.sub,
  .homepage-wrapper
    > .products
    > .products-container
    > button.category.background-yellow
    > p.main {
    color: var(--oliver-base-blue);
  }

  .homepage-wrapper > .products > .products-container > button.add-tile {
    background-color: transparent;
    border: 2px dashed var(--oliver-new-grey);
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-new-grey);
    box-shadow: none;
  }

  .homepage-wrapper > .products > .products-container > button.add-tile > img {
    width: 20px;
    height: 20px;
    margin-right: 7px;
  }

  .homepage-wrapper > .products > .products-container > button > .remove-cover {
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(58, 128, 180, 0.4);
    border-radius: inherit;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button
    > .remove-cover
    > .remove-button {
    width: 14.5454%;
    aspect-ratio: 1/1;
    background-color: #fff;
    border-radius: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 4.8484%;
    top: 3.7383%;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button
    > .remove-cover
    > .remove-button
    > img {
    width: 41.6667%;
    aspect-ratio: 1/1;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button
    > .not-supported {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button
    > .not-supported
    > img {
    position: absolute;
    width: 28px;
    top: 12px;
    left: 12px;
    filter: brightness(0) saturate(100%) invert(28%) sepia(13%) saturate(1227%)
      hue-rotate(179deg) brightness(94%) contrast(91%);
  }

  /* --------------------Mobile Homepage Footer-------------------- */

  .mobile-homepage-footer {
    width: 100%;
    height: 89px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-homepage-footer > button {
    width: 91.4667%;
    height: 54px;
    border-radius: 8px;
    background-color: var(--oliver-blue);
    border: 0;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* --------------------Cart-------------------- */

  .cart {
    background-color: #fff;
    z-index: 5;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    transform: translateY(100%);
    transition: transform 0.3s;
  }

  .cart.open {
    transform: translateY(0);
  }

  .cart > .mobile-header {
    width: 100%;
    height: 62px;
    box-sizing: border-box;
    padding: 0 16px 0 24px;
    display: flex;
    align-items: center;
  }

  .cart > .mobile-header > p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-right: auto;
  }

  .cart > .mobile-header > button {
    width: 32px;
    height: 32px;
    border-radius: 6px;
    border: 0;
    background-color: #f1f4f6;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cart > .mobile-header > button > img {
    width: 12px;
    height: 12px;
  }

  .cart > .body {
    width: 100%;
    height: 0;
    flex-grow: 1;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 16px 12px 16px;
  }

  .cart > .body > img {
    display: none;
    width: 41.80554%;
    height: auto;
    margin: auto;
  }

  .cart > .body > img:only-child {
    display: block;
  }

  .cart > .body > .cart-item {
    box-sizing: border-box;
    width: 100%;
    padding: 12px 0;
    border-top: 1px solid var(--oliver-border);
    display: flex;
    flex-direction: column;
  }

  .cart > .body > .cart-item:nth-child(2) {
    border-top: 0;
  }

  .cart > .body > .cart-item > .main-row {
    width: 100%;
    display: flex;
  }

  .cart > .body > .cart-item > .main-row.aligned {
    align-items: center;
  }

  .cart > .body > .cart-item > .main-row > .quantity {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    margin-right: 15px;
    margin: 0 15px 0 8px;
    color: var(--oliver-blue);
  }

  .cart > .body > .cart-item > .main-row > .quantity:hover {
    text-decoration: underline;
  }

  .cart > .body > .cart-item > .main-row > .content-style {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue-dark);
    width: 0;
    flex-grow: 1;
    padding-right: 10px;
  }

  .cart > .body > .cart-item > .main-row > .content-style:hover {
    text-decoration: underline;
    color: var(--oliver-blue);
  }

  .cart > .body > .cart-item > .main-row > .content-style.line-capped {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .cart > .body > .cart-item > .main-row > .price {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: right;
    color: var(--oliver-base-blue);
    margin-right: 12.63px;
  }

  .cart > .body > .cart-item > .main-row > button.remove-cart-item {
    width: 18px;
    height: 18px;
    border: 0;
    background-color: transparent;
    margin-right: 6.63px;
  }

  .cart > .body > .cart-item > .main-row > button.remove-cart-item > img {
    width: 100%;
    height: 100%;
  }

  .cart > .body > .cart-item > .main-row > button.remove-cart-item:hover > img {
    cursor: pointer;
    filter: brightness(0) saturate(100%) invert(48%) sepia(98%) saturate(1963%)
      hue-rotate(322deg) brightness(96%) contrast(111%);
  }

  .cart > .body > .cart-item > .main-row > .tag {
    padding: 5.5px 11px;
    background-color: rgba(39, 151, 232, 0.2);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-right: 14px;
  }

  .cart > .body > .cart-item > .main-row > .tag.customer {
    background-color: rgba(39, 151, 232, 0.2);
  }

  .cart > .body > .cart-item > .main-row > .tag.group {
    background-color: rgba(165, 104, 188, 0.2);
  }

  .cart > .body > .cart-item > .main-row > .tag.custom-fee {
    background-color: rgba(255, 208, 38, 0.2);
  }

  .cart > .body > .cart-item > .main-row > .tag.cart-note {
    background-color: rgba(255, 133, 82, 0.2);
  }

  .cart > .body > .cart-item > .secondary-col {
    display: flex;
    flex-direction: column;
    padding-left: 32px;
    padding: 0 34px 0 32px;
    margin-top: 10px;
  }

  .cart > .body > .cart-item > .secondary-col > p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .cart > .body > .cart-item > .secondary-col > p > b {
    font-weight: 600;
  }

  .cart > .body > .cart-item > .secondary-col > .row {
    display: flex;
    width: 100%;
  }

  .cart > .body > .cart-item > .secondary-col > .row > p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-base-grey);
    flex: 1;
  }

  .cart > .body > .cart-item > .secondary-col > .row > p:last-of-type {
    text-align: right;
  }

  .cart > .footer {
    width: 100%;
    background-color: #f8fafc;
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--oliver-border);
  }

  .cart > .footer > .totals {
    box-sizing: border-box;
    width: 100%;
    padding: 15px;
  }

  .cart > .footer > .totals > .row {
    width: 100%;
    display: flex;
    margin-top: 7px;
  }

  .cart > .footer > .totals > .row:nth-child(1) {
    margin-top: 0;
  }

  .cart > .footer > .totals > .row > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .cart > .footer > .totals > .row > p > b {
    font-weight: 500;
  }

  .cart > .footer > .totals > .row > p:first-of-type {
    margin-right: 7px;
  }

  .cart > .footer > .totals > .row > p:last-of-type {
    margin-left: auto;
  }

  .cart > .footer > .totals > .row > button#editCartDiscount {
    background-color: transparent;
    border: 0;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-blue);
  }

  .cart > .footer > .totals > .row > button#taxesButton {
    background-color: transparent;
    border: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--oliver-blue);
    margin-right: 4px;
  }

  .cart > .footer > .checkout-container {
    box-sizing: border-box;
    width: 100%;
    border-top: 1px solid var(--oliver-border);
    height: 84px;
    line-height: 0;
  }

  .cart > .footer > .checkout-container > button {
    width: 100%;
    height: 100%;
    border: 0;
    background-color: transparent;
    padding: 15px;
  }

  .cart > .footer > .checkout-container > button > p {
    background-color: var(--oliver-blue);
    width: 100%;
    height: 100%;
    border-radius: 8px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* --------------------Homepage-------------------- */

  .homepage-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .homepage-wrapper > .header {
    width: 100%;
    height: 135px;
    box-sizing: border-box;
    border-bottom: 1px solid var(--oliver-border);
  }

  .homepage-wrapper > .products {
    width: 100%;
    height: 0;
    flex-grow: 1;
  }

  .homepage-wrapper > .navbar {
    position: fixed;
    left: 0;
    top: 72px;
    width: 100%;
    height: calc(100% - 72px);
  }

  /* --------------------Product-------------------- */

  .product-wrapper {
    position: relative;
    width: 100%;
    height: calc(100% - 144px);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }

  /* Product Header */

  .product-wrapper > .header {
    box-sizing: border-box;
    width: 100%;
    z-index: 2;
  }

  .product-wrapper > .header > .mobile-buttons {
    width: 100%;
    height: 281px;
    box-sizing: border-box;
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
  }

  .product-wrapper > .header > .mobile-buttons > button {
    width: 34px;
    height: 34px;
    background-color: rgba(209, 236, 255, 0.5);
    border: 0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product-wrapper
    > .header
    > .mobile-buttons
    > button#mobileExitProductButton
    > img {
    width: 12px;
    height: 12px;
  }

  .product-wrapper > .header > .mobile-buttons > button#mobileAppsButton > img {
    width: 18.62px;
    height: 18.62px;
  }

  .product-wrapper > .header > .main {
    width: 100%;
    box-sizing: border-box;
    padding: 26px 16px;
    display: flex;
    flex-direction: column;
  }

  .product-wrapper > .header > .main > p.route {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 13px;
    text-align: left;
    color: var(--oliver-blue);
    margin-bottom: 12px;
  }

  .product-wrapper > .header > .main > p.prod-name {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .product-wrapper > .header > .main > button#desktopExitProductButton {
    display: none;
  }

  /* Mod Products */

  .product-wrapper > .mod-product {
    width: 100%;
    box-sizing: border-box;
    padding: 0 0 7px 16px;
    position: relative;
  }

  .product-wrapper.no-edit > .mod-product {
    padding-top: 152px;
  }

  .product-wrapper > .mod-product > .no-edit-item {
    display: none;
  }

  .product-wrapper.no-edit > .mod-product > .no-edit-item {
    display: flex;
    position: absolute;
    width: calc(100% + 16px);
    height: 100%;
    background-color: rgba(241, 244, 246, 0.3);
    left: -16px;
    top: 0;
    justify-content: center;
  }

  .product-wrapper.no-edit > .mod-product > .no-edit-item > .inner-container {
    width: min(calc(100% - 32px), 343px);
    height: 128px;
    background-color: #f1f4f6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin-top: 8px;
    overflow: auto;
  }

  .product-wrapper.no-edit
    > .mod-product
    > .no-edit-item
    > .inner-container
    > p.style1 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 20px;
  }

  .product-wrapper.no-edit
    > .mod-product
    > .no-edit-item
    > .inner-container
    > p.style2 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .product-wrapper > .mod-product > .row > button {
    display: none;
  }

  .product-wrapper > .mod-product > .img-container {
    display: none;
  }

  .product-wrapper > .mod-product > .row > p {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-bottom: 12px;
  }

  .product-wrapper > .mod-product > p {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    line-height: 13px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 8px;
  }

  .product-wrapper > .mod-product > p.error::after {
    content: "*Select a variation";
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    text-align: left;
    color: var(--error);
    margin-left: 10px;
  }

  .product-wrapper > .mod-product > .radio-group {
    width: calc(100% + 16px);
    margin-left: -16px;
    display: flex;
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    margin-bottom: 28px;
  }

  .product-wrapper > .mod-product > .radio-group::-webkit-scrollbar {
    display: none;
  }

  .product-wrapper > .mod-product > .radio-group > label {
    margin-right: 16px;
  }

  .product-wrapper > .mod-product > .radio-group > label:nth-child(1) {
    margin-left: 16px;
  }

  .product-wrapper > .mod-product > .radio-group > label > input {
    opacity: 0;
    position: fixed;
    left: -10000px;
  }

  .product-wrapper > .mod-product > .radio-group > label > .custom-radio {
    padding: 14px;
    border: 1.5px solid var(--oliver-border);
    border-radius: 10px;
  }

  .product-wrapper > .mod-product > .radio-group > label > .custom-radio > p {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    line-height: 13px;
    text-align: left;
    color: var(--oliver-base-blue);
    max-width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .product-wrapper
    > .mod-product
    > .radio-group
    > label
    > input:checked
    ~ .custom-radio {
    border: 2px solid var(--oliver-blue);
  }

  .product-wrapper
    > .mod-product
    > .radio-group
    > label
    > input:checked
    ~ .custom-radio
    > p {
    color: var(--oliver-blue);
  }

  .product-wrapper > .mod-product > .radio-group.error > label > .custom-radio {
    border-color: var(--error);
  }

  .product-wrapper > .mod-product > input {
    width: min(300px, 100%);
    box-sizing: border-box;
    padding: 0 16px;
    height: 40px;
    border: 1px solid var(--oliver-border);
    border-radius: 8px;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 28px;
  }

  .product-wrapper > .mod-product > input::placeholder {
    color: var(--oliver-borders);
  }

  .product-wrapper > .mod-product > input:focus {
    border: 2px solid var(--oliver-blue);
  }

  .product-wrapper > .mod-product > .increment-input {
    display: flex;
    align-items: center;
    gap: 11px;
    margin-bottom: 28px;
  }

  .product-wrapper > .mod-product > .increment-input > button {
    width: 40px;
    height: 40px;
    border: 0;
    border-radius: 8px;
    background-color: #ddf1ff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product-wrapper > .mod-product > .increment-input > button > img {
    width: 16px;
    height: 30px;
  }

  .product-wrapper > .mod-product > .increment-input > input {
    width: 80px !important;
    height: 40px;
    box-sizing: border-box;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-blue);
    width: fit-content;
    border: 1px solid var(--oliver-border);
    border-radius: 8px;
  }

  /* Detailed Product */

  .product-wrapper > .detailed-body {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .product-wrapper > .detailed-product > .row {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 30px 0;
    margin: 0 16px;
    border-top: 1px solid var(--oliver-border);
    border-bottom: 1px solid var(--oliver-border);
  }

  .product-wrapper > .detailed-product > .row > .col > p.mobile-only {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 8px;
  }

  .product-wrapper > .detailed-product > .row > .col > .group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .product-wrapper > .detailed-product > .row > .col > .group > .text-row {
    display: flex;
  }

  .product-wrapper > .detailed-product > .row > .col > .group > .text-row > p {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 13px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .product-wrapper
    > .detailed-product
    > .row
    > .col
    > .group
    > .text-row
    > p:first-of-type {
    margin-right: 4px;
  }

  .product-wrapper > .detailed-product > .row > .col > .group > button {
    margin-top: -4px;
    background-color: transparent;
    border: 0;
    padding: 4px 2px;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 13px;
    text-align: left;
    color: var(--oliver-blue);
    text-decoration: underline;
  }

  .product-wrapper
    > .detailed-product
    > .row
    > .col
    > .group
    > button:disabled {
    color: var(--oliver-border);
  }

  .product-wrapper > .detailed-product > .row > .col > .group > .desktop-only {
    display: none;
  }

  .product-wrapper > .detailed-product > .row > .col > button {
    width: 151px;
    height: 35px;
    background-color: #fff;
    border: 1px solid var(--oliver-blue);
    border-radius: 8px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-blue);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product-wrapper > .detailed-product > .row > .col > button > img {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }

  .product-wrapper > .detailed-product > .col {
    display: flex;
    flex-direction: column;
    margin: 0 16px;
    padding: 30px 0;
    border-bottom: 1px solid var(--oliver-border);
  }

  .product-wrapper > .detailed-product > .col > p.title {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 7px;
  }

  .product-wrapper > .detailed-product > .col > p.para {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-bottom: 21px;
  }

  .product-wrapper > .detailed-product > .col > p:last-of-type {
    margin-bottom: 0;
  }

  /* Product Footer */

  .product-wrapper > .product-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 144px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 16px;
    border-top: 1px solid var(--oliver-border);
  }

  .product-wrapper > .product-footer > .row:nth-child(1) {
    position: absolute;
    width: calc(100% - 32px);
    display: flex;
  }

  .product-wrapper > .product-footer > .row > button#addProductNote {
    width: calc(100% - 160px);
    height: 40px;
    background-color: #fff;
    border: 1px solid var(--oliver-blue);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .product-wrapper > .product-footer > .row > button#addProductNote > img {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    filter: brightness(0) saturate(100%) invert(50%) sepia(43%) saturate(2356%)
      hue-rotate(180deg) brightness(95%) contrast(91%);
  }

  .product-wrapper > .product-footer > .row > button#addProductDiscount {
    display: none;
  }

  .product-wrapper > .product-footer > .row > button#addProductToCart {
    position: absolute;
    bottom: 16px;
    width: calc(100% - 32px);
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 8px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  .product-wrapper > .product-footer > .row > .increment-input {
    position: absolute;
    right: 16px;
    width: 140px;
    display: flex;
  }

  .product-wrapper > .product-footer > .row > .increment-input > button {
    width: 40px;
    height: 40px;
    background-color: #edf1f4;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    border-radius: 8px;
  }

  .product-wrapper
    > .product-footer
    > .row
    > .increment-input
    > button:first-of-type
    > img {
    width: 14px;
    height: 30px;
  }

  .product-wrapper
    > .product-footer
    > .row
    > .increment-input
    > button:last-of-type
    > img {
    width: 16px;
    height: 30px;
  }

  .product-wrapper > .product-footer > .row > .increment-input > input {
    width: 0;
    flex-grow: 1;
    border: 0;
    outline: 0;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-base-blue);
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 8px;
    margin: 0 5px;
  }

  .product-wrapper > .product-footer > .row > button#addProductToCart > img {
    width: 29px;
    height: 29px;
    margin-right: 8px;
  }

  /* Product Image */

  .product-wrapper .product-image-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 281px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product-wrapper .product-image-container > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .product-wrapper .product-image-container > img.no-image {
    display: none;
    width: 100%;
    height: 100%;
  }

  .product-wrapper .product-image-container > img.no-image:only-child {
    display: block;
  }

  /* Recommended Upsells */

  .product-wrapper > .recommended-upsells {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 22px 0;
  }

  .product-wrapper > .recommended-upsells > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin: 0 0 10px 16px;
  }

  .product-wrapper > .recommended-upsells > .button-row {
    width: 100%;
    display: flex;
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .product-wrapper > .recommended-upsells > .button-row::-webkit-scrollbar {
    display: none;
  }

  .product-wrapper > .recommended-upsells > .button-row > button {
    width: 129px;
    height: 129px;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    border: 1px solid var(--oliver-border);
    border-radius: 8px;
    overflow: hidden;
    margin-right: 22px;
  }

  .product-wrapper > .recommended-upsells > .button-row > button:first-of-type {
    margin-left: 16px;
  }

  .product-wrapper > .recommended-upsells > .button-row > button:last-of-type {
    margin-right: 16px;
  }

  .product-wrapper
    > .recommended-upsells
    > .button-row
    > button
    > .img-container {
    width: 100%;
    height: 0;
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product-wrapper
    > .recommended-upsells
    > .button-row
    > button
    > .img-container
    > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .product-wrapper > .recommended-upsells > .button-row > button > .prod-name {
    box-sizing: border-box;
    width: 100%;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid var(--oliver-border);
  }

  .product-wrapper
    > .recommended-upsells
    > .button-row
    > button
    > .prod-name
    > p {
    max-width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  /* --------------------Checkout-------------------- */

  .checkout-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  /* Checkout Header */

  .checkout-wrapper > .header {
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    border-bottom: 1px solid var(--oliver-border);
    display: flex;
    align-items: center;
    padding: 0 13px;
  }

  .checkout-wrapper > .header > button#exitCheckoutButton {
    width: 26px;
    height: 26px;
    background-color: transparent;
    border: none;
    margin-right: 17px;
    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .checkout-wrapper > .header > button#exitCheckoutButton > img {
    content: url("assets/images/svg/LeftAngledBracket-Blue.svg");
    width: 100%;
    height: 100%;
  }

  .checkout-wrapper > .header > button#exitCheckoutButton:disabled > img {
    filter: brightness(0) saturate(100%) invert(78%) sepia(14%) saturate(234%)
      hue-rotate(164deg) brightness(95%) contrast(92%);
  }

  .checkout-wrapper > .header > p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-right: auto;
  }

  .checkout-wrapper > .header > button#userInfoButton {
    display: none;
  }

  .checkout-wrapper > .header > button#mobileOptionsButton {
    width: 34px;
    height: 34px;
    background-color: #d1ecff;
    border: 0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  .checkout-wrapper > .header > button#mobileOptionsButton.filter {
    background-color: var(--oliver-blue);
  }

  .checkout-wrapper > .header > button#mobileOptionsButton > img {
    width: 29.79px;
    height: 29.79px;
  }

  .checkout-wrapper > .header > button#mobileOptionsButton.filter > img {
    filter: brightness(0) invert(1);
  }

  .checkout-wrapper > .header > button#mobileAppsButton {
    width: 34px;
    height: 34px;
    background-color: #d1ecff;
    border: 0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .checkout-wrapper > .header > button#mobileAppsButton > img {
    width: 18.62px;
    height: 18.46px;
  }

  /* Checkout Page Options */

  .checkout-wrapper > .page-options-wrapper {
    top: 59px;
  }

  /* Checkout Cart */

  .checkout-wrapper > .cart {
    display: none;
  }

  /* Checkout Body */

  .checkout-wrapper > .checkout-body {
    width: 100%;
    height: 0;
    flex-grow: 1;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-top: 10px;
  }

  .checkout-wrapper > .checkout-body > .balance-container {
    width: 338px;
    border: 1px solid var(--oliver-border);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 18px 20px;
    background-color: transparent;
    margin-bottom: 5px;
  }

  .checkout-wrapper > .checkout-body > .balance-container:hover {
    border-color: var(--oliver-blue);
    cursor: pointer;
  }

  .checkout-wrapper > .checkout-body > .balance-container > .row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .checkout-wrapper > .checkout-body > .balance-container p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .checkout-wrapper > .checkout-body > .balance-container p.style2 {
    font-family: Poppins;
    font-size: 22px;
    font-weight: 600;
    line-height: 31px;
    text-align: left;
    color: var(--oliver-blue);
  }

  .checkout-wrapper > .checkout-body > .balance-container p.style3 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-grey);
  }

  .checkout-wrapper > .checkout-body > .balance-container p.style4 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .checkout-wrapper > .checkout-body > .balance-container:hover {
    border-width: 2px;
    border-color: var(--oliver-blue);
  }

  .checkout-wrapper > .checkout-body button#bottomText {
    padding-top: 5px;
    width: 100%;
    background-color: transparent;
    border: 0;
    margin-bottom: 24px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-grey);
  }

  .checkout-wrapper > .checkout-body button#bottomText:hover {
    color: var(--oliver-blue);
  }

  .checkout-wrapper > .checkout-body > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
    width: 338px;
    margin-bottom: 9px;
  }

  .checkout-wrapper > .checkout-body > .button-row {
    display: flex;
    width: 338px;
    margin-bottom: 15px;
  }

  .checkout-wrapper > .checkout-body > .button-row > button {
    flex: 1;
    height: 40px;
    margin-left: 13px;
    background-color: transparent;
    border: 1px solid var(--oliver-border);
    border-radius: 8px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .checkout-wrapper > .checkout-body > .button-row > button:disabled {
    background-color: #f8fafc;
    color: #b0bec9;
  }

  .checkout-wrapper
    > .checkout-body
    > .button-row
    > button:not(:disabled):hover {
    border-color: var(--oliver-blue);
    border-width: 2px;
    color: var(--oliver-blue);
  }

  .checkout-wrapper > .checkout-body > .button-row > button:first-of-type {
    margin-left: 0;
  }

  .checkout-wrapper > .checkout-body > .button-row + p.style2 {
    padding-top: 7px;
  }

  .checkout-wrapper > .checkout-body > p.style3 {
    display: none;
  }

  .checkout-wrapper > .checkout-body > .payment-types {
    margin-top: 29px;
    width: 100%;
    flex-grow: 1;
    background-color: #f1f9ff;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .checkout-wrapper > .checkout-body > .payment-types > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 20px;
  }

  .checkout-wrapper > .checkout-body > .payment-types > .button-container {
    width: 338px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }

  .checkout-wrapper
    > .checkout-body
    > .payment-types
    > .button-container
    > button {
    width: 104px;
    height: 104px;
    margin: 0 13px 13px 0;
    border: 0;
    border-radius: 8px;
    background-color: transparent;
    overflow: hidden;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  .checkout-wrapper
    > .checkout-body
    > .payment-types
    > .button-container
    > button:nth-child(3n) {
    margin-right: 0;
  }

  .checkout-wrapper
    > .checkout-body
    > .payment-types
    > .button-container
    > button
    > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* Navbar */

  .checkout-wrapper > .navbar {
    display: none;
  }

  /* User Info Wrapper */

  .checkout-wrapper > .user-info-wrapper {
    display: none;
  }

  /* Nav cover */

  .checkout-wrapper > .nav-cover {
    display: none;
  }

  /* --------------------Refund-------------------- */

  .refund-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  /* Refund Header */

  .refund-wrapper > .header {
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    border-bottom: 1px solid var(--oliver-border);
    display: flex;
    align-items: center;
    padding: 0 13px;
  }

  .refund-wrapper > .header > button#exitCheckoutButton {
    width: 26px;
    height: 26px;
    background-color: transparent;
    border: none;
    margin-right: 17px;
  }

  .refund-wrapper > .header > button#exitCheckoutButton > img {
    content: url("assets/images/svg/LeftAngledBracket-Blue.svg");
    width: 100%;
    height: 100%;
  }

  .refund-wrapper > .header > p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-right: auto;
  }

  .refund-wrapper > .header > button#userInfoButton {
    display: none;
  }

  .refund-wrapper > .header > button#mobileOptionsButton {
    width: 34px;
    height: 34px;
    background-color: #d1ecff;
    border: 0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  .refund-wrapper > .header > button#mobileOptionsButton.filter {
    background-color: var(--oliver-blue);
  }

  .refund-wrapper > .header > button#mobileOptionsButton > img {
    width: 29.79px;
    height: 29.79px;
  }

  .refund-wrapper > .header > button#mobileOptionsButton.filter > img {
    filter: brightness(0) invert(1);
  }

  .refund-wrapper > .header > button#mobileAppsButton {
    width: 34px;
    height: 34px;
    background-color: #d1ecff;
    border: 0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .refund-wrapper > .header > button#mobileAppsButton > img {
    width: 18.62px;
    height: 18.46px;
  }

  /* Refund Page Options */

  .refund-wrapper > .page-options-wrapper {
    top: 59px;
  }

  /* Refund Cart */

  .refund-wrapper > .cart {
    display: none;
  }

  /* Refund Body */

  .refund-wrapper > .refund-body {
    width: 100%;
    height: 0;
    flex-grow: 1;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-top: 10px;
  }

  .refund-wrapper > .refund-body > .balance-container {
    width: 338px;
    border: 1px solid var(--oliver-border);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 18px 20px;
    background-color: transparent;
    margin-bottom: 32px;
  }

  .refund-wrapper > .refund-body > .balance-container > .row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .refund-wrapper > .refund-body > .balance-container p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .refund-wrapper > .refund-body > .balance-container p.style2 {
    font-family: Poppins;
    font-size: 22px;
    font-weight: 600;
    line-height: 31px;
    text-align: left;
    color: var(--oliver-blue);
  }

  .refund-wrapper > .refund-body > .balance-container p.style3 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-grey);
  }

  .refund-wrapper > .refund-body > .balance-container p.style4 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .refund-wrapper > .refund-body > button#bottomText {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-grey);
    margin-bottom: 24px;
    width: 100%;
    padding-top: 5px;
    background-color: transparent;
    border: 0;
  }

  .refund-wrapper > .refund-body > button#bottomText:hover {
    color: var(--oliver-blue);
  }

  .refund-wrapper > .refund-body > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
    width: 338px;
    margin-bottom: 9px;
  }

  .refund-wrapper > .refund-body > .button-row {
    display: flex;
    width: 338px;
    margin-bottom: 15px;
  }

  .refund-wrapper > .refund-body > .button-row > button {
    flex: 1;
    height: 40px;
    margin-left: 13px;
    background-color: transparent;
    border: 1px solid var(--oliver-border);
    border-radius: 8px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .refund-wrapper > .refund-body > .button-row > button:disabled {
    background-color: #f8fafc;
    color: #b0bec9;
  }

  .refund-wrapper > .refund-body > .button-row > button:not(:disabled):hover {
    border-width: 2px;
    border-color: var(--oliver-blue);
    color: var(--oliver-blue);
  }

  .refund-wrapper > .refund-body > .button-row > button:first-of-type {
    margin-left: 0;
  }

  .refund-wrapper > .refund-body > .button-row + p.style2 {
    padding-top: 7px;
  }

  .refund-wrapper > .refund-body > p.style3 {
    display: none;
  }

  .refund-wrapper > .refund-body > .payment-types {
    margin-top: 29px;
    width: 100%;
    background-color: #f1f9ff;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
  }

  .refund-wrapper > .refund-body > .payment-types > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 20px;
  }

  .refund-wrapper > .refund-body > .payment-types > .button-container {
    width: 338px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }

  .refund-wrapper > .refund-body > .payment-types > .button-container > button {
    width: 104px;
    height: 104px;
    margin: 0 13px 13px 0;
    border: 0;
    border-radius: 8px;
    background-color: transparent;
    overflow: hidden;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  .refund-wrapper
    > .refund-body
    > .payment-types
    > .button-container
    > button:nth-child(3n) {
    margin-right: 0;
  }

  .refund-wrapper
    > .refund-body
    > .payment-types
    > .button-container
    > button
    > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* Navbar */

  .refund-wrapper > .navbar {
    display: none;
  }

  /* User Info Wrapper */

  .refund-wrapper > .user-info-wrapper {
    display: none;
  }

  /* Nav cover */

  .refund-wrapper > .nav-cover {
    display: none;
  }

  /* --------------------Cash Management-------------------- */

  .cash-management-wrapper {
    width: 100%;
    height: 100%;
  }

  /* --------------------Sale Complete-------------------- */

  .sale-complete-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--oliver-blue);
    overflow-y: scroll;
    box-sizing: border-box;
    padding: 20px;
  }

  .sale-complete-wrapper > .main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
  }

  .sale-complete-wrapper > .main > img {
    width: 50px;
    height: 50px;
    margin-bottom: 18px;
  }

  .sale-complete-wrapper > .main > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: #fff;
    margin-bottom: 8px;
  }

  .sale-complete-wrapper > .main > #loadingDiv {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
  }

  .sale-complete-wrapper > .main > #loadingDiv.hidden {
    display: none;
  }

  .sale-complete-wrapper > .main > #loadingDiv > img {
    width: 23px;
    height: 23px;
    margin-right: 8px;
  }

  .sale-complete-wrapper > .main > #loadingDiv > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: #fff;
  }

  .sale-complete-wrapper > .main > button#openUpdateTransactionStatus {
    border: 0;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    text-align: center;
    color: #fff;
  }

  .sale-complete-wrapper > .main > button#openUpdateTransactionStatus:hover {
    background-color: rgba(16, 123, 201, 0.8);
  }

  .sale-complete-wrapper > .main > button#openUpdateTransactionStatus.hidden {
    display: none;
  }

  .sale-complete-wrapper > .main > button#openUpdateTransactionStatus > img {
    width: 20px;
    height: 20px;
    margin-left: 8px;
    transform: rotate(180deg);
  }

  .sale-complete-wrapper > .main > .change-container {
    width: 290px;
    height: 104px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 10px;
    margin-bottom: 34px;
  }

  .sale-complete-wrapper > .main > .change-container > p.style1 {
    font-family: Poppins;
    font-size: 22px;
    font-weight: 500;
    line-height: 29px;
    text-align: center;
    color: #0079cf;
    margin-bottom: 7px;
  }

  .sale-complete-wrapper > .main > .change-container > p.style2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: #0079cf;
  }

  .sale-complete-wrapper > .main > label {
    display: none;
  }

  .sale-complete-wrapper > .main > button {
    width: 200px;
    height: 50px;
    box-sizing: border-box;
    border: 1px solid #fff;
    border-radius: 6px;
    background-color: transparent;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  .sale-complete-wrapper > .main > button#emailSubwindowButton {
    margin: 16px 0 34px 0;
  }

  .sale-complete-wrapper > .footer {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: auto;
  }

  .sale-complete-wrapper > .footer > .button-container > button#newSale {
    width: 292px;
    height: 55px;
    border-radius: 6px;
    border: 0;
    background-color: #fff;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 40px;
  }

  .sale-complete-wrapper > .footer > .button-container > button#endSession {
    width: 147px;
    height: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #fff;
  }

  .sale-complete-wrapper
    > .footer
    > .button-container
    > button#endSession
    > img {
    width: 26px;
    height: 22px;
    margin-right: 5px;
  }

  .sale-complete-wrapper > .footer > .app-container {
    max-width: 280px;
    max-height: 140px;
    padding: 15px 0 0 15px;
    display: flex;
    flex-wrap: wrap;
    background-color: rgba(16, 123, 201, 0.8);
    margin-bottom: 35px;
    border-radius: 16px;
    overflow-y: auto;
  }

  .sale-complete-wrapper > .footer > .app-container > button {
    width: 55px;
    height: 55px;
    background-color: transparent;
    border: 0;
    border-radius: 7px;
    overflow: hidden;
    margin: 0 15px 15px 0;
  }

  .sale-complete-wrapper > .footer > .app-container > button > img {
    width: 100%;
    height: 100%;
  }

  /* --------------------Refund Complete-------------------- */

  .refund-complete-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--oliver-red);
    overflow-y: scroll;
    box-sizing: border-box;
    padding: 20px;
  }

  .refund-complete-wrapper > .main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
  }

  .refund-complete-wrapper > .main > img {
    width: 165px;
    height: 93px;
    margin: 37px 0 28px 0;
  }

  .refund-complete-wrapper > .main > label {
    display: none;
  }

  .refund-complete-wrapper > .main > button {
    width: 200px;
    height: 50px;
    box-sizing: border-box;
    border: 1px solid #fff;
    border-radius: 6px;
    background-color: transparent;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  .refund-complete-wrapper > .main > button#emailSubwindowButton {
    margin: 16px 0 34px 0;
  }

  .refund-complete-wrapper > .footer {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: auto;
  }

  .refund-complete-wrapper > .footer > .button-container > button#newSale {
    width: 292px;
    height: 55px;
    border-radius: 6px;
    border: 0;
    background-color: #fff;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 40px;
  }

  .refund-complete-wrapper > .footer > .button-container > button#endSession {
    width: 147px;
    height: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 6px;
    margin-bottom: 34px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #fff;
  }

  .refund-complete-wrapper
    > .footer
    > .button-container
    > button#endSession
    > img {
    width: 26px;
    height: 22px;
    margin-right: 5px;
  }

  .refund-complete-wrapper > .footer > .app-container {
    max-width: 280px;
    max-height: 140px;
    padding: 15px 0 0 15px;
    display: flex;
    flex-wrap: wrap;
    background-color: #ff828b;
    margin-bottom: 35px;
    border-radius: 16px;
    overflow-y: auto;
  }

  .refund-complete-wrapper > .footer > .app-container > button {
    width: 55px;
    height: 55px;
    background-color: transparent;
    border: 0;
    border-radius: 7px;
    overflow: hidden;
    margin: 0 15px 15px 0;
  }

  .refund-complete-wrapper > .footer > .app-container > button > img {
    width: 100%;
    height: 100%;
  }

  /* --------------------Customer View-------------------- */

  .customer-view-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  /* Navbar Settings	 */

  .customer-view-wrapper > .navbar {
    top: 60px;
    width: 100%;
    height: calc(100% - 60px);
    padding-bottom: 10px;
  }

  /* Mobile Header */

  .customer-view-wrapper > .mobile-cv-header {
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    padding: 0 16px 0 8px;
    display: flex;
    align-items: center;
  }

  .mobile-cv-header > #mobileNavToggle {
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }

  .mobile-cv-header > #mobileNavToggle > img {
    content: url("assets/images/svg/Hamburger-Menu-Icon.svg");
    width: 20px;
    height: 13.33px;
  }

  .mobile-cv-header > #mobileNavToggle.opened > img {
    content: url("assets/images/svg/Hamburger-Menu-Active-Icon.svg");
    width: 39px;
    height: 39px;
  }

  .mobile-cv-header > p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-right: auto;
  }

  .mobile-cv-header > #mobileCVSearchButton {
    width: 34px;
    height: 34px;
    background-color: #d1ecff;
    border: 0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  .mobile-cv-header > #mobileCVSearchButton > img {
    width: 21px;
    height: 21px;
  }

  .mobile-cv-header > #mobileAppsButton {
    width: 34px;
    height: 34px;
    background-color: #d1ecff;
    border: 0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-cv-header > #mobileAppsButton > img {
    width: 18.62px;
    height: 18.46px;
    transform: translateX(1px);
  }

  .mobile-cv-header > button.filter {
    background-color: var(--oliver-blue) !important;
  }

  .mobile-cv-header > button.filter > img {
    filter: brightness(0) invert(1);
  }

  /* Customer View List */

  .cv-list {
    width: 100%;
    height: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--oliver-border);
  }

  .cv-list > .header {
    width: 100%;
    height: 55px;
    border-bottom: 1px solid var(--oliver-border);
    padding: 0 16px;
    display: flex;
    align-items: center;
  }

  .cv-list > .header > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-right: 10px;
  }

  .cv-list > .header > .sort-wrapper {
    width: 155px;
    height: 36px;
    position: relative;
    user-select: none;
    line-height: 0;
  }

  .cv-list > .header > .sort-wrapper.open {
    z-index: 10;
    box-shadow: 0px 6px 10px rgba(94, 94, 94, 0.1);
  }

  .cv-list > .header > .sort-wrapper > img {
    position: absolute;
    top: calc(50% - 6px);
    right: 9px;
    width: 13px;
    height: 13px;
    filter: brightness(0) saturate(100%) invert(53%) sepia(37%) saturate(1027%)
      hue-rotate(165deg) brightness(91%) contrast(99%);
  }

  .cv-list > .header > .sort-wrapper.open > img {
    transform: rotate(180deg) translateY(-2px);
  }

  .cv-list > .header > .sort-wrapper > input {
    width: 100%;
    height: 100%;
    border: 1px solid var(--oliver-border);
    border-radius: 8px;
    box-sizing: border-box;
    padding: 0 25px 0 10px;
    font-family: Poppins;
    font-size: 14px;
    line-height: 20px;
    color: var(--oliver-blue);
  }

  .cv-list > .header > .sort-wrapper.open > input {
    border-color: var(--oliver-blue);
    border-radius: 8px 8px 0 0;
  }

  .cv-list > .header > .sort-wrapper > input:hover {
    border-color: var(--oliver-blue);
    cursor: pointer;
  }

  .cv-list > .header > .sort-wrapper > .option-container {
    width: 100%;
    display: none;
  }

  .cv-list > .header > .sort-wrapper.open > .option-container {
    display: block;
  }

  .cv-list > .header > .sort-wrapper > .option-container > .option {
    width: 100%;
    height: 36px;
    background-color: #fff;
    border: 1px solid var(--oliver-border);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 10px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .cv-list > .header > .sort-wrapper > .option-container > .option:hover {
    cursor: pointer;
    color: var(--oliver-blue);
  }

  .cv-list > .header > .sort-wrapper > .option-container > .option:last-child {
    border-radius: 0 0 8px 8px;
  }

  .cv-list > .body {
    width: 100%;
    height: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: #f8fafc;
    overflow-y: scroll;
  }

  .cv-list > .body > .no-search-results-list {
    display: none;
    margin: auto;
    border: 0 !important;
  }

  .cv-list > .body > .no-search-results-list:only-child {
    display: block;
  }

  .cv-list > .body > .no-search-results-list > p.style1 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: var(--oliver-base-grey);
    margin-bottom: 10px;
  }

  .cv-list > .body > .no-search-results-list > p.style2 {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-grey);
  }

  .cv-list > .body > .filter-name {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 30px;
    background-color: #f1f4f6;
    border-top: 1px solid var(--oliver-border);
  }

  .cv-list > .body > .filter-name:nth-child(1) {
    border-top: 0;
  }

  .cv-list > .body > .filter-name > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .cv-list > .body > .customer-card {
    position: relative;
    width: 100%;
    height: 95px;
    background-color: #fff;
    border: 0;
    border-top: 1px solid var(--oliver-border);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;
  }

  .cv-list > .body > .customer-card > .selected-indicator {
    position: absolute;
    width: 7px;
    height: 79px;
    left: 0;
    top: 9px;
    border-radius: 0 4px 4px 0;
    background-color: var(--oliver-blue);
    display: none;
  }

  .cv-list > .body > .customer-card.selected {
    background-color: #e9f5fd;
  }

  .cv-list > .body > .customer-card.selected > .selected-indicator {
    display: block;
  }

  .cv-list > .body > .customer-card.selected > .avatar {
    background-color: var(--oliver-blue);
  }

  .cv-list > .body > .customer-card.selected p {
    color: var(--oliver-blue) !important;
  }

  .cv-list > .body > :last-child {
    border-bottom: 1px solid var(--oliver-border);
  }

  .cv-list > .body > .customer-card > .avatar {
    width: 36px;
    height: 36px;
    margin-right: 20px;
  }

  .cv-list > .body > .customer-card > .text-group {
    width: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .cv-list > .body > .customer-card > .text-group > p {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .cv-list > .body > .customer-card > .text-group > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .cv-list > .body > .customer-card > .text-group > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .cv-list > .body > .customer-card > .selected-indicator {
    display: none;
  }

  .cv-list > .mobile-footer {
    width: 100%;
    height: 86px;
    box-sizing: border-box;
    padding: 0 16px;
    border-top: 1px solid var(--oliver-border);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cv-list > .mobile-footer > button {
    width: 100%;
    height: 54px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Customer View Search */

  .customer-view-wrapper > .cv-search {
    position: fixed;
    top: 60px;
    width: 100%;
    height: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    z-index: 3;
    background-color: #fff;
    overflow-y: scroll;
    align-items: center;
    box-sizing: border-box;
    padding: 16px;
    border-top: 1px solid var(--oliver-border);
    transform: translateX(-100%);
    transition: transform 0.3s;
  }

  .customer-view-wrapper > .cv-search.open {
    transform: translateX(0);
  }

  .customer-view-wrapper > .cv-search > .header {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .customer-view-wrapper > .cv-search > .header > p:not(.mobile-only) {
    display: none;
  }

  .customer-view-wrapper > .cv-search > .header > #cvAddCustomer {
    display: none;
  }

  .customer-view-wrapper > .cv-search > .header > #mobileCVExitSearch {
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 4px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-blue);
    margin-bottom: 20px;
  }

  .customer-view-wrapper > .cv-search > .header > #mobileCVExitSearch > img {
    width: 9px;
    height: 16px;
    margin-right: 8px;
  }

  .customer-view-wrapper > .cv-search > .body {
    width: 100%;
    height: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .customer-view-wrapper > .cv-search > .body > .row {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 11px;
  }

  .customer-view-wrapper > .cv-search > .body > .row > img {
    display: none;
  }

  .customer-view-wrapper > .cv-search > .body > .row > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 23px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-right: auto;
  }

  .customer-view-wrapper > .cv-search > .body > .row > p::after {
    content: " for Customer";
  }

  .customer-view-wrapper > .cv-search > .body > .row > button {
    padding: 5px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    text-align: left;
    color: var(--oliver-blue);
    text-decoration: underline;
    background-color: transparent;
    border: 0;
  }

  .customer-view-wrapper > .cv-search > .body > label {
    width: 100%;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 4px;
  }

  .customer-view-wrapper > .cv-search > .body > input {
    width: 100%;
    height: 44px;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    outline: 0;
    border-radius: 6px;
    margin-bottom: 20px;
    padding: 0 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .customer-view-wrapper > .cv-search > .body > input:last-of-type {
    margin-bottom: auto;
  }

  .customer-view-wrapper > .cv-search > .body > button {
    width: 100%;
    height: 54px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin-top: 20px;
  }

  /* Customer View Detailed */

  .customer-view-wrapper > .cv-detailed {
    position: fixed;
    top: 60px;
    width: 100%;
    height: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    z-index: 3;
    background-color: #fff;
    overflow-y: scroll;
    align-items: center;
    box-sizing: border-box;
    border-top: 1px solid var(--oliver-border);
    transform: translateX(100%);
    transition: transform 0.3s;
  }

  .customer-view-wrapper > .cv-detailed > .no-search-results-detailed {
    display: none;
  }

  .customer-view-wrapper > .cv-detailed.open {
    transform: translateX(0);
  }

  .cv-detailed > .mobile-back {
    width: 100%;
    height: 53px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 18px;
  }

  .cv-detailed > .mobile-back > button {
    padding: 0 3px;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .cv-detailed > .mobile-back > button > img {
    width: 9.1px;
    height: 16.2px;
    margin-right: 8px;
  }

  .cv-detailed > .quick-info {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    padding: 16px 18px;
    /* margin-bottom: 17px; */
    background-color: #e9f5fd;
  }

  .cv-detailed > .quick-info > .avatar {
    width: 36px;
    height: 36px;
    background-color: var(--oliver-blue);
    margin: 4px 15px 0 0;
  }

  .cv-detailed > .quick-info > .text-group {
    margin-right: auto;
  }

  .cv-detailed > .quick-info > .text-group > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: var(--oliver-base-blue);
  }

  .cv-detailed > .quick-info > .text-group > p.style2 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .cv-detailed > .quick-info > button {
    width: 70px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid var(--oliver-blue);
    border-radius: 8px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .cv-detailed > .quick-info > button > img {
    width: 15px;
    height: 16px;
    margin-right: 5px;
  }

  .cv-detailed > .cust-totals {
    position: relative;
    width: calc(100% - 32px);
    box-sizing: border-box;
    padding: 13px 0;
    margin: 0 16px;
    border-bottom: 1px solid var(--oliver-border);
  }

  .cv-detailed > .cust-totals > .col {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  .cv-detailed > .cust-totals > .col > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-right: auto;
  }

  .cv-detailed > .cust-totals > .col > p.style2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-right: 8px;
  }

  .cv-detailed > .cust-totals > .col > p.style2::after {
    content: ":";
  }

  .cv-detailed > .cust-totals > .col > button {
    position: absolute;
    right: 0;
    background-color: transparent;
    border: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: right;
    color: var(--oliver-blue);
    text-decoration: underline;
    display: flex;
    align-items: center;
  }

  .cv-detailed > .cust-totals > .col > button::before {
    content: "Adjust Credit";
  }

  .cv-detailed > .cust-totals > .col > button > img {
    width: 16px;
    height: 16px;
    margin-left: 5px;
  }

  .cv-detailed > .bill-ship-info {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: calc(100% - 32px);
    border-bottom: 1px solid var(--oliver-border);
    padding: 15px 0;
    margin: 0 16px;
  }

  .cv-detailed > .bill-ship-info > .col > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 5px;
    margin-top: 17px;
  }

  .cv-detailed > .bill-ship-info > .col:nth-child(1) > p.style1 {
    margin-top: 0;
  }

  .cv-detailed > .bill-ship-info > .col > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .cv-detailed > .cust-notes {
    width: calc(100% - 32px);
    box-sizing: border-box;
    padding: 13px 0;
    margin: 0 16px;
  }

  .cv-detailed > .cust-notes > .header-row {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 13px;
  }

  .cv-detailed > .cust-notes > .header-row > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-right: auto;
  }

  .cv-detailed > .cust-notes > .header-row > button {
    background-color: transparent;
    border: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: right;
    color: var(--oliver-blue);
    text-decoration: underline;
  }

  .cv-detailed > .cust-notes > .customer-note {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }

  .cv-detailed > .cust-notes > .customer-note > .row {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .cv-detailed > .cust-notes > .customer-note > .row > p.style1 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-right: 16px;
  }

  .cv-detailed > .cust-notes > .customer-note > .row > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-right: auto;
  }

  .cv-detailed > .cust-notes > .customer-note > .row > button {
    width: 17px;
    height: 17px;
    background-color: transparent;
    border: 0;
  }

  .cv-detailed > .cust-notes > .customer-note > .row > button > img {
    width: 100%;
    height: 100%;
  }

  .cv-detailed > .cust-notes > .customer-note > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .cv-detailed > .footer {
    width: calc(100% - 32px);
    height: 85px;
    box-sizing: border-box;
    border-top: 1px solid var(--oliver-border);
    margin-top: auto;
    display: flex;
    align-items: center;
  }

  .cv-detailed > .footer > button {
    flex: 1;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: #fff;
  }

  .cv-detailed > .footer > button:first-of-type {
    margin-right: 15px;
  }

  /* --------------------Transactions-------------------- */

  .transactions-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  /* Navbar Settings	 */

  .transactions-wrapper > .navbar {
    top: 60px;
    width: 100%;
    height: calc(100% - 60px);
    padding-bottom: 10px;
  }

  /* Mobile Header */

  .transactions-wrapper > .mobile-transactions-header {
    width: 100%;
    height: 70px;
    box-sizing: border-box;
    border-bottom: 1px solid var(--oliver-border);
    display: flex;
    align-items: center;
    padding: 0 13px 0 8px;
  }

  .transactions-wrapper > .mobile-transactions-header > #mobileNavToggle {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    background-color: transparent;
    border: 0;
  }

  .transactions-wrapper > .mobile-transactions-header > #mobileNavToggle > img {
    content: url("assets/images/svg/Hamburger-Menu-Icon.svg");
    width: 20px;
    height: 13.33px;
  }

  .transactions-wrapper
    > .mobile-transactions-header
    > #mobileNavToggle.opened
    > img {
    content: url("assets/images/svg/Hamburger-Menu-Active-Icon.svg");
    width: 39px;
    height: 39px;
  }

  .transactions-wrapper > .mobile-transactions-header > p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-right: auto;
  }

  .transactions-wrapper
    > .mobile-transactions-header
    > #mobileTransactionsSearchButton {
    width: 34px;
    height: 34px;
    background-color: #d1ecff;
    border: 0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  .transactions-wrapper
    > .mobile-transactions-header
    > #mobileTransactionsSearchButton
    > img {
    width: 21px;
    height: 21px;
  }

  .transactions-wrapper > .mobile-transactions-header > #mobileAppsButton {
    width: 34px;
    height: 34px;
    background-color: #d1ecff;
    border: 0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .transactions-wrapper
    > .mobile-transactions-header
    > #mobileAppsButton
    > img {
    width: 18.62px;
    height: 18.46px;
    transform: translateX(1px);
  }

  .transactions-wrapper > .mobile-transactions-header > button.filter {
    background-color: var(--oliver-blue) !important;
  }

  .transactions-wrapper > .mobile-transactions-header > button.filter > img {
    filter: brightness(0) invert(1);
  }

  /* Refund Cart for Mobile */

  .transactions-wrapper > .mobile-refund.cart {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    background-color: #fff;
    transform: translateY(0);
  }

  .transactions-wrapper > .mobile-refund.cart.hidden {
    transform: translateY(100%);
  }

  .transactions-wrapper > .mobile-refund.cart > .refund-cart-header {
    width: 100%;
    height: 65px;
    border-bottom: 1px solid var(--oliver-border);
    padding: 0 16px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }

  .transactions-wrapper > .mobile-refund.cart > .refund-cart-header > p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-right: auto;
  }

  .transactions-wrapper > .mobile-refund.cart > .refund-cart-header > button {
    width: 32px;
    height: 32px;
    border-radius: 6px;
    border: 0;
    background-color: #f1f4f6;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .transactions-wrapper
    > .mobile-refund.cart
    > .refund-cart-header
    > button
    > img {
    width: 12px;
    height: 12px;
  }

  .transactions-wrapper
    > .mobile-refund.cart
    > .body
    > .cart-item
    > .main-row
    > p.quantity {
    color: var(--oliver-base-grey);
  }

  .transactions-wrapper
    > .mobile-refund.cart
    > .body
    > .cart-item
    > .increment-input {
    width: 130px;
    height: 36px;
    margin-top: 10px;
    display: flex;
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
  }

  .transactions-wrapper
    > .mobile-refund.cart
    > .body
    > .cart-item
    > .increment-input
    > button {
    height: 100%;
    aspect-ratio: 1/1;
    background-color: #ddf1ff;
    border: 0;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .transactions-wrapper
    > .mobile-refund.cart
    > .body
    > .cart-item
    > .increment-input
    > button
    > img {
    width: 10px;
    height: 10px;
  }

  .transactions-wrapper
    > .mobile-refund.cart
    > .body
    > .cart-item
    > .increment-input
    > input {
    width: 0;
    flex-grow: 1;
    border: 0;
    outline: 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .transactions-wrapper
    > .mobile-refund.cart
    > .body
    > .cart-item
    > .increment-input
    > input:hover {
    cursor: default;
  }

  .transactions-wrapper
    > .mobile-refund.cart
    > .footer
    > .totals
    > .row
    > button {
    border: 0;
    background-color: transparent;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: var(--oliver-blue);
  }

  .transactions-wrapper
    > .mobile-refund.cart
    > .footer
    > .totals
    > .row
    > button:hover {
    text-decoration: underline;
  }

  .transactions-wrapper
    > .mobile-refund.cart
    > .footer
    > .totals
    > .row
    > p.type2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: right;
    color: var(--oliver-base-blue);
    margin-right: 0 !important;
  }

  .transactions-wrapper
    > .mobile-refund.cart
    > .footer
    > .totals
    > .row
    > p.type2
    > b {
    font-weight: 600;
  }

  .transactions-wrapper > .mobile-refund.cart > .footer > button {
    width: calc(100% - 30px);
    height: 54px;
    background-color: var(--oliver-blue);
    margin: 0 auto 17px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    border-radius: 10px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  .transactions-wrapper > .mobile-refund.cart > .footer > button > img {
    width: 26px;
    height: 26px;
    margin-left: 12px;
  }

  .transactions-wrapper
    > .mobile-refund.cart
    > .body
    > .cart-item
    > .main-row
    > .quantity {
    color: var(--oliver-base-grey);
  }

  .transactions-wrapper
    > .mobile-refund.cart
    > .body
    > .cart-item
    > .main-row
    > .quantity:hover {
    cursor: default;
    text-decoration: none;
  }

  .transactions-wrapper
    > .mobile-refund.cart
    > .body
    > .cart-item
    > .main-row
    > .content-style:hover {
    color: var(--oliver-base-blue-dark) !important;
    cursor: default;
    text-decoration: none;
  }

  /* Transactions List */

  .transactions-list {
    width: 100%;
    height: calc(100% - 70px);
    display: flex;
    flex-direction: column;
  }

  .transactions-list > .header {
    width: 100%;
    height: 55px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-bottom: 1px solid var(--oliver-border);
  }

  .transactions-list > .header > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-right: 10px;
  }

  .transactions-list > .header > .sort-wrapper {
    width: 155px;
    height: 36px;
    position: relative;
    user-select: none;
  }

  .transactions-list > .header > .sort-wrapper.open {
    z-index: 10;
    box-shadow: 0px 6px 10px rgba(94, 94, 94, 0.1);
  }

  .transactions-list > .header > .sort-wrapper > img {
    position: absolute;
    top: calc(50% - 6px);
    right: 9px;
    width: 13px;
    height: 13px;
    filter: brightness(0) saturate(100%) invert(53%) sepia(37%) saturate(1027%)
      hue-rotate(165deg) brightness(91%) contrast(99%);
  }

  .transactions-list > .header > .sort-wrapper.open > img {
    transform: rotate(180deg) translateY(-2px);
  }

  .transactions-list > .header > .sort-wrapper > input {
    width: 100%;
    height: 100%;
    border: 1px solid var(--oliver-border);
    border-radius: 8px;
    box-sizing: border-box;
    padding: 0 25px 0 10px;
    font-family: Poppins;
    font-size: 14px;
    line-height: 20px;
    color: var(--oliver-blue);
  }

  .transactions-list > .header > .sort-wrapper.open > input {
    border-color: var(--oliver-blue);
    border-radius: 8px 8px 0 0;
  }

  .transactions-list > .header > .sort-wrapper > input:hover {
    border-color: var(--oliver-blue);
    cursor: pointer;
  }

  .transactions-list > .header > .sort-wrapper > .option-container {
    width: 100%;
    display: none;
  }

  .transactions-list > .header > .sort-wrapper.open > .option-container {
    display: block;
  }

  .transactions-list > .header > .sort-wrapper > .option-container > .option {
    width: 100%;
    height: 36px;
    background-color: #fff;
    border: 1px solid var(--oliver-border);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 10px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .transactions-list
    > .header
    > .sort-wrapper
    > .option-container
    > .option:hover {
    cursor: pointer;
    color: var(--oliver-blue);
  }

  .transactions-list
    > .header
    > .sort-wrapper
    > .option-container
    > .option:last-child {
    border-radius: 0 0 8px 8px;
  }

  .transactions-list > .body {
    width: 100%;
    height: 0;
    flex-grow: 1;
    overflow-y: scroll;
    background-color: #f8fafc;
    display: flex;
    flex-direction: column;
  }

  .transactions-list > .body > .no-results {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 58px;
    display: none;
    border: 0 !important;
  }

  .transactions-list > .body > .no-results:only-child {
    display: flex;
  }

  .transactions-list > .body > .no-results > p.style1 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-grey);
    margin-bottom: 10px;
  }

  .transactions-list > .body > .no-results > p.style2 {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-grey);
  }

  .transactions-list > .body > .filter-name {
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    background-color: #f1f4f6;
    display: flex;
    align-items: center;
    padding: 0 30px;
    border-top: 1px solid var(--oliver-border);
  }

  .transactions-list > .body > .filter-name:nth-child(2) {
    border: 0;
  }

  .transactions-list > .body > .filter-name > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .transactions-list > .body > .transaction-card {
    position: relative;
    width: 100%;
    height: 106px;
    box-sizing: border-box;
    background-color: #fff;
    border: 0;
    border-top: 1px solid var(--oliver-border);
    display: flex;
    padding: 15px 15px 15px 20px;
  }

  .transactions-list > .body > :last-child {
    border-bottom: 1px solid var(--oliver-border);
  }

  .transactions-list > .body > .transaction-card > .col {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .transactions-list > .body > .transaction-card > .col:nth-child(1) {
    width: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .transactions-list > .body > .transaction-card > .col > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .transactions-list > .body > .transaction-card > .col > p.style2 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .transactions-list > .body > .transaction-card > .col > .row {
    display: flex;
    align-items: center;
    margin-top: auto;
  }

  .transactions-list > .body > .transaction-card > .col > .row > img {
    width: 14px;
    height: 12px;
    margin-right: 8px;
  }

  .transactions-list > .body > .transaction-card > .col > .row > p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .transactions-list > .body > .transaction-card > .col > p.style3 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: auto;
  }

  .transactions-list > .body > .transaction-card > .col > p.style4 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-top: auto;
    font-style: italic;
    padding-right: 1px;
  }

  .transactions-list > .body > .transaction-card > .selected-indicator {
    display: none;
    width: 7px;
    height: 76px;
    position: absolute;
    top: calc(50% - 38px);
    left: 0;
    background-color: var(--oliver-blue);
    border-radius: 0 4px 4px 0;
  }

  .transactions-list > .body > .transaction-card.selected {
    background-color: #e9f5fd;
  }

  .transactions-list > .body > .transaction-card.selected p {
    color: var(--oliver-blue) !important;
  }

  .transactions-list > .body > .transaction-card.selected img {
    filter: brightness(0) saturate(100%) invert(47%) sepia(74%) saturate(816%)
      hue-rotate(173deg) brightness(95%) contrast(92%);
  }

  .transactions-list
    > .body
    > .transaction-card.selected
    > .selected-indicator {
    display: block;
  }

  /* Transactions Search */

  .transactions-search {
    position: fixed;
    top: 70px;
    background-color: #fff;
    z-index: 3;
    width: 100%;
    height: calc(100% - 70px);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    transform: translateX(-100%);
    transition: transform 0.3s;
  }

  .transactions-search.open {
    transform: translateX(0);
  }

  .transactions-search > .search-header {
    display: none;
  }

  .transactions-search > .search-header-mobile {
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
    display: flex;
    align-items: center;
  }

  .transactions-search > .search-header-mobile > #mobileSearchExit {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-blue);
    margin-right: auto;
  }

  .transactions-search > .search-header-mobile > #mobileSearchExit > img {
    width: 9.1px;
    height: 16.2px;
    margin-right: 9px;
  }

  .transactions-search > .search-header-mobile > #mobileSearchFieldClear {
    width: 109px;
    height: 40px;
    box-sizing: border-box;
    border: 1px solid var(--oliver-blue);
    border-radius: 6px;
    background-color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .transactions-search > .search-body {
    width: 100%;
    box-sizing: border-box;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
  }

  .transactions-search > .search-body > p.mobile-only {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 6px;
  }

  .transactions-search > .search-body label {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 4px;
  }

  .transactions-search > .search-body input {
    width: 100%;
    height: 44px;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    padding: 0 12px;
    outline: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 20px;
  }

  .transactions-search > .search-body > input:first-of-type {
    margin-bottom: 7px;
  }

  .transactions-search > .search-body > p:not(.mobile-only) {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .transactions-search > .search-body > .divider {
    width: 100%;
    height: 1px;
    background-color: var(--oliver-border);
    margin: 20px 0;
  }

  .transactions-search > .search-body > .dropdown-wrapper {
    position: relative;
    width: 100%;
    height: 44px;
    margin-bottom: 20px;
    line-height: 0;
  }

  .transactions-search > .search-body > .dropdown-wrapper.open {
    z-index: 2;
  }

  .transactions-search > .search-body > .dropdown-wrapper:hover {
    cursor: pointer;
  }

  .transactions-search > .search-body > .dropdown-wrapper > img {
    position: absolute;
    right: 14px;
    top: calc(50% - 9px);
    width: 18px;
    height: 18px;
  }

  .transactions-search > .search-body > .dropdown-wrapper.open > img {
    transform: rotate(180deg);
  }

  .transactions-search > .search-body > .dropdown-wrapper > input {
    width: 100%;
    height: 100%;
    margin: 0;
  }

  .transactions-search > .search-body > .dropdown-wrapper > input:focus {
    border-color: var(--oliver-border);
  }

  .transactions-search > .search-body > .dropdown-wrapper.open > input {
    border-radius: 6px 6px 0 0;
    border-color: var(--oliver-blue);
  }

  .transactions-search > .search-body > .dropdown-wrapper > input:hover {
    cursor: pointer;
  }

  .transactions-search > .search-body > .dropdown-wrapper > .option-list {
    display: none;
    width: 100%;
    max-height: 176px;
    height: fit-content;
    overflow-y: scroll;
    border-radius: 0 0 7px 7px;
  }

  .transactions-search > .search-body > .dropdown-wrapper.open > .option-list {
    display: block;
  }

  .transactions-search
    > .search-body
    > .dropdown-wrapper
    > .option-list
    > .option {
    width: 100%;
    height: 44px;
    background-color: #fff;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 12px;
    border: 1px solid var(--oliver-border);
    border-top: 0;
  }

  .transactions-search
    > .search-body
    > .dropdown-wrapper
    > .option-list
    > .option
    > p {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .transactions-search
    > .search-body
    > .dropdown-wrapper
    > .option-list
    > .option:hover
    > p {
    color: var(--oliver-blue);
  }

  .transactions-search > .search-body > .input-row {
    width: 100%;
    display: flex;
  }

  .transactions-search > .search-body > .input-row > .input-col {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .transactions-search > .search-body > .input-row > .input-col:first-child {
    margin-right: 20px;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper {
    position: relative;
    margin-bottom: 20px;
    line-height: 0;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > input {
    margin-bottom: 0;
    padding-right: 32px;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > button {
    position: absolute;
    right: 12px;
    top: calc(50% - 10px);
    width: 20px;
    height: 20px;
    border: 0;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > button:hover
    > img {
    filter: brightness(0) saturate(100%) invert(41%) sepia(90%) saturate(579%)
      hue-rotate(166deg) brightness(106%) contrast(89%);
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > button
    > img {
    width: 100%;
    height: 100%;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector {
    position: fixed;
    align-self: center;
    box-sizing: border-box;
    padding: 25px 20px;
    border: 1px solid var(--oliver-border);
    background-color: #fff;
    z-index: 2;
    border-radius: 6px;
    margin-top: 7px;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0px 10px 20px rgba(119, 135, 147, 0.1));
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper.left
    > .date-selector {
    left: 16px;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper.right
    > .date-selector {
    right: 16px;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector.open {
    display: flex;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .header-row {
    width: 280px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .header-row
    > button {
    background-color: transparent;
    border: 0;
    /* border: 1px solid var(--oliver-blue); */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    padding: 0 10px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .header-row
    > button:hover {
    text-decoration: underline;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .header-row
    > button
    > img {
    width: 40px;
    height: auto;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .header-row
    > button.calendar-left {
    padding-left: 0;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .header-row
    > button.calendar-right {
    padding-right: 0;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .header-row
    > div {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .day-row {
    width: 280px;
    display: flex;
    border-bottom: 1px solid var(--oliver-border);
    margin-bottom: 10px;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .day-row
    > .day {
    flex: 1;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-base-grey);
    box-sizing: border-box;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .date-row {
    width: 280px;
    display: flex;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .date-row
    > button.cell {
    flex: 1;
    height: 40px;
    background-color: transparent;
    border: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-blue);
    border-radius: 6px;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .date-row
    > button.cell:hover:not(:disabled) {
    background-color: var(--oliver-blue);
    color: #fff;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .date-row
    > button.cell:disabled {
    color: var(--oliver-base-grey);
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .month-row {
    width: 320px;
    display: flex;
    margin-top: 15px;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .month-row
    > button.cell {
    flex: 1;
    height: 40px;
    background-color: transparent;
    border: 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-blue);
    border-radius: 6px;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .month-row
    > button.cell:hover {
    background-color: var(--oliver-blue);
    color: #fff;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .year-row {
    width: 280px;
    display: flex;
    margin-top: 15px;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .year-row
    > button.cell {
    flex: 1;
    height: 40px;
    background-color: transparent;
    border: 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-blue);
    border-radius: 6px;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .year-row
    > button.cell:hover {
    background-color: var(--oliver-blue);
    color: #fff;
  }

  .transactions-search > .search-body > button {
    width: 100%;
    height: 54px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    margin: auto 0 20px 0;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Transactions Detailed */

  .transactions-detailed {
    position: fixed;
    top: 70px;
    background-color: #fff;
    z-index: 3;
    width: 100%;
    height: calc(100% - 70px);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    transform: translateX(-100%);
    transition: transform 0.3s;
  }

  .transactions-detailed.open {
    transform: translateX(0);
  }

  .transactions-detailed > .no-orders-detailed {
    display: none;
  }

  .transactions-detailed > .detailed-header-mobile {
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
    display: flex;
    align-items: center;
  }

  .transactions-detailed > .detailed-header-mobile > #mobileDetailedExit {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-blue);
    margin-right: auto;
  }

  .transactions-detailed > .detailed-header-mobile > #mobileDetailedExit > img {
    width: 9px;
    height: 16px;
    margin-right: 9px;
  }

  .transactions-detailed > .scrollable {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .transactions-detailed > .scrollable > .quick-info {
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    flex-direction: column;
    background-color: #e9f5fd;
  }

  .transactions-detailed > .scrollable > .quick-info > .row {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }

  .transactions-detailed > .scrollable > .quick-info > .row:first-child {
    margin-top: 0;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > * {
    width: 0;
    flex: 1;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > p:nth-child(2) {
    text-align: right !important;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > p.style1 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--oliver-base-blue);
  }

  .transactions-detailed > .scrollable > .quick-info > .row > p.style2 {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .transactions-detailed > .scrollable > .quick-info > .row > p.style3 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .transactions-detailed > .scrollable > .quick-info > .row > p.style3 > b {
    font-weight: 600;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > .group {
    display: flex;
    align-items: center;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > .group > img {
    width: 18px;
    height: 16px;
    margin-right: 8px;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > .group > p {
    width: 0;
    flex-grow: 1;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .transactions-detailed > .scrollable > .quick-info p.red {
    color: var(--error) !important;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > .group > p.style1 {
    font-weight: 500;
    display: contents;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > .group {
    justify-content: end;
    gap: 6px;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > .group > p.style1 + img {
    cursor: pointer;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > .group > p.style1 + img:active {
    scale: 0.85;
  }

  /* Color for refund state */
  .transactions-detailed > .scrollable > .quick-info.refund > .row > .group > p.style1 {
    color: var(--error);
  }

  .transactions-detailed > .scrollable > .quick-info > .row > .group > p.style1 {
    color: var(--oliver-blue);
  }

  .transactions-detailed > .scrollable > .quick-info.refund {
    background-color: #ff525f1a;
  }

  .transactions-detailed > .scrollable > .quick-info.refund > .row > .group > p.style1 + img{
    filter: invert(100%) sepia(67%) saturate(2056%) hue-rotate(332deg) brightness(121%) contrast(103%);
    }


  .transactions-detailed > .scrollable > .quick-info.completed {
    background-color: #E9f5fd !important;
  }

  .transactions-detailed > .scrollable > .quick-info p > b.strikethrough {
    text-decoration: line-through !important;
  }

  .transactions-detailed > .scrollable > .customer-info {
    width: calc(100% - 40px);
    box-sizing: border-box;
    padding: 15px 0 20px 0;
    display: flex;
    border-bottom: 1px solid var(--oliver-border);
  }

  .transactions-detailed > .scrollable > .customer-info > .col {
    width: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

  .transactions-detailed > .scrollable > .customer-info > .col > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 8px;
  }

  .transactions-detailed > .scrollable > .customer-info > .col > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 4px;
    max-width: 95%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .transactions-detailed
    > .scrollable
    > .customer-info
    > .col
    > p:last-of-type {
    margin: 0;
  }

  .transactions-detailed > .scrollable > .customer-info > button {
    width: 154px;
    height: 40px;
    box-sizing: border-box;
    border: 1px solid var(--oliver-blue);
    border-radius: 6px;
    background-color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .transactions-detailed > .scrollable > .order-details {
    box-sizing: border-box;
    width: 100%;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    min-height: 100px;
  }

  .transactions-detailed > .scrollable > .order-details > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 24px;
  }

  .transactions-detailed > .scrollable > .order-details > .item {
    width: 100%;
    display: flex;
    margin-top: 20px;
  }

  .transactions-detailed > .scrollable > .order-details > .item:nth-child(2) {
    margin-top: 0;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .img-container {
    position: relative;
    width: 68px;
    height: 69px;
    margin-right: 24px;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .img-container
    > .quantity {
    position: absolute;
    min-width: 26px;
    height: 26px;
    padding: 0 3px;
    right: 0px;
    top: 0px;
    background-color: #fff;
    border-radius: 6px;
    box-sizing: border-box;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--oliver-blue);
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .img-container
    > .quantity
    > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #fff;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .img-container
    > img {
    width: 100%;
    height: auto;
    border-radius: 4px;
  }

  .transactions-detailed > .scrollable > .order-details > .item > .col {
    width: 0;
    flex-grow: 1;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .main-row {
    width: 100%;
    display: flex;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .main-row
    > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .main-row
    > p
    > b {
    color: var(--oliver-base-blue);
    font-weight: 500;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .main-row
    > p:first-of-type {
    color: var(--oliver-base-blue);
    margin-right: auto;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .item-fields {
    box-sizing: border-box;
    width: 100%;
    padding-top: 6px;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .item-fields
    > p {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .modifier-fields {
    width: 100%;
    box-sizing: border-box;
    padding: 8px 0 0 13px;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .modifier-fields
    > .row {
    width: 100%;
    display: flex;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .modifier-fields
    > .row
    > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    color: var(--oliver-base-grey);
    width: 0;
    flex: 1;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 10px;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .modifier-fields
    > .row
    > p:last-of-type {
    text-align: right;
    padding-right: 0;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .product-total-fields {
    width: 100%;
    padding-top: 8px;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .product-total-fields
    > div {
    width: 100%;
    padding-top: 6px;
    border-top: 1px solid var(--oliver-border);
    margin-top: 6px;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .product-total-fields
    > div:first-of-type {
    border-top: 0 !important;
    padding-top: 0 !important;
    margin-top: 0 !important;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .product-total-fields
    > div
    > .row {
    width: 100%;
    display: flex;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .product-total-fields
    > div
    > .row
    > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    color: var(--oliver-base-grey);
    width: 0;
    flex: 1;
    padding-right: 10px;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .product-total-fields
    > div
    > .row
    > p:last-of-type {
    text-align: right;
    padding-right: 0;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .product-total-fields
    > div
    > .row
    > p
    > b {
    font-weight: 500;
  }

  .transactions-detailed > .scrollable > .order-details > .custom-fields {
    width: 100%;
    margin-top: 15px;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .custom-fields
    > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 7px;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .custom-fields
    > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-left: 14px;
    max-width: calc(100% - 14px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: 3px;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .custom-fields
    > p.style2:nth-child(2) {
    margin-top: 0;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper {
    width: 100%;
    box-sizing: border-box;
    padding: 15px 0;
    border-top: 2px solid var(--oliver-border);
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals {
    width: min(400px, 75%);
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row {
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 6px;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row.border-bottom {
    border-bottom: 1px solid var(--oliver-border);
    margin-bottom: 11px;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row.border-top {
    padding: 6px 0 0 0;
    border-top: 1px solid var(--oliver-border);
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row
    > p:nth-child(2) {
    text-align: right;
    width: 0;
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row
    > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row
    > p
    > b {
    font-weight: 500;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row
    > p
    > b.bold2 {
    font-weight: 600;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row
    > p.style2 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .transactions-detailed > .scrollable > .order-details > .refund-header {
    width: 100%;
    box-sizing: border-box;
    padding: 5px 8px;
    background-color: #f1f4f6;
    display: flex;
    align-items: center;
    margin: 25px 0 15px 0;
  }

  .transactions-detailed > .scrollable > .order-details > .refund-header > p {
    width: 0;
    flex: 1;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .refund-header
    > p.style1 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    padding-right: 10px;
    color: var(--oliver-base-blue);
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .refund-header
    > p.style2 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: right;
    color: var(--oliver-base-blue);
  }

  .transactions-detailed > .footer {
    position: relative;
    width: calc(100% - 32px);
    padding: 81px 0 16px 0;
    box-sizing: border-box;
    border-top: 1px solid var(--oliver-border);
    display: flex;
  }

  .transactions-detailed > .footer > button:not(#receiptButton) {
    flex: 1;
    height: 54px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  .transactions-detailed > .footer > button:last-of-type {
    margin-left: 19px;
  }

  .transactions-detailed > .footer > button#receiptButton {
    width: 100%;
    position: absolute;
    height: 54px;
    box-sizing: border-box;
    border: 1px solid var(--oliver-blue);
    background-color: #fff;
    border-radius: 6px;
    top: 16px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .transactions-detailed > .footer > button#refundButtonLandscape {
    display: none;
  }

  /* --------------------Cash Managment-------------------- */

  .cash-management-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  /* Navbar Settings	 */

  .cash-management-wrapper > .navbar {
    top: 60px;
    width: 100%;
    height: calc(100% - 60px);
    padding-bottom: 10px;
  }

  /* Mobile Header */

  .cash-management-wrapper > .mobile-cm-header {
    width: 100%;
    height: 70px;
    box-sizing: border-box;
    border-bottom: 1px solid var(--oliver-border);
    display: flex;
    align-items: center;
    padding: 0 13px 0 8px;
  }

  .cash-management-wrapper > .mobile-cm-header > #mobileNavToggle {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    background-color: transparent;
    border: 0;
  }

  .cash-management-wrapper > .mobile-cm-header > #mobileNavToggle > img {
    content: url("assets/images/svg/Hamburger-Menu-Icon.svg");
    width: 20px;
    height: 13.33px;
  }

  .cash-management-wrapper > .mobile-cm-header > #mobileNavToggle.opened > img {
    content: url("assets/images/svg/Hamburger-Menu-Active-Icon.svg");
    width: 39px;
    height: 39px;
  }

  .cash-management-wrapper > .mobile-cm-header > p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-right: auto;
  }

  .cash-management-wrapper
    > .mobile-cm-header
    > #mobileTransactionsSearchButton {
    width: 34px;
    height: 34px;
    background-color: #d1ecff;
    border: 0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  .cash-management-wrapper
    > .mobile-cm-header
    > #mobileTransactionsSearchButton
    > img {
    width: 21px;
    height: 21px;
  }

  .cash-management-wrapper > .mobile-cm-header > #mobileAppsButton {
    width: 34px;
    height: 34px;
    background-color: #d1ecff;
    border: 0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cash-management-wrapper > .mobile-cm-header > #mobileAppsButton > img {
    width: 18.62px;
    height: 18.46px;
    transform: translateX(1px);
  }

  .cash-management-wrapper > .mobile-cm-header > button.filter {
    background-color: var(--oliver-blue) !important;
  }

  .cash-management-wrapper > .mobile-cm-header > button.filter > img {
    filter: brightness(0) invert(1);
  }

  /* Cash Management List */

  .cm-list {
    width: 100%;
    height: 0;
    flex-grow: 1;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
  }

  .cm-list > .cm-list-header-landscape {
    display: none;
  }

  .cm-list > .cm-list-body {
    width: 100%;
    height: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .cm-list > .cm-list-body > .current-register {
    position: relative;
    width: 100%;
    height: 99px;
    box-sizing: border-box;
    border: 0;
    border-bottom: 1px solid var(--oliver-border);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 91px 0 16px;
    background-color: transparent;
  }

  .cm-list > .cm-list-body > .current-register p {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .cm-list > .cm-list-body > .current-register > p.style1 {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: var(--oliver-blue);
  }

  .cm-list > .cm-list-body > .current-register > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .cm-list > .cm-list-body > .current-register > .text-row > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .cm-list > .cm-list-body > .current-register > .text-row > p.open,
  .cm-list > .cm-list-body > .current-register > .text-row > p.closed {
    display: none;
  }

  .cm-list > .cm-list-body > .current-register > .mobile-fake-button {
    position: absolute;
    right: 16px;
    box-sizing: border-box;
    width: 75px;
    height: 40px;
    border: 1px solid var(--oliver-blue);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .cm-list > .cm-list-body > .current-register.selected {
    background-color: #e9f5fd;
  }

  .cm-list > .cm-list-body > .current-register.selected .mobile-fake-button {
    background-color: #fff !important;
  }

  .cm-list > .cm-list-body > .date {
    width: 100%;
    height: 30px;
    background-color: #f1f4f6;
    box-sizing: border-box;
    border-bottom: 1px solid var(--oliver-border);
    display: flex;
    align-items: center;
    padding: 16px;
  }

  .cm-list > .cm-list-body > .date > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .cm-list > .cm-list-body > .other-register {
    position: relative;
    width: 100%;
    height: 75px;
    box-sizing: border-box;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid var(--oliver-border);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 16px;
  }

  .cm-list > .cm-list-body > .other-register > .row {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .cm-list > .cm-list-body > .other-register > .row:nth-child(1) {
    margin-bottom: 4px;
  }

  .cm-list > .cm-list-body > .other-register > .row > p:first-of-type {
    width: 0;
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .cm-list > .cm-list-body > .other-register > .row > p.style1 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .cm-list > .cm-list-body > .other-register > .row > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .cm-list > .cm-list-body > .other-register.selected {
    background-color: #e9f5fd;
  }

  .cm-list > .cm-list-body > .other-register.selected::before {
    content: "";
    position: absolute;
    width: 6px;
    height: 60px;
    top: 7px;
    left: 0;
    border-radius: 0 4px 4px 0;
    background-color: var(--oliver-blue);
  }

  .cm-list > .cm-list-body > .other-register.selected p {
    color: var(--oliver-blue) !important;
  }

  /* Cash Management Detailed */

  .cm-detailed {
    position: fixed;
    top: 70px;
    background-color: #fff;
    z-index: 3;
    width: 100%;
    height: calc(100% - 70px);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    transform: translateX(-100%);
    transition: transform 0.3s;
  }

  .cm-detailed.open {
    transform: translateX(0);
  }

  .cm-detailed > .detailed-header-mobile {
    width: 100%;
    height: 52px;
  }

  .cm-detailed > .detailed-header-mobile > button {
    height: 100%;
    padding: 0 20px;
    background-color: transparent;
    border: 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-blue);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cm-detailed > .detailed-header-mobile > button > img {
    width: 9px;
    height: 18px;
    margin-right: 8px;
  }

  .cm-detailed > .detailed-header-landscape {
    display: none;
  }

  .cm-detailed > .detailed-quick-info {
    width: 100%;
    background-color: #eaf5ff;
    padding: 15px;
    box-sizing: border-box;
  }

  .cm-detailed > .detailed-quick-info > .row {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .cm-detailed > .detailed-quick-info > .row:first-child {
    margin-bottom: 15px;
  }

  .cm-detailed > .detailed-quick-info > .row > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .cm-detailed > .detailed-quick-info > .row > p.active {
    color: var(--oliver-blue);
  }

  .cm-detailed > .detailed-quick-info > .row > .row-group {
    display: flex;
    align-items: center;
    margin-right: auto;
  }

  .cm-detailed > .detailed-quick-info > .row > .row-group > p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-right: 10px;
  }

  .cm-detailed > .detailed-quick-info > .row > .row-group > .status {
    height: 24px;
    padding: 0 8px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
  }

  .cm-detailed > .detailed-quick-info > .row > .row-group > .status.closed {
    background-color: var(--oliver-border);
    color: var(--oliver-base-blue);
  }

  .cm-detailed > .detailed-quick-info > .row > .row-group > .status.open {
    background-color: var(--oliver-teal);
    color: #fff;
  }

  .cm-detailed > .detailed-quick-info > .row > .col-group {
    margin-right: auto;
  }

  .cm-detailed > .detailed-quick-info > .row > .col-group > p.style1 {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .cm-detailed > .detailed-quick-info > .row > .col-group > p.style2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .cm-detailed > .detailed-quick-info > .row > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 88px;
    height: 40px;
    border: 1px solid var(--oliver-blue);
    border-radius: 8px;
    background-color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .cm-detailed > .detailed-quick-info > .row > button > img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .cm-detailed > .detailed-body {
    width: 100%;
    height: 0;
    flex-grow: 1;
    overflow: auto;
    box-sizing: border-box;
    padding: 0 20px;
  }

  .cm-detailed > .detailed-body > .action {
    width: 100%;
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--oliver-border);
  }

  .cm-detailed > .detailed-body > .action:first-child {
    border-top: 0;
  }

  .cm-detailed > .detailed-body > .action > .header-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .cm-detailed > .detailed-body > .action > .header-row > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .cm-detailed > .detailed-body > .action > .header-row > p.style2 {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    text-align: right;
    color: var(--oliver-base-grey);
  }

  .cm-detailed > .detailed-body > .action > .header-row > p.green {
    color: var(--approval);
  }

  .cm-detailed > .detailed-body > .action > .header-row > p.blue {
    color: var(--oliver-blue);
  }

  .cm-detailed > .detailed-body > .action > .header-row > p.red {
    color: var(--error);
  }

  .cm-detailed > .detailed-body > .action > .body-col {
    width: 100%;
  }

  .cm-detailed > .detailed-body > .action > .body-col > p {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .cm-detailed > .detailed-body > .action > .footer-col {
    width: 100%;
    margin-top: 10px;
  }

  .cm-detailed > .detailed-body > .action > .footer-col > p {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .cm-detailed > .detailed-body > .action > .footer-col > p > b {
    font-weight: 600;
  }

  .cm-detailed > .detailed-footer {
    width: 100%;
    padding: 16px;
    border-top: 1px solid var(--oliver-border);
    display: flex;
    box-sizing: border-box;
  }

  .cm-detailed > .detailed-footer > button {
    flex: 1;
    height: 50px;
    margin-left: 20px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  .cm-detailed > .detailed-footer > button:first-of-type {
    margin-left: 0;
  }

  /* --------------------404 Page-------------------- */

  .error-404-wrapper {
    width: 100%;
    height: 100%;
  }

  .error-404-wrapper > .error-404-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 20px;
    background-color: #f1f4f6;
    overflow: auto;
  }

  .error-404-wrapper > .error-404-container > img {
    width: 130px;
    height: 94px;
    margin: auto 0 35px 0;
    content: url("assets/images/svg/OliverIconErrorBlue.svg");
  }

  .error-404-wrapper > .error-404-container > p.style1 {
    font-family: Poppins;
    font-size: 40px;
    font-weight: 700;
    line-height: 53px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 10px;
  }

  .error-404-wrapper > .error-404-container > p.style2 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    color: var(--oliver-blue);
    margin-bottom: 10px;
  }

  .error-404-wrapper > .error-404-container > p.style3 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: auto;
  }

  .error-404-wrapper > .error-404-container > button {
    width: 173px;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin: 20px 0 35px 0;
  }

  .error-404-wrapper > .error-404-container > .row {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
  }

  .error-404-wrapper > .error-404-container > .row > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-right: 4px;
  }

  .error-404-wrapper > .error-404-container > .row > a {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-blue);
  }
}

@media (orientation: portrait) and (max-width: 375px) {
  .avatar {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  input:focus,
  textarea:focus {
    border-width: 0.5333vw !important;
  }

  .loading-page-wrapper {
    padding: 6.6667vw 0;
  }

  .loading-page-wrapper > img {
    width: 26.9333vw;
    height: 7.4667vw;
    margin-bottom: 16vw;
  }

  .loading-page-wrapper > svg {
    width: 22.6667vw;
    height: 22.6667vw;
    margin-bottom: 16vw;
  }

  .loading-page-wrapper > p.style1 {
    font-size: 5.3333vw;
    line-height: 6.9333vw;
    margin-bottom: 2.6667vw;
  }

  .loading-page-wrapper > p.style2 {
    font-size: 4.2667vw;
    line-height: 5.6vw;
    margin-bottom: 16vw;
  }

  .loading-page-wrapper > p.style3 {
    font-size: 3.7333vw;
    line-height: 4.8vw;
  }

  @keyframes animate-svg-fill-8 {
    0% {
      transform: translate(25px, -25px);
      fill: transparent;
    }

    10%,
    40% {
      transform: translate(0px, 0px);
      fill: #ffd026;
    }

    50%,
    100% {
      transform: translate(-25px, 25px);
      fill: transparent;
    }
  }

  .login-wrapper > img {
    width: 36.2667vw;
    height: 12.5867vw;
    margin: 13.3333vw 0 5.0347vw 0;
  }

  .login-wrapper > p {
    font-size: 4.2667vw;
    line-height: 6.1333vw;
    margin-bottom: 11.728vw;
  }

  .login-wrapper > .login-form {
    width: 85.3333vw;
    margin-bottom: 7.4667vw;
  }

  .login-wrapper > .login-form > label {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .login-wrapper > .login-form > input {
    height: 11.7333vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 0.5333vw;
    padding: 0 3.2vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 7.4667vw;
  }

  .login-wrapper > .login-form > .row {
    margin-bottom: 7.4667vw;
  }

  .login-wrapper > .login-form > .row > a {
    font-size: 3.7333vw;
    line-height: 5.6vw;
  }

  .login-wrapper > .login-form > .row > .custom-checkbox-wrapper {
    font-size: 3.2vw;
    line-height: 5.6vw;
  }

  .login-wrapper
    > .login-form
    > .row
    > .custom-checkbox-wrapper
    > input[type="checkbox"] {
    left: -2666.6667vw;
  }

  .login-wrapper
    > .login-form
    > .row
    > .custom-checkbox-wrapper
    > .custom-checkbox {
    width: 5.3333vw;
    height: 5.3333vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.0667vw;
    margin-right: 2.9333vw;
  }

  .login-wrapper > .login-form > button {
    height: 12.8vw;
    border-radius: 1.0667vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .login-wrapper > .or-row {
    width: 85.3333vw;
    margin-bottom: 7.4667vw;
  }

  .login-wrapper > .or-row > .divider {
    width: 31.936vw;
    height: 0.2667vw;
  }

  .login-wrapper > .or-row > p {
    font-size: 5.3333vw;
    line-height: 5.6vw;
  }

  .login-wrapper > button {
    width: 85.3333vw;
    height: 12.8vw;
    padding-left: 12.8vw;
    border-radius: 1.0667vw;
    margin-bottom: 4.2667vw;
    font-size: 4.8vw;
    line-height: 5.6vw;
  }

  .login-wrapper > button:last-of-type {
    margin-bottom: 5.6187vw;
  }

  .login-wrapper > button > .img-container {
    left: 0.5333vw;
    top: 0.5333vw;
    width: 11.7333vw;
    height: 11.7333vw;
    border-radius: 1.0667vw;
  }

  .login-wrapper > #googleButton > .img-container > img {
    width: 12.0507vw;
    height: 10.296vw;
  }

  .login-wrapper > #facebookButton > .img-container > img {
    width: 7.072vw;
    height: 5.6773vw;
  }

  .login-wrapper > #appleButton > .img-container > img {
    width: 10.8213vw;
    height: 7.312vw;
  }

  .login-wrapper > .row {
    margin-bottom: 13.3333vw;
  }

  .login-wrapper > .row > p {
    font-size: 3.7333vw;
    line-height: 5.6vw;
    margin-right: 1.3333vw;
  }

  .login-wrapper > .row > a {
    font-size: 3.7333vw;
    line-height: 5.6vw;
  }

  .choose-wrapper > .choose-header {
    height: 17.0667vw;
    padding: 0 5.3333vw;
  }

  .choose-wrapper > .choose-header > button {
    width: 29.0667vw;
    height: 10.6667vw;
    border: 0.2667vw solid var(--oliver-blue);
    border-radius: 1.6vw;
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .choose-wrapper > .choose-header > button > img {
    width: 1.8933vw;
    height: 3.7867vw;
    margin-right: 3.9733vw;
  }

  .choose-wrapper > .choose-header > p {
    font-size: 4.2667vw;
    line-height: 6.4vw;
    padding-left: 5.3333vw;
  }

  .choose-wrapper > .choose-body-default {
    padding-top: 3.7333vw;
  }

  .choose-wrapper > .choose-body-default button:disabled::after {
    background-size: 6.4vw 6.4vw;
    width: 6.4vw;
    height: 6.4vw;
    right: 4.5333vw;
  }

  .choose-wrapper > .choose-body-default > p {
    font-size: 5.8667vw;
    line-height: 8.5333vw;
    margin-bottom: 3.2vw;
  }

  .choose-wrapper > .choose-body-default > .divider {
    width: 16vw;
    height: 0.2667vw;
    margin-bottom: 6.9333vw;
  }

  .choose-wrapper > .choose-body-default > .button-container {
    width: 89.3333vw;
  }

  .choose-wrapper > .choose-body-default button {
    height: 24.5333vw;
    padding: 0 6.3733vw 0 4.5333vw;
    margin-bottom: 5.8667vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 2.1333vw;
  }

  .choose-wrapper > .choose-body-default button > .img-container {
    width: 14.9333vw;
    height: 14.9333vw;
    margin-right: 4.8vw;
  }

  .choose-wrapper
    > .choose-body-default
    button
    > .img-container
    > img.oliver-icon {
    width: 7.4667vw;
    height: 7.4667vw;
    transform: translateX(0.2667vw);
  }

  .choose-wrapper
    > .choose-body-default
    button
    > .img-container
    > img.www-icon {
    width: 9.6vw;
    height: 9.6vw;
  }

  .choose-wrapper
    > .choose-body-default
    button
    > .img-container
    > img.store-icon {
    width: 7.656vw;
    height: 7.1093vw;
  }

  .choose-wrapper
    > .choose-body-default
    button
    > .img-container
    > img.register-icon {
    width: 7.2vw;
    height: 7.2vw;
  }

  .choose-wrapper
    > .choose-body-default
    button
    > .img-container
    > img.kiosk-icon {
    width: 9.8667vw;
    height: 9.8667vw;
  }

  .choose-wrapper > .choose-body-default button > .col > p.style1 {
    font-size: 4.2667vw;
    line-height: 6.1333vw;
  }

  .choose-wrapper > .choose-body-default button > .col > p.style2 {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .choose-wrapper > .choose-body-default button > img {
    width: 2.6933vw;
    height: 5.3867vw;
  }

  .choose-wrapper > .choose-body-default button#addNew {
    height: 10.7733vw;
    font-size: 4.2667vw;
    line-height: 5.8667vw;
  }

  .choose-wrapper > .choose-body-default button#addNew > img.mobile {
    width: 6.4vw;
    height: 6.4vw;
    margin-right: 2.9333vw;
  }

  .subwindow-wrapper > .subwindow {
    border-radius: 2.1333vw;
    filter: drop-shadow(0vw 2.6667vw 5.3333vw rgba(0, 0, 0, 0.25));
  }

  .subwindow-wrapper > .subwindow > .subwindow-header {
    height: 14.4vw;
    border-bottom: 0.2667vw solid var(--oliver-border);
  }

  .subwindow-wrapper > .subwindow > .subwindow-header > p {
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper > .subwindow > .subwindow-header > button.close-subwindow {
    top: 2.9333vw;
    right: 2.9333vw;
    width: 8.5333vw;
    height: 8.5333vw;
    border-radius: 1.6vw;
  }

  .subwindow-wrapper
    > .subwindow
    > .subwindow-header
    > button.close-subwindow
    > img {
    width: 3.2vw;
    height: 3.2vw;
  }

  .subwindow-wrapper > .subwindow > .subwindow-body {
    border-radius: 0 0 2.1333vw 2.1333vw;
    padding: 5.3333vw 0;
  }

  .subwindow.update-plugin {
    width: min(91.7333vw, 95%);
    height: min(75.7333vw, 95%);
  }

  .subwindow.update-plugin > .subwindow-body {
    border-radius: 2.1333vw;
  }

  .subwindow.update-plugin > .subwindow-body > img {
    width: 10.6667vw;
    height: 10.6667vw;
    margin: auto 0 2.6667vw 0;
  }

  .subwindow.update-plugin > .subwindow-body > p.style1 {
    font-size: 4.2667vw;
    line-height: 5.6vw;
    margin-bottom: 2.6667vw;
  }

  .subwindow.update-plugin > .subwindow-body > p.style2 {
    font-size: 3.7333vw;
    line-height: 4.8vw;
    margin-bottom: 8.2667vw;
  }

  .subwindow.update-plugin > .subwindow-body > button {
    width: 81.6vw;
    height: 13.3333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow.cart-no-item {
    width: min(91.7333vw, 95%);
    height: min(57.3333vw, 95%);
  }

  .subwindow.cart-no-item > .subwindow-body {
    border-radius: 2.1333vw;
  }

  .subwindow.cart-no-item > .subwindow-body > p.style1 {
    font-size: 4.2667vw;
    line-height: 5.6vw;
    margin: auto 0 5.3333vw 0;
  }

  .subwindow.cart-no-item > .subwindow-body > p.style2 {
    font-size: 3.7333vw;
    line-height: 4.8vw;
    margin-bottom: 11.4667vw;
  }

  .subwindow.cart-no-item > .subwindow-body > button {
    width: 81.6vw;
    height: 13.3333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow.discount-error {
    width: min(91.7333vw, 95%);
    height: min(86.6667vw, 95%);
  }

  .subwindow.discount-error > .subwindow-body {
    border-radius: 2.1333vw;
  }

  .subwindow.discount-error > .subwindow-body > p.style1 {
    font-size: 4.2667vw;
    line-height: 5.6vw;
    margin-bottom: 5.3333vw;
    margin: auto 0 5.3333vw 0;
  }

  .subwindow.discount-error > .subwindow-body > p.style2 {
    font-size: 3.7333vw;
    line-height: 4.8vw;
    margin-bottom: 5.3333vw;
  }

  .subwindow.discount-error > .subwindow-body > p:last-of-type {
    margin-bottom: 13.3333vw;
  }

  .subwindow.discount-error > .subwindow-body > button {
    width: 81.6vw;
    height: 13.3333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow.product-unsupported {
    width: min(91.7333vw, 95%);
    height: min(99.2vw, 95%);
  }

  .subwindow.product-unsupported > .subwindow-body {
    border-radius: 2.1333vw;
  }

  .subwindow.product-unsupported > .subwindow-body > p.style1 {
    margin: auto 0 7.2vw 0;
    font-size: 4.2667vw;
    line-height: 5.6vw;
  }

  .subwindow.product-unsupported > .subwindow-body > img {
    width: 13.3333vw;
    height: 13.8667vw;
    margin-bottom: 7.2vw;
  }

  .subwindow.product-unsupported > .subwindow-body > p.style2 {
    font-size: 3.7333vw;
    line-height: 4.8vw;
    margin-bottom: 8.8vw;
  }

  .subwindow.product-unsupported > .subwindow-body > button {
    width: 81.6vw;
    height: 13.3333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow.refund-show-payments {
    width: min(91.7333vw, 95%);
  }

  .subwindow.refund-show-payments > .subwindow-body {
    max-height: calc(95vh - 14.4vw);
    padding: 5.3333vw 9.6vw;
  }

  .subwindow.refund-show-payments > .subwindow-body > .row {
    margin-top: 2.9333vw;
  }

  .subwindow.refund-show-payments > .subwindow-body > .row > p {
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow.refund-show-payments > .subwindow-body > button {
    height: 13.3333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
    margin-top: 8.8vw;
  }

  .subwindow.knowledge-base > .subwindow-header {
    height: 18.1333vw;
    padding: 0 13.3333vw 0 4.2667vw;
  }

  .subwindow.knowledge-base > .subwindow-header > .icon-container {
    width: 12vw;
    height: 12vw;
    border-radius: 2.1333vw;
    margin-right: 3.7333vw;
  }

  .subwindow.knowledge-base > .subwindow-header > p {
    font-size: 4.2667vw;
    line-height: 5.8667vw;
  }

  .subwindow.knowledge-base > .subwindow-header > button#kBRefresh {
    width: 9.0667vw;
    height: 9.0667vw;
    border-radius: 2.1333vw;
  }

  .subwindow.knowledge-base > .subwindow-header > button#kBRefresh > img {
    width: 5.3333vw;
    height: 5.3333vw;
  }

  .subwindow.knowledge-base > .subwindow-header > button.close-subwindow {
    top: calc(50% - 4.2667vw);
    width: 9.0667vw;
    height: 9.0667vw;
    border-radius: 2.1333vw;
  }

  .subwindow.no-permissions {
    width: min(91.7333vw, 95%);
    height: min(99.2vw, 95%);
  }

  .subwindow.no-permissions > .subwindow-body {
    border-radius: 2.1333vw;
  }

  .subwindow.no-permissions > .subwindow-body > img {
    width: 13.3333vw;
    height: 13.3333vw;
    padding-top: 6.6667vw;
    margin: auto 0 7.4667vw 0;
  }

  .subwindow.no-permissions > .subwindow-body > p.style1 {
    font-size: 4.2667vw;
    line-height: 5.6vw;
    margin-bottom: 7.4667vw;
  }

  .subwindow.no-permissions > .subwindow-body > p.style2 {
    font-size: 3.7333vw;
    line-height: 4.8vw;
    margin-bottom: 15.7333vw;
  }

  .subwindow.no-permissions > .subwindow-body > button {
    width: 81.6vw;
    height: 13.3333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow.no-account > .subwindow-body {
    padding: 10.6667vw 0 8.8vw 0;
  }

  .subwindow.no-account > .subwindow-body > .content > img.mobile-only {
    width: 36.2667vw;
    height: 12.5333vw;
    margin-bottom: 5.0667vw;
  }

  .subwindow.no-account > .subwindow-body > .content > p.mobile-only {
    font-size: 4.2667vw;
    line-height: 6.1333vw;
    margin-bottom: 5.3333vw;
  }

  .subwindow.no-account > .subwindow-body > .content > p.style1 {
    font-size: 4.2667vw;
    line-height: 5.0667vw;
    margin: auto 0 13.3333vw 0;
  }

  .subwindow.no-account > .subwindow-body > .content > p.style2 {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 13.3333vw;
  }

  .subwindow.no-account > .subwindow-body > .content > button#createAccount {
    width: 65.6vw;
    height: 13.3333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow.no-account > .subwindow-body > .content > button#loginTryAgain {
    width: 46.1333vw;
    height: 13.3333vw;
    border: 0.2667vw solid var(--oliver-blue);
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
    margin: 9.3333vw 0;
  }

  .subwindow.no-account > .subwindow-body > .content > .row > p {
    font-size: 4.2667vw;
    line-height: 5.6vw;
  }

  .subwindow.no-account > .subwindow-body > .content > .row > a {
    font-size: 4.2667vw;
    line-height: 5.6vw;
    margin-left: 1.0667vw;
  }

  .subwindow-wrapper > .subwindow.takeover-register {
    width: min(91.7333vw, 95%);
    height: min(89.6vw, 95%);
  }

  .subwindow-wrapper > .subwindow.takeover-register > .subwindow-body > p {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 10.4vw;
  }

  .subwindow-wrapper > .subwindow.takeover-register > .subwindow-body > p > b {
    font-weight: 160vw;
  }

  .subwindow-wrapper > .subwindow.takeover-register > .subwindow-body > button {
    width: 35.4667vw;
    height: 10.6667vw;
    border-radius: 1.6vw;
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper
    > .subwindow.takeover-register
    > .subwindow-body
    > button#takeoverRegister {
    margin-bottom: 3.7333vw;
  }

  .subwindow-wrapper
    > .subwindow.takeover-register
    > .subwindow-body
    > button#cancelTakeover {
    border: 0.2667vw solid var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.register-taken {
    width: min(91.7333vw, 95%);
    height: min(79.4667vw, 95%);
  }

  .subwindow-wrapper > .subwindow.register-taken > .subwindow-body > img {
    width: 13.3333vw;
    height: 13.8667vw;
    margin-bottom: 8vw;
  }

  .subwindow-wrapper > .subwindow.register-taken > .subwindow-body > p {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-header
    > img {
    margin: 0 3.7333vw 0 4.2667vw;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header {
    height: 19.7333vw;
    border-bottom: 0.2667vw solid var(--oliver-border);
    padding: 0 4.2667vw;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > .link-wrapper
    > img {
    width: 10.6667vw;
    height: 10.6667vw;
    border-radius: 1.6vw;
    margin-right: 4.2667vw;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > .link-wrapper
    > .text-col
    > p.style1 {
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > .link-wrapper
    > .text-col
    > p.style2 {
    font-size: 3.2vw;
    line-height: 4.5333vw;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > button {
    width: 34.1333vw;
    height: 8.5333vw;
    border: 0.2667vw solid var(--oliver-base-grey);
    border-radius: 1.6vw;
    font-size: 3.4667vw;
    line-height: 4.5333vw;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > button
    > img {
    width: 3.4667vw;
    height: 3.4667vw;
    margin-right: 1.6vw;
  }

  .subwindow-wrapper > .subwindow.app-launcher-subwindow > .subwindow-header {
    padding: 4.2667vw 12.8vw 4.2667vw 4.2667vw;
  }

  .subwindow-wrapper
    > .subwindow.app-launcher-subwindow
    > .subwindow-header
    > .app-wrapper
    > img {
    width: 13.3333vw;
    height: 13.3333vw;
    border-radius: 2.6667vw;
    margin-right: 5.0667vw;
  }

  .subwindow-wrapper
    > .subwindow.app-launcher-subwindow
    > .subwindow-header
    > .app-wrapper
    > .text-col
    > p.style1 {
    font-size: 4.8vw;
    line-height: 6.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.app-launcher-subwindow
    > .subwindow-header
    > .app-wrapper
    > .text-col
    > p.style2 {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .subwindow.create-customer > .subwindow-body {
    padding: 5.3333vw;
  }

  .subwindow.create-customer > .subwindow-body > div:not(.button-row) {
    border-top: 0.2667vw solid var(--oliver-border);
    padding-top: 4vw;
    margin-top: 4.2667vw;
  }

  .subwindow.create-customer > .subwindow-body > div > p {
    font-size: 4vw;
    line-height: 6.1333vw;
  }

  .subwindow.create-customer > .subwindow-body > div > .input-row {
    margin-top: 4.2667vw;
  }

  .subwindow.create-customer > .subwindow-body > div > .input-row > .input-col {
    margin-top: 4.2667vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > label {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    padding-bottom: 1.0667vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input {
    height: 11.2vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.6vw;
    padding: 0 3.2vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input:focus {
    border-width: 0.5333vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input.error
    ~ .error-wrapper::after {
    font-size: 3.4667vw;
    line-height: 5.0667vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper {
    height: 11.2vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > input:focus {
    border-width: 0.2667vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open
    > input {
    border-radius: 1.6vw 1.6vw 0 0;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > img {
    width: 5.3333vw;
    height: 5.3333vw;
    right: 3.2vw;
    top: calc(50% - 2.6667vw);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open
    > img {
    transform: rotate(180deg) translateY(-0.5333vw);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container {
    border: 0.2667vw solid var(--oliver-blue);
    border-radius: 0 0 1.6vw 1.6vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option {
    height: 11.2vw;
    padding: 0 3.2vw;
    border-radius: 0 0 1.6vw 1.6vw;
    border-top: 0.2667vw solid var(--oliver-blue);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option
    > p {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .subwindow.create-customer > .subwindow-body > div > .title-row > p {
    font-size: 4vw;
    line-height: 6.1333vw;
  }

  .subwindow.create-customer > .subwindow-body > div > .title-row > label {
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > input {
    left: -2666.6667vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > .custom-checkbox {
    width: 4.8vw;
    height: 4.8vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.0667vw;
    margin-right: 3.2vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > .custom-checkbox
    > img {
    width: 2.6667vw;
    height: 2.6667vw;
  }

  .subwindow.create-customer > .subwindow-body > .button-row {
    margin-top: 8vw;
  }

  .subwindow.create-customer > .subwindow-body > .button-row > button {
    height: 11.7333vw;
    margin-top: 4.2667vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow.edit-customer > .subwindow-body {
    padding: 5.3333vw;
  }

  .subwindow.edit-customer > .subwindow-body > div:not(.button-row) {
    border-top: 0.2667vw solid var(--oliver-border);
    padding-top: 4vw;
    margin-top: 4.2667vw;
  }

  .subwindow.edit-customer > .subwindow-body > div > p {
    font-size: 4vw;
    line-height: 6.1333vw;
  }

  .subwindow.edit-customer > .subwindow-body > div > .input-row {
    margin-top: 4.2667vw;
  }

  .subwindow.edit-customer > .subwindow-body > div > .input-row > .input-col {
    margin-top: 4.2667vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > label {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    padding-bottom: 1.0667vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input {
    height: 11.2vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.6vw;
    padding: 0 3.2vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input:focus {
    border-width: 0.5333vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input.error
    ~ .error-wrapper::after {
    font-size: 3.4667vw;
    line-height: 5.0667vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper {
    height: 11.2vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > input:focus {
    border-width: 0.2667vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open
    > input {
    border-radius: 1.6vw 1.6vw 0 0;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > img {
    width: 5.3333vw;
    height: 5.3333vw;
    right: 3.2vw;
    top: calc(50% - 2.6667vw);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open
    > img {
    transform: rotate(180deg) translateY(-0.5333vw);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container {
    border: 0.2667vw solid var(--oliver-blue);
    border-radius: 0 0 1.6vw 1.6vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option {
    height: 11.2vw;
    padding: 0 3.2vw;
    border-radius: 0 0 1.6vw 1.6vw;
    border-top: 0.2667vw solid var(--oliver-blue);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option
    > p {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .subwindow.edit-customer > .subwindow-body > div > .title-row > p {
    font-size: 4vw;
    line-height: 6.1333vw;
  }

  .subwindow.edit-customer > .subwindow-body > div > .title-row > label {
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > input {
    left: -2666.6667vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > .custom-checkbox {
    width: 4.8vw;
    height: 4.8vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.0667vw;
    margin-right: 3.2vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > .custom-checkbox
    > img {
    width: 2.6667vw;
    height: 2.6667vw;
  }

  .subwindow.edit-customer > .subwindow-body > .button-row {
    margin-top: 8vw;
  }

  .subwindow.edit-customer > .subwindow-body > .button-row > button {
    height: 11.7333vw;
    margin-top: 4.2667vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > .button-row
    > button#editCustomerDelete {
    border: 0.2667vw solid var(--error);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > .button-row
    > button#editCustomerDelete
    > img {
    width: 5.3333vw;
    height: 5.6vw;
    margin-right: 2.6667vw;
  }

  .subwindow.delete-customer-confirm {
    width: min(91.7333vw, 95%);
    height: min(77.8667vw, 95%);
  }

  .subwindow.delete-customer-confirm > .subwindow-body > p {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 9.0667vw;
  }

  .subwindow.delete-customer-confirm > .subwindow-body > button {
    width: 35.4667vw;
    height: 10.6667vw;
    border-radius: 1.6vw;
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .subwindow.delete-customer-confirm > .subwindow-body > button#deleteCustomer {
    margin-bottom: 3.7333vw;
  }

  .subwindow.delete-customer-confirm
    > .subwindow-body
    > button#cancelDeleteCustomer {
    border: 0.2667vw solid var(--oliver-blue);
  }

  .subwindow.custom-fee {
    width: min(102.4vw, 95%);
    height: min(142.6667vw, 95%);
  }

  .subwindow.cart-discount {
    width: min(102.4vw, 95%);
    height: min(197.3333vw, 95%);
  }

  .subwindow.custom-fee > .subwindow-body > p,
  .subwindow.cart-discount > .subwindow-body > p {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 2.4vw;
  }

  .subwindow.custom-fee > .subwindow-body > .toggle-container,
  .subwindow.cart-discount > .subwindow-body > .toggle-container {
    width: 76vw;
    height: 13.3333vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 2.1333vw;
    padding: 1.6vw;
    margin-bottom: 6.4vw;
  }

  .subwindow.custom-fee > .subwindow-body > .toggle-container > label > input,
  .subwindow.cart-discount
    > .subwindow-body
    > .toggle-container
    > label
    > input {
    left: -2666.6667vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > .toggle-container
    > label
    > .custom-radio,
  .subwindow.cart-discount
    > .subwindow-body
    > .toggle-container
    > label
    > .custom-radio {
    border-radius: 1.6vw;
    font-size: 4.2667vw;
    line-height: 5.6vw;
  }

  .subwindow.custom-fee > .subwindow-body > #customFeeDiv {
    width: 80.5333vw;
  }

  .subwindow.custom-fee > .subwindow-body > #customFeeDiv > label {
    padding-bottom: 2.1333vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > input#customFeeLabel {
    height: 11.7333vw;
    margin-bottom: 3.7333vw;
    padding: 0 3.2vw;
    border-radius: 1.6vw;
    border: 0.2667vw solid var(--oliver-border);
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > input#customFeeLabel:focus {
    border-width: 0.5333vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > input#customFeeAmount {
    height: 22.1333vw;
    border-radius: 1.6vw;
    border: 0.2667vw solid var(--oliver-border);
    padding: 0 3.2vw;
    font-size: 10.6667vw;
    line-height: 14.9333vw;
    margin-bottom: 2.1333vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > input#customFeeAmount:focus {
    border-width: 0.5333vw;
  }

  .subwindow.custom-fee > .subwindow-body > #customFeeDiv > p {
    font-size: 3.4667vw;
    line-height: 4.8vw;
    margin-bottom: 5.3333vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > .custom-toggle-wrapper {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 8vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > .custom-toggle-wrapper
    > input {
    left: -2666.6667vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > .custom-toggle-wrapper
    > .custom-toggle {
    width: 10.6667vw;
    height: 6.6667vw;
    border-radius: 6.6667vw;
    margin-left: 5.3333vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > .custom-toggle-wrapper
    > .custom-toggle::after {
    left: 0.5333vw;
    top: calc(50% - 2.9333vw);
    width: 5.8667vw;
    height: 5.8667vw;
    box-shadow: 0vw 0.5333vw 1.6vw rgba(0, 0, 0, 0.15),
      0vw 0.5333vw 0.2667vw rgba(0, 0, 0, 0.06);
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > .custom-toggle-wrapper
    > input:checked
    ~ .custom-toggle::after {
    right: 0.5333vw;
  }

  .subwindow.custom-fee > .subwindow-body > #customFeeDiv > button {
    height: 13.3333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow.cart-discount > .subwindow-body > #cartDiscountDiv > .main {
    width: 80.5333vw;
    padding: 5.8667vw 0 8vw 0;
    border-top: 0.2667vw solid var(--oliver-border);
    border-bottom: 0.2667vw solid var(--oliver-border);
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > label {
    padding-bottom: 2.4vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > input {
    height: 22.1333vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.6vw;
    padding: 0 3.2vw;
    font-size: 10.6667vw;
    line-height: 14.9333vw;
    margin-bottom: 6.1333vw;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > input:focus {
    border-width: 0.5333vw;
  }

  .subwindow.cart-discount > .subwindow-body > #cartDiscountDiv > .main > p {
    font-size: 3.4667vw;
    line-height: 4.8vw;
    margin-bottom: 3.2vw;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > .button-row
    > button {
    height: 11.7333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
    margin-left: 3.2vw;
  }

  .subwindow.cart-discount > .subwindow-body > #cartDiscountDiv > .list {
    padding-top: 6.6667vw;
  }

  .subwindow.cart-discount > .subwindow-body > #cartDiscountDiv > .list > p {
    font-size: 3.7333vw;
    line-height: 4.8vw;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .list
    > button {
    width: 76vw;
    height: 11.7333vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.6vw;
    margin-top: 4vw;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .list
    > button
    > p {
    font-size: 4.2667vw;
    line-height: 5.6vw;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .list
    > button#clearCartDiscountDiv
    > p {
    font-size: 3.7333vw;
    line-height: 4.8vw;
  }

  .subwindow-wrapper > .subwindow.add-tile {
    width: min(102.4vw, 95%);
    height: min(113.3333vw, 95%);
  }

  .subwindow-wrapper > .subwindow.add-tile > .subwindow-body > label {
    width: 81.6vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 2.4vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search {
    width: 81.6vw;
    height: 11.7333vw;
    margin-bottom: 7.4667vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > img.search {
    width: 6.4vw;
    height: 6.4vw;
    left: 2.6667vw;
    top: 2.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > button#cancelDropdownSearch {
    width: 4.8vw;
    height: 4.8vw;
    right: 4vw;
    top: 3.8667vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > input {
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.6vw;
    padding: 0 10.1333vw 0 10.6667vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search.open
    > input {
    border-radius: 1.6vw 1.6vw 0 0;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .error-message {
    top: -2.1333vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .error-message::after {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .option-container {
    border-radius: 0 0 1.6vw 1.6vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .option-container
    > .dropdown-option {
    height: 11.7333vw;
    padding: 0 3.2vw;
    border: 0.2667vw solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .option-container
    > .dropdown-option
    > p {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .dropdown-option:last-child {
    border-radius: 0 0 1.6vw 1.6vw;
  }

  .subwindow-wrapper > .subwindow.add-tile > .subwindow-body > p {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 2.9333vw;
  }

  .subwindow-wrapper > .subwindow.add-tile > .subwindow-body > .radio-group {
    width: 62.896vw;
    margin-bottom: 2.944vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .radio-group
    > label {
    margin-right: 4.6027vw;
    margin: 0 4.6027vw 3.9893vw 0;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .radio-group
    > label
    > input[type="radio"] {
    left: -2666.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .radio-group
    > label
    > .custom-radio-button {
    width: 12.2667vw;
    height: 12.2667vw;
    border-radius: 1.8667vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .radio-group
    > label
    > input[type="radio"]:checked
    ~ .custom-radio-button {
    border: 0.5333vw solid #000 !important;
  }

  .subwindow-wrapper > .subwindow.add-tile > .subwindow-body > button {
    width: 81.6vw;
    height: 13.3333vw;
    border-radius: 2.1333vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper > .subwindow.add-order-note {
    width: min(102.4vw, 95%);
    height: min(104vw, 95%);
  }

  .subwindow-wrapper > .subwindow.add-order-note > .subwindow-body > label {
    width: 81.6vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 3.2vw;
  }

  .subwindow-wrapper > .subwindow.add-order-note > .subwindow-body > textarea {
    width: 81.6vw;
    height: 48vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.6vw;
    padding: 3.2vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 5.0667vw;
  }

  .subwindow-wrapper > .subwindow.add-order-note > .subwindow-body > button {
    width: 81.6vw;
    height: 13.3333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper > .subwindow.product-not-found {
    width: min(99.2vw, 95%);
    height: min(99.2vw, 95%);
  }

  .subwindow-wrapper > .subwindow.product-not-found > .subwindow-body {
    border-radius: 2.1333vw;
  }

  .subwindow-wrapper
    > .subwindow.product-not-found
    > .subwindow-body
    > p.style1 {
    font-size: 4.2667vw;
    line-height: 5.6vw;
    margin-bottom: 5.3333vw;
    margin: 9.0667vw 0 5.3333vw 0;
  }

  .subwindow-wrapper > .subwindow.product-not-found > .subwindow-body > img {
    width: 21.6453vw;
    height: 13.7333vw;
    margin-bottom: 9.2vw;
    transform: translateX(2.6667vw);
  }

  .subwindow-wrapper
    > .subwindow.product-not-found
    > .subwindow-body
    > p.style2 {
    font-size: 3.7333vw;
    line-height: 4.8vw;
    margin-bottom: 13.6vw;
  }

  .subwindow-wrapper > .subwindow.product-not-found > .subwindow-body > button {
    width: 81.6vw;
    height: 13.3333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper > .subwindow.upgrade-to-unlock {
    width: min(99.2vw, 95%);
    height: min(99.2vw, 95%);
  }

  .subwindow-wrapper > .subwindow.upgrade-to-unlock > .subwindow-body {
    border-radius: 2.1333vw;
  }

  .subwindow-wrapper > .subwindow.upgrade-to-unlock > .subwindow-body > img {
    width: 13.3333vw;
    height: 13.3333vw;
    margin: 6.9333vw 0 7.4667vw 0;
  }

  .subwindow-wrapper
    > .subwindow.upgrade-to-unlock
    > .subwindow-body
    > p.style1 {
    font-size: 4.2667vw;
    line-height: 5.6vw;
    margin-bottom: 7.4667vw;
  }

  .subwindow-wrapper
    > .subwindow.upgrade-to-unlock
    > .subwindow-body
    > p.style2 {
    font-size: 3.7333vw;
    line-height: 4.8vw;
    margin-bottom: 10.9333vw;
  }

  .subwindow-wrapper > .subwindow.upgrade-to-unlock > .subwindow-body > button {
    width: 81.6vw;
    height: 13.3333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper > .subwindow.advanced-search > .subwindow-header {
    height: 24vw;
    border-radius: 2.1333vw 2.1333vw 0 0;
  }

  .subwindow-wrapper > .subwindow.advanced-search > .subwindow-header > p {
    font-size: 4.8vw;
    line-height: 6.4vw;
    margin-bottom: 5.6vw;
  }

  .subwindow-wrapper > .subwindow.advanced-search > .subwindow-header > button {
    width: 8.5333vw;
    height: 8.5333vw;
    top: 2.9333vw;
    right: 2.9333vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper {
    width: 82.6667vw;
    height: 14.6667vw;
    top: 17.0667vw;
    left: calc(50% - 41.3333vw);
    box-shadow: 0vw 1.3333vw 6.6667vw rgba(0, 0, 0, 0.2);
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > #advSearchInputIcon {
    width: 8vw;
    height: 7.7333vw;
    top: 3.4667vw;
    left: 2.9333vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > #advSearchInputCancel {
    width: 8vw;
    height: 8vw;
    right: 2.9333vw;
    top: 3.4667vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > #advSearchInputCancel
    > img {
    width: 4.6667vw;
    height: 4.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > input {
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.6vw;
    padding: 0 13.8667vw 0 13.8667vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col {
    padding-top: 13.6vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group {
    width: 82.6667vw;
    height: 10.6667vw;
    margin-bottom: 5.0667vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > .dropdown-input {
    border: 0.2667vw solid var(--oliver-border);
    padding: 0 3.2vw;
    border-radius: 2.1333vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > .dropdown-input.open {
    border-radius: 2.1333vw 2.1333vw 0 0;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > .dropdown-input
    > p {
    font-size: 3.2vw;
    line-height: 4.5333vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > label {
    border: 0.2667vw solid var(--oliver-border);
    padding: 0 3.2vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > label:last-of-type {
    border-radius: 0 0 2.1333vw 2.1333vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > label
    > input[type="radio"] {
    left: -2666.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > label
    > p {
    font-size: 3.2vw;
    line-height: 4.5333vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .start-searching {
    margin-top: -14.4vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .start-searching
    > img {
    width: 11.4667vw;
    height: 11.2vw;
    margin-bottom: 8vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .start-searching
    > p.style1 {
    font-size: 4.2667vw;
    line-height: 5.8667vw;
    margin-bottom: 5.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .start-searching
    > p.style2 {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .header {
    width: 82.6667vw;
    border-bottom: 0.2667vw solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .header
    > p {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 1.5173vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body {
    padding: 4vw 0;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results
    > p.style1 {
    font-size: 4.2667vw;
    line-height: 6.1333vw;
    margin-bottom: 1.5173vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results
    > p.style2 {
    font-size: 3.2vw;
    line-height: 4.8vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results
    > .divider {
    width: 26.6667vw;
    height: 0.2667vw;
    margin: 8vw 0;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results
    > button {
    width: 56vw;
    height: 10.6667vw;
    border: 0.2667vw solid var(--oliver-blue);
    border-radius: 1.6vw;
    font-size: 3.2vw;
    line-height: 4.2667vw;
    margin-top: 5.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results
    > button
    > img {
    width: 4.2667vw;
    height: 4.2667vw;
    margin-right: 2.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result {
    width: 82.6667vw;
    height: 53.3333vw;
    border-radius: 1.6vw;
    padding: 4vw 4.4vw 4.8vw 4.4vw;
    margin-top: 3.4667vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result.product {
    height: 58.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result.group {
    height: 58.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .col
    > p.style1 {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 1.0667vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .col
    > p.style2 {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 1.0667vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .col
    > p.style3 {
    font-size: 3.2vw;
    line-height: 4.5333vw;
    margin-bottom: 0.5333vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button {
    width: 23.2vw;
    height: 17.3333vw;
    border-radius: 1.6vw;
    margin-right: 2.1333vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-view {
    font-size: 2.9333vw;
    line-height: 4vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-view
    > img {
    width: 5.3333vw;
    height: 5.3333vw;
    margin-bottom: 0.8vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-transactions {
    font-size: 2.9333vw;
    line-height: 4vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-transactions
    > img {
    width: 6.4vw;
    height: 6.4vw;
    margin-bottom: 0.8vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-add-to-sale {
    font-size: 2.9333vw;
    line-height: 4vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-add-to-sale
    > img {
    width: 6.4vw;
    height: 6.4vw;
    margin-bottom: 0.8vw;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > .group {
    margin: 2.6667vw 0 4.5333vw 0;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > .group > img {
    width: 5.2587vw;
    height: 5.3333vw;
    margin-right: 3.008vw;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > .group > p {
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > p.style1 {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 6.4vw;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > .divider {
    width: 17.3333vw;
    height: 0.2667vw;
    margin-bottom: 6.4vw;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > p.style2 {
    font-size: 5.3333vw;
    line-height: 7.7333vw;
    margin-bottom: 6.4vw;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > .pinpad {
    width: 74.1333vw;
    margin-bottom: 2.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > p.error-message {
    padding: 2.9333vw 7.4667vw;
    border-radius: 2.1333vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin: -2.6667vw 0 5.3333vw 0;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-entries {
    margin-bottom: 4.5333vw;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-entries
    > .pin-entry {
    width: 4.2667vw;
    height: 4.2667vw;
    margin-left: 2.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row {
    gap: 4.2667vw;
    margin-bottom: 4.2667vw;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row
    > button {
    width: 21.8667vw;
    height: 21.8667vw;
    border: 0.2667vw solid #b0bec9;
    border-radius: 2.1333vw;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row
    > button
    > p {
    font-size: 9.6vw;
    line-height: 9.6vw;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row
    > button.backspace
    > img {
    width: 13.3333vw;
    height: 10.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row
    > button
    > .text-row {
    font-size: 2.6667vw;
    line-height: 4vw;
    letter-spacing: 0vw;
  }

  .subwindow-wrapper
    > .subwindow.end-session
    > .subwindow-body
    > button.mobile-close-subwindow {
    width: 29.0667vw;
    height: 10.6667vw;
    border: 0.2667vw solid var(--oliver-blue);
    border-radius: 1.6vw;
    font-size: 4.2667vw;
    line-height: 6.4vw;
    margin: 5.3333vw 0 0 5.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.end-session
    > .subwindow-body
    > button.mobile-close-subwindow
    > img {
    width: 1.8933vw;
    height: 3.7867vw;
    margin-right: 3.9733vw;
  }

  .subwindow-wrapper > .subwindow.end-session > .subwindow-body > p.style1 {
    font-size: 4.8vw;
    line-height: 6.4vw;
    margin: 5.3333vw 0 4.5333vw 0;
  }

  .subwindow-wrapper > .subwindow.end-session > .subwindow-body > p.style2 {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 7.2vw;
  }

  .subwindow-wrapper
    > .subwindow.end-session
    > .subwindow-body
    > button:not(.mobile-close-subwindow) {
    width: 61.3333vw;
    height: 13.3333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
    margin-bottom: 5.3333vw;
  }

  .subwindow-wrapper > .subwindow.partial-payment {
    width: min(106.6667vw, 95%);
    height: min(106.6667vw, 95%);
  }

  .subwindow-wrapper > .subwindow.partial-payment > .subwindow-body > label {
    width: 81.6vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 2.1333vw;
  }

  .subwindow-wrapper > .subwindow.partial-payment > .subwindow-body > input {
    width: 81.6vw;
    height: 18.6667vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 0.5333vw;
    font-size: 10.6667vw;
    line-height: 14.9333vw;
    margin-bottom: 7.4667vw;
  }

  .subwindow-wrapper > .subwindow.partial-payment > .subwindow-body > p {
    font-size: 4.2667vw;
    line-height: 5.3333vw;
    margin-bottom: 3.2vw;
  }

  .subwindow-wrapper
    > .subwindow.partial-payment
    > .subwindow-body
    > .payment-types {
    width: 81.6vw;
  }

  .subwindow-wrapper
    > .subwindow.partial-payment
    > .subwindow-body
    > .payment-types
    > button {
    width: 25.0667vw;
    height: 25.0667vw;
    margin: 0 3.2vw 3.2vw 0;
    border-radius: 2.1333vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper > .subwindow.product-discount {
    width: min(102.4vw, 95%);
    height: min(91.7333vw, 95%);
  }

  .subwindow-wrapper > .subwindow.product-discount > .subwindow-body > label {
    width: 80.5333vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 2.4vw;
  }

  .subwindow-wrapper > .subwindow.product-discount > .subwindow-body > input {
    width: 80.5333vw;
    height: 22.1333vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.6vw;
    font-size: 10.6667vw;
    line-height: 14.9333vw;
    margin-bottom: 6.1333vw;
  }

  .subwindow-wrapper > .subwindow.product-discount > .subwindow-body > p {
    font-size: 3.4667vw;
    line-height: 4.8vw;
    margin-bottom: 3.2vw;
  }

  .subwindow-wrapper
    > .subwindow.product-discount
    > .subwindow-body
    > .button-row {
    width: 80.5333vw;
  }

  .subwindow-wrapper
    > .subwindow.product-discount
    > .subwindow-body
    > .button-row
    > button {
    height: 11.7333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper
    > .subwindow.product-discount
    > .subwindow-body
    > .button-row
    > button:first-of-type {
    margin-right: 3.7333vw;
  }

  .subwindow-wrapper > .subwindow.adjust-inventory {
    width: min(102.4vw, 95%);
    height: min(122.1333vw, 95%);
  }

  .subwindow-wrapper > .subwindow.adjust-inventory > .subwindow-body > p {
    font-size: 4.8vw;
    line-height: 6.6667vw;
    margin-bottom: 4vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > p:first-of-type {
    margin: 5.3333vw 0 1.6vw 0;
  }

  .subwindow-wrapper > .subwindow.adjust-inventory > .subwindow-body > label {
    font-size: 4.2667vw;
    line-height: 5.6vw;
    margin-bottom: 2.6667vw;
  }

  .subwindow-wrapper > .subwindow.adjust-inventory > .subwindow-body > input {
    width: 81.6vw;
    height: 18.6667vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.6vw;
    font-size: 10.6667vw;
    line-height: 14.9333vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > button#editStockButton {
    padding: 2.6667vw;
    font-size: 4.2667vw;
    line-height: 5.6vw;
    margin-bottom: 1.8667vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > button#editStockButton
    > img {
    width: 5.0667vw;
    height: 5.0667vw;
    margin-right: 1.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > button#updateStockButton {
    width: 81.6vw;
    height: 13.3333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
    margin-bottom: 8.5333vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > .text-row {
    width: 81.6vw;
    margin-bottom: 2.4vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > :nth-last-child(2) {
    margin-bottom: 5.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > .text-row
    > p {
    font-size: 4.2667vw;
    line-height: 5.8667vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > .text-row
    > p:first-of-type {
    margin-right: 5.3333vw;
  }

  .subwindow-wrapper > .subwindow.out-of-stock {
    width: min(91.7333vw, 95%);
    height: min(86.1333vw, 95%);
  }

  .subwindow-wrapper > .subwindow.out-of-stock > .subwindow-body {
    border-radius: 2.1333vw;
  }

  .subwindow-wrapper > .subwindow.out-of-stock > .subwindow-body > p.style1 {
    font-size: 4.2667vw;
    line-height: 5.6vw;
    margin-bottom: 4.8vw;
  }

  .subwindow-wrapper > .subwindow.out-of-stock > .subwindow-body > img {
    width: 18.4vw;
    height: 18.4vw;
    margin-bottom: 5.0667vw;
  }

  .subwindow-wrapper > .subwindow.out-of-stock > .subwindow-body > p.style2 {
    font-size: 3.7333vw;
    line-height: 4.8vw;
    margin-bottom: 10.1333vw;
  }

  .subwindow-wrapper
    > .subwindow.out-of-stock
    > .subwindow-body
    > p.style2
    > button {
    font-size: 3.7333vw;
    line-height: 4.8vw;
  }

  .subwindow-wrapper > .subwindow.out-of-stock > .subwindow-body > button {
    width: 81.6vw;
    height: 13.3333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper > .subwindow.product-note {
    width: min(102.4vw, 95%);
    height: min(106.6667vw, 95%);
  }

  .subwindow-wrapper > .subwindow.product-note > .subwindow-body > label {
    width: 81.6vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 3.2vw;
  }

  .subwindow-wrapper > .subwindow.product-note > .subwindow-body > textarea {
    width: 81.6vw;
    height: 48vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.6vw;
    padding: 3.2vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 5.0667vw;
  }

  .subwindow-wrapper > .subwindow.product-note > .subwindow-body > button {
    width: 81.6vw;
    height: 13.3333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper > .subwindow.park-sale {
    width: min(102.4vw, 95%);
    height: min(104.5333vw, 95%);
  }

  .subwindow-wrapper > .subwindow.park-sale > .subwindow-body > label {
    width: 81.6vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 2.4vw;
  }

  .subwindow-wrapper > .subwindow.park-sale > .subwindow-body > textarea {
    width: 81.6vw;
    height: 48vw;
    padding: 3.2vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.6vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 5.0667vw;
  }

  .subwindow-wrapper > .subwindow.park-sale > .subwindow-body > button {
    width: 81.6vw;
    height: 13.3333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper > .subwindow.split-by-people {
    width: min(106.6667vw, 95%);
    height: min(106.6667vw, 95%);
  }

  .subwindow-wrapper
    > .subwindow.split-by-people
    > .subwindow-body
    > .input-numpad
    > .input-container
    > label {
    width: 81.6vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin: 5.3333vw 0 2.6667vw 0;
  }

  .subwindow-wrapper
    > .subwindow.split-by-people
    > .subwindow-body
    > .input-numpad
    > .input-container
    > input {
    width: 81.6vw;
    height: 18.6667vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.6vw;
    font-size: 10.6667vw;
    line-height: 14.9333vw;
  }

  .subwindow-wrapper > .subwindow.split-by-people > .subwindow-body > button {
    width: 81.6vw;
    height: 13.3333vw;
    margin: 8.2667vw 0 5.3333vw 0;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper > .subwindow.split-by-product {
    width: min(102.4vw, 95%);
    height: min(113.3333vw, 95%);
  }

  .subwindow-wrapper > .subwindow.split-by-product > .subwindow-body > .body {
    padding: 1.6vw 0;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row {
    width: 81.6vw;
    padding: 3.7333vw 0;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .main-row
    > .text-group
    > p {
    font-size: 4.2667vw;
    line-height: 5.8667vw;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .main-row
    > .increment-input {
    width: 38.36vw;
    height: 12.5333vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.6vw;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .main-row
    > .increment-input
    > button
    > img {
    width: 5.3333vw;
    height: 5.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .main-row
    > .increment-input
    > input {
    font-size: 4.8vw;
    line-height: 6.6667vw;
  }

  .subwindow-wrapper > .subwindow.split-by-product > .subwindow-body > .footer {
    height: 18.6667vw;
    border-top: 0.2667vw solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .footer
    > button {
    width: 81.6vw;
    height: 13.3333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper > .subwindow.cash-payment.step-1 {
    width: min(102.4vw, 95%);
    height: min(177.6vw, 95%);
  }

  .subwindow-wrapper > .subwindow.cash-payment.step-2 {
    width: min(112vw, 95%);
    height: 106.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .text-row {
    width: 85.6vw;
    margin-bottom: 5.0667vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .text-row
    > p.style1 {
    font-size: 4.2667vw;
    line-height: 5.6vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .text-row
    > p.style2 {
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad {
    padding: 4.2667vw;
    border-radius: 2.1333vw;
    margin-bottom: 3.7333vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > .input-container {
    width: 77.0667vw;
    height: 24.8vw;
    border-radius: 1.6vw;
    margin-bottom: 2.4vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > .input-container
    > label {
    font-size: 3.2vw;
    line-height: 4.5333vw;
    margin-bottom: 2.9333vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > .input-container
    > input {
    width: 51.4667vw;
    height: 11.2vw;
    font-size: 10.6667vw;
    line-height: 10.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > #numpad2
    > .button-row {
    margin-top: 2.4vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > #numpad2
    > .button-row
    > button {
    width: 24vw;
    height: 18.1333vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.6vw;
    margin-left: 2.4vw;
    font-size: 8vw;
    line-height: 12vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > #numpad2
    > .button-row
    > button.backspace
    > img {
    width: 10.6667vw;
    height: 10.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > button {
    width: 85.3333vw;
    height: 13.3333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment.step-2
    > .subwindow-body
    > .step2
    > .text-row {
    width: 81.6vw;
    padding-top: 4vw;
    margin-top: 4vw;
    border-top: 0.2667vw solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.cash-payment.step-2
    > .subwindow-body
    > .step2
    > .text-row
    > p {
    font-size: 4.2667vw;
    line-height: 5.6vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment.step-2
    > .subwindow-body
    > .step2
    > button {
    width: 81.6vw;
    height: 13.3333vw;
    border-radius: 1.6vw;
    margin-top: 6.9333vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper > .subwindow.email-receipt {
    width: min(102.4vw, 95%);
    height: min(73.3333vw, 95%);
  }

  .subwindow-wrapper > .subwindow.email-receipt > .subwindow-body > label {
    width: 81.6vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 2.4vw;
  }

  .subwindow-wrapper > .subwindow.email-receipt > .subwindow-body > input {
    width: 81.6vw;
    height: 11.7333vw;
    padding: 0 3.2vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.6vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 5.3333vw;
  }

  .subwindow-wrapper > .subwindow.email-receipt > .subwindow-body > button {
    width: 81.6vw;
    height: 13.3333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper > .subwindow.quick-tax {
    width: min(80vw, 95%);
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-header {
    height: 14.6667vw;
    padding: 0 2.9333vw 0 5.8667vw;
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-header > P {
    font-size: 4.2667vw;
    line-height: 5.8667vw;
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-header > button {
    padding: 2.9333vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-body {
    padding: 5.8667vw;
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-body > .row {
    margin-top: 2.1333vw;
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-body > .row > p {
    font-size: 4.2667vw;
    line-height: 5.8667vw;
  }

  .subwindow-wrapper
    > .subwindow.quick-tax
    > .subwindow-body
    > .row
    > label
    > input {
    left: -2666.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.quick-tax
    > .subwindow-body
    > .row
    > label
    > .custom-toggle {
    width: 10.6667vw;
    height: 6.6667vw;
    border-radius: 6.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.quick-tax
    > .subwindow-body
    > .row
    > label
    > .custom-toggle
    > .knob {
    width: 5.8667vw;
    height: 5.8667vw;
    top: calc(50% - 2.9333vw);
    left: 0.4vw;
    border: 0.1046vw solid rgba(0, 0, 0, 0.04);
    box-shadow: 0vw 0.6275vw 1.6732vw rgba(0, 0, 0, 0.15),
      0vw 0.6275vw 0.2092vw rgba(0, 0, 0, 0.06);
  }

  .subwindow-wrapper
    > .subwindow.quick-tax
    > .subwindow-body
    > .row
    > label
    > input:checked
    ~ .custom-toggle
    > .knob {
    right: 0.4vw;
  }

  .subwindow-wrapper > .subwindow.detailed-tax {
    width: min(133.3333vw, 95%);
    height: min(180.8vw, 95%);
  }

  .subwindow-wrapper > .subwindow.detailed-tax > .subwindow-body > .header-row {
    height: 12vw;
    padding: 0 5.8667vw;
    border-bottom: 0.2667vw solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .header-row
    > p {
    font-size: 3.7333vw;
    line-height: 4.5333vw;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row {
    height: 16vw;
    padding: 0 5.8667vw;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > p {
    font-size: 3.7333vw;
    line-height: 4.5333vw;
    padding-right: 4vw;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > .checkbox-wrapper
    > input {
    left: -2666.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > .checkbox-wrapper
    > .custom-checkbox {
    width: 4.8vw;
    height: 4.8vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.0667vw;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > .checkbox-wrapper
    > .custom-checkbox
    > img {
    width: 3.2vw;
    height: 2.5227vw;
  }

  .subwindow-wrapper > .subwindow.detailed-tax > .subwindow-body > .footer {
    height: 22.6667vw;
    border-top: 0.2667vw solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .footer
    > button {
    width: 74.6667vw;
    height: 11.7333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper > .subwindow.add-customer-note {
    width: min(102.4vw, 95%);
    height: min(106.6667vw, 95%);
  }

  .subwindow-wrapper > .subwindow.add-customer-note > .subwindow-body > label {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    width: 81.6vw;
    margin-bottom: 2.4vw;
  }

  .subwindow-wrapper
    > .subwindow.add-customer-note
    > .subwindow-body
    > textarea {
    width: 81.6vw;
    height: 48vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.6vw;
    padding: 3.2vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 5.0667vw;
  }

  .subwindow-wrapper > .subwindow.add-customer-note > .subwindow-body > button {
    width: 81.6vw;
    height: 13.3333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper > .subwindow.add-cash {
    width: min(102.4vw, 95%);
    height: min(118.6667vw, 95%);
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body > .text-row {
    width: 82.4vw;
    margin-bottom: 4.5333vw;
  }

  .subwindow-wrapper
    > .subwindow.add-cash
    > .subwindow-body
    > .text-row
    > p.style1 {
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper
    > .subwindow.add-cash
    > .subwindow-body
    > .text-row
    > p.style2 {
    font-size: 5.3333vw;
    line-height: 8vw;
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body > .input-row {
    width: 82.4vw;
    padding-bottom: 6.9333vw;
    border-bottom: 0.2667vw solid var(--oliver-border);
    margin-bottom: 5.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.add-cash
    > .subwindow-body
    > .input-row
    > label {
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper
    > .subwindow.add-cash
    > .subwindow-body
    > .input-row
    > input {
    width: 55.2vw;
    height: 13.3333vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.6vw;
    padding: 0 3.2vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body > label {
    width: 82.4vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 3.2vw;
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body > textarea {
    width: 81.6vw;
    height: 25.6vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.6vw;
    padding: 3.2vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 6.1333vw;
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body > button {
    width: 81.6vw;
    height: 13.3333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper > .subwindow.remove-cash {
    width: min(102.4vw, 95%);
    height: min(118.6667vw, 95%);
  }

  .subwindow-wrapper > .subwindow.remove-cash > .subwindow-body > .text-row {
    width: 82.4vw;
    margin-bottom: 4.5333vw;
  }

  .subwindow-wrapper
    > .subwindow.remove-cash
    > .subwindow-body
    > .text-row
    > p.style1 {
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper
    > .subwindow.remove-cash
    > .subwindow-body
    > .text-row
    > p.style2 {
    font-size: 5.3333vw;
    line-height: 8vw;
  }

  .subwindow-wrapper > .subwindow.remove-cash > .subwindow-body > .input-row {
    width: 82.4vw;
    padding-bottom: 6.9333vw;
    border-bottom: 0.2667vw solid var(--oliver-border);
    margin-bottom: 5.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.remove-cash
    > .subwindow-body
    > .input-row
    > label {
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper
    > .subwindow.remove-cash
    > .subwindow-body
    > .input-row
    > input {
    width: 46.4vw;
    height: 13.3333vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.6vw;
    padding: 0 3.2vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .subwindow-wrapper > .subwindow.remove-cash > .subwindow-body > label {
    width: 82.4vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 3.2vw;
  }

  .subwindow-wrapper > .subwindow.remove-cash > .subwindow-body > textarea {
    width: 81.6vw;
    height: 25.6vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.6vw;
    padding: 3.2vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 6.1333vw;
  }

  .subwindow-wrapper > .subwindow.remove-cash > .subwindow-body > button {
    width: 81.6vw;
    height: 13.3333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper > .subwindow.no-customer {
    width: min(102.4vw, 95%);
    height: min(102.4vw, 95%);
  }

  .subwindow-wrapper > .subwindow.no-customer > .subwindow-body {
    border-radius: 2.1333vw;
  }

  .subwindow-wrapper > .subwindow.no-customer > .subwindow-body > p.style1 {
    font-size: 4.2667vw;
    line-height: 5.6vw;
    margin-bottom: 6.6667vw;
  }

  .subwindow-wrapper > .subwindow.no-customer > .subwindow-body > img {
    width: 13.8667vw;
    height: 13.8667vw;
    margin-bottom: 7.7333vw;
  }

  .subwindow-wrapper > .subwindow.no-customer > .subwindow-body > p.style2 {
    font-size: 3.7333vw;
    line-height: 4.8vw;
    margin-bottom: 9.6vw;
  }

  .subwindow-wrapper > .subwindow.no-customer > .subwindow-body > button {
    width: 81.6vw;
    height: 13.3333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper
    > .subwindow.no-customer
    > .subwindow-body
    > button:first-of-type {
    border: 0.2667vw solid var(--oliver-blue);
    margin-bottom: 2.9333vw;
  }

  .subwindow-wrapper
    > .subwindow.no-customer
    > .subwindow-body
    > button:first-of-type
    > img {
    width: 5.3333vw;
    height: 5.3333vw;
    margin-right: 3.2vw;
  }

  .subwindow-wrapper > .subwindow.adjust-credit {
    width: min(106.6667vw, 95%);
    height: min(132.8vw, 95%);
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body {
    border-radius: 2.1333vw;
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body > p {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 2.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .toggle-wrapper {
    width: 81.8667vw;
    height: 13.3333vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 2.1333vw;
    padding: 1.6vw;
    margin-bottom: 5.6vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .toggle-wrapper
    > label
    > input {
    left: -2666.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .toggle-wrapper
    > label
    > .custom-radio {
    font-size: 4.2667vw;
    line-height: 5.6vw;
    border-radius: 1.6vw;
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body > .text-row {
    width: 81.6vw;
    margin-bottom: 2.1333vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .text-row
    > p.style1 {
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .text-row
    > p.style2 {
    font-size: 5.3333vw;
    line-height: 8vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .change-credit-row {
    width: 81.6vw;
    margin-bottom: 4.2667vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .change-credit-row
    > p {
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .change-credit-row
    > input {
    width: 42.6667vw;
    height: 13.3333vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.6vw;
    padding: 0 3.2vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body > label {
    width: 81.6vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 3.2vw;
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body > textarea {
    width: 81.6vw;
    height: 25.6vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.6vw;
    padding: 3.2vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 5.0667vw;
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body > button {
    width: 81.6vw;
    height: 13.3333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper > .subwindow.layaway {
    width: min(102.4vw, 95%);
    height: min(106.6667vw, 95%);
  }

  .subwindow-wrapper > .subwindow.layaway > .subwindow-body > label {
    width: 81.6vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 3.2vw;
  }

  .subwindow-wrapper > .subwindow.layaway > .subwindow-body > textarea {
    width: 81.6vw;
    height: 48vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.6vw;
    padding: 3.2vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 5.3333vw;
  }

  .subwindow-wrapper > .subwindow.layaway > .subwindow-body > button {
    width: 81.6vw;
    height: 13.3333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper > .subwindow.store-credit-pay {
    width: min(102.4vw, 95%);
    height: min(177.6vw, 95%);
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .text-row {
    width: 85.6vw;
    margin-bottom: 5.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .text-row
    > p.style1 {
    font-size: 4.2667vw;
    line-height: 5.6vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .text-row
    > p.style2 {
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper {
    width: 85.6vw;
    padding: 4.2667vw;
    border-radius: 2.1333vw;
    margin-bottom: 3.7333vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .input-wrapper {
    height: 24.8vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.6vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .input-wrapper
    > p {
    font-size: 3.2vw;
    line-height: 4.5333vw;
    margin-bottom: 2.9333vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .input-wrapper
    > input {
    height: 11.2vw;
    font-size: 10.6667vw;
    line-height: 10.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .numpad-row {
    height: 18.1333vw;
    margin-top: 2.4vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .numpad-row
    > button {
    margin-left: 2.4vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.6vw;
    font-size: 8vw;
    line-height: 12vw;
    letter-spacing: 0vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .numpad-row
    > button
    > img {
    width: 10.6667vw;
    height: 10.6667vw;
  }

  .subwindow-wrapper > .subwindow.store-credit-pay > .subwindow-body > button {
    width: 85.6vw;
    height: 13.3333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper > .subwindow.logout-confirm > .subwindow-body > p.style1 {
    font-size: 5.3333vw;
    line-height: 7.7333vw;
    margin-bottom: 14.1333vw;
  }

  .subwindow-wrapper > .subwindow.logout-confirm > .subwindow-body > p.style2 {
    font-size: 4.2667vw;
    line-height: 5.6vw;
    margin-top: 5.3333vw;
  }

  .subwindow-wrapper > .subwindow.logout-confirm > .subwindow-body > button {
    width: 91.4667vw;
    height: 14.4vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper
    > .subwindow.logout-confirm
    > .subwindow-body
    > button#registerLogout {
    margin: 14.1333vw 0 4.2667vw 0;
  }

  .subwindow-wrapper
    > .subwindow.logout-confirm
    > .subwindow-body
    > button#cancelRegisterLogout {
    border: 0.2667vw solid var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.receipt {
    width: min(102.4vw, 95%);
    height: min(80vw, 95%);
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-options
    > p {
    font-size: 3.7333vw;
    line-height: 4.8vw;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-options
    > button {
    width: 70.6667vw;
    height: 13.3333vw;
    margin-top: 4.5333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-options
    > button:first-of-type {
    margin-top: 5.6vw;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-email
    > label {
    width: 81.6vw;
    font-size: 3.7333vw;
    line-height: 4.8vw;
    padding-bottom: 1.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-email
    > input {
    width: 82.4vw;
    height: 13.3333vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.6vw;
    padding: 0 3.2vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 4.5333vw;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-email
    > input:focus {
    border-width: 0.5333vw;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-email
    > button {
    width: 81.6vw;
    height: 13.3333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper > .subwindow.no-variation-selected {
    width: 91.7333vw;
    height: min(86.1333vw, 95%);
  }

  .subwindow-wrapper > .subwindow.no-variation-selected > .subwindow-body {
    border-radius: 2.1333vw;
  }

  .subwindow-wrapper
    > .subwindow.no-variation-selected
    > .subwindow-body
    > p.style1 {
    font-size: 4.2667vw;
    line-height: 5.6vw;
    margin-bottom: 6.1333vw;
  }

  .subwindow-wrapper
    > .subwindow.no-variation-selected
    > .subwindow-body
    > img {
    width: 16.2667vw;
    height: 16.2667vw;
    margin-bottom: 5.8667vw;
  }

  .subwindow-wrapper
    > .subwindow.no-variation-selected
    > .subwindow-body
    > p.style2 {
    font-size: 3.7333vw;
    line-height: 4.8vw;
    margin-bottom: 10.1333vw;
  }

  .subwindow-wrapper
    > .subwindow.no-variation-selected
    > .subwindow-body
    > button {
    width: 81.6vw;
    height: 13.3333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .subwindow-wrapper > .subwindow.update-transaction-status {
    width: min(91.7333vw, 95%);
    height: min(95%, 150.4vw);
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > p {
    font-size: 3.7333vw;
    line-height: 4.8vw;
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > label {
    margin-top: 4vw;
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > label
    > input {
    left: -2666.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > label
    > .custom-radio {
    width: 76vw;
    height: 12vw;
    border-radius: 2.1333vw;
    border: 0.2667vw solid var(--oliver-border);
    font-size: 4.2667vw;
    line-height: 5.6vw;
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > label
    > input:not(:disabled):not(:checked)
    + .custom-radio:hover {
    border: 0.2667vw solid var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > label
    > input:checked
    + .custom-radio {
    border: 0.5333vw solid var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > button {
    margin-top: 7.4667vw;
    width: 76vw;
    height: 12vw;
    border-radius: 2.1333vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .open-register-wrapper > #cancel,
  .open-register-wrapper > #goBack {
    width: 35.2267vw;
    height: 13.8667vw;
    font-size: 5.3333vw;
    line-height: 8vw;
  }

  .open-register-wrapper > #cancel > img,
  .open-register-wrapper > #goBack > img {
    width: 2.1867vw;
    height: 4.3733vw;
    margin-right: 2.6667vw;
  }

  .open-register-wrapper > #logout-mobile {
    width: 34.96vw;
    height: 13.8667vw;
    font-size: 5.3333vw;
    line-height: 8vw;
    margin-top: 5.3333vw;
  }

  .open-register-wrapper > #logout-mobile > img {
    width: 6.4vw;
    height: 6.4vw;
    margin-right: 2.1333vw;
  }

  .open-register-wrapper > header {
    width: 91.4667vw;
    padding: 2.6667vw 0 8.0747vw 0;
    border-bottom: 0.2667vw solid #fff;
    margin-bottom: 5.3333vw;
  }

  .open-register-wrapper > header > img {
    width: 28.496vw;
    height: 26.0587vw;
    margin-right: 7.504vw;
  }

  .open-register-wrapper > header p.style1 {
    font-size: 8vw;
    line-height: 11.4667vw;
    margin-bottom: 1.3333vw;
  }

  .open-register-wrapper > header p.style2 {
    font-size: 4.2667vw;
    line-height: 6.9333vw;
    margin-bottom: 1.0667vw;
  }

  .open-register-wrapper > header p.style3 {
    font-size: 4.2667vw;
    line-height: 4.2667vw;
  }

  .open-register-wrapper > main > .step1 > p {
    font-size: 5.3333vw;
    line-height: 7.7333vw;
    margin-bottom: 6.9333vw;
  }

  .open-register-wrapper > main > .step1 > button {
    width: 91.4667vw;
    height: 14.4vw;
    border-radius: 2.1333vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .open-register-wrapper > main > .step2 > p {
    width: 91.4667vw;
    font-size: 5.3333vw;
    line-height: 7.7333vw;
    margin-bottom: 6.1333vw;
    margin: 4.2667vw 0 6.1333vw 0;
  }

  .open-register-wrapper > main > .step2 > label {
    width: 91.4667vw;
    font-size: 4.2667vw;
    line-height: 6.4vw;
    margin-bottom: 2.4vw;
  }

  .open-register-wrapper > main > .step2 > input {
    width: 91.4667vw;
    height: 19.2vw;
    border-radius: 1.6vw;
    font-size: 8.5333vw;
    line-height: 12vw;
    margin-bottom: 6.9333vw;
  }

  .open-register-wrapper > main > .step2 > button {
    width: 91.4667vw;
    height: 14.4vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
    border-radius: 2.1333vw;
  }

  .open-register-wrapper > main > .step2 > button#openCashDrawer {
    border: 0.2667vw solid #fff;
    margin-bottom: 6.1333vw;
  }

  .open-register-wrapper > main > .step2 > button#openFloatButton {
    margin: auto 0 6.9333vw 0;
  }

  .open-register-wrapper > main > .step2 > textarea {
    width: 91.4667vw;
    height: 22.1333vw;
    border-radius: 1.6vw;
    padding: 3.2vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 6.1333vw;
  }

  .open-register-wrapper > main > .step3 > p {
    font-size: 4.2667vw;
    line-height: 6.1333vw;
    margin: 5.3333vw 0 5.192vw 0;
  }

  .open-register-wrapper > main > .step3 > .error-message {
    width: 73.6vw;
    height: 11.2vw;
    border-radius: 1.6vw;
    margin-bottom: 4.8vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .open-register-wrapper > main > .step3 > .pinpad {
    width: 73.6vw;
  }

  .open-register-wrapper > main > .step3 > .pinpad > .pin-entries {
    margin-bottom: 3.9707vw;
  }

  .open-register-wrapper > main > .step3 > .pinpad > .pin-entries > .pin-entry {
    width: 3.0133vw;
    height: 3.0133vw;
    margin-left: 1.808vw;
  }

  .open-register-wrapper > main > .step3 > .pinpad > .pin-button-row {
    margin-bottom: 4.3467vw;
  }

  .open-register-wrapper > main > .step3 > .pinpad > .pin-button-row > button {
    width: 21.736vw;
    height: 21.736vw;
    border-radius: 2.1333vw;
    margin-right: 4.3467vw;
  }

  .open-register-wrapper
    > main
    > .step3
    > .pinpad
    > .pin-button-row
    > button
    > p {
    font-size: 9.6vw;
    line-height: 9.6vw;
    transform: translateY(0.8vw);
  }

  .open-register-wrapper
    > main
    > .step3
    > .pinpad
    > .pin-button-row
    > button
    > .text-row {
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .open-register-wrapper
    > main
    > .step3
    > .pinpad
    > .pin-button-row
    > button.backspace
    > img {
    width: 13.4347vw;
    height: 10.6907vw;
  }

  .idle-register-wrapper > #logoutRegisterButton {
    padding: 1.3333vw;
    font-size: 4.2667vw;
    line-height: 6.9333vw;
  }

  .idle-register-wrapper > #logoutRegisterButton > img {
    width: 5.3333vw;
    height: 5.3333vw;
    margin-right: 1.3333vw;
  }

  .idle-register-wrapper > header {
    width: 91.4667vw;
    padding: 8vw 0;
    border-bottom: 0.2667vw solid #fff;
  }

  .idle-register-wrapper > header > img {
    width: 28.496vw;
    height: 26.0587vw;
    margin-right: 7.504vw;
  }

  .idle-register-wrapper > header > .col > p.style1 {
    font-size: 8vw;
    line-height: 11.4667vw;
  }

  .idle-register-wrapper > header > .col > p.style2 {
    font-size: 4.2667vw;
    line-height: 7.4667vw;
    margin-bottom: -2.1333vw;
  }

  .idle-register-wrapper > header > .col > p.style3 {
    font-size: 4.2667vw;
    line-height: 7.4667vw;
  }

  .idle-register-wrapper > main > p {
    font-size: 4.2667vw;
    line-height: 6.1333vw;
    margin: 8vw 0 5.192vw 0;
  }

  .idle-register-wrapper > main > .pinpad {
    width: 74.1333vw;
    margin-bottom: 2.6667vw;
  }

  .idle-register-wrapper > main > .pinpad > p.error-message {
    padding: 2.9333vw 7.4667vw;
    border-radius: 2.1333vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin: -2.6667vw 0 5.3333vw 0;
  }

  .idle-register-wrapper > main > .pinpad > .pin-entries {
    margin-bottom: 4.5333vw;
  }

  .idle-register-wrapper > main > .pinpad > .pin-entries > .pin-entry {
    width: 4.2667vw;
    height: 4.2667vw;
    margin-left: 2.6667vw;
  }

  .idle-register-wrapper > main > .pinpad > .pin-button-row {
    gap: 4.2667vw;
    margin-bottom: 4.2667vw;
  }

  .idle-register-wrapper > main > .pinpad > .pin-button-row > button {
    width: 21.8667vw;
    height: 21.8667vw;
    border-radius: 2.1333vw;
  }

  .idle-register-wrapper
    > main
    > .pinpad
    > .pin-button-row
    > button.backspace
    > img {
    width: 13.3333vw;
    height: 10.6667vw;
  }

  .idle-register-wrapper > main > .pinpad > .pin-button-row > button > p {
    font-size: 9.6vw;
    line-height: 9.6vw;
  }

  .idle-register-wrapper
    > main
    > .pinpad
    > .pin-button-row
    > button
    > .text-row {
    font-size: 2.6667vw;
    line-height: 4vw;
    letter-spacing: 0vw;
  }

  .idle-register-wrapper > main > button#closeRegister2 {
    width: 89.8667vw;
    height: 13.3333vw;
    border-radius: 2.1333vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
    margin: auto 0 5.3333vw 0;
  }

  .close-register-wrapper > button {
    width: 20.8vw;
    height: 14vw;
    font-size: 4vw;
    line-height: 4vw;
  }

  .close-register-wrapper > button > img {
    height: 3.3333vw;
    margin-right: 1.0667vw;
  }

  .close-register-wrapper > header {
    width: 91.4667vw;
    padding: 8.0747vw 0;
    border-bottom: 0.2667vw solid #fff;
  }

  .close-register-wrapper > header > img {
    width: 28.496vw;
    height: 26.0587vw;
    margin-right: 7.504vw;
  }

  .close-register-wrapper > header > .col > p.style1 {
    font-size: 8vw;
    line-height: 11.4667vw;
    margin-bottom: -0.5333vw;
  }

  .close-register-wrapper > header > .col > p.style2 {
    font-size: 4.2667vw;
    line-height: 7.4667vw;
    margin-bottom: -2.1333vw;
  }

  .close-register-wrapper > header > .col > p.style3 {
    font-size: 4.2667vw;
    line-height: 7.4667vw;
  }

  .close-register-wrapper > main > .step1 > p.style1 {
    font-size: 6.6667vw;
    line-height: 9.6vw;
    margin: 5.3333vw 0;
  }

  .close-register-wrapper > main > .step1 > p.style2 {
    font-size: 4.2667vw;
    line-height: 6.1333vw;
    margin-bottom: 5.192vw;
  }

  .close-register-wrapper > main > .step1 > .pinpad {
    margin-bottom: 6.9333vw;
  }

  .close-register-wrapper > main > .step1 > .pinpad {
    width: 74.1333vw;
    margin-bottom: 2.6667vw;
  }

  .close-register-wrapper > main > .step1 > .pinpad > p.error-message {
    padding: 2.9333vw 7.4667vw;
    border-radius: 2.1333vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin: -2.6667vw 0 5.3333vw 0;
  }

  .close-register-wrapper > main > .step1 > .pinpad > .pin-entries {
    margin-bottom: 4.5333vw;
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-entries
    > .pin-entry {
    width: 4.2667vw;
    height: 4.2667vw;
    margin-left: 2.6667vw;
  }

  .close-register-wrapper > main > .step1 > .pinpad > .pin-button-row {
    gap: 4.2667vw;
    margin-bottom: 4.2667vw;
  }

  .close-register-wrapper > main > .step1 > .pinpad > .pin-button-row > button {
    width: 21.8667vw;
    height: 21.8667vw;
    border-radius: 2.1333vw;
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-button-row
    > button.backspace
    > img {
    width: 13.3333vw;
    height: 10.6667vw;
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-button-row
    > button
    > p {
    font-size: 9.6vw;
    line-height: 9.6vw;
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-button-row
    > button
    > .text-row {
    font-size: 2.6667vw;
    line-height: 4vw;
    letter-spacing: 0vw;
  }

  .close-register-wrapper > main > .step2 > p.style1 {
    font-size: 6.6667vw;
    line-height: 9.6vw;
    margin: 4.2667vw 0 4.8vw 0;
  }

  .close-register-wrapper > main > .step2 > p.style2 {
    font-size: 4.2667vw;
    line-height: 6.4vw;
    margin-bottom: 5.3333vw;
  }

  .close-register-wrapper > main > .step2 > button#openCashDrawer {
    width: 87.2vw;
    height: 18.6667vw;
    border: 0.5333vw solid #fff;
    border-radius: 2.1333vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .close-register-wrapper > main > .step2 > .input-column {
    padding: 7.7333vw 0;
  }

  .close-register-wrapper > main > .step2 > .input-column > .input-row {
    width: 87.2vw;
    margin-top: 5.3333vw;
  }

  .close-register-wrapper > main > .step2 > .input-column > .input-row > label {
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .close-register-wrapper > main > .step2 > .input-column > .input-row > input {
    width: 49.0107vw;
    height: 13.3333vw;
    border-radius: 2.1333vw;
    padding: 4.8vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .close-register-wrapper > main > .step2 > button#saveCount {
    width: 87.2vw;
    height: 13.3333vw;
    border-radius: 2.1333vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
    margin-bottom: 6.9333vw;
    margin-top: 4vw;
  }

  .close-register-wrapper > main > .step3 > p.style1 {
    font-size: 6.6667vw;
    line-height: 9.6vw;
    margin: 4.2667vw 0 4.8vw 0;
  }

  .close-register-wrapper > main > .step3 > p.style2 {
    font-size: 4.2667vw;
    line-height: 6.4vw;
    margin-bottom: 8vw;
  }

  .close-register-wrapper > main > .step3 > .closing-totals {
    width: 92.5333vw;
    border-radius: 2.1333vw;
    padding: 7.7333vw 0;
    margin-bottom: 8vw;
  }

  .close-register-wrapper > main > .step3 > .closing-totals p.style1 {
    font-size: 4.8vw;
    line-height: 7.2vw;
    margin-bottom: 6.048vw;
  }

  .close-register-wrapper > main > .step3 > .closing-totals p.style2 {
    font-size: 4.2667vw;
    line-height: 5.6vw;
    margin-bottom: 2.6667vw;
  }

  .close-register-wrapper > main > .step3 > .closing-totals p.style3 {
    font-size: 4.2667vw;
    line-height: 5.6vw;
  }

  .close-register-wrapper > main > .step3 > .closing-totals .divider {
    height: 0.2667vw;
    margin-bottom: 2.6667vw;
  }

  .close-register-wrapper > main > .step3 > .closing-totals > .col {
    width: 82.872vw;
    margin-top: 8.8vw;
  }

  .close-register-wrapper > main > .step3 > .closing-totals > .col > .row {
    margin-top: 3.4667vw;
  }

  .close-register-wrapper > main > .step3 > label {
    width: 91.4667vw;
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .close-register-wrapper > main > .step3 > textarea {
    width: 91.4667vw;
    height: 38.1333vw;
    padding: 3.2vw;
    border-radius: 2.1333vw;
    margin-bottom: 6.6667vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .close-register-wrapper > main > .step3 > button {
    width: 91.4667vw;
    height: 14.4vw;
    border-radius: 2.1333vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
    margin-bottom: 4.2667vw;
  }

  .close-register-wrapper > main > .step3 > button#printReport {
    border: 0.5333vw solid #fff;
  }

  .create-userid-wrapper > .header {
    padding: 2.6667vw;
  }

  .create-userid-wrapper > .header > #goBackPinSetup,
  .create-userid-wrapper > .header > #cancelPinSetup {
    padding: 2.6667vw;
    font-size: 4.8vw;
    line-height: 7.2vw;
  }

  .create-userid-wrapper > .header > #goBackPinSetup > img,
  .create-userid-wrapper > .header > #cancelPinSetup > img {
    width: 2.4vw;
    height: 4.2667vw;
    margin-right: 2.1333vw;
  }

  .create-userid-wrapper > .header > #logoutPinSetup {
    font-size: 4.8vw;
    line-height: 7.2vw;
  }

  .create-userid-wrapper > .header > #logoutPinSetup > img {
    width: 5.3333vw;
    height: 5.3333vw;
    margin-right: 1.8667vw;
  }

  .create-userid-wrapper > .body > .col.static {
    width: 91.4667vw;
    padding-bottom: 8vw;
    border-bottom: 0.2667vw solid #fff;
  }

  .create-userid-wrapper > .body > .col.static > img {
    width: 28.496vw;
    height: 26.0587vw;
    margin-right: 7.4667vw;
  }

  .create-userid-wrapper > .body > .col.static > .text-group > p.style1 {
    font-size: 8vw;
    line-height: 11.4667vw;
  }

  .create-userid-wrapper > .body > .col.static > .text-group > p.style2 {
    font-size: 4.2667vw;
    line-height: 4.2667vw;
    margin-bottom: 0.5333vw;
  }

  .create-userid-wrapper > .body > .col.static > .text-group > p.style3 {
    font-size: 4.2667vw;
    line-height: 4.2667vw;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-1 {
    margin-top: 8.8vw;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-1 > p {
    font-size: 5.3333vw;
    line-height: 9.3333vw;
    margin-bottom: 5.3333vw;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-1 > button {
    width: 91.4667vw;
    height: 13.3333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 {
    margin-top: 5.3333vw;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 > p {
    font-size: 5.3333vw;
    line-height: 7.7333vw;
    margin-bottom: 6.1333vw;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 > .id-row {
    width: 73.6vw;
    margin-bottom: 6.7547vw;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .id-row
    > .id-entry {
    height: 11.7333vw;
    margin-left: 2.6667vw;
    box-shadow: inset 0vw 1.0667vw 1.6vw rgba(0, 0, 0, 0.25);
    border-radius: 1.6vw;
    font-size: 6.6667vw;
    line-height: 9.6vw;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 > .pin-key-row {
    width: 73.6vw;
    margin-bottom: 4.2667vw;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .pin-key-row
    > button {
    margin-left: 4.2667vw;
    border-radius: 2.1333vw;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .pin-key-row
    > button.backspace
    > img {
    width: 13.4347vw;
    height: 10.6907vw;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 > .pin-key-row.last {
    margin-bottom: 4.2667vw;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .pin-key-row
    > button
    > p {
    font-size: 9.6vw;
    line-height: 9.6vw;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .pin-key-row
    > button
    > .letter-row
    > p {
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 > button {
    width: 91.4667vw;
    height: 13.3333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .navbar {
    padding: 2.6667vw 4.5333vw 50.4vw 4.5333vw;
    border-top: 0.2667vw solid var(--oliver-border);
  }

  .navbar > button {
    height: 12.5333vw;
    border-radius: 2.1333vw;
    margin-bottom: 1.1973vw;
    padding: 0 2.3067vw;
  }

  .navbar > button > .img-container {
    width: 8vw;
    height: 8vw;
    margin-right: 3.7333vw;
  }

  .navbar > button > p {
    font-size: 4.2667vw;
    line-height: 5.8667vw;
  }

  .app-launcher-wrapper > .app-launcher > .header {
    height: 19.2vw;
    border-bottom: 0.2667vw solid var(--oliver-border);
  }

  .app-launcher-wrapper > .app-launcher > .header > button {
    width: 10.6667vw;
    height: 10.6667vw;
    margin: 0 2.1333vw;
  }

  .app-launcher-wrapper > .app-launcher > .header > button > img {
    width: 2.8373vw;
    height: 4.8vw;
  }

  .app-launcher-wrapper > .app-launcher > .header > p {
    font-size: 5.3333vw;
    line-height: 7.4667vw;
  }

  .app-launcher-wrapper > .app-launcher > .body {
    padding: 5.3333vw;
  }

  .app-launcher-wrapper > .app-launcher > .body > img {
    width: 68.5333vw;
    height: 46.9333vw;
  }

  .app-launcher-wrapper > .app-launcher > .body > button {
    margin-bottom: 5.3333vw;
  }

  .app-launcher-wrapper > .app-launcher > .body > button > .img-container {
    width: 14.6667vw;
    height: 14.6667vw;
    border-radius: 2.6667vw;
    margin-right: 7.2vw;
  }

  .app-launcher-wrapper > .app-launcher > .body > button > p {
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .link-launcher-wrapper > .link-launcher > .header {
    height: 19.2vw;
    border-bottom: 0.2667vw solid var(--oliver-border);
  }

  .link-launcher-wrapper > .link-launcher > .header > button {
    width: 10.6667vw;
    height: 10.6667vw;
    margin: 0 2.1333vw;
  }

  .link-launcher-wrapper > .link-launcher > .header > button > img {
    width: 2.8373vw;
    height: 4.8vw;
  }

  .link-launcher-wrapper > .link-launcher > .header > p {
    font-size: 5.3333vw;
    line-height: 7.4667vw;
  }

  .link-launcher-wrapper > .link-launcher > .body {
    padding: 5.3333vw;
  }

  .link-launcher-wrapper > .link-launcher > .body > img {
    width: 68.5333vw;
    height: 46.9333vw;
  }

  .link-launcher-wrapper > .link-launcher > .body > button {
    margin-bottom: 5.3333vw;
  }

  .link-launcher-wrapper > .link-launcher > .body > button > .img-container {
    width: 14.6667vw;
    height: 14.6667vw;
    border-radius: 2.6667vw;
    margin-right: 7.2vw;
  }

  .link-launcher-wrapper > .link-launcher > .body > button > .col {
    width: calc(100% - 32.5333vw);
  }

  .link-launcher-wrapper > .link-launcher > .body > button > .col > p.style1 {
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .link-launcher-wrapper > .link-launcher > .body > button > .col > p.style2 {
    font-size: 3.2vw;
    line-height: 4.5333vw;
  }

  .user-info-wrapper > .user-info {
    padding: 5.3333vw;
  }

  .user-info-wrapper > .user-info > .header {
    margin-bottom: 5.8667vw;
  }

  .user-info-wrapper > .user-info > .header > .avatar {
    width: 14.6667vw;
    height: 14.6667vw;
    margin-right: 5.3333vw;
    font-size: 5.3333vw;
  }

  .user-info-wrapper > .user-info > .header > .col > p.style1 {
    font-size: 4.8vw;
    line-height: 6.6667vw;
  }

  .user-info-wrapper > .user-info > .header > .col > p.style2 {
    font-size: 3.4667vw;
    line-height: 4.8vw;
  }

  .user-info-wrapper > .user-info > .body > .language-select {
    margin-bottom: 5.3333vw;
  }

  .user-info-wrapper > .user-info > .body > .language-select > p {
    font-size: 3.2vw;
    line-height: 4.5333vw;
    margin-right: 2.6667vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper {
    height: 9.3333vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > img {
    top: calc(50% - 1.8667vw);
    right: 3.2vw;
    width: 3.7333vw;
    height: 3.7333vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper.open
    > input {
    border-radius: 2.1333vw 2.1333vw 0 0;
    border-width: 0.5333vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > input {
    border: 0.2667vw solid var(--oliver-base-grey);
    border-radius: 2.1333vw;
    padding: 0 3.2vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > .option-container {
    border-radius: 0 0 2.1333vw 2.1333vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > .option-container
    > .language-option {
    height: 9.3333vw;
    border: 0.2667vw solid var(--oliver-border);
    padding: 0 3.2vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > .option-container
    > .language-option
    > p {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    max-width: calc(100% - 4.2667vw);
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > .option-container
    > .language-option:last-child {
    border-radius: 0 0 2.1333vw 2.1333vw;
  }

  .user-info-wrapper > .user-info > .body > button {
    font-size: 4.2667vw;
    line-height: 5.8667vw;
  }

  .user-info-wrapper > .user-info > .body > button > .img-container {
    width: 14.6667vw;
    height: 8vw;
    margin-right: 4vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > button#knowledgeBaseButton
    > .img-container
    > img {
    width: 6.4vw;
    height: 6.4vw;
  }

  .user-info-wrapper > .user-info > .body > button#switchUserButton {
    margin-top: 2.6667vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > button#switchUserButton
    > .img-container
    > img {
    width: 4.5227vw;
    height: 4.6667vw;
  }

  .user-info-wrapper > .user-info > .body > button#endSessionButton {
    margin-top: 2.6667vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > button#endSessionButton
    > .img-container
    > img {
    width: 6.416vw;
    height: 6.4vw;
  }

  .page-options-wrapper {
    top: 18.6667vw;
    height: calc(100% - 18.6667vw);
  }

  .page-options-wrapper > .page-options {
    padding: 0 7.4667vw 4.4vw 7.4667vw;
    border-radius: 0 0 3.2vw 3.2vw;
  }

  .page-options-wrapper > .page-options > p {
    font-size: 4.2667vw;
    line-height: 5.8667vw;
    margin-bottom: 3.2vw;
  }

  .page-options-wrapper > .page-options > button {
    height: 12.5333vw;
    border-radius: 2.1333vw;
  }

  .page-options-wrapper > .page-options > button > .img-container {
    width: 8vw;
    height: 8vw;
    margin-right: 2.4vw;
  }

  .page-options-wrapper > .page-options > button > .img-container > .badge {
    border: 0.5333vw solid #fff;
  }

  .page-options-wrapper > .page-options > button > p {
    font-size: 4.2667vw;
    line-height: 5.8667vw;
  }

  .notifications-wrapper > .notifications > .noti-header {
    height: 16vw;
    padding: 0 4.2667vw;
    border-bottom: 0.2667vw solid var(--oliver-border);
  }

  .notifications-wrapper > .notifications > .noti-header > p {
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .notifications-wrapper > .notifications > .noti-header > button {
    width: 9.0667vw;
    height: 9.0667vw;
    border-radius: 2.1333vw;
    margin-left: 2.9333vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-header
    > button#notiSettingButton
    > img {
    width: 6.4vw;
    height: 6.4vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-header
    > button#mobileNotiExit
    > img {
    width: 3.2vw;
    height: 3.2vw;
  }

  .notifications-wrapper > .notifications > .noti-body > .date {
    padding: 2.4vw 4.2667vw;
  }

  .notifications-wrapper > .notifications > .noti-body > .date > p {
    font-size: 3.4667vw;
    line-height: 4.5333vw;
  }

  .notifications-wrapper > .notifications > .noti-body > .notification-card {
    height: 20.2667vw;
    padding: 0 4vw;
    border-top: 0.2667vw solid var(--oliver-border);
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .icon-wrapper {
    width: 10.6667vw;
    height: 10.6667vw;
    border-radius: 2.1333vw;
    margin-right: 3.2vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .icon-wrapper
    > img {
    width: 8.5333vw;
    height: 8.5333vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .icon-wrapper.red
    > img {
    width: 6.6667vw;
    height: 6.6667vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .icon-wrapper.grey
    > img {
    width: 6.4vw;
    height: 6.4vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .col {
    padding-right: 3.2vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .col
    > p.style1 {
    font-size: 3.4667vw;
    line-height: 4.5333vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .col
    > p.style2 {
    font-size: 3.2vw;
    line-height: 4.2667vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > p {
    font-size: 3.4667vw;
    line-height: 4.5333vw;
  }

  .notifications-wrapper > .notifications.settings > .noti-settings-background {
    top: 16vw;
  }

  .notifications-wrapper > .notifications > .noti-settings-wrapper {
    top: 16vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings {
    border-radius: 0 0 4vw 4vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .header {
    padding: 3.2vw 4.2667vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .header
    > p {
    font-size: 4.2667vw;
    line-height: 5.6vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list {
    padding: 2.6667vw 4.2667vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row {
    padding: 1.3333vw 0;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > img {
    width: 6.4vw;
    height: 6.4vw;
    margin-right: 2.6667vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > p {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    width: 53.3333vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > input {
    left: -2666.6667vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > .toggle {
    width: 17.0667vw;
    height: 8.5333vw;
    border-radius: 8.5333vw;
    padding: 0.5333vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > .toggle::before {
    width: 7.4667vw;
    height: 7.4667vw;
    border: 0.2667vw solid rgba(0, 0, 0, 0.04);
    box-shadow: 0vw 0.8vw 2.1333vw rgba(0, 0, 0, 0.15),
      0vw 0.8vw 0.2667vw rgba(0, 0, 0, 0.06);
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > .toggle::after {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    right: 2.1333vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > input:checked
    + .toggle::before {
    right: 0.5333vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > input:checked
    + .toggle::after {
    left: 2.1333vw;
  }

  .homepage-wrapper > .header {
    padding-top: 2.728vw;
  }

  .homepage-wrapper > .header > .row {
    height: 16.1227vw;
  }

  .homepage-wrapper > .header > .row > button#mobileNavToggle {
    width: 10.6667vw;
    height: 10.6667vw;
    margin: 0 2.1333vw;
  }

  .homepage-wrapper > .header > .row > button#mobileNavToggle > img {
    width: 5.3333vw;
    height: 3.5547vw;
  }

  .homepage-wrapper > .header > .row > button#mobileNavToggle.opened > img {
    width: 10.4vw;
    height: 10.4vw;
  }

  .homepage-wrapper > .header > .row > img {
    width: 17.0667vw;
    height: 4.8vw;
  }

  .homepage-wrapper > .header > .row > button#searchButton {
    width: calc(100% - 8.5333vw);
    height: 12.2667vw;
    left: 4.2667vw;
    bottom: 4vw;
    border-radius: 2.1333vw;
    font-size: 4.2667vw;
    line-height: 5.8667vw;
  }

  .homepage-wrapper > .header > .row > button#searchButton > img {
    width: 5.3333vw;
    height: 5.3333vw;
    margin-right: 2.1333vw;
  }

  .homepage-wrapper > .header > .row > button#mobileOptionsButton,
  .homepage-wrapper > .header > .row > button#mobileAppsButton {
    width: 9.0667vw;
    height: 9.0667vw;
    border-radius: 2.1333vw;
  }

  .homepage-wrapper > .header > .row > button#mobileOptionsButton {
    margin-right: 2.6667vw;
  }

  .homepage-wrapper > .header > .row > button#mobileOptionsButton > img {
    width: 7.944vw;
    height: 7.944vw;
  }

  .homepage-wrapper > .header > .row > button#mobileAppsButton {
    margin-right: 4.2667vw;
  }

  .homepage-wrapper > .header > .row > button#mobileAppsButton > img {
    width: 4.9653vw;
    height: 4.9227vw;
  }

  .homepage-wrapper > .products > .change-row-count {
    margin-bottom: 4.2667vw;
  }

  .homepage-wrapper > .products > .change-row-count > p {
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .homepage-wrapper > .products > .change-row-count > button:not(#exitEditing) {
    width: 10.6667vw;
    height: 10.6667vw;
    border: 0.2667vw solid var(--oliver-blue);
    border-radius: 2.1333vw;
    margin: 0 3.2vw;
  }

  .homepage-wrapper > .products > .change-row-count > button > img {
    width: 3.4667vw;
    height: 7.4667vw;
  }

  .homepage-wrapper > .products > .change-row-count > button#exitEditing {
    height: 10.6667vw;
    padding: 0 3.2vw;
    border: 0.2667vw solid var(--oliver-blue);
    border-radius: 2.1333vw;
    font-size: 4.2667vw;
    line-height: 4.8vw;
  }

  .homepage-wrapper > .products > .category-header {
    margin-bottom: 3.2vw;
  }

  .homepage-wrapper > .products > .category-header > p {
    font-size: 3.7333vw;
    line-height: 5.6vw;
  }

  .homepage-wrapper > .products > .category-header > button {
    width: 32vw;
    height: 8vw;
    border-radius: 1.6vw;
    font-size: 3.2vw;
    line-height: 4.2667vw;
  }

  .homepage-wrapper > .products > .category-header > button > img {
    width: 5.3333vw;
    height: 5.3333vw;
    margin-right: 1.6vw;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > .no-product-container
    > img {
    width: 12vw;
    height: 12vw;
    margin-bottom: 3.4667vw;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > .no-product-container
    > p {
    font-size: 4.8vw;
    line-height: 7.2vw;
    margin-bottom: 10.1333vw;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > .no-product-container
    > button {
    width: 37.3333vw;
    height: 9.8667vw;
    border: 0.2667vw solid var(--oliver-blue);
    border-radius: 1.6vw;
    font-size: 3.7333vw;
    line-height: 5.6vw;
  }

  .homepage-wrapper > .products > .products-container > button {
    border-radius: 2.1333vw;
    box-shadow: 0vw 1.6vw 2.6667vw rgba(94, 94, 94, 0.1);
  }

  .homepage-wrapper > .products > .products-container > button.product > .body {
    border-radius: 2.1333vw 2.1333vw 0 0;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button.product
    > .footer {
    border-radius: 0 0 2.1333vw 2.1333vw;
    border-top: 0.2667vw solid var(--oliver-border);
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button.product
    > .footer
    > p {
    font-size: 4.2667vw;
    line-height: 5.3333vw;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button.category
    > p.sub {
    font-size: 3.2vw;
    line-height: 4vw;
    margin-bottom: 5.3333vw;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button.category
    > p.main {
    font-size: 4.2667vw;
    line-height: 5.3333vw;
  }

  .homepage-wrapper > .products > .products-container > button.add-tile {
    border: 0.5333vw dashed var(--oliver-new-grey);
    font-size: 4.2667vw;
    line-height: 4.8vw;
  }

  .homepage-wrapper > .products > .products-container > button.add-tile > img {
    width: 5.3333vw;
    height: 5.3333vw;
    margin-right: 1.8667vw;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button
    > .not-supported {
    border-radius: 1.6vw;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button
    > .not-supported
    > img {
    width: 7.4667vw;
    top: 3.2vw;
    left: 3.2vw;
  }

  .mobile-homepage-footer {
    height: 23.7333vw;
  }

  .mobile-homepage-footer > button {
    height: 14.4vw;
    border-radius: 2.1333vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .cart > .mobile-header {
    height: 16.5333vw;
    padding: 0 4.2667vw 0 6.4vw;
  }

  .cart > .mobile-header > p {
    font-size: 5.3333vw;
    line-height: 7.4667vw;
  }

  .cart > .mobile-header > button {
    width: 8.5333vw;
    height: 8.5333vw;
    border-radius: 1.6vw;
  }

  .cart > .mobile-header > button > img {
    width: 3.2vw;
    height: 3.2vw;
  }

  .cart > .body {
    padding: 0 4.2667vw 3.2vw 4.2667vw;
  }

  .cart > .body > .cart-item {
    padding: 3.2vw 0;
    border-top: 0.2667vw solid var(--oliver-border);
  }

  .cart > .body > .cart-item > .main-row > .quantity {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-right: 4vw;
    margin: 0 4vw 0 2.1333vw;
  }

  .cart > .body > .cart-item > .main-row > .content-style {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    padding-right: 2.6667vw;
  }

  .cart > .body > .cart-item > .main-row > .price {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-right: 3.368vw;
  }

  .cart > .body > .cart-item > .main-row > button.remove-cart-item {
    width: 4.8vw;
    height: 4.8vw;
    margin-right: 1.768vw;
  }

  .cart > .body > .cart-item > .main-row > .tag {
    padding: 1.4667vw 2.9333vw;
    border-radius: 1.6vw;
    font-size: 2.9333vw;
    line-height: 4vw;
    margin-right: 3.7333vw;
  }

  .cart > .body > .cart-item > .secondary-col {
    padding: 0 9.0667vw 0 8.5333vw;
    margin-top: 2.6667vw;
  }

  .cart > .body > .cart-item > .secondary-col > p {
    font-size: 3.2vw;
    line-height: 4.5333vw;
  }

  .cart > .body > .cart-item > .secondary-col > .row > p {
    font-size: 3.2vw;
    line-height: 4.5333vw;
  }

  .cart > .footer {
    border-top: 0.2667vw solid var(--oliver-border);
  }

  .cart > .footer > .totals {
    padding: 4vw;
  }

  .cart > .footer > .totals > .row {
    margin-top: 1.8667vw;
  }

  .cart > .footer > .totals > .row > p {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .cart > .footer > .totals > .row > p:first-of-type {
    margin-right: 1.8667vw;
  }

  .cart > .footer > .totals > .row > button#editCartDiscount {
    font-size: 3.2vw;
    line-height: 4.5333vw;
  }

  .cart > .footer > .totals > .row > button#taxesButton {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-right: 1.0667vw;
  }

  .cart > .footer > .checkout-container {
    border-top: 0.2667vw solid var(--oliver-border);
    height: 22.4vw;
  }

  .cart > .footer > .checkout-container > button {
    padding: 4vw;
  }

  .cart > .footer > .checkout-container > button > p {
    border-radius: 2.1333vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .homepage-wrapper > .header {
    height: 36vw;
    border-bottom: 0.2667vw solid var(--oliver-border);
  }

  .homepage-wrapper > .navbar {
    top: 19.2vw;
    height: calc(100% - 19.2vw);
  }

  .product-wrapper {
    height: calc(100% - 38.4vw);
  }

  .product-wrapper > .header > .mobile-buttons {
    height: 74.9333vw;
    padding: 2.6667vw 4.2667vw;
  }

  .product-wrapper > .header > .mobile-buttons > button {
    width: 9.0667vw;
    height: 9.0667vw;
    border-radius: 2.1333vw;
  }

  .product-wrapper
    > .header
    > .mobile-buttons
    > button#mobileExitProductButton
    > img {
    width: 3.2vw;
    height: 3.2vw;
  }

  .product-wrapper > .header > .mobile-buttons > button#mobileAppsButton > img {
    width: 4.9653vw;
    height: 4.9653vw;
  }

  .product-wrapper > .header > .main {
    padding: 6.9333vw 4.2667vw;
  }

  .product-wrapper > .header > .main > p.route {
    font-size: 3.4667vw;
    line-height: 3.4667vw;
    margin-bottom: 3.2vw;
  }

  .product-wrapper > .header > .main > p.prod-name {
    font-size: 5.3333vw;
    line-height: 5.3333vw;
  }

  .product-wrapper > .mod-product {
    padding: 0 0 1.8667vw 4.2667vw;
  }

  .product-wrapper.no-edit > .mod-product {
    padding-top: 40.5333vw;
  }

  .product-wrapper.no-edit > .mod-product > .no-edit-item {
    width: calc(100% + 4.2667vw);
    left: -4.2667vw;
  }

  .product-wrapper.no-edit > .mod-product > .no-edit-item > .inner-container {
    width: min(calc(100% - 8.5333vw), 91.4667vw);
    height: 34.1333vw;
    border-radius: 2.1333vw;
    margin-top: 2.1333vw;
  }

  .product-wrapper.no-edit
    > .mod-product
    > .no-edit-item
    > .inner-container
    > p.style1 {
    font-size: 3.7333vw;
    line-height: 4.8vw;
    margin-bottom: 5.3333vw;
  }

  .product-wrapper.no-edit
    > .mod-product
    > .no-edit-item
    > .inner-container
    > p.style2 {
    font-size: 3.2vw;
    line-height: 4.2667vw;
  }

  .product-wrapper > .mod-product > .row > p {
    font-size: 3.4667vw;
    line-height: 4.5333vw;
    margin-bottom: 3.2vw;
  }

  .product-wrapper > .mod-product > p {
    font-size: 3.4667vw;
    line-height: 3.4667vw;
    margin-bottom: 2.1333vw;
  }

  .product-wrapper > .mod-product > p.error::after {
    font-size: 3.4667vw;
    line-height: 3.4667vw;
    margin-left: 2.6667vw;
  }

  .product-wrapper > .mod-product > .radio-group {
    width: calc(100% + 4.2667vw);
    margin-left: -4.2667vw;
    margin-bottom: 7.4667vw;
  }

  .product-wrapper > .mod-product > .radio-group > label {
    margin-right: 4.2667vw;
  }

  .product-wrapper > .mod-product > .radio-group > label:nth-child(1) {
    margin-left: 4.2667vw;
  }

  .product-wrapper > .mod-product > .radio-group > label > input {
    left: -2666.6667vw;
  }

  .product-wrapper > .mod-product > .radio-group > label > .custom-radio {
    padding: 3.7333vw;
    border: 0.4vw solid var(--oliver-border);
    border-radius: 2.6667vw;
  }

  .product-wrapper > .mod-product > .radio-group > label > .custom-radio > p {
    font-size: 3.4667vw;
    line-height: 3.4667vw;
    max-width: 80vw;
  }

  .product-wrapper
    > .mod-product
    > .radio-group
    > label
    > input:checked
    ~ .custom-radio {
    border: 0.5333vw solid var(--oliver-blue);
  }

  .product-wrapper > .mod-product > input {
    width: min(80vw, 100%);
    padding: 0 4.2667vw;
    height: 10.6667vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 2.1333vw;
    font-size: 3.4667vw;
    line-height: 3.4667vw;
    margin-bottom: 7.4667vw;
  }

  .product-wrapper > .mod-product > input:focus {
    border: 0.5333vw solid var(--oliver-blue);
  }

  .product-wrapper > .mod-product > .increment-input {
    gap: 2.9333vw;
    margin-bottom: 7.4667vw;
  }

  .product-wrapper > .mod-product > .increment-input > button {
    width: 10.6667vw;
    height: 10.6667vw;
    border-radius: 2.1333vw;
  }

  .product-wrapper > .mod-product > .increment-input > button > img {
    width: 4.2667vw;
    height: 8vw;
  }

  .product-wrapper > .mod-product > .increment-input > input {
    width: 21.3333vw !important;
    height: 10.6667vw;
    font-size: 3.7333vw;
    line-height: 4.8vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 2.1333vw;
  }

  .product-wrapper > .detailed-product > .row {
    padding: 8vw 0;
    margin: 0 4.2667vw;
    border-top: 0.2667vw solid var(--oliver-border);
    border-bottom: 0.2667vw solid var(--oliver-border);
  }

  .product-wrapper > .detailed-product > .row > .col > p.mobile-only {
    font-size: 3.7333vw;
    line-height: 3.7333vw;
    margin-bottom: 2.1333vw;
  }

  .product-wrapper > .detailed-product > .row > .col > .group {
    margin-bottom: 5.3333vw;
  }

  .product-wrapper > .detailed-product > .row > .col > .group > .text-row > p {
    font-size: 3.4667vw;
    line-height: 3.4667vw;
  }

  .product-wrapper
    > .detailed-product
    > .row
    > .col
    > .group
    > .text-row
    > p:first-of-type {
    margin-right: 1.0667vw;
  }

  .product-wrapper > .detailed-product > .row > .col > .group > button {
    margin-top: -1.0667vw;
    padding: 1.0667vw 0.5333vw;
    font-size: 3.4667vw;
    line-height: 3.4667vw;
  }

  .product-wrapper > .detailed-product > .row > .col > button {
    width: 40.2667vw;
    height: 9.3333vw;
    border: 0.2667vw solid var(--oliver-blue);
    border-radius: 2.1333vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .product-wrapper > .detailed-product > .row > .col > button > img {
    width: 8vw;
    height: 8vw;
    margin-right: 1.3333vw;
  }

  .product-wrapper > .detailed-product > .col {
    margin: 0 4.2667vw;
    padding: 8vw 0;
    border-bottom: 0.2667vw solid var(--oliver-border);
  }

  .product-wrapper > .detailed-product > .col > p.title {
    font-size: 3.7333vw;
    line-height: 3.7333vw;
    margin-bottom: 1.8667vw;
  }

  .product-wrapper > .detailed-product > .col > p.para {
    font-size: 3.4667vw;
    line-height: 4.2667vw;
    margin-bottom: 5.6vw;
  }

  .product-wrapper > .product-footer {
    height: 38.4vw;
    padding: 4.2667vw;
    border-top: 0.2667vw solid var(--oliver-border);
  }

  .product-wrapper > .product-footer > .row:nth-child(1) {
    width: calc(100% - 8.5333vw);
  }

  .product-wrapper > .product-footer > .row > button#addProductNote {
    width: calc(100% - 42.6667vw);
    height: 10.6667vw;
    border: 0.2667vw solid var(--oliver-blue);
    border-radius: 2.1333vw;
    font-size: 3.2vw;
    line-height: 3.2vw;
  }

  .product-wrapper > .product-footer > .row > button#addProductNote > img {
    width: 4vw;
    height: 4vw;
    margin-right: 2.6667vw;
  }

  .product-wrapper > .product-footer > .row > button#addProductToCart {
    bottom: 4.2667vw;
    width: calc(100% - 8.5333vw);
    height: 14.6667vw;
    border-radius: 2.1333vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .product-wrapper > .product-footer > .row > .increment-input {
    right: 4.2667vw;
    width: 37.3333vw;
  }

  .product-wrapper > .product-footer > .row > .increment-input > button {
    width: 10.6667vw;
    height: 10.6667vw;
    border-radius: 2.1333vw;
  }

  .product-wrapper
    > .product-footer
    > .row
    > .increment-input
    > button:first-of-type
    > img {
    width: 3.7333vw;
    height: 8vw;
  }

  .product-wrapper
    > .product-footer
    > .row
    > .increment-input
    > button:last-of-type
    > img {
    width: 4.2667vw;
    height: 8vw;
  }

  .product-wrapper > .product-footer > .row > .increment-input > input {
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .product-wrapper > .product-footer > .row > button#addProductToCart > img {
    width: 7.7333vw;
    height: 7.7333vw;
    margin-right: 2.1333vw;
  }

  .product-wrapper .product-image-container {
    height: 74.9333vw;
  }

  .product-wrapper > .recommended-upsells {
    padding: 5.8667vw 0;
  }

  .product-wrapper > .recommended-upsells > p {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin: 0 0 2.6667vw 4.2667vw;
  }

  .product-wrapper > .recommended-upsells > .button-row > button {
    width: 34.4vw;
    height: 34.4vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 2.1333vw;
    margin-right: 5.8667vw;
  }

  .product-wrapper > .recommended-upsells > .button-row > button:first-of-type {
    margin-left: 4.2667vw;
  }

  .product-wrapper > .recommended-upsells > .button-row > button:last-of-type {
    margin-right: 4.2667vw;
  }

  .product-wrapper > .recommended-upsells > .button-row > button > .prod-name {
    height: 6.6667vw;
    border-top: 0.2667vw solid var(--oliver-border);
  }

  .product-wrapper
    > .recommended-upsells
    > .button-row
    > button
    > .prod-name
    > p {
    font-size: 3.4667vw;
    line-height: 5.0667vw;
  }

  .checkout-wrapper > .header {
    height: 16vw;
    border-bottom: 0.2667vw solid var(--oliver-border);
    padding: 0 3.4667vw;
  }

  .checkout-wrapper > .header > button#exitCheckoutButton {
    width: 6.9333vw;
    height: 6.9333vw;
    margin-right: 4.5333vw;
  }

  .checkout-wrapper > .header > p {
    font-size: 5.3333vw;
    line-height: 7.4667vw;
  }

  .checkout-wrapper > .header > button#mobileOptionsButton {
    width: 9.0667vw;
    height: 9.0667vw;
    border-radius: 2.1333vw;
    margin-right: 2.6667vw;
  }

  .checkout-wrapper > .header > button#mobileOptionsButton > img {
    width: 7.944vw;
    height: 7.944vw;
  }

  .checkout-wrapper > .header > button#mobileAppsButton {
    width: 9.0667vw;
    height: 9.0667vw;
    border-radius: 2.1333vw;
  }

  .checkout-wrapper > .header > button#mobileAppsButton > img {
    width: 4.9653vw;
    height: 4.9227vw;
  }

  .checkout-wrapper > .page-options-wrapper {
    top: 15.7333vw;
  }

  .checkout-wrapper > .checkout-body {
    padding-top: 2.6667vw;
  }

  .checkout-wrapper > .checkout-body > .balance-container {
    width: 90.1333vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 2.1333vw;
    padding: 4.8vw 5.3333vw;
    margin-bottom: 1.3333vw;
  }

  .checkout-wrapper > .checkout-body > .balance-container p.style1 {
    font-size: 4.2667vw;
    line-height: 5.8667vw;
  }

  .checkout-wrapper > .checkout-body > .balance-container p.style2 {
    font-size: 5.8667vw;
    line-height: 8.2667vw;
  }

  .checkout-wrapper > .checkout-body > .balance-container p.style3 {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .checkout-wrapper > .checkout-body > .balance-container p.style4 {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .checkout-wrapper > .checkout-body > .balance-container:hover {
    border-width: 0.5333vw;
  }

  .checkout-wrapper > .checkout-body button#bottomText {
    padding-top: 1.3333vw;
    margin-bottom: 6.4vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .checkout-wrapper > .checkout-body > p.style2 {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    width: 90.1333vw;
    margin-bottom: 2.4vw;
  }

  .checkout-wrapper > .checkout-body > .button-row {
    width: 90.1333vw;
    margin-bottom: 4vw;
  }

  .checkout-wrapper > .checkout-body > .button-row > button {
    height: 10.6667vw;
    margin-left: 3.4667vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 2.1333vw;
    font-size: 3.7333vw;
    line-height: 4.8vw;
  }

  .checkout-wrapper
    > .checkout-body
    > .button-row
    > button:not(:disabled):hover {
    border-width: 0.5333vw;
  }

  .checkout-wrapper > .checkout-body > .button-row + p.style2 {
    padding-top: 1.8667vw;
  }

  .checkout-wrapper > .checkout-body > .payment-types {
    margin-top: 7.7333vw;
    padding: 5.3333vw 0;
  }

  .checkout-wrapper > .checkout-body > .payment-types > p {
    font-size: 4.2667vw;
    line-height: 5.3333vw;
    margin-bottom: 5.3333vw;
  }

  .checkout-wrapper > .checkout-body > .payment-types > .button-container {
    width: 90.1333vw;
  }

  .checkout-wrapper
    > .checkout-body
    > .payment-types
    > .button-container
    > button {
    width: 27.7333vw;
    height: 27.7333vw;
    margin: 0 3.4667vw 3.4667vw 0;
    border-radius: 2.1333vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .refund-wrapper > .header {
    height: 16vw;
    border-bottom: 0.2667vw solid var(--oliver-border);
    padding: 0 3.4667vw;
  }

  .refund-wrapper > .header > button#exitCheckoutButton {
    width: 6.9333vw;
    height: 6.9333vw;
    margin-right: 4.5333vw;
  }

  .refund-wrapper > .header > p {
    font-size: 5.3333vw;
    line-height: 7.4667vw;
  }

  .refund-wrapper > .header > button#mobileOptionsButton {
    width: 9.0667vw;
    height: 9.0667vw;
    border-radius: 2.1333vw;
    margin-right: 2.6667vw;
  }

  .refund-wrapper > .header > button#mobileOptionsButton > img {
    width: 7.944vw;
    height: 7.944vw;
  }

  .refund-wrapper > .header > button#mobileAppsButton {
    width: 9.0667vw;
    height: 9.0667vw;
    border-radius: 2.1333vw;
  }

  .refund-wrapper > .header > button#mobileAppsButton > img {
    width: 4.9653vw;
    height: 4.9227vw;
  }

  .refund-wrapper > .page-options-wrapper {
    top: 15.7333vw;
  }

  .refund-wrapper > .refund-body {
    padding-top: 2.6667vw;
  }

  .refund-wrapper > .refund-body > .balance-container {
    width: 90.1333vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 2.1333vw;
    padding: 4.8vw 5.3333vw;
    margin-bottom: 8.5333vw;
  }

  .refund-wrapper > .refund-body > .balance-container p.style1 {
    font-size: 4.2667vw;
    line-height: 5.8667vw;
  }

  .refund-wrapper > .refund-body > .balance-container p.style2 {
    font-size: 5.8667vw;
    line-height: 8.2667vw;
  }

  .refund-wrapper > .refund-body > .balance-container p.style3 {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .refund-wrapper > .refund-body > .balance-container p.style4 {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .refund-wrapper > .refund-body > button#bottomText {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 6.4vw;
    padding-top: 1.3333vw;
  }

  .refund-wrapper > .refund-body > p.style2 {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    width: 90.1333vw;
    margin-bottom: 2.4vw;
  }

  .refund-wrapper > .refund-body > .button-row {
    width: 90.1333vw;
    margin-bottom: 4vw;
  }

  .refund-wrapper > .refund-body > .button-row > button {
    height: 10.6667vw;
    margin-left: 3.4667vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 2.1333vw;
    font-size: 3.7333vw;
    line-height: 4.8vw;
  }

  .refund-wrapper > .refund-body > .button-row > button:not(:disabled):hover {
    border-width: 0.5333vw;
  }

  .refund-wrapper > .refund-body > .button-row + p.style2 {
    padding-top: 1.8667vw;
  }

  .refund-wrapper > .refund-body > .payment-types {
    margin-top: 7.7333vw;
    padding: 5.3333vw 0;
  }

  .refund-wrapper > .refund-body > .payment-types > p {
    font-size: 4.2667vw;
    line-height: 5.3333vw;
    margin-bottom: 5.3333vw;
  }

  .refund-wrapper > .refund-body > .payment-types > .button-container {
    width: 90.1333vw;
  }

  .refund-wrapper > .refund-body > .payment-types > .button-container > button {
    width: 27.7333vw;
    height: 27.7333vw;
    margin: 0 3.4667vw 3.4667vw 0;
    border-radius: 2.1333vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .sale-complete-wrapper {
    padding: 5.3333vw;
  }

  .sale-complete-wrapper > .main > img {
    width: 13.3333vw;
    height: 13.3333vw;
    margin-bottom: 4.8vw;
  }

  .sale-complete-wrapper > .main > p {
    font-size: 3.7333vw;
    line-height: 4.8vw;
    margin-bottom: 2.1333vw;
  }

  .sale-complete-wrapper > .main > #loadingDiv {
    margin-bottom: 8.5333vw;
  }

  .sale-complete-wrapper > .main > #loadingDiv > img {
    width: 6.1333vw;
    height: 6.1333vw;
    margin-right: 2.1333vw;
  }

  .sale-complete-wrapper > .main > #loadingDiv > p {
    font-size: 4.2667vw;
    line-height: 5.6vw;
  }

  .sale-complete-wrapper > .main > button#openUpdateTransactionStatus {
    padding: 2.1333vw;
    margin-bottom: 8.5333vw;
    font-size: 5.3333vw;
    line-height: 6.9333vw;
  }

  .sale-complete-wrapper > .main > button#openUpdateTransactionStatus > img {
    width: 5.3333vw;
    height: 5.3333vw;
    margin-left: 2.1333vw;
  }

  .sale-complete-wrapper > .main > .change-container {
    width: 77.3333vw;
    height: 27.7333vw;
    border-radius: 2.6667vw;
    margin-bottom: 9.0667vw;
  }

  .sale-complete-wrapper > .main > .change-container > p.style1 {
    font-size: 5.8667vw;
    line-height: 7.7333vw;
    margin-bottom: 1.8667vw;
  }

  .sale-complete-wrapper > .main > .change-container > p.style2 {
    font-size: 4.2667vw;
    line-height: 5.6vw;
  }

  .sale-complete-wrapper > .main > button {
    width: 53.3333vw;
    height: 13.3333vw;
    border: 0.2667vw solid #fff;
    border-radius: 1.6vw;
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .sale-complete-wrapper > .main > button#emailSubwindowButton {
    margin: 4.2667vw 0 9.0667vw 0;
  }

  .sale-complete-wrapper > .footer > .button-container > button#newSale {
    width: 77.8667vw;
    height: 14.6667vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
    margin-bottom: 10.6667vw;
  }

  .sale-complete-wrapper > .footer > .button-container > button#endSession {
    width: 39.2vw;
    height: 10.6667vw;
    border: 0.2667vw solid #fff;
    border-radius: 1.6vw;
    font-size: 4.2667vw;
    line-height: 5.6vw;
  }

  .sale-complete-wrapper
    > .footer
    > .button-container
    > button#endSession
    > img {
    width: 6.9333vw;
    height: 5.8667vw;
    margin-right: 1.3333vw;
  }

  .sale-complete-wrapper > .footer > .app-container {
    max-width: 74.6667vw;
    max-height: 37.3333vw;
    padding: 4vw 0 0 4vw;
    margin-bottom: 9.3333vw;
    border-radius: 4.2667vw;
  }

  .sale-complete-wrapper > .footer > .app-container > button {
    width: 14.6667vw;
    height: 14.6667vw;
    border-radius: 1.8667vw;
    margin: 0 4vw 4vw 0;
  }

  .refund-complete-wrapper {
    padding: 5.3333vw;
  }

  .refund-complete-wrapper > .main > img {
    width: 44vw;
    height: 24.8vw;
    margin: 9.8667vw 0 7.4667vw 0;
  }

  .refund-complete-wrapper > .main > button {
    width: 53.3333vw;
    height: 13.3333vw;
    border: 0.2667vw solid #fff;
    border-radius: 1.6vw;
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .refund-complete-wrapper > .main > button#emailSubwindowButton {
    margin: 4.2667vw 0 9.0667vw 0;
  }

  .refund-complete-wrapper > .footer > .button-container > button#newSale {
    width: 77.8667vw;
    height: 14.6667vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
    margin-bottom: 10.6667vw;
  }

  .refund-complete-wrapper > .footer > .button-container > button#endSession {
    width: 39.2vw;
    height: 10.6667vw;
    border: 0.2667vw solid #fff;
    border-radius: 1.6vw;
    margin-bottom: 9.0667vw;
    font-size: 4.2667vw;
    line-height: 5.6vw;
  }

  .refund-complete-wrapper
    > .footer
    > .button-container
    > button#endSession
    > img {
    width: 6.9333vw;
    height: 5.8667vw;
    margin-right: 1.3333vw;
  }

  .refund-complete-wrapper > .footer > .app-container {
    max-width: 74.6667vw;
    max-height: 37.3333vw;
    padding: 4vw 0 0 4vw;
    margin-bottom: 9.3333vw;
    border-radius: 4.2667vw;
  }

  .refund-complete-wrapper > .footer > .app-container > button {
    width: 14.6667vw;
    height: 14.6667vw;
    border-radius: 1.8667vw;
    margin: 0 4vw 4vw 0;
  }

  .customer-view-wrapper > .navbar {
    top: 16vw;
    height: calc(100% - 16vw);
    padding-bottom: 2.6667vw;
  }

  .customer-view-wrapper > .mobile-cv-header {
    height: 16vw;
    padding: 0 4.2667vw 0 2.1333vw;
  }

  .mobile-cv-header > #mobileNavToggle {
    width: 10.6667vw;
    height: 10.6667vw;
    margin-right: 2.1333vw;
  }

  .mobile-cv-header > #mobileNavToggle > img {
    width: 5.3333vw;
    height: 3.5547vw;
  }

  .mobile-cv-header > #mobileNavToggle.opened > img {
    width: 10.4vw;
    height: 10.4vw;
  }

  .mobile-cv-header > p {
    font-size: 5.3333vw;
    line-height: 7.4667vw;
  }

  .mobile-cv-header > #mobileCVSearchButton {
    width: 9.0667vw;
    height: 9.0667vw;
    border-radius: 2.1333vw;
    margin-right: 2.6667vw;
  }

  .mobile-cv-header > #mobileCVSearchButton > img {
    width: 5.6vw;
    height: 5.6vw;
  }

  .mobile-cv-header > #mobileAppsButton {
    width: 9.0667vw;
    height: 9.0667vw;
    border-radius: 2.1333vw;
  }

  .mobile-cv-header > #mobileAppsButton > img {
    width: 4.9653vw;
    height: 4.9227vw;
    transform: translateX(0.2667vw);
  }

  .cv-list {
    border-top: 0.2667vw solid var(--oliver-border);
  }

  .cv-list > .header {
    height: 14.6667vw;
    border-bottom: 0.2667vw solid var(--oliver-border);
    padding: 0 4.2667vw;
  }

  .cv-list > .header > p {
    font-size: 4.2667vw;
    line-height: 5.8667vw;
    margin-right: 2.6667vw;
  }

  .cv-list > .header > .sort-wrapper {
    width: 41.3333vw;
    height: 9.6vw;
  }

  .cv-list > .header > .sort-wrapper.open {
    box-shadow: 0vw 1.6vw 2.6667vw rgba(94, 94, 94, 0.1);
  }

  .cv-list > .header > .sort-wrapper > img {
    top: calc(50% - 1.6vw);
    right: 2.4vw;
    width: 3.4667vw;
    height: 3.4667vw;
  }

  .cv-list > .header > .sort-wrapper.open > img {
    transform: rotate(180deg) translateY(-0.5333vw);
  }

  .cv-list > .header > .sort-wrapper > input {
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 2.1333vw;
    padding: 0 6.6667vw 0 2.6667vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .cv-list > .header > .sort-wrapper.open > input {
    border-radius: 2.1333vw 2.1333vw 0 0;
  }

  .cv-list > .header > .sort-wrapper > .option-container > .option {
    height: 9.6vw;
    border: 0.2667vw solid var(--oliver-border);
    padding: 0 2.6667vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .cv-list > .header > .sort-wrapper > .option-container > .option:last-child {
    border-radius: 0 0 2.1333vw 2.1333vw;
  }

  .cv-list > .body > .no-search-results-list > p.style1 {
    font-size: 3.7333vw;
    margin-bottom: 2.6667vw;
  }

  .cv-list > .body > .no-search-results-list > p.style2 {
    font-size: 3.4667vw;
    line-height: 4.8vw;
  }

  .cv-list > .body > .filter-name {
    height: 8vw;
    padding: 0 8vw;
    border-top: 0.2667vw solid var(--oliver-border);
  }

  .cv-list > .body > .filter-name > p {
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .cv-list > .body > .customer-card {
    height: 25.3333vw;
    border-top: 0.2667vw solid var(--oliver-border);
    padding: 0 4.2667vw;
  }

  .cv-list > .body > .customer-card > .selected-indicator {
    width: 1.8667vw;
    height: 21.0667vw;
    top: 2.4vw;
    border-radius: 0 1.0667vw 1.0667vw 0;
  }

  .cv-list > .body > :last-child {
    border-bottom: 0.2667vw solid var(--oliver-border);
  }

  .cv-list > .body > .customer-card > .avatar {
    width: 9.6vw;
    height: 9.6vw;
    margin-right: 5.3333vw;
  }

  .cv-list > .body > .customer-card > .text-group > p.style1 {
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .cv-list > .body > .customer-card > .text-group > p.style2 {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .cv-list > .mobile-footer {
    height: 22.9333vw;
    padding: 0 4.2667vw;
    border-top: 0.2667vw solid var(--oliver-border);
  }

  .cv-list > .mobile-footer > button {
    height: 14.4vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .customer-view-wrapper > .cv-search {
    top: 16vw;
    height: calc(100% - 16vw);
    padding: 4.2667vw;
    border-top: 0.2667vw solid var(--oliver-border);
  }

  .customer-view-wrapper > .cv-search > .header > #mobileCVExitSearch {
    padding: 0 1.0667vw;
    font-size: 4.2667vw;
    line-height: 6.4vw;
    margin-bottom: 5.3333vw;
  }

  .customer-view-wrapper > .cv-search > .header > #mobileCVExitSearch > img {
    width: 2.4vw;
    height: 4.2667vw;
    margin-right: 2.1333vw;
  }

  .customer-view-wrapper > .cv-search > .body > .row {
    margin-bottom: 2.9333vw;
  }

  .customer-view-wrapper > .cv-search > .body > .row > p {
    font-size: 4.2667vw;
    line-height: 6.1333vw;
  }

  .customer-view-wrapper > .cv-search > .body > .row > button {
    padding: 1.3333vw;
    font-size: 4.2667vw;
    line-height: 6.1333vw;
  }

  .customer-view-wrapper > .cv-search > .body > label {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 1.0667vw;
  }

  .customer-view-wrapper > .cv-search > .body > input {
    height: 11.7333vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.6vw;
    margin-bottom: 5.3333vw;
    padding: 0 3.2vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .customer-view-wrapper > .cv-search > .body > button {
    height: 14.4vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
    margin-top: 5.3333vw;
  }

  .customer-view-wrapper > .cv-detailed {
    top: 16vw;
    height: calc(100% - 16vw);
    border-top: 0.2667vw solid var(--oliver-border);
  }

  .cv-detailed > .mobile-back {
    height: 14.1333vw;
    padding: 0 4.8vw;
  }

  .cv-detailed > .mobile-back > button {
    padding: 0 0.8vw;
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .cv-detailed > .mobile-back > button > img {
    width: 2.4267vw;
    height: 4.32vw;
    margin-right: 2.1333vw;
  }

  .cv-detailed > .quick-info {
    padding: 4.2667vw 4.8vw;
  }

  .cv-detailed > .quick-info > .avatar {
    width: 9.6vw;
    height: 9.6vw;
    margin: 1.0667vw 4vw 0 0;
  }

  .cv-detailed > .quick-info > .text-group > p.style1 {
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .cv-detailed > .quick-info > .text-group > p.style2 {
    font-size: 3.2vw;
    line-height: 4.5333vw;
  }

  .cv-detailed > .quick-info > button {
    width: 18.6667vw;
    height: 9.6vw;
    border: 0.2667vw solid var(--oliver-blue);
    border-radius: 2.1333vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .cv-detailed > .quick-info > button > img {
    width: 4vw;
    height: 4.2667vw;
    margin-right: 1.3333vw;
  }

  .cv-detailed > .cust-totals {
    width: calc(100% - 8.5333vw);
    padding: 3.4667vw 0;
    margin: 0 4.2667vw;
    border-bottom: 0.2667vw solid var(--oliver-border);
  }

  .cv-detailed > .cust-totals > .col > p.style1 {
    font-size: 4.2667vw;
    line-height: 6.6667vw;
  }

  .cv-detailed > .cust-totals > .col > p.style2 {
    font-size: 4.2667vw;
    line-height: 6.6667vw;
    margin-right: 2.1333vw;
  }

  .cv-detailed > .cust-totals > .col > button {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .cv-detailed > .cust-totals > .col > button > img {
    width: 4.2667vw;
    height: 4.2667vw;
    margin-left: 1.3333vw;
  }

  .cv-detailed > .bill-ship-info {
    width: calc(100% - 8.5333vw);
    border-bottom: 0.2667vw solid var(--oliver-border);
    padding: 4vw 0;
    margin: 0 4.2667vw;
  }

  .cv-detailed > .bill-ship-info > .col > p.style1 {
    font-size: 4.2667vw;
    line-height: 6.4vw;
    margin-bottom: 1.3333vw;
    margin-top: 4.5333vw;
  }

  .cv-detailed > .bill-ship-info > .col > p.style2 {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .cv-detailed > .cust-notes {
    width: calc(100% - 8.5333vw);
    padding: 3.4667vw 0;
    margin: 0 4.2667vw;
  }

  .cv-detailed > .cust-notes > .header-row {
    margin-bottom: 3.4667vw;
  }

  .cv-detailed > .cust-notes > .header-row > p {
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .cv-detailed > .cust-notes > .header-row > button {
    font-size: 3.7333vw;
    line-height: 5.6vw;
  }

  .cv-detailed > .cust-notes > .customer-note {
    margin-bottom: 5.3333vw;
  }

  .cv-detailed > .cust-notes > .customer-note > .row > p.style1 {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-right: 4.2667vw;
  }

  .cv-detailed > .cust-notes > .customer-note > .row > p.style2 {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .cv-detailed > .cust-notes > .customer-note > .row > button {
    width: 4.5333vw;
    height: 4.5333vw;
  }

  .cv-detailed > .cust-notes > .customer-note > p {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .cv-detailed > .footer {
    width: calc(100% - 8.5333vw);
    height: 22.6667vw;
    border-top: 0.2667vw solid var(--oliver-border);
  }

  .cv-detailed > .footer > button {
    height: 13.3333vw;
    border-radius: 1.6vw;
    font-size: 4.2667vw;
    line-height: 5.6vw;
  }

  .cv-detailed > .footer > button:first-of-type {
    margin-right: 4vw;
  }

  .transactions-wrapper > .navbar {
    top: 16vw;
    height: calc(100% - 16vw);
    padding-bottom: 2.6667vw;
  }

  .transactions-wrapper > .mobile-transactions-header {
    height: 18.6667vw;
    border-bottom: 0.2667vw solid var(--oliver-border);
    padding: 0 3.4667vw 0 2.1333vw;
  }

  .transactions-wrapper > .mobile-transactions-header > #mobileNavToggle {
    width: 10.6667vw;
    height: 10.6667vw;
    margin-right: 2.1333vw;
  }

  .transactions-wrapper > .mobile-transactions-header > #mobileNavToggle > img {
    width: 5.3333vw;
    height: 3.5547vw;
  }

  .transactions-wrapper
    > .mobile-transactions-header
    > #mobileNavToggle.opened
    > img {
    width: 10.4vw;
    height: 10.4vw;
  }

  .transactions-wrapper > .mobile-transactions-header > p {
    font-size: 5.3333vw;
    line-height: 7.4667vw;
  }

  .transactions-wrapper
    > .mobile-transactions-header
    > #mobileTransactionsSearchButton {
    width: 9.0667vw;
    height: 9.0667vw;
    border-radius: 2.1333vw;
    margin-right: 2.6667vw;
  }

  .transactions-wrapper
    > .mobile-transactions-header
    > #mobileTransactionsSearchButton
    > img {
    width: 5.6vw;
    height: 5.6vw;
  }

  .transactions-wrapper > .mobile-transactions-header > #mobileAppsButton {
    width: 9.0667vw;
    height: 9.0667vw;
    border-radius: 2.1333vw;
  }

  .transactions-wrapper
    > .mobile-transactions-header
    > #mobileAppsButton
    > img {
    width: 4.9653vw;
    height: 4.9227vw;
    transform: translateX(0.2667vw);
  }

  .transactions-wrapper > .mobile-refund.cart > .refund-cart-header {
    height: 17.3333vw;
    border-bottom: 0.2667vw solid var(--oliver-border);
    padding: 0 4.2667vw;
  }

  .transactions-wrapper > .mobile-refund.cart > .refund-cart-header > p {
    font-size: 5.3333vw;
    line-height: 7.4667vw;
  }

  .transactions-wrapper > .mobile-refund.cart > .refund-cart-header > button {
    width: 8.5333vw;
    height: 8.5333vw;
    border-radius: 1.6vw;
  }

  .transactions-wrapper
    > .mobile-refund.cart
    > .refund-cart-header
    > button
    > img {
    width: 3.2vw;
    height: 3.2vw;
  }

  .transactions-wrapper
    > .mobile-refund.cart
    > .body
    > .cart-item
    > .increment-input {
    width: 34.6667vw;
    height: 9.6vw;
    margin-top: 2.6667vw;
    padding: 1.3333vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.6vw;
  }

  .transactions-wrapper
    > .mobile-refund.cart
    > .body
    > .cart-item
    > .increment-input
    > button {
    border-radius: 1.6vw;
  }

  .transactions-wrapper
    > .mobile-refund.cart
    > .body
    > .cart-item
    > .increment-input
    > button
    > img {
    width: 2.6667vw;
    height: 2.6667vw;
  }

  .transactions-wrapper
    > .mobile-refund.cart
    > .body
    > .cart-item
    > .increment-input
    > input {
    font-size: 4.2667vw;
    line-height: 5.6vw;
  }

  .transactions-wrapper
    > .mobile-refund.cart
    > .footer
    > .totals
    > .row
    > button {
    font-size: 4.2667vw;
    line-height: 5.8667vw;
  }

  .transactions-wrapper
    > .mobile-refund.cart
    > .footer
    > .totals
    > .row
    > p.type2 {
    font-size: 4.2667vw;
    line-height: 5.8667vw;
  }

  .transactions-wrapper > .mobile-refund.cart > .footer > button {
    width: calc(100% - 8vw);
    height: 14.4vw;
    margin: 0 auto 4.5333vw auto;
    border-radius: 2.6667vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .transactions-wrapper > .mobile-refund.cart > .footer > button > img {
    width: 6.9333vw;
    height: 6.9333vw;
    margin-left: 3.2vw;
  }

  .transactions-list {
    height: calc(100% - 18.6667vw);
  }

  .transactions-list > .header {
    height: 14.6667vw;
    padding: 0 4.2667vw;
    border-bottom: 0.2667vw solid var(--oliver-border);
  }

  .transactions-list > .header > p {
    font-size: 4.2667vw;
    line-height: 5.8667vw;
    margin-right: 2.6667vw;
  }

  .transactions-list > .header > .sort-wrapper {
    width: 41.3333vw;
    height: 9.6vw;
    line-height: 0;
  }

  .transactions-list > .header > .sort-wrapper.open {
    box-shadow: 0vw 1.6vw 2.6667vw rgba(94, 94, 94, 0.1);
  }

  .transactions-list > .header > .sort-wrapper > img {
    top: calc(50% - 1.6vw);
    right: 2.4vw;
    width: 3.4667vw;
    height: 3.4667vw;
  }

  .transactions-list > .header > .sort-wrapper.open > img {
    transform: rotate(180deg) translateY(-0.5333vw);
  }

  .transactions-list > .header > .sort-wrapper > input {
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 2.1333vw;
    padding: 0 6.6667vw 0 2.6667vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .transactions-list > .header > .sort-wrapper.open > input {
    border-radius: 2.1333vw 2.1333vw 0 0;
  }

  .transactions-list > .header > .sort-wrapper > .option-container > .option {
    height: 9.6vw;
    border: 0.2667vw solid var(--oliver-border);
    padding: 0 2.6667vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .transactions-list
    > .header
    > .sort-wrapper
    > .option-container
    > .option:last-child {
    border-radius: 0 0 2.1333vw 2.1333vw;
  }

  .transactions-list > .body > .no-results {
    margin-top: 15.4667vw;
  }

  .transactions-list > .body > .no-results > p.style1 {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 2.6667vw;
  }

  .transactions-list > .body > .no-results > p.style2 {
    font-size: 3.4667vw;
    line-height: 4.8vw;
  }

  .transactions-list > .body > .filter-name {
    height: 8vw;
    padding: 0 8vw;
    border-top: 0.2667vw solid var(--oliver-border);
  }

  .transactions-list > .body > .filter-name > p {
    font-size: 3.7333vw;
    line-height: 5.6vw;
  }

  .transactions-list > .body > .transaction-card {
    height: 28.2667vw;
    border-top: 0.2667vw solid var(--oliver-border);
    padding: 4vw 4vw 4vw 5.3333vw;
  }

  .transactions-list > .body > :last-child {
    border-bottom: 0.2667vw solid var(--oliver-border);
  }

  .transactions-list > .body > .transaction-card > .col > p.style1 {
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .transactions-list > .body > .transaction-card > .col > p.style2 {
    font-size: 3.2vw;
    line-height: 4.5333vw;
  }

  .transactions-list > .body > .transaction-card > .col > .row > img {
    width: 3.7333vw;
    height: 3.2vw;
    margin-right: 2.1333vw;
  }

  .transactions-list > .body > .transaction-card > .col > .row > p {
    font-size: 3.2vw;
    line-height: 4.5333vw;
  }

  .transactions-list > .body > .transaction-card > .col > p.style3 {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .transactions-list > .body > .transaction-card > .col > p.style4 {
    font-size: 3.2vw;
    line-height: 4.5333vw;
    padding-right: 0.2667vw;
  }

  .transactions-list > .body > .transaction-card > .selected-indicator {
    width: 1.8667vw;
    height: 20.2667vw;
    top: calc(50% - 10.1333vw);
    border-radius: 0 1.0667vw 1.0667vw 0;
  }

  .transactions-search {
    top: 18.6667vw;
    height: calc(100% - 18.6667vw);
  }

  .transactions-search > .search-header-mobile {
    padding: 4.2667vw;
  }

  .transactions-search > .search-header-mobile > #mobileSearchExit {
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .transactions-search > .search-header-mobile > #mobileSearchExit > img {
    width: 2.4267vw;
    height: 4.32vw;
    margin-right: 2.4vw;
  }

  .transactions-search > .search-header-mobile > #mobileSearchFieldClear {
    width: 29.0667vw;
    height: 10.6667vw;
    border: 0.2667vw solid var(--oliver-blue);
    border-radius: 1.6vw;
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .transactions-search > .search-body {
    padding: 0 4.2667vw;
  }

  .transactions-search > .search-body > p.mobile-only {
    font-size: 4.2667vw;
    line-height: 6.1333vw;
    margin-bottom: 1.6vw;
  }

  .transactions-search > .search-body label {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 1.0667vw;
  }

  .transactions-search > .search-body input {
    height: 11.7333vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.6vw;
    padding: 0 3.2vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 5.3333vw;
  }

  .transactions-search > .search-body > input:first-of-type {
    margin-bottom: 1.8667vw;
  }

  .transactions-search > .search-body > p:not(.mobile-only) {
    font-size: 3.2vw;
    line-height: 4.5333vw;
  }

  .transactions-search > .search-body > .divider {
    height: 0.2667vw;
    margin: 5.3333vw 0;
  }

  .transactions-search > .search-body > .dropdown-wrapper {
    height: 11.7333vw;
    margin-bottom: 5.3333vw;
  }

  .transactions-search > .search-body > .dropdown-wrapper > img {
    right: 3.7333vw;
    top: calc(50% - 2.4vw);
    width: 4.8vw;
    height: 4.8vw;
  }

  .transactions-search > .search-body > .dropdown-wrapper.open > input {
    border-radius: 1.6vw 1.6vw 0 0;
  }

  .transactions-search > .search-body > .dropdown-wrapper > .option-list {
    max-height: 46.9333vw;
    border-radius: 0 0 1.8667vw 1.8667vw;
  }

  .transactions-search
    > .search-body
    > .dropdown-wrapper
    > .option-list
    > .option {
    height: 11.7333vw;
    padding: 0 3.2vw;
    border: 0.2667vw solid var(--oliver-border);
  }

  .transactions-search
    > .search-body
    > .dropdown-wrapper
    > .option-list
    > .option
    > p {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .transactions-search > .search-body > .input-row > .input-col:first-child {
    margin-right: 5.3333vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper {
    margin-bottom: 5.3333vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > input {
    padding-right: 8.5333vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > button {
    right: 3.2vw;
    top: calc(50% - 2.6667vw);
    width: 5.3333vw;
    height: 5.3333vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector {
    padding: 6.6667vw 5.3333vw;
    border: 0.2667vw solid var(--oliver-border);
    border-radius: 1.6vw;
    margin-top: 1.8667vw;
    filter: drop-shadow(0vw 2.6667vw 5.3333vw rgba(119, 135, 147, 0.1));
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper.left
    > .date-selector {
    left: 4.2667vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper.right
    > .date-selector {
    right: 4.2667vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .header-row {
    width: 74.6667vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .header-row
    > button {
    height: 8vw;
    padding: 0 2.6667vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .header-row
    > button
    > img {
    width: 10.6667vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .header-row
    > div {
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .day-row {
    width: 74.6667vw;
    border-bottom: 0.2667vw solid var(--oliver-border);
    margin-bottom: 2.6667vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .day-row
    > .day {
    height: 10.6667vw;
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .date-row {
    width: 74.6667vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .date-row
    > button.cell {
    height: 10.6667vw;
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    border-radius: 1.6vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .month-row {
    width: 85.3333vw;
    margin-top: 4vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .month-row
    > button.cell {
    height: 10.6667vw;
    font-size: 4.2667vw;
    line-height: 5.3333vw;
    border-radius: 1.6vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .year-row {
    width: 74.6667vw;
    margin-top: 4vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .year-row
    > button.cell {
    height: 10.6667vw;
    font-size: 4.2667vw;
    line-height: 5.3333vw;
    border-radius: 1.6vw;
  }

  .transactions-search > .search-body > button {
    height: 14.4vw;
    border-radius: 1.6vw;
    margin: auto 0 5.3333vw 0;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .transactions-detailed {
    top: 18.6667vw;
    height: calc(100% - 18.6667vw);
  }

  .transactions-detailed > .detailed-header-mobile {
    padding: 4.2667vw;
  }

  .transactions-detailed > .detailed-header-mobile > #mobileDetailedExit {
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .transactions-detailed > .detailed-header-mobile > #mobileDetailedExit > img {
    width: 2.4vw;
    height: 4.2667vw;
    margin-right: 2.4vw;
  }

  .transactions-detailed > .scrollable > .quick-info {
    padding: 4vw;
  }

  .transactions-detailed > .scrollable > .quick-info > .row {
    margin-top: 1.3333vw;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > p.style1 {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > p.style2 {
    font-size: 3.4667vw;
    line-height: 4.8vw;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > p.style3 {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > .group > img {
    width: 4.8vw;
    height: 4.2667vw;
    margin-right: 2.1333vw;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > .group > p {
    font-size: 4.2667vw;
    line-height: 5.6vw;
  }

  .transactions-detailed > .scrollable > .customer-info {
    width: calc(100% - 10.6667vw);
    padding: 4vw 0 5.3333vw 0;
    border-bottom: 0.2667vw solid var(--oliver-border);
  }

  .transactions-detailed > .scrollable > .customer-info > .col > p.style1 {
    font-size: 4.2667vw;
    line-height: 6.4vw;
    margin-bottom: 2.1333vw;
  }

  .transactions-detailed > .scrollable > .customer-info > .col > p.style2 {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-bottom: 1.0667vw;
  }

  .transactions-detailed > .scrollable > .customer-info > button {
    width: 41.0667vw;
    height: 10.6667vw;
    border: 0.2667vw solid var(--oliver-blue);
    border-radius: 1.6vw;
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .transactions-detailed > .scrollable > .order-details {
    padding: 4vw 5.3333vw;
    min-height: 26.6667vw;
  }

  .transactions-detailed > .scrollable > .order-details > p {
    font-size: 4.2667vw;
    line-height: 6.4vw;
    margin-bottom: 6.4vw;
  }

  .transactions-detailed > .scrollable > .order-details > .item {
    margin-top: 5.3333vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .img-container {
    width: 18.1333vw;
    height: 18.4vw;
    margin-right: 6.4vw;
    border-radius: 1.0667vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .img-container
    > .quantity {
    min-width: 6.9333vw;
    height: 6.9333vw;
    padding: 0 0.8vw;
    right: 0vw;
    top: 0vw;
    border-radius: 1.6vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .img-container
    > .quantity
    > p {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .img-container
    > img {
    border-radius: 1.0667vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .main-row
    > p {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .item-fields {
    padding-top: 1.6vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .item-fields
    > p {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .modifier-fields {
    padding: 2.1333vw 0 0 3.4667vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .modifier-fields
    > .row
    > p {
    font-size: 3.7333vw;
    line-height: 5.0667vw;
    padding-right: 2.6667vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .product-total-fields {
    padding-top: 2.1333vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .product-total-fields
    > div {
    padding-top: 1.6vw;
    border-top: 0.2667vw solid var(--oliver-border);
    margin-top: 1.6vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .product-total-fields
    > div
    > .row
    > p {
    font-size: 3.7333vw;
    line-height: 5.0667vw;
    padding-right: 2.6667vw;
  }

  .transactions-detailed > .scrollable > .order-details > .custom-fields {
    margin-top: 4vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .custom-fields
    > p.style1 {
    font-size: 4.2667vw;
    line-height: 6.4vw;
    margin-bottom: 1.8667vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .custom-fields
    > p.style2 {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
    margin-left: 3.7333vw;
    max-width: calc(100% - 3.7333vw);
    margin-top: 0.8vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper {
    padding: 4vw 0;
    border-top: 0.5333vw solid var(--oliver-border);
    margin-top: 4vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals {
    width: min(106.6667vw, 75%);
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row {
    padding-bottom: 1.6vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row.border-bottom {
    border-bottom: 0.2667vw solid var(--oliver-border);
    margin-bottom: 2.9333vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row.border-top {
    padding: 1.6vw 0 0 0;
    border-top: 0.2667vw solid var(--oliver-border);
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row
    > p {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row
    > p.style2 {
    font-size: 3.2vw;
    line-height: 4.5333vw;
  }

  .transactions-detailed > .scrollable > .order-details > .refund-header {
    padding: 1.3333vw 2.1333vw;
    margin: 6.6667vw 0 4vw 0;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .refund-header
    > p.style1 {
    font-size: 3.7333vw;
    line-height: 5.6vw;
    padding-right: 2.6667vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .refund-header
    > p.style2 {
    font-size: 3.2vw;
    line-height: 4.8vw;
  }

  .transactions-detailed > .footer {
    width: calc(100% - 8.5333vw);
    padding: 21.6vw 0 4.2667vw 0;
    border-top: 0.2667vw solid var(--oliver-border);
  }

  .transactions-detailed > .footer > button:not(#receiptButton) {
    height: 14.4vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .transactions-detailed > .footer > button:last-of-type {
    margin-left: 5.0667vw;
  }

  .transactions-detailed > .footer > button#receiptButton {
    height: 14.4vw;
    border: 0.2667vw solid var(--oliver-blue);
    border-radius: 1.6vw;
    top: 4.2667vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }

  .cash-management-wrapper > .navbar {
    top: 16vw;
    height: calc(100% - 16vw);
    padding-bottom: 2.6667vw;
  }

  .cash-management-wrapper > .mobile-cm-header {
    height: 18.6667vw;
    border-bottom: 0.2667vw solid var(--oliver-border);
    padding: 0 3.4667vw 0 2.1333vw;
  }

  .cash-management-wrapper > .mobile-cm-header > #mobileNavToggle {
    width: 10.6667vw;
    height: 10.6667vw;
    margin-right: 2.1333vw;
  }

  .cash-management-wrapper > .mobile-cm-header > #mobileNavToggle > img {
    width: 5.3333vw;
    height: 3.5547vw;
  }

  .cash-management-wrapper > .mobile-cm-header > #mobileNavToggle.opened > img {
    width: 10.4vw;
    height: 10.4vw;
  }

  .cash-management-wrapper > .mobile-cm-header > p {
    font-size: 5.3333vw;
    line-height: 7.4667vw;
  }

  .cash-management-wrapper
    > .mobile-cm-header
    > #mobileTransactionsSearchButton {
    width: 9.0667vw;
    height: 9.0667vw;
    border-radius: 2.1333vw;
    margin-right: 2.6667vw;
  }

  .cash-management-wrapper
    > .mobile-cm-header
    > #mobileTransactionsSearchButton
    > img {
    width: 5.6vw;
    height: 5.6vw;
  }

  .cash-management-wrapper > .mobile-cm-header > #mobileAppsButton {
    width: 9.0667vw;
    height: 9.0667vw;
    border-radius: 2.1333vw;
  }

  .cash-management-wrapper > .mobile-cm-header > #mobileAppsButton > img {
    width: 4.9653vw;
    height: 4.9227vw;
    transform: translateX(0.2667vw);
  }

  .cm-list > .cm-list-body > .current-register {
    height: 26.4vw;
    border-bottom: 0.2667vw solid var(--oliver-border);
    padding: 0 24.2667vw 0 4.2667vw;
  }

  .cm-list > .cm-list-body > .current-register > p.style1 {
    font-size: 4vw;
    line-height: 5.6vw;
  }

  .cm-list > .cm-list-body > .current-register > p.style2 {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .cm-list > .cm-list-body > .current-register > .text-row > p {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .cm-list > .cm-list-body > .current-register > .mobile-fake-button {
    right: 4.2667vw;
    width: 20vw;
    height: 10.6667vw;
    border: 0.2667vw solid var(--oliver-blue);
    border-radius: 1.6vw;
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .cm-list > .cm-list-body > .date {
    height: 8vw;
    border-bottom: 0.2667vw solid var(--oliver-border);
    padding: 4.2667vw;
  }

  .cm-list > .cm-list-body > .date > p {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .cm-list > .cm-list-body > .other-register {
    height: 20vw;
    border-bottom: 0.2667vw solid var(--oliver-border);
    padding: 0 4.2667vw;
  }

  .cm-list > .cm-list-body > .other-register > .row:nth-child(1) {
    margin-bottom: 1.0667vw;
  }

  .cm-list > .cm-list-body > .other-register > .row > p.style1 {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .cm-list > .cm-list-body > .other-register > .row > p.style2 {
    font-size: 3.7333vw;
    line-height: 5.3333vw;
  }

  .cm-list > .cm-list-body > .other-register.selected::before {
    width: 1.6vw;
    height: 16vw;
    top: 1.8667vw;
    border-radius: 0 1.0667vw 1.0667vw 0;
  }

  .cm-detailed {
    top: 18.6667vw;
    height: calc(100% - 18.6667vw);
  }

  .cm-detailed > .detailed-header-mobile {
    height: 13.8667vw;
  }

  .cm-detailed > .detailed-header-mobile > button {
    padding: 0 5.3333vw;
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .cm-detailed > .detailed-header-mobile > button > img {
    width: 2.4vw;
    height: 4.8vw;
    margin-right: 2.1333vw;
  }

  .cm-detailed > .detailed-quick-info {
    padding: 4vw;
  }

  .cm-detailed > .detailed-quick-info > .row:first-child {
    margin-bottom: 4vw;
  }

  .cm-detailed > .detailed-quick-info > .row > p {
    font-size: 3.7333vw;
    line-height: 5.6vw;
  }

  .cm-detailed > .detailed-quick-info > .row > .row-group > p {
    font-size: 4.8vw;
    line-height: 7.2vw;
    margin-right: 2.6667vw;
  }

  .cm-detailed > .detailed-quick-info > .row > .row-group > .status {
    height: 6.4vw;
    padding: 0 2.1333vw;
    border-radius: 1.6vw;
    font-size: 3.2vw;
    line-height: 4.8vw;
  }

  .cm-detailed > .detailed-quick-info > .row > .col-group > p.style1 {
    font-size: 3.4667vw;
    line-height: 5.0667vw;
  }

  .cm-detailed > .detailed-quick-info > .row > .col-group > p.style2 {
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .cm-detailed > .detailed-quick-info > .row > button {
    width: 23.4667vw;
    height: 10.6667vw;
    border: 0.2667vw solid var(--oliver-blue);
    border-radius: 2.1333vw;
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .cm-detailed > .detailed-quick-info > .row > button > img {
    width: 5.3333vw;
    height: 5.3333vw;
    margin-right: 2.1333vw;
  }

  .cm-detailed > .detailed-body {
    padding: 0 5.3333vw;
  }

  .cm-detailed > .detailed-body > .action {
    padding: 4vw 0;
    border-top: 0.2667vw solid var(--oliver-border);
  }

  .cm-detailed > .detailed-body > .action > .header-row > p {
    font-size: 3.7333vw;
    line-height: 5.6vw;
  }

  .cm-detailed > .detailed-body > .action > .header-row > p.style2 {
    font-size: 3.4667vw;
    line-height: 5.6vw;
  }

  .cm-detailed > .detailed-body > .action > .body-col > p {
    font-size: 3.4667vw;
    line-height: 5.3333vw;
  }

  .cm-detailed > .detailed-body > .action > .footer-col {
    margin-top: 2.6667vw;
  }

  .cm-detailed > .detailed-body > .action > .footer-col > p {
    font-size: 3.4667vw;
    line-height: 5.3333vw;
  }

  .cm-detailed > .detailed-footer {
    padding: 4.2667vw;
    border-top: 0.2667vw solid var(--oliver-border);
  }

  .cm-detailed > .detailed-footer > button {
    height: 13.3333vw;
    margin-left: 5.3333vw;
    border-radius: 1.6vw;
    font-size: 4.2667vw;
    line-height: 6.4vw;
  }

  .error-404-wrapper > .error-404-container {
    padding: 5.3333vw;
  }

  .error-404-wrapper > .error-404-container > img {
    width: 34.6667vw;
    height: 25.0667vw;
    margin: auto 0 9.3333vw 0;
  }

  .error-404-wrapper > .error-404-container > p.style1 {
    font-size: 10.6667vw;
    line-height: 14.1333vw;
    margin-bottom: 2.6667vw;
  }

  .error-404-wrapper > .error-404-container > p.style2 {
    font-size: 5.3333vw;
    line-height: 6.9333vw;
    margin-bottom: 2.6667vw;
  }

  .error-404-wrapper > .error-404-container > p.style3 {
    font-size: 5.3333vw;
    line-height: 6.9333vw;
  }

  .error-404-wrapper > .error-404-container > button {
    width: 46.1333vw;
    height: 13.3333vw;
    border-radius: 1.6vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
    margin: 5.3333vw 0 9.3333vw 0;
  }

  .error-404-wrapper > .error-404-container > .row {
    margin-bottom: 1.6vw;
  }

  .error-404-wrapper > .error-404-container > .row > p {
    font-size: 4.2667vw;
    line-height: 5.6vw;
    margin-right: 1.0667vw;
  }

  .error-404-wrapper > .error-404-container > .row > a {
    font-size: 4.2667vw;
    line-height: 5.6vw;
  }
}

@media (orientation: portrait) and (min-width: 600px) {
  .avatar {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  input:focus,
  textarea:focus {
    border-width: 0.3333vw !important;
  }

  .loading-page-wrapper {
    padding: 4.1667vw 0;
  }

  .loading-page-wrapper > img {
    width: 16.8333vw;
    height: 4.6667vw;
    margin-bottom: 10vw;
  }

  .loading-page-wrapper > svg {
    width: 14.1667vw;
    height: 14.1667vw;
    margin-bottom: 10vw;
  }

  .loading-page-wrapper > p.style1 {
    font-size: 3.3333vw;
    line-height: 4.3333vw;
    margin-bottom: 1.6667vw;
  }

  .loading-page-wrapper > p.style2 {
    font-size: 2.6667vw;
    line-height: 3.5vw;
    margin-bottom: 10vw;
  }

  .loading-page-wrapper > p.style3 {
    font-size: 2.3333vw;
    line-height: 3vw;
  }

  @keyframes animate-svg-fill-8 {
    0% {
      transform: translate(4.1667vw, -4.1667vw);
      fill: transparent;
    }

    10%,
    40% {
      transform: translate(0px, 0px);
      fill: #ffd026;
    }

    50%,
    100% {
      transform: translate(-4.1667vw, 4.1667vw);
      fill: transparent;
    }
  }

  .login-wrapper > img {
    width: 22.6667vw;
    height: 7.8667vw;
    margin: 8.3333vw 0 3.1467vw 0;
  }

  .login-wrapper > p {
    font-size: 2.6667vw;
    line-height: 3.8333vw;
    margin-bottom: 7.33vw;
  }

  .login-wrapper > .login-form {
    width: 53.3333vw;
    margin-bottom: 4.6667vw;
  }

  .login-wrapper > .login-form > label {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .login-wrapper > .login-form > input {
    height: 7.3333vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 0.3333vw;
    padding: 0 2vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 4.6667vw;
  }

  .login-wrapper > .login-form > .row {
    margin-bottom: 4.6667vw;
  }

  .login-wrapper > .login-form > .row > a {
    font-size: 2.3333vw;
    line-height: 3.5vw;
  }

  .login-wrapper > .login-form > .row > .custom-checkbox-wrapper {
    font-size: 2vw;
    line-height: 3.5vw;
  }

  .login-wrapper
    > .login-form
    > .row
    > .custom-checkbox-wrapper
    > input[type="checkbox"] {
    left: -1666.6667vw;
  }

  .login-wrapper
    > .login-form
    > .row
    > .custom-checkbox-wrapper
    > .custom-checkbox {
    width: 3.3333vw;
    height: 3.3333vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 0.6667vw;
    margin-right: 1.8333vw;
  }

  .login-wrapper > .login-form > button {
    height: 8vw;
    border-radius: 0.6667vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .login-wrapper > .or-row {
    width: 53.3333vw;
    margin-bottom: 4.6667vw;
  }

  .login-wrapper > .or-row > .divider {
    width: 19.96vw;
    height: 0.1667vw;
  }

  .login-wrapper > .or-row > p {
    font-size: 3.3333vw;
    line-height: 3.5vw;
  }

  .login-wrapper > button {
    width: 53.3333vw;
    height: 8vw;
    padding-left: 8vw;
    border-radius: 0.6667vw;
    margin-bottom: 2.6667vw;
    font-size: 3vw;
    line-height: 3.5vw;
  }

  .login-wrapper > button:last-of-type {
    margin-bottom: 3.5117vw;
  }

  .login-wrapper > button > .img-container {
    left: 0.3333vw;
    top: 0.3333vw;
    width: 7.3333vw;
    height: 7.3333vw;
    border-radius: 0.6667vw;
  }

  .login-wrapper > #googleButton > .img-container > img {
    width: 7.5317vw;
    height: 6.435vw;
  }

  .login-wrapper > #facebookButton > .img-container > img {
    width: 4.42vw;
    height: 3.5483vw;
  }

  .login-wrapper > #appleButton > .img-container > img {
    width: 6.7633vw;
    height: 4.57vw;
  }

  .login-wrapper > .row {
    margin-bottom: 8.3333vw;
  }

  .login-wrapper > .row > p {
    font-size: 2.3333vw;
    line-height: 3.5vw;
    margin-right: 0.8333vw;
  }

  .login-wrapper > .row > a {
    font-size: 2.3333vw;
    line-height: 3.5vw;
  }

  .choose-wrapper > .choose-header {
    height: 10.6667vw;
    padding: 0 3.3333vw;
  }

  .choose-wrapper > .choose-header > button {
    width: 18.1667vw;
    height: 6.6667vw;
    border: 0.1667vw solid var(--oliver-blue);
    border-radius: 1vw;
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .choose-wrapper > .choose-header > button > img {
    width: 1.1833vw;
    height: 2.3667vw;
    margin-right: 2.4833vw;
  }

  .choose-wrapper > .choose-header > p {
    font-size: 2.6667vw;
    line-height: 4vw;
    padding-left: 3.3333vw;
  }

  .choose-wrapper > .choose-body-default {
    padding-top: 2.3333vw;
  }

  .choose-wrapper > .choose-body-default button:disabled::after {
    background-size: 4vw 4vw;
    width: 4vw;
    height: 4vw;
    right: 2.8333vw;
  }

  .choose-wrapper > .choose-body-default > p {
    font-size: 3.6667vw;
    line-height: 5.3333vw;
    margin-bottom: 2vw;
  }

  .choose-wrapper > .choose-body-default > .divider {
    width: 10vw;
    height: 0.1667vw;
    margin-bottom: 4.3333vw;
  }

  .choose-wrapper > .choose-body-default > .button-container {
    width: 55.8333vw;
  }

  .choose-wrapper > .choose-body-default button {
    height: 15.3333vw;
    padding: 0 3.9833vw 0 2.8333vw;
    margin-bottom: 3.6667vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1.3333vw;
  }

  .choose-wrapper > .choose-body-default button > .img-container {
    width: 9.3333vw;
    height: 9.3333vw;
    margin-right: 3vw;
  }

  .choose-wrapper
    > .choose-body-default
    button
    > .img-container
    > img.oliver-icon {
    width: 4.6667vw;
    height: 4.6667vw;
    transform: translateX(0.1667vw);
  }

  .choose-wrapper
    > .choose-body-default
    button
    > .img-container
    > img.www-icon {
    width: 6vw;
    height: 6vw;
  }

  .choose-wrapper
    > .choose-body-default
    button
    > .img-container
    > img.store-icon {
    width: 4.785vw;
    height: 4.4433vw;
  }

  .choose-wrapper
    > .choose-body-default
    button
    > .img-container
    > img.register-icon {
    width: 4.5vw;
    height: 4.5vw;
  }

  .choose-wrapper
    > .choose-body-default
    button
    > .img-container
    > img.kiosk-icon {
    width: 6.1667vw;
    height: 6.1667vw;
  }

  .choose-wrapper > .choose-body-default button > .col > p.style1 {
    font-size: 2.6667vw;
    line-height: 3.8333vw;
  }

  .choose-wrapper > .choose-body-default button > .col > p.style2 {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .choose-wrapper > .choose-body-default button > img {
    width: 1.6833vw;
    height: 3.3667vw;
  }

  .choose-wrapper > .choose-body-default button#addNew {
    height: 6.7333vw;
    font-size: 2.6667vw;
    line-height: 3.6667vw;
  }

  .choose-wrapper > .choose-body-default button#addNew > img.mobile {
    width: 4vw;
    height: 4vw;
    margin-right: 1.8333vw;
  }

  .subwindow-wrapper > .subwindow {
    border-radius: 1.3333vw;
    filter: drop-shadow(0vw 1.6667vw 3.3333vw rgba(0, 0, 0, 0.25));
  }

  .subwindow-wrapper > .subwindow > .subwindow-header {
    height: 9vw;
    border-bottom: 0.1667vw solid var(--oliver-border);
  }

  .subwindow-wrapper > .subwindow > .subwindow-header > p {
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow-wrapper > .subwindow > .subwindow-header > button.close-subwindow {
    top: 1.8333vw;
    right: 1.8333vw;
    width: 5.3333vw;
    height: 5.3333vw;
    border-radius: 1vw;
  }

  .subwindow-wrapper
    > .subwindow
    > .subwindow-header
    > button.close-subwindow
    > img {
    width: 2vw;
    height: 2vw;
  }

  .subwindow-wrapper > .subwindow > .subwindow-body {
    border-radius: 0 0 1.3333vw 1.3333vw;
    padding: 3.3333vw 0;
  }

  .subwindow.update-plugin {
    width: min(57.3333vw, 95%);
    height: min(47.3333vw, 95%);
  }

  .subwindow.update-plugin > .subwindow-body {
    border-radius: 1.3333vw;
  }

  .subwindow.update-plugin > .subwindow-body > img {
    width: 6.6667vw;
    height: 6.6667vw;
    margin: auto 0 1.6667vw 0;
  }

  .subwindow.update-plugin > .subwindow-body > p.style1 {
    font-size: 2.6667vw;
    line-height: 3.5vw;
    margin-bottom: 1.6667vw;
  }

  .subwindow.update-plugin > .subwindow-body > p.style2 {
    font-size: 2.3333vw;
    line-height: 3vw;
    margin-bottom: 5.1667vw;
  }

  .subwindow.update-plugin > .subwindow-body > button {
    width: 51vw;
    height: 8.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow.cart-no-item {
    width: min(57.3333vw, 95%);
    height: min(35.8333vw, 95%);
  }

  .subwindow.cart-no-item > .subwindow-body {
    border-radius: 1.3333vw;
  }

  .subwindow.cart-no-item > .subwindow-body > p.style1 {
    font-size: 2.6667vw;
    line-height: 3.5vw;
    margin: auto 0 3.3333vw 0;
  }

  .subwindow.cart-no-item > .subwindow-body > p.style2 {
    font-size: 2.3333vw;
    line-height: 3vw;
    margin-bottom: 7.1667vw;
  }

  .subwindow.cart-no-item > .subwindow-body > button {
    width: 51vw;
    height: 8.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow.discount-error {
    width: min(57.3333vw, 95%);
    height: min(54.1667vw, 95%);
  }

  .subwindow.discount-error > .subwindow-body {
    border-radius: 1.3333vw;
  }

  .subwindow.discount-error > .subwindow-body > p.style1 {
    font-size: 2.6667vw;
    line-height: 3.5vw;
    margin-bottom: 3.3333vw;
    margin: auto 0 3.3333vw 0;
  }

  .subwindow.discount-error > .subwindow-body > p.style2 {
    font-size: 2.3333vw;
    line-height: 3vw;
    margin-bottom: 3.3333vw;
  }

  .subwindow.discount-error > .subwindow-body > p:last-of-type {
    margin-bottom: 8.3333vw;
  }

  .subwindow.discount-error > .subwindow-body > button {
    width: 51vw;
    height: 8.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow.product-unsupported {
    width: min(57.3333vw, 95%);
    height: min(62vw, 95%);
  }

  .subwindow.product-unsupported > .subwindow-body {
    border-radius: 1.3333vw;
  }

  .subwindow.product-unsupported > .subwindow-body > p.style1 {
    margin: auto 0 4.5vw 0;
    font-size: 2.6667vw;
    line-height: 3.5vw;
  }

  .subwindow.product-unsupported > .subwindow-body > img {
    width: 8.3333vw;
    height: 8.6667vw;
    margin-bottom: 4.5vw;
  }

  .subwindow.product-unsupported > .subwindow-body > p.style2 {
    font-size: 2.3333vw;
    line-height: 3vw;
    margin-bottom: 5.5vw;
  }

  .subwindow.product-unsupported > .subwindow-body > button {
    width: 51vw;
    height: 8.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow.refund-show-payments {
    width: min(57.3333vw, 95%);
  }

  .subwindow.refund-show-payments > .subwindow-body {
    max-height: calc(95vh - 9vw);
    padding: 3.3333vw 6vw;
  }

  .subwindow.refund-show-payments > .subwindow-body > .row {
    margin-top: 1.8333vw;
  }

  .subwindow.refund-show-payments > .subwindow-body > .row > p {
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow.refund-show-payments > .subwindow-body > button {
    height: 8.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
    margin-top: 5.5vw;
  }

  .subwindow.knowledge-base > .subwindow-header {
    height: 11.3333vw;
    padding: 0 8.3333vw 0 2.6667vw;
  }

  .subwindow.knowledge-base > .subwindow-header > .icon-container {
    width: 7.5vw;
    height: 7.5vw;
    border-radius: 1.3333vw;
    margin-right: 2.3333vw;
  }

  .subwindow.knowledge-base > .subwindow-header > p {
    font-size: 2.6667vw;
    line-height: 3.6667vw;
  }

  .subwindow.knowledge-base > .subwindow-header > button#kBRefresh {
    width: 5.6667vw;
    height: 5.6667vw;
    border-radius: 1.3333vw;
  }

  .subwindow.knowledge-base > .subwindow-header > button#kBRefresh > img {
    width: 3.3333vw;
    height: 3.3333vw;
  }

  .subwindow.knowledge-base > .subwindow-header > button.close-subwindow {
    top: calc(50% - 2.6667vw);
    width: 5.6667vw;
    height: 5.6667vw;
    border-radius: 1.3333vw;
  }

  .subwindow.no-permissions {
    width: min(57.3333vw, 95%);
    height: min(62vw, 95%);
  }

  .subwindow.no-permissions > .subwindow-body {
    border-radius: 1.3333vw;
  }

  .subwindow.no-permissions > .subwindow-body > img {
    width: 8.3333vw;
    height: 8.3333vw;
    padding-top: 4.1667vw;
    margin: auto 0 4.6667vw 0;
  }

  .subwindow.no-permissions > .subwindow-body > p.style1 {
    font-size: 2.6667vw;
    line-height: 3.5vw;
    margin-bottom: 4.6667vw;
  }

  .subwindow.no-permissions > .subwindow-body > p.style2 {
    font-size: 2.3333vw;
    line-height: 3vw;
    margin-bottom: 9.8333vw;
  }

  .subwindow.no-permissions > .subwindow-body > button {
    width: 51vw;
    height: 8.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow.no-account > .subwindow-body {
    padding: 6.6667vw 0 5.5vw 0;
  }

  .subwindow.no-account > .subwindow-body > .content > img.mobile-only {
    width: 22.6667vw;
    height: 7.8333vw;
    margin-bottom: 3.1667vw;
  }

  .subwindow.no-account > .subwindow-body > .content > p.mobile-only {
    font-size: 2.6667vw;
    line-height: 3.8333vw;
    margin-bottom: 3.3333vw;
  }

  .subwindow.no-account > .subwindow-body > .content > p.style1 {
    font-size: 2.6667vw;
    line-height: 3.1667vw;
    margin: auto 0 8.3333vw 0;
  }

  .subwindow.no-account > .subwindow-body > .content > p.style2 {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 8.3333vw;
  }

  .subwindow.no-account > .subwindow-body > .content > button#createAccount {
    width: 41vw;
    height: 8.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow.no-account > .subwindow-body > .content > button#loginTryAgain {
    width: 28.8333vw;
    height: 8.3333vw;
    border: 0.1667vw solid var(--oliver-blue);
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
    margin: 5.8333vw 0;
  }

  .subwindow.no-account > .subwindow-body > .content > .row > p {
    font-size: 2.6667vw;
    line-height: 3.5vw;
  }

  .subwindow.no-account > .subwindow-body > .content > .row > a {
    font-size: 2.6667vw;
    line-height: 3.5vw;
    margin-left: 0.6667vw;
  }

  .subwindow-wrapper > .subwindow.takeover-register {
    width: min(57.3333vw, 95%);
    height: min(56vw, 95%);
  }

  .subwindow-wrapper > .subwindow.takeover-register > .subwindow-body > p {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 6.5vw;
  }

  .subwindow-wrapper > .subwindow.takeover-register > .subwindow-body > p > b {
    font-weight: 100vw;
  }

  .subwindow-wrapper > .subwindow.takeover-register > .subwindow-body > button {
    width: 22.1667vw;
    height: 6.6667vw;
    border-radius: 1vw;
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .subwindow-wrapper
    > .subwindow.takeover-register
    > .subwindow-body
    > button#takeoverRegister {
    margin-bottom: 2.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.takeover-register
    > .subwindow-body
    > button#cancelTakeover {
    border: 0.1667vw solid var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.register-taken {
    width: min(57.3333vw, 95%);
    height: min(49.6667vw, 95%);
  }

  .subwindow-wrapper > .subwindow.register-taken > .subwindow-body > img {
    width: 8.3333vw;
    height: 8.6667vw;
    margin-bottom: 5vw;
  }

  .subwindow-wrapper > .subwindow.register-taken > .subwindow-body > p {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-header
    > img {
    margin: 0 2.3333vw 0 2.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header {
    height: 12.3333vw;
    border-bottom: 0.1667vw solid var(--oliver-border);
    padding: 0 2.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > .link-wrapper
    > img {
    width: 6.6667vw;
    height: 6.6667vw;
    border-radius: 1vw;
    margin-right: 2.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > .link-wrapper
    > .text-col
    > p.style1 {
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > .link-wrapper
    > .text-col
    > p.style2 {
    font-size: 2vw;
    line-height: 2.8333vw;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > button {
    width: 21.3333vw;
    height: 5.3333vw;
    border: 0.1667vw solid var(--oliver-base-grey);
    border-radius: 1vw;
    font-size: 2.1667vw;
    line-height: 2.8333vw;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > button
    > img {
    width: 2.1667vw;
    height: 2.1667vw;
    margin-right: 1vw;
  }

  .subwindow-wrapper > .subwindow.app-launcher-subwindow > .subwindow-header {
    padding: 2.6667vw 8vw 2.6667vw 2.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.app-launcher-subwindow
    > .subwindow-header
    > .app-wrapper
    > img {
    width: 8.3333vw;
    height: 8.3333vw;
    border-radius: 1.6667vw;
    margin-right: 3.1667vw;
  }

  .subwindow-wrapper
    > .subwindow.app-launcher-subwindow
    > .subwindow-header
    > .app-wrapper
    > .text-col
    > p.style1 {
    font-size: 3vw;
    line-height: 4.1667vw;
  }

  .subwindow-wrapper
    > .subwindow.app-launcher-subwindow
    > .subwindow-header
    > .app-wrapper
    > .text-col
    > p.style2 {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .subwindow.create-customer > .subwindow-body {
    padding: 3.3333vw;
  }

  .subwindow.create-customer > .subwindow-body > div:not(.button-row) {
    border-top: 0.1667vw solid var(--oliver-border);
    padding-top: 2.5vw;
    margin-top: 2.6667vw;
  }

  .subwindow.create-customer > .subwindow-body > div > p {
    font-size: 2.5vw;
    line-height: 3.8333vw;
  }

  .subwindow.create-customer > .subwindow-body > div > .input-row {
    margin-top: 2.6667vw;
  }

  .subwindow.create-customer > .subwindow-body > div > .input-row > .input-col {
    margin-top: 2.6667vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > label {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    padding-bottom: 0.6667vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input {
    height: 7vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1vw;
    padding: 0 2vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input:focus {
    border-width: 0.3333vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input.error
    ~ .error-wrapper::after {
    font-size: 2.1667vw;
    line-height: 3.1667vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper {
    height: 7vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > input:focus {
    border-width: 0.1667vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open
    > input {
    border-radius: 1vw 1vw 0 0;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > img {
    width: 3.3333vw;
    height: 3.3333vw;
    right: 2vw;
    top: calc(50% - 1.6667vw);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open
    > img {
    transform: rotate(180deg) translateY(-0.3333vw);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container {
    border: 0.1667vw solid var(--oliver-blue);
    border-radius: 0 0 1vw 1vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option {
    height: 7vw;
    padding: 0 2vw;
    border-radius: 0 0 1vw 1vw;
    border-top: 0.1667vw solid var(--oliver-blue);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option
    > p {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .subwindow.create-customer > .subwindow-body > div > .title-row > p {
    font-size: 2.5vw;
    line-height: 3.8333vw;
  }

  .subwindow.create-customer > .subwindow-body > div > .title-row > label {
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > input {
    left: -1666.6667vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > .custom-checkbox {
    width: 3vw;
    height: 3vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 0.6667vw;
    margin-right: 2vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > .custom-checkbox
    > img {
    width: 1.6667vw;
    height: 1.6667vw;
  }

  .subwindow.create-customer > .subwindow-body > .button-row {
    margin-top: 5vw;
  }

  .subwindow.create-customer > .subwindow-body > .button-row > button {
    height: 7.3333vw;
    margin-top: 2.6667vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow.edit-customer > .subwindow-body {
    padding: 3.3333vw;
  }

  .subwindow.edit-customer > .subwindow-body > div:not(.button-row) {
    border-top: 0.1667vw solid var(--oliver-border);
    padding-top: 2.5vw;
    margin-top: 2.6667vw;
  }

  .subwindow.edit-customer > .subwindow-body > div > p {
    font-size: 2.5vw;
    line-height: 3.8333vw;
  }

  .subwindow.edit-customer > .subwindow-body > div > .input-row {
    margin-top: 2.6667vw;
  }

  .subwindow.edit-customer > .subwindow-body > div > .input-row > .input-col {
    margin-top: 2.6667vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > label {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    padding-bottom: 0.6667vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input {
    height: 7vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1vw;
    padding: 0 2vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input:focus {
    border-width: 0.3333vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input.error
    ~ .error-wrapper::after {
    font-size: 2.1667vw;
    line-height: 3.1667vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper {
    height: 7vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > input:focus {
    border-width: 0.1667vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open
    > input {
    border-radius: 1vw 1vw 0 0;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > img {
    width: 3.3333vw;
    height: 3.3333vw;
    right: 2vw;
    top: calc(50% - 1.6667vw);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open
    > img {
    transform: rotate(180deg) translateY(-0.3333vw);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container {
    border: 0.1667vw solid var(--oliver-blue);
    border-radius: 0 0 1vw 1vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option {
    height: 7vw;
    padding: 0 2vw;
    border-radius: 0 0 1vw 1vw;
    border-top: 0.1667vw solid var(--oliver-blue);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option
    > p {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .subwindow.edit-customer > .subwindow-body > div > .title-row > p {
    font-size: 2.5vw;
    line-height: 3.8333vw;
  }

  .subwindow.edit-customer > .subwindow-body > div > .title-row > label {
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > input {
    left: -1666.6667vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > .custom-checkbox {
    width: 3vw;
    height: 3vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 0.6667vw;
    margin-right: 2vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > .custom-checkbox
    > img {
    width: 1.6667vw;
    height: 1.6667vw;
  }

  .subwindow.edit-customer > .subwindow-body > .button-row {
    margin-top: 5vw;
  }

  .subwindow.edit-customer > .subwindow-body > .button-row > button {
    height: 7.3333vw;
    margin-top: 2.6667vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > .button-row
    > button#editCustomerDelete {
    border: 0.1667vw solid var(--error);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > .button-row
    > button#editCustomerDelete
    > img {
    width: 3.3333vw;
    height: 3.5vw;
    margin-right: 1.6667vw;
  }

  .subwindow.delete-customer-confirm {
    width: min(57.3333vw, 95%);
    height: min(48.6667vw, 95%);
  }

  .subwindow.delete-customer-confirm > .subwindow-body > p {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 5.6667vw;
  }

  .subwindow.delete-customer-confirm > .subwindow-body > button {
    width: 22.1667vw;
    height: 6.6667vw;
    border-radius: 1vw;
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .subwindow.delete-customer-confirm > .subwindow-body > button#deleteCustomer {
    margin-bottom: 2.3333vw;
  }

  .subwindow.delete-customer-confirm
    > .subwindow-body
    > button#cancelDeleteCustomer {
    border: 0.1667vw solid var(--oliver-blue);
  }

  .subwindow.custom-fee {
    width: min(64vw, 95%);
    height: min(89.1667vw, 95%);
  }

  .subwindow.cart-discount {
    width: min(64vw, 95%);
    height: min(123.3333vw, 95%);
  }

  .subwindow.custom-fee > .subwindow-body > p,
  .subwindow.cart-discount > .subwindow-body > p {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 1.5vw;
  }

  .subwindow.custom-fee > .subwindow-body > .toggle-container,
  .subwindow.cart-discount > .subwindow-body > .toggle-container {
    width: 47.5vw;
    height: 8.3333vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1.3333vw;
    padding: 1vw;
    margin-bottom: 4vw;
  }

  .subwindow.custom-fee > .subwindow-body > .toggle-container > label > input,
  .subwindow.cart-discount
    > .subwindow-body
    > .toggle-container
    > label
    > input {
    left: -1666.6667vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > .toggle-container
    > label
    > .custom-radio,
  .subwindow.cart-discount
    > .subwindow-body
    > .toggle-container
    > label
    > .custom-radio {
    border-radius: 1vw;
    font-size: 2.6667vw;
    line-height: 3.5vw;
  }

  .subwindow.custom-fee > .subwindow-body > #customFeeDiv {
    width: 50.3333vw;
  }

  .subwindow.custom-fee > .subwindow-body > #customFeeDiv > label {
    padding-bottom: 1.3333vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > input#customFeeLabel {
    height: 7.3333vw;
    margin-bottom: 2.3333vw;
    padding: 0 2vw;
    border-radius: 1vw;
    border: 0.1667vw solid var(--oliver-border);
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > input#customFeeLabel:focus {
    border-width: 0.3333vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > input#customFeeAmount {
    height: 13.8333vw;
    border-radius: 1vw;
    border: 0.1667vw solid var(--oliver-border);
    padding: 0 2vw;
    font-size: 6.6667vw;
    line-height: 9.3333vw;
    margin-bottom: 1.3333vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > input#customFeeAmount:focus {
    border-width: 0.3333vw;
  }

  .subwindow.custom-fee > .subwindow-body > #customFeeDiv > p {
    font-size: 2.1667vw;
    line-height: 3vw;
    margin-bottom: 3.3333vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > .custom-toggle-wrapper {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 5vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > .custom-toggle-wrapper
    > input {
    left: -1666.6667vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > .custom-toggle-wrapper
    > .custom-toggle {
    width: 6.6667vw;
    height: 4.1667vw;
    border-radius: 4.1667vw;
    margin-left: 3.3333vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > .custom-toggle-wrapper
    > .custom-toggle::after {
    left: 0.3333vw;
    top: calc(50% - 1.8333vw);
    width: 3.6667vw;
    height: 3.6667vw;
    box-shadow: 0vw 0.3333vw 1vw rgba(0, 0, 0, 0.15),
      0vw 0.3333vw 0.1667vw rgba(0, 0, 0, 0.06);
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > .custom-toggle-wrapper
    > input:checked
    ~ .custom-toggle::after {
    right: 0.3333vw;
  }

  .subwindow.custom-fee > .subwindow-body > #customFeeDiv > button {
    height: 8.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow.cart-discount > .subwindow-body > #cartDiscountDiv > .main {
    width: 50.3333vw;
    padding: 3.6667vw 0 5vw 0;
    border-top: 0.1667vw solid var(--oliver-border);
    border-bottom: 0.1667vw solid var(--oliver-border);
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > label {
    padding-bottom: 1.5vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > input {
    height: 13.8333vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1vw;
    padding: 0 2vw;
    font-size: 6.6667vw;
    line-height: 9.3333vw;
    margin-bottom: 3.8333vw;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > input:focus {
    border-width: 0.3333vw;
  }

  .subwindow.cart-discount > .subwindow-body > #cartDiscountDiv > .main > p {
    font-size: 2.1667vw;
    line-height: 3vw;
    margin-bottom: 2vw;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > .button-row
    > button {
    height: 7.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
    margin-left: 2vw;
  }

  .subwindow.cart-discount > .subwindow-body > #cartDiscountDiv > .list {
    padding-top: 4.1667vw;
  }

  .subwindow.cart-discount > .subwindow-body > #cartDiscountDiv > .list > p {
    font-size: 2.3333vw;
    line-height: 3vw;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .list
    > button {
    width: 47.5vw;
    height: 7.3333vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1vw;
    margin-top: 2.5vw;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .list
    > button
    > p {
    font-size: 2.6667vw;
    line-height: 3.5vw;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .list
    > button#clearCartDiscountDiv
    > p {
    font-size: 2.3333vw;
    line-height: 3vw;
  }

  .subwindow-wrapper > .subwindow.add-tile {
    width: min(64vw, 95%);
    height: min(70.8333vw, 95%);
  }

  .subwindow-wrapper > .subwindow.add-tile > .subwindow-body > label {
    width: 51vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 1.5vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search {
    width: 51vw;
    height: 7.3333vw;
    margin-bottom: 4.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > img.search {
    width: 4vw;
    height: 4vw;
    left: 1.6667vw;
    top: 1.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > button#cancelDropdownSearch {
    width: 3vw;
    height: 3vw;
    right: 2.5vw;
    top: 2.4167vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > input {
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1vw;
    padding: 0 6.3333vw 0 6.6667vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search.open
    > input {
    border-radius: 1vw 1vw 0 0;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .error-message {
    top: -1.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .error-message::after {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .option-container {
    border-radius: 0 0 1vw 1vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .option-container
    > .dropdown-option {
    height: 7.3333vw;
    padding: 0 2vw;
    border: 0.1667vw solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .option-container
    > .dropdown-option
    > p {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .dropdown-option:last-child {
    border-radius: 0 0 1vw 1vw;
  }

  .subwindow-wrapper > .subwindow.add-tile > .subwindow-body > p {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 1.8333vw;
  }

  .subwindow-wrapper > .subwindow.add-tile > .subwindow-body > .radio-group {
    width: 39.31vw;
    margin-bottom: 1.84vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .radio-group
    > label {
    margin-right: 2.8767vw;
    margin: 0 2.8767vw 2.4933vw 0;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .radio-group
    > label
    > input[type="radio"] {
    left: -1666.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .radio-group
    > label
    > .custom-radio-button {
    width: 7.6667vw;
    height: 7.6667vw;
    border-radius: 1.1667vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .radio-group
    > label
    > input[type="radio"]:checked
    ~ .custom-radio-button {
    border: 0.3333vw solid #000 !important;
  }

  .subwindow-wrapper > .subwindow.add-tile > .subwindow-body > button {
    width: 51vw;
    height: 8.3333vw;
    border-radius: 1.3333vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow-wrapper > .subwindow.add-order-note {
    width: min(64vw, 95%);
    height: min(65vw, 95%);
  }

  .subwindow-wrapper > .subwindow.add-order-note > .subwindow-body > label {
    width: 51vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 2vw;
  }

  .subwindow-wrapper > .subwindow.add-order-note > .subwindow-body > textarea {
    width: 51vw;
    height: 30vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1vw;
    padding: 2vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 3.1667vw;
  }

  .subwindow-wrapper > .subwindow.add-order-note > .subwindow-body > button {
    width: 51vw;
    height: 8.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow-wrapper > .subwindow.product-not-found {
    width: min(62vw, 95%);
    height: min(62vw, 95%);
  }

  .subwindow-wrapper > .subwindow.product-not-found > .subwindow-body {
    border-radius: 1.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.product-not-found
    > .subwindow-body
    > p.style1 {
    font-size: 2.6667vw;
    line-height: 3.5vw;
    margin-bottom: 3.3333vw;
    margin: 5.6667vw 0 3.3333vw 0;
  }

  .subwindow-wrapper > .subwindow.product-not-found > .subwindow-body > img {
    width: 13.5283vw;
    height: 8.5833vw;
    margin-bottom: 5.75vw;
    transform: translateX(1.6667vw);
  }

  .subwindow-wrapper
    > .subwindow.product-not-found
    > .subwindow-body
    > p.style2 {
    font-size: 2.3333vw;
    line-height: 3vw;
    margin-bottom: 8.5vw;
  }

  .subwindow-wrapper > .subwindow.product-not-found > .subwindow-body > button {
    width: 51vw;
    height: 8.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow-wrapper > .subwindow.upgrade-to-unlock {
    width: min(62vw, 95%);
    height: min(62vw, 95%);
  }

  .subwindow-wrapper > .subwindow.upgrade-to-unlock > .subwindow-body {
    border-radius: 1.3333vw;
  }

  .subwindow-wrapper > .subwindow.upgrade-to-unlock > .subwindow-body > img {
    width: 8.3333vw;
    height: 8.3333vw;
    margin: 4.3333vw 0 4.6667vw 0;
  }

  .subwindow-wrapper
    > .subwindow.upgrade-to-unlock
    > .subwindow-body
    > p.style1 {
    font-size: 2.6667vw;
    line-height: 3.5vw;
    margin-bottom: 4.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.upgrade-to-unlock
    > .subwindow-body
    > p.style2 {
    font-size: 2.3333vw;
    line-height: 3vw;
    margin-bottom: 6.8333vw;
  }

  .subwindow-wrapper > .subwindow.upgrade-to-unlock > .subwindow-body > button {
    width: 51vw;
    height: 8.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow-wrapper > .subwindow.advanced-search > .subwindow-header {
    height: 15vw;
    border-radius: 1.3333vw 1.3333vw 0 0;
  }

  .subwindow-wrapper > .subwindow.advanced-search > .subwindow-header > p {
    font-size: 3vw;
    line-height: 4vw;
    margin-bottom: 3.5vw;
  }

  .subwindow-wrapper > .subwindow.advanced-search > .subwindow-header > button {
    width: 5.3333vw;
    height: 5.3333vw;
    top: 1.8333vw;
    right: 1.8333vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper {
    width: 51.6667vw;
    height: 9.1667vw;
    top: 10.6667vw;
    left: calc(50% - 25.8333vw);
    box-shadow: 0vw 0.8333vw 4.1667vw rgba(0, 0, 0, 0.2);
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > #advSearchInputIcon {
    width: 5vw;
    height: 4.8333vw;
    top: 2.1667vw;
    left: 1.8333vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > #advSearchInputCancel {
    width: 5vw;
    height: 5vw;
    right: 1.8333vw;
    top: 2.1667vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > #advSearchInputCancel
    > img {
    width: 2.9167vw;
    height: 2.9167vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > input {
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1vw;
    padding: 0 8.6667vw 0 8.6667vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col {
    padding-top: 8.5vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group {
    width: 51.6667vw;
    height: 6.6667vw;
    margin-bottom: 3.1667vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > .dropdown-input {
    border: 0.1667vw solid var(--oliver-border);
    padding: 0 2vw;
    border-radius: 1.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > .dropdown-input.open {
    border-radius: 1.3333vw 1.3333vw 0 0;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > .dropdown-input
    > p {
    font-size: 2vw;
    line-height: 2.8333vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > label {
    border: 0.1667vw solid var(--oliver-border);
    padding: 0 2vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > label:last-of-type {
    border-radius: 0 0 1.3333vw 1.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > label
    > input[type="radio"] {
    left: -1666.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > label
    > p {
    font-size: 2vw;
    line-height: 2.8333vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .start-searching {
    margin-top: -9vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .start-searching
    > img {
    width: 7.1667vw;
    height: 7vw;
    margin-bottom: 5vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .start-searching
    > p.style1 {
    font-size: 2.6667vw;
    line-height: 3.6667vw;
    margin-bottom: 3.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .start-searching
    > p.style2 {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .header {
    width: 51.6667vw;
    border-bottom: 0.1667vw solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .header
    > p {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 0.9483vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body {
    padding: 2.5vw 0;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results
    > p.style1 {
    font-size: 2.6667vw;
    line-height: 3.8333vw;
    margin-bottom: 0.9483vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results
    > p.style2 {
    font-size: 2vw;
    line-height: 3vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results
    > .divider {
    width: 16.6667vw;
    height: 0.1667vw;
    margin: 5vw 0;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results
    > button {
    width: 35vw;
    height: 6.6667vw;
    border: 0.1667vw solid var(--oliver-blue);
    border-radius: 1vw;
    font-size: 2vw;
    line-height: 2.6667vw;
    margin-top: 3.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results
    > button
    > img {
    width: 2.6667vw;
    height: 2.6667vw;
    margin-right: 1.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result {
    width: 51.6667vw;
    height: 33.3333vw;
    border-radius: 1vw;
    padding: 2.5vw 2.75vw 3vw 2.75vw;
    margin-top: 2.1667vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result.product {
    height: 36.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result.group {
    height: 36.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .col
    > p.style1 {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 0.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .col
    > p.style2 {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 0.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .col
    > p.style3 {
    font-size: 2vw;
    line-height: 2.8333vw;
    margin-bottom: 0.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button {
    width: 14.5vw;
    height: 10.8333vw;
    border-radius: 1vw;
    margin-right: 1.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-view {
    font-size: 1.8333vw;
    line-height: 2.5vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-view
    > img {
    width: 3.3333vw;
    height: 3.3333vw;
    margin-bottom: 0.5vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-transactions {
    font-size: 1.8333vw;
    line-height: 2.5vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-transactions
    > img {
    width: 4vw;
    height: 4vw;
    margin-bottom: 0.5vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-add-to-sale {
    font-size: 1.8333vw;
    line-height: 2.5vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-add-to-sale
    > img {
    width: 4vw;
    height: 4vw;
    margin-bottom: 0.5vw;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > .group {
    margin: 1.6667vw 0 2.8333vw 0;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > .group > img {
    width: 3.2867vw;
    height: 3.3333vw;
    margin-right: 1.88vw;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > .group > p {
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > p.style1 {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 4vw;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > .divider {
    width: 10.8333vw;
    height: 0.1667vw;
    margin-bottom: 4vw;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > p.style2 {
    font-size: 3.3333vw;
    line-height: 4.8333vw;
    margin-bottom: 4vw;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > .pinpad {
    width: 46.3333vw;
    margin-bottom: 1.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > p.error-message {
    padding: 1.8333vw 4.6667vw;
    border-radius: 1.3333vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin: -1.6667vw 0 3.3333vw 0;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-entries {
    margin-bottom: 2.8333vw;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-entries
    > .pin-entry {
    width: 2.6667vw;
    height: 2.6667vw;
    margin-left: 1.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row {
    gap: 2.6667vw;
    margin-bottom: 2.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row
    > button {
    width: 13.6667vw;
    height: 13.6667vw;
    border: 0.1667vw solid #b0bec9;
    border-radius: 1.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row
    > button
    > p {
    font-size: 6vw;
    line-height: 6vw;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row
    > button.backspace
    > img {
    width: 8.3333vw;
    height: 6.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row
    > button
    > .text-row {
    font-size: 1.6667vw;
    line-height: 2.5vw;
    letter-spacing: 0vw;
  }

  .subwindow-wrapper
    > .subwindow.end-session
    > .subwindow-body
    > button.mobile-close-subwindow {
    width: 18.1667vw;
    height: 6.6667vw;
    border: 0.1667vw solid var(--oliver-blue);
    border-radius: 1vw;
    font-size: 2.6667vw;
    line-height: 4vw;
    margin: 3.3333vw 0 0 3.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.end-session
    > .subwindow-body
    > button.mobile-close-subwindow
    > img {
    width: 1.1833vw;
    height: 2.3667vw;
    margin-right: 2.4833vw;
  }

  .subwindow-wrapper > .subwindow.end-session > .subwindow-body > p.style1 {
    font-size: 3vw;
    line-height: 4vw;
    margin: 3.3333vw 0 2.8333vw 0;
  }

  .subwindow-wrapper > .subwindow.end-session > .subwindow-body > p.style2 {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 4.5vw;
  }

  .subwindow-wrapper
    > .subwindow.end-session
    > .subwindow-body
    > button:not(.mobile-close-subwindow) {
    width: 38.3333vw;
    height: 8.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
    margin-bottom: 3.3333vw;
  }

  .subwindow-wrapper > .subwindow.partial-payment {
    width: min(66.6667vw, 95%);
    height: min(66.6667vw, 95%);
  }

  .subwindow-wrapper > .subwindow.partial-payment > .subwindow-body > label {
    width: 51vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 1.3333vw;
  }

  .subwindow-wrapper > .subwindow.partial-payment > .subwindow-body > input {
    width: 51vw;
    height: 11.6667vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 0.3333vw;
    font-size: 6.6667vw;
    line-height: 9.3333vw;
    margin-bottom: 4.6667vw;
  }

  .subwindow-wrapper > .subwindow.partial-payment > .subwindow-body > p {
    font-size: 2.6667vw;
    line-height: 3.3333vw;
    margin-bottom: 2vw;
  }

  .subwindow-wrapper
    > .subwindow.partial-payment
    > .subwindow-body
    > .payment-types {
    width: 51vw;
  }

  .subwindow-wrapper
    > .subwindow.partial-payment
    > .subwindow-body
    > .payment-types
    > button {
    width: 15.6667vw;
    height: 15.6667vw;
    margin: 0 2vw 2vw 0;
    border-radius: 1.3333vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow-wrapper > .subwindow.product-discount {
    width: min(64vw, 95%);
    height: min(57.3333vw, 95%);
  }

  .subwindow-wrapper > .subwindow.product-discount > .subwindow-body > label {
    width: 50.3333vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 1.5vw;
  }

  .subwindow-wrapper > .subwindow.product-discount > .subwindow-body > input {
    width: 50.3333vw;
    height: 13.8333vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1vw;
    font-size: 6.6667vw;
    line-height: 9.3333vw;
    margin-bottom: 3.8333vw;
  }

  .subwindow-wrapper > .subwindow.product-discount > .subwindow-body > p {
    font-size: 2.1667vw;
    line-height: 3vw;
    margin-bottom: 2vw;
  }

  .subwindow-wrapper
    > .subwindow.product-discount
    > .subwindow-body
    > .button-row {
    width: 50.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.product-discount
    > .subwindow-body
    > .button-row
    > button {
    height: 7.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow-wrapper
    > .subwindow.product-discount
    > .subwindow-body
    > .button-row
    > button:first-of-type {
    margin-right: 2.3333vw;
  }

  .subwindow-wrapper > .subwindow.adjust-inventory {
    width: min(64vw, 95%);
    height: min(76.3333vw, 95%);
  }

  .subwindow-wrapper > .subwindow.adjust-inventory > .subwindow-body > p {
    font-size: 3vw;
    line-height: 4.1667vw;
    margin-bottom: 2.5vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > p:first-of-type {
    margin: 3.3333vw 0 1vw 0;
  }

  .subwindow-wrapper > .subwindow.adjust-inventory > .subwindow-body > label {
    font-size: 2.6667vw;
    line-height: 3.5vw;
    margin-bottom: 1.6667vw;
  }

  .subwindow-wrapper > .subwindow.adjust-inventory > .subwindow-body > input {
    width: 51vw;
    height: 11.6667vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1vw;
    font-size: 6.6667vw;
    line-height: 9.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > button#editStockButton {
    padding: 1.6667vw;
    font-size: 2.6667vw;
    line-height: 3.5vw;
    margin-bottom: 1.1667vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > button#editStockButton
    > img {
    width: 3.1667vw;
    height: 3.1667vw;
    margin-right: 0.8333vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > button#updateStockButton {
    width: 51vw;
    height: 8.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
    margin-bottom: 5.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > .text-row {
    width: 51vw;
    margin-bottom: 1.5vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > :nth-last-child(2) {
    margin-bottom: 3.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > .text-row
    > p {
    font-size: 2.6667vw;
    line-height: 3.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > .text-row
    > p:first-of-type {
    margin-right: 3.3333vw;
  }

  .subwindow-wrapper > .subwindow.out-of-stock {
    width: min(57.3333vw, 95%);
    height: min(53.8333vw, 95%);
  }

  .subwindow-wrapper > .subwindow.out-of-stock > .subwindow-body {
    border-radius: 1.3333vw;
  }

  .subwindow-wrapper > .subwindow.out-of-stock > .subwindow-body > p.style1 {
    font-size: 2.6667vw;
    line-height: 3.5vw;
    margin-bottom: 3vw;
  }

  .subwindow-wrapper > .subwindow.out-of-stock > .subwindow-body > img {
    width: 11.5vw;
    height: 11.5vw;
    margin-bottom: 3.1667vw;
  }

  .subwindow-wrapper > .subwindow.out-of-stock > .subwindow-body > p.style2 {
    font-size: 2.3333vw;
    line-height: 3vw;
    margin-bottom: 6.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.out-of-stock
    > .subwindow-body
    > p.style2
    > button {
    font-size: 2.3333vw;
    line-height: 3vw;
  }

  .subwindow-wrapper > .subwindow.out-of-stock > .subwindow-body > button {
    width: 51vw;
    height: 8.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow-wrapper > .subwindow.product-note {
    width: min(64vw, 95%);
    height: min(66.6667vw, 95%);
  }

  .subwindow-wrapper > .subwindow.product-note > .subwindow-body > label {
    width: 51vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 2vw;
  }

  .subwindow-wrapper > .subwindow.product-note > .subwindow-body > textarea {
    width: 51vw;
    height: 30vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1vw;
    padding: 2vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 3.1667vw;
  }

  .subwindow-wrapper > .subwindow.product-note > .subwindow-body > button {
    width: 51vw;
    height: 8.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow-wrapper > .subwindow.park-sale {
    width: min(64vw, 95%);
    height: min(65.3333vw, 95%);
  }

  .subwindow-wrapper > .subwindow.park-sale > .subwindow-body > label {
    width: 51vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 1.5vw;
  }

  .subwindow-wrapper > .subwindow.park-sale > .subwindow-body > textarea {
    width: 51vw;
    height: 30vw;
    padding: 2vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 3.1667vw;
  }

  .subwindow-wrapper > .subwindow.park-sale > .subwindow-body > button {
    width: 51vw;
    height: 8.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow-wrapper > .subwindow.split-by-people {
    width: min(66.6667vw, 95%);
    height: min(66.6667vw, 95%);
  }

  .subwindow-wrapper
    > .subwindow.split-by-people
    > .subwindow-body
    > .input-numpad
    > .input-container
    > label {
    width: 51vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin: 3.3333vw 0 1.6667vw 0;
  }

  .subwindow-wrapper
    > .subwindow.split-by-people
    > .subwindow-body
    > .input-numpad
    > .input-container
    > input {
    width: 51vw;
    height: 11.6667vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1vw;
    font-size: 6.6667vw;
    line-height: 9.3333vw;
  }

  .subwindow-wrapper > .subwindow.split-by-people > .subwindow-body > button {
    width: 51vw;
    height: 8.3333vw;
    margin: 5.1667vw 0 3.3333vw 0;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow-wrapper > .subwindow.split-by-product {
    width: min(64vw, 95%);
    height: min(70.8333vw, 95%);
  }

  .subwindow-wrapper > .subwindow.split-by-product > .subwindow-body > .body {
    padding: 1vw 0;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row {
    width: 51vw;
    padding: 2.3333vw 0;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .main-row
    > .text-group
    > p {
    font-size: 2.6667vw;
    line-height: 3.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .main-row
    > .increment-input {
    width: 23.975vw;
    height: 7.8333vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1vw;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .main-row
    > .increment-input
    > button
    > img {
    width: 3.3333vw;
    height: 3.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .main-row
    > .increment-input
    > input {
    font-size: 3vw;
    line-height: 4.1667vw;
  }

  .subwindow-wrapper > .subwindow.split-by-product > .subwindow-body > .footer {
    height: 11.6667vw;
    border-top: 0.1667vw solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .footer
    > button {
    width: 51vw;
    height: 8.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow-wrapper > .subwindow.cash-payment.step-1 {
    width: min(64vw, 95%);
    height: min(111vw, 95%);
  }

  .subwindow-wrapper > .subwindow.cash-payment.step-2 {
    width: min(70vw, 95%);
    height: 66.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .text-row {
    width: 53.5vw;
    margin-bottom: 3.1667vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .text-row
    > p.style1 {
    font-size: 2.6667vw;
    line-height: 3.5vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .text-row
    > p.style2 {
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad {
    padding: 2.6667vw;
    border-radius: 1.3333vw;
    margin-bottom: 2.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > .input-container {
    width: 48.1667vw;
    height: 15.5vw;
    border-radius: 1vw;
    margin-bottom: 1.5vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > .input-container
    > label {
    font-size: 2vw;
    line-height: 2.8333vw;
    margin-bottom: 1.8333vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > .input-container
    > input {
    width: 32.1667vw;
    height: 7vw;
    font-size: 6.6667vw;
    line-height: 6.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > #numpad2
    > .button-row {
    margin-top: 1.5vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > #numpad2
    > .button-row
    > button {
    width: 15vw;
    height: 11.3333vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1vw;
    margin-left: 1.5vw;
    font-size: 5vw;
    line-height: 7.5vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > #numpad2
    > .button-row
    > button.backspace
    > img {
    width: 6.6667vw;
    height: 6.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > button {
    width: 53.3333vw;
    height: 8.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment.step-2
    > .subwindow-body
    > .step2
    > .text-row {
    width: 51vw;
    padding-top: 2.5vw;
    margin-top: 2.5vw;
    border-top: 0.1667vw solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.cash-payment.step-2
    > .subwindow-body
    > .step2
    > .text-row
    > p {
    font-size: 2.6667vw;
    line-height: 3.5vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment.step-2
    > .subwindow-body
    > .step2
    > button {
    width: 51vw;
    height: 8.3333vw;
    border-radius: 1vw;
    margin-top: 4.3333vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow-wrapper > .subwindow.email-receipt {
    width: min(64vw, 95%);
    height: min(45.8333vw, 95%);
  }

  .subwindow-wrapper > .subwindow.email-receipt > .subwindow-body > label {
    width: 51vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 1.5vw;
  }

  .subwindow-wrapper > .subwindow.email-receipt > .subwindow-body > input {
    width: 51vw;
    height: 7.3333vw;
    padding: 0 2vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 3.3333vw;
  }

  .subwindow-wrapper > .subwindow.email-receipt > .subwindow-body > button {
    width: 51vw;
    height: 8.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow-wrapper > .subwindow.quick-tax {
    width: min(50vw, 95%);
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-header {
    height: 9.1667vw;
    padding: 0 1.8333vw 0 3.6667vw;
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-header > P {
    font-size: 2.6667vw;
    line-height: 3.6667vw;
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-header > button {
    padding: 1.8333vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-body {
    padding: 3.6667vw;
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-body > .row {
    margin-top: 1.3333vw;
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-body > .row > p {
    font-size: 2.6667vw;
    line-height: 3.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.quick-tax
    > .subwindow-body
    > .row
    > label
    > input {
    left: -1666.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.quick-tax
    > .subwindow-body
    > .row
    > label
    > .custom-toggle {
    width: 6.6667vw;
    height: 4.1667vw;
    border-radius: 4.1667vw;
  }

  .subwindow-wrapper
    > .subwindow.quick-tax
    > .subwindow-body
    > .row
    > label
    > .custom-toggle
    > .knob {
    width: 3.6667vw;
    height: 3.6667vw;
    top: calc(50% - 1.8333vw);
    left: 0.25vw;
    border: 0.0654vw solid rgba(0, 0, 0, 0.04);
    box-shadow: 0vw 0.3922vw 1.0458vw rgba(0, 0, 0, 0.15),
      0vw 0.3922vw 0.1307vw rgba(0, 0, 0, 0.06);
  }

  .subwindow-wrapper
    > .subwindow.quick-tax
    > .subwindow-body
    > .row
    > label
    > input:checked
    ~ .custom-toggle
    > .knob {
    right: 0.25vw;
  }

  .subwindow-wrapper > .subwindow.detailed-tax {
    width: min(83.3333vw, 95%);
    height: min(113vw, 95%);
  }

  .subwindow-wrapper > .subwindow.detailed-tax > .subwindow-body > .header-row {
    height: 7.5vw;
    padding: 0 3.6667vw;
    border-bottom: 0.1667vw solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .header-row
    > p {
    font-size: 2.3333vw;
    line-height: 2.8333vw;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row {
    height: 10vw;
    padding: 0 3.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > p {
    font-size: 2.3333vw;
    line-height: 2.8333vw;
    padding-right: 2.5vw;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > .checkbox-wrapper
    > input {
    left: -1666.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > .checkbox-wrapper
    > .custom-checkbox {
    width: 3vw;
    height: 3vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 0.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > .checkbox-wrapper
    > .custom-checkbox
    > img {
    width: 2vw;
    height: 1.5767vw;
  }

  .subwindow-wrapper > .subwindow.detailed-tax > .subwindow-body > .footer {
    height: 14.1667vw;
    border-top: 0.1667vw solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .footer
    > button {
    width: 46.6667vw;
    height: 7.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow-wrapper > .subwindow.add-customer-note {
    width: min(64vw, 95%);
    height: min(66.6667vw, 95%);
  }

  .subwindow-wrapper > .subwindow.add-customer-note > .subwindow-body > label {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    width: 51vw;
    margin-bottom: 1.5vw;
  }

  .subwindow-wrapper
    > .subwindow.add-customer-note
    > .subwindow-body
    > textarea {
    width: 51vw;
    height: 30vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1vw;
    padding: 2vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 3.1667vw;
  }

  .subwindow-wrapper > .subwindow.add-customer-note > .subwindow-body > button {
    width: 51vw;
    height: 8.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow-wrapper > .subwindow.add-cash {
    width: min(64vw, 95%);
    height: min(74.1667vw, 95%);
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body > .text-row {
    width: 51.5vw;
    margin-bottom: 2.8333vw;
  }

  .subwindow-wrapper
    > .subwindow.add-cash
    > .subwindow-body
    > .text-row
    > p.style1 {
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .subwindow-wrapper
    > .subwindow.add-cash
    > .subwindow-body
    > .text-row
    > p.style2 {
    font-size: 3.3333vw;
    line-height: 5vw;
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body > .input-row {
    width: 51.5vw;
    padding-bottom: 4.3333vw;
    border-bottom: 0.1667vw solid var(--oliver-border);
    margin-bottom: 3.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.add-cash
    > .subwindow-body
    > .input-row
    > label {
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .subwindow-wrapper
    > .subwindow.add-cash
    > .subwindow-body
    > .input-row
    > input {
    width: 34.5vw;
    height: 8.3333vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1vw;
    padding: 0 2vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body > label {
    width: 51.5vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 2vw;
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body > textarea {
    width: 51vw;
    height: 16vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1vw;
    padding: 2vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 3.8333vw;
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body > button {
    width: 51vw;
    height: 8.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow-wrapper > .subwindow.remove-cash {
    width: min(64vw, 95%);
    height: min(74.1667vw, 95%);
  }

  .subwindow-wrapper > .subwindow.remove-cash > .subwindow-body > .text-row {
    width: 51.5vw;
    margin-bottom: 2.8333vw;
  }

  .subwindow-wrapper
    > .subwindow.remove-cash
    > .subwindow-body
    > .text-row
    > p.style1 {
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .subwindow-wrapper
    > .subwindow.remove-cash
    > .subwindow-body
    > .text-row
    > p.style2 {
    font-size: 3.3333vw;
    line-height: 5vw;
  }

  .subwindow-wrapper > .subwindow.remove-cash > .subwindow-body > .input-row {
    width: 51.5vw;
    padding-bottom: 4.3333vw;
    border-bottom: 0.1667vw solid var(--oliver-border);
    margin-bottom: 3.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.remove-cash
    > .subwindow-body
    > .input-row
    > label {
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .subwindow-wrapper
    > .subwindow.remove-cash
    > .subwindow-body
    > .input-row
    > input {
    width: 29vw;
    height: 8.3333vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1vw;
    padding: 0 2vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .subwindow-wrapper > .subwindow.remove-cash > .subwindow-body > label {
    width: 51.5vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 2vw;
  }

  .subwindow-wrapper > .subwindow.remove-cash > .subwindow-body > textarea {
    width: 51vw;
    height: 16vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1vw;
    padding: 2vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 3.8333vw;
  }

  .subwindow-wrapper > .subwindow.remove-cash > .subwindow-body > button {
    width: 51vw;
    height: 8.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow-wrapper > .subwindow.no-customer {
    width: min(64vw, 95%);
    height: min(64vw, 95%);
  }

  .subwindow-wrapper > .subwindow.no-customer > .subwindow-body {
    border-radius: 1.3333vw;
  }

  .subwindow-wrapper > .subwindow.no-customer > .subwindow-body > p.style1 {
    font-size: 2.6667vw;
    line-height: 3.5vw;
    margin-bottom: 4.1667vw;
  }

  .subwindow-wrapper > .subwindow.no-customer > .subwindow-body > img {
    width: 8.6667vw;
    height: 8.6667vw;
    margin-bottom: 4.8333vw;
  }

  .subwindow-wrapper > .subwindow.no-customer > .subwindow-body > p.style2 {
    font-size: 2.3333vw;
    line-height: 3vw;
    margin-bottom: 6vw;
  }

  .subwindow-wrapper > .subwindow.no-customer > .subwindow-body > button {
    width: 51vw;
    height: 8.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow-wrapper
    > .subwindow.no-customer
    > .subwindow-body
    > button:first-of-type {
    border: 0.1667vw solid var(--oliver-blue);
    margin-bottom: 1.8333vw;
  }

  .subwindow-wrapper
    > .subwindow.no-customer
    > .subwindow-body
    > button:first-of-type
    > img {
    width: 3.3333vw;
    height: 3.3333vw;
    margin-right: 2vw;
  }

  .subwindow-wrapper > .subwindow.adjust-credit {
    width: min(66.6667vw, 95%);
    height: min(83vw, 95%);
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body {
    border-radius: 1.3333vw;
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body > p {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 1.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .toggle-wrapper {
    width: 51.1667vw;
    height: 8.3333vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1.3333vw;
    padding: 1vw;
    margin-bottom: 3.5vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .toggle-wrapper
    > label
    > input {
    left: -1666.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .toggle-wrapper
    > label
    > .custom-radio {
    font-size: 2.6667vw;
    line-height: 3.5vw;
    border-radius: 1vw;
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body > .text-row {
    width: 51vw;
    margin-bottom: 1.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .text-row
    > p.style1 {
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .text-row
    > p.style2 {
    font-size: 3.3333vw;
    line-height: 5vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .change-credit-row {
    width: 51vw;
    margin-bottom: 2.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .change-credit-row
    > p {
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .change-credit-row
    > input {
    width: 26.6667vw;
    height: 8.3333vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1vw;
    padding: 0 2vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body > label {
    width: 51vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 2vw;
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body > textarea {
    width: 51vw;
    height: 16vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1vw;
    padding: 2vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 3.1667vw;
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body > button {
    width: 51vw;
    height: 8.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow-wrapper > .subwindow.layaway {
    width: min(64vw, 95%);
    height: min(66.6667vw, 95%);
  }

  .subwindow-wrapper > .subwindow.layaway > .subwindow-body > label {
    width: 51vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 2vw;
  }

  .subwindow-wrapper > .subwindow.layaway > .subwindow-body > textarea {
    width: 51vw;
    height: 30vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1vw;
    padding: 2vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 3.3333vw;
  }

  .subwindow-wrapper > .subwindow.layaway > .subwindow-body > button {
    width: 51vw;
    height: 8.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow-wrapper > .subwindow.store-credit-pay {
    width: min(64vw, 95%);
    height: min(111vw, 95%);
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .text-row {
    width: 53.5vw;
    margin-bottom: 3.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .text-row
    > p.style1 {
    font-size: 2.6667vw;
    line-height: 3.5vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .text-row
    > p.style2 {
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper {
    width: 53.5vw;
    padding: 2.6667vw;
    border-radius: 1.3333vw;
    margin-bottom: 2.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .input-wrapper {
    height: 15.5vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .input-wrapper
    > p {
    font-size: 2vw;
    line-height: 2.8333vw;
    margin-bottom: 1.8333vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .input-wrapper
    > input {
    height: 7vw;
    font-size: 6.6667vw;
    line-height: 6.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .numpad-row {
    height: 11.3333vw;
    margin-top: 1.5vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .numpad-row
    > button {
    margin-left: 1.5vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1vw;
    font-size: 5vw;
    line-height: 7.5vw;
    letter-spacing: 0vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .numpad-row
    > button
    > img {
    width: 6.6667vw;
    height: 6.6667vw;
  }

  .subwindow-wrapper > .subwindow.store-credit-pay > .subwindow-body > button {
    width: 53.5vw;
    height: 8.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow-wrapper > .subwindow.logout-confirm > .subwindow-body > p.style1 {
    font-size: 3.3333vw;
    line-height: 4.8333vw;
    margin-bottom: 8.8333vw;
  }

  .subwindow-wrapper > .subwindow.logout-confirm > .subwindow-body > p.style2 {
    font-size: 2.6667vw;
    line-height: 3.5vw;
    margin-top: 3.3333vw;
  }

  .subwindow-wrapper > .subwindow.logout-confirm > .subwindow-body > button {
    width: 57.1667vw;
    height: 9vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow-wrapper
    > .subwindow.logout-confirm
    > .subwindow-body
    > button#registerLogout {
    margin: 8.8333vw 0 2.6667vw 0;
  }

  .subwindow-wrapper
    > .subwindow.logout-confirm
    > .subwindow-body
    > button#cancelRegisterLogout {
    border: 0.1667vw solid var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.receipt {
    width: min(64vw, 95%);
    height: min(50vw, 95%);
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-options
    > p {
    font-size: 2.3333vw;
    line-height: 3vw;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-options
    > button {
    width: 44.1667vw;
    height: 8.3333vw;
    margin-top: 2.8333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-options
    > button:first-of-type {
    margin-top: 3.5vw;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-email
    > label {
    width: 51vw;
    font-size: 2.3333vw;
    line-height: 3vw;
    padding-bottom: 0.8333vw;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-email
    > input {
    width: 51.5vw;
    height: 8.3333vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1vw;
    padding: 0 2vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 2.8333vw;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-email
    > input:focus {
    border-width: 0.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-email
    > button {
    width: 51vw;
    height: 8.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow-wrapper > .subwindow.no-variation-selected {
    width: 57.3333vw;
    height: min(53.8333vw, 95%);
  }

  .subwindow-wrapper > .subwindow.no-variation-selected > .subwindow-body {
    border-radius: 1.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.no-variation-selected
    > .subwindow-body
    > p.style1 {
    font-size: 2.6667vw;
    line-height: 3.5vw;
    margin-bottom: 3.8333vw;
  }

  .subwindow-wrapper
    > .subwindow.no-variation-selected
    > .subwindow-body
    > img {
    width: 10.1667vw;
    height: 10.1667vw;
    margin-bottom: 3.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.no-variation-selected
    > .subwindow-body
    > p.style2 {
    font-size: 2.3333vw;
    line-height: 3vw;
    margin-bottom: 6.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.no-variation-selected
    > .subwindow-body
    > button {
    width: 51vw;
    height: 8.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .subwindow-wrapper > .subwindow.update-transaction-status {
    width: min(57.3333vw, 95%);
    height: min(95%, 94vw);
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > p {
    font-size: 2.3333vw;
    line-height: 3vw;
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > label {
    margin-top: 2.5vw;
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > label
    > input {
    left: -1666.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > label
    > .custom-radio {
    width: 47.5vw;
    height: 7.5vw;
    border-radius: 1.3333vw;
    border: 0.1667vw solid var(--oliver-border);
    font-size: 2.6667vw;
    line-height: 3.5vw;
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > label
    > input:not(:disabled):not(:checked)
    + .custom-radio:hover {
    border: 0.1667vw solid var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > label
    > input:checked
    + .custom-radio {
    border: 0.3333vw solid var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > button {
    margin-top: 4.6667vw;
    width: 47.5vw;
    height: 7.5vw;
    border-radius: 1.3333vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .open-register-wrapper > #cancel,
  .open-register-wrapper > #goBack {
    width: 22.0167vw;
    height: 8.6667vw;
    font-size: 3.3333vw;
    line-height: 5vw;
  }

  .open-register-wrapper > #cancel > img,
  .open-register-wrapper > #goBack > img {
    width: 1.3667vw;
    height: 2.7333vw;
    margin-right: 1.6667vw;
  }

  .open-register-wrapper > #logout-mobile {
    width: 21.85vw;
    height: 8.6667vw;
    font-size: 3.3333vw;
    line-height: 5vw;
    margin-top: 3.3333vw;
  }

  .open-register-wrapper > #logout-mobile > img {
    width: 4vw;
    height: 4vw;
    margin-right: 1.3333vw;
  }

  .open-register-wrapper > header {
    width: 57.1667vw;
    padding: 1.6667vw 0 5.0467vw 0;
    border-bottom: 0.1667vw solid #fff;
    margin-bottom: 3.3333vw;
  }

  .open-register-wrapper > header > img {
    width: 17.81vw;
    height: 16.2867vw;
    margin-right: 4.69vw;
  }

  .open-register-wrapper > header p.style1 {
    font-size: 5vw;
    line-height: 7.1667vw;
    margin-bottom: 0.8333vw;
  }

  .open-register-wrapper > header p.style2 {
    font-size: 2.6667vw;
    line-height: 4.3333vw;
    margin-bottom: 0.6667vw;
  }

  .open-register-wrapper > header p.style3 {
    font-size: 2.6667vw;
    line-height: 2.6667vw;
  }

  .open-register-wrapper > main > .step1 > p {
    font-size: 3.3333vw;
    line-height: 4.8333vw;
    margin-bottom: 4.3333vw;
  }

  .open-register-wrapper > main > .step1 > button {
    width: 57.1667vw;
    height: 9vw;
    border-radius: 1.3333vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .open-register-wrapper > main > .step2 > p {
    width: 57.1667vw;
    font-size: 3.3333vw;
    line-height: 4.8333vw;
    margin-bottom: 3.8333vw;
    margin: 2.6667vw 0 3.8333vw 0;
  }

  .open-register-wrapper > main > .step2 > label {
    width: 57.1667vw;
    font-size: 2.6667vw;
    line-height: 4vw;
    margin-bottom: 1.5vw;
  }

  .open-register-wrapper > main > .step2 > input {
    width: 57.1667vw;
    height: 12vw;
    border-radius: 1vw;
    font-size: 5.3333vw;
    line-height: 7.5vw;
    margin-bottom: 4.3333vw;
  }

  .open-register-wrapper > main > .step2 > button {
    width: 57.1667vw;
    height: 9vw;
    font-size: 3vw;
    line-height: 4vw;
    border-radius: 1.3333vw;
  }

  .open-register-wrapper > main > .step2 > button#openCashDrawer {
    border: 0.1667vw solid #fff;
    margin-bottom: 3.8333vw;
  }

  .open-register-wrapper > main > .step2 > button#openFloatButton {
    margin: auto 0 4.3333vw 0;
  }

  .open-register-wrapper > main > .step2 > textarea {
    width: 57.1667vw;
    height: 13.8333vw;
    border-radius: 1vw;
    padding: 2vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 3.8333vw;
  }

  .open-register-wrapper > main > .step3 > p {
    font-size: 2.6667vw;
    line-height: 3.8333vw;
    margin: 3.3333vw 0 3.245vw 0;
  }

  .open-register-wrapper > main > .step3 > .error-message {
    width: 46vw;
    height: 7vw;
    border-radius: 1vw;
    margin-bottom: 3vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .open-register-wrapper > main > .step3 > .pinpad {
    width: 46vw;
  }

  .open-register-wrapper > main > .step3 > .pinpad > .pin-entries {
    margin-bottom: 2.4817vw;
  }

  .open-register-wrapper > main > .step3 > .pinpad > .pin-entries > .pin-entry {
    width: 1.8833vw;
    height: 1.8833vw;
    margin-left: 1.13vw;
  }

  .open-register-wrapper > main > .step3 > .pinpad > .pin-button-row {
    margin-bottom: 2.7167vw;
  }

  .open-register-wrapper > main > .step3 > .pinpad > .pin-button-row > button {
    width: 13.585vw;
    height: 13.585vw;
    border-radius: 1.3333vw;
    margin-right: 2.7167vw;
  }

  .open-register-wrapper
    > main
    > .step3
    > .pinpad
    > .pin-button-row
    > button
    > p {
    font-size: 6vw;
    line-height: 6vw;
    transform: translateY(0.5vw);
  }

  .open-register-wrapper
    > main
    > .step3
    > .pinpad
    > .pin-button-row
    > button
    > .text-row {
    font-size: 1.6667vw;
    line-height: 2.5vw;
  }

  .open-register-wrapper
    > main
    > .step3
    > .pinpad
    > .pin-button-row
    > button.backspace
    > img {
    width: 8.3967vw;
    height: 6.6817vw;
  }

  .idle-register-wrapper > #logoutRegisterButton {
    padding: 0.8333vw;
    font-size: 2.6667vw;
    line-height: 4.3333vw;
  }

  .idle-register-wrapper > #logoutRegisterButton > img {
    width: 3.3333vw;
    height: 3.3333vw;
    margin-right: 0.8333vw;
  }

  .idle-register-wrapper > header {
    width: 57.1667vw;
    padding: 5vw 0;
    border-bottom: 0.1667vw solid #fff;
  }

  .idle-register-wrapper > header > img {
    width: 17.81vw;
    height: 16.2867vw;
    margin-right: 4.69vw;
  }

  .idle-register-wrapper > header > .col > p.style1 {
    font-size: 5vw;
    line-height: 7.1667vw;
  }

  .idle-register-wrapper > header > .col > p.style2 {
    font-size: 2.6667vw;
    line-height: 4.6667vw;
    margin-bottom: -1.3333vw;
  }

  .idle-register-wrapper > header > .col > p.style3 {
    font-size: 2.6667vw;
    line-height: 4.6667vw;
  }

  .idle-register-wrapper > main > p {
    font-size: 2.6667vw;
    line-height: 3.8333vw;
    margin: 5vw 0 3.245vw 0;
  }

  .idle-register-wrapper > main > .pinpad {
    width: 46.3333vw;
    margin-bottom: 1.6667vw;
  }

  .idle-register-wrapper > main > .pinpad > p.error-message {
    padding: 1.8333vw 4.6667vw;
    border-radius: 1.3333vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin: -1.6667vw 0 3.3333vw 0;
  }

  .idle-register-wrapper > main > .pinpad > .pin-entries {
    margin-bottom: 2.8333vw;
  }

  .idle-register-wrapper > main > .pinpad > .pin-entries > .pin-entry {
    width: 2.6667vw;
    height: 2.6667vw;
    margin-left: 1.6667vw;
  }

  .idle-register-wrapper > main > .pinpad > .pin-button-row {
    gap: 2.6667vw;
    margin-bottom: 2.6667vw;
  }

  .idle-register-wrapper > main > .pinpad > .pin-button-row > button {
    width: 13.6667vw;
    height: 13.6667vw;
    border-radius: 1.3333vw;
  }

  .idle-register-wrapper
    > main
    > .pinpad
    > .pin-button-row
    > button.backspace
    > img {
    width: 8.3333vw;
    height: 6.6667vw;
  }

  .idle-register-wrapper > main > .pinpad > .pin-button-row > button > p {
    font-size: 6vw;
    line-height: 6vw;
  }

  .idle-register-wrapper
    > main
    > .pinpad
    > .pin-button-row
    > button
    > .text-row {
    font-size: 1.6667vw;
    line-height: 2.5vw;
    letter-spacing: 0vw;
  }

  .idle-register-wrapper > main > button#closeRegister2 {
    width: 56.1667vw;
    height: 8.3333vw;
    border-radius: 1.3333vw;
    font-size: 3vw;
    line-height: 4vw;
    margin: auto 0 3.3333vw 0;
  }

  .close-register-wrapper > button {
    width: 13vw;
    height: 8.75vw;
    font-size: 2.5vw;
    line-height: 2.5vw;
  }

  .close-register-wrapper > button > img {
    height: 2.0833vw;
    margin-right: 0.6667vw;
  }

  .close-register-wrapper > header {
    width: 57.1667vw;
    padding: 5.0467vw 0;
    border-bottom: 0.1667vw solid #fff;
  }

  .close-register-wrapper > header > img {
    width: 17.81vw;
    height: 16.2867vw;
    margin-right: 4.69vw;
  }

  .close-register-wrapper > header > .col > p.style1 {
    font-size: 5vw;
    line-height: 7.1667vw;
    margin-bottom: -0.3333vw;
  }

  .close-register-wrapper > header > .col > p.style2 {
    font-size: 2.6667vw;
    line-height: 4.6667vw;
    margin-bottom: -1.3333vw;
  }

  .close-register-wrapper > header > .col > p.style3 {
    font-size: 2.6667vw;
    line-height: 4.6667vw;
  }

  .close-register-wrapper > main > .step1 > p.style1 {
    font-size: 4.1667vw;
    line-height: 6vw;
    margin: 3.3333vw 0;
  }

  .close-register-wrapper > main > .step1 > p.style2 {
    font-size: 2.6667vw;
    line-height: 3.8333vw;
    margin-bottom: 3.245vw;
  }

  .close-register-wrapper > main > .step1 > .pinpad {
    margin-bottom: 4.3333vw;
  }

  .close-register-wrapper > main > .step1 > .pinpad {
    width: 46.3333vw;
    margin-bottom: 1.6667vw;
  }

  .close-register-wrapper > main > .step1 > .pinpad > p.error-message {
    padding: 1.8333vw 4.6667vw;
    border-radius: 1.3333vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin: -1.6667vw 0 3.3333vw 0;
  }

  .close-register-wrapper > main > .step1 > .pinpad > .pin-entries {
    margin-bottom: 2.8333vw;
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-entries
    > .pin-entry {
    width: 2.6667vw;
    height: 2.6667vw;
    margin-left: 1.6667vw;
  }

  .close-register-wrapper > main > .step1 > .pinpad > .pin-button-row {
    gap: 2.6667vw;
    margin-bottom: 2.6667vw;
  }

  .close-register-wrapper > main > .step1 > .pinpad > .pin-button-row > button {
    width: 13.6667vw;
    height: 13.6667vw;
    border-radius: 1.3333vw;
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-button-row
    > button.backspace
    > img {
    width: 8.3333vw;
    height: 6.6667vw;
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-button-row
    > button
    > p {
    font-size: 6vw;
    line-height: 6vw;
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-button-row
    > button
    > .text-row {
    font-size: 1.6667vw;
    line-height: 2.5vw;
    letter-spacing: 0vw;
  }

  .close-register-wrapper > main > .step2 > p.style1 {
    font-size: 4.1667vw;
    line-height: 6vw;
    margin: 2.6667vw 0 3vw 0;
  }

  .close-register-wrapper > main > .step2 > p.style2 {
    font-size: 2.6667vw;
    line-height: 4vw;
    margin-bottom: 3.3333vw;
  }

  .close-register-wrapper > main > .step2 > button#openCashDrawer {
    width: 54.5vw;
    height: 11.6667vw;
    border: 0.3333vw solid #fff;
    border-radius: 1.3333vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .close-register-wrapper > main > .step2 > .input-column {
    padding: 4.8333vw 0;
  }

  .close-register-wrapper > main > .step2 > .input-column > .input-row {
    width: 54.5vw;
    margin-top: 3.3333vw;
  }

  .close-register-wrapper > main > .step2 > .input-column > .input-row > label {
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .close-register-wrapper > main > .step2 > .input-column > .input-row > input {
    width: 30.6317vw;
    height: 8.3333vw;
    border-radius: 1.3333vw;
    padding: 3vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .close-register-wrapper > main > .step2 > button#saveCount {
    width: 54.5vw;
    height: 8.3333vw;
    border-radius: 1.3333vw;
    font-size: 3vw;
    line-height: 4vw;
    margin-bottom: 4.3333vw;
    margin-top: 2.5vw;
  }

  .close-register-wrapper > main > .step3 > p.style1 {
    font-size: 4.1667vw;
    line-height: 6vw;
    margin: 2.6667vw 0 3vw 0;
  }

  .close-register-wrapper > main > .step3 > p.style2 {
    font-size: 2.6667vw;
    line-height: 4vw;
    margin-bottom: 5vw;
  }

  .close-register-wrapper > main > .step3 > .closing-totals {
    width: 57.8333vw;
    border-radius: 1.3333vw;
    padding: 4.8333vw 0;
    margin-bottom: 5vw;
  }

  .close-register-wrapper > main > .step3 > .closing-totals p.style1 {
    font-size: 3vw;
    line-height: 4.5vw;
    margin-bottom: 3.78vw;
  }

  .close-register-wrapper > main > .step3 > .closing-totals p.style2 {
    font-size: 2.6667vw;
    line-height: 3.5vw;
    margin-bottom: 1.6667vw;
  }

  .close-register-wrapper > main > .step3 > .closing-totals p.style3 {
    font-size: 2.6667vw;
    line-height: 3.5vw;
  }

  .close-register-wrapper > main > .step3 > .closing-totals .divider {
    height: 0.1667vw;
    margin-bottom: 1.6667vw;
  }

  .close-register-wrapper > main > .step3 > .closing-totals > .col {
    width: 51.795vw;
    margin-top: 5.5vw;
  }

  .close-register-wrapper > main > .step3 > .closing-totals > .col > .row {
    margin-top: 2.1667vw;
  }

  .close-register-wrapper > main > .step3 > label {
    width: 57.1667vw;
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .close-register-wrapper > main > .step3 > textarea {
    width: 57.1667vw;
    height: 23.8333vw;
    padding: 2vw;
    border-radius: 1.3333vw;
    margin-bottom: 4.1667vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .close-register-wrapper > main > .step3 > button {
    width: 57.1667vw;
    height: 9vw;
    border-radius: 1.3333vw;
    font-size: 3vw;
    line-height: 4vw;
    margin-bottom: 2.6667vw;
  }

  .close-register-wrapper > main > .step3 > button#printReport {
    border: 0.3333vw solid #fff;
  }

  .create-userid-wrapper > .header {
    padding: 1.6667vw;
  }

  .create-userid-wrapper > .header > #goBackPinSetup,
  .create-userid-wrapper > .header > #cancelPinSetup {
    padding: 1.6667vw;
    font-size: 3vw;
    line-height: 4.5vw;
  }

  .create-userid-wrapper > .header > #goBackPinSetup > img,
  .create-userid-wrapper > .header > #cancelPinSetup > img {
    width: 1.5vw;
    height: 2.6667vw;
    margin-right: 1.3333vw;
  }

  .create-userid-wrapper > .header > #logoutPinSetup {
    font-size: 3vw;
    line-height: 4.5vw;
  }

  .create-userid-wrapper > .header > #logoutPinSetup > img {
    width: 3.3333vw;
    height: 3.3333vw;
    margin-right: 1.1667vw;
  }

  .create-userid-wrapper > .body > .col.static {
    width: 57.1667vw;
    padding-bottom: 5vw;
    border-bottom: 0.1667vw solid #fff;
  }

  .create-userid-wrapper > .body > .col.static > img {
    width: 17.81vw;
    height: 16.2867vw;
    margin-right: 4.6667vw;
  }

  .create-userid-wrapper > .body > .col.static > .text-group > p.style1 {
    font-size: 5vw;
    line-height: 7.1667vw;
  }

  .create-userid-wrapper > .body > .col.static > .text-group > p.style2 {
    font-size: 2.6667vw;
    line-height: 2.6667vw;
    margin-bottom: 0.3333vw;
  }

  .create-userid-wrapper > .body > .col.static > .text-group > p.style3 {
    font-size: 2.6667vw;
    line-height: 2.6667vw;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-1 {
    margin-top: 5.5vw;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-1 > p {
    font-size: 3.3333vw;
    line-height: 5.8333vw;
    margin-bottom: 3.3333vw;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-1 > button {
    width: 57.1667vw;
    height: 8.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 {
    margin-top: 3.3333vw;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 > p {
    font-size: 3.3333vw;
    line-height: 4.8333vw;
    margin-bottom: 3.8333vw;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 > .id-row {
    width: 46vw;
    margin-bottom: 4.2217vw;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .id-row
    > .id-entry {
    height: 7.3333vw;
    margin-left: 1.6667vw;
    box-shadow: inset 0vw 0.6667vw 1vw rgba(0, 0, 0, 0.25);
    border-radius: 1vw;
    font-size: 4.1667vw;
    line-height: 6vw;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 > .pin-key-row {
    width: 46vw;
    margin-bottom: 2.6667vw;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .pin-key-row
    > button {
    margin-left: 2.6667vw;
    border-radius: 1.3333vw;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .pin-key-row
    > button.backspace
    > img {
    width: 8.3967vw;
    height: 6.6817vw;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 > .pin-key-row.last {
    margin-bottom: 2.6667vw;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .pin-key-row
    > button
    > p {
    font-size: 6vw;
    line-height: 6vw;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .pin-key-row
    > button
    > .letter-row
    > p {
    font-size: 1.6667vw;
    line-height: 2.5vw;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 > button {
    width: 57.1667vw;
    height: 8.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .navbar {
    padding: 1.6667vw 2.8333vw 31.5vw 2.8333vw;
    border-top: 0.1667vw solid var(--oliver-border);
  }

  .navbar > button {
    height: 7.8333vw;
    border-radius: 1.3333vw;
    margin-bottom: 0.7483vw;
    padding: 0 1.4417vw;
  }

  .navbar > button > .img-container {
    width: 5vw;
    height: 5vw;
    margin-right: 2.3333vw;
  }

  .navbar > button > p {
    font-size: 2.6667vw;
    line-height: 3.6667vw;
  }

  .app-launcher-wrapper > .app-launcher > .header {
    height: 12vw;
    border-bottom: 0.1667vw solid var(--oliver-border);
  }

  .app-launcher-wrapper > .app-launcher > .header > button {
    width: 6.6667vw;
    height: 6.6667vw;
    margin: 0 1.3333vw;
  }

  .app-launcher-wrapper > .app-launcher > .header > button > img {
    width: 1.7733vw;
    height: 3vw;
  }

  .app-launcher-wrapper > .app-launcher > .header > p {
    font-size: 3.3333vw;
    line-height: 4.6667vw;
  }

  .app-launcher-wrapper > .app-launcher > .body {
    padding: 3.3333vw;
  }

  .app-launcher-wrapper > .app-launcher > .body > img {
    width: 42.8333vw;
    height: 29.3333vw;
  }

  .app-launcher-wrapper > .app-launcher > .body > button {
    margin-bottom: 3.3333vw;
  }

  .app-launcher-wrapper > .app-launcher > .body > button > .img-container {
    width: 9.1667vw;
    height: 9.1667vw;
    border-radius: 1.6667vw;
    margin-right: 4.5vw;
  }

  .app-launcher-wrapper > .app-launcher > .body > button > p {
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .link-launcher-wrapper > .link-launcher > .header {
    height: 12vw;
    border-bottom: 0.1667vw solid var(--oliver-border);
  }

  .link-launcher-wrapper > .link-launcher > .header > button {
    width: 6.6667vw;
    height: 6.6667vw;
    margin: 0 1.3333vw;
  }

  .link-launcher-wrapper > .link-launcher > .header > button > img {
    width: 1.7733vw;
    height: 3vw;
  }

  .link-launcher-wrapper > .link-launcher > .header > p {
    font-size: 3.3333vw;
    line-height: 4.6667vw;
  }

  .link-launcher-wrapper > .link-launcher > .body {
    padding: 3.3333vw;
  }

  .link-launcher-wrapper > .link-launcher > .body > img {
    width: 42.8333vw;
    height: 29.3333vw;
  }

  .link-launcher-wrapper > .link-launcher > .body > button {
    margin-bottom: 3.3333vw;
  }

  .link-launcher-wrapper > .link-launcher > .body > button > .img-container {
    width: 9.1667vw;
    height: 9.1667vw;
    border-radius: 1.6667vw;
    margin-right: 4.5vw;
  }

  .link-launcher-wrapper > .link-launcher > .body > button > .col {
    width: calc(100% - 20.3333vw);
  }

  .link-launcher-wrapper > .link-launcher > .body > button > .col > p.style1 {
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .link-launcher-wrapper > .link-launcher > .body > button > .col > p.style2 {
    font-size: 2vw;
    line-height: 2.8333vw;
  }

  .user-info-wrapper > .user-info {
    padding: 3.3333vw;
  }

  .user-info-wrapper > .user-info > .header {
    margin-bottom: 3.6667vw;
  }

  .user-info-wrapper > .user-info > .header > .avatar {
    width: 9.1667vw;
    height: 9.1667vw;
    margin-right: 3.3333vw;
    font-size: 3.3333vw;
  }

  .user-info-wrapper > .user-info > .header > .col > p.style1 {
    font-size: 3vw;
    line-height: 4.1667vw;
  }

  .user-info-wrapper > .user-info > .header > .col > p.style2 {
    font-size: 2.1667vw;
    line-height: 3vw;
  }

  .user-info-wrapper > .user-info > .body > .language-select {
    margin-bottom: 3.3333vw;
  }

  .user-info-wrapper > .user-info > .body > .language-select > p {
    font-size: 2vw;
    line-height: 2.8333vw;
    margin-right: 1.6667vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper {
    height: 5.8333vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > img {
    top: calc(50% - 1.1667vw);
    right: 2vw;
    width: 2.3333vw;
    height: 2.3333vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper.open
    > input {
    border-radius: 1.3333vw 1.3333vw 0 0;
    border-width: 0.3333vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > input {
    border: 0.1667vw solid var(--oliver-base-grey);
    border-radius: 1.3333vw;
    padding: 0 2vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > .option-container {
    border-radius: 0 0 1.3333vw 1.3333vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > .option-container
    > .language-option {
    height: 5.8333vw;
    border: 0.1667vw solid var(--oliver-border);
    padding: 0 2vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > .option-container
    > .language-option
    > p {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    max-width: calc(100% - 2.6667vw);
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > .option-container
    > .language-option:last-child {
    border-radius: 0 0 1.3333vw 1.3333vw;
  }

  .user-info-wrapper > .user-info > .body > button {
    font-size: 2.6667vw;
    line-height: 3.6667vw;
  }

  .user-info-wrapper > .user-info > .body > button > .img-container {
    width: 9.1667vw;
    height: 5vw;
    margin-right: 2.5vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > button#knowledgeBaseButton
    > .img-container
    > img {
    width: 4vw;
    height: 4vw;
  }

  .user-info-wrapper > .user-info > .body > button#switchUserButton {
    margin-top: 1.6667vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > button#switchUserButton
    > .img-container
    > img {
    width: 2.8267vw;
    height: 2.9167vw;
  }

  .user-info-wrapper > .user-info > .body > button#endSessionButton {
    margin-top: 1.6667vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > button#endSessionButton
    > .img-container
    > img {
    width: 4.01vw;
    height: 4vw;
  }

  .page-options-wrapper {
    top: 11.6667vw;
    height: calc(100% - 11.6667vw);
  }

  .page-options-wrapper > .page-options {
    padding: 0 4.6667vw 2.75vw 4.6667vw;
    border-radius: 0 0 2vw 2vw;
  }

  .page-options-wrapper > .page-options > p {
    font-size: 2.6667vw;
    line-height: 3.6667vw;
    margin-bottom: 2vw;
  }

  .page-options-wrapper > .page-options > button {
    height: 7.8333vw;
    border-radius: 1.3333vw;
  }

  .page-options-wrapper > .page-options > button > .img-container {
    width: 5vw;
    height: 5vw;
    margin-right: 1.5vw;
  }

  .page-options-wrapper > .page-options > button > .img-container > .badge {
    border: 0.3333vw solid #fff;
  }

  .page-options-wrapper > .page-options > button > p {
    font-size: 2.6667vw;
    line-height: 3.6667vw;
  }

  .notifications-wrapper > .notifications > .noti-header {
    height: 10vw;
    padding: 0 2.6667vw;
    border-bottom: 0.1667vw solid var(--oliver-border);
  }

  .notifications-wrapper > .notifications > .noti-header > p {
    font-size: 3vw;
    line-height: 4vw;
  }

  .notifications-wrapper > .notifications > .noti-header > button {
    width: 5.6667vw;
    height: 5.6667vw;
    border-radius: 1.3333vw;
    margin-left: 1.8333vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-header
    > button#notiSettingButton
    > img {
    width: 4vw;
    height: 4vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-header
    > button#mobileNotiExit
    > img {
    width: 2vw;
    height: 2vw;
  }

  .notifications-wrapper > .notifications > .noti-body > .date {
    padding: 1.5vw 2.6667vw;
  }

  .notifications-wrapper > .notifications > .noti-body > .date > p {
    font-size: 2.1667vw;
    line-height: 2.8333vw;
  }

  .notifications-wrapper > .notifications > .noti-body > .notification-card {
    height: 12.6667vw;
    padding: 0 2.5vw;
    border-top: 0.1667vw solid var(--oliver-border);
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .icon-wrapper {
    width: 6.6667vw;
    height: 6.6667vw;
    border-radius: 1.3333vw;
    margin-right: 2vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .icon-wrapper
    > img {
    width: 5.3333vw;
    height: 5.3333vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .icon-wrapper.red
    > img {
    width: 4.1667vw;
    height: 4.1667vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .icon-wrapper.grey
    > img {
    width: 4vw;
    height: 4vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .col {
    padding-right: 2vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .col
    > p.style1 {
    font-size: 2.1667vw;
    line-height: 2.8333vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .col
    > p.style2 {
    font-size: 2vw;
    line-height: 2.6667vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > p {
    font-size: 2.1667vw;
    line-height: 2.8333vw;
  }

  .notifications-wrapper > .notifications.settings > .noti-settings-background {
    top: 10vw;
  }

  .notifications-wrapper > .notifications > .noti-settings-wrapper {
    top: 10vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings {
    border-radius: 0 0 2.5vw 2.5vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .header {
    padding: 2vw 2.6667vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .header
    > p {
    font-size: 2.6667vw;
    line-height: 3.5vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list {
    padding: 1.6667vw 2.6667vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row {
    padding: 0.8333vw 0;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > img {
    width: 4vw;
    height: 4vw;
    margin-right: 1.6667vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > p {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    width: 33.3333vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > input {
    left: -1666.6667vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > .toggle {
    width: 10.6667vw;
    height: 5.3333vw;
    border-radius: 5.3333vw;
    padding: 0.3333vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > .toggle::before {
    width: 4.6667vw;
    height: 4.6667vw;
    border: 0.1667vw solid rgba(0, 0, 0, 0.04);
    box-shadow: 0vw 0.5vw 1.3333vw rgba(0, 0, 0, 0.15),
      0vw 0.5vw 0.1667vw rgba(0, 0, 0, 0.06);
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > .toggle::after {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    right: 1.3333vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > input:checked
    + .toggle::before {
    right: 0.3333vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > input:checked
    + .toggle::after {
    left: 1.3333vw;
  }

  .homepage-wrapper > .header {
    padding-top: 1.705vw;
  }

  .homepage-wrapper > .header > .row {
    height: 10.0767vw;
  }

  .homepage-wrapper > .header > .row > button#mobileNavToggle {
    width: 6.6667vw;
    height: 6.6667vw;
    margin: 0 1.3333vw;
  }

  .homepage-wrapper > .header > .row > button#mobileNavToggle > img {
    width: 3.3333vw;
    height: 2.2217vw;
  }

  .homepage-wrapper > .header > .row > button#mobileNavToggle.opened > img {
    width: 6.5vw;
    height: 6.5vw;
  }

  .homepage-wrapper > .header > .row > img {
    width: 10.6667vw;
    height: 3vw;
  }

  .homepage-wrapper > .header > .row > button#searchButton {
    width: calc(100% - 5.3333vw);
    height: 7.6667vw;
    left: 2.6667vw;
    bottom: 2.5vw;
    border-radius: 1.3333vw;
    font-size: 2.6667vw;
    line-height: 3.6667vw;
  }

  .homepage-wrapper > .header > .row > button#searchButton > img {
    width: 3.3333vw;
    height: 3.3333vw;
    margin-right: 1.3333vw;
  }

  .homepage-wrapper > .header > .row > button#mobileOptionsButton,
  .homepage-wrapper > .header > .row > button#mobileAppsButton {
    width: 5.6667vw;
    height: 5.6667vw;
    border-radius: 1.3333vw;
  }

  .homepage-wrapper > .header > .row > button#mobileOptionsButton {
    margin-right: 1.6667vw;
  }

  .homepage-wrapper > .header > .row > button#mobileOptionsButton > img {
    width: 4.965vw;
    height: 4.965vw;
  }

  .homepage-wrapper > .header > .row > button#mobileAppsButton {
    margin-right: 2.6667vw;
  }

  .homepage-wrapper > .header > .row > button#mobileAppsButton > img {
    width: 3.1033vw;
    height: 3.0767vw;
  }

  .homepage-wrapper > .products > .change-row-count {
    margin-bottom: 2.6667vw;
  }

  .homepage-wrapper > .products > .change-row-count > p {
    font-size: 3vw;
    line-height: 4vw;
  }

  .homepage-wrapper > .products > .change-row-count > button:not(#exitEditing) {
    width: 6.6667vw;
    height: 6.6667vw;
    border: 0.1667vw solid var(--oliver-blue);
    border-radius: 1.3333vw;
    margin: 0 2vw;
  }

  .homepage-wrapper > .products > .change-row-count > button > img {
    width: 2.1667vw;
    height: 4.6667vw;
  }

  .homepage-wrapper > .products > .change-row-count > button#exitEditing {
    height: 6.6667vw;
    padding: 0 2vw;
    border: 0.1667vw solid var(--oliver-blue);
    border-radius: 1.3333vw;
    font-size: 2.6667vw;
    line-height: 3vw;
  }

  .homepage-wrapper > .products > .category-header {
    margin-bottom: 2vw;
  }

  .homepage-wrapper > .products > .category-header > p {
    font-size: 2.3333vw;
    line-height: 3.5vw;
  }

  .homepage-wrapper > .products > .category-header > button {
    width: 20vw;
    height: 5vw;
    border-radius: 1vw;
    font-size: 2vw;
    line-height: 2.6667vw;
  }

  .homepage-wrapper > .products > .category-header > button > img {
    width: 3.3333vw;
    height: 3.3333vw;
    margin-right: 1vw;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > .no-product-container
    > img {
    width: 7.5vw;
    height: 7.5vw;
    margin-bottom: 2.1667vw;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > .no-product-container
    > p {
    font-size: 3vw;
    line-height: 4.5vw;
    margin-bottom: 6.3333vw;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > .no-product-container
    > button {
    width: 23.3333vw;
    height: 6.1667vw;
    border: 0.1667vw solid var(--oliver-blue);
    border-radius: 1vw;
    font-size: 2.3333vw;
    line-height: 3.5vw;
  }

  .homepage-wrapper > .products > .products-container > button {
    border-radius: 1.3333vw;
    box-shadow: 0vw 1vw 1.6667vw rgba(94, 94, 94, 0.1);
  }

  .homepage-wrapper > .products > .products-container > button.product > .body {
    border-radius: 1.3333vw 1.3333vw 0 0;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button.product
    > .footer {
    border-radius: 0 0 1.3333vw 1.3333vw;
    border-top: 0.1667vw solid var(--oliver-border);
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button.product
    > .footer
    > p {
    font-size: 2.6667vw;
    line-height: 3.3333vw;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button.category
    > p.sub {
    font-size: 2vw;
    line-height: 2.5vw;
    margin-bottom: 3.3333vw;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button.category
    > p.main {
    font-size: 2.6667vw;
    line-height: 3.3333vw;
  }

  .homepage-wrapper > .products > .products-container > button.add-tile {
    border: 0.3333vw dashed var(--oliver-new-grey);
    font-size: 2.6667vw;
    line-height: 3vw;
  }

  .homepage-wrapper > .products > .products-container > button.add-tile > img {
    width: 3.3333vw;
    height: 3.3333vw;
    margin-right: 1.1667vw;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button
    > .not-supported {
    border-radius: 1vw;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button
    > .not-supported
    > img {
    width: 4.6667vw;
    top: 2vw;
    left: 2vw;
  }

  .mobile-homepage-footer {
    height: 14.8333vw;
  }

  .mobile-homepage-footer > button {
    height: 9vw;
    border-radius: 1.3333vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .cart > .mobile-header {
    height: 10.3333vw;
    padding: 0 2.6667vw 0 4vw;
  }

  .cart > .mobile-header > p {
    font-size: 3.3333vw;
    line-height: 4.6667vw;
  }

  .cart > .mobile-header > button {
    width: 5.3333vw;
    height: 5.3333vw;
    border-radius: 1vw;
  }

  .cart > .mobile-header > button > img {
    width: 2vw;
    height: 2vw;
  }

  .cart > .body {
    padding: 0 2.6667vw 2vw 2.6667vw;
  }

  .cart > .body > .cart-item {
    padding: 2vw 0;
    border-top: 0.1667vw solid var(--oliver-border);
  }

  .cart > .body > .cart-item > .main-row > .quantity {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-right: 2.5vw;
    margin: 0 2.5vw 0 1.3333vw;
  }

  .cart > .body > .cart-item > .main-row > .content-style {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    padding-right: 1.6667vw;
  }

  .cart > .body > .cart-item > .main-row > .price {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-right: 2.105vw;
  }

  .cart > .body > .cart-item > .main-row > button.remove-cart-item {
    width: 3vw;
    height: 3vw;
    margin-right: 1.105vw;
  }

  .cart > .body > .cart-item > .main-row > .tag {
    padding: 0.9167vw 1.8333vw;
    border-radius: 1vw;
    font-size: 1.8333vw;
    line-height: 2.5vw;
    margin-right: 2.3333vw;
  }

  .cart > .body > .cart-item > .secondary-col {
    padding: 0 5.6667vw 0 5.3333vw;
    margin-top: 1.6667vw;
  }

  .cart > .body > .cart-item > .secondary-col > p {
    font-size: 2vw;
    line-height: 2.8333vw;
  }

  .cart > .body > .cart-item > .secondary-col > .row > p {
    font-size: 2vw;
    line-height: 2.8333vw;
  }

  .cart > .footer {
    border-top: 0.1667vw solid var(--oliver-border);
  }

  .cart > .footer > .totals {
    padding: 2.5vw;
  }

  .cart > .footer > .totals > .row {
    margin-top: 1.1667vw;
  }

  .cart > .footer > .totals > .row > p {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .cart > .footer > .totals > .row > p:first-of-type {
    margin-right: 1.1667vw;
  }

  .cart > .footer > .totals > .row > button#editCartDiscount {
    font-size: 2vw;
    line-height: 2.8333vw;
  }

  .cart > .footer > .totals > .row > button#taxesButton {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-right: 0.6667vw;
  }

  .cart > .footer > .checkout-container {
    border-top: 0.1667vw solid var(--oliver-border);
    height: 14vw;
  }

  .cart > .footer > .checkout-container > button {
    padding: 2.5vw;
  }

  .cart > .footer > .checkout-container > button > p {
    border-radius: 1.3333vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .homepage-wrapper > .header {
    height: 22.5vw;
    border-bottom: 0.1667vw solid var(--oliver-border);
  }

  .homepage-wrapper > .navbar {
    top: 12vw;
    height: calc(100% - 12vw);
  }

  .product-wrapper {
    height: calc(100% - 24vw);
  }

  .product-wrapper > .header > .mobile-buttons {
    height: 46.8333vw;
    padding: 1.6667vw 2.6667vw;
  }

  .product-wrapper > .header > .mobile-buttons > button {
    width: 5.6667vw;
    height: 5.6667vw;
    border-radius: 1.3333vw;
  }

  .product-wrapper
    > .header
    > .mobile-buttons
    > button#mobileExitProductButton
    > img {
    width: 2vw;
    height: 2vw;
  }

  .product-wrapper > .header > .mobile-buttons > button#mobileAppsButton > img {
    width: 3.1033vw;
    height: 3.1033vw;
  }

  .product-wrapper > .header > .main {
    padding: 4.3333vw 2.6667vw;
  }

  .product-wrapper > .header > .main > p.route {
    font-size: 2.1667vw;
    line-height: 2.1667vw;
    margin-bottom: 2vw;
  }

  .product-wrapper > .header > .main > p.prod-name {
    font-size: 3.3333vw;
    line-height: 3.3333vw;
  }

  .product-wrapper > .mod-product {
    padding: 0 0 1.1667vw 2.6667vw;
  }

  .product-wrapper.no-edit > .mod-product {
    padding-top: 25.3333vw;
  }

  .product-wrapper.no-edit > .mod-product > .no-edit-item {
    width: calc(100% + 2.6667vw);
    left: -2.6667vw;
  }

  .product-wrapper.no-edit > .mod-product > .no-edit-item > .inner-container {
    width: min(calc(100% - 5.3333vw), 57.1667vw);
    height: 21.3333vw;
    border-radius: 1.3333vw;
    margin-top: 1.3333vw;
  }

  .product-wrapper.no-edit
    > .mod-product
    > .no-edit-item
    > .inner-container
    > p.style1 {
    font-size: 2.3333vw;
    line-height: 3vw;
    margin-bottom: 3.3333vw;
  }

  .product-wrapper.no-edit
    > .mod-product
    > .no-edit-item
    > .inner-container
    > p.style2 {
    font-size: 2vw;
    line-height: 2.6667vw;
  }

  .product-wrapper > .mod-product > .row > p {
    font-size: 2.1667vw;
    line-height: 2.8333vw;
    margin-bottom: 2vw;
  }

  .product-wrapper > .mod-product > p {
    font-size: 2.1667vw;
    line-height: 2.1667vw;
    margin-bottom: 1.3333vw;
  }

  .product-wrapper > .mod-product > p.error::after {
    font-size: 2.1667vw;
    line-height: 2.1667vw;
    margin-left: 1.6667vw;
  }

  .product-wrapper > .mod-product > .radio-group {
    width: calc(100% + 2.6667vw);
    margin-left: -2.6667vw;
    margin-bottom: 4.6667vw;
  }

  .product-wrapper > .mod-product > .radio-group > label {
    margin-right: 2.6667vw;
  }

  .product-wrapper > .mod-product > .radio-group > label:nth-child(1) {
    margin-left: 2.6667vw;
  }

  .product-wrapper > .mod-product > .radio-group > label > input {
    left: -1666.6667vw;
  }

  .product-wrapper > .mod-product > .radio-group > label > .custom-radio {
    padding: 2.3333vw;
    border: 0.25vw solid var(--oliver-border);
    border-radius: 1.6667vw;
  }

  .product-wrapper > .mod-product > .radio-group > label > .custom-radio > p {
    font-size: 2.1667vw;
    line-height: 2.1667vw;
    max-width: 50vw;
  }

  .product-wrapper
    > .mod-product
    > .radio-group
    > label
    > input:checked
    ~ .custom-radio {
    border: 0.3333vw solid var(--oliver-blue);
  }

  .product-wrapper > .mod-product > input {
    width: min(50vw, 100%);
    padding: 0 2.6667vw;
    height: 6.6667vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1.3333vw;
    font-size: 2.1667vw;
    line-height: 2.1667vw;
    margin-bottom: 4.6667vw;
  }

  .product-wrapper > .mod-product > input:focus {
    border: 0.3333vw solid var(--oliver-blue);
  }

  .product-wrapper > .mod-product > .increment-input {
    gap: 1.8333vw;
    margin-bottom: 4.6667vw;
  }

  .product-wrapper > .mod-product > .increment-input > button {
    width: 6.6667vw;
    height: 6.6667vw;
    border-radius: 1.3333vw;
  }

  .product-wrapper > .mod-product > .increment-input > button > img {
    width: 2.6667vw;
    height: 5vw;
  }

  .product-wrapper > .mod-product > .increment-input > input {
    width: 13.3333vw !important;
    height: 6.6667vw;
    font-size: 2.3333vw;
    line-height: 3vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1.3333vw;
  }

  .product-wrapper > .detailed-product > .row {
    padding: 5vw 0;
    margin: 0 2.6667vw;
    border-top: 0.1667vw solid var(--oliver-border);
    border-bottom: 0.1667vw solid var(--oliver-border);
  }

  .product-wrapper > .detailed-product > .row > .col > p.mobile-only {
    font-size: 2.3333vw;
    line-height: 2.3333vw;
    margin-bottom: 1.3333vw;
  }

  .product-wrapper > .detailed-product > .row > .col > .group {
    margin-bottom: 3.3333vw;
  }

  .product-wrapper > .detailed-product > .row > .col > .group > .text-row > p {
    font-size: 2.1667vw;
    line-height: 2.1667vw;
  }

  .product-wrapper
    > .detailed-product
    > .row
    > .col
    > .group
    > .text-row
    > p:first-of-type {
    margin-right: 0.6667vw;
  }

  .product-wrapper > .detailed-product > .row > .col > .group > button {
    margin-top: -0.6667vw;
    padding: 0.6667vw 0.3333vw;
    font-size: 2.1667vw;
    line-height: 2.1667vw;
  }

  .product-wrapper > .detailed-product > .row > .col > button {
    width: 25.1667vw;
    height: 5.8333vw;
    border: 0.1667vw solid var(--oliver-blue);
    border-radius: 1.3333vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .product-wrapper > .detailed-product > .row > .col > button > img {
    width: 5vw;
    height: 5vw;
    margin-right: 0.8333vw;
  }

  .product-wrapper > .detailed-product > .col {
    margin: 0 2.6667vw;
    padding: 5vw 0;
    border-bottom: 0.1667vw solid var(--oliver-border);
  }

  .product-wrapper > .detailed-product > .col > p.title {
    font-size: 2.3333vw;
    line-height: 2.3333vw;
    margin-bottom: 1.1667vw;
  }

  .product-wrapper > .detailed-product > .col > p.para {
    font-size: 2.1667vw;
    line-height: 2.6667vw;
    margin-bottom: 3.5vw;
  }

  .product-wrapper > .product-footer {
    height: 24vw;
    padding: 2.6667vw;
    border-top: 0.1667vw solid var(--oliver-border);
  }

  .product-wrapper > .product-footer > .row:nth-child(1) {
    width: calc(100% - 5.3333vw);
  }

  .product-wrapper > .product-footer > .row > button#addProductNote {
    width: calc(100% - 26.6667vw);
    height: 6.6667vw;
    border: 0.1667vw solid var(--oliver-blue);
    border-radius: 1.3333vw;
    font-size: 2vw;
    line-height: 2vw;
  }

  .product-wrapper > .product-footer > .row > button#addProductNote > img {
    width: 2.5vw;
    height: 2.5vw;
    margin-right: 1.6667vw;
  }

  .product-wrapper > .product-footer > .row > button#addProductToCart {
    bottom: 2.6667vw;
    width: calc(100% - 5.3333vw);
    height: 9.1667vw;
    border-radius: 1.3333vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .product-wrapper > .product-footer > .row > .increment-input {
    right: 2.6667vw;
    width: 23.3333vw;
  }

  .product-wrapper > .product-footer > .row > .increment-input > button {
    width: 6.6667vw;
    height: 6.6667vw;
    border-radius: 1.3333vw;
  }

  .product-wrapper
    > .product-footer
    > .row
    > .increment-input
    > button:first-of-type
    > img {
    width: 2.3333vw;
    height: 5vw;
  }

  .product-wrapper
    > .product-footer
    > .row
    > .increment-input
    > button:last-of-type
    > img {
    width: 2.6667vw;
    height: 5vw;
  }

  .product-wrapper > .product-footer > .row > .increment-input > input {
    font-size: 3vw;
    line-height: 4vw;
    border-radius: 1.3333vw;
  }

  .product-wrapper > .product-footer > .row > button#addProductToCart > img {
    width: 4.8333vw;
    height: 4.8333vw;
    margin-right: 1.3333vw;
  }

  .product-wrapper .product-image-container {
    height: 46.8333vw;
  }

  .product-wrapper > .recommended-upsells {
    padding: 3.6667vw 0;
  }

  .product-wrapper > .recommended-upsells > p {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin: 0 0 1.6667vw 2.6667vw;
  }

  .product-wrapper > .recommended-upsells > .button-row > button {
    width: 21.5vw;
    height: 21.5vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1.3333vw;
    margin-right: 3.6667vw;
  }

  .product-wrapper > .recommended-upsells > .button-row > button:first-of-type {
    margin-left: 2.6667vw;
  }

  .product-wrapper > .recommended-upsells > .button-row > button:last-of-type {
    margin-right: 2.6667vw;
  }

  .product-wrapper > .recommended-upsells > .button-row > button > .prod-name {
    height: 4.1667vw;
    border-top: 0.1667vw solid var(--oliver-border);
  }

  .product-wrapper
    > .recommended-upsells
    > .button-row
    > button
    > .prod-name
    > p {
    font-size: 2.1667vw;
    line-height: 3.1667vw;
  }

  .checkout-wrapper > .header {
    height: 10vw;
    border-bottom: 0.1667vw solid var(--oliver-border);
    padding: 0 2.1667vw;
  }

  .checkout-wrapper > .header > button#exitCheckoutButton {
    width: 4.3333vw;
    height: 4.3333vw;
    margin-right: 2.8333vw;
  }

  .checkout-wrapper > .header > p {
    font-size: 3.3333vw;
    line-height: 4.6667vw;
  }

  .checkout-wrapper > .header > button#mobileOptionsButton {
    width: 5.6667vw;
    height: 5.6667vw;
    border-radius: 1.3333vw;
    margin-right: 1.6667vw;
  }

  .checkout-wrapper > .header > button#mobileOptionsButton > img {
    width: 4.965vw;
    height: 4.965vw;
  }

  .checkout-wrapper > .header > button#mobileAppsButton {
    width: 5.6667vw;
    height: 5.6667vw;
    border-radius: 1.3333vw;
  }

  .checkout-wrapper > .header > button#mobileAppsButton > img {
    width: 3.1033vw;
    height: 3.0767vw;
  }

  .checkout-wrapper > .page-options-wrapper {
    top: 9.8333vw;
  }

  .checkout-wrapper > .checkout-body {
    padding-top: 1.6667vw;
  }

  .checkout-wrapper > .checkout-body > .balance-container {
    width: 56.3333vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1.3333vw;
    padding: 3vw 3.3333vw;
    margin-bottom: 0.8333vw;
  }

  .checkout-wrapper > .checkout-body > .balance-container p.style1 {
    font-size: 2.6667vw;
    line-height: 3.6667vw;
  }

  .checkout-wrapper > .checkout-body > .balance-container p.style2 {
    font-size: 3.6667vw;
    line-height: 5.1667vw;
  }

  .checkout-wrapper > .checkout-body > .balance-container p.style3 {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .checkout-wrapper > .checkout-body > .balance-container p.style4 {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .checkout-wrapper > .checkout-body > .balance-container:hover {
    border-width: 0.3333vw;
  }

  .checkout-wrapper > .checkout-body button#bottomText {
    padding-top: 0.8333vw;
    margin-bottom: 4vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .checkout-wrapper > .checkout-body > p.style2 {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    width: 56.3333vw;
    margin-bottom: 1.5vw;
  }

  .checkout-wrapper > .checkout-body > .button-row {
    width: 56.3333vw;
    margin-bottom: 2.5vw;
  }

  .checkout-wrapper > .checkout-body > .button-row > button {
    height: 6.6667vw;
    margin-left: 2.1667vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1.3333vw;
    font-size: 2.3333vw;
    line-height: 3vw;
  }

  .checkout-wrapper
    > .checkout-body
    > .button-row
    > button:not(:disabled):hover {
    border-width: 0.3333vw;
  }

  .checkout-wrapper > .checkout-body > .button-row + p.style2 {
    padding-top: 1.1667vw;
  }

  .checkout-wrapper > .checkout-body > .payment-types {
    margin-top: 4.8333vw;
    padding: 3.3333vw 0;
  }

  .checkout-wrapper > .checkout-body > .payment-types > p {
    font-size: 2.6667vw;
    line-height: 3.3333vw;
    margin-bottom: 3.3333vw;
  }

  .checkout-wrapper > .checkout-body > .payment-types > .button-container {
    width: 56.3333vw;
  }

  .checkout-wrapper
    > .checkout-body
    > .payment-types
    > .button-container
    > button {
    width: 17.3333vw;
    height: 17.3333vw;
    margin: 0 2.1667vw 2.1667vw 0;
    border-radius: 1.3333vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .refund-wrapper > .header {
    height: 10vw;
    border-bottom: 0.1667vw solid var(--oliver-border);
    padding: 0 2.1667vw;
  }

  .refund-wrapper > .header > button#exitCheckoutButton {
    width: 4.3333vw;
    height: 4.3333vw;
    margin-right: 2.8333vw;
  }

  .refund-wrapper > .header > p {
    font-size: 3.3333vw;
    line-height: 4.6667vw;
  }

  .refund-wrapper > .header > button#mobileOptionsButton {
    width: 5.6667vw;
    height: 5.6667vw;
    border-radius: 1.3333vw;
    margin-right: 1.6667vw;
  }

  .refund-wrapper > .header > button#mobileOptionsButton > img {
    width: 4.965vw;
    height: 4.965vw;
  }

  .refund-wrapper > .header > button#mobileAppsButton {
    width: 5.6667vw;
    height: 5.6667vw;
    border-radius: 1.3333vw;
  }

  .refund-wrapper > .header > button#mobileAppsButton > img {
    width: 3.1033vw;
    height: 3.0767vw;
  }

  .refund-wrapper > .page-options-wrapper {
    top: 9.8333vw;
  }

  .refund-wrapper > .refund-body {
    padding-top: 1.6667vw;
  }

  .refund-wrapper > .refund-body > .balance-container {
    width: 56.3333vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1.3333vw;
    padding: 3vw 3.3333vw;
    margin-bottom: 5.3333vw;
  }

  .refund-wrapper > .refund-body > .balance-container p.style1 {
    font-size: 2.6667vw;
    line-height: 3.6667vw;
  }

  .refund-wrapper > .refund-body > .balance-container p.style2 {
    font-size: 3.6667vw;
    line-height: 5.1667vw;
  }

  .refund-wrapper > .refund-body > .balance-container p.style3 {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .refund-wrapper > .refund-body > .balance-container p.style4 {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .refund-wrapper > .refund-body > button#bottomText {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 4vw;
    padding-top: 0.8333vw;
  }

  .refund-wrapper > .refund-body > p.style2 {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    width: 56.3333vw;
    margin-bottom: 1.5vw;
  }

  .refund-wrapper > .refund-body > .button-row {
    width: 56.3333vw;
    margin-bottom: 2.5vw;
  }

  .refund-wrapper > .refund-body > .button-row > button {
    height: 6.6667vw;
    margin-left: 2.1667vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1.3333vw;
    font-size: 2.3333vw;
    line-height: 3vw;
  }

  .refund-wrapper > .refund-body > .button-row > button:not(:disabled):hover {
    border-width: 0.3333vw;
  }

  .refund-wrapper > .refund-body > .button-row + p.style2 {
    padding-top: 1.1667vw;
  }

  .refund-wrapper > .refund-body > .payment-types {
    margin-top: 4.8333vw;
    padding: 3.3333vw 0;
  }

  .refund-wrapper > .refund-body > .payment-types > p {
    font-size: 2.6667vw;
    line-height: 3.3333vw;
    margin-bottom: 3.3333vw;
  }

  .refund-wrapper > .refund-body > .payment-types > .button-container {
    width: 56.3333vw;
  }

  .refund-wrapper > .refund-body > .payment-types > .button-container > button {
    width: 17.3333vw;
    height: 17.3333vw;
    margin: 0 2.1667vw 2.1667vw 0;
    border-radius: 1.3333vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .sale-complete-wrapper {
    padding: 3.3333vw;
  }

  .sale-complete-wrapper > .main > img {
    width: 8.3333vw;
    height: 8.3333vw;
    margin-bottom: 3vw;
  }

  .sale-complete-wrapper > .main > p {
    font-size: 2.3333vw;
    line-height: 3vw;
    margin-bottom: 1.3333vw;
  }

  .sale-complete-wrapper > .main > #loadingDiv {
    margin-bottom: 5.3333vw;
  }

  .sale-complete-wrapper > .main > #loadingDiv > img {
    width: 3.8333vw;
    height: 3.8333vw;
    margin-right: 1.3333vw;
  }

  .sale-complete-wrapper > .main > #loadingDiv > p {
    font-size: 2.6667vw;
    line-height: 3.5vw;
  }

  .sale-complete-wrapper > .main > button#openUpdateTransactionStatus {
    padding: 1.3333vw;
    margin-bottom: 5.3333vw;
    font-size: 3.3333vw;
    line-height: 4.3333vw;
  }

  .sale-complete-wrapper > .main > button#openUpdateTransactionStatus > img {
    width: 3.3333vw;
    height: 3.3333vw;
    margin-left: 1.3333vw;
  }

  .sale-complete-wrapper > .main > .change-container {
    width: 48.3333vw;
    height: 17.3333vw;
    border-radius: 1.6667vw;
    margin-bottom: 5.6667vw;
  }

  .sale-complete-wrapper > .main > .change-container > p.style1 {
    font-size: 3.6667vw;
    line-height: 4.8333vw;
    margin-bottom: 1.1667vw;
  }

  .sale-complete-wrapper > .main > .change-container > p.style2 {
    font-size: 2.6667vw;
    line-height: 3.5vw;
  }

  .sale-complete-wrapper > .main > button {
    width: 33.3333vw;
    height: 8.3333vw;
    border: 0.1667vw solid #fff;
    border-radius: 1vw;
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .sale-complete-wrapper > .main > button#emailSubwindowButton {
    margin: 2.6667vw 0 5.6667vw 0;
  }

  .sale-complete-wrapper > .footer > .button-container > button#newSale {
    width: 48.6667vw;
    height: 9.1667vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
    margin-bottom: 6.6667vw;
  }

  .sale-complete-wrapper > .footer > .button-container > button#endSession {
    width: 24.5vw;
    height: 6.6667vw;
    border: 0.1667vw solid #fff;
    border-radius: 1vw;
    font-size: 2.6667vw;
    line-height: 3.5vw;
  }

  .sale-complete-wrapper
    > .footer
    > .button-container
    > button#endSession
    > img {
    width: 4.3333vw;
    height: 3.6667vw;
    margin-right: 0.8333vw;
  }

  .sale-complete-wrapper > .footer > .app-container {
    max-width: 46.6667vw;
    max-height: 23.3333vw;
    padding: 2.5vw 0 0 2.5vw;
    margin-bottom: 5.8333vw;
    border-radius: 2.6667vw;
  }

  .sale-complete-wrapper > .footer > .app-container > button {
    width: 9.1667vw;
    height: 9.1667vw;
    border-radius: 1.1667vw;
    margin: 0 2.5vw 2.5vw 0;
  }

  .refund-complete-wrapper {
    padding: 3.3333vw;
  }

  .refund-complete-wrapper > .main > img {
    width: 27.5vw;
    height: 15.5vw;
    margin: 6.1667vw 0 4.6667vw 0;
  }

  .refund-complete-wrapper > .main > button {
    width: 33.3333vw;
    height: 8.3333vw;
    border: 0.1667vw solid #fff;
    border-radius: 1vw;
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .refund-complete-wrapper > .main > button#emailSubwindowButton {
    margin: 2.6667vw 0 5.6667vw 0;
  }

  .refund-complete-wrapper > .footer > .button-container > button#newSale {
    width: 48.6667vw;
    height: 9.1667vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
    margin-bottom: 6.6667vw;
  }

  .refund-complete-wrapper > .footer > .button-container > button#endSession {
    width: 24.5vw;
    height: 6.6667vw;
    border: 0.1667vw solid #fff;
    border-radius: 1vw;
    margin-bottom: 5.6667vw;
    font-size: 2.6667vw;
    line-height: 3.5vw;
  }

  .refund-complete-wrapper
    > .footer
    > .button-container
    > button#endSession
    > img {
    width: 4.3333vw;
    height: 3.6667vw;
    margin-right: 0.8333vw;
  }

  .refund-complete-wrapper > .footer > .app-container {
    max-width: 46.6667vw;
    max-height: 23.3333vw;
    padding: 2.5vw 0 0 2.5vw;
    margin-bottom: 5.8333vw;
    border-radius: 2.6667vw;
  }

  .refund-complete-wrapper > .footer > .app-container > button {
    width: 9.1667vw;
    height: 9.1667vw;
    border-radius: 1.1667vw;
    margin: 0 2.5vw 2.5vw 0;
  }

  .customer-view-wrapper > .navbar {
    top: 10vw;
    height: calc(100% - 10vw);
    padding-bottom: 1.6667vw;
  }

  .customer-view-wrapper > .mobile-cv-header {
    height: 10vw;
    padding: 0 2.6667vw 0 1.3333vw;
  }

  .mobile-cv-header > #mobileNavToggle {
    width: 6.6667vw;
    height: 6.6667vw;
    margin-right: 1.3333vw;
  }

  .mobile-cv-header > #mobileNavToggle > img {
    width: 3.3333vw;
    height: 2.2217vw;
  }

  .mobile-cv-header > #mobileNavToggle.opened > img {
    width: 6.5vw;
    height: 6.5vw;
  }

  .mobile-cv-header > p {
    font-size: 3.3333vw;
    line-height: 4.6667vw;
  }

  .mobile-cv-header > #mobileCVSearchButton {
    width: 5.6667vw;
    height: 5.6667vw;
    border-radius: 1.3333vw;
    margin-right: 1.6667vw;
  }

  .mobile-cv-header > #mobileCVSearchButton > img {
    width: 3.5vw;
    height: 3.5vw;
  }

  .mobile-cv-header > #mobileAppsButton {
    width: 5.6667vw;
    height: 5.6667vw;
    border-radius: 1.3333vw;
  }

  .mobile-cv-header > #mobileAppsButton > img {
    width: 3.1033vw;
    height: 3.0767vw;
    transform: translateX(0.1667vw);
  }

  .cv-list {
    border-top: 0.1667vw solid var(--oliver-border);
  }

  .cv-list > .header {
    height: 9.1667vw;
    border-bottom: 0.1667vw solid var(--oliver-border);
    padding: 0 2.6667vw;
  }

  .cv-list > .header > p {
    font-size: 2.6667vw;
    line-height: 3.6667vw;
    margin-right: 1.6667vw;
  }

  .cv-list > .header > .sort-wrapper {
    width: 25.8333vw;
    height: 6vw;
  }

  .cv-list > .header > .sort-wrapper.open {
    box-shadow: 0vw 1vw 1.6667vw rgba(94, 94, 94, 0.1);
  }

  .cv-list > .header > .sort-wrapper > img {
    top: calc(50% - 1vw);
    right: 1.5vw;
    width: 2.1667vw;
    height: 2.1667vw;
  }

  .cv-list > .header > .sort-wrapper.open > img {
    transform: rotate(180deg) translateY(-0.3333vw);
  }

  .cv-list > .header > .sort-wrapper > input {
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1.3333vw;
    padding: 0 4.1667vw 0 1.6667vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .cv-list > .header > .sort-wrapper.open > input {
    border-radius: 1.3333vw 1.3333vw 0 0;
  }

  .cv-list > .header > .sort-wrapper > .option-container > .option {
    height: 6vw;
    border: 0.1667vw solid var(--oliver-border);
    padding: 0 1.6667vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .cv-list > .header > .sort-wrapper > .option-container > .option:last-child {
    border-radius: 0 0 1.3333vw 1.3333vw;
  }

  .cv-list > .body > .no-search-results-list > p.style1 {
    font-size: 2.3333vw;
    margin-bottom: 1.6667vw;
  }

  .cv-list > .body > .no-search-results-list > p.style2 {
    font-size: 2.1667vw;
    line-height: 3vw;
  }

  .cv-list > .body > .filter-name {
    height: 5vw;
    padding: 0 5vw;
    border-top: 0.1667vw solid var(--oliver-border);
  }

  .cv-list > .body > .filter-name > p {
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .cv-list > .body > .customer-card {
    height: 15.8333vw;
    border-top: 0.1667vw solid var(--oliver-border);
    padding: 0 2.6667vw;
  }

  .cv-list > .body > .customer-card > .selected-indicator {
    width: 1.1667vw;
    height: 13.1667vw;
    top: 1.5vw;
    border-radius: 0 0.6667vw 0.6667vw 0;
  }

  .cv-list > .body > :last-child {
    border-bottom: 0.1667vw solid var(--oliver-border);
  }

  .cv-list > .body > .customer-card > .avatar {
    width: 6vw;
    height: 6vw;
    margin-right: 3.3333vw;
  }

  .cv-list > .body > .customer-card > .text-group > p.style1 {
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .cv-list > .body > .customer-card > .text-group > p.style2 {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .cv-list > .mobile-footer {
    height: 14.3333vw;
    padding: 0 2.6667vw;
    border-top: 0.1667vw solid var(--oliver-border);
  }

  .cv-list > .mobile-footer > button {
    height: 9vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .customer-view-wrapper > .cv-search {
    top: 10vw;
    height: calc(100% - 10vw);
    padding: 2.6667vw;
    border-top: 0.1667vw solid var(--oliver-border);
  }

  .customer-view-wrapper > .cv-search > .header > #mobileCVExitSearch {
    padding: 0 0.6667vw;
    font-size: 2.6667vw;
    line-height: 4vw;
    margin-bottom: 3.3333vw;
  }

  .customer-view-wrapper > .cv-search > .header > #mobileCVExitSearch > img {
    width: 1.5vw;
    height: 2.6667vw;
    margin-right: 1.3333vw;
  }

  .customer-view-wrapper > .cv-search > .body > .row {
    margin-bottom: 1.8333vw;
  }

  .customer-view-wrapper > .cv-search > .body > .row > p {
    font-size: 2.6667vw;
    line-height: 3.8333vw;
  }

  .customer-view-wrapper > .cv-search > .body > .row > button {
    padding: 0.8333vw;
    font-size: 2.6667vw;
    line-height: 3.8333vw;
  }

  .customer-view-wrapper > .cv-search > .body > label {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 0.6667vw;
  }

  .customer-view-wrapper > .cv-search > .body > input {
    height: 7.3333vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1vw;
    margin-bottom: 3.3333vw;
    padding: 0 2vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .customer-view-wrapper > .cv-search > .body > button {
    height: 9vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
    margin-top: 3.3333vw;
  }

  .customer-view-wrapper > .cv-detailed {
    top: 10vw;
    height: calc(100% - 10vw);
    border-top: 0.1667vw solid var(--oliver-border);
  }

  .cv-detailed > .mobile-back {
    height: 8.8333vw;
    padding: 0 3vw;
  }

  .cv-detailed > .mobile-back > button {
    padding: 0 0.5vw;
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .cv-detailed > .mobile-back > button > img {
    width: 1.5167vw;
    height: 2.7vw;
    margin-right: 1.3333vw;
  }

  .cv-detailed > .quick-info {
    padding: 2.6667vw 3vw;
  }

  .cv-detailed > .quick-info > .avatar {
    width: 6vw;
    height: 6vw;
    margin: 0.6667vw 2.5vw 0 0;
  }

  .cv-detailed > .quick-info > .text-group > p.style1 {
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .cv-detailed > .quick-info > .text-group > p.style2 {
    font-size: 2vw;
    line-height: 2.8333vw;
  }

  .cv-detailed > .quick-info > button {
    width: 11.6667vw;
    height: 6vw;
    border: 0.1667vw solid var(--oliver-blue);
    border-radius: 1.3333vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .cv-detailed > .quick-info > button > img {
    width: 2.5vw;
    height: 2.6667vw;
    margin-right: 0.8333vw;
  }

  .cv-detailed > .cust-totals {
    width: calc(100% - 5.3333vw);
    padding: 2.1667vw 0;
    margin: 0 2.6667vw;
    border-bottom: 0.1667vw solid var(--oliver-border);
  }

  .cv-detailed > .cust-totals > .col > p.style1 {
    font-size: 2.6667vw;
    line-height: 4.1667vw;
  }

  .cv-detailed > .cust-totals > .col > p.style2 {
    font-size: 2.6667vw;
    line-height: 4.1667vw;
    margin-right: 1.3333vw;
  }

  .cv-detailed > .cust-totals > .col > button {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .cv-detailed > .cust-totals > .col > button > img {
    width: 2.6667vw;
    height: 2.6667vw;
    margin-left: 0.8333vw;
  }

  .cv-detailed > .bill-ship-info {
    width: calc(100% - 5.3333vw);
    border-bottom: 0.1667vw solid var(--oliver-border);
    padding: 2.5vw 0;
    margin: 0 2.6667vw;
  }

  .cv-detailed > .bill-ship-info > .col > p.style1 {
    font-size: 2.6667vw;
    line-height: 4vw;
    margin-bottom: 0.8333vw;
    margin-top: 2.8333vw;
  }

  .cv-detailed > .bill-ship-info > .col > p.style2 {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .cv-detailed > .cust-notes {
    width: calc(100% - 5.3333vw);
    padding: 2.1667vw 0;
    margin: 0 2.6667vw;
  }

  .cv-detailed > .cust-notes > .header-row {
    margin-bottom: 2.1667vw;
  }

  .cv-detailed > .cust-notes > .header-row > p {
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .cv-detailed > .cust-notes > .header-row > button {
    font-size: 2.3333vw;
    line-height: 3.5vw;
  }

  .cv-detailed > .cust-notes > .customer-note {
    margin-bottom: 3.3333vw;
  }

  .cv-detailed > .cust-notes > .customer-note > .row > p.style1 {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-right: 2.6667vw;
  }

  .cv-detailed > .cust-notes > .customer-note > .row > p.style2 {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .cv-detailed > .cust-notes > .customer-note > .row > button {
    width: 2.8333vw;
    height: 2.8333vw;
  }

  .cv-detailed > .cust-notes > .customer-note > p {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .cv-detailed > .footer {
    width: calc(100% - 5.3333vw);
    height: 14.1667vw;
    border-top: 0.1667vw solid var(--oliver-border);
  }

  .cv-detailed > .footer > button {
    height: 8.3333vw;
    border-radius: 1vw;
    font-size: 2.6667vw;
    line-height: 3.5vw;
  }

  .cv-detailed > .footer > button:first-of-type {
    margin-right: 2.5vw;
  }

  .transactions-wrapper > .navbar {
    top: 10vw;
    height: calc(100% - 10vw);
    padding-bottom: 1.6667vw;
  }

  .transactions-wrapper > .mobile-transactions-header {
    height: 11.6667vw;
    border-bottom: 0.1667vw solid var(--oliver-border);
    padding: 0 2.1667vw 0 1.3333vw;
  }

  .transactions-wrapper > .mobile-transactions-header > #mobileNavToggle {
    width: 6.6667vw;
    height: 6.6667vw;
    margin-right: 1.3333vw;
  }

  .transactions-wrapper > .mobile-transactions-header > #mobileNavToggle > img {
    width: 3.3333vw;
    height: 2.2217vw;
  }

  .transactions-wrapper
    > .mobile-transactions-header
    > #mobileNavToggle.opened
    > img {
    width: 6.5vw;
    height: 6.5vw;
  }

  .transactions-wrapper > .mobile-transactions-header > p {
    font-size: 3.3333vw;
    line-height: 4.6667vw;
  }

  .transactions-wrapper
    > .mobile-transactions-header
    > #mobileTransactionsSearchButton {
    width: 5.6667vw;
    height: 5.6667vw;
    border-radius: 1.3333vw;
    margin-right: 1.6667vw;
  }

  .transactions-wrapper
    > .mobile-transactions-header
    > #mobileTransactionsSearchButton
    > img {
    width: 3.5vw;
    height: 3.5vw;
  }

  .transactions-wrapper > .mobile-transactions-header > #mobileAppsButton {
    width: 5.6667vw;
    height: 5.6667vw;
    border-radius: 1.3333vw;
  }

  .transactions-wrapper
    > .mobile-transactions-header
    > #mobileAppsButton
    > img {
    width: 3.1033vw;
    height: 3.0767vw;
    transform: translateX(0.1667vw);
  }

  .transactions-wrapper > .mobile-refund.cart > .refund-cart-header {
    height: 10.8333vw;
    border-bottom: 0.1667vw solid var(--oliver-border);
    padding: 0 2.6667vw;
  }

  .transactions-wrapper > .mobile-refund.cart > .refund-cart-header > p {
    font-size: 3.3333vw;
    line-height: 4.6667vw;
  }

  .transactions-wrapper > .mobile-refund.cart > .refund-cart-header > button {
    width: 5.3333vw;
    height: 5.3333vw;
    border-radius: 1vw;
  }

  .transactions-wrapper
    > .mobile-refund.cart
    > .refund-cart-header
    > button
    > img {
    width: 2vw;
    height: 2vw;
  }

  .transactions-wrapper
    > .mobile-refund.cart
    > .body
    > .cart-item
    > .increment-input {
    width: 21.6667vw;
    height: 6vw;
    margin-top: 1.6667vw;
    padding: 0.8333vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1vw;
  }

  .transactions-wrapper
    > .mobile-refund.cart
    > .body
    > .cart-item
    > .increment-input
    > button {
    border-radius: 1vw;
  }

  .transactions-wrapper
    > .mobile-refund.cart
    > .body
    > .cart-item
    > .increment-input
    > button
    > img {
    width: 1.6667vw;
    height: 1.6667vw;
  }

  .transactions-wrapper
    > .mobile-refund.cart
    > .body
    > .cart-item
    > .increment-input
    > input {
    font-size: 2.6667vw;
    line-height: 3.5vw;
  }

  .transactions-wrapper
    > .mobile-refund.cart
    > .footer
    > .totals
    > .row
    > button {
    font-size: 2.6667vw;
    line-height: 3.6667vw;
  }

  .transactions-wrapper
    > .mobile-refund.cart
    > .footer
    > .totals
    > .row
    > p.type2 {
    font-size: 2.6667vw;
    line-height: 3.6667vw;
  }

  .transactions-wrapper > .mobile-refund.cart > .footer > button {
    width: calc(100% - 5vw);
    height: 9vw;
    margin: 0 auto 2.8333vw auto;
    border-radius: 1.6667vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .transactions-wrapper > .mobile-refund.cart > .footer > button > img {
    width: 4.3333vw;
    height: 4.3333vw;
    margin-left: 2vw;
  }

  .transactions-list {
    height: calc(100% - 11.6667vw);
  }

  .transactions-list > .header {
    height: 9.1667vw;
    padding: 0 2.6667vw;
    border-bottom: 0.1667vw solid var(--oliver-border);
  }

  .transactions-list > .header > p {
    font-size: 2.6667vw;
    line-height: 3.6667vw;
    margin-right: 1.6667vw;
  }

  .transactions-list > .header > .sort-wrapper {
    width: 25.8333vw;
    height: 6vw;
  }

  .transactions-list > .header > .sort-wrapper.open {
    box-shadow: 0vw 1vw 1.6667vw rgba(94, 94, 94, 0.1);
  }

  .transactions-list > .header > .sort-wrapper > img {
    top: calc(50% - 1vw);
    right: 1.5vw;
    width: 2.1667vw;
    height: 2.1667vw;
  }

  .transactions-list > .header > .sort-wrapper.open > img {
    transform: rotate(180deg) translateY(-0.3333vw);
  }

  .transactions-list > .header > .sort-wrapper > input {
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1.3333vw;
    padding: 0 4.1667vw 0 1.6667vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .transactions-list > .header > .sort-wrapper.open > input {
    border-radius: 1.3333vw 1.3333vw 0 0;
  }

  .transactions-list > .header > .sort-wrapper > .option-container > .option {
    height: 6vw;
    border: 0.1667vw solid var(--oliver-border);
    padding: 0 1.6667vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .transactions-list
    > .header
    > .sort-wrapper
    > .option-container
    > .option:last-child {
    border-radius: 0 0 1.3333vw 1.3333vw;
  }

  .transactions-list > .body > .no-results {
    margin-top: 9.6667vw;
  }

  .transactions-list > .body > .no-results > p.style1 {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 1.6667vw;
  }

  .transactions-list > .body > .no-results > p.style2 {
    font-size: 2.1667vw;
    line-height: 3vw;
  }

  .transactions-list > .body > .filter-name {
    height: 5vw;
    padding: 0 5vw;
    border-top: 0.1667vw solid var(--oliver-border);
  }

  .transactions-list > .body > .filter-name > p {
    font-size: 2.3333vw;
    line-height: 3.5vw;
  }

  .transactions-list > .body > .transaction-card {
    height: 17.6667vw;
    border-top: 0.1667vw solid var(--oliver-border);
    padding: 2.5vw 2.5vw 2.5vw 3.3333vw;
  }

  .transactions-list > .body > :last-child {
    border-bottom: 0.1667vw solid var(--oliver-border);
  }

  .transactions-list > .body > .transaction-card > .col > p.style1 {
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .transactions-list > .body > .transaction-card > .col > p.style2 {
    font-size: 2vw;
    line-height: 2.8333vw;
  }

  .transactions-list > .body > .transaction-card > .col > .row > img {
    width: 2.3333vw;
    height: 2vw;
    margin-right: 1.3333vw;
  }

  .transactions-list > .body > .transaction-card > .col > .row > p {
    font-size: 2vw;
    line-height: 2.8333vw;
  }

  .transactions-list > .body > .transaction-card > .col > p.style3 {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .transactions-list > .body > .transaction-card > .col > p.style4 {
    font-size: 2vw;
    line-height: 2.8333vw;
    padding-right: 0.1667vw;
  }

  .transactions-list > .body > .transaction-card > .selected-indicator {
    width: 1.1667vw;
    height: 12.6667vw;
    top: calc(50% - 6.3333vw);
    border-radius: 0 0.6667vw 0.6667vw 0;
  }

  .transactions-search {
    top: 11.6667vw;
    height: calc(100% - 11.6667vw);
  }

  .transactions-search > .search-header-mobile {
    padding: 2.6667vw;
  }

  .transactions-search > .search-header-mobile > #mobileSearchExit {
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .transactions-search > .search-header-mobile > #mobileSearchExit > img {
    width: 1.5167vw;
    height: 2.7vw;
    margin-right: 1.5vw;
  }

  .transactions-search > .search-header-mobile > #mobileSearchFieldClear {
    width: 18.1667vw;
    height: 6.6667vw;
    border: 0.1667vw solid var(--oliver-blue);
    border-radius: 1vw;
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .transactions-search > .search-body {
    padding: 0 2.6667vw;
  }

  .transactions-search > .search-body > p.mobile-only {
    font-size: 2.6667vw;
    line-height: 3.8333vw;
    margin-bottom: 1vw;
  }

  .transactions-search > .search-body label {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 0.6667vw;
  }

  .transactions-search > .search-body input {
    height: 7.3333vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1vw;
    padding: 0 2vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 3.3333vw;
  }

  .transactions-search > .search-body > input:first-of-type {
    margin-bottom: 1.1667vw;
  }

  .transactions-search > .search-body > p:not(.mobile-only) {
    font-size: 2vw;
    line-height: 2.8333vw;
  }

  .transactions-search > .search-body > .divider {
    height: 0.1667vw;
    margin: 3.3333vw 0;
  }

  .transactions-search > .search-body > .dropdown-wrapper {
    height: 7.3333vw;
    margin-bottom: 3.3333vw;
  }

  .transactions-search > .search-body > .dropdown-wrapper > img {
    right: 2.3333vw;
    top: calc(50% - 1.5vw);
    width: 3vw;
    height: 3vw;
  }

  .transactions-search > .search-body > .dropdown-wrapper.open > input {
    border-radius: 1vw 1vw 0 0;
  }

  .transactions-search > .search-body > .dropdown-wrapper > .option-list {
    max-height: 29.3333vw;
    border-radius: 0 0 1.1667vw 1.1667vw;
  }

  .transactions-search
    > .search-body
    > .dropdown-wrapper
    > .option-list
    > .option {
    height: 7.3333vw;
    padding: 0 2vw;
    border: 0.1667vw solid var(--oliver-border);
  }

  .transactions-search
    > .search-body
    > .dropdown-wrapper
    > .option-list
    > .option
    > p {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .transactions-search > .search-body > .input-row > .input-col:first-child {
    margin-right: 3.3333vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper {
    margin-bottom: 3.3333vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > input {
    padding-right: 5.3333vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > button {
    right: 2vw;
    top: calc(50% - 1.6667vw);
    width: 3.3333vw;
    height: 3.3333vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector {
    padding: 4.1667vw 3.3333vw;
    border: 0.1667vw solid var(--oliver-border);
    border-radius: 1vw;
    margin-top: 1.1667vw;
    filter: drop-shadow(0vw 1.6667vw 3.3333vw rgba(119, 135, 147, 0.1));
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper.left
    > .date-selector {
    left: 2.6667vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper.right
    > .date-selector {
    right: 2.6667vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .header-row {
    width: 46.6667vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .header-row
    > button {
    height: 5vw;
    padding: 0 1.6667vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .header-row
    > button
    > img {
    width: 6.6667vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .header-row
    > div {
    font-size: 3vw;
    line-height: 4vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .day-row {
    width: 46.6667vw;
    border-bottom: 0.1667vw solid var(--oliver-border);
    margin-bottom: 1.6667vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .day-row
    > .day {
    height: 6.6667vw;
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .date-row {
    width: 46.6667vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .date-row
    > button.cell {
    height: 6.6667vw;
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    border-radius: 1vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .month-row {
    width: 53.3333vw;
    margin-top: 2.5vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .month-row
    > button.cell {
    height: 6.6667vw;
    font-size: 2.6667vw;
    line-height: 3.3333vw;
    border-radius: 1vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .year-row {
    width: 46.6667vw;
    margin-top: 2.5vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .year-row
    > button.cell {
    height: 6.6667vw;
    font-size: 2.6667vw;
    line-height: 3.3333vw;
    border-radius: 1vw;
  }

  .transactions-search > .search-body > button {
    height: 9vw;
    border-radius: 1vw;
    margin: auto 0 3.3333vw 0;
    font-size: 3vw;
    line-height: 4vw;
  }

  .transactions-detailed {
    top: 11.6667vw;
    height: calc(100% - 11.6667vw);
  }

  .transactions-detailed > .detailed-header-mobile {
    padding: 2.6667vw;
  }

  .transactions-detailed > .detailed-header-mobile > #mobileDetailedExit {
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .transactions-detailed > .detailed-header-mobile > #mobileDetailedExit > img {
    width: 1.5vw;
    height: 2.6667vw;
    margin-right: 1.5vw;
  }

  .transactions-detailed > .scrollable > .quick-info {
    padding: 2.5vw;
  }

  .transactions-detailed > .scrollable > .quick-info > .row {
    margin-top: 0.8333vw;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > p.style1 {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > p.style2 {
    font-size: 2.1667vw;
    line-height: 3vw;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > p.style3 {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > .group > img {
    width: 3vw;
    height: 2.6667vw;
    margin-right: 1.3333vw;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > .group > p {
    font-size: 2.6667vw;
    line-height: 3.5vw;
  }

  .transactions-detailed > .scrollable > .customer-info {
    width: calc(100% - 6.6667vw);
    padding: 2.5vw 0 3.3333vw 0;
    border-bottom: 0.1667vw solid var(--oliver-border);
  }

  .transactions-detailed > .scrollable > .customer-info > .col > p.style1 {
    font-size: 2.6667vw;
    line-height: 4vw;
    margin-bottom: 1.3333vw;
  }

  .transactions-detailed > .scrollable > .customer-info > .col > p.style2 {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-bottom: 0.6667vw;
  }

  .transactions-detailed > .scrollable > .customer-info > button {
    width: 25.6667vw;
    height: 6.6667vw;
    border: 0.1667vw solid var(--oliver-blue);
    border-radius: 1vw;
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .transactions-detailed > .scrollable > .order-details {
    padding: 2.5vw 3.3333vw;
    min-height: 16.6667vw;
  }

  .transactions-detailed > .scrollable > .order-details > p {
    font-size: 2.6667vw;
    line-height: 4vw;
    margin-bottom: 4vw;
  }

  .transactions-detailed > .scrollable > .order-details > .item {
    margin-top: 3.3333vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .img-container {
    width: 11.3333vw;
    height: 11.5vw;
    margin-right: 4vw;
    border-radius: 0.6667vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .img-container
    > .quantity {
    min-width: 4.3333vw;
    height: 4.3333vw;
    padding: 0 0.5vw;
    right: 0vw;
    top: 0vw;
    border-radius: 1vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .img-container
    > .quantity
    > p {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .img-container
    > img {
    border-radius: 0.6667vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .main-row
    > p {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .item-fields {
    padding-top: 1vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .item-fields
    > p {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .modifier-fields {
    padding: 1.3333vw 0 0 2.1667vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .modifier-fields
    > .row
    > p {
    font-size: 2.3333vw;
    line-height: 3.1667vw;
    padding-right: 1.6667vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .product-total-fields {
    padding-top: 1.3333vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .product-total-fields
    > div {
    padding-top: 1vw;
    border-top: 0.1667vw solid var(--oliver-border);
    margin-top: 1vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .product-total-fields
    > div
    > .row
    > p {
    font-size: 2.3333vw;
    line-height: 3.1667vw;
    padding-right: 1.6667vw;
  }

  .transactions-detailed > .scrollable > .order-details > .custom-fields {
    margin-top: 2.5vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .custom-fields
    > p.style1 {
    font-size: 2.6667vw;
    line-height: 4vw;
    margin-bottom: 1.1667vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .custom-fields
    > p.style2 {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
    margin-left: 2.3333vw;
    max-width: calc(100% - 2.3333vw);
    margin-top: 0.5vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper {
    padding: 2.5vw 0;
    border-top: 0.3333vw solid var(--oliver-border);
    margin-top: 2.5vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals {
    width: min(66.6667vw, 75%);
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row {
    padding-bottom: 1vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row.border-bottom {
    border-bottom: 0.1667vw solid var(--oliver-border);
    margin-bottom: 1.8333vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row.border-top {
    padding: 1vw 0 0 0;
    border-top: 0.1667vw solid var(--oliver-border);
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row
    > p {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row
    > p.style2 {
    font-size: 2vw;
    line-height: 2.8333vw;
  }

  .transactions-detailed > .scrollable > .order-details > .refund-header {
    padding: 0.8333vw 1.3333vw;
    margin: 4.1667vw 0 2.5vw 0;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .refund-header
    > p.style1 {
    font-size: 2.3333vw;
    line-height: 3.5vw;
    padding-right: 1.6667vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .refund-header
    > p.style2 {
    font-size: 2vw;
    line-height: 3vw;
  }

  .transactions-detailed > .footer {
    width: calc(100% - 5.3333vw);
    padding: 13.5vw 0 2.6667vw 0;
    border-top: 0.1667vw solid var(--oliver-border);
  }

  .transactions-detailed > .footer > button:not(#receiptButton) {
    height: 9vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .transactions-detailed > .footer > button:last-of-type {
    margin-left: 3.1667vw;
  }

  .transactions-detailed > .footer > button#receiptButton {
    height: 9vw;
    border: 0.1667vw solid var(--oliver-blue);
    border-radius: 1vw;
    top: 2.6667vw;
    font-size: 3vw;
    line-height: 4vw;
  }

  .cash-management-wrapper > .navbar {
    top: 10vw;
    height: calc(100% - 10vw);
    padding-bottom: 1.6667vw;
  }

  .cash-management-wrapper > .mobile-cm-header {
    height: 11.6667vw;
    border-bottom: 0.1667vw solid var(--oliver-border);
    padding: 0 2.1667vw 0 1.3333vw;
  }

  .cash-management-wrapper > .mobile-cm-header > #mobileNavToggle {
    width: 6.6667vw;
    height: 6.6667vw;
    margin-right: 1.3333vw;
  }

  .cash-management-wrapper > .mobile-cm-header > #mobileNavToggle > img {
    width: 3.3333vw;
    height: 2.2217vw;
  }

  .cash-management-wrapper > .mobile-cm-header > #mobileNavToggle.opened > img {
    width: 6.5vw;
    height: 6.5vw;
  }

  .cash-management-wrapper > .mobile-cm-header > p {
    font-size: 3.3333vw;
    line-height: 4.6667vw;
  }

  .cash-management-wrapper
    > .mobile-cm-header
    > #mobileTransactionsSearchButton {
    width: 5.6667vw;
    height: 5.6667vw;
    border-radius: 1.3333vw;
    margin-right: 1.6667vw;
  }

  .cash-management-wrapper
    > .mobile-cm-header
    > #mobileTransactionsSearchButton
    > img {
    width: 3.5vw;
    height: 3.5vw;
  }

  .cash-management-wrapper > .mobile-cm-header > #mobileAppsButton {
    width: 5.6667vw;
    height: 5.6667vw;
    border-radius: 1.3333vw;
  }

  .cash-management-wrapper > .mobile-cm-header > #mobileAppsButton > img {
    width: 3.1033vw;
    height: 3.0767vw;
    transform: translateX(0.1667vw);
  }

  .cm-list > .cm-list-body > .current-register {
    height: 16.5vw;
    border-bottom: 0.1667vw solid var(--oliver-border);
    padding: 0 15.1667vw 0 2.6667vw;
  }

  .cm-list > .cm-list-body > .current-register > p.style1 {
    font-size: 2.5vw;
    line-height: 3.5vw;
  }

  .cm-list > .cm-list-body > .current-register > p.style2 {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .cm-list > .cm-list-body > .current-register > .text-row > p {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .cm-list > .cm-list-body > .current-register > .mobile-fake-button {
    right: 2.6667vw;
    width: 12.5vw;
    height: 6.6667vw;
    border: 0.1667vw solid var(--oliver-blue);
    border-radius: 1vw;
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .cm-list > .cm-list-body > .date {
    height: 5vw;
    border-bottom: 0.1667vw solid var(--oliver-border);
    padding: 2.6667vw;
  }

  .cm-list > .cm-list-body > .date > p {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .cm-list > .cm-list-body > .other-register {
    height: 12.5vw;
    border-bottom: 0.1667vw solid var(--oliver-border);
    padding: 0 2.6667vw;
  }

  .cm-list > .cm-list-body > .other-register > .row:nth-child(1) {
    margin-bottom: 0.6667vw;
  }

  .cm-list > .cm-list-body > .other-register > .row > p.style1 {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .cm-list > .cm-list-body > .other-register > .row > p.style2 {
    font-size: 2.3333vw;
    line-height: 3.3333vw;
  }

  .cm-list > .cm-list-body > .other-register.selected::before {
    width: 1vw;
    height: 10vw;
    top: 1.1667vw;
    border-radius: 0 0.6667vw 0.6667vw 0;
  }

  .cm-detailed {
    top: 11.6667vw;
    height: calc(100% - 11.6667vw);
  }

  .cm-detailed > .detailed-header-mobile {
    height: 8.6667vw;
  }

  .cm-detailed > .detailed-header-mobile > button {
    padding: 0 3.3333vw;
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .cm-detailed > .detailed-header-mobile > button > img {
    width: 1.5vw;
    height: 3vw;
    margin-right: 1.3333vw;
  }

  .cm-detailed > .detailed-quick-info {
    padding: 2.5vw;
  }

  .cm-detailed > .detailed-quick-info > .row:first-child {
    margin-bottom: 2.5vw;
  }

  .cm-detailed > .detailed-quick-info > .row > p {
    font-size: 2.3333vw;
    line-height: 3.5vw;
  }

  .cm-detailed > .detailed-quick-info > .row > .row-group > p {
    font-size: 3vw;
    line-height: 4.5vw;
    margin-right: 1.6667vw;
  }

  .cm-detailed > .detailed-quick-info > .row > .row-group > .status {
    height: 4vw;
    padding: 0 1.3333vw;
    border-radius: 1vw;
    font-size: 2vw;
    line-height: 3vw;
  }

  .cm-detailed > .detailed-quick-info > .row > .col-group > p.style1 {
    font-size: 2.1667vw;
    line-height: 3.1667vw;
  }

  .cm-detailed > .detailed-quick-info > .row > .col-group > p.style2 {
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .cm-detailed > .detailed-quick-info > .row > button {
    width: 14.6667vw;
    height: 6.6667vw;
    border: 0.1667vw solid var(--oliver-blue);
    border-radius: 1.3333vw;
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .cm-detailed > .detailed-quick-info > .row > button > img {
    width: 3.3333vw;
    height: 3.3333vw;
    margin-right: 1.3333vw;
  }

  .cm-detailed > .detailed-body {
    padding: 0 3.3333vw;
  }

  .cm-detailed > .detailed-body > .action {
    padding: 2.5vw 0;
    border-top: 0.1667vw solid var(--oliver-border);
  }

  .cm-detailed > .detailed-body > .action > .header-row > p {
    font-size: 2.3333vw;
    line-height: 3.5vw;
  }

  .cm-detailed > .detailed-body > .action > .header-row > p.style2 {
    font-size: 2.1667vw;
    line-height: 3.5vw;
  }

  .cm-detailed > .detailed-body > .action > .body-col > p {
    font-size: 2.1667vw;
    line-height: 3.3333vw;
  }

  .cm-detailed > .detailed-body > .action > .footer-col {
    margin-top: 1.6667vw;
  }

  .cm-detailed > .detailed-body > .action > .footer-col > p {
    font-size: 2.1667vw;
    line-height: 3.3333vw;
  }

  .cm-detailed > .detailed-footer {
    padding: 2.6667vw;
    border-top: 0.1667vw solid var(--oliver-border);
  }

  .cm-detailed > .detailed-footer > button {
    height: 8.3333vw;
    margin-left: 3.3333vw;
    border-radius: 1vw;
    font-size: 2.6667vw;
    line-height: 4vw;
  }

  .error-404-wrapper > .error-404-container {
    padding: 3.3333vw;
  }

  .error-404-wrapper > .error-404-container > img {
    width: 21.6667vw;
    height: 15.6667vw;
    margin: auto 0 5.8333vw 0;
  }

  .error-404-wrapper > .error-404-container > p.style1 {
    font-size: 6.6667vw;
    line-height: 8.8333vw;
    margin-bottom: 1.6667vw;
  }

  .error-404-wrapper > .error-404-container > p.style2 {
    font-size: 3.3333vw;
    line-height: 4.3333vw;
    margin-bottom: 1.6667vw;
  }

  .error-404-wrapper > .error-404-container > p.style3 {
    font-size: 3.3333vw;
    line-height: 4.3333vw;
  }

  .error-404-wrapper > .error-404-container > button {
    width: 28.8333vw;
    height: 8.3333vw;
    border-radius: 1vw;
    font-size: 3vw;
    line-height: 4vw;
    margin: 3.3333vw 0 5.8333vw 0;
  }

  .error-404-wrapper > .error-404-container > .row {
    margin-bottom: 1vw;
  }

  .error-404-wrapper > .error-404-container > .row > p {
    font-size: 2.6667vw;
    line-height: 3.5vw;
    margin-right: 0.6667vw;
  }

  .error-404-wrapper > .error-404-container > .row > a {
    font-size: 2.6667vw;
    line-height: 3.5vw;
  }
}

/* --------------------Landscape (Tablet Mode) ---------------------------------------------------------------------------------------------------------------------------*/
@media (orientation: landscape) {
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-button {
    height: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    width: 5px;
    border-radius: 6px;
    background-color: #b0bec9;
  }

  /* Avatar - Default */
  .avatar {
    border-radius: 50%;
    background-color: var(--oliver-blue);
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .avatar > .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  :disabled > .avatar {
    background-color: var(--oliver-new-grey);
  }

  /* Inputs and Text Areas */

  input:focus,
  textarea:focus {
    border-width: 2px !important;
  }

  /* --------------------Loading Page-------------------- */

  .loading-page-wrapper {
    width: 100%;
    height: 100%;
    background-color: #eaf5ff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 25px 0;
  }

  .loading-page-wrapper > img {
    width: 101px;
    height: 28px;
    margin-bottom: 60px;
  }

  .loading-page-wrapper > svg {
    width: 85px;
    height: 85px;
    margin-bottom: 60px;
  }

  .loading-page-wrapper > p.style1 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
    color: var(--oliver-base-blue-dark);
    margin-bottom: 10px;
  }

  .loading-page-wrapper > p.style2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue-dark);
    margin-bottom: 60px;
  }

  .loading-page-wrapper > p.style3 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-grey);
  }

  .ologo-1 {
    animation: animate-svg-fill-1 3s ease-in 0.2s both infinite;
    fill: #a1e663;
  }

  .ologo-2 {
    animation: animate-svg-fill-2 3s ease-in 0.4s both infinite;
    fill: #47d2a5;
  }

  .ologo-3 {
    animation: animate-svg-fill-3 3s ease-in 0.6s both infinite;
    fill: #1db9ca;
  }

  .ologo-4 {
    animation: animate-svg-fill-4 3s ease-in 0.8s both infinite;
    fill: #2797e8;
  }

  .ologo-5 {
    animation: animate-svg-fill-5 3s ease-in 0.9s both infinite;
    fill: #a568bc;
  }

  .ologo-6 {
    animation: animate-svg-fill-6 3s ease-in 1.1s both infinite;
    fill: #ff525f;
  }

  .ologo-7 {
    animation: animate-svg-fill-7 3s ease-in 1.2s both infinite;
    fill: #ff8552;
  }

  .ologo-8 {
    animation: animate-svg-fill-8 3s ease-in-out 1.3s both infinite;
    fill: #ffd026;
  }

  @keyframes animate-svg-fill-8 {
    0% {
      transform: translate(25px, -25px);
      fill: transparent;
    }

    10%,
    40% {
      transform: translate(0px, 0px);
      fill: #ffd026;
    }

    50%,
    100% {
      transform: translate(-25px, 25px);
      fill: transparent;
    }
  }

  /* --------------------Login-------------------- */

  .login-wrapper {
    width: 100%;
    height: 0;
    flex-grow: 1;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .login-wrapper > img {
    width: 143px;
    height: 50px;
    margin: 50px 0 20px 0;
  }

  .login-wrapper > p {
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 43.98px;
  }

  .login-wrapper > .login-form {
    width: 347px;
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;
  }

  .login-wrapper > .login-form > label {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .login-wrapper > .login-form > input {
    width: 100%;
    height: 44px;
    outline: 0;
    border: 1px solid var(--oliver-border);
    border-radius: 2px;
    padding: 0 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 28px;
  }

  .login-wrapper > .login-form > input:focus {
    border-color: var(--oliver-blue);
  }

  .login-wrapper > .login-form > .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;
  }

  .login-wrapper > .login-form > .row > a {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .login-wrapper > .login-form > .row > .custom-checkbox-wrapper {
    display: flex;
    align-items: center;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    color: var(--oliver-base-grey);
  }

  .login-wrapper
    > .login-form
    > .row
    > .custom-checkbox-wrapper
    > input[type="checkbox"] {
    position: fixed;
    left: -10000px;
    opacity: 0;
  }

  .login-wrapper
    > .login-form
    > .row
    > .custom-checkbox-wrapper
    > .custom-checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid var(--oliver-border);
    border-radius: 4px;
    margin-right: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login-wrapper
    > .login-form
    > .row
    > .custom-checkbox-wrapper
    > .custom-checkbox
    > img {
    width: 75%;
    height: auto;
    display: none;
  }

  .login-wrapper
    > .login-form
    > .row
    > .custom-checkbox-wrapper
    > input[type="checkbox"]:checked
    ~ .custom-checkbox {
    border-color: var(--oliver-blue);
  }

  .login-wrapper
    > .login-form
    > .row
    > .custom-checkbox-wrapper
    > input[type="checkbox"]:checked
    ~ .custom-checkbox
    > img {
    display: block;
  }

  .login-wrapper > .login-form > button {
    width: 100%;
    height: 48px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 4px;
    font-family: Poppins;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  .login-wrapper > .or-row {
    width: 347px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;
  }

  .login-wrapper > .or-row > .divider {
    width: 127.53px;
    height: 1px;
    background-color: var(--oliver-border);
  }

  .login-wrapper > .or-row > p {
    font-size: 20px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-border);
  }

  .login-wrapper > button {
    position: relative;
    box-sizing: border-box;
    width: 347px;
    height: 48px;
    padding-left: 48px;
    border: 0;
    border-radius: 4px;
    background-color: var(--oliver-blue);
    margin-bottom: 16px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: #fff;
    line-height: 0;
  }

  .login-wrapper > button:last-of-type {
    margin-bottom: 54px;
  }

  .login-wrapper > button > .img-container {
    position: absolute;
    left: 2px;
    top: 2px;
    width: 44px;
    height: 44px;
    display: flex;
    background-color: #fff;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
  }

  .login-wrapper > #googleButton {
    background-color: var(--oliver-blue);
  }

  .login-wrapper > #googleButton > .img-container > img {
    width: 45.19px;
    height: 38.61px;
  }

  .login-wrapper > #facebookButton {
    background-color: #4267b2;
  }

  .login-wrapper > #facebookButton > .img-container > img {
    width: 26.52px;
    height: 21.29px;
  }

  .login-wrapper > #appleButton {
    background-color: #050505;
  }

  .login-wrapper > #appleButton > .img-container > img {
    width: 40.58px;
    height: 27.42px;
  }

  .login-wrapper > .row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
  }

  .login-wrapper > .row > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-right: 5px;
  }

  .login-wrapper > .row > a {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  /* --------------------Choose Pages-------------------- */

  .choose-wrapper {
    width: 100%;
    height: 100%;
    background-color: var(--oliver-bg-grey);
    display: flex;
    flex-direction: column;
  }

  .choose-wrapper > .choose-header {
    width: 100%;
    height: 64px;
    background-color: #fff;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 20px;
  }

  .choose-wrapper > .choose-header > button {
    width: 109px;
    height: 40px;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid var(--oliver-blue);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-blue);
    margin-right: auto;
  }

  .choose-wrapper > .choose-header > button > img {
    width: 7.1px;
    height: 14.2px;
    margin-right: 10px;
  }

  .choose-wrapper > .choose-header > p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: right;
    color: var(--oliver-base-grey);
    max-width: 90%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .choose-wrapper > .choose-body-default {
    width: 100%;
    height: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    box-sizing: border-box;
    padding: 55px 0;
  }

  .choose-wrapper > .choose-body-default button:disabled::after {
    content: "";
    background-image: url(assets/images/svg/LockIcon.svg);
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
    position: absolute;
    left: 8px;
    top: 10px;
  }

  .choose-wrapper > .choose-body-default button:disabled:hover {
    cursor: default !important;
  }

  .choose-wrapper > .choose-body-default button:disabled > .img-container {
    background-color: var(--oliver-border) !important;
  }

  .choose-wrapper > .choose-body-default button:disabled > .col > p.style1 {
    color: #b0bec9 !important;
  }

  .choose-wrapper > .choose-body-default button:disabled > .col > p.style2 {
    color: var(--oliver-border) !important;
  }

  .choose-wrapper > .choose-body-default button:disabled > .fake-button {
    background-color: var(--oliver-border) !important;
  }

  .choose-wrapper > .choose-body-default > p {
    font-family: Poppins;
    font-size: 22px;
    font-weight: 400;
    line-height: 32px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 19px;
  }

  .choose-wrapper > .choose-body-default > .divider {
    width: 60px;
    height: 1px;
    background-color: var(--oliver-border);
    margin-bottom: 45px;
  }

  .choose-wrapper > .choose-body-default > .button-container {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 970px;
    justify-content: center;
  }

  .choose-wrapper > .choose-body-default > .button-container > p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 20px;
    margin-right: auto;
  }

  .choose-wrapper > .choose-body-default > .button-container > .divider {
    width: 100%;
    height: 1px;
    background-color: var(--oliver-border);
    margin-bottom: 20px;
  }

  .choose-wrapper > .choose-body-default button {
    width: 174px;
    height: 256px;
    margin: 0 25px 25px 0;
    box-sizing: border-box;
    border: 1px solid var(--oliver-ui-bubble-grey);
    border-radius: 6.78146px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 26.28px 0 19px 0;
    filter: drop-shadow(0px 4.23841px 8.47682px rgba(0, 0, 0, 0.1));
  }

  .choose-wrapper > .choose-body-default button:nth-child(5n) {
    margin-right: 0;
  }

  .choose-wrapper > .choose-body-default button:last-of-type {
    margin-right: 0;
  }

  .choose-wrapper > .choose-body-default button > .img-container {
    width: 82.33px;
    height: 82.33px;
    border-radius: 50%;
    background-color: var(--oliver-blue);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .choose-wrapper > .choose-body-default button > .img-container > img {
    width: 100%;
    height: 100%;
  }

  .choose-wrapper
    > .choose-body-default
    button
    > .img-container
    > img.oliver-icon {
    width: 36px;
    height: 36px;
    transform: translateX(1px);
  }

  .choose-wrapper
    > .choose-body-default
    button
    > .img-container
    > img.www-icon {
    width: 52px;
    height: 52px;
  }

  .choose-wrapper
    > .choose-body-default
    button
    > .img-container
    > img.store-icon {
    width: 40px;
    height: 37px;
  }

  .choose-wrapper
    > .choose-body-default
    button
    > .img-container
    > img.register-icon {
    width: 43px;
    height: 43px;
  }

  .choose-wrapper
    > .choose-body-default
    button
    > .img-container
    > img.kiosk-icon {
    width: 56px;
    height: 56px;
  }

  .choose-wrapper > .choose-body-default button > .col {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .choose-wrapper > .choose-body-default button > .col > p.style1 {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    color: var(--oliver-base-blue);
    max-width: 90%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .choose-wrapper > .choose-body-default button > .col > p.style2 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: center;
    color: var(--oliver-base-grey);
    max-width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .choose-wrapper > .choose-body-default button:not(#addNew) > img {
    display: none;
  }

  .choose-wrapper > .choose-body-default button > .fake-button {
    background-color: var(--oliver-blue);
    width: 126.18px;
    height: 48px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }

  .choose-wrapper > .choose-body-default button.assigned > .fake-button {
    box-sizing: border-box;
    border: 1px solid var(--oliver-blue);
    color: var(--oliver-blue);
    background-color: #fff !important;
  }

  .choose-wrapper
    > .choose-body-default
    button.assigned
    > .fake-button.background-coral {
    border-color: var(--oliver-coral);
    color: var(--oliver-coral);
  }

  .choose-wrapper
    > .choose-body-default
    button.assigned
    > .fake-button.background-cyan {
    border-color: var(--oliver-cyan);
    color: var(--oliver-cyan);
  }

  .choose-wrapper
    > .choose-body-default
    button.assigned
    > .fake-button.background-lime {
    border-color: var(--oliver-lime);
    color: var(--oliver-lime);
  }

  .choose-wrapper
    > .choose-body-default
    button.assigned
    > .fake-button.background-primary {
    border-color: var(--oliver-primary);
    color: var(--oliver-primary);
  }

  .choose-wrapper
    > .choose-body-default
    button.assigned
    > .fake-button.background-red {
    border-color: var(--oliver-red);
    color: var(--oliver-red);
  }

  .choose-wrapper
    > .choose-body-default
    button.assigned
    > .fake-button.background-teal {
    border-color: var(--oliver-teal);
    color: var(--oliver-teal);
  }

  .choose-wrapper
    > .choose-body-default
    button.assigned
    > .fake-button.background-violet {
    border-color: var(--oliver-violet);
    color: var(--oliver-violet);
  }

  .choose-wrapper
    > .choose-body-default
    button.assigned
    > .fake-button.background-yellow {
    border-color: var(--oliver-yellow);
    color: var(--oliver-yellow);
  }

  .choose-wrapper > .choose-body-default button#addNew {
    border: 2px dashed var(--oliver-border);
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 15px;
    text-align: center;
    color: var(--oliver-grey-text);
    filter: none;
  }

  .choose-wrapper > .choose-body-default button#addNew > img.landscape {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }

  .choose-wrapper > .choose-body-default button#addNew > img.mobile {
    display: none;
  }

  .choose-wrapper > .choose-body-default .button-group {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    padding-bottom: 25px;
  }

  .choose-wrapper
    > .choose-body-default
    .button-group
    > button:not(.assigned)
    > .col
    > p.style2 {
    color: var(--oliver-blue);
  }

  /* --------------------Subwindows-------------------- */

  .subwindow-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(123, 140, 152, 0.3);
    z-index: 5;
  }

  .subwindow-wrapper.hidden {
    display: none;
  }

  .subwindow-wrapper > .subwindow {
    position: absolute;
    background-color: #fff;
    border: 0;
    border-radius: 8px;
    box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.3);
    display: none !important;
    flex-direction: column;
    overflow: auto;
  }

  .subwindow-wrapper > .subwindow.current {
    display: flex !important;
  }

  .subwindow-wrapper > .subwindow > .subwindow-header {
    position: relative;
    width: 100%;
    min-height: 65px;
    height: fit-content;
    box-sizing: border-box;
    border-bottom: 1px solid var(--oliver-border);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .subwindow-wrapper > .subwindow > .subwindow-header > p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper > .subwindow > .subwindow-header > button.close-subwindow {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 32px;
    height: 32px;
    background-color: #f1f4f6;
    border: 0;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .subwindow-wrapper
    > .subwindow
    > .subwindow-header
    > button.close-subwindow
    > img {
    width: 12px;
    height: 12px;
  }

  .subwindow-wrapper > .subwindow > .subwindow-body {
    width: 100%;
    height: 0;
    flex-grow: 1;
    overflow: auto;
    background-color: #f8fafc;
    border-radius: 0 0 8px 8px;
    padding: 20px 0;
    box-sizing: border-box;
  }

  /* Update Plugin */

  .subwindow.update-plugin {
    width: min(504px, 95%);
    height: min(400px, 95%);
  }

  .subwindow.update-plugin > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow.update-plugin > .subwindow-body > img {
    width: 62px;
    height: 62px;
    margin: auto 0 50px 0;
  }

  .subwindow.update-plugin > .subwindow-body > p > br.mobile {
    display: none;
  }

  .subwindow.update-plugin > .subwindow-body > p.style1 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 15px;
  }

  .subwindow.update-plugin > .subwindow-body > p.style2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 50px;
  }

  .subwindow.update-plugin > .subwindow-body > button {
    width: 198px;
    height: 50px;
    border: 0;
    border-radius: 6px;
    background-color: var(--oliver-blue);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin-bottom: auto;
  }

  /* Cart No Item */

  .subwindow.cart-no-item {
    width: min(504px, 95%);
    height: min(300px, 95%);
  }

  .subwindow.cart-no-item > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow.cart-no-item > .subwindow-body > p.style1 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin: auto 0 15px 0;
  }

  .subwindow.cart-no-item > .subwindow-body > p.style2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 50px;
  }

  .subwindow.cart-no-item > .subwindow-body > button {
    width: 198px;
    height: 50px;
    border: 0;
    border-radius: 6px;
    background-color: var(--oliver-blue);
    margin-bottom: auto;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Discount-Error */

  .subwindow.discount-error {
    width: min(95%, 504px);
    height: min(95%, 400px);
  }

  .subwindow.discount-error > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow.discount-error > .subwindow-body > p > br.mobile {
    display: none;
  }

  .subwindow.discount-error > .subwindow-body > p.style1 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin: auto 0 15px 0;
  }

  .subwindow.discount-error > .subwindow-body > p.style2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 15px;
  }

  .subwindow.discount-error > .subwindow-body > p:last-of-type {
    margin-bottom: 50px;
  }

  .subwindow.discount-error > .subwindow-body > button {
    width: 198px;
    height: 50px;
    border: 0;
    border-radius: 6px;
    background-color: var(--oliver-blue);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin-bottom: auto;
  }

  /* Product Unsupported */

  .subwindow.product-unsupported {
    width: 504px;
    height: 400px;
  }

  .subwindow.product-unsupported > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow.product-unsupported > .subwindow-body > p.style1 {
    margin: auto 0 33px 0;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .subwindow.product-unsupported > .subwindow-body > img {
    width: 50px;
    height: 52px;
    margin-bottom: 33px;
  }

  .subwindow.product-unsupported > .subwindow-body > p.style2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 33px;
  }

  .subwindow.product-unsupported > .subwindow-body > p.style2 > br.mobile {
    display: none;
  }

  .subwindow.product-unsupported > .subwindow-body > button {
    width: 198px;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin-bottom: auto;
  }

  /* Refund Show Payments */

  .subwindow.refund-show-payments {
    width: min(504px, 95%);
  }

  .subwindow.refund-show-payments > .subwindow-body {
    height: fit-content;
    max-height: calc(95vh - 65px);
    padding: 20px 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    overflow: auto;
  }

  .subwindow.refund-show-payments > .subwindow-body > .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 11px;
  }

  .subwindow.refund-show-payments > .subwindow-body > .row:nth-child(1) {
    margin-top: 0;
  }

  .subwindow.refund-show-payments > .subwindow-body > .row > p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow.refund-show-payments > .subwindow-body > .row > p > b {
    font-weight: 500;
  }

  .subwindow.refund-show-payments > .subwindow-body > button {
    width: 100%;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin-top: 33px;
  }

  /* Edit-order-status */

    .subwindow.edit-order-status {
      width: min(344px, 95%);
      height: min(564px, 95%);
    }
  
    .subwindow.edit-order-status > .subwindow-body > div.order-status-options{
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      gap: 15px;
    }

    .subwindow.edit-order-status > .subwindow-body > div.order-status-options > button{
      width: 285px;
      height: 45px;
      box-sizing: border-box;
      border: 1px solid var(--oliver-blue);
      border-radius: 6px;
      background-color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: var(--oliver-blue);
    }



    .subwindow.edit-order-status > .subwindow-body > div.order-status-options > button.button-update-order-status{
    width: 285px;
    height: 44px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin-top: 13px;
    color: #fff;
    }

  /* No Permissions */

  .subwindow.no-permissions {
    width: min(504px, 95%);
    height: min(400px, 95%);
  }

  .subwindow.no-permissions > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow.no-permissions > .subwindow-body > img {
    width: 50px;
    height: 50px;
    margin: auto 0 25px 0;
  }

  .subwindow.no-permissions > .subwindow-body > p.style1 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 36px;
  }

  .subwindow.no-permissions > .subwindow-body > p.style2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 44px;
  }

  .subwindow.no-permissions > .subwindow-body > p.style2 > br.mobile {
    display: none;
  }

  .subwindow.no-permissions > .subwindow-body > button {
    width: 198px;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin-bottom: auto;
  }

  /* No Registered Account */

  .subwindow-wrapper > .subwindow.no-account {
    width: min(800px, 95%);
    height: min(650px, 95%);
  }

  .subwindow-wrapper > .subwindow.no-account .mobile-only {
    display: none !important;
  }

  .subwindow-wrapper > .subwindow.no-account > .subwindow-body {
    display: flex;
    flex-direction: row;
    padding: 0;
    background-color: #fff;
    overflow: hidden;
  }

  .subwindow-wrapper > .subwindow.no-account > .subwindow-body > img {
    width: auto;
    height: 100%;
  }

  .subwindow-wrapper > .subwindow.no-account > .subwindow-body > .content {
    width: 0;
    height: 100%;
    flex-grow: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0 30px 0;
    box-sizing: border-box;
  }

  .subwindow-wrapper
    > .subwindow.no-account
    > .subwindow-body
    > .content
    > img {
    width: 45px;
    height: 45px;
    margin-bottom: 40px;
  }

  .subwindow-wrapper
    > .subwindow.no-account
    > .subwindow-body
    > .content
    > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 40px;
  }

  .subwindow-wrapper
    > .subwindow.no-account
    > .subwindow-body
    > .content
    > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 40px;
  }

  .subwindow-wrapper
    > .subwindow.no-account
    > .subwindow-body
    > .content
    > button#createAccount {
    width: 265px;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin-bottom: auto;
  }

  .subwindow-wrapper
    > .subwindow.no-account
    > .subwindow-body
    > .content
    > button#loginTryAgain {
    width: 173px;
    height: 50px;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid var(--oliver-blue);
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-blue);
    margin: 40px 0;
  }

  .subwindow-wrapper
    > .subwindow.no-account
    > .subwindow-body
    > .content
    > .row {
    display: flex;
    align-items: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
  }

  .subwindow-wrapper
    > .subwindow.no-account
    > .subwindow-body
    > .content
    > .row
    > p {
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.no-account
    > .subwindow-body
    > .content
    > .row
    > a {
    font-weight: 600;
    color: var(--oliver-blue);
    margin-left: 4px;
  }

  /* Takeover Register */

  .subwindow-wrapper > .subwindow.takeover-register {
    width: min(504px, 95%);
    height: min(420px, 95%);
  }

  .subwindow-wrapper > .subwindow.takeover-register > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper > .subwindow.takeover-register > .subwindow-body > p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 60px;
  }

  .subwindow-wrapper > .subwindow.takeover-register > .subwindow-body > p > b {
    font-weight: 600;
  }

  .subwindow-wrapper > .subwindow.takeover-register > .subwindow-body > button {
    width: 170px;
    height: 48px;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }

  .subwindow-wrapper
    > .subwindow.takeover-register
    > .subwindow-body
    > button#takeoverRegister {
    background-color: var(--oliver-red);
    border: 0;
    color: #fff;
    margin-bottom: 17px;
  }

  .subwindow-wrapper
    > .subwindow.takeover-register
    > .subwindow-body
    > button#cancelTakeover {
    background-color: #fff;
    border: 0.08375vw solid var(--oliver-blue);
    color: var(--oliver-blue);
  }

  /* Register Taken */

  .subwindow-wrapper > .subwindow.register-taken {
    width: 490px;
    height: min(95%, 339px);
  }

  .subwindow-wrapper > .subwindow.register-taken > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper > .subwindow.register-taken > .subwindow-body > img {
    width: 50px;
    height: 52px;
    margin-bottom: 30px;
  }

  .subwindow-wrapper > .subwindow.register-taken > .subwindow-body > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  /* Knowledge Base */

  .subwindow.knowledge-base {
    width: min(1000px, 95%);
    height: min(794px, 95%);
  }

  .subwindow.knowledge-base > .subwindow-header {
    width: 100%;
    box-sizing: border-box;
    padding: 0 66px 0 19px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .subwindow.knowledge-base > .subwindow-header > .icon-container {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 17px;
  }

  .subwindow.knowledge-base > .subwindow-header > .icon-container > img {
    width: 100%;
    height: 100%;
  }

  .subwindow.knowledge-base > .subwindow-header > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-right: auto;
  }

  .subwindow.knowledge-base > .subwindow-header > button#kBRefresh {
    width: 128px;
    height: 32px;
    border-radius: 6px;
    background-color: transparent;
    border: 1px solid var(--oliver-base-grey);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    text-align: center;
    color: var(--oliver-base-grey);
  }

  .subwindow.knowledge-base > .subwindow-header > button#kBRefresh > img {
    width: 13px;
    height: 13px;
    margin-right: 6px;
  }

  .subwindow.knowledge-base > .subwindow-body {
    overflow: hidden;
    padding: 0;
  }

  .subwindow.knowledge-base > .subwindow-body > iframe {
    width: 100%;
    height: 100%;
  }

  /* Link Launcher */

  .subwindow-wrapper > .subwindow.link-launcher-subwindow {
    width: 1000px;
    height: min(95%, 794px);
  }

  .subwindow-wrapper > .subwindow.link-launcher-subwindow > .subwindow-header {
    justify-content: flex-start;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-header
    > img {
    margin: 0 14px 0 16px;
  }

  .subwindow-wrapper > .subwindow.link-launcher-subwindow > .subwindow-body {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header {
    width: 100%;
    height: 74px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid var(--oliver-border);
    padding: 0 16px;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > .link-wrapper {
    display: flex;
    width: 0;
    flex-grow: 1;
    align-items: center;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > .link-wrapper
    > img {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 6px;
    margin-right: 16px;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > .link-wrapper
    > .text-col {
    width: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > .link-wrapper
    > .text-col
    > p {
    max-width: 95%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > .link-wrapper
    > .text-col
    > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > .link-wrapper
    > .text-col
    > p.style2 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > button {
    width: 128px;
    height: 32px;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid var(--oliver-base-grey);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    text-align: center;
    color: var(--oliver-base-grey);
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > button
    > img {
    width: 13px;
    height: 13px;
    margin-right: 6px;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > iframe {
    width: 100%;
    height: 0;
    flex-grow: 1;
  }

  /* App Launcher Subwindow */

  .subwindow-wrapper > .subwindow.app-launcher-subwindow {
    width: 800px;
    height: calc(100% - 40px);
  }

  .subwindow-wrapper > .subwindow.app-launcher-subwindow.small {
    width: 600px;
  }

  .subwindow-wrapper > .subwindow.app-launcher-subwindow.large {
    width: calc(100% - 40px);
  }

  .subwindow-wrapper > .subwindow.app-launcher-subwindow > .subwindow-header {
    height: fit-content;
    justify-content: flex-start;
    padding: 16px 48px 16px 16px;
  }

  .subwindow-wrapper
    > .subwindow.app-launcher-subwindow
    > .subwindow-header
    > .app-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .subwindow-wrapper
    > .subwindow.app-launcher-subwindow
    > .subwindow-header
    > .app-wrapper
    > img {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 10px;
    margin-right: 19px;
  }

  .subwindow-wrapper
    > .subwindow.app-launcher-subwindow
    > .subwindow-header
    > .app-wrapper
    > .text-col {
    width: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .subwindow-wrapper
    > .subwindow.app-launcher-subwindow
    > .subwindow-header
    > .app-wrapper
    > .text-col
    > p {
    max-width: 95%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .subwindow-wrapper
    > .subwindow.app-launcher-subwindow
    > .subwindow-header
    > .app-wrapper
    > .text-col
    > p.style1 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.app-launcher-subwindow
    > .subwindow-header
    > .app-wrapper
    > .text-col
    > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .subwindow-wrapper > .subwindow.app-launcher-subwindow > .subwindow-body {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .subwindow-wrapper
    > .subwindow.app-launcher-subwindow
    > .subwindow-body
    > iframe {
    width: 100%;
    height: 0;
    flex-grow: 1;
  }

  /* Create Customer */

  .subwindow.create-customer {
    width: 744px;
    height: min(95%, 1090px);
    overflow: hidden;
  }

  .subwindow.create-customer > .subwindow-body {
    box-sizing: border-box;
    width: 100%;
    height: 0;
    flex-grow: 1;
    padding: 20px 68px;
    display: block;
    overflow-y: scroll;
  }

  .subwindow.create-customer > .subwindow-body > div:not(.button-row) {
    width: 100%;
    border-top: 1px solid var(--oliver-border);
    padding-top: 15px;
    margin-top: 16px;
  }

  .subwindow.create-customer > .subwindow-body > div:first-of-type {
    border-top: 0 !important;
    padding-top: 0 !important;
    margin-top: 0 !important;
  }

  .subwindow.create-customer > .subwindow-body > div > p {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 23px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow.create-customer > .subwindow-body > div > .input-row {
    width: 100%;
    display: flex;
    margin-top: 16px;
  }

  .subwindow.create-customer > .subwindow-body > div > .input-row > .input-col {
    width: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 14px;
    position: relative;
    line-height: 0;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col:nth-child(1) {
    margin-left: 0 !important;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > label {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    padding-bottom: 4px;
    z-index: 2;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > label:hover {
    cursor: pointer;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input {
    height: 42px;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    padding: 0 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input:hover {
    border-color: var(--oliver-blue);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input:focus {
    border-width: 2px;
    border-color: var(--oliver-blue);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input.error {
    border-color: var(--error);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input.error
    ~ .error-wrapper {
    position: absolute;
    top: 0;
    right: 0;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input.error
    ~ .error-wrapper::after {
    content: "*Error";
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    color: var(--error);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input.error.invalid-input
    ~ .error-wrapper::after {
    content: "*Invalid Input";
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input.error.required
    ~ .error-wrapper::after {
    content: "*Required";
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper {
    width: 100%;
    height: 42px;
    position: relative;
    background-color: #fff;
    line-height: 0;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open {
    z-index: 3;
    box-shadow: 0px 10px 20px rgba(119, 135, 147, 0.1);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > input {
    width: 100%;
    height: 100%;
    z-index: 2;
    position: relative;
    line-height: 0;
    background-color: transparent;
    padding-right: 40px;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > input:focus {
    border-width: 1px;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > input:hover {
    cursor: pointer;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open
    > input {
    border-color: var(--oliver-blue);
    border-radius: 6px 6px 0 0;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > img {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 12px;
    top: calc(50% - 10px);
    z-index: 1;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open
    > img {
    transform: rotate(180deg) translateY(-2px);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container {
    display: none;
    box-sizing: border-box;
    width: 100%;
    background-color: #fff;
    max-height: 400%;
    border: 1px solid var(--oliver-blue);
    border-top: 0;
    border-radius: 0 0 6px 6px;
    overflow: auto;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open
    > .option-container {
    display: block;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option {
    box-sizing: border-box;
    width: 100%;
    height: 42px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    border-radius: 0 0 6px 6px;
    border-top: 1px solid var(--oliver-blue);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option:nth-child(1) {
    border-top: 0 !important;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option
    > p {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option:hover {
    cursor: pointer;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option:hover
    > p {
    color: var(--oliver-blue);
  }

  .subwindow.create-customer > .subwindow-body > div > .title-row {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .subwindow.create-customer > .subwindow-body > div > .title-row > p {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 23px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-right: auto;
  }

  .subwindow.create-customer > .subwindow-body > div > .title-row > label {
    display: flex;
    align-items: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .title-row
    > label:hover {
    cursor: pointer;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > input {
    position: fixed;
    left: -10000px;
    opacity: 0;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > .custom-checkbox {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 4px;
    margin-right: 12px;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > .custom-checkbox
    > img {
    width: 10px;
    height: 10px;
    display: none;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > input:checked
    ~ .custom-checkbox {
    border-color: var(--oliver-blue);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > input:checked
    ~ .custom-checkbox
    > img {
    display: block;
  }

  .subwindow.create-customer > .subwindow-body > .button-row {
    width: 100%;
    display: flex;
    margin-top: 30px;
  }

  .subwindow.create-customer > .subwindow-body > .button-row > button {
    flex: 1;
    margin-left: 14px;
    height: 44px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  .subwindow.create-customer
    > .subwindow-body
    > .button-row
    > button:first-of-type {
    margin-left: 0 !important;
  }

  .subwindow.create-customer > .subwindow-body > div > .input-row.hidden {
    display: none;
  }

  /* Edit Customer */

  .subwindow.edit-customer {
    width: 744px;
    height: min(95%, 1090px);
    overflow: hidden;
  }

  .subwindow.edit-customer > .subwindow-body {
    box-sizing: border-box;
    width: 100%;
    height: 0;
    flex-grow: 1;
    padding: 20px 68px;
    display: block;
    overflow-y: scroll;
  }

  .subwindow.edit-customer > .subwindow-body > div:not(.button-row) {
    width: 100%;
    border-top: 1px solid var(--oliver-border);
    padding-top: 15px;
    margin-top: 16px;
  }

  .subwindow.edit-customer > .subwindow-body > div:first-of-type {
    border-top: 0 !important;
    padding-top: 0 !important;
    margin-top: 0 !important;
  }

  .subwindow.edit-customer > .subwindow-body > div > p {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 23px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow.edit-customer > .subwindow-body > div > .input-row {
    width: 100%;
    display: flex;
    margin-top: 16px;
  }

  .subwindow.edit-customer > .subwindow-body > div > .input-row > .input-col {
    width: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 14px;
    position: relative;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col:nth-child(1) {
    margin-left: 0 !important;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > label {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    padding-bottom: 4px;
    z-index: 2;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > label:hover {
    cursor: pointer;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input {
    height: 42px;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    padding: 0 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input:hover {
    border-color: var(--oliver-blue);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input:focus {
    border-width: 2px;
    border-color: var(--oliver-blue);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input.error {
    border-color: var(--error);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input.error
    ~ .error-wrapper {
    position: absolute;
    top: 0;
    right: 0;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input.error
    ~ .error-wrapper::after {
    content: "*Error";
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    color: var(--error);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input.error.invalid-input
    ~ .error-wrapper::after {
    content: "*Invalid Input";
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input.error.required
    ~ .error-wrapper::after {
    content: "*Required";
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper {
    width: 100%;
    height: 42px;
    position: relative;
    background-color: #fff;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open {
    z-index: 3;
    box-shadow: 0px 10px 20px rgba(119, 135, 147, 0.1);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > input {
    width: 100%;
    height: 100%;
    z-index: 2;
    position: relative;
    background-color: transparent;
    padding-right: 40px;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > input:focus {
    border-width: 1px;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > input:hover {
    cursor: pointer;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open
    > input {
    border-color: var(--oliver-blue);
    border-radius: 6px 6px 0 0;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > img {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 12px;
    top: calc(50% - 10px);
    z-index: 1;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open
    > img {
    transform: rotate(180deg) translateY(-2px);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container {
    display: none;
    box-sizing: border-box;
    width: 100%;
    background-color: #fff;
    max-height: 400%;
    border: 1px solid var(--oliver-blue);
    border-top: 0;
    border-radius: 0 0 6px 6px;
    overflow: auto;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open
    > .option-container {
    display: block;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option {
    box-sizing: border-box;
    width: 100%;
    height: 42px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    border-radius: 0 0 6px 6px;
    border-top: 1px solid var(--oliver-blue);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option:nth-child(1) {
    border-top: 0 !important;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option
    > p {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option:hover {
    cursor: pointer;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option:hover
    > p {
    color: var(--oliver-blue);
  }

  .subwindow.edit-customer > .subwindow-body > div > .title-row {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .subwindow.edit-customer > .subwindow-body > div > .title-row > p {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 23px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-right: auto;
  }

  .subwindow.edit-customer > .subwindow-body > div > .title-row > label {
    display: flex;
    align-items: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow.edit-customer > .subwindow-body > div > .title-row > label:hover {
    cursor: pointer;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > input {
    position: fixed;
    left: -10000px;
    opacity: 0;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > .custom-checkbox {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 4px;
    margin-right: 12px;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > .custom-checkbox
    > img {
    width: 10px;
    height: 10px;
    display: none;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > input:checked
    ~ .custom-checkbox {
    border-color: var(--oliver-blue);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > input:checked
    ~ .custom-checkbox
    > img {
    display: block;
  }

  .subwindow.edit-customer > .subwindow-body > .button-row {
    width: 100%;
    display: flex;
    margin-top: 30px;
  }

  .subwindow.edit-customer > .subwindow-body > .button-row > button {
    flex: 1;
    margin-left: 14px;
    height: 44px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > .button-row
    > button:first-of-type {
    margin-left: 0 !important;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > .button-row
    > button#editCustomerDelete {
    background-color: #fff;
    color: var(--error);
    border: 1px solid var(--error);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > .button-row
    > button#editCustomerDelete
    > img {
    width: 20px;
    height: 21px;
    margin-right: 10px;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > .button-row
    > button#editCustomerDelete.disabled {
    border-color: var(--oliver-new-grey);
    color: var(--oliver-new-grey);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > .button-row
    > button#editCustomerDelete.disabled
    > img {
    filter: brightness(0) saturate(100%) invert(78%) sepia(14%) saturate(234%)
      hue-rotate(164deg) brightness(95%) contrast(92%);
  }

  .subwindow.edit-customer > .subwindow-body > div > .input-row.hidden {
    display: none;
  }

  /* Delete Customer Confirm */

  .subwindow.delete-customer-confirm {
    width: min(504px, 95%);
    height: min(295px, 95%);
  }

  .subwindow.delete-customer-confirm > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow.delete-customer-confirm > .subwindow-body > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 26px;
  }

  .subwindow.delete-customer-confirm > .subwindow-body > p > br {
    display: none;
  }

  .subwindow.delete-customer-confirm > .subwindow-body > button {
    width: 170px;
    height: 48px;
    border-radius: 6px;
    box-sizing: border-box;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }

  .subwindow.delete-customer-confirm > .subwindow-body > button#deleteCustomer {
    background-color: var(--oliver-red);
    border: 0;
    color: #fff;
    margin-bottom: 17px;
  }

  .subwindow.delete-customer-confirm
    > .subwindow-body
    > button#cancelDeleteCustomer {
    border: 1px solid var(--oliver-blue);
    background-color: #fff;
    color: var(--oliver-blue);
  }

  /* Custom Fee-Cart Discount */

  .subwindow.custom-fee {
    width: 504px;
    height: min(544px, 95%);
  }

  .subwindow.cart-discount {
    width: 504px;
    height: 758px;
  }

  .subwindow.custom-fee > .subwindow-body,
  .subwindow.cart-discount > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow.cart-discount > .subwindow-body > .auto-margin-top {
    margin: 0 !important;
  }

  .subwindow.custom-fee > .subwindow-body > #cartDiscountDiv {
    display: none;
  }

  .subwindow.cart-discount > .subwindow-body > #customFeeDiv {
    display: none;
  }

  .subwindow.custom-fee > .subwindow-body > p,
  .subwindow.cart-discount > .subwindow-body > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-grey);
    margin-bottom: 9px;
  }

  .subwindow.custom-fee > .subwindow-body > .toggle-container,
  .subwindow.cart-discount > .subwindow-body > .toggle-container {
    width: 285px;
    height: 50px;
    background-color: #fff;
    border: 1px solid var(--oliver-border);
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 6px;
    box-sizing: border-box;
    margin-bottom: 24px;
  }

  .subwindow.custom-fee > .subwindow-body > .toggle-container > label,
  .subwindow.cart-discount > .subwindow-body > .toggle-container > label {
    flex: 1;
    height: 100%;
    border: 0;
  }

  .subwindow.custom-fee > .subwindow-body > .toggle-container > label:hover,
  .subwindow.cart-discount > .subwindow-body > .toggle-container > label:hover {
    cursor: pointer;
  }

  .subwindow.custom-fee > .subwindow-body > .toggle-container > label > input,
  .subwindow.cart-discount
    > .subwindow-body
    > .toggle-container
    > label
    > input {
    position: fixed;
    left: -10000px;
    opacity: 0;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > .toggle-container
    > label
    > .custom-radio,
  .subwindow.cart-discount
    > .subwindow-body
    > .toggle-container
    > label
    > .custom-radio {
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-grey);
  }

  .subwindow.custom-fee
    > .subwindow-body
    > .toggle-container
    > label
    > input:checked
    + .custom-radio,
  .subwindow.cart-discount
    > .subwindow-body
    > .toggle-container
    > label
    > input:checked
    + .custom-radio {
    background-color: var(--oliver-blue);
    color: #fff;
  }

  .subwindow.custom-fee > .subwindow-body > #customFeeDiv {
    width: 348px;
    display: flex;
    flex-direction: column;
  }

  .subwindow.custom-fee > .subwindow-body > #customFeeDiv > label {
    width: 100%;
    padding-bottom: 8px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .subwindow.custom-fee > .subwindow-body > #customFeeDiv > label:hover {
    cursor: pointer;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > input#customFeeLabel {
    width: 100%;
    height: 44px;
    margin-bottom: 14px;
    box-sizing: border-box;
    padding: 0 12px;
    border-radius: 6px;
    border: 1px solid var(--oliver-border);
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > input#customFeeLabel:hover {
    border-color: var(--oliver-blue);
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > input#customFeeLabel:focus {
    border-color: var(--oliver-blue);
    border-width: 2px;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > input#customFeeAmount {
    width: 100%;
    height: 83px;
    border-radius: 6px;
    border: 1px solid var(--oliver-border);
    padding: 0 12px;
    box-sizing: border-box;
    font-family: Poppins;
    font-size: 40px;
    font-weight: 400;
    line-height: 56px;
    text-align: center;
    color: var(--oliver-blue);
    margin-bottom: 8px;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > input#customFeeAmount:hover {
    border-color: var(--oliver-blue);
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > input#customFeeAmount:focus {
    border-color: var(--oliver-blue);
    border-width: 2px;
  }

  .subwindow.custom-fee > .subwindow-body > #customFeeDiv > p {
    width: 100%;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-bottom: 20px;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > label.custom-toggle-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: var(--oliver-base-blue);
    padding-bottom: 30px;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > label.custom-toggle-wrapper
    > input {
    position: fixed;
    left: -10000px;
    opacity: 0;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > label.custom-toggle-wrapper
    > .custom-toggle {
    width: 40px;
    height: 25px;
    border-radius: 25px;
    background-color: var(--oliver-spacer);
    margin-left: 20px;
    position: relative;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > label.custom-toggle-wrapper
    > .custom-toggle::after {
    position: absolute;
    top: calc(50% - 11px);
    left: 1.5px;
    content: "";
    width: 22px;
    height: 22px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15), 0px 2px 1px rgba(0, 0, 0, 0.06);
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > label.custom-toggle-wrapper
    > input:checked
    ~ .custom-toggle {
    background-color: var(--oliver-blue);
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > label.custom-toggle-wrapper
    > input:checked
    ~ .custom-toggle::after {
    left: auto;
    right: 1.5px;
  }

  .subwindow.custom-fee > .subwindow-body > #customFeeDiv > button {
    width: 100%;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  .subwindow.cart-discount > .subwindow-body > #cartDiscountDiv > .main {
    width: 100%;
    padding: 22px 0 30px 0;
    border-top: 1px solid var(--oliver-border);
    border-bottom: 1px solid var(--oliver-border);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > label {
    width: 348px;
    padding-bottom: 9px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > label:hover {
    cursor: pointer;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > label:hover
    + input {
    border-color: var(--oliver-blue);
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > input {
    width: 348px;
    height: 83px;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    padding: 0 12px;
    box-sizing: border-box;
    font-family: Poppins;
    font-size: 40px;
    font-weight: 400;
    line-height: 56px;
    text-align: center;
    color: var(--oliver-blue);
    margin-bottom: 23px;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > input:hover {
    border-color: var(--oliver-blue);
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > input:focus {
    border-color: var(--oliver-blue);
    border-width: 2px;
  }

  .subwindow.cart-discount > .subwindow-body > #cartDiscountDiv > .main > p {
    width: 348px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-bottom: 10px;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > .button-row {
    width: 348px;
    display: flex;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > .button-row
    > button {
    flex: 1;
    height: 44px;
    border: 0;
    border-radius: 6px;
    background-color: var(--oliver-blue);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin-left: 16px;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > .button-row
    > button:first-of-type {
    margin-left: 0;
  }

  .subwindow.cart-discount > .subwindow-body > #cartDiscountDiv > .list {
    padding-top: 25px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow.cart-discount > .subwindow-body > #cartDiscountDiv > .list > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-grey);
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .list
    > button {
    width: 285px;
    height: 44px;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    margin-top: 15px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .list
    > button
    > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
    max-width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .list
    > button#clearCartDiscountDiv {
    background-color: transparent;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .list
    > button#clearCartDiscountDiv
    > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-grey);
  }

  /* Add Tile */

  .subwindow-wrapper > .subwindow.add-tile {
    width: 504px;
    height: min(420px, 95%);
  }

  .subwindow-wrapper > .subwindow.add-tile > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
  }

  .subwindow-wrapper > .subwindow.add-tile > .subwindow-body > label {
    width: 432px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-bottom: 8px;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search {
    width: 432px;
    height: 44px;
    margin-bottom: 28px;
    z-index: 2;
    position: relative;
    line-height: 0;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > img.search {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 10px;
    top: 10px;
    filter: brightness(0) saturate(100%) invert(81%) sepia(4%) saturate(573%)
      hue-rotate(169deg) brightness(87%) contrast(85%);
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > button#cancelDropdownSearch {
    display: none;
    position: absolute;
    width: 18px;
    height: 18px;
    right: 15px;
    top: 14.5px;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 0;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > button#cancelDropdownSearch
    > img {
    width: 100%;
    height: 100%;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search.open
    > img.search {
    filter: none;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search.open
    > button#cancelDropdownSearch {
    display: flex;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > input {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    padding: 0 38px 0 40px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search.open
    > input {
    border-radius: 6px 6px 0 0;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > input.error {
    border-color: var(--error);
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .error-message {
    display: none;
    position: absolute;
    top: -8px;
    right: 0;
    transform: translateY(-100%);
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .error-message::after {
    content: "*Error";
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--error);
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > input.error
    + .error-message {
    display: block;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > input.error.duplicate
    + .error-message::after {
    content: "*This product has already been added";
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .option-container {
    display: none;
    width: 100%;
    /* height: fit-content; */
    max-height: 400%;
    border-radius: 0 0 6px 6px;
    overflow-y: scroll;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search.open
    > .option-container {
    display: block;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .option-container:hover {
    cursor: pointer;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .option-container
    > .dropdown-option {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 12px;
    background-color: #fff;
    border: 1px solid var(--oliver-border);
    border-top: 0;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .option-container
    > .dropdown-option
    > p {
    max-width: 100%;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .option-container
    > .dropdown-option:hover {
    background-color: var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .option-container
    > .dropdown-option:hover
    > p {
    color: #fff;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .dropdown-option:last-child {
    border-radius: 0 0 6px 6px;
  }

  .subwindow-wrapper > .subwindow.add-tile > .subwindow-body > p {
    width: 432px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-bottom: 10px;
  }

  .subwindow-wrapper > .subwindow.add-tile > .subwindow-body > .radio-group {
    display: flex;
    margin-bottom: 39px;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .radio-group
    > label {
    margin-right: 15px;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .radio-group
    > label:hover {
    cursor: pointer;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .radio-group
    > label:last-of-type {
    margin-right: 0;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .radio-group
    > label
    > input[type="radio"] {
    position: fixed;
    left: -10000px;
    opacity: 0;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .radio-group
    > label
    > .custom-radio-button {
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: #fff;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .radio-group
    > label
    > input[type="radio"]:checked
    ~ .custom-radio-button {
    border: 2px solid #000 !important;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .radio-group
    > label
    > input[type="radio"]:disabled
    ~ .custom-radio-button {
    opacity: 0.25;
  }

  .subwindow-wrapper > .subwindow.add-tile > .subwindow-body > button {
    width: 432px;
    height: 44px;
    border: 0;
    border-radius: 6px;
    background-color: var(--oliver-blue);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  .subwindow-wrapper > .subwindow.add-tile > .subwindow-body > button:disabled {
    background-color: var(--oliver-new-grey);
  }

  /* Add Order Note */

  .subwindow-wrapper > .subwindow.add-order-note {
    width: 504px;
    height: min(420px, 95%);
  }

  .subwindow-wrapper > .subwindow.add-order-note > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper > .subwindow.add-order-note > .subwindow-body > label {
    width: 424px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-bottom: 9px;
  }

  .subwindow-wrapper > .subwindow.add-order-note > .subwindow-body > textarea {
    box-sizing: border-box;
    width: 424px;
    height: 189px;
    outline: 0;
    resize: none;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    padding: 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 20px;
  }

  .subwindow-wrapper
    > .subwindow.add-order-note
    > .subwindow-body
    > textarea:focus {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.add-order-note > .subwindow-body > button {
    width: 424px;
    height: 48px;
    border: 0;
    border-radius: 6px;
    background-color: var(--oliver-blue);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Product not Found */

  .subwindow-wrapper > .subwindow.product-not-found {
    width: 504px;
    height: min(400px, 95%);
  }

  .subwindow-wrapper > .subwindow.product-not-found > .subwindow-body {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper
    > .subwindow.product-not-found
    > .subwindow-body
    > p.style1 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 24px;
  }

  .subwindow-wrapper > .subwindow.product-not-found > .subwindow-body > img {
    width: 81px;
    height: 52px;
    margin-bottom: 40px;
    transform: translateX(10px);
  }

  .subwindow-wrapper
    > .subwindow.product-not-found
    > .subwindow-body
    > p.style2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 36px;
  }

  .subwindow-wrapper > .subwindow.product-not-found > .subwindow-body > button {
    width: 198px;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Upgrade to Unlock */

  .subwindow-wrapper > .subwindow.upgrade-to-unlock {
    width: 504px;
    height: min(400px, 95%);
  }

  .subwindow-wrapper > .subwindow.upgrade-to-unlock > .subwindow-body {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper > .subwindow.upgrade-to-unlock > .subwindow-body > img {
    width: 50px;
    height: 50px;
    margin-bottom: 25px;
  }

  .subwindow-wrapper
    > .subwindow.upgrade-to-unlock
    > .subwindow-body
    > p.style1 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 36px;
  }

  .subwindow-wrapper
    > .subwindow.upgrade-to-unlock
    > .subwindow-body
    > p.style2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 44px;
  }

  .subwindow-wrapper > .subwindow.upgrade-to-unlock > .subwindow-body > button {
    width: 198px;
    height: 50px;
    border: 0;
    border-radius: 6px;
    background-color: var(--oliver-blue);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Advanced Search */

  .subwindow-wrapper > .subwindow.advanced-search {
    width: 840px;
    height: 95%;
  }

  .subwindow-wrapper > .subwindow.advanced-search > .subwindow-header {
    position: relative;
    width: 100%;
    height: 127px;
    background-color: #f1f4f6;
    z-index: 2;
    align-items: center;
    justify-content: center;
    border-radius: 8px 8px 0 0;
  }

  .subwindow-wrapper > .subwindow.advanced-search > .subwindow-header > p {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 500;
    line-height: 33px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 22px;
  }

  .subwindow-wrapper > .subwindow.advanced-search > .subwindow-header > button {
    width: 32px;
    height: 32px;
    top: 16px;
    right: 16px;
    background-color: #fff;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper {
    position: absolute;
    width: 760px;
    height: 55px;
    top: 99px;
    left: 40px;
    filter: drop-shadow(0px 15px 30px rgba(0, 0, 0, 0.1));
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > #advSearchInputIcon {
    width: 30px;
    height: 29px;
    position: absolute;
    top: 13px;
    left: 11px;
    filter: brightness(0) saturate(100%) invert(72%) sepia(7%) saturate(349%)
      hue-rotate(169deg) brightness(95%) contrast(92%);
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > #advSearchInputCancel {
    opacity: 0;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 11px;
    top: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 0;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > #advSearchInputCancel:hover {
    cursor: default;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > #advSearchInputCancel
    > img {
    width: 17.5px;
    height: 17.5px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > input {
    width: 100%;
    height: 100%;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    padding: 0 52px 0 52px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > input:focus {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > input:focus
    ~ #advSearchInputIcon {
    filter: none;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > input:focus
    ~ #advSearchInputCancel {
    opacity: 1;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > input:focus
    ~ #advSearchInputCancel:hover {
    cursor: pointer;
  }

  .subwindow-wrapper > .subwindow.advanced-search > .subwindow-body {
    display: flex;
    padding: 0;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col {
    width: 260px;
    box-sizing: border-box;
    padding-top: 74px;
    border-right: 1px solid var(--oliver-border);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > p {
    width: 196px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 15px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > p:last-of-type {
    margin-bottom: 8px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group {
    width: 196px;
    display: flex;
    flex-direction: column;
    margin-bottom: 33px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > .dropdown-input {
    display: none;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > label {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > label:hover {
    cursor: pointer;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > label
    > input[type="radio"] {
    position: fixed;
    left: -10000px;
    opacity: 0;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > label
    > .custom-radio {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid var(--oliver-border);
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > label
    > .custom-radio
    > img {
    width: 8px;
    height: 8px;
    display: none;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > label
    > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > label
    > input[type="radio"]:checked
    ~ .custom-radio {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > label
    > input[type="radio"]:checked
    ~ .custom-radio
    > img {
    display: block;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > label
    > input[type="radio"]:checked
    ~ .custom-radio
    ~ p {
    color: var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .recent-searches {
    width: 196px;
    display: flex;
    flex-direction: column;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .recent-searches
    > a {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: var(--oliver-blue);
    margin-bottom: 8px;
    text-decoration: none;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .recent-searches
    > a:last-of-type {
    margin-bottom: 0;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .recent-searches
    > a:hover {
    text-decoration: underline;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col {
    padding-top: 54px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .start-searching {
    display: none;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding-bottom: 54px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .start-searching:only-child {
    display: flex;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .start-searching
    > img {
    width: 43px;
    height: 42px;
    margin: auto 0 30px 0;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .start-searching
    > p.style1 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 20px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .start-searching
    > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: auto;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .header {
    border-bottom: 1px solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .header
    > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--oliver-base-blue);
    margin: 0 0 14px 22px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .header
    > p
    > b {
    font-weight: 600;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body {
    width: 100%;
    height: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 22px;
    overflow: auto;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results {
    display: none;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results:only-child {
    display: flex;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results
    > p.style1 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 5px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results
    > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results
    > .divider {
    width: 100px;
    height: 1px;
    background-color: var(--oliver-border);
    margin: 30px 0;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results
    > button {
    width: 225px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--oliver-blue);
    border-radius: 6px;
    background-color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: var(--oliver-blue);
    margin-top: 10px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results
    > button
    > img {
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result {
    width: 100%;
    height: 148px;
    border-radius: 6px;
    box-sizing: border-box;
    padding: 18px 20px;
    background-color: #ebf6ff;
    display: flex;
    align-items: center;
    margin-top: 14px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result:nth-child(2) {
    margin-top: 0;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result.customer {
    background-color: #ebf6ff;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result.product {
    background-color: #f1f4f6;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result.group {
    background-color: #fff5f1;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .col {
    width: 0;
    flex-grow: 1;
    height: 100%;
    margin-right: auto;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .col
    > p {
    max-width: 95%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .col
    > p:last-of-type {
    margin-bottom: 0;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .col
    > p.style1 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-blue);
    margin-bottom: 13px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .col
    > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 4px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .col
    > p.style3 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 4px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row {
    display: flex;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button {
    width: 70px;
    height: 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    text-align: center;
    color: #fff;
    margin-right: 8px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button:last-of-type {
    margin-right: 0;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-view {
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-view
    > img {
    width: 20px;
    height: 20px;
    margin-bottom: 3px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-transactions {
    font-size: 8px;
    font-weight: 500;
    line-height: 11px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-transactions
    > img {
    width: 24px;
    height: 24px;
    margin-bottom: 3px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-add-to-sale {
    font-size: 8px;
    font-weight: 500;
    line-height: 11px;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-add-to-sale
    > img {
    width: 24px;
    height: 24px;
    margin-bottom: 3px;
  }

  /* Switch Users */

  .subwindow-wrapper > .subwindow.switch-user {
    width: 504px;
    height: min(780px, 95%);
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > button.mobile-close-subwindow {
    display: none;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > .group {
    display: flex;
    align-items: center;
    margin-bottom: 17px;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > .group > img {
    width: 19px;
    height: 20px;
    margin-right: 11px;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > .group > p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > p.style1 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 24px;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > .divider {
    width: 65px;
    height: 1px;
    margin-bottom: 24px;
    background-color: var(--oliver-border);
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > p.style2 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 24px;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > .pinpad {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 278px;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > p.error-message {
    padding: 11px 28px;
    background-color: #ffe7e8;
    border-radius: 8px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--error);
    margin: -10px 0 20px 0;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-entries {
    display: flex;
    margin-bottom: 17px;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-entries
    > .pin-entry {
    width: 16px;
    height: 16px;
    background-color: #b0bec9;
    margin-left: 10px;
    border-radius: 50%;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-entries
    > .pin-entry:first-child {
    margin: 0;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-entries
    > .pin-entry.entered {
    background-color: var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-entries.incorrect {
    animation: shake 0.3s;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-entries.incorrect
    > .pin-entry {
    background-color: var(--oliver-red);
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row {
    width: 100%;
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row
    > button {
    width: 82px;
    height: 82px;
    box-sizing: border-box;
    border: 1px solid #b0bec9;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row:last-child
    > button:first-child {
    margin-left: auto;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row
    > button.backspace {
    background-color: transparent;
    border: 0;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row
    > button.backspace
    > img {
    width: 50px;
    height: 40px;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row
    > button
    > p {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row
    > button
    > .text-row {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row
    > button
    > .text-row.no-opacity {
    opacity: 0;
  }

  /* End Session */

  .subwindow-wrapper > .subwindow.end-session {
    width: 504px;
    height: min(400px, 95%);
  }

  .subwindow-wrapper > .subwindow.end-session > .subwindow-body {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper
    > .subwindow.end-session
    > .subwindow-body
    > button.mobile-close-subwindow {
    display: none;
  }

  .subwindow-wrapper > .subwindow.end-session > .subwindow-body > p.style1 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 17px;
  }

  .subwindow-wrapper > .subwindow.end-session > .subwindow-body > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 35px;
  }

  .subwindow-wrapper > .subwindow.end-session > .subwindow-body > button {
    width: 230px;
    height: 50px;
    border: 0;
    background-color: var(--oliver-red);
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Partial Payments */

  .subwindow-wrapper > .subwindow.partial-payment {
    width: 504px;
    height: min(536px, 95%);
  }

  .subwindow-wrapper > .subwindow.partial-payment > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper > .subwindow.partial-payment > .subwindow-body > label {
    width: 348px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-bottom: 9px;
  }

  .subwindow-wrapper > .subwindow.partial-payment > .subwindow-body > input {
    width: 348px;
    height: 83px;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    font-family: Poppins;
    font-size: 40px;
    font-weight: 400;
    line-height: 56px;
    text-align: center;
    color: var(--oliver-blue);
    margin-bottom: 35px;
  }

  .subwindow-wrapper
    > .subwindow.partial-payment
    > .subwindow-body
    > input:hover,
  .subwindow-wrapper
    > .subwindow.partial-payment
    > .subwindow-body
    > input:focus {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.partial-payment > .subwindow-body > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 12px;
  }

  .subwindow-wrapper
    > .subwindow.partial-payment
    > .subwindow-body
    > .payment-types {
    width: 348px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }

  .subwindow-wrapper
    > .subwindow.partial-payment
    > .subwindow-body
    > .payment-types
    > button {
    width: 106px;
    height: 106px;
    margin: 0 13px 13px 0;
    border: 0;
    border-radius: 8px;
    background-color: var(--oliver-primary);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #fff;
    overflow: hidden;
  }

  .subwindow-wrapper
    > .subwindow.partial-payment
    > .subwindow-body
    > .payment-types
    > button:nth-child(3n) {
    margin-right: 0;
  }

  .subwindow-wrapper
    > .subwindow.partial-payment
    > .subwindow-body
    > .payment-types
    > button
    > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* Product Discount */

  .subwindow-wrapper > .subwindow.product-discount {
    width: 504px;
    height: min(420px, 95%);
  }

  .subwindow-wrapper > .subwindow.product-discount > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper > .subwindow.product-discount > .subwindow-body > label {
    width: 348px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-bottom: 9px;
  }

  .subwindow-wrapper > .subwindow.product-discount > .subwindow-body > input {
    width: 348px;
    height: 83px;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    outline: 0;
    font-family: Poppins;
    font-size: 40px;
    font-weight: 400;
    line-height: 56px;
    text-align: center;
    color: var(--oliver-blue);
    margin-bottom: 23px;
  }

  .subwindow-wrapper
    > .subwindow.product-discount
    > .subwindow-body
    > input:focus {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.product-discount > .subwindow-body > p {
    width: 348px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-bottom: 10px;
  }

  .subwindow-wrapper
    > .subwindow.product-discount
    > .subwindow-body
    > .button-row {
    width: 348px;
    display: flex;
  }

  .subwindow-wrapper
    > .subwindow.product-discount
    > .subwindow-body
    > .button-row
    > button {
    flex: 1;
    height: 44px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
  }

  .subwindow-wrapper
    > .subwindow.product-discount
    > .subwindow-body
    > .button-row
    > button:first-of-type {
    margin-right: 16px;
  }

  /* Adjust Inventory */

  .subwindow-wrapper > .subwindow.adjust-inventory {
    width: 504px;
    height: min(531px, 95%);
  }

  .subwindow-wrapper > .subwindow.adjust-inventory > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper > .subwindow.adjust-inventory > .subwindow-body > p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 11px;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > p:first-of-type {
    margin-top: 11px;
  }

  .subwindow-wrapper > .subwindow.adjust-inventory > .subwindow-body > label {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 7px;
  }

  .subwindow-wrapper > .subwindow.adjust-inventory > .subwindow-body > input {
    outline: 0;
    width: 250px;
    height: 83px;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    font-family: Poppins;
    font-size: 40px;
    font-weight: 600;
    line-height: 56px;
    text-align: center;
    color: var(--oliver-base-blue);
    cursor: default;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > input:focus {
    cursor: auto;
    color: var(--oliver-blue);
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > button#editStockButton {
    display: flex;
    align-items: center;
    padding: 7px 10px;
    background-color: transparent;
    border: 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-blue);
    margin-bottom: 17px;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > button#editStockButton
    > img {
    width: 19px;
    height: 19px;
    margin-right: 5px;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > button#updateStockButton {
    width: 427px;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin-bottom: 27px;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > .text-row {
    width: 427px;
    display: flex;
    align-items: center;
    margin-top: 19px;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > p
    + .text-row {
    margin-top: 14px;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > .text-row
    > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > .text-row
    > p
    > b {
    font-weight: 600;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > .text-row
    > p:first-of-type {
    margin-right: 20px;
    max-width: 213px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  /* Product out of Stock */

  .subwindow-wrapper > .subwindow.out-of-stock {
    width: 504px;
    height: min(350px, 95%);
  }

  .subwindow-wrapper > .subwindow.out-of-stock > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
  }

  .subwindow-wrapper > .subwindow.out-of-stock > .subwindow-body > p.style1 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    margin-bottom: 24px;
  }

  .subwindow-wrapper > .subwindow.out-of-stock > .subwindow-body > img {
    width: 69px;
    height: 69px;
    margin-bottom: 13px;
  }

  .subwindow-wrapper > .subwindow.out-of-stock > .subwindow-body > p.style2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 30px;
  }

  .subwindow-wrapper
    > .subwindow.out-of-stock
    > .subwindow-body
    > p.style2
    > button {
    background-color: transparent;
    border: 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.out-of-stock
    > .subwindow-body
    > p.style2
    > button:hover {
    text-decoration: underline;
  }

  .subwindow-wrapper > .subwindow.out-of-stock > .subwindow-body > button {
    width: 198px;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    color: #fff;
  }

  /* Product Note */

  .subwindow-wrapper > .subwindow.product-note {
    width: 504px;
    height: min(420px, 95%);
  }

  .subwindow-wrapper > .subwindow.product-note > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper > .subwindow.product-note > .subwindow-body > label {
    width: 424px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-bottom: 9px;
  }

  .subwindow-wrapper > .subwindow.product-note > .subwindow-body > textarea {
    width: 424px;
    height: 189px;
    resize: none;
    outline: 0;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    padding: 1.005vw;
    box-sizing: border-box;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 20px;
  }

  .subwindow-wrapper
    > .subwindow.product-note
    > .subwindow-body
    > textarea:focus {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.product-note > .subwindow-body > button {
    width: 424px;
    height: 48px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* No Variations Selected */

  .subwindow-wrapper > .subwindow.no-variation-selected {
    width: 504px;
    height: min(350px, 95%);
  }

  .subwindow-wrapper > .subwindow.no-variation-selected > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
  }

  .subwindow-wrapper
    > .subwindow.no-variation-selected
    > .subwindow-body
    > p.style1 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 24px;
  }

  .subwindow-wrapper
    > .subwindow.no-variation-selected
    > .subwindow-body
    > img {
    width: 61px;
    height: 61px;
    margin-bottom: 21px;
  }

  .subwindow-wrapper
    > .subwindow.no-variation-selected
    > .subwindow-body
    > p.style2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 30px;
  }

  .subwindow-wrapper
    > .subwindow.no-variation-selected
    > .subwindow-body
    > button {
    width: 198px;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Park Sale */

  .subwindow-wrapper > .subwindow.park-sale {
    width: 504px;
    height: min(420px, 95%);
  }

  .subwindow-wrapper > .subwindow.park-sale > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper > .subwindow.park-sale > .subwindow-body > label {
    width: 425px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: var(--oliver-base-grey);
    padding-bottom: 6px;
  }

  .subwindow-wrapper > .subwindow.park-sale > .subwindow-body > textarea {
    width: 425px;
    height: 181px;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    outline: 0;
    resize: none;
    padding: 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 38px;
  }

  .subwindow-wrapper > .subwindow.park-sale > .subwindow-body > textarea:focus {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.park-sale > .subwindow-body > button {
    width: 425px;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    margin-bottom: 10px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Split by Group (TODO) */

  .subwindow-wrapper > .subwindow.split-by-group {
  }

  /* Split by Product */

  .subwindow-wrapper > .subwindow.split-by-product {
    width: 504px;
    height: min(502px, 95%);
  }

  .subwindow-wrapper > .subwindow.split-by-product > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .subwindow-wrapper > .subwindow.split-by-product > .subwindow-body > .body {
    height: 0;
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-top: 25px;
    overflow-y: scroll;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row {
    width: 423px;
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    border-top: 1px solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row:nth-child(1) {
    padding-top: 0;
    border: 0;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .main-row {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .main-row
    > .text-group {
    margin-right: auto;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .main-row
    > .text-group
    > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .main-row
    > .increment-input {
    width: 148px;
    height: 44px;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    display: flex;
    border-radius: 6px;
    background-color: #fff;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .main-row
    > .increment-input
    > button {
    width: 25%;
    height: 100%;
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .main-row
    > .increment-input
    > button
    > img {
    width: 20px;
    height: 20px;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .main-row
    > .increment-input
    > input {
    border: 0;
    outline: 0;
    width: 50%;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .secondary-row {
    width: 100%;
    margin-top: 13px;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .secondary-row
    > p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .subwindow-wrapper > .subwindow.split-by-product > .subwindow-body > .footer {
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-top: 1px solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .footer
    > button {
    width: 425px;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Cash Payment */

  .subwindow-wrapper > .subwindow.cash-payment.step-1 {
    width: 504px;
    height: min(709px, 95%);
  }

  .subwindow-wrapper > .subwindow.cash-payment.step-2 {
    width: 504px;
    height: 500px;
  }

  .subwindow-wrapper > .subwindow.cash-payment > .subwindow-body > .step1 {
    width: 100%;
    height: 100%;
    display: none;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment.step-1
    > .subwindow-body
    > .step1 {
    display: flex;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .text-row {
    width: 321px;
    display: flex;
    align-items: center;
    margin-bottom: 26px;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .text-row
    > p.style1 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-right: auto;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .text-row
    > p.style2 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    text-align: right;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background-color: rgba(212, 222, 229, 0.5);
    margin-bottom: 18px;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > .input-container {
    width: 289px;
    height: 93px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 6px;
    margin-bottom: 9px;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > .input-container
    > label {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: center;
    color: var(--oliver-base-grey);
    margin-bottom: 10px;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > .input-container
    > input {
    width: 193px;
    height: 42px;
    outline: 0;
    border: 0;
    background-color: transparent;
    font-family: Poppins;
    font-size: 40px;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > #numpad2 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > #numpad2
    > .button-row {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 9px;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > #numpad2
    > .button-row:nth-child(1) {
    margin: 0;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > #numpad2
    > .button-row
    > button {
    width: 90px;
    height: 68px;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    margin-left: 9px;
    background-color: #fff;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 400;
    line-height: 45px;
    text-align: center;
    color: var(--oliver-base-blue);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > #numpad2
    > .button-row
    > button:first-of-type {
    margin-left: 0;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > #numpad2
    > .button-row
    > button.backspace
    > img {
    width: 40px;
    height: 40px;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > button {
    width: 321px;
    height: 60px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > button:disabled {
    background-color: var(--oliver-border);
  }

  .subwindow-wrapper > .subwindow.cash-payment > .subwindow-body > .step2 {
    width: 100%;
    height: 100%;
    display: none;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment.step-2
    > .subwindow-body
    > .step2 {
    display: flex;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment.step-2
    > .subwindow-body
    > .step2
    > .text-row {
    width: 370px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.cash-payment.step-2
    > .subwindow-body
    > .step2
    > .text-row:nth-child(2) {
    border: 0;
    padding: 0;
    margin-top: 0;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment.step-2
    > .subwindow-body
    > .step2
    > .text-row
    > p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.cash-payment.step-2
    > .subwindow-body
    > .step2
    > .text-row
    > p
    > b {
    font-weight: 600;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment.step-2
    > .subwindow-body
    > .step2
    > button {
    width: 370px;
    height: 60px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    margin-top: 50px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  .subwindow-wrapper > .subwindow.email-receipt {
    display: none;
  }

  /* Quick Tax */

  .subwindow-wrapper > .subwindow.quick-tax {
    right: 50px;
    bottom: 50px;
    width: 300px;
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-header {
    justify-content: space-between;
    height: 55px;
    padding: 0 11px 0 22px;
    min-height: 0;
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-header > P {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #243249;
    flex-grow: 1;
    width: 0;
    max-width: max-content;
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-header > button {
    border: 0;
    background-color: transparent;
    padding: 11px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-blue);
    text-decoration: underline;
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-body {
    height: fit-content;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 22px;
    box-sizing: border-box;
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-body > .row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
  }

  .subwindow-wrapper
    > .subwindow.quick-tax
    > .subwindow-body
    > .row:nth-child(1) {
    margin-top: 0;
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-body > .row > p {
    width: 0;
    flex-grow: 1;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.quick-tax
    > .subwindow-body
    > .row
    > label:hover {
    cursor: pointer;
  }

  .subwindow-wrapper
    > .subwindow.quick-tax
    > .subwindow-body
    > .row
    > label
    > input {
    opacity: 0;
    position: fixed;
    left: -10000px;
  }

  .subwindow-wrapper
    > .subwindow.quick-tax
    > .subwindow-body
    > .row
    > label
    > .custom-toggle {
    position: relative;
    width: 40px;
    height: 25px;
    background-color: #b0bec9;
    border-radius: 25px;
    line-height: 0;
  }

  .subwindow-wrapper
    > .subwindow.quick-tax
    > .subwindow-body
    > .row
    > label
    > .custom-toggle
    > .knob {
    box-sizing: border-box;
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #fff;
    top: calc(50% - 11px);
    left: 1.5px;
    border: 0.392157px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0px 2.35294px 6.27451px rgba(0, 0, 0, 0.15),
      0px 2.35294px 0.784314px rgba(0, 0, 0, 0.06);
  }

  .subwindow-wrapper
    > .subwindow.quick-tax
    > .subwindow-body
    > .row
    > label
    > input:checked
    ~ .custom-toggle {
    background-color: var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.quick-tax
    > .subwindow-body
    > .row
    > label
    > input:checked
    ~ .custom-toggle
    > .knob {
    left: auto;
    right: 1.5px;
  }

  /* Detailed Tax */

  .subwindow-wrapper > .subwindow.detailed-tax {
    width: 744px;
    height: min(735px, 95%);
  }

  .subwindow-wrapper > .subwindow.detailed-tax > .subwindow-body {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .subwindow-wrapper > .subwindow.detailed-tax > .subwindow-body > .header-row {
    width: 100%;
    height: 45px;
    box-sizing: border-box;
    padding: 0 22px;
    border-bottom: 1px solid var(--oliver-border);
    display: flex;
    align-items: center;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .header-row
    > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .header-row
    > :nth-child(1) {
    width: 29.2857%;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .header-row
    > :nth-child(2) {
    width: 16.8571%;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .header-row
    > :nth-child(3) {
    width: 17.8571%;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .header-row
    > :nth-child(4) {
    width: 29.5714%;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .header-row
    > :nth-child(5) {
    width: 6.4287%;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container {
    width: 100%;
    height: 0;
    flex-grow: 1;
    overflow-y: scroll;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row {
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    padding: 0 22px;
    background-color: #f1f9ff;
    display: flex;
    align-items: center;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row:nth-child(2n) {
    background-color: transparent;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > :nth-child(1) {
    width: 29.2857%;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > :nth-child(2) {
    width: 16.8571%;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > :nth-child(3) {
    width: 17.8571%;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > :nth-child(4) {
    width: 29.5714%;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > :nth-child(5) {
    width: 6.4287%;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > p {
    box-sizing: border-box;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-base-blue);
    padding-right: 15px;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > .checkbox-wrapper {
    display: flex;
    justify-content: center;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > .checkbox-wrapper:hover {
    cursor: pointer;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > .checkbox-wrapper
    > input {
    position: fixed;
    left: -10000px;
    opacity: 0;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > .checkbox-wrapper
    > .custom-checkbox {
    width: 18px;
    height: 18px;
    border: 1px solid var(--oliver-border);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > .checkbox-wrapper
    > .custom-checkbox
    > img {
    display: none;
    width: 12px;
    height: 9.46px;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > .checkbox-wrapper
    > input:checked
    ~ .custom-checkbox
    > img {
    display: block;
  }

  .subwindow-wrapper > .subwindow.detailed-tax > .subwindow-body > .footer {
    width: 100%;
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-top: 1px solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .footer
    > button {
    width: 280px;
    height: 44px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
  }

  /* Add Customer Note */

  .subwindow-wrapper > .subwindow.add-customer-note {
    width: 504px;
    height: min(420px, 95%);
  }

  .subwindow-wrapper > .subwindow.add-customer-note > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper > .subwindow.add-customer-note > .subwindow-body > label {
    width: 424px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-bottom: 9px;
  }

  .subwindow-wrapper
    > .subwindow.add-customer-note
    > .subwindow-body
    > textarea {
    width: 424px;
    height: 189px;
    resize: none;
    outline: 0;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    box-sizing: border-box;
    padding: 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 20px;
  }

  .subwindow-wrapper
    > .subwindow.add-customer-note
    > .subwindow-body
    > textarea:focus {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.add-customer-note > .subwindow-body > button {
    width: 424px;
    height: 48px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Add Cash */

  .subwindow-wrapper > .subwindow.add-cash {
    width: 504px;
    height: min(534px, 95%);
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body > .text-row {
    width: 427px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 21px;
    border-bottom: 1px solid var(--oliver-border);
    margin-bottom: 31px;
  }

  .subwindow-wrapper
    > .subwindow.add-cash
    > .subwindow-body
    > .text-row
    > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.add-cash
    > .subwindow-body
    > .text-row
    > p.style2 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: right;
    color: var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body > .input-row {
    width: 427px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 17px;
  }

  .subwindow-wrapper
    > .subwindow.add-cash
    > .subwindow-body
    > .input-row
    > label {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.add-cash
    > .subwindow-body
    > .input-row
    > input {
    width: 241px;
    height: 50px;
    outline: 0;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    padding: 0 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.add-cash
    > .subwindow-body
    > .input-row
    > input:focus {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body > label {
    width: 427px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 6px;
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body > textarea {
    width: 427px;
    height: 144px;
    box-sizing: border-box;
    outline: 0;
    resize: none;
    padding: 12px;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 23px;
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body > textarea:focus {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body > button {
    width: 427px;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Remove Cash */

  .subwindow-wrapper > .subwindow.remove-cash {
    width: 504px;
    height: min(534px, 95%);
  }

  .subwindow-wrapper > .subwindow.remove-cash > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper > .subwindow.remove-cash > .subwindow-body > .text-row {
    width: 427px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 21px;
    border-bottom: 1px solid var(--oliver-border);
    margin-bottom: 31px;
  }

  .subwindow-wrapper
    > .subwindow.remove-cash
    > .subwindow-body
    > .text-row
    > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.remove-cash
    > .subwindow-body
    > .text-row
    > p.style2 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: right;
    color: var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.remove-cash > .subwindow-body > .input-row {
    width: 427px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 17px;
  }

  .subwindow-wrapper
    > .subwindow.remove-cash
    > .subwindow-body
    > .input-row
    > label {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.remove-cash
    > .subwindow-body
    > .input-row
    > input {
    width: 241px;
    height: 50px;
    outline: 0;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    padding: 0 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.remove-cash
    > .subwindow-body
    > .input-row
    > input:focus {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.remove-cash > .subwindow-body > label {
    width: 427px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 6px;
  }

  .subwindow-wrapper > .subwindow.remove-cash > .subwindow-body > textarea {
    width: 427px;
    height: 144px;
    box-sizing: border-box;
    outline: 0;
    resize: none;
    padding: 12px;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 23px;
  }

  .subwindow-wrapper
    > .subwindow.remove-cash
    > .subwindow-body
    > textarea:focus {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.remove-cash > .subwindow-body > button {
    width: 427px;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* No Customer */

  .subwindow-wrapper > .subwindow.no-customer {
    width: 504px;
    height: min(430px, 95%);
    /* border-radius: 8px; */
  }

  .subwindow-wrapper > .subwindow.no-customer > .subwindow-body {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper > .subwindow.no-customer > .subwindow-body > p.style1 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 23px;
  }

  .subwindow-wrapper > .subwindow.no-customer > .subwindow-body > p.style2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 41px;
  }

  .subwindow-wrapper > .subwindow.no-customer > .subwindow-body > img {
    width: 59px;
    height: 59px;
    margin-bottom: 24px;
  }

  .subwindow-wrapper > .subwindow.no-customer > .subwindow-body > button {
    width: 265px;
    height: 50px;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }

  .subwindow-wrapper
    > .subwindow.no-customer
    > .subwindow-body
    > button:first-of-type {
    background-color: #fff;
    box-sizing: border-box;
    border: 1px solid var(--oliver-blue);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--oliver-blue);
    margin-bottom: 17px;
  }

  .subwindow-wrapper
    > .subwindow.no-customer
    > .subwindow-body
    > button:first-of-type
    > img {
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }

  .subwindow-wrapper
    > .subwindow.no-customer
    > .subwindow-body
    > button:nth-last-child(2) {
    background-color: var(--oliver-blue);
    border: 0;
    color: #fff;
  }

  /* Adjust Credit */

  .subwindow-wrapper > .subwindow.adjust-credit {
    width: 504px;
    height: min(635px, 95%);
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-grey);
    margin-bottom: 9px;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .toggle-wrapper {
    width: 320px;
    height: 50px;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    padding: 6px;
    margin-bottom: 24px;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .toggle-wrapper
    > label {
    flex: 1;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .toggle-wrapper
    > label:hover {
    cursor: pointer;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .toggle-wrapper
    > label
    > input {
    position: fixed;
    left: -10000px;
    opacity: 0;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .toggle-wrapper
    > label
    > .custom-radio {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-grey);
    border-radius: 6px;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .toggle-wrapper
    > label
    > input:checked
    ~ .custom-radio {
    background-color: var(--oliver-blue);
    color: #fff;
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body > .text-row {
    width: 427px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 21px;
    border-top: 1px solid var(--oliver-border);
    margin-bottom: 18px;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .text-row
    > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .text-row
    > p.style2 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: right;
    color: var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .change-credit-row {
    width: 427px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--oliver-border);
    margin-bottom: 18px;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .change-credit-row.hidden {
    display: none;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .change-credit-row
    > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .change-credit-row
    > input {
    width: 242px;
    height: 50px;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    padding: 0 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .change-credit-row
    > input:focus {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body > label {
    width: 427px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 6px;
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body > textarea {
    width: 427px;
    height: 144px;
    outline: 0;
    resize: none;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    padding: 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 24px;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > textarea:focus {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body > button {
    width: 427px;
    height: 50px;
    border: 0;
    border-radius: 6px;
    background-color: var(--oliver-blue);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Layaway */

  .subwindow-wrapper > .subwindow.layaway {
    width: 504px;
    height: min(420px, 95%);
  }

  .subwindow-wrapper > .subwindow.layaway > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper > .subwindow.layaway > .subwindow-body > label {
    width: 424px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 9px;
  }

  .subwindow-wrapper > .subwindow.layaway > .subwindow-body > textarea {
    width: 424px;
    height: 189px;
    outline: 0;
    resize: none;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    padding: 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 20px;
  }

  .subwindow-wrapper > .subwindow.layaway > .subwindow-body > textarea:focus {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper > .subwindow.layaway > .subwindow-body > button {
    width: 424px;
    height: 48px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Store Credit Pay */

  .subwindow-wrapper > .subwindow.store-credit-pay {
    width: 504px;
    height: min(709px, 95%);
  }

  .subwindow-wrapper > .subwindow.store-credit-pay > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .text-row {
    width: 321px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 26px;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .text-row
    > p.style1 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .text-row
    > p.style2 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    text-align: right;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper {
    width: 321px;
    box-sizing: border-box;
    padding: 16px;
    background-color: rgba(212, 222, 229, 0.5);
    border-radius: 8px;
    margin-bottom: 18px;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .input-wrapper {
    width: 100%;
    height: 93px;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    background-color: #fff;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .input-wrapper
    > p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: center;
    color: var(--oliver-base-grey);
    margin-bottom: 11px;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .input-wrapper
    > input {
    width: 90%;
    height: 42px;
    border: 0;
    font-family: Poppins;
    font-size: 40px;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .numpad-row {
    width: 100%;
    height: 68px;
    margin-top: 9px;
    display: flex;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .numpad-row
    > button {
    flex: 1;
    margin-left: 9px;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .numpad-row
    > button:first-of-type {
    margin-left: 0;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .numpad-row
    > button
    > img {
    width: 40px;
    height: 40px;
  }

  .subwindow-wrapper > .subwindow.store-credit-pay > .subwindow-body > button {
    width: 321px;
    height: 60px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Logout Confirmation */

  .subwindow-wrapper > .subwindow.logout-confirm {
    width: 100%;
    height: 100%;
  }

  .subwindow-wrapper > .subwindow.logout-confirm > .subwindow-body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper > .subwindow.logout-confirm > .subwindow-body > p.style1 {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin: 10px 0 49px 0;
  }

  .subwindow-wrapper > .subwindow.logout-confirm > .subwindow-body > p.style2 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-top: 10px;
  }

  .subwindow-wrapper
    > .subwindow.logout-confirm
    > .subwindow-body
    > p.style2:first-of-type {
    margin-top: 0;
  }

  .subwindow-wrapper
    > .subwindow.logout-confirm
    > .subwindow-body
    > p.style2
    > br {
    display: none;
  }

  .subwindow-wrapper > .subwindow.logout-confirm > .subwindow-body > button {
    width: 190px;
    height: 48px;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }

  .subwindow-wrapper
    > .subwindow.logout-confirm
    > .subwindow-body
    > button#registerLogout {
    background-color: var(--oliver-blue);
    border: 0;
    color: #fff;
    margin: 61px 0 17px 0;
  }

  .subwindow-wrapper
    > .subwindow.logout-confirm
    > .subwindow-body
    > button#cancelRegisterLogout {
    background-color: #fff;
    border: 1px solid var(--oliver-blue);
    color: var(--oliver-blue);
    margin-bottom: 10px;
  }

  /* Receipt */

  .subwindow-wrapper > .subwindow.receipt {
    width: 504px;
    height: 295px;
    display: flex;
    flex-direction: column;
  }

  .subwindow-wrapper > .subwindow.receipt > .subwindow-body {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper > .subwindow.receipt > .subwindow-body > .receipt-options {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-options.hidden {
    display: none;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-options
    > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-options
    > button {
    width: 265px;
    height: 50px;
    margin-top: 17px;
    border-radius: 6px;
    border: 0;
    background-color: var(--oliver-blue);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-options
    > button:first-of-type {
    margin-top: 21px;
  }

  .subwindow-wrapper > .subwindow.receipt > .subwindow-body > .receipt-email {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-email.hidden {
    display: none;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-email
    > label {
    width: 350px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: var(--oliver-base-blue);
    padding-bottom: 7px;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-email
    > label:hover {
    cursor: pointer;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-email
    > label:hover
    + input {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-email
    > input {
    width: 350px;
    height: 50px;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    padding: 0 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 17px;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-email
    > input:hover {
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-email
    > input:focus {
    border-width: 2px;
    border-color: var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-email
    > button {
    width: 350px;
    height: 50px;
    border: 0;
    background-color: var(--oliver-blue);
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Update Transaction Status */

  .subwindow-wrapper > .subwindow.update-transaction-status {
    width: 344px;
    height: min(95%, 564px);
  }

  .subwindow-wrapper > .subwindow.update-transaction-status > .subwindow-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-grey);
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > label {
    margin-top: 15px;
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > label
    > input {
    position: fixed;
    left: -10000px;
    opacity: 0;
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > label
    > .custom-radio {
    width: 285px;
    height: 45px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid var(--oliver-border);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > label
    > input:not(:disabled):not(:checked)
    + .custom-radio:hover {
    cursor: pointer;
    border: 1px solid var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > label
    > input:checked
    + .custom-radio {
    border: 2px solid var(--oliver-blue);
    color: var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > label
    > input:disabled
    + .custom-radio {
    background-color: transparent;
    color: var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > button {
    margin-top: 28px;
    width: 285px;
    height: 45px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 8px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* --------------------Open Register-------------------- */

  .open-register-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: var(--oliver-base-blue);
    overflow: auto;
    line-height: 0;
  }

  .open-register-wrapper > #cancel,
  .open-register-wrapper > #goBack {
    position: absolute;
    left: 10px;
    top: 10px;
    display: none;
    align-items: center;
    justify-content: center;
    width: 132.1px;
    height: 52px;
    border: 0;
    background-color: transparent;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    color: #fff;
  }

  .open-register-wrapper > #cancel > img,
  .open-register-wrapper > #goBack > img {
    width: 8.2px;
    height: 16.4px;
    margin-right: 8px;
  }

  .open-register-wrapper > #logout-landscape {
    position: absolute;
    right: 10px;
    top: 10px;
    display: none;
    align-items: center;
    justify-content: center;
    width: 132.1px;
    height: 52px;
    border: 0;
    background-color: transparent;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    color: #fff;
  }

  .open-register-wrapper > #logout-landscape > img {
    width: 24px;
    height: 24px;
    margin-right: 7px;
  }

  .open-register-wrapper.state-1 > #logout-landscape {
    display: flex;
  }

  .open-register-wrapper.state-1 > #cancel {
    display: flex;
  }

  .open-register-wrapper.state-2 > #goBack,
  .open-register-wrapper.state-3 > #goBack {
    display: flex;
  }

  .open-register-wrapper > #logout-mobile {
    display: none;
  }

  .open-register-wrapper > header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .open-register-wrapper > header > img {
    width: 276.68px;
    height: 253px;
    margin-bottom: 20px;
  }

  .open-register-wrapper > header .col {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .open-register-wrapper > header p {
    max-width: 30vw;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .open-register-wrapper > header p.style1 {
    font-family: Poppins;
    font-size: 48px;
    font-weight: 300;
    line-height: 69px;
    text-align: center;
    color: #fff;
    margin-bottom: 10px;
  }

  .open-register-wrapper > header .divider {
    width: 261px;
    height: 1px;
    background-color: #fff;
    margin-bottom: 14px;
  }

  .open-register-wrapper > header p.style2 {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    color: #fff;
  }

  .open-register-wrapper > header p.style3 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #fff;
  }

  .open-register-wrapper > main {
    display: flex;
    flex-direction: column;
  }

  .open-register-wrapper > main > * {
    width: 348px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 159px;
  }

  .open-register-wrapper > main > *.hidden {
    display: none !important;
  }

  .open-register-wrapper > main > .step1 {
    display: flex;
    flex-direction: column;
  }

  .open-register-wrapper > main > .step1 > p {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 400;
    line-height: 35px;
    text-align: center;
    color: #fff;
    margin-bottom: 17px;
  }

  .open-register-wrapper > main > .step1 > button {
    width: 347px;
    height: 50px;
    border: 0;
    border-radius: 6px;
    background-color: var(--oliver-blue);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  .open-register-wrapper > main > .step2 {
    display: flex;
    flex-direction: column;
  }

  .open-register-wrapper > main > .step2 > p {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 35px;
    text-align: left;
    color: #fff;
    margin-bottom: 24px;
  }

  .open-register-wrapper > main > .step2 > label {
    width: 348px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    margin-bottom: 9px;
    color: #fff;
  }

  .open-register-wrapper > main > .step2 > input {
    width: 348px;
    height: 83px;
    border: 0;
    border-radius: 6px;
    outline: 0;
    font-family: Poppins;
    font-size: 40px;
    font-weight: 400;
    line-height: 56px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 24px;
  }

  .open-register-wrapper > main > .step2 > button {
    width: 348px;
    height: 50px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    border-radius: 6px;
  }

  .open-register-wrapper > main > .step2 > button#openCashDrawer {
    box-sizing: border-box;
    border: 1px solid #fff;
    background-color: transparent;
    margin-bottom: 24px;
  }

  .open-register-wrapper > main > .step2 > button#openFloatButton {
    border: 0;
    background-color: var(--oliver-blue);
  }

  .open-register-wrapper > main > .step2 > textarea {
    box-sizing: border-box;
    width: 347px;
    height: 83px;
    outline: 0;
    resize: none;
    padding: 12px;
    border: 0;
    border-radius: 6px;
    margin-bottom: 24px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .open-register-wrapper > main > .step3 {
    display: flex;
    flex-direction: column;
  }

  .open-register-wrapper > main > .step3 > p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
    text-align: center;
    color: #fff;
    margin: 25px;
  }

  .open-register-wrapper > main > .step3 > .error-message {
    width: 276px;
    height: 42px;
    box-sizing: border-box;
    background-color: #ffe7e8;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 18px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--error);
  }

  .open-register-wrapper > main > .step3 > .pinpad {
    width: 276px;
    display: flex;
    flex-direction: column;
  }

  .open-register-wrapper > main > .step3 > .pinpad > .pin-entries {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 17.06px;
  }

  .open-register-wrapper > main > .step3 > .pinpad > .pin-entries > .pin-entry {
    width: 15px;
    height: 15px;
    background-color: #fff;
    border-radius: 50%;
    margin-left: 9.42px;
  }

  .open-register-wrapper
    > main
    > .step3
    > .pinpad
    > .pin-entries
    > .pin-entry:nth-child(1) {
    margin-left: 0;
  }

  .open-register-wrapper
    > main
    > .step3
    > .pinpad
    > .pin-entries
    > .pin-entry.entered {
    background-color: var(--oliver-blue);
  }

  .open-register-wrapper > main > .step3 > .pinpad > .pin-entries.incorrect {
    animation: shake 0.3s;
  }

  .open-register-wrapper
    > main
    > .step3
    > .pinpad
    > .pin-entries.incorrect
    > .pin-entry {
    background-color: var(--oliver-red);
  }

  .open-register-wrapper > main > .step3 > .pinpad > .pin-button-row {
    width: 100%;
    display: flex;
    margin-top: 16.3px;
  }

  .open-register-wrapper
    > main
    > .step3
    > .pinpad
    > .pin-button-row:nth-child(2) {
    margin-top: 0;
  }

  .open-register-wrapper > main > .step3 > .pinpad > .pin-button-row > button {
    width: 81.51px;
    height: 81.51px;
    border: 0;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 15.73px;
  }

  .open-register-wrapper
    > main
    > .step3
    > .pinpad
    > .pin-button-row
    > button
    > p {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    color: var(--oliver-base-blue);
    transform: translateY(3px);
  }

  .open-register-wrapper
    > main
    > .step3
    > .pinpad
    > .pin-button-row
    > button
    > .text-row {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 300;
    line-height: 15px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .open-register-wrapper
    > main
    > .step3
    > .pinpad
    > .pin-button-row
    > button
    > .text-row.no-opacity {
    opacity: 0;
  }

  .open-register-wrapper
    > main
    > .step3
    > .pinpad
    > .pin-button-row
    > button:last-of-type {
    margin-right: 0;
  }

  .open-register-wrapper
    > main
    > .step3
    > .pinpad
    > .pin-button-row
    > button.backspace {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .open-register-wrapper
    > main
    > .step3
    > .pinpad
    > .pin-button-row
    > button.backspace
    > img {
    width: 50.38px;
    height: 40.09px;
  }

  .open-register-wrapper
    > main
    > .step3
    > .pinpad:last-child
    > .pin-button-row
    > button:first-of-type {
    margin-left: auto;
  }

  /* --------------------Logout Confirmation-------------------- */

  .logout-confirmation-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
  }

  .logout-confirmation-wrapper.hidden {
    display: none;
  }

  .logout-confirmation-wrapper > p.style1 {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin: 26px 0 49px 0;
  }

  .logout-confirmation-wrapper > p.style2 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 61px;
  }

  .logout-confirmation-wrapper > button {
    width: 190px;
    height: 48px;
    border-radius: 8px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }

  .logout-confirmation-wrapper > button#logoutConfirm {
    background-color: var(--oliver-blue);
    color: #fff;
    border: 0;
    margin-bottom: 17px;
  }

  .logout-confirmation-wrapper > button#logoutCancel {
    box-sizing: border-box;
    border: 1px solid var(--oliver-blue);
    background-color: #fff;
    color: var(--oliver-blue);
    margin-bottom: 26px;
  }

  /* --------------------Idle Register-------------------- */

  .idle-register-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: var(--oliver-blue);
    overflow: auto;
  }

  .idle-register-wrapper > #logoutRegisterButton {
    position: absolute;
    padding: 10px;
    top: 14px;
    left: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 0;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    color: #fff;
  }

  .idle-register-wrapper > #logoutRegisterButton > img {
    width: 24px;
    height: 24px;
    margin-right: 7px;
  }

  .idle-register-wrapper > header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 171px auto auto;
  }

  .idle-register-wrapper > header > img {
    width: 276.68px;
    height: 253px;
    margin: 20px 0 17px 0;
  }

  .idle-register-wrapper > header > .col > p {
    max-width: 30vw;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .idle-register-wrapper > header > .col > p.style1 {
    font-family: Poppins;
    font-size: 48px;
    font-weight: 300;
    line-height: 69px;
    text-align: center;
    color: #fff;
    margin-bottom: 10px;
  }

  .idle-register-wrapper > header > .col > p.style2 {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
    color: #fff;
    margin-bottom: 1px;
  }

  .idle-register-wrapper > header > .col > p.style3 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #fff;
    margin-bottom: 17px;
  }

  .idle-register-wrapper > header > .col > .divider {
    width: 261px;
    height: 1px;
    background-color: #fff;
    margin-bottom: 14px;
  }

  .idle-register-wrapper > header > .col > button#closeRegister1 {
    width: 261px;
    height: 50px;
    border: 0;
    border-radius: 4px;
    background-color: var(--oliver-base-blue);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin-bottom: 20px;
  }

  .idle-register-wrapper > main {
    margin: auto auto auto 0;
    display: flex;
    flex-direction: column;
  }

  .idle-register-wrapper > main > p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
    text-align: center;
    color: #fff;
    margin: 20px 0 24.69px 0;
  }

  .idle-register-wrapper > main > .pinpad {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 278px;
    margin-bottom: 10px;
  }

  .idle-register-wrapper > main > .pinpad > p.error-message {
    padding: 11px 28px;
    background-color: #ffe7e8;
    border-radius: 8px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--error);
    margin: -10px 0 20px 0;
  }

  .idle-register-wrapper > main > .pinpad > .pin-entries {
    display: flex;
    margin-bottom: 17px;
  }

  .idle-register-wrapper > main > .pinpad > .pin-entries > .pin-entry {
    width: 16px;
    height: 16px;
    background-color: #fff;
    margin-left: 10px;
    border-radius: 50%;
  }

  .idle-register-wrapper
    > main
    > .pinpad
    > .pin-entries
    > .pin-entry:first-child {
    margin: 0;
  }

  .idle-register-wrapper > main > .pinpad > .pin-entries > .pin-entry.entered {
    background-color: var(--oliver-base-blue);
  }

  .idle-register-wrapper > main > .pinpad > .pin-entries.incorrect {
    animation: shake 0.3s;
  }

  .idle-register-wrapper
    > main
    > .pinpad
    > .pin-entries.incorrect
    > .pin-entry {
    background-color: var(--oliver-red);
  }

  .idle-register-wrapper > main > .pinpad > .pin-button-row {
    width: 100%;
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
  }

  .idle-register-wrapper > main > .pinpad > .pin-button-row > button {
    width: 82px;
    height: 82px;
    box-sizing: border-box;
    border: 0;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .idle-register-wrapper
    > main
    > .pinpad
    > .pin-button-row:last-child
    > button:first-child {
    margin-left: auto;
  }

  .idle-register-wrapper > main > .pinpad > .pin-button-row > button.backspace {
    background-color: transparent;
    border: 0;
  }

  .idle-register-wrapper
    > main
    > .pinpad
    > .pin-button-row
    > button.backspace
    > img {
    width: 50px;
    height: 40px;
  }

  .idle-register-wrapper > main > .pinpad > .pin-button-row > button > p {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .idle-register-wrapper
    > main
    > .pinpad
    > .pin-button-row
    > button
    > .text-row {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .idle-register-wrapper
    > main
    > .pinpad
    > .pin-button-row
    > button
    > .text-row.no-opacity {
    opacity: 0;
  }

  .idle-register-wrapper > main > button#closeRegister2 {
    display: none;
  }

  /* --------------------Close Register-------------------- */

  .close-register-wrapper {
    width: 100%;
    height: 100%;
    background-color: var(--oliver-blue);
    overflow: auto;
    display: flex;
    position: relative;
    line-height: 0;
  }

  .close-register-wrapper > button {
    position: absolute;
    left: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 132.1px;
    height: 52px;
    border: 0;
    background-color: transparent;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    color: #fff;
  }

  .close-register-wrapper > button > img {
    width: 8.2px;
    height: 16.4px;
    margin-right: 8px;
  }

  .close-register-wrapper > button.hidden {
    display: none;
  }

  .close-register-wrapper > header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 0 auto auto;
  }

  .close-register-wrapper > header > img {
    width: 276.68px;
    height: 253px;
    margin-bottom: 17px;
  }

  .close-register-wrapper > header > .col > p {
    max-width: 30vw;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .close-register-wrapper > header > .col > p.style1 {
    font-family: Poppins;
    font-size: 48px;
    font-weight: 300;
    line-height: 69px;
    text-align: center;
    color: #fff;
  }

  .close-register-wrapper > header > .col > p.style2 {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
    color: #fff;
    margin-bottom: 1px;
  }

  .close-register-wrapper > header > .col > p.style3 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #fff;
  }

  .close-register-wrapper > header > .col > .divider {
    width: 261px;
    height: 1px;
    background-color: #fff;
    margin-bottom: 14px;
  }

  .close-register-wrapper > main {
    display: flex;
    flex-direction: column;
    margin: auto auto auto 0;
  }

  .close-register-wrapper > main > *.hidden {
    display: none !important;
  }

  .close-register-wrapper > main > .step1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 171px;
  }

  .close-register-wrapper > main > .step1 > .auto-margin-bottom,
  .close-register-wrapper > main > .step1 > .auto-margin-top {
    margin: 0;
  }

  .close-register-wrapper > main > .step1 > p.style1 {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 35px;
    text-align: center;
    color: #fff;
    margin: 26px 0 36px 0;
  }

  .close-register-wrapper > main > .step1 > .divider {
    width: 90px;
    height: 1px;
    background-color: #fff;
    margin-bottom: 43px;
  }

  .close-register-wrapper > main > .step1 > p.style2 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
    text-align: center;
    color: #fff;
    margin-bottom: 24.69px;
  }

  .close-register-wrapper > main > .step1 > .pinpad {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 26px;
  }

  .close-register-wrapper > main > .step1 > .pinpad {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 278px;
    margin-bottom: 10px;
  }

  .close-register-wrapper > main > .step1 > .pinpad > p.error-message {
    padding: 11px 28px;
    background-color: #ffe7e8;
    border-radius: 8px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--error);
    margin: -10px 0 20px 0;
  }

  .close-register-wrapper > main > .step1 > .pinpad > .pin-entries {
    display: flex;
    margin-bottom: 17px;
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-entries
    > .pin-entry {
    width: 16px;
    height: 16px;
    background-color: #fff;
    margin-left: 10px;
    border-radius: 50%;
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-entries
    > .pin-entry:first-child {
    margin: 0;
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-entries
    > .pin-entry.entered {
    background-color: var(--oliver-base-blue);
  }

  .close-register-wrapper > main > .step1 > .pinpad > .pin-entries.incorrect {
    animation: shake 0.3s;
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-entries.incorrect
    > .pin-entry {
    background-color: var(--oliver-red);
  }

  .close-register-wrapper > main > .step1 > .pinpad > .pin-button-row {
    width: 100%;
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
  }

  .close-register-wrapper > main > .step1 > .pinpad > .pin-button-row > button {
    width: 82px;
    height: 82px;
    box-sizing: border-box;
    border: 0;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-button-row:last-child
    > button:first-child {
    margin-left: auto;
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-button-row
    > button.backspace {
    background-color: transparent;
    border: 0;
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-button-row
    > button.backspace
    > img {
    width: 50px;
    height: 40px;
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-button-row
    > button
    > p {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-button-row
    > button
    > .text-row {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-button-row
    > button
    > .text-row.no-opacity {
    opacity: 0;
  }

  .close-register-wrapper > main > .step2 {
    width: 416px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 155.32px;
  }

  .close-register-wrapper > main > .step2 > p.style1 {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 35px;
    text-align: center;
    color: #fff;
    margin: 26px 0 30px 0;
  }

  .close-register-wrapper > main > .step2 > .divider {
    width: 87.07px;
    height: 1px;
    background-color: #fff;
    margin-bottom: 49px;
  }

  .close-register-wrapper > main > .step2 > p.style2 {
    width: 100%;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #fff;
    margin-bottom: 22px;
  }

  .close-register-wrapper > main > .step2 > p.style2 > b {
    font-weight: 600;
  }

  .close-register-wrapper > main > .step2 > button#openCashDrawer {
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    border: 2px solid #fff;
    border-radius: 8px;
    background-color: transparent;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin-bottom: 38px;
  }

  .close-register-wrapper > main > .step2 > .input-column {
    width: 100%;
    margin-bottom: 38px;
    display: flex;
    flex-direction: column;
  }

  .close-register-wrapper > main > .step2 > .input-column > .input-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 21px;
  }

  .close-register-wrapper
    > main
    > .step2
    > .input-column
    > .input-row:nth-child(1) {
    margin-top: 0;
  }

  .close-register-wrapper > main > .step2 > .input-column > .input-row > label {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #fff;
  }

  .close-register-wrapper > main > .step2 > .input-column > .input-row > input {
    width: 232.4px;
    height: 50px;
    border: 0;
    border-radius: 6px;
    outline: 0;
    box-sizing: border-box;
    padding: 0 18px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .close-register-wrapper > main > .step2 > button#saveCount {
    width: 100%;
    height: 50px;
    background-color: var(--oliver-base-blue);
    border: 0;
    border-radius: 8px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin-bottom: 26px;
  }

  .close-register-wrapper > main > .step3 {
    width: 619px;
    margin-left: 76.32px;
    display: flex;
    flex-direction: column;
  }

  .close-register-wrapper > main > .step3 > p.style1 {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 35px;
    text-align: center;
    color: #fff;
    margin: 26px 0 38px 0;
  }

  .close-register-wrapper > main > .step3 > p.style2 {
    display: none;
  }

  .close-register-wrapper > main > .step3 > label {
    width: 100%;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #fff;
    margin-bottom: 9px;
  }

  .close-register-wrapper > main > .step3 > .closing-totals {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 0 15px;
    margin-bottom: 25px;
  }

  .close-register-wrapper > main > .step3 > .closing-totals > p.style1 {
    display: none;
  }

  .close-register-wrapper > main > .step3 > .closing-totals > .tablet-row {
    display: flex;
    margin: 12px 0 22px 0;
  }

  .close-register-wrapper > main > .step3 > .closing-totals > .tablet-row > p {
    font-family: Roboto Mono;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: var(--oliver-base-blue);
    width: 20%;
    text-align: right;
  }

  .close-register-wrapper
    > main
    > .step3
    > .closing-totals
    > .tablet-row
    > p:nth-child(1) {
    width: 40%;
    text-align: left;
  }

  .close-register-wrapper > main > .step3 > .closing-totals > .col {
    display: flex;
    margin-bottom: 16px;
  }

  .close-register-wrapper > main > .step3 > .closing-totals > .col > p {
    font-family: Roboto Mono;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
    width: 40%;
  }

  .close-register-wrapper > main > .step3 > .closing-totals > .col > .row {
    width: 20%;
  }

  .close-register-wrapper
    > main
    > .step3
    > .closing-totals
    > .col:not(.total)
    > .row
    > p:first-of-type {
    display: none;
  }

  .close-register-wrapper
    > main
    > .step3
    > .closing-totals
    > .col
    > .row
    > p:last-of-type {
    font-family: Roboto Mono;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: right;
    color: var(--oliver-base-blue);
  }

  .close-register-wrapper > main > .step3 > .closing-totals > .tablet-divider {
    width: calc(100% + 30px);
    height: 1px;
    margin-left: -15px;
    background-color: var(--oliver-border);
    margin-bottom: 16px;
  }

  .close-register-wrapper > main > .step3 > .closing-totals > .col.total > p {
    font-family: Roboto Mono;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-right: auto;
  }

  .close-register-wrapper
    > main
    > .step3
    > .closing-totals
    > .col.total
    > .row
    > p {
    font-family: Roboto Mono;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: right;
    color: var(--oliver-base-blue);
  }

  .close-register-wrapper > main > .step3 > textarea {
    width: 100%;
    height: 105px;
    box-sizing: border-box;
    border: 0;
    border-radius: 8px;
    outline: 0;
    resize: none;
    padding: 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 29px;
  }

  .close-register-wrapper > main > .step3 > button#printReport {
    width: 244px;
    height: 50px;
    box-sizing: border-box;
    border: 2px solid #fff;
    border-radius: 8px;
    background-color: transparent;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin-bottom: 29px;
    align-self: center;
  }

  .close-register-wrapper > main > .step3 > button#closeRegister {
    width: 100%;
    height: 50px;
    border: 0;
    border-radius: 8px;
    background-color: var(--oliver-base-blue);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin-bottom: 26px;
  }

  /* --------------------Create User ID-------------------- */

  .create-userid-wrapper {
    width: 100%;
    height: 100%;
    background-color: var(--oliver-base-blue);
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .create-userid-wrapper > .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    box-sizing: border-box;
  }

  .create-userid-wrapper > .header > #cancelPinSetup,
  .create-userid-wrapper > .header > #goBackPinSetup {
    display: none;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: transparent;
    border: 0;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    color: #fff;
  }

  .create-userid-wrapper > .header > #cancelPinSetup > img,
  .create-userid-wrapper > .header > #goBackPinSetup > img {
    width: 9px;
    height: 16px;
    margin-right: 7px;
  }

  .create-userid-wrapper > .header > #logoutPinSetup {
    padding: 10px;
    display: none;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 0;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    color: #fff;
  }

  .create-userid-wrapper > .header > #logoutPinSetup > img {
    width: 24px;
    height: 24px;
    margin-right: 7px;
  }

  .create-userid-wrapper > .header.var-1 > #cancelPinSetup {
    display: flex;
  }

  .create-userid-wrapper > .header.var-1 > #logoutPinSetup {
    display: flex;
  }

  .create-userid-wrapper > .header.var-2 > #goBackPinSetup {
    display: flex;
  }

  .create-userid-wrapper > .body {
    width: 100%;
    height: fit-content;
    flex-grow: 1;
    display: flex;
    padding-bottom: 20px;
  }

  .create-userid-wrapper > .body > .col {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .create-userid-wrapper > .body > .col.static {
    padding-left: 10%;
  }

  .create-userid-wrapper > .body > .col.static > img {
    width: 276.68px;
    height: 253px;
    margin-bottom: 20px;
  }

  .create-userid-wrapper > .body > .col.static > .text-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .create-userid-wrapper > .body > .col.static > .text-group > p {
    font-family: Poppins;
    text-align: center;
    color: #fff;
    max-width: 80%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .create-userid-wrapper > .body > .col.static > .text-group > p.style1 {
    font-size: 48px;
    font-weight: 300;
    line-height: 52px;
    margin-bottom: 10px;
  }

  .create-userid-wrapper > .body > .col.static > .text-group > p.style2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 1px;
  }

  .create-userid-wrapper > .body > .col.static > .text-group > p.style3 {
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
  }

  .create-userid-wrapper > .body > .col.static > .text-group > .divider {
    width: 261px;
    height: 1px;
    background-color: #fff;
    margin-bottom: 14px;
  }

  .create-userid-wrapper > .body > .col.dynamic {
    padding-right: 10%;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-1.hidden {
    display: none;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-1 > p {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 400;
    line-height: 35px;
    text-align: center;
    color: #fff;
    margin-bottom: 20px;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-1 > p > b {
    font-weight: 700;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-1 > button {
    width: 347px;
    height: 50px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2.hidden {
    display: none;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 > p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
    text-align: center;
    color: #fff;
    margin-bottom: 23px;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 > .id-row {
    width: 276px;
    display: flex;
    margin-bottom: 25.33px;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .id-row
    > .id-entry {
    flex: 1;
    height: 44px;
    background-color: #fff;
    margin-left: 10px;
    box-shadow: inset 0px 4px 6px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 25px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .id-row
    > .id-entry:first-child {
    margin-left: 0;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 > .pin-key-row {
    width: 276px;
    display: flex;
    margin-bottom: 16px;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .pin-key-row
    > button {
    flex: 1;
    aspect-ratio: 1/1;
    margin-left: 16px;
    background-color: #fff;
    border: 0;
    border-radius: 8px;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .pin-key-row
    > button.backspace {
    background-color: transparent;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .pin-key-row
    > button.backspace
    > img {
    width: 50.38px;
    height: 40.09px;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .pin-key-row
    > button:first-of-type {
    margin-left: 0;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 > .pin-key-row.last {
    margin-bottom: 30px;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .pin-key-row.last
    > button {
    flex: 0.312759;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .pin-key-row.last
    > button:first-of-type {
    margin-left: auto;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .pin-key-row
    > button
    > p {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .pin-key-row
    > button
    > .letter-row
    > p {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 300;
    line-height: 15px;
    text-align: center;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 > button {
    width: 276px;
    height: 50px;
    border-radius: 6px;
    border: 0;
    background-color: var(--oliver-blue);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 > button:disabled {
    background-color: var(--oliver-disabled);
  }

  /* --------------------Navbar-------------------- */

  .navbar {
    z-index: 2;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 18px 16px 20px 16px;
    overflow: hidden;
  }

  .navbar.open {
    box-sizing: border-box;
    width: calc(369.4444% + 1px);
    border-right: 1px solid var(--oliver-border);
    /* filter: drop-shadow(5px 0px 20px rgba(119, 135, 147, 0.2)); */
  }

  .navbar > .header-row {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
  }

  .navbar > .header-row > img.oliver-logo {
    width: 29px;
    height: 30px;
    margin: 0 20px 0 6px;
  }

  .navbar > .header-row > img.oliver-text {
    display: none;
    width: 66px;
    height: 18px;
  }

  .navbar.open > .header-row > img.oliver-text {
    display: block;
  }

  .navbar > button.page-link {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    padding: 0 8px;
    border: 0;
    border-radius: 8px;
    background-color: transparent;
    margin-bottom: 5px;
    line-height: 0;
  }

  .navbar > button.page-link > .img-container {
    width: 24px;
    height: 24px;
  }

  .navbar > button.page-link > .img-container > img {
    width: 100%;
    height: 100%;
  }

  .navbar > button > p {
    display: none;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin: 0 auto 0 9px;
  }

  .navbar.open > button > p {
    display: block;
  }

  .navbar > button > .f-key {
    display: none;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background-color: rgba(117, 134, 150, 0.1);
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: center;
    color: var(--oliver-base-grey);
  }

  .navbar.open > button > .f-key {
    display: flex;
  }

  .navbar > button.page-link.selected {
    background-color: rgba(13, 153, 255, 0.1);
  }

  .navbar > button.page-link.selected > .img-container > img {
    filter: brightness(0) saturate(100%) invert(49%) sepia(68%) saturate(646%)
      hue-rotate(167deg) brightness(92%) contrast(97%);
  }

  .navbar > button.page-link.selected > p {
    color: var(--oliver-blue);
  }

  .navbar > button.page-link.selected > .f-key {
    background-color: #c5e7ff;
    color: var(--oliver-blue);
  }

  .navbar > button.page-link.disabled {
    background-color: transparent;
  }

  .navbar > button.page-link.disabled > .img-container > img {
    filter: brightness(0) saturate(100%) invert(80%) sepia(19%) saturate(134%)
      hue-rotate(169deg) brightness(86%) contrast(83%);
  }

  .navbar > button.page-link.disabled > p {
    color: #a6aeb6;
  }

  .navbar > button.launcher {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border: 0;
    background-color: transparent;
    box-sizing: border-box;
    padding: 0 4px;
  }

  .navbar > button.launcher > .img-container {
    width: 32px;
    height: 32px;
    background-color: #f1f4f6;
    border-radius: 6px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .navbar > button.launcher.app > .img-container > img {
    width: 100%;
    height: 100%;
  }

  .navbar > button.launcher#linkLauncherButton {
    margin: auto 0 16px 0;
  }

  .navbar > button.launcher#navApp3 {
    margin-bottom: 18px;
  }

  .navbar > button.launcher#linkLauncherButton > .img-container > img {
    width: 20px;
    height: 20px;
  }

  .navbar > button.launcher#appLauncherButton > .img-container > img {
    width: 19px;
    height: 19px;
  }

  .navbar > button.launcher > p {
    margin-left: 12px;
  }

  .navbar > .divider {
    width: 32px;
    height: 1px;
    background-color: var(--oliver-border);
    margin: 0 0 16px 4px;
  }

  .navbar > button.toggle-nav {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 0;
    box-sizing: border-box;
    padding: 0 8px;
  }

  .navbar > button.toggle-nav > .img-container {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .navbar > button.toggle-nav > .img-container > img {
    width: 20px;
    height: 17px;
  }

  .navbar.open > button.toggle-nav > .img-container > img {
    transform: rotate(180deg);
  }

  .navbar > button.toggle-nav > p {
    margin-left: 16px;
  }

  .navbar > button#appLauncherButton.filter > .img-container {
    background-color: var(--oliver-blue);
  }

  .navbar > button#appLauncherButton.filter > .img-container > img {
    filter: brightness(0) invert(1);
  }

  .navbar > button#linkLauncherButton.filter > .img-container {
    background-color: var(--oliver-blue);
  }

  .navbar > button#linkLauncherButton.filter > .img-container > img {
    filter: brightness(0) invert(1);
  }

  /* --------------------Navbar Cover-------------------- */

  .nav-cover {
    z-index: 5;
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 266px;
    top: 0;
    background-color: #7b8c98;
    opacity: 0.3;
  }

  .navbar.open ~ .nav-cover {
    display: block;
  }

  /* --------------------App Launcher-------------------- */

  .app-launcher-wrapper {
    z-index: 3;
    box-sizing: border-box;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 72px;
    top: 0;
    background-color: rgba(123, 140, 152, 0.3);
    border-left: 1px solid var(--oliver-border);
  }

  .app-launcher-wrapper.hidden {
    display: none;
  }

  .navbar.open ~ .app-launcher-wrapper {
    left: 266px;
  }

  .app-launcher-wrapper > .app-launcher {
    width: 375px;
    height: 100%;
    overflow: auto;
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }

  .app-launcher-wrapper > .app-launcher > .header {
    width: 100%;
    padding: 24px 0;
  }

  .app-launcher-wrapper > .app-launcher > .header > button {
    display: none;
  }

  .app-launcher-wrapper > .app-launcher > .header > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    margin-left: 29px;
    color: var(--oliver-base-blue);
  }

  .app-launcher-wrapper > .app-launcher > .body {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 29px;
  }

  .app-launcher-wrapper > .app-launcher > .body > img {
    display: none;
    width: 260px;
    height: 165px;
    margin: auto;
  }

  .app-launcher-wrapper > .app-launcher > .body > img:only-child {
    display: block;
  }

  .app-launcher-wrapper > .app-launcher > .body > button {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    border: 0;
    background-color: transparent;
  }

  .app-launcher-wrapper > .app-launcher > .body > button > .img-container {
    width: 55px;
    height: 55px;
    margin-right: 26px;
    overflow: hidden;
    border-radius: 10px;
  }

  .app-launcher-wrapper
    > .app-launcher
    > .body
    > button
    > .img-container
    > img {
    width: 100%;
    height: 100%;
  }

  .app-launcher-wrapper > .app-launcher > .body > button > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .app-launcher-wrapper:not(.hidden) ~ .nav-cover {
    display: none;
  }

  /* --------------------Link Launcher-------------------- */

  .link-launcher-wrapper {
    z-index: 3;
    box-sizing: border-box;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 72px;
    top: 0;
    background-color: rgba(123, 140, 152, 0.3);
    border-left: 1px solid var(--oliver-border);
  }

  .link-launcher-wrapper.hidden {
    display: none;
  }

  .navbar.open ~ .link-launcher-wrapper {
    left: 266px;
  }

  .link-launcher-wrapper > .link-launcher {
    width: 375px;
    height: 100%;
    overflow: auto;
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }

  .link-launcher-wrapper > .link-launcher > .header {
    width: 100%;
    padding: 24px 0;
  }

  .link-launcher-wrapper > .link-launcher > .header > button {
    display: none;
  }

  .link-launcher-wrapper > .link-launcher > .header > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    margin-left: 29px;
    color: var(--oliver-base-blue);
  }

  .link-launcher-wrapper > .link-launcher > .body {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 29px;
  }

  .link-launcher-wrapper > .link-launcher > .body > img {
    display: none;
    width: 257px;
    height: 176px;
    margin: auto;
  }

  .link-launcher-wrapper > .link-launcher > .body > img:only-child {
    display: block;
  }

  .link-launcher-wrapper > .link-launcher > .body > button {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    border: 0;
    background-color: transparent;
  }

  .link-launcher-wrapper > .link-launcher > .body > button > .img-container {
    width: 55px;
    height: 55px;
    margin-right: 26px;
    overflow: hidden;
    border-radius: 10px;
  }

  .link-launcher-wrapper
    > .link-launcher
    > .body
    > button
    > .img-container
    > img {
    width: 100%;
    height: 100%;
  }

  .link-launcher-wrapper > .link-launcher > .body > button > .col {
    width: 0;
    flex-grow: 1;
  }

  .link-launcher-wrapper > .link-launcher > .body > button > .col > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .link-launcher-wrapper > .link-launcher > .body > button > .col > p.style2 {
    max-width: 100%;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-base-grey);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .link-launcher-wrapper:not(.hidden) ~ .nav-cover {
    display: none;
  }

  /* --------------------User Info-------------------- */

  .user-info-wrapper {
    position: fixed;
    left: 72px;
    top: 68px;
    width: calc(100% - 72px);
    height: calc(100% - 68px);
    background-color: rgba(123, 140, 152, 0.3);
  }

  .user-info-wrapper.hidden {
    display: none;
  }

  .user-info-wrapper > .user-info {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 350px;
    height: 457px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 20px 30px rgba(18, 43, 81, 0.25);
    box-sizing: border-box;
    padding: 20px 20px 25px 20px;
    display: flex;
    flex-direction: column;
  }

  .user-info-wrapper > .user-info > .header {
    width: 100%;
    padding-bottom: 18px;
    border-bottom: 1px solid var(--oliver-border);
    display: flex;
    align-items: center;
  }

  .user-info-wrapper > .user-info > .header > .avatar {
    width: 60px;
    height: 60px;
    margin-right: 17px;
    font-size: 20px;
  }

  .user-info-wrapper > .user-info > .header > .avatar > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .user-info-wrapper > .user-info > .header > .avatar > img.default {
    display: none;
  }

  .user-info-wrapper
    > .user-info
    > .header
    > .avatar
    > img.default:only-of-type {
    display: block;
  }

  .user-info-wrapper > .user-info > .header > .avatar > .col {
    width: 0;
    flex-grow: 1;
  }

  .user-info-wrapper > .user-info > .header > .col > p.style1 {
    max-width: 100%;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    text-align: left;
    color: var(--oliver-base-blue);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .user-info-wrapper > .user-info > .header > .col > p.style2 {
    max-width: 100%;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: var(--oliver-base-grey);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .user-info-wrapper > .user-info > .body {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 18px 0;
  }

  .user-info-wrapper > .user-info > .body > .language-select {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .user-info-wrapper > .user-info > .body > .language-select > p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-right: 10px;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper {
    position: relative;
    width: 0;
    flex-grow: 1;
    height: 35px;
    line-height: 0;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper:hover {
    cursor: pointer;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > img {
    position: absolute;
    top: calc(50% - 7px);
    right: 12px;
    width: 14px;
    height: 14px;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper.open
    > img {
    transform: rotate(180deg);
    filter: invert(43%) sepia(76%) saturate(756%) hue-rotate(168deg)
      brightness(103%) contrast(86%);
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper.open
    > input {
    border-radius: 8px 8px 0 0;
    border-color: var(--oliver-blue);
    border-width: 2px;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > input {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid var(--oliver-base-grey);
    border-radius: 8px;
    outline: 0;
    padding: 0 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper:hover
    > input:hover {
    cursor: pointer;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper:hover
    > img {
    filter: invert(43%) sepia(76%) saturate(756%) hue-rotate(168deg)
      brightness(103%) contrast(86%);
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > .option-container {
    width: 100%;
    max-height: 400%;
    overflow: auto;
    border-radius: 0 0 8px 8px;
    display: none;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper.open
    > .option-container {
    display: block;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > .option-container
    > .language-option {
    box-sizing: border-box;
    width: 100%;
    height: 35px;
    border: 1px solid var(--oliver-border);
    border-top: 0;
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 0 12px;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > .option-container
    > .language-option
    > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
    max-width: calc(100% - 16px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > .option-container
    > .language-option:hover {
    background-color: var(--oliver-blue);
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > .option-container
    > .language-option:hover
    > p {
    color: #fff;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > .option-container
    > .language-option:last-child {
    border-radius: 0 0 8px 8px;
  }

  .user-info-wrapper > .user-info > .body > button {
    width: 100%;
    height: 40px;
    margin-bottom: 8px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }

  .user-info-wrapper > .user-info > .body > button:hover {
    color: var(--oliver-primary);
  }

  .user-info-wrapper
    > .user-info
    > .body
    > button:hover:not(:last-child)
    > .img-container
    > img {
    filter: invert(43%) sepia(76%) saturate(756%) hue-rotate(168deg)
      brightness(103%) contrast(86%);
  }

  .user-info-wrapper > .user-info > .body > button:last-of-type {
    margin-bottom: 0;
  }

  .user-info-wrapper > .user-info > .body > button > .img-container {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > button#knowledgeBaseButton
    > .img-container
    > img {
    width: 22px;
    height: 22px;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > button#switchUserButton
    > .img-container
    > img {
    width: 14px;
    height: 15px;
  }

  .user-info-wrapper > .user-info > .body > button#endSessionButton {
    color: var(--oliver-red);
  }

  .user-info-wrapper
    > .user-info
    > .body
    > button#endSessionButton
    > .img-container
    > img {
    width: 26px;
    height: 22px;
  }

  .user-info-wrapper > .user-info > .footer {
    width: 100%;
    padding-top: 18px;
    border-top: 1px solid var(--oliver-border);
    display: flex;
    flex-direction: column;
  }

  .user-info-wrapper > .user-info > .footer > .row {
    display: flex;
    align-items: center;
    margin-bottom: 13px;
  }

  .user-info-wrapper > .user-info > .footer > .row > img {
    width: 19px;
    height: 19px;
    margin-right: 7px;
  }

  .user-info-wrapper > .user-info > .footer > .row > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .user-info-wrapper > .user-info > .footer > .button-row {
    width: 100%;
    display: flex;
  }

  .user-info-wrapper > .user-info > .footer > .button-row > button {
    width: 64px;
    height: 64px;
    border-radius: 10px;
    overflow: hidden;
    background-color: transparent;
    border: 0;
    margin-right: 18px;
  }

  .user-info-wrapper
    > .user-info
    > .footer
    > .button-row
    > button:last-of-type {
    margin-right: 0;
  }

  .user-info-wrapper > .user-info > .footer > .button-row > button > img {
    width: 100%;
    height: 100%;
  }

  /* --------------------Page Options-------------------- */

  .page-options-wrapper {
    position: fixed;
    top: 22px;
    right: 76px;
  }

  .page-options-wrapper > .page-options {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  .page-options-wrapper > .page-options > p {
    display: none;
  }

  .page-options-wrapper > .page-options > button {
    background-color: transparent;
    border: 0;
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  .page-options-wrapper > .page-options > button:first-of-type {
    margin-right: 0;
  }

  .page-options-wrapper > .page-options > button > .img-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
  }

  .page-options-wrapper
    > .page-options
    > button#notificationsButton
    > .img-container
    > img {
    width: 100%;
    height: 100%;
  }

  .page-options-wrapper
    > .page-options
    > button#notificationsButton
    > .img-container
    > img.highlight {
    filter: brightness(0) saturate(100%) invert(47%) sepia(69%) saturate(786%)
      hue-rotate(169deg) brightness(95%) contrast(91%);
  }

  .page-options-wrapper
    > .page-options
    > button#customFeeDiscountButton
    > .img-container
    > img {
    width: 66.6667%;
    height: 66.6667%;
  }

  .page-options-wrapper
    > .page-options
    > button#addNoteButton
    > .img-container
    > img {
    width: 75.5417%;
    height: 62.5%;
    transform: translateY(12%);
  }

  .page-options-wrapper
    > .page-options
    > button#clearCartButton
    > .img-container
    > img {
    width: 60%;
    height: auto;
  }

  .page-options-wrapper > .page-options > button > .img-container > .badge {
    position: absolute;
    top: 0;
    right: 0;
    box-sizing: border-box;
    width: 50%;
    height: 50%;
    border-radius: 50%;
    border: 2px solid #fff;
    background-color: var(--oliver-blue);
  }

  .page-options-wrapper > .page-options > button > .img-container > .badge.red {
    background-color: var(--oliver-red);
  }

  .page-options-wrapper
    > .page-options
    > button
    > .img-container
    > .badge.green {
    background-color: var(--oliver-teal);
  }

  .page-options-wrapper
    > .page-options
    > button
    > .img-container
    > .badge.grey {
    background-color: #c4c4c4;
  }

  .page-options-wrapper > .page-options > button > p {
    display: none;
  }

  .page-options-wrapper > .page-options > button:disabled > p {
    color: var(--oliver-new-grey) !important;
  }

  .page-options-wrapper
    > .page-options
    > button:disabled
    > .img-container
    > img {
    filter: brightness(0) saturate(100%) invert(78%) sepia(14%) saturate(234%)
      hue-rotate(164deg) brightness(95%) contrast(92%) !important;
  }

  .page-options-wrapper > .page-options > button.hidden {
    display: none !important;
  }

  /* --------------------Notifications-------------------- */

  .notifications-wrapper {
    position: fixed;
    width: calc(100% - 72px);
    height: calc(100% - 68px);
    left: 72px;
    top: 68px;
    background-color: rgba(123, 140, 152, 0.3);
  }

  .notifications-wrapper.hidden {
    display: none;
  }

  .notifications-wrapper > .notifications {
    position: absolute;
    top: 1px;
    right: 16px;
    width: 375px;
    height: min(750px, 90%);
    background-color: #fff;
    border-radius: 0 0 8px 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .notifications-wrapper > .notifications.settings {
    height: min(240px, 90%);
  }

  .notifications-wrapper > .notifications > .noti-header {
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 16px;
    border-bottom: 1px solid var(--oliver-border);
  }

  .notifications-wrapper > .notifications > .noti-header > p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .notifications-wrapper > .notifications > .noti-header > button {
    background-color: transparent;
    border: 0;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(10px);
  }

  .notifications-wrapper > .notifications > .noti-header > button > img {
    width: 25px;
    height: 25px;
  }

  .notifications-wrapper
    > .notifications
    > .noti-header
    > button#mobileNotiExit {
    display: none;
  }

  .notifications-wrapper
    > .notifications.settings
    > .noti-header
    > button
    > img {
    filter: brightness(0) saturate(100%) invert(45%) sepia(55%) saturate(2555%)
      hue-rotate(184deg) brightness(103%) contrast(82%);
  }

  .notifications-wrapper > .notifications > .noti-body {
    width: 100%;
    height: 0;
    flex-grow: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  .notifications-wrapper > .notifications.settings > .noti-body {
    display: none;
  }

  .notifications-wrapper > .notifications > .noti-body > .date {
    width: 100%;
    box-sizing: border-box;
    padding: 9px 16px;
    background-color: #f1f4f6;
  }

  .notifications-wrapper > .notifications > .noti-body > .date > p {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    color: var(--oliver-base-grey);
  }

  .notifications-wrapper > .notifications > .noti-body > .notification-card {
    width: 100%;
    height: 76px;
    box-sizing: border-box;
    padding: 0 15px;
    display: flex;
    align-items: center;
    border-top: 1px solid var(--oliver-border);
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .date
    + .notification-card {
    border: 0;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .icon-wrapper {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .icon-wrapper
    > img {
    width: 32px;
    height: 32px;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .icon-wrapper.green {
    background-color: #dbf8f2;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .icon-wrapper.blue {
    background-color: #e7f4fe;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .icon-wrapper.red {
    background-color: #ffe7e9;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .icon-wrapper.red
    > img {
    width: 25px;
    height: 25px;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .icon-wrapper.grey {
    background-color: #f1f4f6;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .icon-wrapper.grey
    > img {
    width: 24px;
    height: 24px;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .col {
    width: 0;
    flex-grow: 1;
    box-sizing: border-box;
    padding-right: 12px;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .col
    > p {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .col
    > p.style1 {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    line-height: 17px;
    color: var(--oliver-base-blue);
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .col
    > p.style2 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: var(--oliver-base-grey);
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > p {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    text-align: center;
    color: var(--oliver-base-grey);
  }

  .notifications-wrapper > .notifications > .noti-settings-wrapper {
    display: none;
    width: 100%;
    height: 0;
    flex-grow: 1;
    flex-direction: column;
  }

  .notifications-wrapper > .notifications.settings > .noti-settings-wrapper {
    display: flex;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings {
    width: 100%;
    height: 0;
    flex-grow: 1;
    overflow: auto;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .header {
    width: 100%;
    box-sizing: border-box;
    padding: 12px 16px;
    background-color: #f1f4f6;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .header
    > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    color: var(--oliver-base-blue);
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 10px 16px;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px 0;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    display: flex;
    align-items: center;
    width: 200px;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label:hover {
    cursor: pointer;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > input {
    position: fixed;
    left: -10000px;
    opacity: 0;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > .toggle {
    position: relative;
    width: 64px;
    height: 32px;
    border-radius: 32px;
    background-color: var(--oliver-border);
    margin-left: auto;
    box-sizing: border-box;
    padding: 2px;
    display: flex;
    align-items: center;
    line-height: 0;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > .toggle::before {
    content: "";
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > .toggle::after {
    content: "Off";
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--oliver-base-blue-dark);
    position: absolute;
    right: 8px;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > input:checked
    + .toggle {
    background-color: var(--oliver-blue);
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > input:checked
    + .toggle::before {
    right: 2px;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > input:checked
    + .toggle::after {
    content: "On";
    left: 8px;
    right: auto;
    color: #fff;
  }

  /* --------------------Homepage Header-------------------- */

  .homepage-wrapper > .header {
    width: 100%;
    height: 100%;
  }

  .homepage-wrapper > .header > .row {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .homepage-wrapper > .header > .row > button#mobileNavToggle {
    display: none;
  }

  .homepage-wrapper > .header > .row > img {
    width: 66px;
    height: 18px;
    transform: translateY(-2px);
    margin-right: auto;
  }

  .homepage-wrapper > .header > .row > button#searchButton {
    width: 180px;
    height: 36px;
    background-color: #e7f4fe;
    border: 0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: var(--oliver-blue);
    margin-right: 173px;
  }

  .homepage-wrapper > .header > .row > button#searchButton > div {
    margin-left: 3px;
    font-size: 0.7166vw;
    opacity: 0.5;
  }

  .homepage-wrapper > .header > .row > button#searchButton > img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .homepage-wrapper > .header > .row > .options-container {
    display: flex;
    flex-direction: row-reverse;
    margin-right: 18px;
  }

  .homepage-wrapper > .header > .row > .options-container > p {
    display: none;
  }

  .homepage-wrapper > .header > .row > .options-container > button {
    width: 24px;
    height: 24px;
    background-color: transparent;
    border: 0;
    margin-right: 7px;
  }

  .homepage-wrapper
    > .header
    > .row
    > .options-container
    > button:first-of-type {
    margin-right: 0;
  }

  .homepage-wrapper
    > .header
    > .row
    > .options-container
    > button
    > .img-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .homepage-wrapper > .header > .row > .options-container > button > p {
    display: none;
  }

  .homepage-wrapper > .header > .row > button#userInfoButton {
    background-color: transparent;
    border: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 17px;
    box-sizing: border-box;
  }

  .homepage-wrapper > .header > .row > button#userInfoButton > .avatar {
    width: 100%;
    height: 100%;
  }

  .homepage-wrapper > .header > .row > button#userInfoButton > img.default {
    display: none;
  }

  .homepage-wrapper
    > .header
    > .row
    > button#userInfoButton
    > img.default:only-of-type {
    display: block;
  }

  .homepage-wrapper > .header > .row > button#mobileOptionsButton {
    display: none;
  }

  .homepage-wrapper > .header > .row > button#mobileAppsButton {
    display: none;
  }

  /* --------------------Homepage Products-------------------- */

  .homepage-wrapper > .products {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 2% 2% 0 2%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, #eaf5fe 0%, #dbeefc 100%);
  }

  .homepage-wrapper > .products > .change-row-count {
    width: 100%;
    display: none;
    align-items: center;
    /* justify-content: center; */
    margin-bottom: 16px;
  }

  .homepage-wrapper > .products.edit-screen > .change-row-count {
    display: flex;
  }

  .homepage-wrapper > .products > .change-row-count > p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .homepage-wrapper > .products > .change-row-count > button:not(#exitEditing) {
    width: 40px;
    height: 40px;
    border: 1px solid var(--oliver-blue);
    border-radius: 8px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 12px;
  }

  .homepage-wrapper > .products > .change-row-count > button:first-of-type {
    margin-left: 0 !important;
  }

  .homepage-wrapper > .products > .change-row-count > button > img {
    width: 13px;
    height: 28px;
  }

  .homepage-wrapper > .products > .change-row-count > button#exitEditing {
    height: 40px;
    padding: 0 12px;
    border: 1px solid var(--oliver-blue);
    background-color: transparent;
    border-radius: 8px;
    margin-left: auto;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    color: var(--oliver-blue);
  }

  .homepage-wrapper > .products > .category-header {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 2%;
  }

  .homepage-wrapper > .products > .category-header > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .homepage-wrapper > .products > .category-header > button {
    width: 170px;
    height: 40px;
    background-color: var(--oliver-blue);
    border-radius: 6px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin-left: auto;
  }

  .homepage-wrapper > .products > .category-header > button > img {
    width: 23px;
    height: 23px;
    margin-right: 10px;
  }

  .homepage-wrapper > .products > .products-container {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }

  .homepage-wrapper > .products > .products-container > .no-product-container {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > .no-product-container:only-child {
    display: flex;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > .no-product-container
    > img {
    width: 45px;
    height: 45px;
    margin-bottom: 13px;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > .no-product-container
    > p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    text-align: center;
    color: var(--oliver-blue);
    margin-bottom: 38px;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > .no-product-container
    > button {
    width: 140px;
    height: 37px;
    box-sizing: border-box;
    border: 1px solid var(--oliver-blue);
    border-radius: 6px;
    background-color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .homepage-wrapper > .products > .products-container > button {
    position: relative;
    width: 23.5%;
    aspect-ratio: 1/1.29697;
    background-color: #1db9ca;
    margin: 0 2% 2% 0;
    box-sizing: border-box;
    border-radius: 8px;
    border: 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 6px 10px rgba(94, 94, 94, 0.1);
  }

  .homepage-wrapper > .products > .products-container > button img {
    pointer-events: none;
  }

  .homepage-wrapper
    > .products
    > .products-container:not(.margin-script)
    > button:nth-of-type(4n) {
    margin-right: 0;
  }

  .homepage-wrapper > .products > .products-container > button.product > .body {
    display: flex;
    width: 100%;
    flex: 75;
    border-radius: 8px 8px 0 0;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button.product
    > .body
    > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button.product
    > .footer {
    width: 100%;
    flex: 32;
    background-color: #fff;
    border-radius: 0 0 8px 8px;
    box-sizing: border-box;
    border-top: 1px solid var(--oliver-border);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button.product
    > .footer
    > p {
    max-width: 90%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .homepage-wrapper > .products > .products-container > button.category {
    align-items: center;
    justify-content: center;
    background-color: #2797e8;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button.category
    > p.sub {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-align: center;
    color: #fff;
    margin-bottom: 20px;
    max-width: 80%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button.category
    > p.main {
    max-width: 80%;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #fff;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button.category.background-lime
    > p.sub,
  .homepage-wrapper
    > .products
    > .products-container
    > button.category.background-lime
    > p.main {
    color: var(--oliver-base-blue);
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button.category.background-yellow
    > p.sub,
  .homepage-wrapper
    > .products
    > .products-container
    > button.category.background-yellow
    > p.main {
    color: var(--oliver-base-blue);
  }

  .homepage-wrapper > .products > .products-container > button.add-tile {
    background-color: transparent;
    border: 2px dashed var(--oliver-new-grey);
    box-shadow: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-new-grey);
  }

  .homepage-wrapper > .products > .products-container > button.add-tile > img {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }

  .homepage-wrapper > .products > .products-container > button > .remove-cover {
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(58, 128, 180, 0.4);
    border-radius: inherit;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button
    > .remove-cover
    > .remove-button {
    width: 14.5454%;
    aspect-ratio: 1/1;
    background-color: #fff;
    border-radius: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 4.8484%;
    top: 3.7383%;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button
    > .remove-cover
    > .remove-button
    > img {
    width: 41.6667%;
    aspect-ratio: 1/1;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button
    > .not-supported {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button
    > .not-supported
    > img {
    position: absolute;
    width: 28px;
    top: 12px;
    left: 12px;
    filter: brightness(0) saturate(100%) invert(28%) sepia(13%) saturate(1227%)
      hue-rotate(179deg) brightness(94%) contrast(91%);
  }

  /* --------------------Mobile Homepage Footer-------------------- */

  .mobile-homepage-footer {
    display: none;
  }

  /* --------------------Cart-------------------- */

  .cart {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .cart > .mobile-header {
    display: none;
  }

  .cart > .body {
    width: 100%;
    height: 0;
    flex-grow: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 16px 12px 16px;
  }

  .cart > .body > img {
    display: none;
    width: 41.80554%;
    height: auto;
    margin: auto;
  }

  .cart > .body > img:only-child {
    display: block;
  }

  .cart > .body > .cart-item {
    box-sizing: border-box;
    width: 100%;
    padding: 12px 0;
    border-top: 1px solid var(--oliver-border);
    display: flex;
    flex-direction: column;
  }

  .cart > .body > .cart-item:nth-child(2) {
    border-top: 0;
  }

  .cart > .body > .cart-item > .main-row {
    width: 100%;
    display: flex;
  }

  .cart > .body > .cart-item > .main-row.aligned {
    align-items: center;
  }

  .cart > .body > .cart-item > .main-row > .quantity {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    margin-right: 15px;
    margin: 0 15px 0 8px;
    color: var(--oliver-blue);
  }

  .cart > .body > .cart-item > .main-row > .content-style {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue-dark);
    width: 0;
    flex-grow: 1;
    padding-right: 10px;
  }

  .cart > .body > .cart-item > .main-row > .quantity:hover,
  .cart > .body > .cart-item > .main-row > .content-style:hover {
    cursor: pointer;
    color: var(--oliver-blue);
    text-decoration: underline;
  }

  .cart > .body > .cart-item > .main-row > .content-style.line-capped {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .cart > .body > .cart-item > .main-row > .price {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: right;
    color: var(--oliver-base-blue);
    margin-right: 12.63px;
  }

  .cart > .body > .cart-item > .main-row > button.remove-cart-item {
    width: 18px;
    height: 18px;
    border: 0;
    background-color: transparent;
    margin-right: 6.63px;
    line-height: 0;
  }

  .cart > .body > .cart-item > .main-row > button.remove-cart-item > img {
    width: 100%;
    height: 100%;
  }

  .cart > .body > .cart-item > .main-row > button.remove-cart-item:hover > img {
    cursor: pointer;
    filter: brightness(0) saturate(100%) invert(48%) sepia(98%) saturate(1963%)
      hue-rotate(322deg) brightness(96%) contrast(111%);
  }

  .cart > .body > .cart-item > .main-row > .tag {
    padding: 5.5px 11px;
    background-color: rgba(39, 151, 232, 0.2);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-right: 14px;
  }

  .cart > .body > .cart-item > .main-row > .tag.customer {
    background-color: rgba(39, 151, 232, 0.2);
  }

  .cart > .body > .cart-item > .main-row > .tag.group {
    background-color: rgba(165, 104, 188, 0.2);
  }

  .cart > .body > .cart-item > .main-row > .tag.custom-fee {
    background-color: rgba(255, 208, 38, 0.2);
  }

  .cart > .body > .cart-item > .main-row > .tag.cart-note {
    background-color: rgba(255, 133, 82, 0.2);
  }

  .cart > .body > .cart-item > .secondary-col {
    display: flex;
    flex-direction: column;
    padding: 0 34px 0 32px;
    margin-top: 10px;
  }

  .cart > .body > .cart-item > .secondary-col > p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .cart > .body > .cart-item > .secondary-col > p > b {
    font-weight: 600;
  }

  .cart > .body > .cart-item > .secondary-col > .row {
    display: flex;
    width: 100%;
  }

  .cart > .body > .cart-item > .secondary-col > .row > p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-base-grey);
    flex: 1;
  }

  .cart > .body > .cart-item > .secondary-col > .row > p:last-of-type {
    text-align: right;
  }

  .cart > .footer {
    width: 100%;
    background-color: #f8fafc;
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--oliver-border);
  }

  .cart > .footer > .totals {
    box-sizing: border-box;
    width: 100%;
    padding: 15px;
  }

  .cart > .footer > .totals > .row {
    width: 100%;
    display: flex;
    margin-top: 7px;
  }

  .cart > .footer > .totals > .row:nth-child(1) {
    margin-top: 0;
  }

  .cart > .footer > .totals > .row > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue-dark);
  }

  .cart > .footer > .totals > .row > p > b {
    font-weight: 500;
  }

  .cart > .footer > .totals > .row > p > b.bolder {
    font-weight: 700;
  }

  .cart > .footer > .totals > .row > p:first-of-type {
    margin-right: 7px;
  }

  .cart > .footer > .totals > .row > p:last-of-type {
    margin-left: auto;
  }

  .cart > .footer > .totals > .row > button#editCartDiscount {
    background-color: transparent;
    border: 0;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-blue);
  }

  .cart > .footer > .totals > .row > button#taxesButton {
    background-color: transparent;
    border: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--oliver-blue);
    margin-right: 4px;
  }

  .cart > .footer > .checkout-container {
    box-sizing: border-box;
    width: 100%;
    border-top: 1px solid var(--oliver-border);
    height: 84px;
  }

  .cart > .footer > .checkout-container > button {
    width: 100%;
    height: 100%;
    border: 0;
    background-color: transparent;
    padding: 15px;
  }

  .cart > .footer > .checkout-container > button > p {
    background-color: var(--oliver-blue);
    width: 100%;
    height: 100%;
    border-radius: 8px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* --------------------Homepage-------------------- */

  .homepage-wrapper {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 72px auto 25vw;
    grid-template-rows: 68px auto;
    grid-template-areas:
      "navbar header header"
      "navbar products cart";
  }

  .homepage-wrapper > .navbar {
    grid-area: navbar;
  }

  .homepage-wrapper > .header {
    grid-area: header;
  }

  .homepage-wrapper > .products {
    grid-area: products;
    box-sizing: border-box;
    border-top: 1px solid var(--oliver-border);
    border-left: 1px solid var(--oliver-border);
  }

  .homepage-wrapper > .cart {
    grid-area: cart;
    box-sizing: border-box;
    border-top: 1px solid var(--oliver-border);
    border-left: 1px solid var(--oliver-border);
  }

  /* --------------------Product-------------------- */

  .product-wrapper {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 72px auto 40vw;
    grid-template-rows: 68px auto 160px;
    grid-template-areas:
      "navbar header header"
      "navbar mod-product detailed-product"
      "navbar recommended-upsells product-footer";
  }

  .product-wrapper > .navbar {
    grid-area: navbar;
  }

  .product-wrapper > .header {
    grid-area: header;
  }

  .product-wrapper > .mod-product {
    grid-area: mod-product;
    box-sizing: border-box;
    border-top: 1px solid var(--oliver-border);
    border-left: 1px solid var(--oliver-border);
  }

  .product-wrapper > .detailed-product {
    grid-area: detailed-product;
    box-sizing: border-box;
    border-top: 1px solid var(--oliver-border);
    border-left: 1px solid var(--oliver-border);
  }

  .product-wrapper > .recommended-upsells {
    grid-area: recommended-upsells;
    box-sizing: border-box;
    border-top: 1px solid var(--oliver-border);
    border-left: 1px solid var(--oliver-border);
  }

  .product-wrapper > .product-footer {
    grid-area: product-footer;
    box-sizing: border-box;
    border-top: 1px solid var(--oliver-border);
    border-left: 1px solid var(--oliver-border);
  }

  /* Product Header */

  .product-wrapper > .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
  }

  .product-wrapper > .header > .mobile-buttons {
    display: none;
  }

  .product-wrapper > .header > .main {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .product-wrapper > .header > .main > p.route {
    display: none;
  }

  .product-wrapper > .header > .main > p.prod-name {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 29px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-right: auto;
  }

  .product-wrapper > .header > .main > button#desktopExitProductButton {
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 0;
    margin-right: 14px;
  }

  .product-wrapper > .header > .main > button#desktopExitProductButton > img {
    width: 19.17px;
    height: 19.17px;
  }

  /* Mod Products */

  .product-wrapper > .mod-product {
    width: 100%;
    height: 100%;
    background-color: #f8fafc;
    box-sizing: border-box;
    padding: 20px 20px 20px 20px;
    overflow: auto;
    position: relative;
  }

  .product-wrapper.no-edit > .mod-product {
    overflow: hidden;
  }

  .product-wrapper > .mod-product > .img-container {
    display: none;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .product-wrapper > .mod-product > .img-container > img:first-of-type {
    width: 318px;
    height: 177px;
  }

  .product-wrapper > .mod-product > .img-container > img:last-of-type {
    width: 204px;
    height: 85px;
    margin-bottom: 38px;
  }

  .product-wrapper > .mod-product > .img-container > button {
    width: 167px;
    height: 37px;
    box-sizing: border-box;
    border: 1px solid var(--oliver-blue);
    border-radius: 6px;
    background-color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .product-wrapper > .mod-product.show-image > .img-container {
    display: flex;
    height: fit-content;
    padding: 60px 0;
  }

  .product-wrapper > .mod-product.show-image > .img-container:only-child {
    height: 100% !important;
    padding: 0 !important;
  }

  .product-wrapper > .mod-product > .img-container.no-var > img:last-of-type {
    display: none;
  }

  .product-wrapper > .mod-product > .img-container.no-var > button {
    display: none;
  }

  .product-wrapper
    > .mod-product
    > .img-container.no-stock
    > img:first-of-type {
    display: none;
  }

  .product-wrapper > .mod-product > .img-container.no-stock > img:last-of-type {
    display: block;
  }

  .product-wrapper > .mod-product > .img-container.no-stock > button {
    display: block;
  }

  .product-wrapper > .mod-product > img:only-child {
    display: block;
  }

  .product-wrapper > .mod-product > .no-edit-item {
    display: none;
  }

  .product-wrapper.no-edit > .mod-product > .no-edit-item {
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    position: absolute;
    background-color: rgba(241, 244, 246, 0.5);
    left: -20px;
    top: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product-wrapper > .mod-product > .no-edit-item > .inner-container {
    width: min(400px, 95%);
    height: min(225px, 95%);
    background-color: #fff;
    box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;
  }

  .product-wrapper
    > .mod-product
    > .no-edit-item
    > .inner-container
    > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 20px;
  }

  .product-wrapper
    > .mod-product
    > .no-edit-item
    > .inner-container
    > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .product-wrapper > .mod-product > .row {
    width: 100%;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
  }

  .product-wrapper > .mod-product > .row > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .product-wrapper > .mod-product > .row > button {
    padding: 10px;
    background-color: transparent;
    border: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .product-wrapper > .mod-product > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 9px;
  }

  .product-wrapper > .mod-product > p.error::after {
    content: "*Please select a variant";
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    color: var(--error);
    margin-left: 10px;
  }

  .product-wrapper > .mod-product > .radio-group {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    /* align-content: flex-start; */
    margin-bottom: 5px;
  }

  .product-wrapper > .mod-product > .radio-group > label {
    max-width: 100%;
    margin: 0 10px 10px 0;
  }

  .product-wrapper > .mod-product > .radio-group > label:hover {
    cursor: pointer;
  }

  .product-wrapper > .mod-product > .radio-group > label > input[type="radio"] {
    position: fixed;
    opacity: 0;
    left: -10000px;
  }

  .product-wrapper > .mod-product > .radio-group > label > .custom-radio {
    box-sizing: border-box;
    padding: 18px;
    border: 1px solid var(--oliver-border);
    border-radius: 8px;
    background-color: #fff;
  }

  .product-wrapper > .mod-product > .radio-group > label > .custom-radio > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: var(--oliver-base-blue);
    max-width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .product-wrapper
    > .mod-product
    > .radio-group
    > label
    > input[type="radio"]:checked
    ~ .custom-radio {
    border: 2px solid var(--oliver-blue);
  }

  .product-wrapper
    > .mod-product
    > .radio-group
    > label
    > input[type="radio"]:checked
    ~ .custom-radio
    > p {
    color: var(--oliver-blue);
  }

  .product-wrapper > .mod-product > .radio-group + .row {
    margin-top: 40px;
  }

  .product-wrapper > .mod-product > .radio-group.error > label > .custom-radio {
    border-color: var(--error);
  }

  .product-wrapper > .mod-product > input {
    width: min(300px, 100%);
    box-sizing: border-box;
    padding: 16px 18px;
    border: 1px solid var(--oliver-border);
    border-radius: 8px;
    margin-bottom: 15px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .product-wrapper > .mod-product > input::placeholder {
    color: var(--oliver-borders);
  }

  .product-wrapper > .mod-product > input:focus {
    border: 2px solid var(--oliver-blue);
  }

  .product-wrapper > .mod-product > .increment-input {
    display: flex;
    align-items: center;
    gap: 11px;
  }

  .product-wrapper > .mod-product > .increment-input > button {
    width: 40px;
    height: 40px;
    border: 0;
    border-radius: 8px;
    background-color: #ddf1ff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product-wrapper > .mod-product > .increment-input > button > img {
    width: 16px;
    height: 30px;
  }

  .product-wrapper > .mod-product > .increment-input > input {
    width: 80px !important;
    height: 40px;
    box-sizing: border-box;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-blue);
    width: fit-content;
    border: 1px solid var(--oliver-border);
    border-radius: 8px;
  }

  /* Detailed Product */

  .product-wrapper > .detailed-product {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    box-sizing: border-box;
    padding: 20px 20px 0 20px;
  }

  .product-wrapper > .detailed-product > .row {
    width: 100%;
    display: flex;
    margin-bottom: 23px;
    align-items: center;
  }

  .product-wrapper > .detailed-product > .row > .product-image-container {
    flex: 1;
    aspect-ratio: 1/1;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product-wrapper > .detailed-product > .row > .product-image-container > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .product-wrapper
    > .detailed-product
    > .row
    > .product-image-container
    > img.no-image {
    display: none;
    width: 100%;
    height: 100%;
  }

  .product-wrapper
    > .detailed-product
    > .row
    > .product-image-container
    > img.no-image:only-child {
    display: block;
  }

  .product-wrapper > .detailed-product > .row > .col {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .product-wrapper
    > .detailed-product
    > .row
    > .col
    > button#addProductDiscountMobile {
    display: none;
  }

  .product-wrapper > .detailed-product > .row > .col p.mobile-only {
    display: none;
  }

  .product-wrapper
    > .detailed-product
    > .row
    > .col
    > .group
    > .text-row
    > p.quantity {
    font-family: Poppins;
    font-size: 48px;
    font-weight: 500;
    line-height: 67px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .product-wrapper > .detailed-product > .row > .col > .group > p.desktop-only {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 21px;
  }

  .product-wrapper > .detailed-product > .row > .col > .group > button {
    width: 167px;
    height: 37px;
    background-color: #fff;
    border: 1px solid var(--oliver-blue);
    border-radius: 8px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .product-wrapper
    > .detailed-product
    > .row
    > .col
    > .group
    > button:disabled {
    border-color: var(--oliver-border) !important;
    color: var(--oliver-border) !important;
  }

  .product-wrapper > .detailed-product > .col {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .product-wrapper > .detailed-product > .col > p.title {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 4px;
  }

  .product-wrapper > .detailed-product > .col > p.para {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-bottom: 20px;
  }

  /* Recommended Upsells */

  .product-wrapper > .recommended-upsells {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid var(--oliver-border);
    background-color: #f8fafc;
  }

  .product-wrapper > .recommended-upsells > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 10px;
  }

  .product-wrapper > .recommended-upsells > .button-row {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .product-wrapper > .recommended-upsells > .button-row > button {
    width: 103px;
    height: 103px;
    background-color: #fff;
    border: 1px solid var(--oliver-border);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-right: 16px;
  }

  .product-wrapper > .recommended-upsells > .button-row > button:last-of-type {
    margin-right: 0;
  }

  .product-wrapper
    > .recommended-upsells
    > .button-row
    > button
    > .img-container {
    width: 100%;
    height: 0;
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product-wrapper
    > .recommended-upsells
    > .button-row
    > button
    > .img-container
    > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .product-wrapper > .recommended-upsells > .button-row > button > .prod-name {
    width: 100%;
    height: 22px;
    border-top: 1px solid var(--oliver-border);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product-wrapper
    > .recommended-upsells
    > .button-row
    > button
    > .prod-name
    > p {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    text-align: center;
    color: var(--oliver-base-blue);
    max-width: 90%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  /* Product Footer */

  .product-wrapper > .product-footer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .product-wrapper > .product-footer > .row {
    box-sizing: border-box;
    padding: 0 3.2193%;
    width: 100%;
    height: 87px;
    display: flex;
    align-items: center;
  }

  .product-wrapper > .product-footer > .row:nth-child(1) {
    width: 100%;
    height: 0;
    flex-grow: 1;
    border-bottom: 1px solid var(--oliver-border);
    justify-content: center;
  }

  .product-wrapper > .product-footer > .row > button#addProductNote {
    flex: 1;
    height: 55.5556%;
    margin-right: 3.2193%;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid var(--oliver-blue);
    border-radius: 8px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    text-align: center;
    color: var(--oliver-blue);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product-wrapper > .product-footer > .row > button#addProductNote > img {
    width: 14px;
    height: 15px;
    margin-right: 10px;
    filter: brightness(0) saturate(100%) invert(50%) sepia(43%) saturate(2356%)
      hue-rotate(180deg) brightness(95%) contrast(91%);
  }

  .product-wrapper > .product-footer > .row > button#addProductDiscount {
    flex: 1;
    height: 55.5556%;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid var(--oliver-blue);
    border-radius: 8px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .product-wrapper > .product-footer > .row > .increment-input {
    display: flex;
    align-items: center;
    width: 142px;
    margin-right: 23px;
  }

  .product-wrapper > .product-footer > .row > .increment-input > button {
    width: 40px;
    height: 40px;
    background-color: #ddf1ff;
    border: 0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product-wrapper
    > .product-footer
    > .row
    > .increment-input
    > button:nth-child(1)
    > img {
    width: 14px;
    height: 30px;
  }

  .product-wrapper
    > .product-footer
    > .row
    > .increment-input
    > button:nth-child(3)
    > img {
    width: 16px;
    height: 30px;
  }

  .product-wrapper > .product-footer > .row > .increment-input > input {
    width: 0;
    flex-grow: 1;
    height: 40px;
    border: 0;
    outline: 0;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-base-blue);
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 8px;
    margin: 0 5px;
  }

  .product-wrapper > .product-footer > .row > button#addProductToCart {
    width: 0;
    flex-grow: 1;
    height: 55px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 8px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product-wrapper > .product-footer > .row > button#addProductToCart:disabled {
    background-color: var(--oliver-disabled);
  }

  .product-wrapper > .product-footer > .row > button#addProductToCart > img {
    width: 29px;
    height: 29px;
    margin-right: 8px;
  }

  /* --------------------Checkout-------------------- */

  .checkout-wrapper {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 72px 25vw auto;
    grid-template-rows: 68px auto;
    grid-template-areas:
      "navbar header header"
      "navbar cart checkout-body";
  }

  .checkout-wrapper > .navbar {
    grid-area: navbar;
  }

  .checkout-wrapper > .header {
    grid-area: header;
  }

  .checkout-wrapper > .cart {
    grid-area: cart;
    border-left: 1px solid var(--oliver-border);
    border-top: 1px solid var(--oliver-border);
  }

  .checkout-wrapper > .checkout-body {
    grid-area: checkout-body;
    border-left: 1px solid var(--oliver-border);
    border-top: 1px solid var(--oliver-border);
  }

  /* Checkout Header */

  .checkout-wrapper > .header {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 17px 0 1px;
  }

  .checkout-wrapper > .header > button#exitCheckoutButton {
    width: 38px;
    height: 38px;
    margin-right: 8px;
    background-color: transparent;
    border: 0;
    line-height: 0;
  }

  .checkout-wrapper > .header > button#exitCheckoutButton.hidden {
    display: none;
  }

  .checkout-wrapper > .header > button#exitCheckoutButton > img {
    width: 100%;
    height: 100%;
    content: url("assets/images/svg/Arrow_Left.svg");
  }

  .checkout-wrapper > .header > button#exitCheckoutButton:disabled > img {
    filter: brightness(0) saturate(100%) invert(78%) sepia(14%) saturate(234%)
      hue-rotate(164deg) brightness(95%) contrast(92%);
  }

  .checkout-wrapper > .header > p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 29px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-right: auto;
  }

  .checkout-wrapper > .header > button#userInfoButton {
    background-color: transparent;
    border: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    box-sizing: border-box;
    overflow: hidden;
  }

  .checkout-wrapper > .header > button#userInfoButton > .avatar {
    width: 100%;
    height: 100%;
  }

  .checkout-wrapper > .header > button#mobileOptionsButton {
    display: none;
  }

  .checkout-wrapper > .header > button#mobileAppsButton {
    display: none;
  }

  /* Checkout Page Options */

  .checkout-wrapper > .page-options-wrapper > .page-options > button {
    width: fit-content;
    display: flex;
    align-items: center;
  }

  .checkout-wrapper
    > .page-options-wrapper
    > .page-options
    > button
    > .img-container {
    width: 24px;
    height: 24px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .checkout-wrapper
    > .page-options-wrapper
    > .page-options
    > button
    > .img-container
    > img {
    width: 100%;
    height: 100%;
  }

  .checkout-wrapper > .page-options-wrapper > .page-options > button > p {
    display: block;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  /* Checkout Cart */

  .checkout-wrapper > .cart > .body > .cart-item > .main-row > .quantity {
    color: var(--oliver-base-grey);
  }

  .checkout-wrapper > .cart > .body > .cart-item > .main-row > .quantity:hover {
    cursor: default;
    text-decoration: none;
  }

  .checkout-wrapper
    > .cart
    > .body
    > .cart-item
    > .main-row
    > .content-style:hover {
    color: var(--oliver-base-blue-dark) !important;
    cursor: default;
    text-decoration: none;
  }

  .checkout-wrapper > .cart > .body > .cart-item > .main-row > .price {
    margin: 0 !important;
  }

  /* Checkout Body */

  .checkout-wrapper > .checkout-body {
    width: 100%;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    padding: 60px 60px 40px 60px;
  }

  .checkout-wrapper > .checkout-body > .balance-container {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid var(--oliver-border);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 25px 30px;
    justify-content: space-between;
    margin-bottom: 9px;
    background-color: transparent;
  }

  .checkout-wrapper > .checkout-body > .balance-container:hover {
    border-width: 2px;
    border-color: var(--oliver-blue);
  }

  .checkout-wrapper > .checkout-body > .balance-container > .row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3px;
  }

  .checkout-wrapper > .checkout-body > .balance-container > .row:nth-child(1) {
    margin-bottom: 7px;
    margin-top: 0;
  }

  .checkout-wrapper > .checkout-body > .balance-container > .row:only-child {
    margin-bottom: 0;
  }

  .checkout-wrapper > .checkout-body > .balance-container p.style1 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .checkout-wrapper > .checkout-body > .balance-container p.style2 {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 42px;
    text-align: right;
    color: var(--oliver-blue);
  }

  .checkout-wrapper > .checkout-body > .balance-container p.style3 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .checkout-wrapper > .checkout-body > .balance-container p.style4 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .checkout-wrapper > .checkout-body > #bottomText {
    background-color: transparent;
    border: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-grey);
    margin-bottom: 30px;
    width: 100%;
  }

  .checkout-wrapper > .checkout-body > #bottomText:hover {
    color: var(--oliver-blue);
  }

  .checkout-wrapper > .checkout-body > p.style2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 12px;
  }

  .checkout-wrapper > .checkout-body > .button-row {
    width: 100%;
    display: flex;
    margin-bottom: 19px;
  }

  .checkout-wrapper > .checkout-body > .button-row > button {
    flex: 1;
    height: 50px;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 8px;
    margin-left: 20px;
    background-color: transparent;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .checkout-wrapper
    > .checkout-body
    > .button-row
    > button:not(:disabled):hover {
    border-width: 2px;
    border-color: var(--oliver-blue);
    color: var(--oliver-blue);
  }

  .checkout-wrapper > .checkout-body > .button-row > button:first-of-type {
    margin-left: 0;
  }

  .checkout-wrapper > .checkout-body > .button-row > button:disabled {
    background-color: #f8fafc;
    color: #b0bec9;
  }

  .checkout-wrapper > .checkout-body > .button-row + p.style2 {
    padding-top: 11px;
  }

  .checkout-wrapper > .checkout-body > p.style3 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-top: -8px;
    margin: -8px 0 12px 0;
  }

  .checkout-wrapper > .checkout-body > .payment-types {
    width: 100%;
    margin-top: 30px;
  }

  .checkout-wrapper > .checkout-body > .payment-types > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 14px;
  }

  .checkout-wrapper > .checkout-body > .payment-types > .button-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }

  .checkout-wrapper
    > .checkout-body
    > .payment-types
    > .button-container
    > button {
    width: calc((100% - 60px) / 4);
    aspect-ratio: 1.0357/1;
    margin: 0 20px 20px 0;
    border: 0;
    border-radius: 8px;
    background-color: transparent;
    font-family: Poppins;
    font-size: 25px;
    font-weight: 500;
    line-height: 33px;
    text-align: center;
    color: #fff;
    padding: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .checkout-wrapper
    > .checkout-body
    > .payment-types
    > .button-container
    > button:nth-child(4n) {
    margin-right: 0;
  }

  .checkout-wrapper
    > .checkout-body
    > .payment-types
    > .button-container
    > button
    > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* --------------------Refund-------------------- */

  .refund-wrapper {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 72px 25vw auto;
    grid-template-rows: 68px auto;
    grid-template-areas:
      "navbar header header"
      "navbar cart refund-body";
  }

  .refund-wrapper > .navbar {
    grid-area: navbar;
  }

  .refund-wrapper > .header {
    grid-area: header;
  }

  .refund-wrapper > .cart {
    grid-area: cart;
    border-left: 1px solid var(--oliver-border);
    border-top: 1px solid var(--oliver-border);
  }

  .refund-wrapper > .refund-body {
    grid-area: refund-body;
    border-left: 1px solid var(--oliver-border);
    border-top: 1px solid var(--oliver-border);
  }

  /* Refund Header */

  .refund-wrapper > .header {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 17px 0 1px;
  }

  .refund-wrapper > .header > button#exitCheckoutButton {
    width: 38px;
    height: 38px;
    margin-right: 8px;
    background-color: transparent;
    border: 0;
  }

  .refund-wrapper > .header > button#exitCheckoutButton > img {
    width: 100%;
    height: 100%;
    content: url("assets/images/svg/Arrow_Left.svg");
  }

  .refund-wrapper > .header > p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 29px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-right: auto;
  }

  .refund-wrapper > .header > button#userInfoButton {
    background-color: transparent;
    border: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    box-sizing: border-box;
  }

  .refund-wrapper > .header > button#userInfoButton > .avatar {
    width: 100%;
    height: 100%;
  }

  .refund-wrapper > .header > button#mobileOptionsButton {
    display: none;
  }

  .refund-wrapper > .header > button#mobileAppsButton {
    display: none;
  }

  /* Refund Page Options */

  .refund-wrapper > .page-options-wrapper > .page-options > button {
    width: fit-content;
    display: flex;
    align-items: center;
  }

  .refund-wrapper
    > .page-options-wrapper
    > .page-options
    > button
    > .img-container {
    width: 24px;
    height: 24px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .refund-wrapper > .page-options-wrapper > .page-options > button > p {
    display: block;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  /* Refund Cart */

  .refund-wrapper > .cart {
    width: 100%;
    height: 100%;
    border-bottom: 1px solid var(--oliver-border);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .refund-wrapper > .cart > .body > .cart-item > .main-row > p.quantity {
    color: var(--oliver-base-grey);
  }

  .refund-wrapper > .cart > .body > .cart-item > .increment-input {
    width: 130px;
    height: 36px;
    margin-top: 10px;
    display: flex;
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
  }

  .refund-wrapper > .cart > .body > .cart-item > .increment-input > button {
    height: 100%;
    aspect-ratio: 1/1;
    background-color: #ddf1ff;
    border: 0;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .refund-wrapper
    > .cart
    > .body
    > .cart-item
    > .increment-input
    > button
    > img {
    width: 10px;
    height: 10px;
  }

  .refund-wrapper > .cart > .body > .cart-item > .increment-input > input {
    width: 0;
    flex-grow: 1;
    border: 0;
    outline: 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .refund-wrapper
    > .cart
    > .body
    > .cart-item
    > .increment-input
    > input:hover {
    cursor: default;
  }

  .refund-wrapper > .cart > .footer > .totals > .row > button {
    border: 0;
    background-color: transparent;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: var(--oliver-blue);
  }

  .refund-wrapper > .cart > .footer > .totals > .row > button:hover {
    text-decoration: underline;
  }

  .refund-wrapper > .cart > .footer > .totals > .row > p.type2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: right;
    color: var(--oliver-base-blue);
    margin-right: 0 !important;
  }

  .refund-wrapper > .cart > .footer > .totals > .row > p.type2 > b {
    font-weight: 600;
  }

  .refund-wrapper > .cart > .body > .cart-item > .main-row > .quantity {
    color: var(--oliver-base-grey);
  }

  .refund-wrapper > .cart > .body > .cart-item > .main-row > .quantity:hover {
    cursor: default;
    text-decoration: none;
  }

  .refund-wrapper
    > .cart
    > .body
    > .cart-item
    > .main-row
    > .content-style:hover {
    color: var(--oliver-base-blue-dark) !important;
    cursor: default;
    text-decoration: none;
  }

  /* Refund Body */

  .refund-wrapper > .refund-body {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 60px 60px 40px 60px;
  }

  .refund-wrapper > .refund-body > .balance-container {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid var(--oliver-border);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 25px 30px;
    justify-content: space-between;
    margin-bottom: 9px;
    background-color: transparent;
    margin-bottom: 30px;
  }

  .refund-wrapper > .refund-body > .balance-container > .row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3px;
  }

  .refund-wrapper > .refund-body > .balance-container > .row:nth-child(1) {
    margin-bottom: 7px;
    margin-top: 0;
  }

  .refund-wrapper > .refund-body > .balance-container > .row:only-child {
    margin-bottom: 0 !important;
  }

  .refund-wrapper > .refund-body > .balance-container p.style1 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .refund-wrapper > .refund-body > .balance-container p.style2 {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 42px;
    text-align: right;
    color: var(--oliver-blue);
  }

  .refund-wrapper > .refund-body > .balance-container p.style3 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .refund-wrapper > .refund-body > .balance-container p.style4 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .refund-wrapper > .refund-body > p.style2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 12px;
  }

  .refund-wrapper > .refund-body > .button-row {
    width: 100%;
    display: flex;
    margin-bottom: 19px;
  }

  .refund-wrapper > .refund-body > .button-row > button {
    flex: 1;
    height: 50px;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 8px;
    margin-left: 20px;
    background-color: transparent;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .refund-wrapper > .refund-body > .button-row > button:not(:disabled):hover {
    border-width: 2px;
    border-color: var(--oliver-blue);
  }

  .refund-wrapper > .refund-body > .button-row > button:first-of-type {
    margin-left: 0;
  }

  .refund-wrapper > .refund-body > .button-row > button:disabled {
    background-color: #f8fafc;
    color: #b0bec9;
  }

  .refund-wrapper > .refund-body > .button-row + p.style2 {
    padding-top: 11px;
  }

  .refund-wrapper > .refund-body > p.style3 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-top: -8px;
    margin: -8px 0 12px 0;
  }

  .refund-wrapper > .refund-body > .payment-types {
    width: 100%;
    margin-top: 30px;
  }

  .refund-wrapper > .refund-body > .payment-types > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 14px;
  }

  .refund-wrapper > .refund-body > .payment-types > .button-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }

  .refund-wrapper > .refund-body > .payment-types > .button-container > button {
    width: calc((100% - 60px) / 4);
    aspect-ratio: 1.0357/1;
    margin: 0 20px 20px 0;
    border: 0;
    border-radius: 8px;
    background-color: transparent;
    font-family: Poppins;
    font-size: 25px;
    font-weight: 500;
    line-height: 33px;
    text-align: center;
    color: #fff;
    padding: 0;
    overflow: hidden;
  }

  .refund-wrapper
    > .refund-body
    > .payment-types
    > .button-container
    > button:nth-child(4n) {
    margin-right: 0;
  }

  .refund-wrapper
    > .refund-body
    > .payment-types
    > .button-container
    > button
    > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* --------------------Sale Complete-------------------- */

  .sale-complete-wrapper {
    width: 100%;
    height: 100%;
    background-color: var(--oliver-blue);
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .sale-complete-wrapper > .main {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 20px 0;
  }

  .sale-complete-wrapper > .main > img {
    width: 50px;
    height: 50px;
    margin: auto 0 18px 0;
  }

  .sale-complete-wrapper > .main > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: #fff;
    margin-bottom: 8px;
  }

  .sale-complete-wrapper > .main > #loadingDiv {
    display: flex;
    align-items: center;
    margin-bottom: 42px;
  }

  .sale-complete-wrapper > .main > #loadingDiv.hidden {
    display: none;
  }

  .sale-complete-wrapper > .main > #loadingDiv > img {
    width: 23px;
    height: 23px;
    margin-right: 8px;
  }

  .sale-complete-wrapper > .main > #loadingDiv > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: #fff;
  }

  .sale-complete-wrapper > .main > button#openUpdateTransactionStatus {
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: auto;
    padding: 8px;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    text-align: center;
    margin-bottom: 42px;
  }

  .sale-complete-wrapper > .main > button#openUpdateTransactionStatus:hover {
    background-color: #0079cf;
  }

  .sale-complete-wrapper > .main > button#openUpdateTransactionStatus.hidden {
    display: none;
  }

  .sale-complete-wrapper > .main > button#openUpdateTransactionStatus > img {
    width: 15px;
    height: 15px;
    margin-left: 8px;
    transform: rotate(180deg);
  }

  .sale-complete-wrapper > .main > .change-container {
    width: 490px;
    height: 104px;
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
  }

  .sale-complete-wrapper > .main > .change-container.hide {
    display: none;
  }

  .sale-complete-wrapper > .main > .change-container > p.style1 {
    font-family: Poppins;
    font-size: 22px;
    font-weight: 500;
    line-height: 29px;
    text-align: center;
    color: #0079cf;
    margin-bottom: 7px;
  }

  .sale-complete-wrapper > .main > .change-container > p.style2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: #0079cf;
  }

  .sale-complete-wrapper > .main > label.email-label {
    margin-bottom: 20px;
    position: relative;
    line-height: 0;
  }

  .sale-complete-wrapper > .main > label.email-label > input {
    width: 490px;
    height: 56px;
    border: 0;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 0 69px 0 19px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .sale-complete-wrapper > .main > label.email-label > button {
    position: absolute;
    right: 6px;
    top: 6px;
    width: 118px;
    height: 44px;
    background-color: var(--oliver-base-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: #fff;
  }

  .sale-complete-wrapper > .main > label.checkbox-label {
    display: flex;
    align-items: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: #fff;
    margin-bottom: 43px;
  }

  .sale-complete-wrapper > .main > label.checkbox-label:hover {
    cursor: pointer;
  }

  .sale-complete-wrapper > .main > label.checkbox-label > input {
    position: fixed;
    left: -10000px;
    opacity: 0;
  }

  .sale-complete-wrapper > .main > label.checkbox-label > .custom-checkbox {
    width: 24px;
    height: 24px;
    background-color: #fff;
    border-radius: 7px;
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: 2px solid #197bc1;
  }

  .sale-complete-wrapper
    > .main
    > label.checkbox-label
    > .custom-checkbox
    > img {
    display: none;
    width: 16px;
    height: 16px;
  }

  .sale-complete-wrapper
    > .main
    > label.checkbox-label
    > input:checked
    ~ .custom-checkbox
    > img {
    display: block;
  }

  .sale-complete-wrapper > .main > button {
    width: 238px;
    height: 56px;
    box-sizing: border-box;
    border: 1px solid #fff;
    border-radius: 8px;
    background-color: transparent;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin-bottom: auto;
  }

  .sale-complete-wrapper > .main > button#emailSubwindowButton {
    display: none;
  }

  .sale-complete-wrapper > .footer {
    width: 100%;
    padding-top: 20px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 38px 38px 38px;
  }

  .sale-complete-wrapper > .footer > .button-container {
    width: 197px;
  }

  .sale-complete-wrapper > .footer > .button-container > button#endSession {
    width: 200px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #fff;
  }

  .sale-complete-wrapper
    > .footer
    > .button-container
    > button#endSession
    > img {
    width: 26px;
    height: 22px;
    margin-right: 5px;
  }

  .sale-complete-wrapper > .footer > .button-container > button#newSale {
    width: 200px;
    height: 56px;
    background-color: #fff;
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .sale-complete-wrapper > .footer > .app-container {
    background-color: rgba(16, 123, 201, 0.8);
    padding: 15px 15px 0 15px;
    border-radius: 15px;
    display: flex;
    flex-wrap: wrap;
    max-width: 295px;
    box-sizing: border-box;
  }

  .sale-complete-wrapper > .footer > .app-container > button {
    width: 55px;
    height: 55px;
    border-radius: 7px;
    border: 0;
    background-color: transparent;
    overflow: hidden;
    margin: 0 15px 15px 0;
    background-color: #fff;
    line-height: 0;
  }

  .sale-complete-wrapper > .footer > .app-container > button:nth-child(4n) {
    margin-right: 0;
  }

  .sale-complete-wrapper > .footer > .app-container > button > img {
    width: 100%;
    height: 100%;
  }

  /* --------------------Refund Complete-------------------- */

  .refund-complete-wrapper {
    width: 100%;
    height: 100%;
    background-color: var(--oliver-red);
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .refund-complete-wrapper > .main {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .refund-complete-wrapper > .main > img {
    width: 193px;
    height: 93px;
    margin: 20px 0 45px 0;
  }

  .refund-complete-wrapper > .main > label.email-label {
    margin-bottom: 20px;
    position: relative;
    line-height: 0;
  }

  .refund-complete-wrapper > .main > label.email-label > input {
    width: 490px;
    height: 56px;
    border: 0;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 0 69px 0 19px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .refund-complete-wrapper > .main > label.email-label > button {
    position: absolute;
    right: 6px;
    top: 6px;
    width: 118px;
    height: 44px;
    background-color: var(--error);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: #fff;
  }

  .refund-complete-wrapper > .main > label.checkbox-label {
    display: flex;
    align-items: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: #fff;
    margin-bottom: 43px;
  }

  .refund-complete-wrapper > .main > label.checkbox-label:hover {
    cursor: pointer;
  }

  .refund-complete-wrapper > .main > label.checkbox-label > input {
    position: fixed;
    left: -10000px;
    opacity: 0;
  }

  .refund-complete-wrapper > .main > label.checkbox-label > .custom-checkbox {
    width: 24px;
    height: 24px;
    background-color: #fff;
    border-radius: 7px;
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: 2px solid var(--error);
  }

  .refund-complete-wrapper
    > .main
    > label.checkbox-label
    > .custom-checkbox
    > img {
    display: none;
    width: 16px;
    height: 16px;
    filter: brightness(0) saturate(100%) invert(16%) sepia(64%) saturate(4391%)
      hue-rotate(329deg) brightness(99%) contrast(94%);
  }

  .refund-complete-wrapper
    > .main
    > label.checkbox-label
    > input:checked
    ~ .custom-checkbox
    > img {
    display: block;
  }

  .refund-complete-wrapper > .main > button {
    width: 238px;
    height: 56px;
    box-sizing: border-box;
    border: 1px solid #fff;
    border-radius: 8px;
    background-color: transparent;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  .refund-complete-wrapper > .main > button#emailSubwindowButton {
    display: none;
  }

  .refund-complete-wrapper > .footer {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 20px 38px;
  }

  .refund-complete-wrapper > .footer > .button-container {
    width: 197px;
  }

  .refund-complete-wrapper > .footer > .button-container > button#endSession {
    width: 200px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #fff;
  }

  .refund-complete-wrapper
    > .footer
    > .button-container
    > button#endSession
    > img {
    width: 26px;
    height: 22px;
    margin-right: 5px;
  }

  .refund-complete-wrapper > .footer > .button-container > button#newSale {
    width: 200px;
    height: 56px;
    background-color: #fff;
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .refund-complete-wrapper > .footer > .app-container {
    background-color: #ff828b;
    padding: 15px 15px 0 15px;
    border-radius: 15px;
    display: flex;
    flex-wrap: wrap;
    max-width: 295px;
    box-sizing: border-box;
  }

  .refund-complete-wrapper > .footer > .app-container > button {
    width: 55px;
    height: 55px;
    border-radius: 7px;
    border: 0;
    background-color: transparent;
    overflow: hidden;
    margin: 0 15px 15px 0;
    background-color: #fff;
  }

  .refund-complete-wrapper > .footer > .app-container > button:nth-child(4n) {
    margin-right: 0;
  }

  .refund-complete-wrapper > .footer > .app-container > button > img {
    width: 100%;
    height: 100%;
  }

  /* --------------------Customer View-------------------- */

  .customer-view-wrapper {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 72px max(328px, 25vw) max(328px, 25vw) 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "navbar cv-search cv-list cv-detailed";
  }

  .customer-view-wrapper > .navbar {
    grid-area: navbar;
  }

  .customer-view-wrapper > .cv-search {
    grid-area: cv-search;
    border-left: 1px solid var(--oliver-border);
  }

  .customer-view-wrapper > .cv-list {
    grid-area: cv-list;
    border-left: 1px solid var(--oliver-border);
  }

  .customer-view-wrapper > .cv-detailed {
    grid-area: cv-detailed;
    border-left: 1px solid var(--oliver-border);
  }

  .customer-view-wrapper > .mobile-cv-header {
    display: none;
  }

  /* Customer View Search */

  .cv-search {
    display: flex;
    flex-direction: column;
  }

  .cv-search > .header {
    width: 100%;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 13px 0 22px;
    border-bottom: 1px solid var(--oliver-border);
  }

  .cv-search > .header > p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 29px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .cv-search > .header > button {
    width: 110px;
    height: 36px;
    background-color: #e7f4fe;
    border: 0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-blue);
  }

  .cv-search > .header > button > img {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }

  .cv-search > .header > button#mobileCVExitSearch {
    display: none;
  }

  .cv-search > .header > p.mobile-only {
    display: none;
  }

  .cv-search > .body {
    width: 100%;
    height: 0;
    flex-grow: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 15px 22px;
  }

  .cv-search > .body > .row {
    width: 100%;
    display: flex;
    margin-bottom: 13px;
  }

  .cv-search > .body > .row > img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .cv-search > .body > .row > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 23px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-right: auto;
  }

  .cv-search > .body > .row > button {
    padding: 5px;
    transform: translateY(-5px);
    background-color: transparent;
    border: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-blue);
    text-decoration: underline;
  }

  .cv-search > .body > label {
    width: 100%;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 4px;
  }

  .cv-search > .body > input {
    width: 100%;
    height: 44px;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    padding: 0 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 20px;
  }

  .cv-search > .body > input:focus {
    border-color: var(--oliver-blue);
  }

  .cv-search > .body > button {
    width: 100%;
    height: 44px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    margin-top: auto;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  /* Customer View List */

  .cv-list {
    display: flex;
    flex-direction: column;
  }

  .cv-list > .header {
    width: 100%;
    height: 68px;
    box-sizing: border-box;
    padding: 0 16px;
    border-bottom: 1px solid var(--oliver-border);
    display: flex;
    align-items: center;
  }

  .cv-list > .header > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-left: auto;
    margin: 0 12px 0 auto;
  }

  .cv-list > .header > .sort-wrapper {
    width: 155px;
    height: 36px;
    position: relative;
    user-select: none;
    line-height: 0;
  }

  .cv-list > .header > .sort-wrapper.open {
    z-index: 10;
    box-shadow: 0px 6px 10px rgba(94, 94, 94, 0.1);
  }

  .cv-list > .header > .sort-wrapper > img {
    position: absolute;
    top: calc(50% - 6px);
    right: 9px;
    width: 13px;
    height: 13px;
    filter: brightness(0) saturate(100%) invert(53%) sepia(37%) saturate(1027%)
      hue-rotate(165deg) brightness(91%) contrast(99%);
  }

  .cv-list > .header > .sort-wrapper.open > img {
    transform: rotate(180deg) translateY(-2px);
  }

  .cv-list > .header > .sort-wrapper > input {
    width: 100%;
    height: 100%;
    border: 1px solid var(--oliver-border);
    border-radius: 8px;
    box-sizing: border-box;
    padding: 0 25px 0 10px;
    font-family: Poppins;
    font-size: 14px;
    line-height: 20px;
    color: var(--oliver-blue);
  }

  .cv-list > .header > .sort-wrapper.open > input {
    border-color: var(--oliver-blue);
    border-radius: 8px 8px 0 0;
  }

  .cv-list > .header > .sort-wrapper > input:hover {
    border-color: var(--oliver-blue);
    cursor: pointer;
  }

  .cv-list > .header > .sort-wrapper > .option-container {
    width: 100%;
    display: none;
  }

  .cv-list > .header > .sort-wrapper.open > .option-container {
    display: block;
  }

  .cv-list > .header > .sort-wrapper > .option-container > .option {
    width: 100%;
    height: 36px;
    background-color: #fff;
    border: 1px solid var(--oliver-border);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 10px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .cv-list > .header > .sort-wrapper > .option-container > .option:hover {
    cursor: pointer;
    color: var(--oliver-blue);
  }

  .cv-list > .header > .sort-wrapper > .option-container > .option:last-child {
    border-radius: 0 0 8px 8px;
  }

  .cv-list > .body {
    width: 100%;
    height: 0;
    flex-grow: 1;
    overflow: auto;
    background-color: #f8fafc;
    display: flex;
    flex-direction: column;
  }

  .cv-list > .body > .no-search-results-list {
    display: none;
    margin: 83px auto;
  }

  .cv-list > .body > .no-search-results-list:only-child {
    display: block;
  }

  .cv-list > .body > .no-search-results-list > p.style1 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-grey);
    margin-bottom: 10px;
  }

  .cv-list > .body > .no-search-results-list > p.style2 {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-grey);
  }

  .cv-list > .body > .filter-name {
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    border-bottom: 1px solid var(--oliver-border);
    padding: 0 16px;
    background-color: #f1f4f6;
    display: flex;
    align-items: center;
  }

  .cv-list > .body > .filter-name > p {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .cv-list > .body > button.customer-card {
    position: relative;
    width: 100%;
    height: 94px;
    background-color: #fff;
    box-sizing: border-box;
    border: 0;
    border-bottom: 1px solid var(--oliver-border);
    padding: 0 16px;
    display: flex;
    align-items: center;
  }

  .cv-list > .body > button.customer-card > .avatar {
    width: 36px;
    height: 36px;
    margin-right: 20px;
  }

  .cv-list > .body > button.customer-card > .text-group {
    width: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .cv-list > .body > button.customer-card > .text-group > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .cv-list > .body > button.customer-card > .text-group > p.style2 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-base-blue);
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .cv-list > .body > button.customer-card > .selected-indicator {
    display: none;
    width: 7px;
    height: 76px;
    position: absolute;
    left: 0;
    top: calc(50% - 38px);
    background-color: var(--oliver-blue);
    border-radius: 0 4px 4px 0;
  }

  .cv-list > .body > button.customer-card.selected {
    background-color: #e9f5fd;
  }

  .cv-list > .body > button.customer-card.selected p {
    color: var(--oliver-blue) !important;
  }

  .cv-list > .body > button.customer-card.selected > .avatar {
    background-color: var(--oliver-blue);
  }

  .cv-list > .body > button.customer-card.selected > .selected-indicator {
    display: block;
  }

  .cv-list > .mobile-footer {
    display: none;
  }

  /* Customer View Detailed */

  .cv-detailed {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 23px;
    overflow: auto;
  }

  .cv-detailed > .no-search-results-detailed {
    display: none;
    margin: auto;
  }

  .cv-detailed > .no-search-results-detailed:only-child {
    display: block;
  }

  .cv-detailed > .no-search-results-detailed > p.style1 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-grey);
    margin-bottom: 10px;
  }

  .cv-detailed > .no-search-results-detailed > p.style2 {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-grey);
  }

  .cv-detailed > .mobile-back {
    display: none;
  }

  .cv-detailed > .quick-info {
    width: 100%;
    display: flex;
    padding: 15px;
    margin: 20px 0 17px 0;
    background-color: #e9f5fd;
    box-sizing: border-box;
  }

  .cv-detailed > .quick-info > .avatar {
    width: 40px;
    height: 40px;
    background-color: var(--oliver-blue);
    margin: 4px 19px 0 0;
  }

  .cv-detailed > .quick-info > .text-group {
    margin-right: auto;
  }

  .cv-detailed > .quick-info > .text-group > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: var(--oliver-base-blue);
  }

  .cv-detailed > .quick-info > .text-group > p.style2 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .cv-detailed > .quick-info > button {
    width: 70px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid var(--oliver-blue);
    border-radius: 8px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .cv-detailed > .quick-info > button > img {
    width: 15px;
    height: 16px;
    margin-right: 5px;
  }

  .cv-detailed > .cust-totals {
    width: 100%;
    height: 83px;
    padding-bottom: 22px;
    border-bottom: 1px solid var(--oliver-border);
    display: flex;
  }

  .cv-detailed > .cust-totals > .col {
    flex: 1;
    display: flex;
    align-items: center;
    border-left: 1px solid var(--oliver-border);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .cv-detailed > .cust-totals > .col:nth-child(1) {
    border: 0;
  }

  .cv-detailed > .cust-totals > .col > p.style1 {
    font-family: Poppins;
    font-size: 22px;
    font-weight: 600;
    line-height: 27px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 4px;
  }

  .cv-detailed > .cust-totals > .col > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .cv-detailed > .cust-totals > .col:last-child > p.style2 {
    display: none;
  }

  .cv-detailed > .cust-totals > .col > button {
    background-color: transparent;
    border: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-blue);
    text-decoration: underline;
    display: flex;
    align-items: center;
  }

  .cv-detailed > .cust-totals > .col > button:before {
    content: "Store Credit";
  }

  .cv-detailed > .cust-totals > .col > button > img {
    width: 16px;
    height: 16px;
    margin-left: 5px;
  }

  .cv-detailed > .bill-ship-info {
    width: 100%;
    padding: 20px 0;
    display: flex;
    border-bottom: 1px solid var(--oliver-border);
  }

  .cv-detailed > .bill-ship-info > .col {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .cv-detailed > .bill-ship-info > .col:nth-child(1) {
    margin-right: 40px;
  }

  .cv-detailed > .bill-ship-info > .col > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 8px;
  }

  .cv-detailed > .bill-ship-info > .col > p.style2 {
    width: 100%;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .cv-detailed > .cust-notes {
    width: calc(100% + 46px);
    height: 0;
    flex-grow: 1;
    min-height: 100px;
    overflow: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px 0;
    margin: 0 -23px;
  }

  .cv-detailed > .cust-notes > .header-row {
    width: calc(100% - 46px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 11px;
  }

  .cv-detailed > .cust-notes > .header-row > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .cv-detailed > .cust-notes > .header-row > button {
    background-color: transparent;
    border: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: right;
    color: var(--oliver-blue);
    text-decoration: underline;
  }

  .cv-detailed > .cust-notes > .customer-note {
    width: calc(100% - 46px);
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  .cv-detailed > .cust-notes > .customer-note:nth-child(2) {
    margin-top: 0;
  }

  .cv-detailed > .cust-notes > .customer-note > .row {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .cv-detailed > .cust-notes > .customer-note > .row > p.style1 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
    margin-right: auto;
  }

  .cv-detailed > .cust-notes > .customer-note > .row > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: right;
    color: var(--oliver-base-blue);
    margin-right: 6px;
  }

  .cv-detailed > .cust-notes > .customer-note > .row > button {
    width: 17px;
    height: 17px;
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cv-detailed > .cust-notes > .customer-note > .row > button > img {
    width: 16px;
    height: 16px;
  }

  .cv-detailed > .cust-notes > .customer-note > .row > button:hover > img {
    filter: brightness(0) saturate(100%) invert(39%) sepia(60%) saturate(1129%)
      hue-rotate(317deg) brightness(105%) contrast(101%);
  }

  .cv-detailed > .cust-notes > .customer-note > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin: 10px 6px 0 10px;
  }

  .cv-detailed > .footer {
    width: 100%;
    height: 74px;
    box-sizing: border-box;
    border-top: 1px solid var(--oliver-border);
    display: flex;
    align-items: center;
  }

  .cv-detailed > .footer > button {
    flex: 1;
    height: 44px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
  }

  .cv-detailed > .footer > button:first-of-type {
    margin-right: 13px;
  }

  /* --------------------Transactions-------------------- */

  .transactions-wrapper {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 72px max(328px, 25vw) max(328px, 25vw) 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "navbar transactions-search transactions-list transactions-detailed";
  }

  .transactions-wrapper > .transactions-search {
    grid-area: transactions-search;
    box-sizing: border-box;
    border-left: 1px solid var(--oliver-border);
  }

  .transactions-wrapper > .transactions-list {
    grid-area: transactions-list;
    box-sizing: border-box;
    border-left: 1px solid var(--oliver-border);
  }

  .transactions-wrapper > .transactions-detailed {
    grid-area: transactions-detailed;
    box-sizing: border-box;
    border-left: 1px solid var(--oliver-border);
  }

  .transactions-wrapper > .mobile-transactions-header {
    display: none;
  }

  .transactions-wrapper > .mobile-refund.cart {
    display: none;
  }

  /* Transactions Search */

  .transactions-search {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 2;
  }

  .transactions-search > .search-header-mobile {
    display: none;
  }

  .transactions-search > .search-header {
    width: 100%;
    height: 68px;
    box-sizing: border-box;
    border-bottom: 1px solid var(--oliver-border);
    padding: 0 16px 0 25px;
    display: flex;
    align-items: center;
  }

  .transactions-search > .search-header > p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 29px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-right: auto;
  }

  .transactions-search > .search-header > button {
    width: 58px;
    height: 36px;
    background-color: #e7f4fe;
    border: 0;
    border-radius: 8px;
    padding: 4px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .transactions-search > .search-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 0;
    flex-grow: 1;
    overflow: auto;
    box-sizing: border-box;
    padding: 0 22px;
  }

  .transactions-search > .search-body > .mobile-only {
    display: none;
  }

  .transactions-search > .search-body label {
    width: 100%;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 4px;
  }

  .transactions-search > .search-body > label:first-of-type {
    margin-top: 13px;
  }

  .transactions-search > .search-body input {
    width: 100%;
    height: 44px;
    box-sizing: border-box;
    border: 1px solid var(--oliver-border);
    border-radius: 6px;
    outline: 0;
    padding: 0 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 20px;
  }

  .transactions-search > .search-body input:focus {
    border-color: var(--oliver-blue);
  }

  .transactions-search > .search-body > input:first-of-type {
    margin-bottom: 7px;
  }

  .transactions-search > .search-body > p {
    width: 100%;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .transactions-search > .search-body > .divider {
    width: 100%;
    height: 1px;
    background-color: var(--oliver-border);
    margin: 20px 0;
  }

  .transactions-search > .search-body > .dropdown-wrapper {
    position: relative;
    width: 100%;
    height: 44px;
    margin-bottom: 20px;
    line-height: 0;
  }

  .transactions-search > .search-body > .dropdown-wrapper.open {
    z-index: 2;
    box-shadow: 0px 10px 20px rgba(119, 135, 147, 0.3);
  }

  .transactions-search > .search-body > .dropdown-wrapper:hover {
    cursor: pointer;
  }

  .transactions-search > .search-body > .dropdown-wrapper > img {
    position: absolute;
    right: 14px;
    top: calc(50% - 9px);
    width: 18px;
    height: 18px;
  }

  .transactions-search > .search-body > .dropdown-wrapper.open > img {
    transform: rotate(180deg);
  }

  .transactions-search > .search-body > .dropdown-wrapper > input {
    width: 100%;
    height: 100%;
    margin: 0;
  }

  .transactions-search > .search-body > .dropdown-wrapper > input:focus {
    border-color: var(--oliver-border);
  }

  .transactions-search > .search-body > .dropdown-wrapper.open > input {
    border-radius: 6px 6px 0 0;
    border-color: var(--oliver-blue);
  }

  .transactions-search > .search-body > .dropdown-wrapper > input:hover {
    cursor: pointer;
  }

  .transactions-search > .search-body > .dropdown-wrapper > .option-list {
    display: none;
    width: 100%;
    max-height: 176px;
    height: fit-content;
    overflow: auto;
    border-radius: 0 0 7px 7px;
  }

  .transactions-search > .search-body > .dropdown-wrapper.open > .option-list {
    display: block;
  }

  .transactions-search
    > .search-body
    > .dropdown-wrapper
    > .option-list
    > .option {
    width: 100%;
    height: 44px;
    background-color: #fff;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 12px;
    border: 1px solid var(--oliver-border);
    border-top: 0;
  }

  .transactions-search
    > .search-body
    > .dropdown-wrapper
    > .option-list
    > .option:last-child {
    border-radius: 0 0 7px 7px;
  }

  .transactions-search
    > .search-body
    > .dropdown-wrapper
    > .option-list
    > .option
    > p {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .transactions-search
    > .search-body
    > .dropdown-wrapper
    > .option-list
    > .option:hover
    > p {
    color: var(--oliver-blue);
  }

  .transactions-search > .search-body > .input-row {
    width: 100%;
    display: flex;
  }

  .transactions-search > .search-body > .input-row > .input-col {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .transactions-search > .search-body > .input-row > .input-col:first-child {
    margin-right: 18px;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper {
    position: relative;
    margin-bottom: 20px;
    line-height: 0;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > input {
    margin-bottom: 0;
    padding-right: 32px;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > button {
    position: absolute;
    right: 12px;
    top: calc(50% - 10px);
    width: 20px;
    height: 20px;
    border: 0;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > button:hover
    > img {
    filter: brightness(0) saturate(100%) invert(41%) sepia(90%) saturate(579%)
      hue-rotate(166deg) brightness(106%) contrast(89%);
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > button
    > img {
    width: 100%;
    height: 100%;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector {
    position: fixed;
    box-sizing: border-box;
    padding: 25px 20px;
    border: 1px solid var(--oliver-border);
    background-color: #fff;
    z-index: 2;
    border-radius: 6px;
    margin-top: 7px;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0px 10px 20px rgba(119, 135, 147, 0.1));
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector.open {
    display: flex;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .header-row {
    width: 280px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .header-row
    > button {
    background-color: transparent;
    border: 0;
    /* border: 1px solid var(--oliver-blue); */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    padding: 0 10px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .header-row
    > button:hover {
    text-decoration: underline;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .header-row
    > button
    > img {
    width: 40px;
    height: auto;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .header-row
    > button.calendar-left {
    padding-left: 0;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .header-row
    > button.calendar-right {
    padding-right: 0;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .header-row
    > div {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .day-row {
    width: 280px;
    display: flex;
    border-bottom: 1px solid var(--oliver-border);
    margin-bottom: 10px;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .day-row
    > .day {
    flex: 1;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-base-grey);
    box-sizing: border-box;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .date-row {
    width: 280px;
    display: flex;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .date-row
    > button.cell {
    flex: 1;
    height: 40px;
    background-color: transparent;
    border: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-blue);
    border-radius: 6px;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .date-row
    > button.cell:hover:not(:disabled) {
    background-color: var(--oliver-blue);
    color: #fff;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .date-row
    > button.cell:disabled {
    color: var(--oliver-base-grey);
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .month-row {
    width: 320px;
    display: flex;
    margin-top: 15px;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .month-row
    > button.cell {
    flex: 1;
    height: 40px;
    background-color: transparent;
    border: 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-blue);
    border-radius: 6px;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .month-row
    > button.cell:hover {
    background-color: var(--oliver-blue);
    color: #fff;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .year-row {
    width: 280px;
    display: flex;
    margin-top: 15px;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .year-row
    > button.cell {
    flex: 1;
    height: 40px;
    background-color: transparent;
    border: 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-blue);
    border-radius: 6px;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .year-row
    > button.cell:hover {
    background-color: var(--oliver-blue);
    color: #fff;
  }

  .transactions-search > .search-body > button {
    width: 100%;
    height: 45px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin: auto 0 15px 0;
  }

  /* Transactions List */

  .transactions-list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .transactions-list > .header {
    width: 100%;
    height: 68px;
    box-sizing: border-box;
    border-bottom: 1px solid var(--oliver-border);
    display: flex;
    align-items: center;
    padding: 0 20px;
  }

  .transactions-list > .header > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-right: auto;
  }

  .transactions-list > .header > .sort-wrapper {
    width: 155px;
    height: 36px;
    position: relative;
    user-select: none;
    line-height: 0;
  }

  .transactions-list > .header > .sort-wrapper.open {
    z-index: 10;
    box-shadow: 0px 6px 10px rgba(94, 94, 94, 0.1);
  }

  .transactions-list > .header > .sort-wrapper > img {
    position: absolute;
    top: calc(50% - 6px);
    right: 9px;
    width: 13px;
    height: 13px;
    filter: brightness(0) saturate(100%) invert(53%) sepia(37%) saturate(1027%)
      hue-rotate(165deg) brightness(91%) contrast(99%);
  }

  .transactions-list > .header > .sort-wrapper.open > img {
    transform: rotate(180deg) translateY(-2px);
  }

  .transactions-list > .header > .sort-wrapper > input {
    width: 100%;
    height: 100%;
    border: 1px solid var(--oliver-border);
    border-radius: 8px;
    box-sizing: border-box;
    padding: 0 25px 0 10px;
    font-family: Poppins;
    font-size: 14px;
    line-height: 20px;
    color: var(--oliver-blue);
  }

  .transactions-list > .header > .sort-wrapper.open > input {
    border-color: var(--oliver-blue);
    border-radius: 8px 8px 0 0;
  }

  .transactions-list > .header > .sort-wrapper > input:hover {
    border-color: var(--oliver-blue);
    cursor: pointer;
  }

  .transactions-list > .header > .sort-wrapper > .option-container {
    width: 100%;
    display: none;
  }

  .transactions-list > .header > .sort-wrapper.open > .option-container {
    display: block;
  }

  .transactions-list > .header > .sort-wrapper > .option-container > .option {
    width: 100%;
    height: 36px;
    background-color: #fff;
    border: 1px solid var(--oliver-border);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 10px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .transactions-list
    > .header
    > .sort-wrapper
    > .option-container
    > .option:hover {
    cursor: pointer;
    color: var(--oliver-blue);
  }

  .transactions-list
    > .header
    > .sort-wrapper
    > .option-container
    > .option:last-child {
    border-radius: 0 0 8px 8px;
  }

  .transactions-list > .body {
    width: 100%;
    height: 0;
    flex-grow: 1;
    overflow: auto;
    background-color: #f8fafc;
    display: flex;
    flex-direction: column;
  }

  .transactions-list > .body > .no-results {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 58px;
    display: none;
    border: 0 !important;
  }

  .transactions-list > .body > .no-results:only-child {
    display: flex;
  }

  .transactions-list > .body > .no-results > p.style1 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-grey);
    margin-bottom: 10px;
  }

  .transactions-list > .body > .no-results > p.style2 {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-grey);
  }

  .transactions-list > .body > .filter-name {
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    border-top: 1px solid var(--oliver-border);
    background-color: #f1f4f6;
    display: flex;
    align-items: center;
    padding: 0 16px;
  }

  .transactions-list > .body > .filter-name > p {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .transactions-list > .body > :first-child {
    border-top: 0;
  }

  .transactions-list > .body > :last-child {
    border-bottom: 1px solid var(--oliver-border);
  }

  .transactions-list > .body > button {
    position: relative;
    width: 100%;
    height: 96px;
    box-sizing: border-box;
    background-color: #fff;
    border: 0;
    border-top: 1px solid var(--oliver-border);
    display: flex;
    padding: 10px 20px 10px 24px;
    line-height: 0;
  }

  .transactions-list > .body > button > .col {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .transactions-list > .body > button > .col:first-child {
    width: 0;
    flex-grow: 1;
    margin-right: 10px;
  }

  .transactions-list > .body > button > .col p {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .transactions-list > .body > button > .col > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .transactions-list > .body > button > .col > p.style2 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .transactions-list > .body > button > .col > .row {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: auto;
  }

  .transactions-list > .body > button > .col > .row > img {
    width: 14px;
    height: 12px;
    margin-right: 8px;
  }

  .transactions-list > .body > button > .col > .row > p {
    max-width: calc(100% - 22px);
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .transactions-list > .body > button > .col > p.style3 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: right;
    color: var(--oliver-base-blue);
    margin-bottom: auto;
  }

  .transactions-list > .body > button > .col > p.style4 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: right;
    color: var(--oliver-base-blue);
    font-style: italic;
    padding-right: 1px;
  }

  .transactions-list > .body > button > .selected-indicator {
    display: none;
    position: absolute;
    left: 0;
    top: calc(50% - 38px);
    width: 7px;
    height: 76px;
    background-color: var(--oliver-blue);
    border-radius: 0 4px 4px 0;
  }

  .transactions-list > .body > button.selected {
    background-color: #e9f5fd;
  }

  .transactions-list > .body > button.selected p {
    color: var(--oliver-blue) !important;
  }

  .transactions-list > .body > button.selected img {
    filter: brightness(0) saturate(100%) invert(68%) sepia(82%) saturate(3941%)
      hue-rotate(179deg) brightness(90%) contrast(103%);
  }

  .transactions-list > .body > button.selected > .selected-indicator {
    display: block;
  }

  /* Transaction Detailed */

  .transactions-detailed {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
  }

  .transactions-detailed > .no-orders-detailed {
    margin: auto 0;
    display: flex;
    flex-direction: column;
    display: none;
  }

  .transactions-detailed > .no-orders-detailed:only-child {
    display: flex;
  }

  .transactions-detailed > .no-orders-detailed > p.style1 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    color: var(--oliver-base-grey);
    margin-bottom: 10px;
  }

  .transactions-detailed > .no-orders-detailed > p.style2 {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-base-grey);
  }

  .transactions-detailed > .detailed-header-mobile {
    display: none;
  }

  .transactions-detailed > .scrollable {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 0;
    flex-grow: 1;
    overflow: auto;
    /* padding-top: 15px; */
  }

  .transactions-detailed > .scrollable > .quick-info {
    width: calc(100% - 40px);
    box-sizing: border-box;
    padding: 15px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    background-color: #e9f5fd;
  }

  /* Background color for refund */
  .transactions-detailed > .scrollable > .quick-info.refund {
    background-color: rgba(255, 82, 95, 0.1);
  }

  .transactions-detailed > .scrollable > .quick-info > .row {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }

  .transactions-detailed > .scrollable > .quick-info > .row:first-child {
    margin-top: 0;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > * {
    width: 0;
    flex: 1;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > p:nth-child(2) {
    text-align: right !important;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > p.style1 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--oliver-base-blue);
  }

  .transactions-detailed > .scrollable > .quick-info > .row > p.style2 {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .transactions-detailed > .scrollable > .quick-info > .row > p.style3 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .transactions-detailed > .scrollable > .quick-info > .row > p.style3 > b {
    font-weight: 600;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > .group {
    display: flex;
    align-items: center;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > .group > img {
    width: 18px;
    height: 16px;
    margin-right: 8px;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > .group > p {
    width: 0;
    flex-grow: 1;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  /* Color for refund state */
  .transactions-detailed > .scrollable > .quick-info > .row > .group > p.style1 {
    font-weight: 500;
    display: contents;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > .group {
    justify-content: end;
    gap: 6px;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > .group > p.style1 + img {
    cursor: pointer;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > .group > p.style1 + img:active {
    scale: 0.85;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > .group > p.style1 {
    color: var(--oliver-blue);
  }

  /* Color for refund state */
  .transactions-detailed > .scrollable > .quick-info.refund > .row > .group > p.style1 {
    color: var(--error);
  }

  .transactions-detailed > .scrollable > .quick-info.refund > .row > .group > p.style1 + img{
    filter: invert(100%) sepia(67%) saturate(2056%) hue-rotate(332deg) brightness(121%) contrast(103%);
  }

  .transactions-detailed > .scrollable > .quick-info.completed {
    background-color: #E9f5fd !important;
  }

  .transactions-detailed > .scrollable > .quick-info p > b.strikethrough {
    text-decoration: line-through !important;
  }

  .transactions-detailed > .scrollable > .customer-info {
    width: calc(100% - 40px);
    box-sizing: border-box;
    padding: 15px 0 20px 0;
    display: flex;
    border-bottom: 1px solid var(--oliver-border);
  }

  .transactions-detailed > .scrollable > .customer-info > .col {
    width: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

  .transactions-detailed > .scrollable > .customer-info > .col > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 8px;
  }

  .transactions-detailed > .scrollable > .customer-info > .col > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 4px;
    max-width: 95%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .transactions-detailed
    > .scrollable
    > .customer-info
    > .col
    > p:last-of-type {
    margin: 0;
  }

  .transactions-detailed > .scrollable > .customer-info > button {
    width: 154px;
    height: 40px;
    box-sizing: border-box;
    border: 1px solid var(--oliver-blue);
    border-radius: 6px;
    background-color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .transactions-detailed > .scrollable > .order-details {
    box-sizing: border-box;
    width: 100%;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    min-height: 100px;
  }

  .transactions-detailed > .scrollable > .order-details > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 24px;
  }

  .transactions-detailed > .scrollable > .order-details > .item {
    width: 100%;
    display: flex;
    margin-top: 20px;
  }

  .transactions-detailed > .scrollable > .order-details > .item:nth-child(2) {
    margin-top: 0;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .img-container {
    position: relative;
    width: 68px;
    height: 69px;
    margin-right: 24px;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .img-container
    > .quantity {
    position: absolute;
    min-width: 26px;
    height: 26px;
    padding: 0 3px;
    right: 0px;
    top: 0px;
    background-color: #fff;
    border-radius: 6px;
    box-sizing: border-box;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--oliver-blue);
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .img-container
    > .quantity
    > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #fff;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .img-container
    > img {
    width: 100%;
    height: auto;
    border-radius: 4px;
  }

  .transactions-detailed > .scrollable > .order-details > .item > .col {
    width: 0;
    flex-grow: 1;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .main-row {
    width: 100%;
    display: flex;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .main-row
    > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .main-row
    > p
    > b {
    color: var(--oliver-base-blue);
    font-weight: 500;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .main-row
    > p:first-of-type {
    color: var(--oliver-base-blue);
    margin-right: auto;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .item-fields {
    box-sizing: border-box;
    width: 100%;
    padding-top: 6px;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .item-fields
    > p {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .modifier-fields {
    width: 100%;
    box-sizing: border-box;
    padding: 8px 0 0 13px;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .modifier-fields
    > .row {
    width: 100%;
    display: flex;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .modifier-fields
    > .row
    > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    color: var(--oliver-base-grey);
    width: 0;
    flex: 1;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 10px;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .modifier-fields
    > .row
    > p:last-of-type {
    text-align: right;
    padding-right: 0;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .product-total-fields {
    width: 100%;
    padding-top: 8px;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .product-total-fields
    > div {
    width: 100%;
    padding-top: 6px;
    border-top: 1px solid var(--oliver-border);
    margin-top: 6px;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .product-total-fields
    > div:first-of-type {
    border-top: 0 !important;
    padding-top: 0 !important;
    margin-top: 0 !important;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .product-total-fields
    > div
    > .row {
    width: 100%;
    display: flex;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .product-total-fields
    > div
    > .row
    > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    color: var(--oliver-base-grey);
    width: 0;
    flex: 1;
    padding-right: 10px;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .product-total-fields
    > div
    > .row
    > p:last-of-type {
    text-align: right;
    padding-right: 0;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .product-total-fields
    > div
    > .row
    > p
    > b {
    font-weight: 500;
  }

  .transactions-detailed > .scrollable > .order-details > .custom-fields {
    width: 100%;
    margin-top: 15px;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .custom-fields
    > p.style1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-bottom: 7px;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .custom-fields
    > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
    margin-left: 14px;
    max-width: calc(100% - 14px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: 3px;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .custom-fields
    > p.style2:nth-child(2) {
    margin-top: 0;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper {
    width: 100%;
    box-sizing: border-box;
    padding: 15px 0;
    border-top: 2px solid var(--oliver-border);
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals {
    width: min(400px, 75%);
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row {
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 6px;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row.border-bottom {
    border-bottom: 1px solid var(--oliver-border);
    margin-bottom: 11px;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row.border-top {
    padding: 6px 0 0 0;
    border-top: 1px solid var(--oliver-border);
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row
    > p:nth-child(2) {
    text-align: right;
    width: 0;
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row
    > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row
    > p
    > b {
    font-weight: 500;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row
    > p
    > b.bold2 {
    font-weight: 600;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row
    > p.style2 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .transactions-detailed > .scrollable > .order-details > .refund-header {
    width: 100%;
    box-sizing: border-box;
    padding: 5px 8px;
    background-color: #f1f4f6;
    display: flex;
    align-items: center;
    margin: 25px 0 15px 0;
  }

  .transactions-detailed > .scrollable > .order-details > .refund-header > p {
    width: 0;
    flex: 1;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .refund-header
    > p.style1 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    padding-right: 10px;
    color: var(--oliver-base-blue);
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .refund-header
    > p.style2 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: right;
    color: var(--oliver-base-blue);
  }

  .transactions-detailed > .footer {
    width: 100%;
    box-sizing: border-box;
    padding: 15px 20px;
    border-top: 1px solid var(--oliver-border);
    display: flex;
    align-items: center;
  }

  .transactions-detailed > .footer > button {
    flex: 1;
    height: 44px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin-left: 12px;
  }

  .transactions-detailed > .footer > button#refundButtonLandscape {
    margin-left: 0;
  }

  .transactions-detailed > .footer > button#refundButtonMobile {
    display: none;
  }

  /* --------------------Cash Management-------------------- */

  .cash-management-wrapper {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 72px 33vw 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "navbar cm-list cm-detailed";
  }

  .cash-management-wrapper > .navbar {
    grid-area: navbar;
  }

  .cash-management-wrapper > .cm-list {
    grid-area: cm-list;
    border-left: 1px solid var(--oliver-border);
  }

  .cash-management-wrapper > .cm-detailed {
    grid-area: cm-detailed;
    border-left: 1px solid var(--oliver-border);
  }

  .cash-management-wrapper > .mobile-cm-header {
    display: none;
  }

  /* Cash Management List */

  .cm-list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .cm-list > .cm-list-header-landscape {
    width: 100%;
    height: 68px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 20px;
    border-bottom: 1px solid var(--oliver-border);
  }

  .cm-list > .cm-list-header-landscape > p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 29px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .cm-list > .cm-list-body {
    width: 100%;
    height: 0;
    flex-grow: 1;
    overflow: auto;
  }

  .cm-list > .cm-list-body > .current-register {
    width: 100%;
    height: 103px;
    box-sizing: border-box;
    border: 0;
    border-bottom: 1px solid var(--oliver-border);
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
  }

  .cm-list > .cm-list-body > .current-register.selected {
    background-color: #e9f5fd;
  }

  .cm-list > .cm-list-body > .current-register > .mobile-fake-button {
    display: none;
  }

  .cm-list > .cm-list-body > .current-register > .text-row {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }

  .cm-list > .cm-list-body > .current-register > .text-row > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-right: 7px;
  }

  .cm-list > .cm-list-body > .current-register > .text-row > p.open {
    font-weight: 600;
    color: var(--approval);
  }

  .cm-list > .cm-list-body > .current-register > p.style1 {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: var(--oliver-blue);
    margin-bottom: 5px;
  }

  .cm-list > .cm-list-body > .current-register > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .cm-list > .cm-list-body > .date {
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    border-bottom: 1px solid var(--oliver-border);
    background-color: #f1f4f6;
    display: flex;
    align-items: center;
    padding: 0 12px;
  }

  .cm-list > .cm-list-body > .date > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .cm-list > .cm-list-body > .other-register {
    position: relative;
    width: 100%;
    height: 76px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    border: 0;
    border-bottom: 1px solid var(--oliver-border);
    background-color: transparent;
    padding: 0 22px;
    line-height: 0;
  }

  .cm-list > .cm-list-body > .other-register.selected {
    background-color: #e9f5fd;
  }

  .cm-list > .cm-list-body > .other-register.selected p {
    color: var(--oliver-blue) !important;
  }

  .cm-list > .cm-list-body > .other-register.selected::before {
    content: "";
    width: 7px;
    height: 60px;
    position: absolute;
    left: 0;
    top: 8px;
    background-color: var(--oliver-blue);
    border-radius: 0 4px 4px 0;
  }

  .cm-list > .cm-list-body > .other-register > .row {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .cm-list > .cm-list-body > .other-register > .row:nth-child(1) {
    margin-bottom: 4px;
  }

  .cm-list > .cm-list-body > .other-register > .row > p.style1 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .cm-list > .cm-list-body > .other-register > .row > p.style2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .cm-list > .cm-list-body > .other-register > .row > p {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .cm-list > .cm-list-body > .other-register > .row > p:first-of-type {
    flex-grow: 1;
  }

  /* Cash Management Detailed */

  .cm-detailed {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .cm-detailed > .detailed-header-mobile {
    display: none;
  }

  .cm-detailed > .detailed-header-landscape {
    width: calc(100% - 40px);
    height: 67px;
    display: flex;
    align-items: center;
  }

  .cm-detailed > .detailed-header-landscape > img {
    width: 22px;
    height: 22px;
    margin-right: 8px;
  }

  .cm-detailed > .detailed-header-landscape > p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .cm-detailed > .detailed-quick-info {
    width: calc(100% - 40px);
    background-color: #eaf5ff;
    padding: 15px;
    box-sizing: border-box;
  }

  .cm-detailed > .detailed-quick-info > .row {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .cm-detailed > .detailed-quick-info > .row:first-child {
    margin-bottom: 10px;
  }

  .cm-detailed > .detailed-quick-info > .row > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .cm-detailed > .detailed-quick-info > .row > p.active {
    color: var(--oliver-blue);
  }

  .cm-detailed > .detailed-quick-info > .row > .row-group {
    display: flex;
    align-items: center;
    margin-right: auto;
  }

  .cm-detailed > .detailed-quick-info > .row > .row-group > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-right: 10px;
  }

  .cm-detailed > .detailed-quick-info > .row > .row-group > .status {
    height: 24px;
    padding: 0 8px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
  }

  .cm-detailed > .detailed-quick-info > .row > .row-group > .status.closed {
    background-color: var(--oliver-border);
    color: var(--oliver-base-blue);
  }

  .cm-detailed > .detailed-quick-info > .row > .row-group > .status.open {
    background-color: var(--oliver-teal);
    color: #fff;
  }

  .cm-detailed > .detailed-quick-info > .row > .col-group {
    margin-right: auto;
  }

  .cm-detailed > .detailed-quick-info > .row > .col-group > p.style1 {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .cm-detailed > .detailed-quick-info > .row > .col-group > p.style2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .cm-detailed > .detailed-quick-info > .row > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 88px;
    height: 40px;
    border: 1px solid var(--oliver-blue);
    border-radius: 8px;
    background-color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-blue);
  }

  .cm-detailed > .detailed-quick-info > .row > button > img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .cm-detailed > .detailed-body {
    width: 100%;
    height: 0;
    flex-grow: 1;
    overflow: auto;
    box-sizing: border-box;
    padding: 0 20px;
  }

  .cm-detailed > .detailed-body > .action {
    width: 100%;
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--oliver-border);
  }

  .cm-detailed > .detailed-body > .action:first-child {
    border-top: 0;
  }

  .cm-detailed > .detailed-body > .action > .header-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .cm-detailed > .detailed-body > .action > .header-row > p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .cm-detailed > .detailed-body > .action > .header-row > p.style2 {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    text-align: right;
    color: var(--oliver-base-grey);
  }

  .cm-detailed > .detailed-body > .action > .header-row > p.green {
    color: var(--approval);
  }

  .cm-detailed > .detailed-body > .action > .header-row > p.blue {
    color: var(--oliver-blue);
  }

  .cm-detailed > .detailed-body > .action > .header-row > p.red {
    color: var(--error);
  }

  .cm-detailed > .detailed-body > .action > .body-col {
    width: 100%;
  }

  .cm-detailed > .detailed-body > .action > .body-col > p {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-grey);
  }

  .cm-detailed > .detailed-body > .action > .footer-col {
    width: 100%;
    margin-top: 10px;
  }

  .cm-detailed > .detailed-body > .action > .footer-col > p {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--oliver-base-blue);
  }

  .cm-detailed > .detailed-body > .action > .footer-col > p > b {
    font-weight: 600;
  }

  .cm-detailed > .detailed-footer {
    width: 100%;
    padding: 20px 30px;
    border-top: 1px solid var(--oliver-border);
    display: flex;
    box-sizing: border-box;
  }

  .cm-detailed > .detailed-footer > button {
    flex: 1;
    height: 50px;
    margin-left: 30px;
    background-color: var(--oliver-blue);
    border: 0;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  .cm-detailed > .detailed-footer > button:first-of-type {
    margin-left: 0;
  }

  /* --------------------404 Page-------------------- */

  .error-404-wrapper {
    width: 100%;
    height: 100%;
    background-color: #f1f4f6;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .error-404-wrapper > .error-404-container {
    width: min(100%, 507px);
    height: min(100%, 555px);
    background-color: #fff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 20px;
    overflow: auto;
  }

  .error-404-wrapper > .error-404-container > img {
    width: 130px;
    height: 94px;
    margin: auto 0 35px 0;
    content: url("assets/images/svg/OliverIconError.svg");
  }

  .error-404-wrapper > .error-404-container > p.style1 {
    font-family: Poppins;
    font-size: 40px;
    font-weight: 700;
    line-height: 53px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 10px;
  }

  .error-404-wrapper > .error-404-container > p.style2 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    color: var(--oliver-blue);
    margin-bottom: 10px;
  }

  .error-404-wrapper > .error-404-container > p.style3 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-bottom: 35px;
  }

  .error-404-wrapper > .error-404-container > p.style3 > br {
    display: none;
  }

  .error-404-wrapper > .error-404-container > button {
    width: 173px;
    height: 50px;
    border: 0;
    border-radius: 6px;
    background-color: var(--oliver-blue);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin-bottom: 35px;
  }

  .error-404-wrapper > .error-404-container > .row {
    display: flex;
    align-items: center;
    margin-bottom: auto;
  }

  .error-404-wrapper > .error-404-container > .row > p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-base-blue);
    margin-right: 4px;
  }

  .error-404-wrapper > .error-404-container > .row > a {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    text-align: center;
    color: var(--oliver-blue);
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  ::-webkit-scrollbar {
    width: 0.293vw;
  }

  ::-webkit-scrollbar-button {
    height: 0.1953vw;
  }

  ::-webkit-scrollbar-thumb {
    width: 0.2441vw;
    border-radius: 0.293vw;
  }

  .avatar {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  input:focus,
  textarea:focus {
    border-width: 0.0977vw !important;
  }

  .login-wrapper > img {
    width: 6.9824vw;
    height: 2.4414vw;
    margin: 2.4414vw 0 0.9766vw 0;
  }

  .loading-page-wrapper {
    padding: 1.2207vw 0;
  }

  .loading-page-wrapper > img {
    width: 4.9316vw;
    height: 1.3672vw;
    margin-bottom: 2.9297vw;
  }

  .loading-page-wrapper > svg {
    width: 4.1504vw;
    height: 4.1504vw;
    margin-bottom: 2.9297vw;
  }

  .loading-page-wrapper > p.style1 {
    font-size: 0.9766vw;
    line-height: 1.2695vw;
    margin-bottom: 0.4883vw;
  }

  .loading-page-wrapper > p.style2 {
    font-size: 0.7813vw;
    line-height: 1.0254vw;
    margin-bottom: 2.9297vw;
  }

  .loading-page-wrapper > p.style3 {
    font-size: 0.6836vw;
    line-height: 0.8789vw;
  }

  @keyframes animate-svg-fill-8 {
    0% {
      transform: translate(1.2207vw, -1.2207vw);
      fill: transparent;
    }

    10%,
    40% {
      transform: translate(0px, 0px);
      fill: #ffd026;
    }

    50%,
    100% {
      transform: translate(-1.2207vw, 1.2207vw);
      fill: transparent;
    }
  }

  .login-wrapper > p {
    font-size: 0.7813vw;
    line-height: 1.123vw;
    margin-bottom: 2.1475vw;
  }

  .login-wrapper > .login-form {
    width: 16.9434vw;
    margin-bottom: 1.3672vw;
  }

  .login-wrapper > .login-form > label {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .login-wrapper > .login-form > input {
    height: 2.1484vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.0977vw;
    padding: 0 0.5859vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 1.3672vw;
  }

  .login-wrapper > .login-form > .row {
    margin-bottom: 1.3672vw;
  }

  .login-wrapper > .login-form > .row > a {
    font-size: 0.6836vw;
    line-height: 1.0254vw;
  }

  .login-wrapper > .login-form > .row > .custom-checkbox-wrapper {
    font-size: 0.5859vw;
    line-height: 1.0254vw;
  }

  .login-wrapper
    > .login-form
    > .row
    > .custom-checkbox-wrapper
    > input[type="checkbox"] {
    left: -488.2813vw;
  }

  .login-wrapper
    > .login-form
    > .row
    > .custom-checkbox-wrapper
    > .custom-checkbox {
    width: 0.9766vw;
    height: 0.9766vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.1953vw;
    margin-right: 0.5371vw;
  }

  .login-wrapper > .login-form > button {
    height: 2.3438vw;
    border-radius: 0.1953vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .login-wrapper > .or-row {
    width: 16.9434vw;
    margin-bottom: 1.3672vw;
  }

  .login-wrapper > .or-row > .divider {
    width: 6.2271vw;
    height: 0.0488vw;
  }

  .login-wrapper > .or-row > p {
    font-size: 0.9766vw;
    line-height: 1.0254vw;
  }

  .login-wrapper > button {
    width: 16.9434vw;
    height: 2.3438vw;
    padding-left: 2.3438vw;
    border-radius: 0.1953vw;
    margin-bottom: 0.7813vw;
    font-size: 0.8789vw;
    line-height: 1.0254vw;
  }

  .login-wrapper > button:last-of-type {
    margin-bottom: 2.6367vw;
  }

  .login-wrapper > button > .img-container {
    left: 0.0977vw;
    top: 0.0977vw;
    width: 2.1484vw;
    height: 2.1484vw;
    border-radius: 0.1953vw;
  }

  .login-wrapper > #googleButton > .img-container > img {
    width: 2.2065vw;
    height: 1.8853vw;
  }

  .login-wrapper > #facebookButton > .img-container > img {
    width: 1.2949vw;
    height: 1.0396vw;
  }

  .login-wrapper > #appleButton > .img-container > img {
    width: 1.9814vw;
    height: 1.3389vw;
  }

  .login-wrapper > .row {
    margin-bottom: 2.4414vw;
  }

  .login-wrapper > .row > p {
    font-size: 0.6836vw;
    line-height: 1.0254vw;
    margin-right: 0.2441vw;
  }

  .login-wrapper > .row > a {
    font-size: 0.6836vw;
    line-height: 1.0254vw;
  }

  .choose-wrapper > .choose-header {
    height: 3.125vw;
    padding: 0 0.9766vw;
  }

  .choose-wrapper > .choose-header > button {
    width: 5.3223vw;
    height: 1.9531vw;
    border: 0.0488vw solid var(--oliver-blue);
    border-radius: 0.293vw;
    font-size: 0.7813vw;
    line-height: 1.1719vw;
  }

  .choose-wrapper > .choose-header > button > img {
    width: 0.3467vw;
    height: 0.6934vw;
    margin-right: 0.4883vw;
  }

  .choose-wrapper > .choose-header > p {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
  }

  .choose-wrapper > .choose-body-default {
    padding: 2.6855vw 0;
  }

  .choose-wrapper > .choose-body-default button:disabled::after {
    background-size: 1.1719vw 1.1719vw;
    width: 1.1719vw;
    height: 1.1719vw;
    left: 0.3906vw;
    top: 0.4883vw;
  }

  .choose-wrapper > .choose-body-default > p {
    font-size: 1.0742vw;
    line-height: 1.5625vw;
    margin-bottom: 0.9277vw;
  }

  .choose-wrapper > .choose-body-default > .divider {
    width: 2.9297vw;
    height: 0.0488vw;
    margin-bottom: 2.1973vw;
  }

  .choose-wrapper > .choose-body-default > .button-container {
    width: 47.3633vw;
  }

  .choose-wrapper > .choose-body-default > .button-container > p {
    font-size: 0.8789vw;
    line-height: 1.1719vw;
    margin-bottom: 0.9766vw;
  }

  .choose-wrapper > .choose-body-default > .button-container > .divider {
    height: 0.0488vw;
    margin-bottom: 0.9766vw;
  }

  .choose-wrapper > .choose-body-default button {
    width: 8.4961vw;
    height: 12.5vw;
    margin: 0 1.2207vw 1.2207vw 0;
    border: 0.0488vw solid var(--oliver-ui-bubble-grey);
    border-radius: 0.3311vw;
    padding: 1.2832vw 0 0.9277vw 0;
    filter: drop-shadow(0vw 0.207vw 0.4139vw rgba(0, 0, 0, 0.1));
  }

  .choose-wrapper > .choose-body-default button > .img-container {
    width: 4.02vw;
    height: 4.02vw;
  }

  .choose-wrapper
    > .choose-body-default
    button
    > .img-container
    > img.oliver-icon {
    width: 1.7578vw;
    height: 1.7578vw;
    transform: translateX(0.0488vw);
  }

  .choose-wrapper
    > .choose-body-default
    button
    > .img-container
    > img.www-icon {
    width: 2.5391vw;
    height: 2.5391vw;
  }

  .choose-wrapper
    > .choose-body-default
    button
    > .img-container
    > img.store-icon {
    width: 1.9531vw;
    height: 1.8066vw;
  }

  .choose-wrapper
    > .choose-body-default
    button
    > .img-container
    > img.register-icon {
    width: 2.0996vw;
    height: 2.0996vw;
  }

  .choose-wrapper
    > .choose-body-default
    button
    > .img-container
    > img.kiosk-icon {
    width: 2.7344vw;
    height: 2.7344vw;
  }

  .choose-wrapper > .choose-body-default button > .col > p.style1 {
    font-size: 0.7324vw;
    line-height: 1.0742vw;
  }

  .choose-wrapper > .choose-body-default button > .col > p.style2 {
    font-size: 0.5859vw;
    line-height: 0.8301vw;
  }

  .choose-wrapper > .choose-body-default button > .fake-button {
    width: 6.1611vw;
    height: 2.3438vw;
    font-size: 0.6836vw;
    line-height: 1.1719vw;
    border-radius: 0.1953vw;
  }

  .choose-wrapper > .choose-body-default button.assigned > .fake-button {
    border: 0.0488vw solid var(--oliver-blue);
  }

  .choose-wrapper > .choose-body-default button#addNew {
    border: 0.0977vw dashed var(--oliver-border);
    font-size: 0.6836vw;
    line-height: 0.7324vw;
  }

  .choose-wrapper > .choose-body-default button#addNew > img.landscape {
    width: 0.9766vw;
    height: 0.9766vw;
    margin-right: 0.293vw;
  }

  .choose-wrapper > .choose-body-default .button-group {
    padding-bottom: 1.2207vw;
  }

  .subwindow-wrapper > .subwindow {
    border-radius: 0.3906vw;
    box-shadow: 0vw 0.7324vw 1.9531vw rgba(0, 0, 0, 0.3);
  }

  .subwindow-wrapper > .subwindow > .subwindow-header {
    min-height: 3.1738vw;
    border-bottom: 0.0488vw solid var(--oliver-border);
  }

  .subwindow-wrapper > .subwindow > .subwindow-header > p {
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper > .subwindow > .subwindow-header > button.close-subwindow {
    top: 0.7813vw;
    right: 0.7813vw;
    width: 1.5625vw;
    height: 1.5625vw;
    border-radius: 0.293vw;
  }

  .subwindow-wrapper
    > .subwindow
    > .subwindow-header
    > button.close-subwindow
    > img {
    width: 0.5859vw;
    height: 0.5859vw;
  }

  .subwindow-wrapper > .subwindow > .subwindow-body {
    border-radius: 0 0 0.3906vw 0.3906vw;
    padding: 0.9766vw 0;
  }

  .subwindow.update-plugin {
    width: min(24.6094vw, 95%);
    height: min(19.5313vw, 95%);
  }

  .subwindow.update-plugin > .subwindow-body > img {
    width: 3.0273vw;
    height: 3.0273vw;
    margin: auto 0 2.4414vw 0;
  }

  .subwindow.update-plugin > .subwindow-body > p.style1 {
    font-size: 0.8789vw;
    line-height: 1.1719vw;
    margin-bottom: 0.7324vw;
  }

  .subwindow.update-plugin > .subwindow-body > p.style2 {
    font-size: 0.7813vw;
    line-height: 1.0254vw;
    margin-bottom: 2.4414vw;
  }

  .subwindow.update-plugin > .subwindow-body > button {
    width: 9.668vw;
    height: 2.4414vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow.cart-no-item {
    width: min(24.6094vw, 95%);
    height: min(14.6484vw, 95%);
  }

  .subwindow.cart-no-item > .subwindow-body > p.style1 {
    font-size: 0.8789vw;
    line-height: 1.1719vw;
    margin: auto 0 0.7324vw 0;
  }

  .subwindow.cart-no-item > .subwindow-body > p.style2 {
    font-size: 0.7813vw;
    line-height: 1.0254vw;
    margin-bottom: 2.4414vw;
  }

  .subwindow.cart-no-item > .subwindow-body > button {
    width: 9.668vw;
    height: 2.4414vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow.discount-error {
    width: min(95%, 24.6094vw);
    height: min(95%, 19.5313vw);
  }

  .subwindow.discount-error > .subwindow-body > p.style1 {
    font-size: 0.8789vw;
    line-height: 1.1719vw;
    margin-bottom: 0.7324vw;
    margin: auto 0 0.7324vw 0;
  }

  .subwindow.discount-error > .subwindow-body > p.style2 {
    font-size: 0.7813vw;
    line-height: 1.0254vw;
    margin-bottom: 0.7324vw;
  }

  .subwindow.discount-error > .subwindow-body > p:last-of-type {
    margin-bottom: 2.4414vw;
  }

  .subwindow.discount-error > .subwindow-body > button {
    width: 9.668vw;
    height: 2.4414vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow.product-unsupported {
    width: 24.6094vw;
    height: 19.5313vw;
  }

  .subwindow.product-unsupported > .subwindow-body > p.style1 {
    margin: auto 0 1.6113vw 0;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow.product-unsupported > .subwindow-body > img {
    width: 2.4414vw;
    height: 2.5391vw;
    margin-bottom: 1.6113vw;
  }

  .subwindow.product-unsupported > .subwindow-body > p.style2 {
    font-size: 0.7813vw;
    line-height: 1.0254vw;
    margin-bottom: 1.6113vw;
  }

  .subwindow.product-unsupported > .subwindow-body > button {
    width: 9.668vw;
    height: 2.4414vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow.refund-show-payments {
    width: min(24.6094vw, 95%);
  }

  .subwindow.refund-show-payments > .subwindow-body {
    max-height: calc(95vh - 3.1738vw);
    padding: 0.9766vw 1.7578vw;
  }

  .subwindow.refund-show-payments > .subwindow-body > .row {
    margin-top: 0.5371vw;
  }

  .subwindow.refund-show-payments > .subwindow-body > .row > p {
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow.refund-show-payments > .subwindow-body > button {
    height: 2.4414vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
    margin-top: 1.6113vw;
  }

  .subwindow.no-permissions {
    width: min(24.6094vw, 95%);
    height: min(19.5313vw, 95%);
  }

  .subwindow.no-permissions > .subwindow-body > img {
    width: 2.4414vw;
    height: 2.4414vw;
    margin: auto 0 1.2207vw 0;
  }

  .subwindow.no-permissions > .subwindow-body > p.style1 {
    font-size: 0.8789vw;
    line-height: 1.1719vw;
    margin-bottom: 1.7578vw;
  }

  .subwindow.no-permissions > .subwindow-body > p.style2 {
    font-size: 0.7813vw;
    line-height: 1.0254vw;
    margin-bottom: 2.1484vw;
  }

  .subwindow.no-permissions > .subwindow-body > button {
    width: 9.668vw;
    height: 2.4414vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper > .subwindow.no-account {
    width: min(39.0625vw, 95%);
    height: min(31.7383vw, 95%);
  }

  .subwindow-wrapper > .subwindow.no-account > .subwindow-body > .content {
    padding: 2.4414vw 0 1.4648vw 0;
  }

  .subwindow-wrapper
    > .subwindow.no-account
    > .subwindow-body
    > .content
    > img {
    width: 2.1973vw;
    height: 2.1973vw;
    margin-bottom: 1.9531vw;
  }

  .subwindow-wrapper
    > .subwindow.no-account
    > .subwindow-body
    > .content
    > p.style1 {
    font-size: 0.7813vw;
    line-height: 0.9277vw;
    margin-bottom: 1.9531vw;
  }

  .subwindow-wrapper
    > .subwindow.no-account
    > .subwindow-body
    > .content
    > p.style2 {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 1.9531vw;
  }

  .subwindow-wrapper
    > .subwindow.no-account
    > .subwindow-body
    > .content
    > button#createAccount {
    width: 12.9395vw;
    height: 2.4414vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper
    > .subwindow.no-account
    > .subwindow-body
    > .content
    > button#loginTryAgain {
    width: 8.4473vw;
    height: 2.4414vw;
    border: 0.0488vw solid var(--oliver-blue);
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
    margin: 1.9531vw 0;
  }

  .subwindow-wrapper
    > .subwindow.no-account
    > .subwindow-body
    > .content
    > .row {
    font-size: 0.7813vw;
    line-height: 1.0254vw;
  }

  .subwindow-wrapper
    > .subwindow.no-account
    > .subwindow-body
    > .content
    > .row
    > a {
    margin-left: 0.1953vw;
  }

  .subwindow-wrapper > .subwindow.takeover-register {
    width: min(24.6094vw, 95%);
    height: min(20.5078vw, 95%);
  }

  .subwindow-wrapper > .subwindow.takeover-register > .subwindow-body > p {
    font-size: 0.8789vw;
    line-height: 1.2695vw;
    margin-bottom: 2.9297vw;
  }

  .subwindow-wrapper > .subwindow.takeover-register > .subwindow-body > button {
    width: 8.3008vw;
    height: 2.3438vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper
    > .subwindow.takeover-register
    > .subwindow-body
    > button#takeoverRegister {
    margin-bottom: 0.8301vw;
  }

  .subwindow-wrapper > .subwindow.register-taken {
    width: 23.9258vw;
    height: min(95%, 16.5527vw);
  }

  .subwindow-wrapper > .subwindow.register-taken > .subwindow-body > img {
    width: 2.4414vw;
    height: 2.5391vw;
    margin-bottom: 1.4648vw;
  }

  .subwindow-wrapper > .subwindow.register-taken > .subwindow-body > p {
    font-size: 0.7813vw;
    line-height: 1.123vw;
  }

  .subwindow.knowledge-base {
    width: min(48.8281vw, 95%);
    height: min(38.7695vw, 95%);
  }

  .subwindow.knowledge-base > .subwindow-header {
    padding: 0 3.2227vw 0 0.9277vw;
  }

  .subwindow.knowledge-base > .subwindow-header > .icon-container {
    width: 1.9531vw;
    height: 1.9531vw;
    border-radius: 0.3906vw;
    margin-right: 0.8301vw;
  }

  .subwindow.knowledge-base > .subwindow-header > p {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
  }

  .subwindow.knowledge-base > .subwindow-header > button#kBRefresh {
    width: 6.25vw;
    height: 1.5625vw;
    border-radius: 0.293vw;
    border: 0.0488vw solid var(--oliver-base-grey);
    font-size: 0.6348vw;
    line-height: 0.8301vw;
  }

  .subwindow.knowledge-base > .subwindow-header > button#kBRefresh > img {
    width: 0.6348vw;
    height: 0.6348vw;
    margin-right: 0.293vw;
  }

  .subwindow-wrapper > .subwindow.link-launcher-subwindow {
    width: 48.8281vw;
    height: min(95%, 38.7695vw);
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-header
    > img {
    margin: 0 0.6836vw 0 0.7813vw;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header {
    height: 3.6133vw;
    border-bottom: 0.0488vw solid var(--oliver-border);
    padding: 0 0.7813vw;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > .link-wrapper
    > img {
    width: 1.9531vw;
    height: 1.9531vw;
    border-radius: 0.293vw;
    margin-right: 0.7813vw;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > .link-wrapper
    > .text-col
    > p.style1 {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > .link-wrapper
    > .text-col
    > p.style2 {
    font-size: 0.5859vw;
    line-height: 0.8301vw;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > button {
    width: 6.25vw;
    height: 1.5625vw;
    border: 0.0488vw solid var(--oliver-base-grey);
    border-radius: 0.293vw;
    font-size: 0.6348vw;
    line-height: 0.8301vw;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > button
    > img {
    width: 0.6348vw;
    height: 0.6348vw;
    margin-right: 0.293vw;
  }

  .subwindow-wrapper > .subwindow.app-launcher-subwindow {
    width: 39.0625vw;
    height: calc(100% - 1.9531vw);
  }

  .subwindow-wrapper > .subwindow.app-launcher-subwindow.small {
    width: 29.2969vw;
  }

  .subwindow-wrapper > .subwindow.app-launcher-subwindow.large {
    width: calc(100% - 1.9531vw);
  }

  .subwindow-wrapper > .subwindow.app-launcher-subwindow > .subwindow-header {
    padding: 0.7813vw 2.3438vw 0.7813vw 0.7813vw;
  }

  .subwindow-wrapper
    > .subwindow.app-launcher-subwindow
    > .subwindow-header
    > .app-wrapper
    > img {
    width: 2.4414vw;
    height: 2.4414vw;
    border-radius: 0.4883vw;
    margin-right: 0.9277vw;
  }

  .subwindow-wrapper
    > .subwindow.app-launcher-subwindow
    > .subwindow-header
    > .app-wrapper
    > .text-col
    > p.style1 {
    font-size: 0.8789vw;
    line-height: 1.2207vw;
  }

  .subwindow-wrapper
    > .subwindow.app-launcher-subwindow
    > .subwindow-header
    > .app-wrapper
    > .text-col
    > p.style2 {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .subwindow.create-customer {
    width: 36.3281vw;
    height: min(95%, 53.2227vw);
  }

  .subwindow.create-customer > .subwindow-body {
    padding: 0.9766vw 3.3203vw;
  }

  .subwindow.create-customer > .subwindow-body > div:not(.button-row) {
    border-top: 0.0488vw solid var(--oliver-border);
    padding-top: 0.7324vw;
    margin-top: 0.7813vw;
  }

  .subwindow.create-customer > .subwindow-body > div > p {
    font-size: 0.7324vw;
    line-height: 1.123vw;
  }

  .subwindow.create-customer > .subwindow-body > div > .input-row {
    margin-top: 0.7813vw;
  }

  .subwindow.create-customer > .subwindow-body > div > .input-row > .input-col {
    margin-left: 0.6836vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > label {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    padding-bottom: 0.1953vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input {
    height: 2.0508vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.293vw;
    padding: 0 0.5859vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input:focus {
    border-width: 0.0977vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input.error
    ~ .error-wrapper::after {
    font-size: 0.6348vw;
    line-height: 0.9277vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper {
    height: 2.0508vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open {
    box-shadow: 0vw 0.4883vw 0.9766vw rgba(119, 135, 147, 0.1);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > input {
    padding-right: 1.9531vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > input:focus {
    border-width: 0.0488vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open
    > input {
    border-radius: 0.293vw 0.293vw 0 0;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > img {
    width: 0.9766vw;
    height: 0.9766vw;
    right: 0.5859vw;
    top: calc(50% - 0.4883vw);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open
    > img {
    transform: rotate(180deg) translateY(-0.0977vw);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container {
    border: 0.0488vw solid var(--oliver-blue);
    border-radius: 0 0 0.293vw 0.293vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option {
    height: 2.0508vw;
    padding: 0 0.5859vw;
    border-radius: 0 0 0.293vw 0.293vw;
    border-top: 0.0488vw solid var(--oliver-blue);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option
    > p {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .subwindow.create-customer > .subwindow-body > div > .title-row > p {
    font-size: 0.7324vw;
    line-height: 1.123vw;
  }

  .subwindow.create-customer > .subwindow-body > div > .title-row > label {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > input {
    left: -488.2813vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > .custom-checkbox {
    width: 0.8789vw;
    height: 0.8789vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.1953vw;
    margin-right: 0.5859vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > .custom-checkbox
    > img {
    width: 0.4883vw;
    height: 0.4883vw;
  }

  .subwindow.create-customer > .subwindow-body > .button-row {
    margin-top: 1.4648vw;
  }

  .subwindow.create-customer > .subwindow-body > .button-row > button {
    margin-left: 0.6836vw;
    height: 2.1484vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow.edit-customer {
    width: 36.3281vw;
    height: min(95%, 53.2227vw);
  }

  .subwindow.edit-customer > .subwindow-body {
    padding: 0.9766vw 3.3203vw;
  }

  .subwindow.edit-customer > .subwindow-body > div:not(.button-row) {
    border-top: 0.0488vw solid var(--oliver-border);
    padding-top: 0.7324vw;
    margin-top: 0.7813vw;
  }

  .subwindow.edit-customer > .subwindow-body > div > p {
    font-size: 0.7324vw;
    line-height: 1.123vw;
  }

  .subwindow.edit-customer > .subwindow-body > div > .input-row {
    margin-top: 0.7813vw;
  }

  .subwindow.edit-customer > .subwindow-body > div > .input-row > .input-col {
    margin-left: 0.6836vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > label {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    padding-bottom: 0.1953vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input {
    height: 2.0508vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.293vw;
    padding: 0 0.5859vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input:focus {
    border-width: 0.0977vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input.error
    ~ .error-wrapper::after {
    font-size: 0.6348vw;
    line-height: 0.9277vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper {
    height: 2.0508vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open {
    box-shadow: 0vw 0.4883vw 0.9766vw rgba(119, 135, 147, 0.1);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > input {
    padding-right: 1.9531vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > input:focus {
    border-width: 0.0488vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open
    > input {
    border-radius: 0.293vw 0.293vw 0 0;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > img {
    width: 0.9766vw;
    height: 0.9766vw;
    right: 0.5859vw;
    top: calc(50% - 0.4883vw);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open
    > img {
    transform: rotate(180deg) translateY(-0.0977vw);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container {
    border: 0.0488vw solid var(--oliver-blue);
    border-radius: 0 0 0.293vw 0.293vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option {
    height: 2.0508vw;
    padding: 0 0.5859vw;
    border-radius: 0 0 0.293vw 0.293vw;
    border-top: 0.0488vw solid var(--oliver-blue);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option
    > p {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .subwindow.edit-customer > .subwindow-body > div > .title-row > p {
    font-size: 0.7324vw;
    line-height: 1.123vw;
  }

  .subwindow.edit-customer > .subwindow-body > div > .title-row > label {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > input {
    left: -488.2813vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > .custom-checkbox {
    width: 0.8789vw;
    height: 0.8789vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.1953vw;
    margin-right: 0.5859vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > .custom-checkbox
    > img {
    width: 0.4883vw;
    height: 0.4883vw;
  }

  .subwindow.edit-customer > .subwindow-body > .button-row {
    margin-top: 1.4648vw;
  }

  .subwindow.edit-customer > .subwindow-body > .button-row > button {
    margin-left: 0.6836vw;
    height: 2.1484vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > .button-row
    > button#editCustomerDelete {
    border: 0.0488vw solid var(--error);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > .button-row
    > button#editCustomerDelete
    > img {
    width: 0.9766vw;
    height: 1.0254vw;
    margin-right: 0.4883vw;
  }

  .subwindow.delete-customer-confirm {
    width: min(24.6094vw, 95%);
    height: min(14.4043vw, 95%);
  }

  .subwindow.delete-customer-confirm > .subwindow-body > p {
    font-size: 0.7813vw;
    line-height: 1.0254vw;
    margin-bottom: 1.2695vw;
  }

  .subwindow.delete-customer-confirm > .subwindow-body > button {
    width: 8.3008vw;
    height: 2.3438vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow.delete-customer-confirm > .subwindow-body > button#deleteCustomer {
    margin-bottom: 0.8301vw;
  }

  .subwindow.delete-customer-confirm
    > .subwindow-body
    > button#cancelDeleteCustomer {
    border: 0.0488vw solid var(--oliver-blue);
  }

  .subwindow.custom-fee {
    width: 24.6094vw;
    height: min(26.5625vw, 95%);
  }

  .subwindow.cart-discount {
    width: 24.6094vw;
    height: 37.0117vw;
  }

  .subwindow.custom-fee > .subwindow-body > p,
  .subwindow.cart-discount > .subwindow-body > p {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 0.4395vw;
  }

  .subwindow.custom-fee > .subwindow-body > .toggle-container,
  .subwindow.cart-discount > .subwindow-body > .toggle-container {
    width: 13.916vw;
    height: 2.4414vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.3906vw;
    padding: 0.293vw;
    margin-bottom: 1.1719vw;
  }

  .subwindow.custom-fee > .subwindow-body > .toggle-container > label > input,
  .subwindow.cart-discount
    > .subwindow-body
    > .toggle-container
    > label
    > input {
    left: -488.2813vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > .toggle-container
    > label
    > .custom-radio,
  .subwindow.cart-discount
    > .subwindow-body
    > .toggle-container
    > label
    > .custom-radio {
    border-radius: 0.293vw;
    font-size: 0.7813vw;
    line-height: 1.0254vw;
  }

  .subwindow.custom-fee > .subwindow-body > #customFeeDiv {
    width: 16.9922vw;
  }

  .subwindow.custom-fee > .subwindow-body > #customFeeDiv > label {
    padding-bottom: 0.3906vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > input#customFeeLabel {
    height: 2.1484vw;
    margin-bottom: 0.6836vw;
    padding: 0 0.5859vw;
    border-radius: 0.293vw;
    border: 0.0488vw solid var(--oliver-border);
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > input#customFeeLabel:focus {
    border-width: 0.0977vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > input#customFeeAmount {
    height: 4.0527vw;
    border-radius: 0.293vw;
    border: 0.0488vw solid var(--oliver-border);
    padding: 0 0.5859vw;
    font-size: 1.9531vw;
    line-height: 2.7344vw;
    margin-bottom: 0.3906vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > input#customFeeAmount:focus {
    border-width: 0.0977vw;
  }

  .subwindow.custom-fee > .subwindow-body > #customFeeDiv > p {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 0.9766vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > label.custom-toggle-wrapper {
    font-size: 0.7813vw;
    line-height: 1.0742vw;
    padding-bottom: 1.4648vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > label.custom-toggle-wrapper
    > input {
    left: -488.2813vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > label.custom-toggle-wrapper
    > .custom-toggle {
    width: 1.9531vw;
    height: 1.2207vw;
    border-radius: 1.2207vw;
    margin-left: 0.9766vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > label.custom-toggle-wrapper
    > .custom-toggle::after {
    top: calc(50% - 0.5371vw);
    left: 0.0732vw;
    width: 1.0742vw;
    height: 1.0742vw;
    box-shadow: 0vw 0.0977vw 0.293vw rgba(0, 0, 0, 0.15),
      0vw 0.0977vw 0.0488vw rgba(0, 0, 0, 0.06);
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > label.custom-toggle-wrapper
    > input:checked
    ~ .custom-toggle::after {
    right: 0.0732vw;
  }

  .subwindow.custom-fee > .subwindow-body > #customFeeDiv > button {
    height: 2.4414vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow.cart-discount > .subwindow-body > #cartDiscountDiv > .main {
    padding: 1.0742vw 0 1.4648vw 0;
    border-top: 0.0488vw solid var(--oliver-border);
    border-bottom: 0.0488vw solid var(--oliver-border);
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > label {
    width: 16.9922vw;
    padding-bottom: 0.4395vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > input {
    width: 16.9922vw;
    height: 4.0527vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.293vw;
    padding: 0 0.5859vw;
    font-size: 1.9531vw;
    line-height: 2.7344vw;
    margin-bottom: 1.123vw;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > input:focus {
    border-width: 0.0977vw;
  }

  .subwindow.cart-discount > .subwindow-body > #cartDiscountDiv > .main > p {
    width: 16.9922vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 0.4883vw;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > .button-row {
    width: 16.9922vw;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > .button-row
    > button {
    height: 2.1484vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
    margin-left: 0.7813vw;
  }

  .subwindow.cart-discount > .subwindow-body > #cartDiscountDiv > .list {
    padding-top: 1.2207vw;
  }

  .subwindow.cart-discount > .subwindow-body > #cartDiscountDiv > .list > p {
    font-size: 0.6836vw;
    line-height: 0.8789vw;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .list
    > button {
    width: 13.916vw;
    height: 2.1484vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.293vw;
    margin-top: 0.7324vw;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .list
    > button
    > p {
    font-size: 0.7813vw;
    line-height: 1.0254vw;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .list
    > button#clearCartDiscountDiv
    > p {
    font-size: 0.6836vw;
    line-height: 0.8789vw;
  }

  .subwindow-wrapper > .subwindow.add-tile {
    width: 24.6094vw;
    height: min(20.5078vw, 95%);
  }

  .subwindow-wrapper > .subwindow.add-tile > .subwindow-body > label {
    width: 21.0938vw;
    font-size: 0.6836vw;
    line-height: 0.8789vw;
    margin-bottom: 0.3906vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search {
    width: 21.0938vw;
    height: 2.1484vw;
    margin-bottom: 1.3672vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > img.search {
    width: 1.1719vw;
    height: 1.1719vw;
    left: 0.4883vw;
    top: 0.4883vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > button#cancelDropdownSearch {
    width: 0.8789vw;
    height: 0.8789vw;
    right: 0.7324vw;
    top: 0.708vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > input {
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.293vw;
    padding: 0 1.8555vw 0 1.9531vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search.open
    > input {
    border-radius: 0.293vw 0.293vw 0 0;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .error-message {
    top: -0.3906vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .error-message::after {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .option-container {
    border-radius: 0 0 0.293vw 0.293vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .option-container
    > .dropdown-option {
    height: 2.1484vw;
    padding: 0 0.5859vw;
    border: 0.0488vw solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .option-container
    > .dropdown-option
    > p {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .dropdown-option:last-child {
    border-radius: 0 0 0.293vw 0.293vw;
  }

  .subwindow-wrapper > .subwindow.add-tile > .subwindow-body > p {
    width: 21.0938vw;
    font-size: 0.6836vw;
    line-height: 0.8789vw;
    margin-bottom: 0.4883vw;
  }

  .subwindow-wrapper > .subwindow.add-tile > .subwindow-body > .radio-group {
    margin-bottom: 1.9043vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .radio-group
    > label {
    margin-right: 0.7324vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .radio-group
    > label
    > input[type="radio"] {
    left: -488.2813vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .radio-group
    > label
    > .custom-radio-button {
    width: 1.9531vw;
    height: 1.9531vw;
    border-radius: 0.3906vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .radio-group
    > label
    > input[type="radio"]:checked
    ~ .custom-radio-button {
    border: 0.0977vw solid #000 !important;
  }

  .subwindow-wrapper > .subwindow.add-tile > .subwindow-body > button {
    width: 21.0938vw;
    height: 2.1484vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper > .subwindow.add-order-note {
    width: 24.6094vw;
    height: min(20.5078vw, 95%);
  }

  .subwindow-wrapper > .subwindow.add-order-note > .subwindow-body > label {
    width: 20.7031vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 0.4395vw;
  }

  .subwindow-wrapper > .subwindow.add-order-note > .subwindow-body > textarea {
    width: 20.7031vw;
    height: 9.2285vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.293vw;
    padding: 0.5859vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 0.9766vw;
  }

  .subwindow-wrapper > .subwindow.add-order-note > .subwindow-body > button {
    width: 20.7031vw;
    height: 2.3438vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper > .subwindow.product-not-found {
    width: 24.6094vw;
    height: min(19.5313vw, 95%);
  }

  .subwindow-wrapper > .subwindow.product-not-found > .subwindow-body {
    border-radius: 0.3906vw;
  }

  .subwindow-wrapper
    > .subwindow.product-not-found
    > .subwindow-body
    > p.style1 {
    font-size: 0.8789vw;
    line-height: 1.1719vw;
    margin-bottom: 1.1719vw;
  }

  .subwindow-wrapper > .subwindow.product-not-found > .subwindow-body > img {
    width: 3.9551vw;
    height: 2.5391vw;
    margin-bottom: 1.9531vw;
    transform: translateX(0.4883vw);
  }

  .subwindow-wrapper
    > .subwindow.product-not-found
    > .subwindow-body
    > p.style2 {
    font-size: 0.7813vw;
    line-height: 1.0254vw;
    margin-bottom: 1.7578vw;
  }

  .subwindow-wrapper > .subwindow.product-not-found > .subwindow-body > button {
    width: 9.668vw;
    height: 2.4414vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper > .subwindow.upgrade-to-unlock {
    width: 24.6094vw;
    height: min(19.5313vw, 95%);
  }

  .subwindow-wrapper > .subwindow.upgrade-to-unlock > .subwindow-body {
    border-radius: 0.3906vw;
  }

  .subwindow-wrapper > .subwindow.upgrade-to-unlock > .subwindow-body > img {
    width: 2.4414vw;
    height: 2.4414vw;
    margin-bottom: 1.2207vw;
  }

  .subwindow-wrapper
    > .subwindow.upgrade-to-unlock
    > .subwindow-body
    > p.style1 {
    font-size: 0.8789vw;
    line-height: 1.1719vw;
    margin-bottom: 1.7578vw;
  }

  .subwindow-wrapper
    > .subwindow.upgrade-to-unlock
    > .subwindow-body
    > p.style2 {
    font-size: 0.7813vw;
    line-height: 1.0254vw;
    margin-bottom: 2.1484vw;
  }

  .subwindow-wrapper > .subwindow.upgrade-to-unlock > .subwindow-body > button {
    width: 9.668vw;
    height: 2.4414vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper > .subwindow.advanced-search {
    width: 41.0156vw;
  }

  .subwindow-wrapper > .subwindow.advanced-search > .subwindow-header {
    height: 6.2012vw;
    border-radius: 0.3906vw 0.3906vw 0 0;
  }

  .subwindow-wrapper > .subwindow.advanced-search > .subwindow-header > p {
    font-size: 1.2207vw;
    line-height: 1.6113vw;
    margin-bottom: 1.0742vw;
  }

  .subwindow-wrapper > .subwindow.advanced-search > .subwindow-header > button {
    width: 1.5625vw;
    height: 1.5625vw;
    top: 0.7813vw;
    right: 0.7813vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper {
    width: 37.1094vw;
    height: 2.6855vw;
    top: 4.834vw;
    left: 1.9531vw;
    filter: drop-shadow(0vw 0.7324vw 1.4648vw rgba(0, 0, 0, 0.1));
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > #advSearchInputIcon {
    width: 1.4648vw;
    height: 1.416vw;
    top: 0.6348vw;
    left: 0.5371vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > #advSearchInputCancel {
    width: 1.4648vw;
    height: 1.4648vw;
    right: 0.5371vw;
    top: 0.6348vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > #advSearchInputCancel
    > img {
    width: 0.8545vw;
    height: 0.8545vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > input {
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.293vw;
    padding: 0 2.5391vw 0 2.5391vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col {
    width: 12.6953vw;
    padding-top: 3.6133vw;
    border-right: 0.0488vw solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > p {
    width: 9.5703vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 0.7324vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > p:last-of-type {
    margin-bottom: 0.3906vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group {
    width: 9.5703vw;
    margin-bottom: 1.6113vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > label {
    margin-bottom: 0.5859vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > label
    > input[type="radio"] {
    left: -488.2813vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > label
    > .custom-radio {
    width: 0.8789vw;
    height: 0.8789vw;
    border: 0.0488vw solid var(--oliver-border);
    margin-right: 0.3906vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > label
    > .custom-radio
    > img {
    width: 0.3906vw;
    height: 0.3906vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > label
    > p {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .recent-searches {
    width: 9.5703vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .recent-searches
    > a {
    font-size: 0.6348vw;
    line-height: 0.8789vw;
    margin-bottom: 0.3906vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col {
    padding-top: 2.6367vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .start-searching {
    padding-bottom: 2.6367vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .start-searching
    > img {
    width: 2.0996vw;
    height: 2.0508vw;
    margin: auto 0 1.4648vw 0;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .start-searching
    > p.style1 {
    font-size: 0.8789vw;
    line-height: 1.2207vw;
    margin-bottom: 0.9766vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .start-searching
    > p.style2 {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .header {
    border-bottom: 0.0488vw solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .header
    > p {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin: 0 0 0.6836vw 1.0742vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body {
    padding: 1.0742vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results
    > p.style1 {
    font-size: 0.8789vw;
    line-height: 1.2207vw;
    margin-bottom: 0.2441vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results
    > p.style2 {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results
    > .divider {
    width: 4.8828vw;
    height: 0.0488vw;
    margin: 1.4648vw 0;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results
    > button {
    width: 10.9863vw;
    height: 1.9531vw;
    border: 0.0488vw solid var(--oliver-blue);
    border-radius: 0.293vw;
    font-size: 0.6836vw;
    line-height: 0.8789vw;
    margin-top: 0.4883vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results
    > button
    > img {
    width: 0.9766vw;
    height: 0.9766vw;
    margin-right: 0.5859vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result {
    height: 7.2266vw;
    border-radius: 0.293vw;
    padding: 0.8789vw 0.9766vw;
    margin-top: 0.6836vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .col
    > p.style1 {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 0.6348vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .col
    > p.style2 {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 0.1953vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .col
    > p.style3 {
    font-size: 0.5859vw;
    line-height: 0.8301vw;
    margin-bottom: 0.1953vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button {
    width: 3.418vw;
    height: 3.1738vw;
    border-radius: 0.293vw;
    margin-right: 0.3906vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-view {
    font-size: 0.4883vw;
    line-height: 0.6836vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-view
    > img {
    width: 0.9766vw;
    height: 0.9766vw;
    margin-bottom: 0.1465vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-transactions {
    font-size: 0.3906vw;
    line-height: 0.5371vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-transactions
    > img {
    width: 1.1719vw;
    height: 1.1719vw;
    margin-bottom: 0.1465vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-add-to-sale {
    font-size: 0.3906vw;
    line-height: 0.5371vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-add-to-sale
    > img {
    width: 1.1719vw;
    height: 1.1719vw;
    margin-bottom: 0.1465vw;
  }

  .subwindow-wrapper > .subwindow.switch-user {
    width: 24.6094vw;
    height: min(38.0859vw, 95%);
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > .group {
    margin-bottom: 0.8301vw;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > .group > img {
    width: 0.9277vw;
    height: 0.9766vw;
    margin-right: 0.5371vw;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > .group > p {
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > p.style1 {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 1.1719vw;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > .divider {
    width: 3.1738vw;
    height: 0.0488vw;
    margin-bottom: 1.1719vw;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > p.style2 {
    font-size: 0.9766vw;
    line-height: 1.416vw;
    margin-bottom: 1.1719vw;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > .pinpad {
    width: 13.5742vw;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > p.error-message {
    padding: 0.5371vw 1.3672vw;
    border-radius: 0.3906vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin: -0.4883vw 0 0.9766vw 0;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-entries {
    margin-bottom: 0.8301vw;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-entries
    > .pin-entry {
    width: 0.7813vw;
    height: 0.7813vw;
    margin-left: 0.4883vw;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row {
    gap: 0.7813vw;
    margin-bottom: 0.7813vw;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row
    > button {
    width: 4.0039vw;
    height: 4.0039vw;
    border: 0.0488vw solid #b0bec9;
    border-radius: 0.3906vw;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row
    > button.backspace
    > img {
    width: 2.4414vw;
    height: 1.9531vw;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row
    > button
    > p {
    font-size: 1.7578vw;
    line-height: 1.7578vw;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row
    > button
    > .text-row {
    font-size: 0.4883vw;
    line-height: 0.7324vw;
    letter-spacing: 0vw;
  }

  .subwindow-wrapper > .subwindow.end-session {
    width: 24.6094vw;
    height: min(19.5313vw, 95%);
  }

  .subwindow-wrapper > .subwindow.end-session > .subwindow-body > p.style1 {
    font-size: 0.8789vw;
    line-height: 1.1719vw;
    margin-bottom: 0.8301vw;
  }

  .subwindow-wrapper > .subwindow.end-session > .subwindow-body > p.style2 {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 1.709vw;
  }

  .subwindow-wrapper > .subwindow.end-session > .subwindow-body > button {
    width: 11.2305vw;
    height: 2.4414vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper > .subwindow.partial-payment {
    width: 24.6094vw;
    height: min(26.1719vw, 95%);
  }

  .subwindow-wrapper > .subwindow.partial-payment > .subwindow-body > label {
    width: 16.9922vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 0.4395vw;
  }

  .subwindow-wrapper > .subwindow.partial-payment > .subwindow-body > input {
    width: 16.9922vw;
    height: 4.0527vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.293vw;
    font-size: 1.9531vw;
    line-height: 2.7344vw;
    margin-bottom: 1.709vw;
  }

  .subwindow-wrapper > .subwindow.partial-payment > .subwindow-body > p {
    font-size: 0.7813vw;
    line-height: 0.9766vw;
    margin-bottom: 0.5859vw;
  }

  .subwindow-wrapper
    > .subwindow.partial-payment
    > .subwindow-body
    > .payment-types {
    width: 16.9922vw;
  }

  .subwindow-wrapper
    > .subwindow.partial-payment
    > .subwindow-body
    > .payment-types
    > button {
    width: 5.1758vw;
    height: 5.1758vw;
    margin: 0 0.6348vw 0.6348vw 0;
    border-radius: 0.3906vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper > .subwindow.product-discount {
    width: 24.6094vw;
    height: min(20.5078vw, 95%);
  }

  .subwindow-wrapper > .subwindow.product-discount > .subwindow-body > label {
    width: 16.9922vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 0.4395vw;
  }

  .subwindow-wrapper > .subwindow.product-discount > .subwindow-body > input {
    width: 16.9922vw;
    height: 4.0527vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.293vw;
    font-size: 1.9531vw;
    line-height: 2.7344vw;
    margin-bottom: 1.123vw;
  }

  .subwindow-wrapper > .subwindow.product-discount > .subwindow-body > p {
    width: 16.9922vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 0.4883vw;
  }

  .subwindow-wrapper
    > .subwindow.product-discount
    > .subwindow-body
    > .button-row {
    width: 16.9922vw;
  }

  .subwindow-wrapper
    > .subwindow.product-discount
    > .subwindow-body
    > .button-row
    > button {
    height: 2.1484vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper
    > .subwindow.product-discount
    > .subwindow-body
    > .button-row
    > button:first-of-type {
    margin-right: 0.7813vw;
  }

  .subwindow-wrapper > .subwindow.adjust-inventory {
    width: 24.6094vw;
    height: min(25.9277vw, 95%);
  }

  .subwindow-wrapper > .subwindow.adjust-inventory > .subwindow-body > p {
    font-size: 0.8789vw;
    line-height: 1.2207vw;
    margin-bottom: 0.5371vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > p:first-of-type {
    margin-top: 0.5371vw;
  }

  .subwindow-wrapper > .subwindow.adjust-inventory > .subwindow-body > label {
    font-size: 0.7813vw;
    line-height: 1.0254vw;
    margin-bottom: 0.3418vw;
  }

  .subwindow-wrapper > .subwindow.adjust-inventory > .subwindow-body > input {
    width: 12.207vw;
    height: 4.0527vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.293vw;
    font-size: 1.9531vw;
    line-height: 2.7344vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > button#editStockButton {
    padding: 0.3418vw 0.4883vw;
    font-size: 0.7813vw;
    line-height: 1.0254vw;
    margin-bottom: 0.8301vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > button#editStockButton
    > img {
    width: 0.9277vw;
    height: 0.9277vw;
    margin-right: 0.2441vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > button#updateStockButton {
    width: 20.8496vw;
    height: 2.4414vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
    margin-bottom: 1.3184vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > .text-row {
    width: 20.8496vw;
    margin-top: 0.9277vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > p
    + .text-row {
    margin-top: 0.6836vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > .text-row
    > p {
    font-size: 0.7813vw;
    line-height: 1.0742vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > .text-row
    > p:first-of-type {
    margin-right: 0.9766vw;
    max-width: 10.4004vw;
  }

  .subwindow-wrapper > .subwindow.out-of-stock {
    width: 24.6094vw;
    height: min(17.0898vw, 95%);
  }

  .subwindow-wrapper > .subwindow.out-of-stock > .subwindow-body {
    border-radius: 0.3906vw;
  }

  .subwindow-wrapper > .subwindow.out-of-stock > .subwindow-body > p.style1 {
    font-size: 0.8789vw;
    line-height: 1.1719vw;
    margin-bottom: 1.1719vw;
  }

  .subwindow-wrapper > .subwindow.out-of-stock > .subwindow-body > img {
    width: 3.3691vw;
    height: 3.3691vw;
    margin-bottom: 0.6348vw;
  }

  .subwindow-wrapper > .subwindow.out-of-stock > .subwindow-body > p.style2 {
    font-size: 0.7813vw;
    line-height: 1.0254vw;
    margin-bottom: 1.4648vw;
  }

  .subwindow-wrapper
    > .subwindow.out-of-stock
    > .subwindow-body
    > p.style2
    > button {
    font-size: 0.7813vw;
    line-height: 1.0254vw;
  }

  .subwindow-wrapper > .subwindow.out-of-stock > .subwindow-body > button {
    width: 9.668vw;
    height: 2.4414vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
  }

  .subwindow-wrapper > .subwindow.product-note {
    width: 24.6094vw;
    height: min(20.5078vw, 95%);
  }

  .subwindow-wrapper > .subwindow.product-note > .subwindow-body > label {
    width: 20.7031vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 0.4395vw;
  }

  .subwindow-wrapper > .subwindow.product-note > .subwindow-body > textarea {
    width: 20.7031vw;
    height: 9.2285vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.293vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 0.9766vw;
  }

  .subwindow-wrapper > .subwindow.product-note > .subwindow-body > button {
    width: 20.7031vw;
    height: 2.3438vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper > .subwindow.no-variation-selected {
    width: 24.6094vw;
    height: min(17.0898vw, 95%);
  }

  .subwindow-wrapper > .subwindow.no-variation-selected > .subwindow-body {
    border-radius: 0.3906vw;
  }

  .subwindow-wrapper
    > .subwindow.no-variation-selected
    > .subwindow-body
    > p.style1 {
    font-size: 0.8789vw;
    line-height: 1.1719vw;
    margin-bottom: 1.1719vw;
  }

  .subwindow-wrapper
    > .subwindow.no-variation-selected
    > .subwindow-body
    > img {
    width: 2.9785vw;
    height: 2.9785vw;
    margin-bottom: 1.0254vw;
  }

  .subwindow-wrapper
    > .subwindow.no-variation-selected
    > .subwindow-body
    > p.style2 {
    font-size: 0.7813vw;
    line-height: 1.0254vw;
    margin-bottom: 1.4648vw;
  }

  .subwindow-wrapper
    > .subwindow.no-variation-selected
    > .subwindow-body
    > button {
    width: 9.668vw;
    height: 2.4414vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper > .subwindow.park-sale {
    width: 24.6094vw;
    height: min(20.5078vw, 95%);
  }

  .subwindow-wrapper > .subwindow.park-sale > .subwindow-body > label {
    width: 20.752vw;
    font-size: 0.6836vw;
    line-height: 1.0254vw;
    padding-bottom: 0.293vw;
  }

  .subwindow-wrapper > .subwindow.park-sale > .subwindow-body > textarea {
    width: 20.752vw;
    height: 8.8379vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.293vw;
    padding: 0.5859vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 1.8555vw;
  }

  .subwindow-wrapper > .subwindow.park-sale > .subwindow-body > button {
    width: 20.752vw;
    height: 2.4414vw;
    border-radius: 0.293vw;
    margin-bottom: 0.4883vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper > .subwindow.split-by-product {
    width: 24.6094vw;
    height: min(24.5117vw, 95%);
  }

  .subwindow-wrapper > .subwindow.split-by-product > .subwindow-body > .body {
    padding-top: 1.2207vw;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row {
    width: 20.6543vw;
    padding: 0.9766vw 0;
    border-top: 0.0488vw solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .main-row
    > .text-group
    > p {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .main-row
    > .increment-input {
    width: 7.2266vw;
    height: 2.1484vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.293vw;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .main-row
    > .increment-input
    > button
    > img {
    width: 0.9766vw;
    height: 0.9766vw;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .main-row
    > .increment-input
    > input {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .secondary-row {
    margin-top: 0.6348vw;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .secondary-row
    > p {
    font-size: 0.5859vw;
    line-height: 0.8301vw;
  }

  .subwindow-wrapper > .subwindow.split-by-product > .subwindow-body > .footer {
    height: 4.3945vw;
    border-top: 0.0488vw solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .footer
    > button {
    width: 20.752vw;
    height: 2.4414vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper > .subwindow.cash-payment.step-1 {
    width: 24.6094vw;
    height: min(34.6191vw, 95%);
  }

  .subwindow-wrapper > .subwindow.cash-payment.step-2 {
    width: 24.6094vw;
    height: 24.4141vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .text-row {
    width: 15.6738vw;
    margin-bottom: 1.2695vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .text-row
    > p.style1 {
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .text-row
    > p.style2 {
    font-size: 0.9766vw;
    line-height: 1.2695vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad {
    padding: 0.7813vw;
    border-radius: 0.3906vw;
    margin-bottom: 0.8789vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > .input-container {
    width: 14.1113vw;
    height: 4.541vw;
    border-radius: 0.293vw;
    margin-bottom: 0.4395vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > .input-container
    > label {
    font-size: 0.5859vw;
    line-height: 0.8301vw;
    margin-bottom: 0.4883vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > .input-container
    > input {
    width: 9.4238vw;
    height: 2.0508vw;
    font-size: 1.9531vw;
    line-height: 1.9531vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > #numpad2
    > .button-row {
    margin-top: 0.4395vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > #numpad2
    > .button-row
    > button {
    width: 4.3945vw;
    height: 3.3203vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.293vw;
    margin-left: 0.4395vw;
    font-size: 1.4648vw;
    line-height: 2.1973vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > #numpad2
    > .button-row
    > button.backspace
    > img {
    width: 1.9531vw;
    height: 1.9531vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > button {
    width: 15.6738vw;
    height: 2.9297vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment.step-2
    > .subwindow-body
    > .step2
    > .text-row {
    width: 18.0664vw;
    padding-top: 0.9766vw;
    margin-top: 0.9766vw;
    border-top: 0.0488vw solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.cash-payment.step-2
    > .subwindow-body
    > .step2
    > .text-row
    > p {
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment.step-2
    > .subwindow-body
    > .step2
    > button {
    width: 18.0664vw;
    height: 2.9297vw;
    border-radius: 0.293vw;
    margin-top: 2.4414vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper > .subwindow.quick-tax {
    right: 2.4414vw;
    bottom: 2.4414vw;
    width: 14.6484vw;
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-header {
    height: 2.6855vw;
    padding: 0 0.5371vw 0 1.0742vw;
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-header > P {
    font-size: 0.7813vw;
    line-height: 1.0742vw;
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-header > button {
    padding: 0.5371vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-body {
    padding: 1.0742vw;
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-body > .row {
    margin-top: 0.3906vw;
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-body > .row > p {
    font-size: 0.7813vw;
    line-height: 1.0742vw;
  }

  .subwindow-wrapper
    > .subwindow.quick-tax
    > .subwindow-body
    > .row
    > label
    > input {
    left: -488.2813vw;
  }

  .subwindow-wrapper
    > .subwindow.quick-tax
    > .subwindow-body
    > .row
    > label
    > .custom-toggle {
    width: 1.9531vw;
    height: 1.2207vw;
    border-radius: 1.2207vw;
  }

  .subwindow-wrapper
    > .subwindow.quick-tax
    > .subwindow-body
    > .row
    > label
    > .custom-toggle
    > .knob {
    width: 1.0742vw;
    height: 1.0742vw;
    top: calc(50% - 0.5371vw);
    left: 0.0732vw;
    border: 0.0191vw solid rgba(0, 0, 0, 0.04);
    box-shadow: 0vw 0.1149vw 0.3064vw rgba(0, 0, 0, 0.15),
      0vw 0.1149vw 0.0383vw rgba(0, 0, 0, 0.06);
  }

  .subwindow-wrapper
    > .subwindow.quick-tax
    > .subwindow-body
    > .row
    > label
    > input:checked
    ~ .custom-toggle
    > .knob {
    right: 0.0732vw;
  }

  .subwindow-wrapper > .subwindow.detailed-tax {
    width: 36.3281vw;
    height: min(35.8887vw, 95%);
  }

  .subwindow-wrapper > .subwindow.detailed-tax > .subwindow-body > .header-row {
    height: 2.1973vw;
    padding: 0 1.0742vw;
    border-bottom: 0.0488vw solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .header-row
    > p {
    font-size: 0.6836vw;
    line-height: 0.8301vw;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row {
    height: 2.9297vw;
    padding: 0 1.0742vw;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > p {
    font-size: 0.6836vw;
    line-height: 0.8301vw;
    padding-right: 0.7324vw;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > .checkbox-wrapper
    > input {
    left: -488.2813vw;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > .checkbox-wrapper
    > .custom-checkbox {
    width: 0.8789vw;
    height: 0.8789vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.1953vw;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > .checkbox-wrapper
    > .custom-checkbox
    > img {
    width: 0.5859vw;
    height: 0.4619vw;
  }

  .subwindow-wrapper > .subwindow.detailed-tax > .subwindow-body > .footer {
    height: 4.1504vw;
    border-top: 0.0488vw solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .footer
    > button {
    width: 13.6719vw;
    height: 2.1484vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper > .subwindow.add-customer-note {
    width: 24.6094vw;
    height: min(20.5078vw, 95%);
  }

  .subwindow-wrapper > .subwindow.add-customer-note > .subwindow-body > label {
    width: 20.7031vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 0.4395vw;
  }

  .subwindow-wrapper
    > .subwindow.add-customer-note
    > .subwindow-body
    > textarea {
    width: 20.7031vw;
    height: 9.2285vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.293vw;
    padding: 0.5859vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 0.9766vw;
  }

  .subwindow-wrapper > .subwindow.add-customer-note > .subwindow-body > button {
    width: 20.7031vw;
    height: 2.3438vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper > .subwindow.add-cash {
    width: 24.6094vw;
    height: min(26.0742vw, 95%);
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body > .text-row {
    width: 20.8496vw;
    padding-bottom: 1.0254vw;
    border-bottom: 0.0488vw solid var(--oliver-border);
    margin-bottom: 1.5137vw;
  }

  .subwindow-wrapper
    > .subwindow.add-cash
    > .subwindow-body
    > .text-row
    > p.style1 {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper
    > .subwindow.add-cash
    > .subwindow-body
    > .text-row
    > p.style2 {
    font-size: 0.9766vw;
    line-height: 1.4648vw;
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body > .input-row {
    width: 20.8496vw;
    margin-bottom: 0.8301vw;
  }

  .subwindow-wrapper
    > .subwindow.add-cash
    > .subwindow-body
    > .input-row
    > label {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper
    > .subwindow.add-cash
    > .subwindow-body
    > .input-row
    > input {
    width: 11.7676vw;
    height: 2.4414vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.293vw;
    padding: 0 0.5859vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body > label {
    width: 20.8496vw;
    font-size: 0.7813vw;
    line-height: 1.1719vw;
    margin-bottom: 0.293vw;
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body > textarea {
    width: 20.8496vw;
    height: 7.0313vw;
    padding: 0.5859vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.293vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 1.123vw;
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body > button {
    width: 20.8496vw;
    height: 2.4414vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper > .subwindow.remove-cash {
    width: 24.6094vw;
    height: min(26.0742vw, 95%);
  }

  .subwindow-wrapper > .subwindow.remove-cash > .subwindow-body > .text-row {
    width: 20.8496vw;
    padding-bottom: 1.0254vw;
    border-bottom: 0.0488vw solid var(--oliver-border);
    margin-bottom: 1.5137vw;
  }

  .subwindow-wrapper
    > .subwindow.remove-cash
    > .subwindow-body
    > .text-row
    > p.style1 {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper
    > .subwindow.remove-cash
    > .subwindow-body
    > .text-row
    > p.style2 {
    font-size: 0.9766vw;
    line-height: 1.4648vw;
  }

  .subwindow-wrapper > .subwindow.remove-cash > .subwindow-body > .input-row {
    width: 20.8496vw;
    margin-bottom: 0.8301vw;
  }

  .subwindow-wrapper
    > .subwindow.remove-cash
    > .subwindow-body
    > .input-row
    > label {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper
    > .subwindow.remove-cash
    > .subwindow-body
    > .input-row
    > input {
    width: 11.7676vw;
    height: 2.4414vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.293vw;
    padding: 0 0.5859vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .subwindow-wrapper > .subwindow.remove-cash > .subwindow-body > label {
    width: 20.8496vw;
    font-size: 0.7813vw;
    line-height: 1.1719vw;
    margin-bottom: 0.293vw;
  }

  .subwindow-wrapper > .subwindow.remove-cash > .subwindow-body > textarea {
    width: 20.8496vw;
    height: 7.0313vw;
    padding: 0.5859vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.293vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 1.123vw;
  }

  .subwindow-wrapper > .subwindow.remove-cash > .subwindow-body > button {
    width: 20.8496vw;
    height: 2.4414vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper > .subwindow.no-customer {
    width: 24.6094vw;
    height: min(20.9961vw, 95%);
  }

  .subwindow-wrapper > .subwindow.no-customer > .subwindow-body {
    border-radius: 0.3906vw;
  }

  .subwindow-wrapper > .subwindow.no-customer > .subwindow-body > p.style1 {
    font-size: 0.8789vw;
    line-height: 1.1719vw;
    margin-bottom: 1.123vw;
  }

  .subwindow-wrapper > .subwindow.no-customer > .subwindow-body > p.style2 {
    font-size: 0.7813vw;
    line-height: 1.0254vw;
    margin-bottom: 2.002vw;
  }

  .subwindow-wrapper > .subwindow.no-customer > .subwindow-body > img {
    width: 2.8809vw;
    height: 2.8809vw;
    margin-bottom: 1.1719vw;
  }

  .subwindow-wrapper > .subwindow.no-customer > .subwindow-body > button {
    width: 12.9395vw;
    height: 2.4414vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper
    > .subwindow.no-customer
    > .subwindow-body
    > button:first-of-type {
    border: 0.0488vw solid var(--oliver-blue);
    margin-bottom: 0.8301vw;
  }

  .subwindow-wrapper
    > .subwindow.no-customer
    > .subwindow-body
    > button:first-of-type
    > img {
    width: 0.9766vw;
    height: 0.9766vw;
    margin-right: 0.5859vw;
  }

  .subwindow-wrapper > .subwindow.adjust-credit {
    width: 24.6094vw;
    height: min(31.0059vw, 95%);
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body > p {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 0.4395vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .toggle-wrapper {
    width: 15.625vw;
    height: 2.4414vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.3906vw;
    padding: 0.293vw;
    margin-bottom: 1.1719vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .toggle-wrapper
    > label
    > input {
    left: -488.2813vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .toggle-wrapper
    > label
    > .custom-radio {
    font-size: 0.7813vw;
    line-height: 1.0254vw;
    border-radius: 0.293vw;
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body > .text-row {
    width: 20.8496vw;
    padding-top: 1.0254vw;
    border-top: 0.0488vw solid var(--oliver-border);
    margin-bottom: 0.8789vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .text-row
    > p.style1 {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .text-row
    > p.style2 {
    font-size: 0.9766vw;
    line-height: 1.4648vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .change-credit-row {
    width: 20.8496vw;
    padding-bottom: 1.1719vw;
    border-bottom: 0.0488vw solid var(--oliver-border);
    margin-bottom: 0.8789vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .change-credit-row
    > p {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .change-credit-row
    > input {
    width: 11.8164vw;
    height: 2.4414vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.293vw;
    padding: 0 0.5859vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body > label {
    width: 20.8496vw;
    font-size: 0.7813vw;
    line-height: 1.1719vw;
    margin-bottom: 0.293vw;
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body > textarea {
    width: 20.8496vw;
    height: 7.0313vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.293vw;
    padding: 0.5859vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 1.1719vw;
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body > button {
    width: 20.8496vw;
    height: 2.4414vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper > .subwindow.layaway {
    width: 24.6094vw;
    height: min(20.5078vw, 95%);
  }

  .subwindow-wrapper > .subwindow.layaway > .subwindow-body > label {
    width: 20.7031vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 0.4395vw;
  }

  .subwindow-wrapper > .subwindow.layaway > .subwindow-body > textarea {
    width: 20.7031vw;
    height: 9.2285vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.293vw;
    padding: 0.5859vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 0.9766vw;
  }

  .subwindow-wrapper > .subwindow.layaway > .subwindow-body > button {
    width: 20.7031vw;
    height: 2.3438vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper > .subwindow.store-credit-pay {
    width: 24.6094vw;
    height: min(34.6191vw, 95%);
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .text-row {
    width: 15.6738vw;
    margin-bottom: 1.2695vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .text-row
    > p.style1 {
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .text-row
    > p.style2 {
    font-size: 0.9766vw;
    line-height: 1.2695vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper {
    width: 15.6738vw;
    padding: 0.7813vw;
    border-radius: 0.3906vw;
    margin-bottom: 0.8789vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .input-wrapper {
    height: 4.541vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.293vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .input-wrapper
    > p {
    font-size: 0.5859vw;
    line-height: 0.8301vw;
    margin-bottom: 0.5371vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .input-wrapper
    > input {
    height: 2.0508vw;
    font-size: 1.9531vw;
    line-height: 1.9531vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .numpad-row {
    height: 3.3203vw;
    margin-top: 0.4395vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .numpad-row
    > button {
    margin-left: 0.4395vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.293vw;
    font-size: 1.4648vw;
    line-height: 2.1973vw;
    letter-spacing: 0vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .numpad-row
    > button
    > img {
    width: 1.9531vw;
    height: 1.9531vw;
  }

  .subwindow-wrapper > .subwindow.store-credit-pay > .subwindow-body > button {
    width: 15.6738vw;
    height: 2.9297vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper > .subwindow.logout-confirm > .subwindow-body > p.style1 {
    font-size: 1.2207vw;
    line-height: 1.7578vw;
    margin: 0.4883vw 0 2.3926vw 0;
  }

  .subwindow-wrapper > .subwindow.logout-confirm > .subwindow-body > p.style2 {
    font-size: 0.8789vw;
    line-height: 1.1719vw;
    margin-top: 0.4883vw;
  }

  .subwindow-wrapper > .subwindow.logout-confirm > .subwindow-body > button {
    width: 9.2773vw;
    height: 2.3438vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper
    > .subwindow.logout-confirm
    > .subwindow-body
    > button#registerLogout {
    margin: 2.9785vw 0 0.8301vw 0;
  }

  .subwindow-wrapper
    > .subwindow.logout-confirm
    > .subwindow-body
    > button#cancelRegisterLogout {
    border: 0.0488vw solid var(--oliver-blue);
    margin-bottom: 0.4883vw;
  }

  .subwindow-wrapper > .subwindow.receipt {
    width: 24.6094vw;
    height: 14.4043vw;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-options
    > p {
    font-size: 0.7813vw;
    line-height: 1.0254vw;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-options
    > button {
    width: 12.9395vw;
    height: 2.4414vw;
    margin-top: 0.8301vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-options
    > button:first-of-type {
    margin-top: 1.0254vw;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-email
    > label {
    width: 17.0898vw;
    font-size: 0.7813vw;
    line-height: 1.0254vw;
    padding-bottom: 0.3418vw;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-email
    > input {
    width: 17.0898vw;
    height: 2.4414vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.293vw;
    padding: 0 0.5859vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 0.8301vw;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-email
    > input:focus {
    border-width: 0.0977vw;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-email
    > button {
    width: 17.0898vw;
    height: 2.4414vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper > .subwindow.update-transaction-status {
    width: 16.7969vw;
    height: min(95%, 27.5391vw);
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > p {
    font-size: 0.6836vw;
    line-height: 0.8789vw;
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > label {
    margin-top: 0.7324vw;
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > label
    > input {
    left: -488.2813vw;
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > label
    > .custom-radio {
    width: 13.916vw;
    height: 2.1973vw;
    border-radius: 0.3906vw;
    border: 0.0488vw solid var(--oliver-border);
    font-size: 0.7813vw;
    line-height: 1.0254vw;
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > label
    > input:not(:disabled):not(:checked)
    + .custom-radio:hover {
    border: 0.0488vw solid var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > label
    > input:checked
    + .custom-radio {
    border: 0.0977vw solid var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > button {
    margin-top: 1.3672vw;
    width: 13.916vw;
    height: 2.1973vw;
    border-radius: 0.3906vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .open-register-wrapper > #cancel,
  .open-register-wrapper > #goBack {
    left: 0.4883vw;
    top: 0.4883vw;
    width: 6.4502vw;
    height: 2.5391vw;
    font-size: 0.9766vw;
    line-height: 1.4648vw;
  }

  .open-register-wrapper > #cancel > img,
  .open-register-wrapper > #goBack > img {
    width: 0.4004vw;
    height: 0.8008vw;
    margin-right: 0.3906vw;
  }

  .open-register-wrapper > #logout-landscape {
    right: 0.4883vw;
    top: 0.4883vw;
    width: 6.4502vw;
    height: 2.5391vw;
    font-size: 0.9766vw;
    line-height: 1.4648vw;
  }

  .open-register-wrapper > #logout-landscape > img {
    width: 1.1719vw;
    height: 1.1719vw;
    margin-right: 0.3418vw;
  }

  .open-register-wrapper > header > img {
    width: 13.5098vw;
    height: 12.3535vw;
    margin-bottom: 0.9766vw;
  }

  .open-register-wrapper > header p.style1 {
    font-size: 2.3438vw;
    line-height: 3.3691vw;
    margin-bottom: 0.4883vw;
  }

  .open-register-wrapper > header .divider {
    width: 12.7441vw;
    height: 0.0488vw;
    margin-bottom: 0.6836vw;
  }

  .open-register-wrapper > header p.style2 {
    font-size: 1.1719vw;
    line-height: 1.7578vw;
  }

  .open-register-wrapper > header p.style3 {
    font-size: 0.8789vw;
    line-height: 1.3672vw;
  }

  .open-register-wrapper > main > * {
    width: 16.9922vw;
    margin-left: 7.7637vw;
  }

  .open-register-wrapper > main > .step1 > p {
    font-size: 1.1719vw;
    line-height: 1.709vw;
    margin-bottom: 0.8301vw;
  }

  .open-register-wrapper > main > .step1 > button {
    width: 16.9434vw;
    height: 2.4414vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .open-register-wrapper > main > .step2 > p {
    font-size: 1.1719vw;
    line-height: 1.709vw;
    margin-bottom: 1.1719vw;
  }

  .open-register-wrapper > main > .step2 > label {
    width: 16.9922vw;
    font-size: 0.7813vw;
    line-height: 1.1719vw;
    margin-bottom: 0.4395vw;
  }

  .open-register-wrapper > main > .step2 > input {
    width: 16.9922vw;
    height: 4.0527vw;
    border-radius: 0.293vw;
    font-size: 1.9531vw;
    line-height: 2.7344vw;
    margin-bottom: 1.1719vw;
  }

  .open-register-wrapper > main > .step2 > button {
    width: 16.9922vw;
    height: 2.4414vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
    border-radius: 0.293vw;
  }

  .open-register-wrapper > main > .step2 > button#openCashDrawer {
    border: 0.0488vw solid #fff;
    margin-bottom: 1.1719vw;
  }

  .open-register-wrapper > main > .step2 > textarea {
    width: 16.9434vw;
    height: 4.0527vw;
    padding: 0.5859vw;
    border-radius: 0.293vw;
    margin-bottom: 1.1719vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .open-register-wrapper > main > .step3 > p {
    font-size: 0.9766vw;
    line-height: 1.416vw;
    margin: 1.2207vw;
  }

  .open-register-wrapper > main > .step3 > .error-message {
    width: 13.4766vw;
    height: 2.0508vw;
    border-radius: 0.293vw;
    margin-bottom: 0.8789vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .open-register-wrapper > main > .step3 > .pinpad {
    width: 13.4766vw;
  }

  .open-register-wrapper > main > .step3 > .pinpad > .pin-entries {
    margin-bottom: 0.833vw;
  }

  .open-register-wrapper > main > .step3 > .pinpad > .pin-entries > .pin-entry {
    width: 0.7324vw;
    height: 0.7324vw;
    margin-left: 0.46vw;
  }

  .open-register-wrapper > main > .step3 > .pinpad > .pin-button-row {
    margin-top: 0.7959vw;
  }

  .open-register-wrapper > main > .step3 > .pinpad > .pin-button-row > button {
    width: 3.98vw;
    height: 3.98vw;
    border-radius: 0.3906vw;
    margin-right: 0.7681vw;
  }

  .open-register-wrapper
    > main
    > .step3
    > .pinpad
    > .pin-button-row
    > button
    > p {
    font-size: 1.7578vw;
    line-height: 1.7578vw;
    transform: translateY(0.1465vw);
  }

  .open-register-wrapper
    > main
    > .step3
    > .pinpad
    > .pin-button-row
    > button
    > .text-row {
    font-size: 0.4883vw;
    line-height: 0.7324vw;
  }

  .open-register-wrapper
    > main
    > .step3
    > .pinpad
    > .pin-button-row
    > button.backspace
    > img {
    width: 2.46vw;
    height: 1.9575vw;
  }

  .logout-confirmation-wrapper > p.style1 {
    font-size: 1.2207vw;
    line-height: 1.7578vw;
    margin: 1.2695vw 0 2.3926vw 0;
  }

  .logout-confirmation-wrapper > p.style2 {
    font-size: 0.8789vw;
    line-height: 1.1719vw;
    margin-bottom: 2.9785vw;
  }

  .logout-confirmation-wrapper > button {
    width: 9.2773vw;
    height: 2.3438vw;
    border-radius: 0.3906vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .logout-confirmation-wrapper > button#logoutConfirm {
    margin-bottom: 0.8301vw;
  }

  .logout-confirmation-wrapper > button#logoutCancel {
    border: 0.0488vw solid var(--oliver-blue);
    margin-bottom: 1.2695vw;
  }

  .idle-register-wrapper > #logoutRegisterButton {
    padding: 0.4883vw;
    top: 0.6836vw;
    left: 0.6836vw;
    font-size: 0.9766vw;
    line-height: 1.4648vw;
  }

  .idle-register-wrapper > #logoutRegisterButton > img {
    width: 1.1719vw;
    height: 1.1719vw;
    margin-right: 0.3418vw;
  }

  .idle-register-wrapper > header {
    margin: auto 8.3496vw auto auto;
  }

  .idle-register-wrapper > header > img {
    width: 13.5098vw;
    height: 12.3535vw;
    margin: 0.9766vw 0 0.8301vw 0;
  }

  .idle-register-wrapper > header > .col > p.style1 {
    font-size: 2.3438vw;
    line-height: 3.3691vw;
    margin-bottom: 0.4883vw;
  }

  .idle-register-wrapper > header > .col > p.style2 {
    font-size: 1.1719vw;
    line-height: 1.3672vw;
    margin-bottom: 0.0488vw;
  }

  .idle-register-wrapper > header > .col > p.style3 {
    font-size: 0.8789vw;
    line-height: 1.3672vw;
    margin-bottom: 0.8301vw;
  }

  .idle-register-wrapper > header > .col > .divider {
    width: 12.7441vw;
    height: 0.0488vw;
    margin-bottom: 0.6836vw;
  }

  .idle-register-wrapper > header > .col > button#closeRegister1 {
    width: 12.7441vw;
    height: 2.4414vw;
    border-radius: 0.1953vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
    margin-bottom: 0.9766vw;
  }

  .idle-register-wrapper > main > p {
    font-size: 0.9766vw;
    line-height: 1.416vw;
    margin: 0.9766vw 0 1.2056vw 0;
  }

  .idle-register-wrapper > main > .pinpad {
    width: 13.5742vw;
    margin-bottom: 0.4883vw;
  }

  .idle-register-wrapper > main > .pinpad > p.error-message {
    padding: 0.5371vw 1.3672vw;
    border-radius: 0.3906vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin: -0.4883vw 0 0.9766vw 0;
  }

  .idle-register-wrapper > main > .pinpad > .pin-entries {
    margin-bottom: 0.8301vw;
  }

  .idle-register-wrapper > main > .pinpad > .pin-entries > .pin-entry {
    width: 0.7813vw;
    height: 0.7813vw;
    margin-left: 0.4883vw;
  }

  .idle-register-wrapper > main > .pinpad > .pin-button-row {
    gap: 0.7813vw;
    margin-bottom: 0.7813vw;
  }

  .idle-register-wrapper > main > .pinpad > .pin-button-row > button {
    width: 4.0039vw;
    height: 4.0039vw;
    border-radius: 0.3906vw;
  }

  .idle-register-wrapper
    > main
    > .pinpad
    > .pin-button-row
    > button.backspace
    > img {
    width: 2.4414vw;
    height: 1.9531vw;
  }

  .idle-register-wrapper > main > .pinpad > .pin-button-row > button > p {
    font-size: 1.7578vw;
    line-height: 1.7578vw;
  }

  .idle-register-wrapper
    > main
    > .pinpad
    > .pin-button-row
    > button
    > .text-row {
    font-size: 0.4883vw;
    line-height: 0.7324vw;
    letter-spacing: 0vw;
  }

  .close-register-wrapper > button {
    left: 0.4883vw;
    top: 0.4883vw;
    width: 6.4502vw;
    height: 2.5391vw;
    font-size: 0.9766vw;
    line-height: 1.4648vw;
  }

  .close-register-wrapper > button > img {
    width: 0.4004vw;
    height: 0.8008vw;
    margin-right: 0.3906vw;
  }

  .close-register-wrapper > header > img {
    width: 13.5098vw;
    height: 12.3535vw;
    margin-bottom: 0.8301vw;
  }

  .close-register-wrapper > header > .col > p.style1 {
    font-size: 2.3438vw;
    line-height: 3.3691vw;
  }

  .close-register-wrapper > header > .col > p.style2 {
    font-size: 1.1719vw;
    line-height: 1.3672vw;
    margin-bottom: 0.0488vw;
  }

  .close-register-wrapper > header > .col > p.style3 {
    font-size: 0.8789vw;
    line-height: 1.3672vw;
  }

  .close-register-wrapper > header > .col > .divider {
    width: 12.7441vw;
    height: 0.0488vw;
    margin-bottom: 0.6836vw;
  }

  .close-register-wrapper > main > .step1 {
    margin-left: 8.3496vw;
  }

  .close-register-wrapper > main > .step1 > p.style1 {
    font-size: 1.1719vw;
    line-height: 1.709vw;
    margin: 1.2695vw 0 1.7578vw 0;
  }

  .close-register-wrapper > main > .step1 > .divider {
    width: 4.3945vw;
    height: 0.0488vw;
    margin-bottom: 2.0996vw;
  }

  .close-register-wrapper > main > .step1 > p.style2 {
    font-size: 0.9766vw;
    line-height: 1.416vw;
    margin-bottom: 1.2056vw;
  }

  .close-register-wrapper > main > .step1 > .pinpad {
    margin-bottom: 1.2695vw;
  }

  .close-register-wrapper > main > .step1 > .pinpad {
    width: 13.5742vw;
    margin-bottom: 0.4883vw;
  }

  .close-register-wrapper > main > .step1 > .pinpad > p.error-message {
    padding: 0.5371vw 1.3672vw;
    border-radius: 0.3906vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin: -0.4883vw 0 0.9766vw 0;
  }

  .close-register-wrapper > main > .step1 > .pinpad > .pin-entries {
    margin-bottom: 0.8301vw;
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-entries
    > .pin-entry {
    width: 0.7813vw;
    height: 0.7813vw;
    margin-left: 0.4883vw;
  }

  .close-register-wrapper > main > .step1 > .pinpad > .pin-button-row {
    gap: 0.7813vw;
    margin-bottom: 0.7813vw;
  }

  .close-register-wrapper > main > .step1 > .pinpad > .pin-button-row > button {
    width: 4.0039vw;
    height: 4.0039vw;
    border-radius: 0.3906vw;
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-button-row
    > button.backspace
    > img {
    width: 2.4414vw;
    height: 1.9531vw;
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-button-row
    > button
    > p {
    font-size: 1.7578vw;
    line-height: 1.7578vw;
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-button-row
    > button
    > .text-row {
    font-size: 0.4883vw;
    line-height: 0.7324vw;
    letter-spacing: 0vw;
  }

  .close-register-wrapper > main > .step2 {
    width: 20.3125vw;
    margin-left: 7.584vw;
  }

  .close-register-wrapper > main > .step2 > p.style1 {
    font-size: 1.1719vw;
    line-height: 1.709vw;
    margin: 1.2695vw 0 1.4648vw 0;
  }

  .close-register-wrapper > main > .step2 > .divider {
    width: 4.2515vw;
    height: 0.0488vw;
    margin-bottom: 2.3926vw;
  }

  .close-register-wrapper > main > .step2 > p.style2 {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
    margin-bottom: 1.0742vw;
  }

  .close-register-wrapper > main > .step2 > button#openCashDrawer {
    height: 2.4414vw;
    border: 0.0977vw solid #fff;
    border-radius: 0.3906vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
    margin-bottom: 1.8555vw;
  }

  .close-register-wrapper > main > .step2 > .input-column {
    margin-bottom: 1.8555vw;
  }

  .close-register-wrapper > main > .step2 > .input-column > .input-row {
    margin-top: 1.0254vw;
  }

  .close-register-wrapper > main > .step2 > .input-column > .input-row > label {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
  }

  .close-register-wrapper > main > .step2 > .input-column > .input-row > input {
    width: 11.3477vw;
    height: 2.4414vw;
    border-radius: 0.293vw;
    padding: 0 0.8789vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .close-register-wrapper > main > .step2 > button#saveCount {
    height: 2.4414vw;
    border-radius: 0.3906vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
    margin-bottom: 1.2695vw;
  }

  .close-register-wrapper > main > .step3 {
    width: 30.2246vw;
    margin-left: 3.7266vw;
  }

  .close-register-wrapper > main > .step3 > p.style1 {
    font-size: 1.1719vw;
    line-height: 1.709vw;
    margin: 1.2695vw 0 1.8555vw 0;
  }

  .close-register-wrapper > main > .step3 > label {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
    margin-bottom: 0.4395vw;
  }

  .close-register-wrapper > main > .step3 > .closing-totals {
    border-radius: 0.3906vw;
    padding: 0 0.7324vw;
    margin-bottom: 1.2207vw;
  }

  .close-register-wrapper > main > .step3 > .closing-totals > .tablet-row {
    margin: 0.5859vw 0 1.0742vw 0;
  }

  .close-register-wrapper > main > .step3 > .closing-totals > .tablet-row > p {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
  }

  .close-register-wrapper > main > .step3 > .closing-totals > .col {
    margin-bottom: 0.7813vw;
  }

  .close-register-wrapper > main > .step3 > .closing-totals > .col > p {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
  }

  .close-register-wrapper
    > main
    > .step3
    > .closing-totals
    > .col
    > .row
    > p:last-of-type {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
  }

  .close-register-wrapper > main > .step3 > .closing-totals > .tablet-divider {
    width: calc(100% + 1.4648vw);
    height: 0.0488vw;
    margin-left: -0.7324vw;
    margin-bottom: 0.7813vw;
  }

  .close-register-wrapper > main > .step3 > .closing-totals > .col.total > p {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
  }

  .close-register-wrapper
    > main
    > .step3
    > .closing-totals
    > .col.total
    > .row
    > p {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
  }

  .close-register-wrapper > main > .step3 > textarea {
    height: 5.127vw;
    border-radius: 0.3906vw;
    padding: 0.5859vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 1.416vw;
  }

  .close-register-wrapper > main > .step3 > button#printReport {
    width: 11.9141vw;
    height: 2.4414vw;
    border: 0.0977vw solid #fff;
    border-radius: 0.3906vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
    margin-bottom: 1.416vw;
  }

  .close-register-wrapper > main > .step3 > button#closeRegister {
    height: 2.4414vw;
    border-radius: 0.3906vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
    margin-bottom: 1.2695vw;
  }

  .create-userid-wrapper > .header {
    padding: 0.4883vw;
  }

  .create-userid-wrapper > .header > #cancelPinSetup,
  .create-userid-wrapper > .header > #goBackPinSetup {
    padding: 0.4883vw;
    font-size: 0.9766vw;
    line-height: 1.4648vw;
  }

  .create-userid-wrapper > .header > #cancelPinSetup > img,
  .create-userid-wrapper > .header > #goBackPinSetup > img {
    width: 0.4395vw;
    height: 0.7813vw;
    margin-right: 0.3418vw;
  }

  .create-userid-wrapper > .header > #logoutPinSetup {
    padding: 0.4883vw;
    font-size: 0.9766vw;
    line-height: 1.4648vw;
  }

  .create-userid-wrapper > .header > #logoutPinSetup > img {
    width: 1.1719vw;
    height: 1.1719vw;
    margin-right: 0.3418vw;
  }

  .create-userid-wrapper > .body {
    padding-bottom: 0.9766vw;
  }

  .create-userid-wrapper > .body > .col.static > img {
    width: 13.5098vw;
    height: 12.3535vw;
    margin-bottom: 0.9766vw;
  }

  .create-userid-wrapper > .body > .col.static > .text-group > p.style1 {
    font-size: 2.3438vw;
    line-height: 2.5391vw;
    margin-bottom: 0.4883vw;
  }

  .create-userid-wrapper > .body > .col.static > .text-group > p.style2 {
    font-size: 1.1719vw;
    line-height: 1.3672vw;
    margin-bottom: 0.0488vw;
  }

  .create-userid-wrapper > .body > .col.static > .text-group > p.style3 {
    font-size: 0.8789vw;
    line-height: 1.3672vw;
  }

  .create-userid-wrapper > .body > .col.static > .text-group > .divider {
    width: 12.7441vw;
    height: 0.0488vw;
    margin-bottom: 0.6836vw;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-1 > p {
    font-size: 1.1719vw;
    line-height: 1.709vw;
    margin-bottom: 0.9766vw;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-1 > button {
    width: 16.9434vw;
    height: 2.4414vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 > p {
    font-size: 0.9766vw;
    line-height: 1.416vw;
    margin-bottom: 1.123vw;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 > .id-row {
    width: 13.4766vw;
    margin-bottom: 1.2368vw;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .id-row
    > .id-entry {
    height: 2.1484vw;
    margin-left: 0.4883vw;
    box-shadow: inset 0vw 0.1953vw 0.293vw rgba(0, 0, 0, 0.25);
    border-radius: 0.293vw;
    font-size: 1.2207vw;
    line-height: 1.7578vw;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 > .pin-key-row {
    width: 13.4766vw;
    margin-bottom: 0.7813vw;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .pin-key-row
    > button {
    margin-left: 0.7813vw;
    border-radius: 0.3906vw;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .pin-key-row
    > button.backspace
    > img {
    width: 2.46vw;
    height: 1.9575vw;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 > .pin-key-row.last {
    margin-bottom: 1.4648vw;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .pin-key-row
    > button
    > p {
    font-size: 1.7578vw;
    line-height: 1.7578vw;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .pin-key-row
    > button
    > .letter-row
    > p {
    font-size: 0.4883vw;
    line-height: 0.7324vw;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 > button {
    width: 13.4766vw;
    height: 2.4414vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .navbar {
    padding: 0.8789vw 0.7813vw 0.9766vw 0.7813vw;
  }

  .navbar.open {
    width: calc(369.4444% + 0.0488vw);
    border-right: 0.0488vw solid var(--oliver-border);
  }

  .navbar > .header-row {
    margin-bottom: 0.9766vw;
  }

  .navbar > .header-row > img.oliver-logo {
    width: 1.416vw;
    height: 1.4648vw;
    margin: 0 0.9766vw 0 0.293vw;
  }

  .navbar > .header-row > img.oliver-text {
    width: 3.2227vw;
    height: 0.8789vw;
  }

  .navbar > button.page-link {
    height: 1.9531vw;
    padding: 0 0.3906vw;
    border-radius: 0.3906vw;
    margin-bottom: 0.2441vw;
  }

  .navbar > button.page-link > .img-container {
    width: 1.1719vw;
    height: 1.1719vw;
  }

  .navbar > button > p {
    font-size: 0.5859vw;
    line-height: 0.8301vw;
    margin: 0 auto 0 0.4395vw;
  }

  .navbar > button > .f-key {
    width: 1.1719vw;
    height: 1.1719vw;
    border-radius: 0.1953vw;
    font-size: 0.5859vw;
    line-height: 0.8301vw;
  }

  .navbar > button.launcher {
    margin-bottom: 0.9766vw;
    padding: 0 0.1953vw;
  }

  .navbar > button.launcher > .img-container {
    width: 1.5625vw;
    height: 1.5625vw;
    border-radius: 0.293vw;
  }

  .navbar > button.launcher#linkLauncherButton {
    margin: auto 0 0.7813vw 0;
  }

  .navbar > button.launcher#navApp3 {
    margin-bottom: 0.8789vw;
  }

  .navbar > button.launcher#linkLauncherButton > .img-container > img {
    width: 0.9766vw;
    height: 0.9766vw;
  }

  .navbar > button.launcher#appLauncherButton > .img-container > img {
    width: 0.9277vw;
    height: 0.9277vw;
  }

  .navbar > button.launcher > p {
    margin-left: 0.5859vw;
  }

  .navbar > .divider {
    width: 1.5625vw;
    height: 0.0488vw;
    margin: 0 0 0.7813vw 0.1953vw;
  }

  .navbar > button.toggle-nav {
    padding: 0 0.3906vw;
  }

  .navbar > button.toggle-nav > .img-container {
    width: 1.1719vw;
    height: 1.1719vw;
  }

  .navbar > button.toggle-nav > .img-container > img {
    width: 0.9766vw;
    height: 0.8301vw;
  }

  .navbar > button.toggle-nav > p {
    margin-left: 0.7813vw;
  }

  .nav-cover {
    left: 12.9883vw;
  }

  .app-launcher-wrapper {
    left: 3.5156vw;
    border-left: 0.0488vw solid var(--oliver-border);
  }

  .navbar.open ~ .app-launcher-wrapper {
    left: 12.9883vw;
  }

  .app-launcher-wrapper > .app-launcher {
    width: 18.3105vw;
  }

  .app-launcher-wrapper > .app-launcher > .header {
    padding: 1.1719vw 0;
  }

  .app-launcher-wrapper > .app-launcher > .header > p {
    font-size: 0.7813vw;
    line-height: 1.0254vw;
    margin-left: 1.416vw;
  }

  .app-launcher-wrapper > .app-launcher > .body {
    padding: 0 1.416vw;
  }

  .app-launcher-wrapper > .app-launcher > .body > img {
    width: 12.6953vw;
    height: 8.0566vw;
  }

  .app-launcher-wrapper > .app-launcher > .body > button {
    margin-bottom: 0.9766vw;
  }

  .app-launcher-wrapper > .app-launcher > .body > button > .img-container {
    width: 2.6855vw;
    height: 2.6855vw;
    margin-right: 1.2695vw;
    border-radius: 0.4883vw;
  }

  .app-launcher-wrapper > .app-launcher > .body > button > p {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
  }

  .link-launcher-wrapper {
    left: 3.5156vw;
    border-left: 0.0488vw solid var(--oliver-border);
  }

  .navbar.open ~ .link-launcher-wrapper {
    left: 12.9883vw;
  }

  .link-launcher-wrapper > .link-launcher {
    width: 18.3105vw;
  }

  .link-launcher-wrapper > .link-launcher > .header {
    padding: 1.1719vw 0;
  }

  .link-launcher-wrapper > .link-launcher > .header > p {
    font-size: 0.7813vw;
    line-height: 1.0254vw;
    margin-left: 1.416vw;
  }

  .link-launcher-wrapper > .link-launcher > .body {
    padding: 0 1.416vw;
  }

  .link-launcher-wrapper > .link-launcher > .body > img {
    width: 12.5488vw;
    height: 8.5938vw;
  }

  .link-launcher-wrapper > .link-launcher > .body > button {
    margin-bottom: 0.9766vw;
  }

  .link-launcher-wrapper > .link-launcher > .body > button > .img-container {
    width: 2.6855vw;
    height: 2.6855vw;
    margin-right: 1.2695vw;
    border-radius: 0.4883vw;
  }

  .link-launcher-wrapper > .link-launcher > .body > button > .col > p.style1 {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
  }

  .link-launcher-wrapper > .link-launcher > .body > button > .col > p.style2 {
    font-size: 0.5859vw;
    line-height: 0.8301vw;
  }

  .user-info-wrapper {
    left: 3.5156vw;
    top: 3.3203vw;
    width: calc(100% - 3.5156vw);
    height: calc(100% - 3.3203vw);
  }

  .user-info-wrapper > .user-info {
    right: 0.7813vw;
    top: 0.7813vw;
    width: 17.0898vw;
    height: 22.3145vw;
    border-radius: 0.4883vw;
    box-shadow: 0vw 0.9766vw 1.4648vw rgba(18, 43, 81, 0.25);
    padding: 0.9766vw 0.9766vw 1.2207vw 0.9766vw;
  }

  .user-info-wrapper > .user-info > .header {
    padding-bottom: 0.8789vw;
    border-bottom: 0.0488vw solid var(--oliver-border);
  }

  .user-info-wrapper > .user-info > .header > .avatar {
    width: 2.9297vw;
    height: 2.9297vw;
    margin-right: 0.8301vw;
    font-size: 0.9766vw;
  }

  .user-info-wrapper > .user-info > .header > .col > p.style1 {
    font-size: 0.8789vw;
    line-height: 1.2207vw;
  }

  .user-info-wrapper > .user-info > .header > .col > p.style2 {
    font-size: 0.6348vw;
    line-height: 0.8789vw;
  }

  .user-info-wrapper > .user-info > .body {
    padding: 0.8789vw 0;
  }

  .user-info-wrapper > .user-info > .body > .language-select {
    margin-bottom: 0.9766vw;
  }

  .user-info-wrapper > .user-info > .body > .language-select > p {
    font-size: 0.5859vw;
    line-height: 0.8301vw;
    margin-right: 0.4883vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper {
    height: 1.709vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > img {
    top: calc(50% - 0.3418vw);
    right: 0.5859vw;
    width: 0.6836vw;
    height: 0.6836vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper.open
    > input {
    border-radius: 0.3906vw 0.3906vw 0 0;
    border-width: 0.0977vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > input {
    border: 0.0488vw solid var(--oliver-base-grey);
    border-radius: 0.3906vw;
    padding: 0 0.5859vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > .option-container {
    border-radius: 0 0 0.3906vw 0.3906vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > .option-container
    > .language-option {
    height: 1.709vw;
    border: 0.0488vw solid var(--oliver-border);
    padding: 0 0.5859vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > .option-container
    > .language-option
    > p {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    max-width: calc(100% - 0.7813vw);
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > .option-container
    > .language-option:last-child {
    border-radius: 0 0 0.3906vw 0.3906vw;
  }

  .user-info-wrapper > .user-info > .body > button {
    height: 1.9531vw;
    margin-bottom: 0.3906vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .user-info-wrapper > .user-info > .body > button > .img-container {
    width: 1.4648vw;
    height: 1.4648vw;
    margin-right: 0.4883vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > button#knowledgeBaseButton
    > .img-container
    > img {
    width: 1.0742vw;
    height: 1.0742vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > button#switchUserButton
    > .img-container
    > img {
    width: 0.6836vw;
    height: 0.7324vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > button#endSessionButton
    > .img-container
    > img {
    width: 1.2695vw;
    height: 1.0742vw;
  }

  .user-info-wrapper > .user-info > .footer {
    padding-top: 0.8789vw;
    border-top: 0.0488vw solid var(--oliver-border);
  }

  .user-info-wrapper > .user-info > .footer > .row {
    margin-bottom: 0.6348vw;
  }

  .user-info-wrapper > .user-info > .footer > .row > img {
    width: 0.9277vw;
    height: 0.9277vw;
    margin-right: 0.3418vw;
  }

  .user-info-wrapper > .user-info > .footer > .row > p {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .user-info-wrapper > .user-info > .footer > .button-row > button {
    width: 3.125vw;
    height: 3.125vw;
    border-radius: 0.4883vw;
    margin-right: 0.8789vw;
  }

  .page-options-wrapper {
    top: 1.0742vw;
    right: 3.7109vw;
  }

  .page-options-wrapper > .page-options > button {
    width: 1.1719vw;
    height: 1.1719vw;
    margin-right: 0.4883vw;
  }

  .page-options-wrapper > .page-options > button > .img-container > .badge {
    border: 0.0977vw solid #fff;
  }

  .notifications-wrapper {
    width: calc(100% - 3.5156vw);
    height: calc(100% - 3.3203vw);
    left: 3.5156vw;
    top: 3.3203vw;
  }

  .notifications-wrapper > .notifications {
    top: 0.0488vw;
    right: 0.7813vw;
    width: 18.3105vw;
    height: min(36.6211vw, 90%);
    border-radius: 0 0 0.3906vw 0.3906vw;
  }

  .notifications-wrapper > .notifications.settings {
    height: min(11.7188vw, 90%);
  }

  .notifications-wrapper > .notifications > .noti-header {
    height: 2.6367vw;
    padding: 0 0.7813vw;
    border-bottom: 0.0488vw solid var(--oliver-border);
  }

  .notifications-wrapper > .notifications > .noti-header > p {
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .notifications-wrapper > .notifications > .noti-header > button {
    padding: 0.4883vw;
    transform: translateX(0.4883vw);
  }

  .notifications-wrapper > .notifications > .noti-header > button > img {
    width: 1.2207vw;
    height: 1.2207vw;
  }

  .notifications-wrapper > .notifications > .noti-body > .date {
    padding: 0.4395vw 0.7813vw;
  }

  .notifications-wrapper > .notifications > .noti-body > .date > p {
    font-size: 0.6348vw;
    line-height: 0.8301vw;
  }

  .notifications-wrapper > .notifications > .noti-body > .notification-card {
    height: 3.7109vw;
    padding: 0 0.7324vw;
    border-top: 0.0488vw solid var(--oliver-border);
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .icon-wrapper {
    width: 1.9531vw;
    height: 1.9531vw;
    border-radius: 0.3906vw;
    margin-right: 0.5859vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .icon-wrapper
    > img {
    width: 1.5625vw;
    height: 1.5625vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .icon-wrapper.red
    > img {
    width: 1.2207vw;
    height: 1.2207vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .icon-wrapper.grey
    > img {
    width: 1.1719vw;
    height: 1.1719vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .col {
    padding-right: 0.5859vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .col
    > p.style1 {
    font-size: 0.6348vw;
    line-height: 0.8301vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .col
    > p.style2 {
    font-size: 0.5859vw;
    line-height: 0.7813vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > p {
    font-size: 0.6348vw;
    line-height: 0.8301vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .header {
    padding: 0.5859vw 0.7813vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .header
    > p {
    font-size: 0.7813vw;
    line-height: 1.0254vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list {
    padding: 0.4883vw 0.7813vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row {
    padding: 0.2441vw 0;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > img {
    width: 1.1719vw;
    height: 1.1719vw;
    margin-right: 0.4883vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > p {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    width: 9.7656vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > input {
    left: -488.2813vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > .toggle {
    width: 3.125vw;
    height: 1.5625vw;
    border-radius: 1.5625vw;
    padding: 0.0977vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > .toggle::before {
    width: 1.3672vw;
    height: 1.3672vw;
    border: 0.0488vw solid rgba(0, 0, 0, 0.04);
    box-shadow: 0vw 0.1465vw 0.3906vw rgba(0, 0, 0, 0.15),
      0vw 0.1465vw 0.0488vw rgba(0, 0, 0, 0.06);
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > .toggle::after {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    right: 0.3906vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > input:checked
    + .toggle::before {
    right: 0.0977vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > input:checked
    + .toggle::after {
    left: 0.3906vw;
  }

  .homepage-wrapper > .header > .row > img {
    width: 3.2227vw;
    height: 0.8789vw;
    transform: translateY(-0.0977vw);
  }

  .homepage-wrapper > .header > .row > button#searchButton {
    width: 8.7891vw;
    height: 1.7578vw;
    border-radius: 0.3906vw;
    font-size: 0.6348vw;
    line-height: 0.8789vw;
    margin-right: 8.4473vw;
  }

  .homepage-wrapper > .header > .row > button#searchButton > div {
    margin-left: 0.1465vw;
  }

  .homepage-wrapper > .header > .row > button#searchButton > img {
    width: 0.9766vw;
    height: 0.9766vw;
    margin-right: 0.2441vw;
  }

  .homepage-wrapper > .header > .row > .options-container {
    margin-right: 0.8789vw;
  }

  .homepage-wrapper > .header > .row > .options-container > button {
    width: 1.1719vw;
    height: 1.1719vw;
    margin-right: 0.3418vw;
  }

  .homepage-wrapper > .header > .row > button#userInfoButton {
    width: 1.9531vw;
    height: 1.9531vw;
    margin-right: 0.8301vw;
  }

  .homepage-wrapper > .products > .change-row-count {
    margin-bottom: 0.7813vw;
  }

  .homepage-wrapper > .products > .change-row-count > p {
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .homepage-wrapper > .products > .change-row-count > button:not(#exitEditing) {
    width: 1.9531vw;
    height: 1.9531vw;
    border: 0.0488vw solid var(--oliver-blue);
    border-radius: 0.3906vw;
    margin: 0 0.5859vw;
  }

  .homepage-wrapper > .products > .change-row-count > button > img {
    width: 0.6348vw;
    height: 1.3672vw;
  }

  .homepage-wrapper > .products > .change-row-count > button#exitEditing {
    height: 1.9531vw;
    padding: 0 0.5859vw;
    border: 0.0488vw solid var(--oliver-blue);
    border-radius: 0.3906vw;
    font-size: 0.7813vw;
    line-height: 0.8789vw;
  }

  .homepage-wrapper > .products > .category-header > p {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
  }

  .homepage-wrapper > .products > .category-header > button {
    width: 8.3008vw;
    height: 1.9531vw;
    border-radius: 0.293vw;
    font-size: 0.7813vw;
    line-height: 1.1719vw;
  }

  .homepage-wrapper > .products > .category-header > button > img {
    width: 1.123vw;
    height: 1.123vw;
    margin-right: 0.4883vw;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > .no-product-container
    > img {
    width: 2.1973vw;
    height: 2.1973vw;
    margin-bottom: 0.6348vw;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > .no-product-container
    > p {
    font-size: 0.8789vw;
    line-height: 1.3184vw;
    margin-bottom: 1.8555vw;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > .no-product-container
    > button {
    width: 6.8359vw;
    height: 1.8066vw;
    border: 0.0488vw solid var(--oliver-blue);
    border-radius: 0.293vw;
    font-size: 0.6836vw;
    line-height: 1.0254vw;
  }

  .homepage-wrapper > .products > .products-container > button {
    border-radius: 0.3906vw;
    box-shadow: 0vw 0.293vw 0.4883vw rgba(94, 94, 94, 0.1);
  }

  .homepage-wrapper > .products > .products-container > button.product > .body {
    border-radius: 0.3906vw 0.3906vw 0 0;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button.product
    > .footer {
    border-radius: 0 0 0.3906vw 0.3906vw;
    border-top: 0.0488vw solid var(--oliver-border);
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button.product
    > .footer
    > p {
    font-size: 0.7813vw;
    line-height: 0.9766vw;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button.category
    > p.sub {
    font-size: 0.5859vw;
    line-height: 0.7324vw;
    margin-bottom: 0.9766vw;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button.category
    > p.main {
    font-size: 0.7813vw;
    line-height: 0.9766vw;
  }

  .homepage-wrapper > .products > .products-container > button.add-tile {
    border: 0.0977vw dashed var(--oliver-new-grey);
    font-size: 0.7813vw;
    line-height: 0.8789vw;
  }

  .homepage-wrapper > .products > .products-container > button.add-tile > img {
    width: 0.9766vw;
    height: 0.9766vw;
    margin-right: 0.293vw;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button
    > .not-supported {
    border-radius: 0.293vw;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button
    > .not-supported
    > img {
    width: 1.3672vw;
    top: 0.5859vw;
    left: 0.5859vw;
  }

  .cart > .body {
    padding: 0 0.7813vw 0.5859vw 0.7813vw;
  }

  .cart > .body > .cart-item {
    padding: 0.5859vw 0;
    border-top: 0.0488vw solid var(--oliver-border);
  }

  .cart > .body > .cart-item > .main-row > .quantity {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-right: 0.7324vw;
    margin: 0 0.7324vw 0 0.3906vw;
  }

  .cart > .body > .cart-item > .main-row > .content-style {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    padding-right: 0.4883vw;
  }

  .cart > .body > .cart-item > .main-row > .price {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-right: 0.6167vw;
  }

  .cart > .body > .cart-item > .main-row > button.remove-cart-item {
    width: 0.8789vw;
    height: 0.8789vw;
    margin-right: 0.3237vw;
  }

  .cart > .body > .cart-item > .main-row > .tag {
    padding: 0.2686vw 0.5371vw;
    border-radius: 0.293vw;
    font-size: 0.5371vw;
    line-height: 0.7324vw;
    margin-right: 0.6836vw;
  }

  .cart > .body > .cart-item > .secondary-col {
    padding: 0 1.6602vw 0 1.5625vw;
    margin-top: 0.4883vw;
  }

  .cart > .body > .cart-item > .secondary-col > p {
    font-size: 0.5859vw;
    line-height: 0.8301vw;
  }

  .cart > .body > .cart-item > .secondary-col > .row > p {
    font-size: 0.5859vw;
    line-height: 0.8301vw;
  }

  .cart > .footer {
    border-top: 0.0488vw solid var(--oliver-border);
  }

  .cart > .footer > .totals {
    padding: 0.7324vw;
  }

  .cart > .footer > .totals > .row {
    margin-top: 0.3418vw;
  }

  .cart > .footer > .totals > .row > p {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .cart > .footer > .totals > .row > p:first-of-type {
    margin-right: 0.3418vw;
  }

  .cart > .footer > .totals > .row > button#editCartDiscount {
    font-size: 0.5859vw;
    line-height: 0.8301vw;
  }

  .cart > .footer > .totals > .row > button#taxesButton {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-right: 0.1953vw;
  }

  .cart > .footer > .checkout-container {
    border-top: 0.0488vw solid var(--oliver-border);
    height: 4.1016vw;
  }

  .cart > .footer > .checkout-container > button {
    padding: 0.7324vw;
  }

  .cart > .footer > .checkout-container > button > p {
    border-radius: 0.3906vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .homepage-wrapper {
    grid-template-columns: 3.5156vw auto 25vw;
    grid-template-rows: 3.3203vw auto;
  }

  .homepage-wrapper > .products {
    border-top: 0.0488vw solid var(--oliver-border);
    border-left: 0.0488vw solid var(--oliver-border);
  }

  .homepage-wrapper > .cart {
    border-top: 0.0488vw solid var(--oliver-border);
    border-left: 0.0488vw solid var(--oliver-border);
  }

  .product-wrapper {
    grid-template-columns: 3.5156vw auto 40vw;
    grid-template-rows: 3.3203vw auto 7.8125vw;
  }

  .product-wrapper > .mod-product {
    border-top: 0.0488vw solid var(--oliver-border);
    border-left: 0.0488vw solid var(--oliver-border);
  }

  .product-wrapper > .detailed-product {
    border-top: 0.0488vw solid var(--oliver-border);
    border-left: 0.0488vw solid var(--oliver-border);
  }

  .product-wrapper > .recommended-upsells {
    border-top: 0.0488vw solid var(--oliver-border);
    border-left: 0.0488vw solid var(--oliver-border);
  }

  .product-wrapper > .product-footer {
    border-top: 0.0488vw solid var(--oliver-border);
    border-left: 0.0488vw solid var(--oliver-border);
  }

  .product-wrapper > .header > .main > p.prod-name {
    font-size: 0.9766vw;
    line-height: 1.416vw;
  }

  .product-wrapper > .header > .main > button#desktopExitProductButton {
    width: 2.2461vw;
    height: 2.2461vw;
    margin-right: 0.6836vw;
  }

  .product-wrapper > .header > .main > button#desktopExitProductButton > img {
    width: 0.936vw;
    height: 0.936vw;
  }

  .product-wrapper > .mod-product {
    padding: 0.9766vw 0.9766vw 0.9766vw 0.9766vw;
  }

  .product-wrapper > .mod-product > .img-container > img:first-of-type {
    width: 15.5273vw;
    height: 8.6426vw;
  }

  .product-wrapper > .mod-product > .img-container > img:last-of-type {
    width: 9.9609vw;
    height: 4.1504vw;
    margin-bottom: 1.8555vw;
  }

  .product-wrapper > .mod-product.show-image > .img-container {
    padding: 2.9297vw 0;
  }

  .product-wrapper > .mod-product > .img-container > button {
    width: 8.1543vw;
    height: 1.8066vw;
    border: 0.0488vw solid var(--oliver-blue);
    border-radius: 0.293vw;
    font-size: 0.6836vw;
    line-height: 1.0254vw;
  }

  .product-wrapper > .mod-product > .no-edit-item {
    width: calc(100% + 0.9766vw);
    height: calc(100% + 0.9766vw);
    left: -0.9766vw;
    top: -0.9766vw;
  }

  .product-wrapper > .mod-product > .no-edit-item > .inner-container {
    width: min(19.5313vw, 95%);
    height: min(10.9863vw, 95%);
    box-shadow: 0vw 0.7324vw 1.9531vw rgba(0, 0, 0, 0.1);
    border-radius: 0.3906vw;
  }

  .product-wrapper
    > .mod-product
    > .no-edit-item
    > .inner-container
    > p.style1 {
    font-size: 0.7813vw;
    line-height: 1.0254vw;
    margin-bottom: 0.9766vw;
  }

  .product-wrapper
    > .mod-product
    > .no-edit-item
    > .inner-container
    > p.style2 {
    font-size: 0.6836vw;
    line-height: 0.8789vw;
  }

  .product-wrapper > .mod-product > .row {
    height: 1.8555vw;
    margin-bottom: 0.293vw;
  }

  .product-wrapper > .mod-product > .row > p {
    font-size: 0.7813vw;
    line-height: 1.0254vw;
  }

  .product-wrapper > .mod-product > .row > button {
    padding: 0.4883vw;
    font-size: 0.6836vw;
    line-height: 0.8789vw;
  }

  .product-wrapper > .mod-product > p {
    font-size: 0.6836vw;
    line-height: 1.0254vw;
    margin-bottom: 0.4395vw;
  }

  .product-wrapper > .mod-product > p.error::after {
    font-size: 0.6836vw;
    line-height: 0.6836vw;
    margin-left: 0.4883vw;
  }

  .product-wrapper > .mod-product > .radio-group {
    margin-bottom: 0.2441vw;
  }

  .product-wrapper > .mod-product > .radio-group > label {
    margin: 0 0.4883vw 0.4883vw 0;
  }

  .product-wrapper > .mod-product > .radio-group > label > input[type="radio"] {
    left: -488.2813vw;
  }

  .product-wrapper > .mod-product > .radio-group > label > .custom-radio {
    padding: 0.8789vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.3906vw;
  }

  .product-wrapper > .mod-product > .radio-group > label > .custom-radio > p {
    font-size: 0.6836vw;
    line-height: 0.8789vw;
  }

  .product-wrapper
    > .mod-product
    > .radio-group
    > label
    > input[type="radio"]:checked
    ~ .custom-radio {
    border: 0.0977vw solid var(--oliver-blue);
  }

  .product-wrapper > .mod-product > .radio-group + .row {
    margin-top: 1.9531vw;
  }

  .product-wrapper > .mod-product > input {
    width: min(14.6484vw, 100%);
    padding: 0.7813vw 0.8789vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.3906vw;
    margin-bottom: 0.7324vw;
    font-size: 0.6836vw;
    line-height: 0.6836vw;
  }

  .product-wrapper > .mod-product > input:focus {
    border: 0.0977vw solid var(--oliver-blue);
  }

  .product-wrapper > .mod-product > .increment-input {
    gap: 0.5371vw;
  }

  .product-wrapper > .mod-product > .increment-input > button {
    width: 1.9531vw;
    height: 1.9531vw;
    border-radius: 0.3906vw;
  }

  .product-wrapper > .mod-product > .increment-input > button > img {
    width: 0.7813vw;
    height: 1.4648vw;
  }

  .product-wrapper > .mod-product > .increment-input > input {
    width: 3.9063vw !important;
    height: 1.9531vw;
    font-size: 0.6836vw;
    line-height: 0.8789vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.3906vw;
  }

  .product-wrapper > .detailed-product {
    padding: 0.9766vw 0.9766vw 0 0.9766vw;
  }

  .product-wrapper > .detailed-product > .row {
    margin-bottom: 1.123vw;
  }

  .product-wrapper > .detailed-product > .row > .product-image-container {
    border-radius: 0.3906vw;
    margin-right: 0.9766vw;
  }

  .product-wrapper
    > .detailed-product
    > .row
    > .col
    > .group
    > .text-row
    > p.quantity {
    font-size: 2.3438vw;
    line-height: 3.2715vw;
  }

  .product-wrapper > .detailed-product > .row > .col > .group > p.desktop-only {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 1.0254vw;
  }

  .product-wrapper > .detailed-product > .row > .col > .group > button {
    width: 8.1543vw;
    height: 1.8066vw;
    border: 0.0488vw solid var(--oliver-blue);
    border-radius: 0.3906vw;
    font-size: 0.6836vw;
    line-height: 1.0254vw;
  }

  .product-wrapper > .detailed-product > .col > p.title {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 0.1953vw;
  }

  .product-wrapper > .detailed-product > .col > p.para {
    font-size: 0.6348vw;
    line-height: 0.8301vw;
    margin-bottom: 0.9766vw;
  }

  .product-wrapper > .recommended-upsells {
    border-top: 0.0488vw solid var(--oliver-border);
  }

  .product-wrapper > .recommended-upsells > p {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 0.4883vw;
  }

  .product-wrapper > .recommended-upsells > .button-row > button {
    width: 5.0293vw;
    height: 5.0293vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.3906vw;
    margin-right: 0.7813vw;
  }

  .product-wrapper > .recommended-upsells > .button-row > button > .prod-name {
    height: 1.0742vw;
    border-top: 0.0488vw solid var(--oliver-border);
  }

  .product-wrapper
    > .recommended-upsells
    > .button-row
    > button
    > .prod-name
    > p {
    font-size: 0.4883vw;
    line-height: 0.7324vw;
  }

  .product-wrapper > .product-footer > .row {
    height: 4.248vw;
  }

  .product-wrapper > .product-footer > .row:nth-child(1) {
    border-bottom: 0.0488vw solid var(--oliver-border);
  }

  .product-wrapper > .product-footer > .row > button#addProductNote {
    border: 0.0488vw solid var(--oliver-blue);
    border-radius: 0.3906vw;
    font-size: 0.6836vw;
    line-height: 0.6836vw;
  }

  .product-wrapper > .product-footer > .row > button#addProductNote > img {
    width: 0.6836vw;
    height: 0.7324vw;
    margin-right: 0.4883vw;
  }

  .product-wrapper > .product-footer > .row > button#addProductDiscount {
    border: 0.0488vw solid var(--oliver-blue);
    border-radius: 0.3906vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .product-wrapper > .product-footer > .row > .increment-input {
    width: 6.9336vw;
    margin-right: 1.123vw;
  }

  .product-wrapper > .product-footer > .row > .increment-input > button {
    width: 1.9531vw;
    height: 1.9531vw;
    border-radius: 0.3906vw;
  }

  .product-wrapper
    > .product-footer
    > .row
    > .increment-input
    > button:nth-child(1)
    > img {
    width: 0.6836vw;
    height: 1.4648vw;
  }

  .product-wrapper
    > .product-footer
    > .row
    > .increment-input
    > button:nth-child(3)
    > img {
    width: 0.7813vw;
    height: 1.4648vw;
  }

  .product-wrapper > .product-footer > .row > .increment-input > input {
    height: 1.9531vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .product-wrapper > .product-footer > .row > button#addProductToCart {
    height: 2.6855vw;
    border-radius: 0.3906vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .product-wrapper > .product-footer > .row > button#addProductToCart > img {
    width: 1.416vw;
    height: 1.416vw;
    margin-right: 0.3906vw;
  }

  .checkout-wrapper {
    grid-template-columns: 3.5156vw 25vw auto;
    grid-template-rows: 3.3203vw auto;
  }

  .checkout-wrapper > .cart {
    border-left: 0.0488vw solid var(--oliver-border);
    border-top: 0.0488vw solid var(--oliver-border);
  }

  .checkout-wrapper > .checkout-body {
    border-left: 0.0488vw solid var(--oliver-border);
    border-top: 0.0488vw solid var(--oliver-border);
  }

  .checkout-wrapper > .header {
    padding: 0 0.8301vw 0 0.0488vw;
  }

  .checkout-wrapper > .header > button#exitCheckoutButton {
    width: 1.8555vw;
    height: 1.8555vw;
    margin-right: 0.3906vw;
  }

  .checkout-wrapper > .header > p {
    font-size: 0.9766vw;
    line-height: 1.416vw;
  }

  .checkout-wrapper > .header > button#userInfoButton {
    width: 1.9531vw;
    height: 1.9531vw;
  }

  .checkout-wrapper
    > .page-options-wrapper
    > .page-options
    > button
    > .img-container {
    width: 1.1719vw;
    height: 1.1719vw;
    margin-right: 0.2441vw;
  }

  .checkout-wrapper > .page-options-wrapper > .page-options > button > p {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .checkout-wrapper > .checkout-body {
    padding: 2.9297vw 2.9297vw 1.9531vw 2.9297vw;
  }

  .checkout-wrapper > .checkout-body > .balance-container {
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.3906vw;
    padding: 1.2207vw 1.4648vw;
    margin-bottom: 0.4395vw;
  }

  .checkout-wrapper > .checkout-body > .balance-container:hover {
    border-width: 0.0977vw;
  }

  .checkout-wrapper > .checkout-body > .balance-container > .row {
    margin-top: 0.1465vw;
  }

  .checkout-wrapper > .checkout-body > .balance-container > .row:nth-child(1) {
    margin-bottom: 0.3418vw;
  }

  .checkout-wrapper > .checkout-body > .balance-container p.style1 {
    font-size: 0.9766vw;
    line-height: 1.3672vw;
  }

  .checkout-wrapper > .checkout-body > .balance-container p.style2 {
    font-size: 1.4648vw;
    line-height: 2.0508vw;
  }

  .checkout-wrapper > .checkout-body > .balance-container p.style3 {
    font-size: 0.7813vw;
    line-height: 1.0742vw;
  }

  .checkout-wrapper > .checkout-body > .balance-container p.style4 {
    font-size: 0.7813vw;
    line-height: 1.0742vw;
  }

  .checkout-wrapper > .checkout-body > #bottomText {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 1.4648vw;
  }

  .checkout-wrapper > .checkout-body > p.style2 {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
    margin-bottom: 0.5859vw;
  }

  .checkout-wrapper > .checkout-body > .button-row {
    margin-bottom: 0.9277vw;
  }

  .checkout-wrapper > .checkout-body > .button-row > button {
    height: 2.4414vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.3906vw;
    margin-left: 0.9766vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .checkout-wrapper
    > .checkout-body
    > .button-row
    > button:not(:disabled):hover {
    border-width: 0.0977vw;
  }

  .checkout-wrapper > .checkout-body > .button-row + p.style2 {
    padding-top: 0.5371vw;
  }

  .checkout-wrapper > .checkout-body > p.style3 {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-top: -0.3906vw;
    margin: -0.3906vw 0 0.5859vw 0;
  }

  .checkout-wrapper > .checkout-body > .payment-types {
    margin-top: 1.4648vw;
  }

  .checkout-wrapper > .checkout-body > .payment-types > p {
    font-size: 0.7813vw;
    line-height: 0.9766vw;
    margin-bottom: 0.6836vw;
  }

  .checkout-wrapper
    > .checkout-body
    > .payment-types
    > .button-container
    > button {
    width: calc((100% - 2.9297vw) / 4);
    margin: 0 0.9766vw 0.9766vw 0;
    border-radius: 0.3906vw;
    font-size: 1.2207vw;
    line-height: 1.6113vw;
  }

  .refund-wrapper {
    grid-template-columns: 3.5156vw 25vw auto;
    grid-template-rows: 3.3203vw auto;
  }

  .refund-wrapper > .cart {
    border-left: 0.0488vw solid var(--oliver-border);
    border-top: 0.0488vw solid var(--oliver-border);
  }

  .refund-wrapper > .refund-body {
    border-left: 0.0488vw solid var(--oliver-border);
    border-top: 0.0488vw solid var(--oliver-border);
  }

  .refund-wrapper > .header {
    padding: 0 0.8301vw 0 0.0488vw;
  }

  .refund-wrapper > .header > button#exitCheckoutButton {
    width: 1.8555vw;
    height: 1.8555vw;
    margin-right: 0.3906vw;
  }

  .refund-wrapper > .header > p {
    font-size: 0.9766vw;
    line-height: 1.416vw;
  }

  .refund-wrapper > .header > button#userInfoButton {
    width: 1.9531vw;
    height: 1.9531vw;
  }

  .refund-wrapper
    > .page-options-wrapper
    > .page-options
    > button
    > .img-container {
    width: 1.1719vw;
    height: 1.1719vw;
    margin-right: 0.2441vw;
  }

  .refund-wrapper > .page-options-wrapper > .page-options > button > p {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .refund-wrapper > .cart {
    border-bottom: 0.0488vw solid var(--oliver-border);
  }

  .refund-wrapper > .cart > .body > .cart-item > .increment-input {
    width: 6.3477vw;
    height: 1.7578vw;
    margin-top: 0.4883vw;
    padding: 0.2441vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.293vw;
  }

  .refund-wrapper > .cart > .body > .cart-item > .increment-input > button {
    border-radius: 0.293vw;
  }

  .refund-wrapper
    > .cart
    > .body
    > .cart-item
    > .increment-input
    > button
    > img {
    width: 0.4883vw;
    height: 0.4883vw;
  }

  .refund-wrapper > .cart > .body > .cart-item > .increment-input > input {
    font-size: 0.7813vw;
    line-height: 1.0254vw;
  }

  .refund-wrapper > .cart > .footer > .totals > .row > button {
    font-size: 0.7813vw;
    line-height: 1.0742vw;
  }

  .refund-wrapper > .cart > .footer > .totals > .row > p.type2 {
    font-size: 0.7813vw;
    line-height: 1.0742vw;
  }

  .refund-wrapper > .refund-body {
    padding: 2.9297vw 2.9297vw 1.9531vw 2.9297vw;
  }

  .refund-wrapper > .refund-body > .balance-container {
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.3906vw;
    padding: 1.2207vw 1.4648vw;
    margin-bottom: 0.4395vw;
    margin-bottom: 1.4648vw;
  }

  .refund-wrapper > .refund-body > .balance-container > .row {
    margin-top: 0.1465vw;
  }

  .refund-wrapper > .refund-body > .balance-container > .row:nth-child(1) {
    margin-bottom: 0.3418vw;
  }

  .refund-wrapper > .refund-body > .balance-container p.style1 {
    font-size: 0.9766vw;
    line-height: 1.3672vw;
  }

  .refund-wrapper > .refund-body > .balance-container p.style2 {
    font-size: 1.4648vw;
    line-height: 2.0508vw;
  }

  .refund-wrapper > .refund-body > .balance-container p.style3 {
    font-size: 0.7813vw;
    line-height: 1.0742vw;
  }

  .refund-wrapper > .refund-body > .balance-container p.style4 {
    font-size: 0.7813vw;
    line-height: 1.0742vw;
  }

  .refund-wrapper > .refund-body > p.style2 {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
    margin-bottom: 0.5859vw;
  }

  .refund-wrapper > .refund-body > .button-row {
    margin-bottom: 0.9277vw;
  }

  .refund-wrapper > .refund-body > .button-row > button {
    height: 2.4414vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.3906vw;
    margin-left: 0.9766vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .refund-wrapper > .refund-body > .button-row > button:not(:disabled):hover {
    border-width: 0.0977vw;
  }

  .refund-wrapper > .refund-body > .button-row + p.style2 {
    padding-top: 0.5371vw;
  }

  .refund-wrapper > .refund-body > p.style3 {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-top: -0.3906vw;
    margin: -0.3906vw 0 0.5859vw 0;
  }

  .refund-wrapper > .refund-body > .payment-types {
    margin-top: 1.4648vw;
  }

  .refund-wrapper > .refund-body > .payment-types > p {
    font-size: 0.7813vw;
    line-height: 0.9766vw;
    margin-bottom: 0.6836vw;
  }

  .refund-wrapper > .refund-body > .payment-types > .button-container > button {
    width: calc((100% - 2.9297vw) / 4);
    margin: 0 0.9766vw 0.9766vw 0;
    border-radius: 0.3906vw;
    font-size: 1.2207vw;
    line-height: 1.6113vw;
  }

  .sale-complete-wrapper > .main {
    padding: 0.9766vw 0;
  }

  .sale-complete-wrapper > .main > img {
    width: 2.4414vw;
    height: 2.4414vw;
    margin: auto 0 0.8789vw 0;
  }

  .sale-complete-wrapper > .main > p {
    font-size: 0.6836vw;
    line-height: 0.8789vw;
    margin-bottom: 0.3906vw;
  }

  .sale-complete-wrapper > .main > #loadingDiv {
    margin-bottom: 2.0508vw;
  }

  .sale-complete-wrapper > .main > #loadingDiv > img {
    width: 1.123vw;
    height: 1.123vw;
    margin-right: 0.3906vw;
  }

  .sale-complete-wrapper > .main > #loadingDiv > p {
    font-size: 0.7813vw;
    line-height: 1.0254vw;
  }

  .sale-complete-wrapper > .main > button#openUpdateTransactionStatus {
    padding: 0.3906vw;
    font-size: 0.9766vw;
    line-height: 1.2695vw;
    margin-bottom: 2.0508vw;
  }

  .sale-complete-wrapper > .main > button#openUpdateTransactionStatus > img {
    width: 0.7324vw;
    height: 0.7324vw;
    margin-left: 0.3906vw;
  }

  .sale-complete-wrapper > .main > .change-container {
    width: 23.9258vw;
    height: 5.0781vw;
    border-radius: 0.4883vw;
    margin-bottom: 2.4414vw;
  }

  .sale-complete-wrapper > .main > .change-container > p.style1 {
    font-size: 1.0742vw;
    line-height: 1.416vw;
    margin-bottom: 0.3418vw;
  }

  .sale-complete-wrapper > .main > .change-container > p.style2 {
    font-size: 0.7813vw;
    line-height: 1.0254vw;
  }

  .sale-complete-wrapper > .main > label.email-label {
    margin-bottom: 0.9766vw;
  }

  .sale-complete-wrapper > .main > label.email-label > input {
    width: 23.9258vw;
    height: 2.7344vw;
    border-radius: 0.3906vw;
    padding: 0 3.3691vw 0 0.9277vw;
    font-size: 0.6836vw;
    line-height: 0.8789vw;
  }

  .sale-complete-wrapper > .main > label.email-label > button {
    right: 0.293vw;
    top: 0.293vw;
    width: 5.7617vw;
    height: 2.1484vw;
    border-radius: 0.293vw;
    font-size: 0.6836vw;
    line-height: 0.8789vw;
  }

  .sale-complete-wrapper > .main > label.checkbox-label {
    font-size: 0.7813vw;
    line-height: 1.0254vw;
    margin-bottom: 2.0996vw;
  }

  .sale-complete-wrapper > .main > label.checkbox-label > input {
    left: -488.2813vw;
  }

  .sale-complete-wrapper > .main > label.checkbox-label > .custom-checkbox {
    width: 1.1719vw;
    height: 1.1719vw;
    border-radius: 0.3418vw;
    margin-left: 0.3906vw;
    border: 0.0977vw solid #197bc1;
  }

  .sale-complete-wrapper
    > .main
    > label.checkbox-label
    > .custom-checkbox
    > img {
    width: 0.7813vw;
    height: 0.7813vw;
  }

  .sale-complete-wrapper > .main > button {
    width: 11.6211vw;
    height: 2.7344vw;
    border: 0.0488vw solid #fff;
    border-radius: 0.3906vw;
    font-size: 0.7813vw;
    line-height: 1.1719vw;
  }

  .sale-complete-wrapper > .footer {
    padding-top: 0.9766vw;
    padding: 0 1.8555vw 1.8555vw 1.8555vw;
  }

  .sale-complete-wrapper > .footer > .button-container {
    width: 9.6191vw;
  }

  .sale-complete-wrapper > .footer > .button-container > button#endSession {
    width: 9.766vw;
    height: 2.734vw;
    border: 0.0488vw solid #fff;
    border-radius: 0.293vw;
    font-size: 0.7813vw;
    line-height: 1.0254vw;
  }

  .sale-complete-wrapper
    > .footer
    > .button-container
    > button#endSession
    > img {
    width: 1.2695vw;
    height: 1.0742vw;
    margin-right: 0.2441vw;
  }

  .sale-complete-wrapper > .footer > .button-container > button#newSale {
    width: 9.766vw;
    height: 2.734vw;
    border-radius: 0.293vw;
    font-size: 0.9766vw;
    line-height: 1.2695vw;
  }

  .sale-complete-wrapper > .footer > .app-container {
    padding: 0.7324vw 0.7324vw 0 0.7324vw;
    border-radius: 0.7324vw;
    max-width: 14.4043vw;
  }

  .sale-complete-wrapper > .footer > .app-container > button {
    width: 2.6855vw;
    height: 2.6855vw;
    border-radius: 0.3418vw;
    margin: 0 0.7324vw 0.7324vw 0;
  }

  .refund-complete-wrapper > .main > img {
    width: 9.4238vw;
    height: 4.541vw;
    margin: 0.9766vw 0 2.1973vw 0;
  }

  .refund-complete-wrapper > .main > label.email-label {
    margin-bottom: 0.9766vw;
  }

  .refund-complete-wrapper > .main > label.email-label > input {
    width: 23.9258vw;
    height: 2.7344vw;
    border-radius: 0.3906vw;
    padding: 0 3.3691vw 0 0.9277vw;
    font-size: 0.6836vw;
    line-height: 0.8789vw;
  }

  .refund-complete-wrapper > .main > label.email-label > button {
    right: 0.293vw;
    top: 0.293vw;
    width: 5.7617vw;
    height: 2.1484vw;
    border-radius: 0.293vw;
    font-size: 0.6836vw;
    line-height: 0.8789vw;
  }

  .refund-complete-wrapper > .main > label.checkbox-label {
    font-size: 0.7813vw;
    line-height: 1.0254vw;
    margin-bottom: 2.0996vw;
  }

  .refund-complete-wrapper > .main > label.checkbox-label > input {
    left: -488.2813vw;
  }

  .refund-complete-wrapper > .main > label.checkbox-label > .custom-checkbox {
    width: 1.1719vw;
    height: 1.1719vw;
    border-radius: 0.3418vw;
    margin-left: 0.3906vw;
    border: 0.0977vw solid var(--error);
  }

  .refund-complete-wrapper
    > .main
    > label.checkbox-label
    > .custom-checkbox
    > img {
    width: 0.7813vw;
    height: 0.7813vw;
  }

  .refund-complete-wrapper > .main > button {
    width: 11.6211vw;
    height: 2.7344vw;
    border: 0.0488vw solid #fff;
    border-radius: 0.3906vw;
    font-size: 0.7813vw;
    line-height: 1.1719vw;
  }

  .refund-complete-wrapper > .footer {
    padding: 0.9766vw 1.8555vw;
  }

  .refund-complete-wrapper > .footer > .button-container {
    width: 9.6191vw;
  }

  .refund-complete-wrapper > .footer > .button-container > button#endSession {
    width: 9.7656vw;
    height: 2.7344vw;
    border: 0.0488vw solid #fff;
    border-radius: 0.293vw;
    font-size: 0.7813vw;
    line-height: 1.0254vw;
  }

  .refund-complete-wrapper
    > .footer
    > .button-container
    > button#endSession
    > img {
    width: 1.2695vw;
    height: 1.0742vw;
    margin-right: 0.2441vw;
  }

  .refund-complete-wrapper > .footer > .button-container > button#newSale {
    width: 9.7656vw;
    height: 2.7344vw;
    border-radius: 0.293vw;
    font-size: 0.9766vw;
    line-height: 1.2695vw;
  }

  .refund-complete-wrapper > .footer > .app-container {
    padding: 0.7324vw 0.7324vw 0 0.7324vw;
    border-radius: 0.7324vw;
    max-width: 14.4043vw;
  }

  .refund-complete-wrapper > .footer > .app-container > button {
    width: 2.6855vw;
    height: 2.6855vw;
    border-radius: 0.3418vw;
    margin: 0 0.7324vw 0.7324vw 0;
  }

  .customer-view-wrapper {
    grid-template-columns: 3.5156vw max(16.0156vw, 25vw) max(16.0156vw, 25vw) 1fr;
  }

  .customer-view-wrapper > .cv-search {
    border-left: 0.0488vw solid var(--oliver-border);
  }

  .customer-view-wrapper > .cv-list {
    border-left: 0.0488vw solid var(--oliver-border);
  }

  .customer-view-wrapper > .cv-detailed {
    border-left: 0.0488vw solid var(--oliver-border);
  }

  .cv-search > .header {
    height: 3.3203vw;
    padding: 0 0.6348vw 0 1.0742vw;
    border-bottom: 0.0488vw solid var(--oliver-border);
  }

  .cv-search > .header > p {
    font-size: 0.9766vw;
    line-height: 1.416vw;
  }

  .cv-search > .header > button {
    width: 5.3711vw;
    height: 1.7578vw;
    border-radius: 0.3906vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .cv-search > .header > button > img {
    width: 0.8789vw;
    height: 0.8789vw;
    margin-right: 0.3906vw;
  }

  .cv-search > .body {
    padding: 0.7324vw 1.0742vw;
  }

  .cv-search > .body > .row {
    margin-bottom: 0.6348vw;
  }

  .cv-search > .body > .row > img {
    width: 0.9766vw;
    height: 0.9766vw;
    margin-right: 0.4883vw;
  }

  .cv-search > .body > .row > p {
    font-size: 0.7813vw;
    line-height: 1.123vw;
  }

  .cv-search > .body > .row > button {
    padding: 0.2441vw;
    transform: translateY(-0.2441vw);
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .cv-search > .body > label {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 0.1953vw;
  }

  .cv-search > .body > input {
    height: 2.1484vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.293vw;
    padding: 0 0.5859vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 0.9766vw;
  }

  .cv-search > .body > button {
    height: 2.1484vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .cv-list > .header {
    height: 3.3203vw;
    padding: 0 0.7813vw;
    border-bottom: 0.0488vw solid var(--oliver-border);
  }

  .cv-list > .header > p {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin: 0 0.5859vw 0 auto;
  }

  .cv-list > .header > .sort-wrapper {
    width: 7.5684vw;
    height: 1.7578vw;
  }

  .cv-list > .header > .sort-wrapper.open {
    box-shadow: 0vw 0.293vw 0.4883vw rgba(94, 94, 94, 0.1);
  }

  .cv-list > .header > .sort-wrapper > img {
    top: calc(50% - 0.293vw);
    right: 0.4395vw;
    width: 0.6348vw;
    height: 0.6348vw;
  }

  .cv-list > .header > .sort-wrapper.open > img {
    transform: rotate(180deg) translateY(-0.0977vw);
  }

  .cv-list > .header > .sort-wrapper > input {
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.3906vw;
    padding: 0 1.2207vw 0 0.4883vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .cv-list > .header > .sort-wrapper.open > input {
    border-radius: 0.3906vw 0.3906vw 0 0;
  }

  .cv-list > .header > .sort-wrapper > .option-container > .option {
    height: 1.7578vw;
    border: 0.0488vw solid var(--oliver-border);
    padding: 0 0.4883vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .cv-list > .header > .sort-wrapper > .option-container > .option:last-child {
    border-radius: 0 0 0.3906vw 0.3906vw;
  }

  .cv-list > .body > .no-search-results-list {
    margin: 4.0527vw auto;
  }

  .cv-list > .body > .no-search-results-list > p.style1 {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 0.4883vw;
  }

  .cv-list > .body > .no-search-results-list > p.style2 {
    font-size: 0.6348vw;
    line-height: 0.8789vw;
  }

  .cv-list > .body > .filter-name {
    height: 1.4648vw;
    border-bottom: 0.0488vw solid var(--oliver-border);
    padding: 0 0.7813vw;
  }

  .cv-list > .body > .filter-name > p {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .cv-list > .body > button.customer-card {
    height: 4.5898vw;
    border-bottom: 0.0488vw solid var(--oliver-border);
    padding: 0 0.7813vw;
  }

  .cv-list > .body > button.customer-card > .avatar {
    width: 1.7578vw;
    height: 1.7578vw;
    margin-right: 0.9766vw;
  }

  .cv-list > .body > button.customer-card > .text-group > p.style1 {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
  }

  .cv-list > .body > button.customer-card > .text-group > p.style2 {
    font-size: 0.5859vw;
    line-height: 0.8301vw;
  }

  .cv-list > .body > button.customer-card > .selected-indicator {
    width: 0.3418vw;
    height: 3.7109vw;
    top: calc(50% - 1.8555vw);
    border-radius: 0 0.1953vw 0.1953vw 0;
  }

  .cv-detailed {
    padding: 0 1.123vw;
  }

  .cv-detailed > .no-search-results-detailed > p.style1 {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 0.4883vw;
  }

  .cv-detailed > .no-search-results-detailed > p.style2 {
    font-size: 0.6348vw;
    line-height: 0.8789vw;
  }

  .cv-detailed > .quick-info {
    padding: 0.7324vw;
    margin: 0.9766vw 0 0.8301vw 0;
  }

  .cv-detailed > .quick-info > .avatar {
    width: 1.9531vw;
    height: 1.9531vw;
    margin: 0.1953vw 0.9277vw 0 0;
  }

  .cv-detailed > .quick-info > .text-group > p.style1 {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
  }

  .cv-detailed > .quick-info > .text-group > p.style2 {
    font-size: 0.5859vw;
    line-height: 0.8301vw;
  }

  .cv-detailed > .quick-info > button {
    width: 3.418vw;
    height: 1.7578vw;
    border: 0.0488vw solid var(--oliver-blue);
    border-radius: 0.3906vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .cv-detailed > .quick-info > button > img {
    width: 0.7324vw;
    height: 0.7813vw;
    margin-right: 0.2441vw;
  }

  .cv-detailed > .cust-totals {
    height: 4.0527vw;
    padding-bottom: 1.0742vw;
    border-bottom: 0.0488vw solid var(--oliver-border);
  }

  .cv-detailed > .cust-totals > .col {
    border-left: 0.0488vw solid var(--oliver-border);
  }

  .cv-detailed > .cust-totals > .col > p.style1 {
    font-size: 1.0742vw;
    line-height: 1.3184vw;
    margin-bottom: 0.1953vw;
  }

  .cv-detailed > .cust-totals > .col > p.style2 {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .cv-detailed > .cust-totals > .col > button {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .cv-detailed > .cust-totals > .col > button > img {
    width: 0.7813vw;
    height: 0.7813vw;
    margin-left: 0.2441vw;
  }

  .cv-detailed > .bill-ship-info {
    padding: 0.9766vw 0;
    border-bottom: 0.0488vw solid var(--oliver-border);
  }

  .cv-detailed > .bill-ship-info > .col:nth-child(1) {
    margin-right: 1.9531vw;
  }

  .cv-detailed > .bill-ship-info > .col > p.style1 {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
    margin-bottom: 0.3906vw;
  }

  .cv-detailed > .bill-ship-info > .col > p.style2 {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .cv-detailed > .cust-notes {
    width: calc(100% + 2.2461vw);
    min-height: 4.8828vw;
    padding: 0.9766vw 0;
    margin: 0 -1.123vw;
  }

  .cv-detailed > .cust-notes > .header-row {
    width: calc(100% - 2.2461vw);
    margin-bottom: 0.5371vw;
  }

  .cv-detailed > .cust-notes > .header-row > p {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
  }

  .cv-detailed > .cust-notes > .header-row > button {
    font-size: 0.6836vw;
    line-height: 1.0254vw;
  }

  .cv-detailed > .cust-notes > .customer-note {
    width: calc(100% - 2.2461vw);
    margin-top: 0.9766vw;
  }

  .cv-detailed > .cust-notes > .customer-note > .row > p.style1 {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .cv-detailed > .cust-notes > .customer-note > .row > p.style2 {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-right: 0.293vw;
  }

  .cv-detailed > .cust-notes > .customer-note > .row > button {
    width: 0.8301vw;
    height: 0.8301vw;
  }

  .cv-detailed > .cust-notes > .customer-note > .row > button > img {
    width: 0.7813vw;
    height: 0.7813vw;
  }

  .cv-detailed > .cust-notes > .customer-note > p {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin: 0.4883vw 0.293vw 0 0.4883vw;
  }

  .cv-detailed > .footer {
    height: 3.6133vw;
    border-top: 0.0488vw solid var(--oliver-border);
  }

  .cv-detailed > .footer > button {
    height: 2.1484vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .cv-detailed > .footer > button:first-of-type {
    margin-right: 0.6348vw;
  }

  .transactions-wrapper {
    grid-template-columns: 3.5156vw max(16.0156vw, 25vw) max(16.0156vw, 25vw) 1fr;
  }

  .transactions-wrapper > .transactions-search {
    border-left: 0.0488vw solid var(--oliver-border);
  }

  .transactions-wrapper > .transactions-list {
    border-left: 0.0488vw solid var(--oliver-border);
  }

  .transactions-wrapper > .transactions-detailed {
    border-left: 0.0488vw solid var(--oliver-border);
  }

  .transactions-search > .search-header {
    height: 3.3203vw;
    border-bottom: 0.0488vw solid var(--oliver-border);
    padding: 0 0.7813vw 0 1.2207vw;
  }

  .transactions-search > .search-header > p {
    font-size: 0.9766vw;
    line-height: 1.416vw;
  }

  .transactions-search > .search-header > button {
    width: 2.832vw;
    height: 1.7578vw;
    border-radius: 0.3906vw;
    padding: 0.1953vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .transactions-search > .search-body {
    padding: 0 1.0742vw;
  }

  .transactions-search > .search-body label {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 0.1953vw;
  }

  .transactions-search > .search-body > label:first-of-type {
    margin-top: 0.6348vw;
  }

  .transactions-search > .search-body input {
    height: 2.1484vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.293vw;
    padding: 0 0.5859vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 0.9766vw;
  }

  .transactions-search > .search-body > input:first-of-type {
    margin-bottom: 0.3418vw;
  }

  .transactions-search > .search-body > p {
    font-size: 0.5859vw;
    line-height: 0.8301vw;
  }

  .transactions-search > .search-body > .divider {
    height: 0.0488vw;
    margin: 0.9766vw 0;
  }

  .transactions-search > .search-body > .dropdown-wrapper {
    height: 2.1484vw;
    margin-bottom: 0.9766vw;
  }

  .transactions-search > .search-body > .dropdown-wrapper.open {
    box-shadow: 0vw 0.4883vw 0.9766vw rgba(119, 135, 147, 0.3);
  }

  .transactions-search > .search-body > .dropdown-wrapper > img {
    right: 0.6836vw;
    top: calc(50% - 0.4395vw);
    width: 0.8789vw;
    height: 0.8789vw;
  }

  .transactions-search > .search-body > .dropdown-wrapper.open > input {
    border-radius: 0.293vw 0.293vw 0 0;
  }

  .transactions-search > .search-body > .dropdown-wrapper > .option-list {
    max-height: 8.5938vw;
    border-radius: 0 0 0.3418vw 0.3418vw;
  }

  .transactions-search
    > .search-body
    > .dropdown-wrapper
    > .option-list
    > .option {
    height: 2.1484vw;
    padding: 0 0.5859vw;
    border: 0.0488vw solid var(--oliver-border);
  }

  .transactions-search
    > .search-body
    > .dropdown-wrapper
    > .option-list
    > .option:last-child {
    border-radius: 0 0 0.3418vw 0.3418vw;
  }

  .transactions-search
    > .search-body
    > .dropdown-wrapper
    > .option-list
    > .option
    > p {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .transactions-search > .search-body > .input-row > .input-col:first-child {
    margin-right: 0.8789vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper {
    margin-bottom: 0.9766vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > input {
    padding-right: 1.5625vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > button {
    right: 0.5859vw;
    top: calc(50% - 0.4883vw);
    width: 0.9766vw;
    height: 0.9766vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector {
    padding: 1.2207vw 0.9766vw;
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.293vw;
    margin-top: 0.3418vw;
    filter: drop-shadow(0vw 0.4883vw 0.9766vw rgba(119, 135, 147, 0.1));
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .header-row {
    width: 13.6719vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .header-row
    > button {
    height: 1.4648vw;
    padding: 0 0.4883vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .header-row
    > button
    > img {
    width: 1.9531vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .header-row
    > div {
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .day-row {
    width: 13.6719vw;
    border-bottom: 0.0488vw solid var(--oliver-border);
    margin-bottom: 0.4883vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .day-row
    > .day {
    height: 1.9531vw;
    font-size: 0.7813vw;
    line-height: 1.1719vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .date-row {
    width: 13.6719vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .date-row
    > button.cell {
    height: 1.9531vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    border-radius: 0.293vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .month-row {
    width: 15.625vw;
    margin-top: 0.7324vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .month-row
    > button.cell {
    height: 1.9531vw;
    font-size: 0.7813vw;
    line-height: 0.9766vw;
    border-radius: 0.293vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .year-row {
    width: 13.6719vw;
    margin-top: 0.7324vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .year-row
    > button.cell {
    height: 1.9531vw;
    font-size: 0.7813vw;
    line-height: 0.9766vw;
    border-radius: 0.293vw;
  }

  .transactions-search > .search-body > button {
    height: 2.1973vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
    margin: auto 0 0.7324vw 0;
  }

  .transactions-list > .header {
    height: 3.3203vw;
    border-bottom: 0.0488vw solid var(--oliver-border);
    padding: 0 0.9766vw;
  }

  .transactions-list > .header > p {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .transactions-list > .header > .sort-wrapper {
    width: 7.5684vw;
    height: 1.7578vw;
  }

  .transactions-list > .header > .sort-wrapper.open {
    box-shadow: 0vw 0.293vw 0.4883vw rgba(94, 94, 94, 0.1);
  }

  .transactions-list > .header > .sort-wrapper > img {
    top: calc(50% - 0.293vw);
    right: 0.4395vw;
    width: 0.6348vw;
    height: 0.6348vw;
  }

  .transactions-list > .header > .sort-wrapper.open > img {
    transform: rotate(180deg) translateY(-0.0977vw);
  }

  .transactions-list > .header > .sort-wrapper > input {
    border: 0.0488vw solid var(--oliver-border);
    border-radius: 0.3906vw;
    padding: 0 1.2207vw 0 0.4883vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .transactions-list > .header > .sort-wrapper.open > input {
    border-radius: 0.3906vw 0.3906vw 0 0;
  }

  .transactions-list > .header > .sort-wrapper > .option-container > .option {
    height: 1.7578vw;
    border: 0.0488vw solid var(--oliver-border);
    padding: 0 0.4883vw;
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .transactions-list
    > .header
    > .sort-wrapper
    > .option-container
    > .option:last-child {
    border-radius: 0 0 0.3906vw 0.3906vw;
  }

  .transactions-list > .body > .no-results {
    margin-top: 2.832vw;
  }

  .transactions-list > .body > .no-results > p.style1 {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 0.4883vw;
  }

  .transactions-list > .body > .no-results > p.style2 {
    font-size: 0.6348vw;
    line-height: 0.8789vw;
  }

  .transactions-list > .body > .filter-name {
    height: 1.4648vw;
    border-top: 0.0488vw solid var(--oliver-border);
    padding: 0 0.7813vw;
  }

  .transactions-list > .body > .filter-name > p {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .transactions-list > .body > :last-child {
    border-bottom: 0.0488vw solid var(--oliver-border);
  }

  .transactions-list > .body > button {
    height: 4.6875vw;
    border-top: 0.0488vw solid var(--oliver-border);
    padding: 0.4883vw 0.9766vw 0.4883vw 1.1719vw;
  }

  .transactions-list > .body > button > .col:first-child {
    margin-right: 0.4883vw;
  }

  .transactions-list > .body > button > .col > p.style1 {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
  }

  .transactions-list > .body > button > .col > p.style2 {
    font-size: 0.5859vw;
    line-height: 0.8301vw;
  }

  .transactions-list > .body > button > .col > .row > img {
    width: 0.6836vw;
    height: 0.5859vw;
    margin-right: 0.3906vw;
  }

  .transactions-list > .body > button > .col > .row > p {
    max-width: calc(100% - 1.0742vw);
    font-size: 0.5859vw;
    line-height: 0.8301vw;
  }

  .transactions-list > .body > button > .col > p.style3 {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .transactions-list > .body > button > .col > p.style4 {
    font-size: 0.5859vw;
    line-height: 0.8301vw;
    padding-right: 0.04883vw;
  }

  .transactions-list > .body > button > .selected-indicator {
    top: calc(50% - 1.8555vw);
    width: 0.3418vw;
    height: 3.7109vw;
    border-radius: 0 0.1953vw 0.1953vw 0;
  }

  .transactions-detailed > .no-orders-detailed > p.style1 {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 0.4883vw;
  }

  .transactions-detailed > .no-orders-detailed > p.style2 {
    font-size: 0.6348vw;
    line-height: 0.8789vw;
  }

  .transactions-detailed > .scrollable > .quick-info {
    width: calc(100% - 1.9531vw);
    padding: 0.7324vw;
    margin-top: 0.7324vw;
  }

  .transactions-detailed > .scrollable > .quick-info > .row {
    margin-top: 0.2441vw;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > p.style1 {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > p.style2 {
    font-size: 0.6348vw;
    line-height: 0.8789vw;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > p.style3 {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > .group > img {
    width: 0.8789vw;
    height: 0.7813vw;
    margin-right: 0.3906vw;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > .group > p {
    font-size: 0.7813vw;
    line-height: 1.0254vw;
  }

  .transactions-detailed > .scrollable > .customer-info {
    width: calc(100% - 1.9531vw);
    padding: 0.7324vw 0 0.9766vw 0;
    border-bottom: 0.0488vw solid var(--oliver-border);
  }

  .transactions-detailed > .scrollable > .customer-info > .col > p.style1 {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
    margin-bottom: 0.3906vw;
  }

  .transactions-detailed > .scrollable > .customer-info > .col > p.style2 {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-bottom: 0.1953vw;
  }

  .transactions-detailed > .scrollable > .customer-info > button {
    width: 7.5195vw;
    height: 1.9531vw;
    border: 0.0488vw solid var(--oliver-blue);
    border-radius: 0.293vw;
    font-size: 0.7813vw;
    line-height: 1.1719vw;
  }

  .transactions-detailed > .scrollable > .order-details {
    padding: 0.7324vw 0.9766vw;
    min-height: 4.8828vw;
  }

  .transactions-detailed > .scrollable > .order-details > p {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
    margin-bottom: 1.1719vw;
  }

  .transactions-detailed > .scrollable > .order-details > .item {
    margin-top: 0.9766vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .img-container {
    width: 3.3203vw;
    height: 3.3691vw;
    margin-right: 1.1719vw;
    border-radius: 0.1953vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .img-container
    > .quantity {
    min-width: 1.2695vw;
    height: 1.2695vw;
    padding: 0 0.1465vw;
    right: 0vw;
    top: 0vw;
    border-radius: 0.293vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .img-container
    > .quantity
    > p {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .img-container
    > img {
    border-radius: 0.1953vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .main-row
    > p {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .item-fields {
    padding-top: 0.293vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .item-fields
    > p {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .modifier-fields {
    padding: 0.3906vw 0 0 0.6348vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .modifier-fields
    > .row
    > p {
    font-size: 0.6836vw;
    line-height: 0.9277vw;
    padding-right: 0.4883vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .product-total-fields {
    padding-top: 0.3906vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .product-total-fields
    > div {
    padding-top: 0.293vw;
    border-top: 0.0488vw solid var(--oliver-border);
    margin-top: 0.293vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .product-total-fields
    > div
    > .row
    > p {
    font-size: 0.6836vw;
    line-height: 0.9277vw;
    padding-right: 0.4883vw;
  }

  .transactions-detailed > .scrollable > .order-details > .custom-fields {
    margin-top: 0.7324vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .custom-fields
    > p.style1 {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
    margin-bottom: 0.3418vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .custom-fields
    > p.style2 {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-left: 0.6836vw;
    max-width: calc(100% - 0.6836vw);
    margin-top: 0.1465vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper {
    padding: 0.7324vw 0;
    border-top: 0.0977vw solid var(--oliver-border);
    margin-top: 0.7324vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals {
    width: min(19.5313vw, 75%);
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row {
    padding-bottom: 0.293vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row.border-bottom {
    border-bottom: 0.0488vw solid var(--oliver-border);
    margin-bottom: 0.5371vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row.border-top {
    padding: 0.293vw 0 0 0;
    border-top: 0.0488vw solid var(--oliver-border);
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row
    > p {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row
    > p.style2 {
    font-size: 0.5859vw;
    line-height: 0.8301vw;
  }

  .transactions-detailed > .scrollable > .order-details > .refund-header {
    padding: 0.2441vw 0.3906vw;
    margin: 1.2207vw 0 0.7324vw 0;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .refund-header
    > p.style1 {
    font-size: 0.6836vw;
    line-height: 1.0254vw;
    padding-right: 0.4883vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .refund-header
    > p.style2 {
    font-size: 0.5859vw;
    line-height: 0.8789vw;
  }

  .transactions-detailed > .footer {
    padding: 0.7324vw 0.9766vw;
    border-top: 0.0488vw solid var(--oliver-border);
  }

  .transactions-detailed > .footer > button {
    height: 2.1484vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
    margin-left: 0.5859vw;
  }

  .cash-management-wrapper {
    grid-template-columns: 3.5156vw 33vw 1fr;
  }

  .cash-management-wrapper > .cm-list {
    border-left: 0.0488vw solid var(--oliver-border);
  }

  .cash-management-wrapper > .cm-detailed {
    border-left: 0.0488vw solid var(--oliver-border);
  }

  .cm-list > .cm-list-header-landscape {
    height: 3.3203vw;
    padding: 0 0.9766vw;
    border-bottom: 0.0488vw solid var(--oliver-border);
  }

  .cm-list > .cm-list-header-landscape > p {
    font-size: 0.9766vw;
    line-height: 1.416vw;
  }

  .cm-list > .cm-list-body > .current-register {
    height: 5.0293vw;
    border-bottom: 0.0488vw solid var(--oliver-border);
    padding: 0 0.9766vw;
  }

  .cm-list > .cm-list-body > .current-register > .text-row {
    margin-bottom: 0.1953vw;
  }

  .cm-list > .cm-list-body > .current-register > .text-row > p {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
    margin-right: 0.3418vw;
  }

  .cm-list > .cm-list-body > .current-register > p.style1 {
    font-size: 0.7324vw;
    line-height: 1.0254vw;
    margin-bottom: 0.2441vw;
  }

  .cm-list > .cm-list-body > .current-register > p.style2 {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .cm-list > .cm-list-body > .date {
    height: 1.4648vw;
    border-bottom: 0.0488vw solid var(--oliver-border);
    padding: 0 0.5859vw;
  }

  .cm-list > .cm-list-body > .date > p {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .cm-list > .cm-list-body > .other-register {
    height: 3.7109vw;
    border-bottom: 0.0488vw solid var(--oliver-border);
    padding: 0 1.0742vw;
  }

  .cm-list > .cm-list-body > .other-register.selected::before {
    width: 0.3418vw;
    height: 2.9297vw;
    top: 0.3906vw;
    border-radius: 0 0.1953vw 0.1953vw 0;
  }

  .cm-list > .cm-list-body > .other-register > .row:nth-child(1) {
    margin-bottom: 0.1953vw;
  }

  .cm-list > .cm-list-body > .other-register > .row > p.style1 {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .cm-list > .cm-list-body > .other-register > .row > p.style2 {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }

  .cm-detailed > .detailed-header-landscape {
    width: calc(100% - 1.9531vw);
    height: 3.2715vw;
  }

  .cm-detailed > .detailed-header-landscape > img {
    width: 1.0742vw;
    height: 1.0742vw;
    margin-right: 0.3906vw;
  }

  .cm-detailed > .detailed-header-landscape > p {
    font-size: 0.8789vw;
    line-height: 1.3184vw;
  }

  .cm-detailed > .detailed-quick-info {
    width: calc(100% - 1.9531vw);
    padding: 0.7324vw;
  }

  .cm-detailed > .detailed-quick-info > .row:first-child {
    margin-bottom: 0.4883vw;
  }

  .cm-detailed > .detailed-quick-info > .row > p {
    font-size: 0.6836vw;
    line-height: 1.0254vw;
  }

  .cm-detailed > .detailed-quick-info > .row > .row-group > p {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
    margin-right: 0.4883vw;
  }

  .cm-detailed > .detailed-quick-info > .row > .row-group > .status {
    height: 1.1719vw;
    padding: 0 0.3906vw;
    border-radius: 0.293vw;
    font-size: 0.5859vw;
    line-height: 0.8789vw;
  }

  .cm-detailed > .detailed-quick-info > .row > .col-group > p.style1 {
    font-size: 0.6348vw;
    line-height: 0.9277vw;
  }

  .cm-detailed > .detailed-quick-info > .row > .col-group > p.style2 {
    font-size: 0.7813vw;
    line-height: 1.1719vw;
  }

  .cm-detailed > .detailed-quick-info > .row > button {
    width: 4.2969vw;
    height: 1.9531vw;
    border: 0.0488vw solid var(--oliver-blue);
    border-radius: 0.3906vw;
    font-size: 0.7813vw;
    line-height: 1.1719vw;
  }

  .cm-detailed > .detailed-quick-info > .row > button > img {
    width: 0.9766vw;
    height: 0.9766vw;
    margin-right: 0.3906vw;
  }

  .cm-detailed > .detailed-body {
    padding: 0 0.9766vw;
  }

  .cm-detailed > .detailed-body > .action {
    padding: 0.7324vw 0;
    border-top: 0.0488vw solid var(--oliver-border);
  }

  .cm-detailed > .detailed-body > .action > .header-row > p {
    font-size: 0.6836vw;
    line-height: 1.0254vw;
  }

  .cm-detailed > .detailed-body > .action > .header-row > p.style2 {
    font-size: 0.6348vw;
    line-height: 1.0254vw;
  }

  .cm-detailed > .detailed-body > .action > .body-col > p {
    font-size: 0.6348vw;
    line-height: 0.9766vw;
  }

  .cm-detailed > .detailed-body > .action > .footer-col {
    margin-top: 0.4883vw;
  }

  .cm-detailed > .detailed-body > .action > .footer-col > p {
    font-size: 0.6348vw;
    line-height: 0.9766vw;
  }

  .cm-detailed > .detailed-footer {
    padding: 0.9766vw 1.4648vw;
    border-top: 0.0488vw solid var(--oliver-border);
  }

  .cm-detailed > .detailed-footer > button {
    height: 2.4414vw;
    margin-left: 1.4648vw;
    border-radius: 0.293vw;
    font-size: 0.7813vw;
    line-height: 1.1719vw;
  }

  .error-404-wrapper > .error-404-container {
    width: min(100%, 24.7559vw);
    height: min(100%, 27.0996vw);
    box-shadow: 0vw 0.4883vw 0.9766vw rgba(0, 0, 0, 0.05);
    border-radius: 0.4883vw;
    padding: 0.9766vw;
  }

  .error-404-wrapper > .error-404-container > img {
    width: 6.3477vw;
    height: 4.5898vw;
    margin: auto 0 1.709vw 0;
  }

  .error-404-wrapper > .error-404-container > p.style1 {
    font-size: 1.9531vw;
    line-height: 2.5879vw;
    margin-bottom: 0.4883vw;
  }

  .error-404-wrapper > .error-404-container > p.style2 {
    font-size: 0.9766vw;
    line-height: 1.2695vw;
    margin-bottom: 0.4883vw;
  }

  .error-404-wrapper > .error-404-container > p.style3 {
    font-size: 0.9766vw;
    line-height: 1.2695vw;
    margin-bottom: 1.709vw;
  }

  .error-404-wrapper > .error-404-container > button {
    width: 8.4473vw;
    height: 2.4414vw;
    border-radius: 0.293vw;
    font-size: 0.8789vw;
    line-height: 1.1719vw;
    margin-bottom: 1.709vw;
  }

  .error-404-wrapper > .error-404-container > .row > p {
    font-size: 0.7813vw;
    line-height: 1.0254vw;
    margin-right: 0.1953vw;
  }

  .error-404-wrapper > .error-404-container > .row > a {
    font-size: 0.7813vw;
    line-height: 1.0254vw;
  }
}

@media (orientation: landscape) and (max-width: 1200px) {
  ::-webkit-scrollbar {
    width: 0.5vw;
  }

  ::-webkit-scrollbar-button {
    height: 0.3333vw;
  }

  ::-webkit-scrollbar-thumb {
    width: 0.4167vw;
    border-radius: 0.5vw;
  }

  .avatar {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  input:focus,
  textarea:focus {
    border-width: 0.1667vw !important;
  }

  .loading-page-wrapper {
    padding: 2.0833vw 0;
  }

  .loading-page-wrapper > img {
    width: 8.4167vw;
    height: 2.3333vw;
    margin-bottom: 5vw;
  }

  .loading-page-wrapper > svg {
    width: 7.0833vw;
    height: 7.0833vw;
    margin-bottom: 5vw;
  }

  .loading-page-wrapper > p.style1 {
    font-size: 1.6667vw;
    line-height: 2.1667vw;
    margin-bottom: 0.8333vw;
  }

  .loading-page-wrapper > p.style2 {
    font-size: 1.3333vw;
    line-height: 1.75vw;
    margin-bottom: 5vw;
  }

  .loading-page-wrapper > p.style3 {
    font-size: 1.1667vw;
    line-height: 1.5vw;
  }

  @keyframes animate-svg-fill-8 {
    0% {
      transform: translate(2.0833vw, -2.0833vw);
      fill: transparent;
    }

    10%,
    40% {
      transform: translate(0px, 0px);
      fill: #ffd026;
    }

    50%,
    100% {
      transform: translate(-2.0833vw, 2.0833vw);
      fill: transparent;
    }
  }

  .login-wrapper > img {
    width: 11.9167vw;
    height: 4.1667vw;
    margin: 4.1667vw 0 1.6667vw 0;
  }

  .login-wrapper > p {
    font-size: 1.3333vw;
    line-height: 1.9167vw;
    margin-bottom: 3.665vw;
  }

  .login-wrapper > .login-form {
    width: 28.9167vw;
    margin-bottom: 2.3333vw;
  }

  .login-wrapper > .login-form > label {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .login-wrapper > .login-form > input {
    height: 3.6667vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.1667vw;
    padding: 0 1vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 2.3333vw;
  }

  .login-wrapper > .login-form > .row {
    margin-bottom: 2.3333vw;
  }

  .login-wrapper > .login-form > .row > a {
    font-size: 1.1667vw;
    line-height: 1.75vw;
  }

  .login-wrapper > .login-form > .row > .custom-checkbox-wrapper {
    font-size: 1vw;
    line-height: 1.75vw;
  }

  .login-wrapper
    > .login-form
    > .row
    > .custom-checkbox-wrapper
    > input[type="checkbox"] {
    left: -833.3333vw;
  }

  .login-wrapper
    > .login-form
    > .row
    > .custom-checkbox-wrapper
    > .custom-checkbox {
    width: 1.6667vw;
    height: 1.6667vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.3333vw;
    margin-right: 0.9167vw;
  }

  .login-wrapper > .login-form > button {
    height: 4vw;
    border-radius: 0.3333vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .login-wrapper > .or-row {
    width: 28.9167vw;
    margin-bottom: 2.3333vw;
  }

  .login-wrapper > .or-row > .divider {
    width: 10.6275vw;
    height: 0.0833vw;
  }

  .login-wrapper > .or-row > p {
    font-size: 1.6667vw;
    line-height: 1.75vw;
  }

  .login-wrapper > button {
    width: 28.9167vw;
    height: 4vw;
    padding-left: 4vw;
    border-radius: 0.3333vw;
    margin-bottom: 1.3333vw;
    font-size: 1.5vw;
    line-height: 1.75vw;
  }

  .login-wrapper > button:last-of-type {
    margin-bottom: 4.5vw;
  }

  .login-wrapper > button > .img-container {
    left: 0.1667vw;
    top: 0.1667vw;
    width: 3.6667vw;
    height: 3.6667vw;
    border-radius: 0.3333vw;
  }

  .login-wrapper > #googleButton > .img-container > img {
    width: 3.7658vw;
    height: 3.2175vw;
  }

  .login-wrapper > #facebookButton > .img-container > img {
    width: 2.21vw;
    height: 1.7742vw;
  }

  .login-wrapper > #appleButton > .img-container > img {
    width: 3.3817vw;
    height: 2.285vw;
  }

  .login-wrapper > .row {
    margin-bottom: 4.1667vw;
  }

  .login-wrapper > .row > p {
    font-size: 1.1667vw;
    line-height: 1.75vw;
    margin-right: 0.4167vw;
  }

  .login-wrapper > .row > a {
    font-size: 1.1667vw;
    line-height: 1.75vw;
  }

  .choose-wrapper > .choose-header {
    height: 5.3333vw;
    padding: 0 1.6667vw;
  }

  .choose-wrapper > .choose-header > button {
    width: 9.0833vw;
    height: 3.3333vw;
    border: 0.0833vw solid var(--oliver-blue);
    border-radius: 0.5vw;
    font-size: 1.3333vw;
    line-height: 2vw;
  }

  .choose-wrapper > .choose-header > button > img {
    width: 0.5917vw;
    height: 1.1833vw;
    margin-right: 0.8333vw;
  }

  .choose-wrapper > .choose-header > p {
    font-size: 1.3333vw;
    line-height: 2vw;
  }

  .choose-wrapper > .choose-body-default {
    padding: 4.5833vw 0;
  }

  .choose-wrapper > .choose-body-default button:disabled::after {
    background-size: 2vw 2vw;
    width: 2vw;
    height: 2vw;
    left: 0.6667vw;
    top: 0.8333vw;
  }

  .choose-wrapper > .choose-body-default > p {
    font-size: 1.8333vw;
    line-height: 2.6667vw;
    margin-bottom: 1.5833vw;
  }

  .choose-wrapper > .choose-body-default > .divider {
    width: 5vw;
    height: 0.0833vw;
    margin-bottom: 3.75vw;
  }

  .choose-wrapper > .choose-body-default > .button-container {
    width: 80.8333vw;
  }

  .choose-wrapper > .choose-body-default > .button-container > p {
    font-size: 1.5vw;
    line-height: 2vw;
    margin-bottom: 1.6667vw;
  }

  .choose-wrapper > .choose-body-default > .button-container > .divider {
    height: 0.0833vw;
    margin-bottom: 1.6667vw;
  }

  .choose-wrapper > .choose-body-default button {
    width: 14.5vw;
    height: 21.3333vw;
    margin: 0 2.0833vw 2.0833vw 0;
    border: 0.0833vw solid var(--oliver-ui-bubble-grey);
    border-radius: 0.5651vw;
    padding: 2.19vw 0 1.5833vw 0;
    filter: drop-shadow(0vw 0.3532vw 0.7064vw rgba(0, 0, 0, 0.1));
  }

  .choose-wrapper > .choose-body-default button > .img-container {
    width: 6.8608vw;
    height: 6.8608vw;
  }

  .choose-wrapper
    > .choose-body-default
    button
    > .img-container
    > img.oliver-icon {
    width: 3vw;
    height: 3vw;
    transform: translateX(0.0833vw);
  }

  .choose-wrapper
    > .choose-body-default
    button
    > .img-container
    > img.www-icon {
    width: 4.3333vw;
    height: 4.3333vw;
  }

  .choose-wrapper
    > .choose-body-default
    button
    > .img-container
    > img.store-icon {
    width: 3.3333vw;
    height: 3.0833vw;
  }

  .choose-wrapper
    > .choose-body-default
    button
    > .img-container
    > img.register-icon {
    width: 3.5833vw;
    height: 3.5833vw;
  }

  .choose-wrapper
    > .choose-body-default
    button
    > .img-container
    > img.kiosk-icon {
    width: 4.6667vw;
    height: 4.6667vw;
  }

  .choose-wrapper > .choose-body-default button > .col > p.style1 {
    font-size: 1.25vw;
    line-height: 1.8333vw;
  }

  .choose-wrapper > .choose-body-default button > .col > p.style2 {
    font-size: 1vw;
    line-height: 1.4167vw;
  }

  .choose-wrapper > .choose-body-default button > .fake-button {
    width: 10.515vw;
    height: 4vw;
    font-size: 1.1667vw;
    line-height: 2vw;
    border-radius: 0.3333vw;
  }

  .choose-wrapper > .choose-body-default button.assigned > .fake-button {
    border: 0.0833vw solid var(--oliver-blue);
  }

  .choose-wrapper > .choose-body-default button#addNew {
    border: 0.1667vw dashed var(--oliver-border);
    font-size: 1.1667vw;
    line-height: 1.25vw;
  }

  .choose-wrapper > .choose-body-default button#addNew > img.landscape {
    width: 1.6667vw;
    height: 1.6667vw;
    margin-right: 0.5vw;
  }

  .choose-wrapper > .choose-body-default .button-group {
    padding-bottom: 2.0833vw;
  }

  .subwindow-wrapper > .subwindow {
    border-radius: 0.6667vw;
    box-shadow: 0vw 1.25vw 3.3333vw rgba(0, 0, 0, 0.3);
  }

  .subwindow-wrapper > .subwindow > .subwindow-header {
    min-height: 5.4167vw;
    border-bottom: 0.0833vw solid var(--oliver-border);
  }

  .subwindow-wrapper > .subwindow > .subwindow-header > p {
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow-wrapper > .subwindow > .subwindow-header > button.close-subwindow {
    top: 1.3333vw;
    right: 1.3333vw;
    width: 2.6667vw;
    height: 2.6667vw;
    border-radius: 0.5vw;
  }

  .subwindow-wrapper
    > .subwindow
    > .subwindow-header
    > button.close-subwindow
    > img {
    width: 1vw;
    height: 1vw;
  }

  .subwindow-wrapper > .subwindow > .subwindow-body {
    border-radius: 0 0 0.6667vw 0.6667vw;
    padding: 1.6667vw 0;
  }

  .subwindow.update-plugin {
    width: min(42vw, 95%);
    height: min(33.3333vw, 95%);
  }

  .subwindow.update-plugin > .subwindow-body > img {
    width: 5.1667vw;
    height: 5.1667vw;
    margin: auto 0 4.1667vw 0;
  }

  .subwindow.update-plugin > .subwindow-body > p.style1 {
    font-size: 1.5vw;
    line-height: 2vw;
    margin-bottom: 1.25vw;
  }

  .subwindow.update-plugin > .subwindow-body > p.style2 {
    font-size: 1.3333vw;
    line-height: 1.75vw;
    margin-bottom: 4.1667vw;
  }

  .subwindow.update-plugin > .subwindow-body > button {
    width: 16.5vw;
    height: 4.1667vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow.cart-no-item {
    width: min(42vw, 95%);
    height: min(25vw, 95%);
  }

  .subwindow.cart-no-item > .subwindow-body > p.style1 {
    font-size: 1.5vw;
    line-height: 2vw;
    margin: auto 0 1.25vw 0;
  }

  .subwindow.cart-no-item > .subwindow-body > p.style2 {
    font-size: 1.3333vw;
    line-height: 1.75vw;
    margin-bottom: 4.1667vw;
  }

  .subwindow.cart-no-item > .subwindow-body > button {
    width: 16.5vw;
    height: 4.1667vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow.discount-error {
    width: min(95%, 42vw);
    height: min(95%, 33.3333vw);
  }

  .subwindow.discount-error > .subwindow-body > p.style1 {
    font-size: 1.5vw;
    line-height: 2vw;
    margin-bottom: 1.25vw;
    margin: auto 0 1.25vw 0;
  }

  .subwindow.discount-error > .subwindow-body > p.style2 {
    font-size: 1.3333vw;
    line-height: 1.75vw;
    margin-bottom: 1.25vw;
  }

  .subwindow.discount-error > .subwindow-body > p:last-of-type {
    margin-bottom: 4.1667vw;
  }

  .subwindow.discount-error > .subwindow-body > button {
    width: 16.5vw;
    height: 4.1667vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
    margin-bottom: auto;
  }

  .subwindow.product-unsupported {
    width: 42vw;
    height: 33.3333vw;
  }

  .subwindow.product-unsupported > .subwindow-body > p.style1 {
    margin: auto 0 2.75vw 0;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow.product-unsupported > .subwindow-body > img {
    width: 4.1667vw;
    height: 4.3333vw;
    margin-bottom: 2.75vw;
  }

  .subwindow.product-unsupported > .subwindow-body > p.style2 {
    font-size: 1.3333vw;
    line-height: 1.75vw;
    margin-bottom: 2.75vw;
  }

  .subwindow.product-unsupported > .subwindow-body > button {
    width: 16.5vw;
    height: 4.1667vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow.refund-show-payments {
    width: min(42vw, 95%);
  }

  .subwindow.refund-show-payments > .subwindow-body {
    max-height: calc(95vh - 5.4167vw);
    padding: 1.6667vw 3vw;
  }

  .subwindow.refund-show-payments > .subwindow-body > .row {
    margin-top: 0.9167vw;
  }

  .subwindow.refund-show-payments > .subwindow-body > .row > p {
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow.refund-show-payments > .subwindow-body > button {
    height: 4.1667vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
    margin-top: 2.75vw;
  }

  .subwindow.no-permissions {
    width: min(42vw, 95%);
    height: min(33.3333vw, 95%);
  }

  .subwindow.no-permissions > .subwindow-body > img {
    width: 4.1667vw;
    height: 4.1667vw;
    margin: auto 0 2.0833vw 0;
  }

  .subwindow.no-permissions > .subwindow-body > p.style1 {
    font-size: 1.5vw;
    line-height: 2vw;
    margin-bottom: 3vw;
  }

  .subwindow.no-permissions > .subwindow-body > p.style2 {
    font-size: 1.3333vw;
    line-height: 1.75vw;
    margin-bottom: 3.6667vw;
  }

  .subwindow.no-permissions > .subwindow-body > button {
    width: 16.5vw;
    height: 4.1667vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow-wrapper > .subwindow.no-account {
    width: min(66.6667vw, 95%);
    height: min(54.1667vw, 95%);
  }

  .subwindow-wrapper > .subwindow.no-account > .subwindow-body > .content {
    padding: 4.1667vw 0 2.5vw 0;
  }

  .subwindow-wrapper
    > .subwindow.no-account
    > .subwindow-body
    > .content
    > img {
    width: 3.75vw;
    height: 3.75vw;
    margin-bottom: 3.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.no-account
    > .subwindow-body
    > .content
    > p.style1 {
    font-size: 1.3333vw;
    line-height: 1.5833vw;
    margin-bottom: 3.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.no-account
    > .subwindow-body
    > .content
    > p.style2 {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 3.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.no-account
    > .subwindow-body
    > .content
    > button#createAccount {
    width: 22.0833vw;
    height: 4.1667vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow-wrapper
    > .subwindow.no-account
    > .subwindow-body
    > .content
    > button#loginTryAgain {
    width: 14.4167vw;
    height: 4.1667vw;
    border: 0.0833vw solid var(--oliver-blue);
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
    margin: 3.3333vw 0;
  }

  .subwindow-wrapper
    > .subwindow.no-account
    > .subwindow-body
    > .content
    > .row {
    font-size: 1.3333vw;
    line-height: 1.75vw;
  }

  .subwindow-wrapper
    > .subwindow.no-account
    > .subwindow-body
    > .content
    > .row
    > a {
    margin-left: 0.3333vw;
  }

  .subwindow-wrapper > .subwindow.takeover-register {
    width: min(42vw, 95%);
    height: min(35vw, 95%);
  }

  .subwindow-wrapper > .subwindow.takeover-register > .subwindow-body > p {
    font-size: 1.5vw;
    line-height: 2.1667vw;
    margin-bottom: 5vw;
  }

  .subwindow-wrapper > .subwindow.takeover-register > .subwindow-body > button {
    width: 14.1667vw;
    height: 4vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow-wrapper
    > .subwindow.takeover-register
    > .subwindow-body
    > button#takeoverRegister {
    margin-bottom: 1.4167vw;
  }

  .subwindow-wrapper > .subwindow.register-taken {
    width: 40.8333vw;
    height: min(95%, 28.25vw);
  }

  .subwindow-wrapper > .subwindow.register-taken > .subwindow-body > img {
    width: 4.1667vw;
    height: 4.3333vw;
    margin-bottom: 2.5vw;
  }

  .subwindow-wrapper > .subwindow.register-taken > .subwindow-body > p {
    font-size: 1.3333vw;
    line-height: 1.9167vw;
  }

  .subwindow.knowledge-base {
    width: min(83.3333vw, 95%);
    height: min(66.1667vw, 95%);
  }

  .subwindow.knowledge-base > .subwindow-header {
    padding: 0 5.5vw 0 1.5833vw;
  }

  .subwindow.knowledge-base > .subwindow-header > .icon-container {
    width: 3.3333vw;
    height: 3.3333vw;
    border-radius: 0.6667vw;
    margin-right: 1.4167vw;
  }

  .subwindow.knowledge-base > .subwindow-header > p {
    font-size: 1.3333vw;
    line-height: 2vw;
  }

  .subwindow.knowledge-base > .subwindow-header > button#kBRefresh {
    width: 10.6667vw;
    height: 2.6667vw;
    border-radius: 0.5vw;
    border: 0.0833vw solid var(--oliver-base-grey);
    font-size: 1.0833vw;
    line-height: 1.4167vw;
  }

  .subwindow.knowledge-base > .subwindow-header > button#kBRefresh > img {
    width: 1.0833vw;
    height: 1.0833vw;
    margin-right: 0.5vw;
  }

  .subwindow-wrapper > .subwindow.link-launcher-subwindow {
    width: 83.3333vw;
    height: min(95%, 66.1667vw);
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-header
    > img {
    margin: 0 1.1667vw 0 1.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header {
    height: 6.1667vw;
    border-bottom: 0.0833vw solid var(--oliver-border);
    padding: 0 1.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > .link-wrapper
    > img {
    width: 3.3333vw;
    height: 3.3333vw;
    border-radius: 0.5vw;
    margin-right: 1.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > .link-wrapper
    > .text-col
    > p.style1 {
    font-size: 1.3333vw;
    line-height: 2vw;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > .link-wrapper
    > .text-col
    > p.style2 {
    font-size: 1vw;
    line-height: 1.4167vw;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > button {
    width: 10.6667vw;
    height: 2.6667vw;
    border: 0.0833vw solid var(--oliver-base-grey);
    border-radius: 0.5vw;
    font-size: 1.0833vw;
    line-height: 1.4167vw;
  }

  .subwindow-wrapper
    > .subwindow.link-launcher-subwindow
    > .subwindow-body
    > .link-header
    > button
    > img {
    width: 1.0833vw;
    height: 1.0833vw;
    margin-right: 0.5vw;
  }

  .subwindow-wrapper > .subwindow.app-launcher-subwindow {
    width: 66.6667vw;
    height: calc(100% - 3.3333vw);
  }

  .subwindow-wrapper > .subwindow.app-launcher-subwindow.small {
    width: 50vw;
  }

  .subwindow-wrapper > .subwindow.app-launcher-subwindow.large {
    width: calc(100% - 3.3333vw);
  }

  .subwindow-wrapper > .subwindow.app-launcher-subwindow > .subwindow-header {
    padding: 1.3333vw 4vw 1.3333vw 1.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.app-launcher-subwindow
    > .subwindow-header
    > .app-wrapper
    > img {
    width: 4.1667vw;
    height: 4.1667vw;
    border-radius: 0.8333vw;
    margin-right: 1.5833vw;
  }

  .subwindow-wrapper
    > .subwindow.app-launcher-subwindow
    > .subwindow-header
    > .app-wrapper
    > .text-col
    > p.style1 {
    font-size: 1.5vw;
    line-height: 2.0833vw;
  }

  .subwindow-wrapper
    > .subwindow.app-launcher-subwindow
    > .subwindow-header
    > .app-wrapper
    > .text-col
    > p.style2 {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .subwindow.create-customer {
    width: 62vw;
    height: min(95%, 90.8333vw);
  }

  .subwindow.create-customer > .subwindow-body {
    padding: 1.6667vw 5.6667vw;
  }

  .subwindow.create-customer > .subwindow-body > div:not(.button-row) {
    border-top: 0.0833vw solid var(--oliver-border);
    padding-top: 1.25vw;
    margin-top: 1.3333vw;
  }

  .subwindow.create-customer > .subwindow-body > div > p {
    font-size: 1.25vw;
    line-height: 1.9167vw;
  }

  .subwindow.create-customer > .subwindow-body > div > .input-row {
    margin-top: 1.3333vw;
  }

  .subwindow.create-customer > .subwindow-body > div > .input-row > .input-col {
    margin-left: 1.1667vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > label {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    padding-bottom: 0.3333vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input {
    height: 3.5vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.5vw;
    padding: 0 1vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input:focus {
    border-width: 0.1667vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input.error
    ~ .error-wrapper::after {
    font-size: 1.0833vw;
    line-height: 1.5833vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper {
    height: 3.5vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open {
    box-shadow: 0vw 0.8333vw 1.6667vw rgba(119, 135, 147, 0.1);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > input {
    padding-right: 3.3333vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > input:focus {
    border-width: 0.0833vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open
    > input {
    border-radius: 0.5vw 0.5vw 0 0;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > img {
    width: 1.6667vw;
    height: 1.6667vw;
    right: 1vw;
    top: calc(50% - 0.8333vw);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open
    > img {
    transform: rotate(180deg) translateY(-0.1667vw);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container {
    border: 0.0833vw solid var(--oliver-blue);
    border-radius: 0 0 0.5vw 0.5vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option {
    height: 3.5vw;
    padding: 0 1vw;
    border-radius: 0 0 0.5vw 0.5vw;
    border-top: 0.0833vw solid var(--oliver-blue);
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option
    > p {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .subwindow.create-customer > .subwindow-body > div > .title-row > p {
    font-size: 1.25vw;
    line-height: 1.9167vw;
  }

  .subwindow.create-customer > .subwindow-body > div > .title-row > label {
    font-size: 1.3333vw;
    line-height: 2vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > input {
    left: -833.3333vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > .custom-checkbox {
    width: 1.5vw;
    height: 1.5vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.3333vw;
    margin-right: 1vw;
  }

  .subwindow.create-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > .custom-checkbox
    > img {
    width: 0.8333vw;
    height: 0.8333vw;
  }

  .subwindow.create-customer > .subwindow-body > .button-row {
    margin-top: 2.5vw;
  }

  .subwindow.create-customer > .subwindow-body > .button-row > button {
    margin-left: 1.1667vw;
    height: 3.6667vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow.edit-customer {
    width: 62vw;
    height: min(95%, 90.8333vw);
  }

  .subwindow.edit-customer > .subwindow-body {
    padding: 1.6667vw 5.6667vw;
  }

  .subwindow.edit-customer > .subwindow-body > div:not(.button-row) {
    border-top: 0.0833vw solid var(--oliver-border);
    padding-top: 1.25vw;
    margin-top: 1.3333vw;
  }

  .subwindow.edit-customer > .subwindow-body > div > p {
    font-size: 1.25vw;
    line-height: 1.9167vw;
  }

  .subwindow.edit-customer > .subwindow-body > div > .input-row {
    margin-top: 1.3333vw;
  }

  .subwindow.edit-customer > .subwindow-body > div > .input-row > .input-col {
    margin-left: 1.1667vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > label {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    padding-bottom: 0.3333vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input {
    height: 3.5vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.5vw;
    padding: 0 1vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input:focus {
    border-width: 0.1667vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    input.error
    ~ .error-wrapper::after {
    font-size: 1.0833vw;
    line-height: 1.5833vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper {
    height: 3.5vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open {
    box-shadow: 0vw 0.8333vw 1.6667vw rgba(119, 135, 147, 0.1);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > input {
    padding-right: 3.3333vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > input:focus {
    border-width: 0.0833vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open
    > input {
    border-radius: 0.5vw 0.5vw 0 0;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > img {
    width: 1.6667vw;
    height: 1.6667vw;
    right: 1vw;
    top: calc(50% - 0.8333vw);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper.open
    > img {
    transform: rotate(180deg) translateY(-0.1667vw);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container {
    border: 0.0833vw solid var(--oliver-blue);
    border-radius: 0 0 0.5vw 0.5vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option {
    height: 3.5vw;
    padding: 0 1vw;
    border-radius: 0 0 0.5vw 0.5vw;
    border-top: 0.0833vw solid var(--oliver-blue);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .input-row
    > .input-col
    > .dropdown-wrapper
    > .option-container
    > .option
    > p {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .subwindow.edit-customer > .subwindow-body > div > .title-row > p {
    font-size: 1.25vw;
    line-height: 1.9167vw;
  }

  .subwindow.edit-customer > .subwindow-body > div > .title-row > label {
    font-size: 1.3333vw;
    line-height: 2vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > input {
    left: -833.3333vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > .custom-checkbox {
    width: 1.5vw;
    height: 1.5vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.3333vw;
    margin-right: 1vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > div
    > .title-row
    > label
    > .custom-checkbox
    > img {
    width: 0.8333vw;
    height: 0.8333vw;
  }

  .subwindow.edit-customer > .subwindow-body > .button-row {
    margin-top: 2.5vw;
  }

  .subwindow.edit-customer > .subwindow-body > .button-row > button {
    margin-left: 1.1667vw;
    height: 3.6667vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow.edit-customer
    > .subwindow-body
    > .button-row
    > button#editCustomerDelete {
    border: 0.0833vw solid var(--error);
  }

  .subwindow.edit-customer
    > .subwindow-body
    > .button-row
    > button#editCustomerDelete
    > img {
    width: 1.6667vw;
    height: 1.75vw;
    margin-right: 0.8333vw;
  }

  .subwindow.delete-customer-confirm {
    width: min(42vw, 95%);
    height: min(24.5833vw, 95%);
  }

  .subwindow.delete-customer-confirm > .subwindow-body > p {
    font-size: 1.3333vw;
    line-height: 1.75vw;
    margin-bottom: 2.1667vw;
  }

  .subwindow.delete-customer-confirm > .subwindow-body > button {
    width: 14.1667vw;
    height: 4vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow.delete-customer-confirm > .subwindow-body > button#deleteCustomer {
    margin-bottom: 1.4167vw;
  }

  .subwindow.delete-customer-confirm
    > .subwindow-body
    > button#cancelDeleteCustomer {
    border: 0.0833vw solid var(--oliver-blue);
  }

  .subwindow.custom-fee {
    width: 42vw;
    height: min(45.3333vw, 95%);
  }

  .subwindow.cart-discount {
    width: 42vw;
    height: 63.1667vw;
  }

  .subwindow.custom-fee > .subwindow-body > p,
  .subwindow.cart-discount > .subwindow-body > p {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 0.75vw;
  }

  .subwindow.custom-fee > .subwindow-body > .toggle-container,
  .subwindow.cart-discount > .subwindow-body > .toggle-container {
    width: 23.75vw;
    height: 4.1667vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.6667vw;
    padding: 0.5vw;
    margin-bottom: 2vw;
  }

  .subwindow.custom-fee > .subwindow-body > .toggle-container > label > input,
  .subwindow.cart-discount
    > .subwindow-body
    > .toggle-container
    > label
    > input {
    left: -833.3333vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > .toggle-container
    > label
    > .custom-radio,
  .subwindow.cart-discount
    > .subwindow-body
    > .toggle-container
    > label
    > .custom-radio {
    border-radius: 0.5vw;
    font-size: 1.3333vw;
    line-height: 1.75vw;
  }

  .subwindow.custom-fee > .subwindow-body > #customFeeDiv {
    width: 29vw;
  }

  .subwindow.custom-fee > .subwindow-body > #customFeeDiv > label {
    padding-bottom: 0.6667vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > input#customFeeLabel {
    height: 3.6667vw;
    margin-bottom: 1.1667vw;
    padding: 0 1vw;
    border-radius: 0.5vw;
    border: 0.0833vw solid var(--oliver-border);
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > input#customFeeLabel:focus {
    border-width: 0.1667vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > input#customFeeAmount {
    height: 6.9167vw;
    border-radius: 0.5vw;
    border: 0.0833vw solid var(--oliver-border);
    padding: 0 1vw;
    font-size: 3.3333vw;
    line-height: 4.6667vw;
    margin-bottom: 0.6667vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > input#customFeeAmount:focus {
    border-width: 0.1667vw;
  }

  .subwindow.custom-fee > .subwindow-body > #customFeeDiv > p {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 1.6667vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > label.custom-toggle-wrapper {
    font-size: 1.3333vw;
    line-height: 1.8333vw;
    padding-bottom: 2.5vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > label.custom-toggle-wrapper
    > input {
    left: -833.3333vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > label.custom-toggle-wrapper
    > .custom-toggle {
    width: 3.3333vw;
    height: 2.0833vw;
    border-radius: 2.0833vw;
    margin-left: 1.6667vw;
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > label.custom-toggle-wrapper
    > .custom-toggle::after {
    top: calc(50% - 0.9167vw);
    left: 0.125vw;
    width: 1.8333vw;
    height: 1.8333vw;
    box-shadow: 0vw 0.1667vw 0.5vw rgba(0, 0, 0, 0.15),
      0vw 0.1667vw 0.0833vw rgba(0, 0, 0, 0.06);
  }

  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > label.custom-toggle-wrapper
    > input:checked
    ~ .custom-toggle::after {
    right: 0.125vw;
  }

  .subwindow.custom-fee > .subwindow-body > #customFeeDiv > button {
    height: 4.1667vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow.cart-discount > .subwindow-body > #cartDiscountDiv > .main {
    padding: 1.8333vw 0 2.5vw 0;
    border-top: 0.0833vw solid var(--oliver-border);
    border-bottom: 0.0833vw solid var(--oliver-border);
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > label {
    width: 29vw;
    padding-bottom: 0.75vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > input {
    width: 29vw;
    height: 6.9167vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.5vw;
    padding: 0 1vw;
    font-size: 3.3333vw;
    line-height: 4.6667vw;
    margin-bottom: 1.9167vw;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > input:focus {
    border-width: 0.1667vw;
  }

  .subwindow.cart-discount > .subwindow-body > #cartDiscountDiv > .main > p {
    width: 29vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 0.8333vw;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > .button-row {
    width: 29vw;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .main
    > .button-row
    > button {
    height: 3.6667vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
    margin-left: 1.3333vw;
  }

  .subwindow.cart-discount > .subwindow-body > #cartDiscountDiv > .list {
    padding-top: 2.0833vw;
  }

  .subwindow.cart-discount > .subwindow-body > #cartDiscountDiv > .list > p {
    font-size: 1.1667vw;
    line-height: 1.5vw;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .list
    > button {
    width: 23.75vw;
    height: 3.6667vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.5vw;
    margin-top: 1.25vw;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .list
    > button
    > p {
    font-size: 1.3333vw;
    line-height: 1.75vw;
  }

  .subwindow.cart-discount
    > .subwindow-body
    > #cartDiscountDiv
    > .list
    > button#clearCartDiscountDiv
    > p {
    font-size: 1.1667vw;
    line-height: 1.5vw;
  }

  .subwindow-wrapper > .subwindow.add-tile {
    width: 42vw;
    height: min(35vw, 95%);
  }

  .subwindow-wrapper > .subwindow.add-tile > .subwindow-body > label {
    width: 36vw;
    font-size: 1.1667vw;
    line-height: 1.5vw;
    margin-bottom: 0.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search {
    width: 36vw;
    height: 3.6667vw;
    margin-bottom: 2.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > img.search {
    width: 2vw;
    height: 2vw;
    left: 0.8333vw;
    top: 0.8333vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > button#cancelDropdownSearch {
    width: 1.5vw;
    height: 1.5vw;
    right: 1.25vw;
    top: 1.2083vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > input {
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.5vw;
    padding: 0 3.1667vw 0 3.3333vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search.open
    > input {
    border-radius: 0.5vw 0.5vw 0 0;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .error-message {
    top: -0.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .error-message::after {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .option-container {
    border-radius: 0 0 0.5vw 0.5vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .option-container
    > .dropdown-option {
    height: 3.6667vw;
    padding: 0 1vw;
    border: 0.0833vw solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .option-container
    > .dropdown-option
    > p {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > .dropdown-option:last-child {
    border-radius: 0 0 0.5vw 0.5vw;
  }

  .subwindow-wrapper > .subwindow.add-tile > .subwindow-body > p {
    width: 36vw;
    font-size: 1.1667vw;
    line-height: 1.5vw;
    margin-bottom: 0.8333vw;
  }

  .subwindow-wrapper > .subwindow.add-tile > .subwindow-body > .radio-group {
    margin-bottom: 3.25vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .radio-group
    > label {
    margin-right: 1.25vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .radio-group
    > label
    > input[type="radio"] {
    left: -833.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .radio-group
    > label
    > .custom-radio-button {
    width: 3.3333vw;
    height: 3.3333vw;
    border-radius: 0.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .radio-group
    > label
    > input[type="radio"]:checked
    ~ .custom-radio-button {
    border: 0.1667vw solid #000 !important;
  }

  .subwindow-wrapper > .subwindow.add-tile > .subwindow-body > button {
    width: 36vw;
    height: 3.6667vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow-wrapper > .subwindow.add-order-note {
    width: 42vw;
    height: min(35vw, 95%);
  }

  .subwindow-wrapper > .subwindow.add-order-note > .subwindow-body > label {
    width: 35.3333vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 0.75vw;
  }

  .subwindow-wrapper > .subwindow.add-order-note > .subwindow-body > textarea {
    width: 35.3333vw;
    height: 15.75vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.5vw;
    padding: 1vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 1.6667vw;
  }

  .subwindow-wrapper > .subwindow.add-order-note > .subwindow-body > button {
    width: 35.3333vw;
    height: 4vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow-wrapper > .subwindow.product-not-found {
    width: 42vw;
    height: min(33.3333vw, 95%);
  }

  .subwindow-wrapper > .subwindow.product-not-found > .subwindow-body {
    border-radius: 0.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.product-not-found
    > .subwindow-body
    > p.style1 {
    font-size: 1.5vw;
    line-height: 2vw;
    margin-bottom: 2vw;
  }

  .subwindow-wrapper > .subwindow.product-not-found > .subwindow-body > img {
    width: 6.75vw;
    height: 4.3333vw;
    margin-bottom: 3.3333vw;
    transform: translateX(0.8333vw);
  }

  .subwindow-wrapper
    > .subwindow.product-not-found
    > .subwindow-body
    > p.style2 {
    font-size: 1.3333vw;
    line-height: 1.75vw;
    margin-bottom: 3vw;
  }

  .subwindow-wrapper > .subwindow.product-not-found > .subwindow-body > button {
    width: 16.5vw;
    height: 4.1667vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow-wrapper > .subwindow.upgrade-to-unlock {
    width: 42vw;
    height: min(33.3333vw, 95%);
  }

  .subwindow-wrapper > .subwindow.upgrade-to-unlock > .subwindow-body {
    border-radius: 0.6667vw;
  }

  .subwindow-wrapper > .subwindow.upgrade-to-unlock > .subwindow-body > img {
    width: 4.1667vw;
    height: 4.1667vw;
    margin-bottom: 2.0833vw;
  }

  .subwindow-wrapper
    > .subwindow.upgrade-to-unlock
    > .subwindow-body
    > p.style1 {
    font-size: 1.5vw;
    line-height: 2vw;
    margin-bottom: 3vw;
  }

  .subwindow-wrapper
    > .subwindow.upgrade-to-unlock
    > .subwindow-body
    > p.style2 {
    font-size: 1.3333vw;
    line-height: 1.75vw;
    margin-bottom: 3.6667vw;
  }

  .subwindow-wrapper > .subwindow.upgrade-to-unlock > .subwindow-body > button {
    width: 16.5vw;
    height: 4.1667vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow-wrapper > .subwindow.advanced-search {
    width: 70vw;
  }

  .subwindow-wrapper > .subwindow.advanced-search > .subwindow-header {
    height: 10.5833vw;
    border-radius: 0.6667vw 0.6667vw 0 0;
  }

  .subwindow-wrapper > .subwindow.advanced-search > .subwindow-header > p {
    font-size: 2.0833vw;
    line-height: 2.75vw;
    margin-bottom: 1.8333vw;
  }

  .subwindow-wrapper > .subwindow.advanced-search > .subwindow-header > button {
    width: 2.6667vw;
    height: 2.6667vw;
    top: 1.3333vw;
    right: 1.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper {
    width: 63.3333vw;
    height: 4.5833vw;
    top: 8.25vw;
    left: 3.3333vw;
    filter: drop-shadow(0vw 1.25vw 2.5vw rgba(0, 0, 0, 0.1));
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > #advSearchInputIcon {
    width: 2.5vw;
    height: 2.4167vw;
    top: 1.0833vw;
    left: 0.9167vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > #advSearchInputCancel {
    width: 2.5vw;
    height: 2.5vw;
    right: 0.9167vw;
    top: 1.0833vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > #advSearchInputCancel
    > img {
    width: 1.4583vw;
    height: 1.4583vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-header
    > .input-wrapper
    > input {
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.5vw;
    padding: 0 4.3333vw 0 4.3333vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col {
    width: 21.6667vw;
    padding-top: 6.1667vw;
    border-right: 0.0833vw solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > p {
    width: 16.3333vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 1.25vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > p:last-of-type {
    margin-bottom: 0.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group {
    width: 16.3333vw;
    margin-bottom: 2.75vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > label {
    margin-bottom: 1vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > label
    > input[type="radio"] {
    left: -833.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > label
    > .custom-radio {
    width: 1.5vw;
    height: 1.5vw;
    border: 0.0833vw solid var(--oliver-border);
    margin-right: 0.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > label
    > .custom-radio
    > img {
    width: 0.6667vw;
    height: 0.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .radio-group
    > label
    > p {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .recent-searches {
    width: 16.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .left-col
    > .recent-searches
    > a {
    font-size: 1.0833vw;
    line-height: 1.5vw;
    margin-bottom: 0.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col {
    padding-top: 4.5vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .start-searching {
    padding-bottom: 4.5vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .start-searching
    > img {
    width: 3.5833vw;
    height: 3.5vw;
    margin: auto 0 2.5vw 0;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .start-searching
    > p.style1 {
    font-size: 1.5vw;
    line-height: 2.0833vw;
    margin-bottom: 1.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .start-searching
    > p.style2 {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .header {
    border-bottom: 0.0833vw solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .header
    > p {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin: 0 0 1.1667vw 1.8333vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body {
    padding: 1.8333vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results
    > p.style1 {
    font-size: 1.5vw;
    line-height: 2.0833vw;
    margin-bottom: 0.4167vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results
    > p.style2 {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results
    > .divider {
    width: 8.3333vw;
    height: 0.0833vw;
    margin: 2.5vw 0;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results
    > button {
    width: 18.75vw;
    height: 3.3333vw;
    border: 0.0833vw solid var(--oliver-blue);
    border-radius: 0.5vw;
    font-size: 1.1667vw;
    line-height: 1.5vw;
    margin-top: 0.8333vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .no-results
    > button
    > img {
    width: 1.6667vw;
    height: 1.6667vw;
    margin-right: 1vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result {
    height: 12.3333vw;
    border-radius: 0.5vw;
    padding: 1.5vw 1.6667vw;
    margin-top: 1.1667vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .col
    > p.style1 {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 1.0833vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .col
    > p.style2 {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 0.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .col
    > p.style3 {
    font-size: 1vw;
    line-height: 1.4167vw;
    margin-bottom: 0.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button {
    width: 5.8333vw;
    height: 5.4167vw;
    border-radius: 0.5vw;
    margin-right: 0.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-view {
    font-size: 0.8333vw;
    line-height: 1.1667vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-view
    > img {
    width: 1.6667vw;
    height: 1.6667vw;
    margin-bottom: 0.25vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-transactions {
    font-size: 0.6667vw;
    line-height: 0.9167vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-transactions
    > img {
    width: 2vw;
    height: 2vw;
    margin-bottom: 0.25vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-add-to-sale {
    font-size: 0.6667vw;
    line-height: 0.9167vw;
  }

  .subwindow-wrapper
    > .subwindow.advanced-search
    > .subwindow-body
    > .right-col
    > .body
    > .search-result
    > .row
    > button.search-add-to-sale
    > img {
    width: 2vw;
    height: 2vw;
    margin-bottom: 0.25vw;
  }

  .subwindow-wrapper > .subwindow.switch-user {
    width: 42vw;
    height: min(65vw, 95%);
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > .group {
    margin-bottom: 1.4167vw;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > .group > img {
    width: 1.5833vw;
    height: 1.6667vw;
    margin-right: 0.9167vw;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > .group > p {
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > p.style1 {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 2vw;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > .divider {
    width: 5.4167vw;
    height: 0.0833vw;
    margin-bottom: 2vw;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > p.style2 {
    font-size: 1.6667vw;
    line-height: 2.4167vw;
    margin-bottom: 2vw;
  }

  .subwindow-wrapper > .subwindow.switch-user > .subwindow-body > .pinpad {
    width: 23.1667vw;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > p.error-message {
    padding: 0.9167vw 2.3333vw;
    border-radius: 0.6667vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin: -0.8333vw 0 1.6667vw 0;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-entries {
    margin-bottom: 1.4167vw;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-entries
    > .pin-entry {
    width: 1.3333vw;
    height: 1.3333vw;
    margin-left: 0.8333vw;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row {
    gap: 1.3333vw;
    margin-bottom: 1.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row
    > button {
    width: 6.8333vw;
    height: 6.8333vw;
    border: 0.0833vw solid #b0bec9;
    border-radius: 0.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row
    > button.backspace
    > img {
    width: 4.1667vw;
    height: 3.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row
    > button
    > p {
    font-size: 3vw;
    line-height: 3vw;
  }

  .subwindow-wrapper
    > .subwindow.switch-user
    > .subwindow-body
    > .pinpad
    > .pin-button-row
    > button
    > .text-row {
    font-size: 0.8333vw;
    line-height: 1.25vw;
    letter-spacing: 0vw;
  }

  .subwindow-wrapper > .subwindow.end-session {
    width: 42vw;
    height: min(33.3333vw, 95%);
  }

  .subwindow-wrapper > .subwindow.end-session > .subwindow-body > p.style1 {
    font-size: 1.5vw;
    line-height: 2vw;
    margin-bottom: 1.4167vw;
  }

  .subwindow-wrapper > .subwindow.end-session > .subwindow-body > p.style2 {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 2.9167vw;
  }

  .subwindow-wrapper > .subwindow.end-session > .subwindow-body > button {
    width: 19.1667vw;
    height: 4.1667vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow-wrapper > .subwindow.partial-payment {
    width: 42vw;
    height: min(44.6667vw, 95%);
  }

  .subwindow-wrapper > .subwindow.partial-payment > .subwindow-body > label {
    width: 29vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 0.75vw;
  }

  .subwindow-wrapper > .subwindow.partial-payment > .subwindow-body > input {
    width: 29vw;
    height: 6.9167vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.5vw;
    font-size: 3.3333vw;
    line-height: 4.6667vw;
    margin-bottom: 2.9167vw;
  }

  .subwindow-wrapper > .subwindow.partial-payment > .subwindow-body > p {
    font-size: 1.3333vw;
    line-height: 1.6667vw;
    margin-bottom: 1vw;
  }

  .subwindow-wrapper
    > .subwindow.partial-payment
    > .subwindow-body
    > .payment-types {
    width: 29vw;
  }

  .subwindow-wrapper
    > .subwindow.partial-payment
    > .subwindow-body
    > .payment-types
    > button {
    width: 8.8333vw;
    height: 8.8333vw;
    margin: 0 1.0833vw 1.0833vw 0;
    border-radius: 0.6667vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow-wrapper > .subwindow.product-discount {
    width: 42vw;
    height: min(35vw, 95%);
  }

  .subwindow-wrapper > .subwindow.product-discount > .subwindow-body > label {
    width: 29vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 0.75vw;
  }

  .subwindow-wrapper > .subwindow.product-discount > .subwindow-body > input {
    width: 29vw;
    height: 6.9167vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.5vw;
    font-size: 3.3333vw;
    line-height: 4.6667vw;
    margin-bottom: 1.9167vw;
  }

  .subwindow-wrapper > .subwindow.product-discount > .subwindow-body > p {
    width: 29vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 0.8333vw;
  }

  .subwindow-wrapper
    > .subwindow.product-discount
    > .subwindow-body
    > .button-row {
    width: 29vw;
  }

  .subwindow-wrapper
    > .subwindow.product-discount
    > .subwindow-body
    > .button-row
    > button {
    height: 3.6667vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow-wrapper
    > .subwindow.product-discount
    > .subwindow-body
    > .button-row
    > button:first-of-type {
    margin-right: 1.3333vw;
  }

  .subwindow-wrapper > .subwindow.adjust-inventory {
    width: 42vw;
    height: min(44.25vw, 95%);
  }

  .subwindow-wrapper > .subwindow.adjust-inventory > .subwindow-body > p {
    font-size: 1.5vw;
    line-height: 2.0833vw;
    margin-bottom: 0.9167vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > p:first-of-type {
    margin-top: 0.9167vw;
  }

  .subwindow-wrapper > .subwindow.adjust-inventory > .subwindow-body > label {
    font-size: 1.3333vw;
    line-height: 1.75vw;
    margin-bottom: 0.5833vw;
  }

  .subwindow-wrapper > .subwindow.adjust-inventory > .subwindow-body > input {
    width: 20.8333vw;
    height: 6.9167vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.5vw;
    font-size: 3.3333vw;
    line-height: 4.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > button#editStockButton {
    padding: 0.5833vw 0.8333vw;
    font-size: 1.3333vw;
    line-height: 1.75vw;
    margin-bottom: 1.4167vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > button#editStockButton
    > img {
    width: 1.5833vw;
    height: 1.5833vw;
    margin-right: 0.4167vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > button#updateStockButton {
    width: 35.5833vw;
    height: 4.1667vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
    margin-bottom: 2.25vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > .text-row {
    width: 35.5833vw;
    margin-top: 1.5833vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > p
    + .text-row {
    margin-top: 1.1667vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > .text-row
    > p {
    font-size: 1.3333vw;
    line-height: 1.8333vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-inventory
    > .subwindow-body
    > .text-row
    > p:first-of-type {
    margin-right: 1.6667vw;
    max-width: 17.75vw;
  }

  .subwindow-wrapper > .subwindow.out-of-stock {
    width: 42vw;
    height: min(29.1667vw, 95%);
  }

  .subwindow-wrapper > .subwindow.out-of-stock > .subwindow-body {
    border-radius: 0.6667vw;
  }

  .subwindow-wrapper > .subwindow.out-of-stock > .subwindow-body > p.style1 {
    font-size: 1.5vw;
    line-height: 2vw;
    margin-bottom: 2vw;
  }

  .subwindow-wrapper > .subwindow.out-of-stock > .subwindow-body > img {
    width: 5.75vw;
    height: 5.75vw;
    margin-bottom: 1.0833vw;
  }

  .subwindow-wrapper > .subwindow.out-of-stock > .subwindow-body > p.style2 {
    font-size: 1.3333vw;
    line-height: 1.75vw;
    margin-bottom: 2.5vw;
  }

  .subwindow-wrapper
    > .subwindow.out-of-stock
    > .subwindow-body
    > p.style2
    > button {
    font-size: 1.3333vw;
    line-height: 1.75vw;
  }

  .subwindow-wrapper > .subwindow.out-of-stock > .subwindow-body > button {
    width: 16.5vw;
    height: 4.1667vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
  }

  .subwindow-wrapper > .subwindow.product-note {
    width: 42vw;
    height: min(35vw, 95%);
  }

  .subwindow-wrapper > .subwindow.product-note > .subwindow-body > label {
    width: 35.3333vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 0.75vw;
  }

  .subwindow-wrapper > .subwindow.product-note > .subwindow-body > textarea {
    width: 35.3333vw;
    height: 15.75vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.5vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 1.6667vw;
  }

  .subwindow-wrapper > .subwindow.product-note > .subwindow-body > button {
    width: 35.3333vw;
    height: 4vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow-wrapper > .subwindow.no-variation-selected {
    width: 42vw;
    height: min(29.1667vw, 95%);
  }

  .subwindow-wrapper > .subwindow.no-variation-selected > .subwindow-body {
    border-radius: 0.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.no-variation-selected
    > .subwindow-body
    > p.style1 {
    font-size: 1.5vw;
    line-height: 2vw;
    margin-bottom: 2vw;
  }

  .subwindow-wrapper
    > .subwindow.no-variation-selected
    > .subwindow-body
    > img {
    width: 5.0833vw;
    height: 5.0833vw;
    margin-bottom: 1.75vw;
  }

  .subwindow-wrapper
    > .subwindow.no-variation-selected
    > .subwindow-body
    > p.style2 {
    font-size: 1.3333vw;
    line-height: 1.75vw;
    margin-bottom: 2.5vw;
  }

  .subwindow-wrapper
    > .subwindow.no-variation-selected
    > .subwindow-body
    > button {
    width: 16.5vw;
    height: 4.1667vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow-wrapper > .subwindow.park-sale {
    width: 42vw;
    height: min(35vw, 95%);
  }

  .subwindow-wrapper > .subwindow.park-sale > .subwindow-body > label {
    width: 35.4167vw;
    font-size: 1.1667vw;
    line-height: 1.75vw;
    padding-bottom: 0.5vw;
  }

  .subwindow-wrapper > .subwindow.park-sale > .subwindow-body > textarea {
    width: 35.4167vw;
    height: 15.0833vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.5vw;
    padding: 1vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 3.1667vw;
  }

  .subwindow-wrapper > .subwindow.park-sale > .subwindow-body > button {
    width: 35.4167vw;
    height: 4.1667vw;
    border-radius: 0.5vw;
    margin-bottom: 0.8333vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow-wrapper > .subwindow.split-by-product {
    width: 42vw;
    height: min(41.8333vw, 95%);
  }

  .subwindow-wrapper > .subwindow.split-by-product > .subwindow-body > .body {
    padding-top: 2.0833vw;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row {
    width: 35.25vw;
    padding: 1.6667vw 0;
    border-top: 0.0833vw solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .main-row
    > .text-group
    > p {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .main-row
    > .increment-input {
    width: 12.3333vw;
    height: 3.6667vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.5vw;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .main-row
    > .increment-input
    > button
    > img {
    width: 1.6667vw;
    height: 1.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .main-row
    > .increment-input
    > input {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .secondary-row {
    margin-top: 1.0833vw;
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .secondary-row
    > p {
    font-size: 1vw;
    line-height: 1.4167vw;
  }

  .subwindow-wrapper > .subwindow.split-by-product > .subwindow-body > .footer {
    height: 7.5vw;
    border-top: 0.0833vw solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .footer
    > button {
    width: 35.4167vw;
    height: 4.1667vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow-wrapper > .subwindow.cash-payment.step-1 {
    width: 42vw;
    height: min(59.0833vw, 95%);
  }

  .subwindow-wrapper > .subwindow.cash-payment.step-2 {
    width: 42vw;
    height: 41.6667vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .text-row {
    width: 26.75vw;
    margin-bottom: 2.1667vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .text-row
    > p.style1 {
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .text-row
    > p.style2 {
    font-size: 1.6667vw;
    line-height: 2.1667vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad {
    padding: 1.3333vw;
    border-radius: 0.6667vw;
    margin-bottom: 1.5vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > .input-container {
    width: 24.0833vw;
    height: 7.75vw;
    border-radius: 0.5vw;
    margin-bottom: 0.75vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > .input-container
    > label {
    font-size: 1vw;
    line-height: 1.4167vw;
    margin-bottom: 0.8333vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > .input-container
    > input {
    width: 16.0833vw;
    height: 3.5vw;
    font-size: 3.3333vw;
    line-height: 3.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > #numpad2
    > .button-row {
    margin-top: 0.75vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > #numpad2
    > .button-row
    > button {
    width: 7.5vw;
    height: 5.6667vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.5vw;
    margin-left: 0.75vw;
    font-size: 2.5vw;
    line-height: 3.75vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > #numpad2
    > .button-row
    > button.backspace
    > img {
    width: 3.3333vw;
    height: 3.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > button {
    width: 26.75vw;
    height: 5vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment.step-2
    > .subwindow-body
    > .step2
    > .text-row {
    width: 30.8333vw;
    padding-top: 1.6667vw;
    margin-top: 1.6667vw;
    border-top: 0.0833vw solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.cash-payment.step-2
    > .subwindow-body
    > .step2
    > .text-row
    > p {
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment.step-2
    > .subwindow-body
    > .step2
    > button {
    width: 30.8333vw;
    height: 5vw;
    border-radius: 0.5vw;
    margin-top: 4.1667vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow-wrapper > .subwindow.quick-tax {
    right: 4.1667vw;
    bottom: 4.1667vw;
    width: 25vw;
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-header {
    height: 4.5833vw;
    padding: 0 0.9167vw 0 1.8333vw;
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-header > P {
    font-size: 1.3333vw;
    line-height: 1.8333vw;
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-header > button {
    padding: 0.9167vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-body {
    padding: 1.8333vw;
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-body > .row {
    margin-top: 0.6667vw;
  }

  .subwindow-wrapper > .subwindow.quick-tax > .subwindow-body > .row > p {
    font-size: 1.3333vw;
    line-height: 1.8333vw;
  }

  .subwindow-wrapper
    > .subwindow.quick-tax
    > .subwindow-body
    > .row
    > label
    > input {
    left: -833.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.quick-tax
    > .subwindow-body
    > .row
    > label
    > .custom-toggle {
    width: 3.3333vw;
    height: 2.0833vw;
    border-radius: 2.0833vw;
  }

  .subwindow-wrapper
    > .subwindow.quick-tax
    > .subwindow-body
    > .row
    > label
    > .custom-toggle
    > .knob {
    width: 1.8333vw;
    height: 1.8333vw;
    top: calc(50% - 0.9167vw);
    left: 0.125vw;
    border: 0.0327vw solid rgba(0, 0, 0, 0.04);
    box-shadow: 0vw 0.1961vw 0.5229vw rgba(0, 0, 0, 0.15),
      0vw 0.1961vw 0.0654vw rgba(0, 0, 0, 0.06);
  }

  .subwindow-wrapper
    > .subwindow.quick-tax
    > .subwindow-body
    > .row
    > label
    > input:checked
    ~ .custom-toggle
    > .knob {
    right: 0.125vw;
  }

  .subwindow-wrapper > .subwindow.detailed-tax {
    width: 62vw;
    height: min(61.25vw, 95%);
  }

  .subwindow-wrapper > .subwindow.detailed-tax > .subwindow-body > .header-row {
    height: 3.75vw;
    padding: 0 1.8333vw;
    border-bottom: 0.0833vw solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .header-row
    > p {
    font-size: 1.1667vw;
    line-height: 1.4167vw;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row {
    height: 5vw;
    padding: 0 1.8333vw;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > p {
    font-size: 1.1667vw;
    line-height: 1.4167vw;
    padding-right: 1.25vw;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > .checkbox-wrapper
    > input {
    left: -833.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > .checkbox-wrapper
    > .custom-checkbox {
    width: 1.5vw;
    height: 1.5vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .options-container
    > .option-row
    > .checkbox-wrapper
    > .custom-checkbox
    > img {
    width: 1vw;
    height: 0.7883vw;
  }

  .subwindow-wrapper > .subwindow.detailed-tax > .subwindow-body > .footer {
    height: 7.0833vw;
    border-top: 0.0833vw solid var(--oliver-border);
  }

  .subwindow-wrapper
    > .subwindow.detailed-tax
    > .subwindow-body
    > .footer
    > button {
    width: 23.3333vw;
    height: 3.6667vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow-wrapper > .subwindow.add-customer-note {
    width: 42vw;
    height: min(35vw, 95%);
  }

  .subwindow-wrapper > .subwindow.add-customer-note > .subwindow-body > label {
    width: 35.3333vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 0.75vw;
  }

  .subwindow-wrapper
    > .subwindow.add-customer-note
    > .subwindow-body
    > textarea {
    width: 35.3333vw;
    height: 15.75vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.5vw;
    padding: 1vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 1.6667vw;
  }

  .subwindow-wrapper > .subwindow.add-customer-note > .subwindow-body > button {
    width: 35.3333vw;
    height: 4vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow-wrapper > .subwindow.add-cash {
    width: 42vw;
    height: min(44.5vw, 95%);
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body > .text-row {
    width: 35.5833vw;
    padding-bottom: 1.75vw;
    border-bottom: 0.0833vw solid var(--oliver-border);
    margin-bottom: 2.5833vw;
  }

  .subwindow-wrapper
    > .subwindow.add-cash
    > .subwindow-body
    > .text-row
    > p.style1 {
    font-size: 1.3333vw;
    line-height: 2vw;
  }

  .subwindow-wrapper
    > .subwindow.add-cash
    > .subwindow-body
    > .text-row
    > p.style2 {
    font-size: 1.6667vw;
    line-height: 2.5vw;
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body > .input-row {
    width: 35.5833vw;
    margin-bottom: 1.4167vw;
  }

  .subwindow-wrapper
    > .subwindow.add-cash
    > .subwindow-body
    > .input-row
    > label {
    font-size: 1.3333vw;
    line-height: 2vw;
  }

  .subwindow-wrapper
    > .subwindow.add-cash
    > .subwindow-body
    > .input-row
    > input {
    width: 20.0833vw;
    height: 4.1667vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.5vw;
    padding: 0 1vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body > label {
    width: 35.5833vw;
    font-size: 1.3333vw;
    line-height: 2vw;
    margin-bottom: 0.5vw;
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body > textarea {
    width: 35.5833vw;
    height: 12vw;
    padding: 1vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.5vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 1.9167vw;
  }

  .subwindow-wrapper > .subwindow.add-cash > .subwindow-body > button {
    width: 35.5833vw;
    height: 4.1667vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow-wrapper > .subwindow.remove-cash {
    width: 42vw;
    height: min(44.5vw, 95%);
  }

  .subwindow-wrapper > .subwindow.remove-cash > .subwindow-body > .text-row {
    width: 35.5833vw;
    padding-bottom: 1.75vw;
    border-bottom: 0.0833vw solid var(--oliver-border);
    margin-bottom: 2.5833vw;
  }

  .subwindow-wrapper
    > .subwindow.remove-cash
    > .subwindow-body
    > .text-row
    > p.style1 {
    font-size: 1.3333vw;
    line-height: 2vw;
  }

  .subwindow-wrapper
    > .subwindow.remove-cash
    > .subwindow-body
    > .text-row
    > p.style2 {
    font-size: 1.6667vw;
    line-height: 2.5vw;
  }

  .subwindow-wrapper > .subwindow.remove-cash > .subwindow-body > .input-row {
    width: 35.5833vw;
    margin-bottom: 1.4167vw;
  }

  .subwindow-wrapper
    > .subwindow.remove-cash
    > .subwindow-body
    > .input-row
    > label {
    font-size: 1.3333vw;
    line-height: 2vw;
  }

  .subwindow-wrapper
    > .subwindow.remove-cash
    > .subwindow-body
    > .input-row
    > input {
    width: 20.0833vw;
    height: 4.1667vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.5vw;
    padding: 0 1vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .subwindow-wrapper > .subwindow.remove-cash > .subwindow-body > label {
    width: 35.5833vw;
    font-size: 1.3333vw;
    line-height: 2vw;
    margin-bottom: 0.5vw;
  }

  .subwindow-wrapper > .subwindow.remove-cash > .subwindow-body > textarea {
    width: 35.5833vw;
    height: 12vw;
    padding: 1vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.5vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 1.9167vw;
  }

  .subwindow-wrapper > .subwindow.remove-cash > .subwindow-body > button {
    width: 35.5833vw;
    height: 4.1667vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow-wrapper > .subwindow.no-customer {
    width: 42vw;
    height: min(35.8333vw, 95%);
  }

  .subwindow-wrapper > .subwindow.no-customer > .subwindow-body {
    border-radius: 0.6667vw;
  }

  .subwindow-wrapper > .subwindow.no-customer > .subwindow-body > p.style1 {
    font-size: 1.5vw;
    line-height: 2vw;
    margin-bottom: 1.9167vw;
  }

  .subwindow-wrapper > .subwindow.no-customer > .subwindow-body > p.style2 {
    font-size: 1.3333vw;
    line-height: 1.75vw;
    margin-bottom: 3.4167vw;
  }

  .subwindow-wrapper > .subwindow.no-customer > .subwindow-body > img {
    width: 4.9167vw;
    height: 4.9167vw;
    margin-bottom: 2vw;
  }

  .subwindow-wrapper > .subwindow.no-customer > .subwindow-body > button {
    width: 22.0833vw;
    height: 4.1667vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow-wrapper
    > .subwindow.no-customer
    > .subwindow-body
    > button:first-of-type {
    border: 0.0833vw solid var(--oliver-blue);
    margin-bottom: 1.4167vw;
  }

  .subwindow-wrapper
    > .subwindow.no-customer
    > .subwindow-body
    > button:first-of-type
    > img {
    width: 1.6667vw;
    height: 1.6667vw;
    margin-right: 1vw;
  }

  .subwindow-wrapper > .subwindow.adjust-credit {
    width: 42vw;
    height: min(52.9167vw, 95%);
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body > p {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 0.75vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .toggle-wrapper {
    width: 26.6667vw;
    height: 4.1667vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.6667vw;
    padding: 0.5vw;
    margin-bottom: 2vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .toggle-wrapper
    > label
    > input {
    left: -833.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .toggle-wrapper
    > label
    > .custom-radio {
    font-size: 1.3333vw;
    line-height: 1.75vw;
    border-radius: 0.5vw;
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body > .text-row {
    width: 35.5833vw;
    padding-top: 1.75vw;
    border-top: 0.0833vw solid var(--oliver-border);
    margin-bottom: 1.5vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .text-row
    > p.style1 {
    font-size: 1.3333vw;
    line-height: 2vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .text-row
    > p.style2 {
    font-size: 1.6667vw;
    line-height: 2.5vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .change-credit-row {
    width: 35.5833vw;
    padding-bottom: 2vw;
    border-bottom: 0.0833vw solid var(--oliver-border);
    margin-bottom: 1.5vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .change-credit-row
    > p {
    font-size: 1.3333vw;
    line-height: 2vw;
  }

  .subwindow-wrapper
    > .subwindow.adjust-credit
    > .subwindow-body
    > .change-credit-row
    > input {
    width: 20.1667vw;
    height: 4.1667vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.5vw;
    padding: 0 1vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body > label {
    width: 35.5833vw;
    font-size: 1.3333vw;
    line-height: 2vw;
    margin-bottom: 0.5vw;
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body > textarea {
    width: 35.5833vw;
    height: 12vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.5vw;
    padding: 1vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 2vw;
  }

  .subwindow-wrapper > .subwindow.adjust-credit > .subwindow-body > button {
    width: 35.5833vw;
    height: 4.1667vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow-wrapper > .subwindow.layaway {
    width: 42vw;
    height: min(35vw, 95%);
  }

  .subwindow-wrapper > .subwindow.layaway > .subwindow-body > label {
    width: 35.3333vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 0.75vw;
  }

  .subwindow-wrapper > .subwindow.layaway > .subwindow-body > textarea {
    width: 35.3333vw;
    height: 15.75vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.5vw;
    padding: 1vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 1.6667vw;
  }

  .subwindow-wrapper > .subwindow.layaway > .subwindow-body > button {
    width: 35.3333vw;
    height: 4vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow-wrapper > .subwindow.store-credit-pay {
    width: 42vw;
    height: min(59.0833vw, 95%);
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .text-row {
    width: 26.75vw;
    margin-bottom: 2.1667vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .text-row
    > p.style1 {
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .text-row
    > p.style2 {
    font-size: 1.6667vw;
    line-height: 2.1667vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper {
    width: 26.75vw;
    padding: 1.3333vw;
    border-radius: 0.6667vw;
    margin-bottom: 1.5vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .input-wrapper {
    height: 7.75vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.5vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .input-wrapper
    > p {
    font-size: 1vw;
    line-height: 1.4167vw;
    margin-bottom: 0.9167vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .input-wrapper
    > input {
    height: 3.5vw;
    font-size: 3.3333vw;
    line-height: 3.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .numpad-row {
    height: 5.6667vw;
    margin-top: 0.75vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .numpad-row
    > button {
    margin-left: 0.75vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.5vw;
    font-size: 2.5vw;
    line-height: 3.75vw;
    letter-spacing: 0vw;
  }

  .subwindow-wrapper
    > .subwindow.store-credit-pay
    > .subwindow-body
    > .numpad-wrapper
    > .numpad-row
    > button
    > img {
    width: 3.3333vw;
    height: 3.3333vw;
  }

  .subwindow-wrapper > .subwindow.store-credit-pay > .subwindow-body > button {
    width: 26.75vw;
    height: 5vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow-wrapper > .subwindow.logout-confirm > .subwindow-body > p.style1 {
    font-size: 2.0833vw;
    line-height: 3vw;
    margin: 0.8333vw 0 4.0833vw 0;
  }

  .subwindow-wrapper > .subwindow.logout-confirm > .subwindow-body > p.style2 {
    font-size: 1.5vw;
    line-height: 2vw;
    margin-top: 0.8333vw;
  }

  .subwindow-wrapper > .subwindow.logout-confirm > .subwindow-body > button {
    width: 15.8333vw;
    height: 4vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow-wrapper
    > .subwindow.logout-confirm
    > .subwindow-body
    > button#registerLogout {
    margin: 5.0833vw 0 1.4167vw 0;
  }

  .subwindow-wrapper
    > .subwindow.logout-confirm
    > .subwindow-body
    > button#cancelRegisterLogout {
    border: 0.0833vw solid var(--oliver-blue);
    margin-bottom: 0.8333vw;
  }

  .subwindow-wrapper > .subwindow.receipt {
    width: 42vw;
    height: 24.5833vw;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-options
    > p {
    font-size: 1.3333vw;
    line-height: 1.75vw;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-options
    > button {
    width: 22.0833vw;
    height: 4.1667vw;
    margin-top: 1.4167vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-options
    > button:first-of-type {
    margin-top: 1.75vw;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-email
    > label {
    width: 29.1667vw;
    font-size: 1.3333vw;
    line-height: 1.75vw;
    padding-bottom: 0.5833vw;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-email
    > input {
    width: 29.1667vw;
    height: 4.1667vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.5vw;
    padding: 0 1vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 1.4167vw;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-email
    > input:focus {
    border-width: 0.1667vw;
  }

  .subwindow-wrapper
    > .subwindow.receipt
    > .subwindow-body
    > .receipt-email
    > button {
    width: 29.1667vw;
    height: 4.1667vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .subwindow-wrapper > .subwindow.update-transaction-status {
    width: 28.6667vw;
    height: min(95%, 47vw);
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > p {
    font-size: 1.1667vw;
    line-height: 1.5vw;
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > label {
    margin-top: 1.25vw;
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > label
    > input {
    left: -833.3333vw;
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > label
    > .custom-radio {
    width: 23.75vw;
    height: 3.75vw;
    border-radius: 0.6667vw;
    border: 0.0833vw solid var(--oliver-border);
    font-size: 1.3333vw;
    line-height: 1.75vw;
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > label
    > input:not(:disabled):not(:checked)
    + .custom-radio:hover {
    border: 0.0833vw solid var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > label
    > input:checked
    + .custom-radio {
    border: 0.1667vw solid var(--oliver-blue);
  }

  .subwindow-wrapper
    > .subwindow.update-transaction-status
    > .subwindow-body
    > button {
    margin-top: 2.3333vw;
    width: 23.75vw;
    height: 3.75vw;
    border-radius: 0.6667vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .open-register-wrapper > #cancel,
  .open-register-wrapper > #goBack {
    left: 0.8333vw;
    top: 0.8333vw;
    width: 11.0083vw;
    height: 4.3333vw;
    font-size: 1.6667vw;
    line-height: 2.5vw;
  }

  .open-register-wrapper > #cancel > img,
  .open-register-wrapper > #goBack > img {
    width: 0.6833vw;
    height: 1.3667vw;
    margin-right: 0.6667vw;
  }

  .open-register-wrapper > #logout-landscape {
    right: 0.8333vw;
    top: 0.8333vw;
    width: 11.0083vw;
    height: 4.3333vw;
    font-size: 1.6667vw;
    line-height: 2.5vw;
  }

  .open-register-wrapper > #logout-landscape > img {
    width: 2vw;
    height: 2vw;
    margin-right: 0.5833vw;
  }

  .open-register-wrapper > header > img {
    width: 23.0567vw;
    height: 21.0833vw;
    margin-bottom: 1.6667vw;
  }

  .open-register-wrapper > header p.style1 {
    font-size: 4vw;
    line-height: 5.75vw;
    margin-bottom: 0.8333vw;
  }

  .open-register-wrapper > header .divider {
    width: 21.75vw;
    height: 0.0833vw;
    margin-bottom: 1.1667vw;
  }

  .open-register-wrapper > header p.style2 {
    font-size: 2vw;
    line-height: 3vw;
  }

  .open-register-wrapper > header p.style3 {
    font-size: 1.5vw;
    line-height: 2.3333vw;
  }

  .open-register-wrapper > main > * {
    width: 29vw;
    margin-left: 13.25vw;
  }

  .open-register-wrapper > main > .step1 > p {
    font-size: 2vw;
    line-height: 2.9167vw;
    margin-bottom: 1.4167vw;
  }

  .open-register-wrapper > main > .step1 > button {
    width: 28.9167vw;
    height: 4.1667vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .open-register-wrapper > main > .step2 > p {
    font-size: 2vw;
    line-height: 2.9167vw;
    margin-bottom: 2vw;
  }

  .open-register-wrapper > main > .step2 > label {
    width: 29vw;
    font-size: 1.3333vw;
    line-height: 2vw;
    margin-bottom: 0.75vw;
  }

  .open-register-wrapper > main > .step2 > input {
    width: 29vw;
    height: 6.9167vw;
    border-radius: 0.5vw;
    font-size: 3.3333vw;
    line-height: 4.6667vw;
    margin-bottom: 2vw;
  }

  .open-register-wrapper > main > .step2 > button {
    width: 29vw;
    height: 4.1667vw;
    font-size: 1.5vw;
    line-height: 2vw;
    border-radius: 0.5vw;
  }

  .open-register-wrapper > main > .step2 > button#openCashDrawer {
    border: 0.0833vw solid #fff;
    margin-bottom: 2vw;
  }

  .open-register-wrapper > main > .step2 > textarea {
    width: 28.9167vw;
    height: 6.9167vw;
    padding: 1vw;
    border-radius: 0.5vw;
    margin-bottom: 2vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .open-register-wrapper > main > .step3 > p {
    font-size: 1.6667vw;
    line-height: 2.4167vw;
    margin: 2.0833vw;
  }

  .open-register-wrapper > main > .step3 > .error-message {
    width: 23vw;
    height: 3.5vw;
    border-radius: 0.5vw;
    margin-bottom: 1.5vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .open-register-wrapper > main > .step3 > .pinpad {
    width: 23vw;
  }

  .open-register-wrapper > main > .step3 > .pinpad > .pin-entries {
    margin-bottom: 1.4217vw;
  }

  .open-register-wrapper > main > .step3 > .pinpad > .pin-entries > .pin-entry {
    width: 1.25vw;
    height: 1.25vw;
    margin-left: 0.785vw;
  }

  .open-register-wrapper > main > .step3 > .pinpad > .pin-button-row {
    margin-top: 1.3583vw;
  }

  .open-register-wrapper > main > .step3 > .pinpad > .pin-button-row > button {
    width: 6.7925vw;
    height: 6.7925vw;
    border-radius: 0.6667vw;
    margin-right: 1.3108vw;
  }

  .open-register-wrapper
    > main
    > .step3
    > .pinpad
    > .pin-button-row
    > button
    > p {
    font-size: 3vw;
    line-height: 3vw;
    transform: translateY(0.25vw);
  }

  .open-register-wrapper
    > main
    > .step3
    > .pinpad
    > .pin-button-row
    > button
    > .text-row {
    font-size: 0.8333vw;
    line-height: 1.25vw;
  }

  .open-register-wrapper
    > main
    > .step3
    > .pinpad
    > .pin-button-row
    > button.backspace
    > img {
    width: 4.1983vw;
    height: 3.3408vw;
  }

  .logout-confirmation-wrapper > p.style1 {
    font-size: 2.0833vw;
    line-height: 3vw;
    margin: 2.1667vw 0 4.0833vw 0;
  }

  .logout-confirmation-wrapper > p.style2 {
    font-size: 1.5vw;
    line-height: 2vw;
    margin-bottom: 5.0833vw;
  }

  .logout-confirmation-wrapper > button {
    width: 15.8333vw;
    height: 4vw;
    border-radius: 0.6667vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .logout-confirmation-wrapper > button#logoutConfirm {
    margin-bottom: 1.4167vw;
  }

  .logout-confirmation-wrapper > button#logoutCancel {
    border: 0.0833vw solid var(--oliver-blue);
    margin-bottom: 2.1667vw;
  }

  .idle-register-wrapper > #logoutRegisterButton {
    padding: 0.8333vw;
    top: 1.1667vw;
    left: 1.1667vw;
    font-size: 1.6667vw;
    line-height: 2.5vw;
  }

  .idle-register-wrapper > #logoutRegisterButton > img {
    width: 2vw;
    height: 2vw;
    margin-right: 0.5833vw;
  }

  .idle-register-wrapper > header {
    margin: auto 14.25vw auto auto;
  }

  .idle-register-wrapper > header > img {
    width: 23.0567vw;
    height: 21.0833vw;
    margin: 1.6667vw 0 1.4167vw 0;
  }

  .idle-register-wrapper > header > .col > p.style1 {
    font-size: 4vw;
    line-height: 5.75vw;
    margin-bottom: 0.8333vw;
  }

  .idle-register-wrapper > header > .col > p.style2 {
    font-size: 2vw;
    line-height: 2.3333vw;
    margin-bottom: 0.0833vw;
  }

  .idle-register-wrapper > header > .col > p.style3 {
    font-size: 1.5vw;
    line-height: 2.3333vw;
    margin-bottom: 1.4167vw;
  }

  .idle-register-wrapper > header > .col > .divider {
    width: 21.75vw;
    height: 0.0833vw;
    margin-bottom: 1.1667vw;
  }

  .idle-register-wrapper > header > .col > button#closeRegister1 {
    width: 21.75vw;
    height: 4.1667vw;
    border-radius: 0.3333vw;
    font-size: 1.5vw;
    line-height: 2vw;
    margin-bottom: 1.6667vw;
  }

  .idle-register-wrapper > main > p {
    font-size: 1.6667vw;
    line-height: 2.4167vw;
    margin: 1.6667vw 0 2.0575vw 0;
  }

  .idle-register-wrapper > main > .pinpad {
    width: 23.1667vw;
    margin-bottom: 0.8333vw;
  }

  .idle-register-wrapper > main > .pinpad > p.error-message {
    padding: 0.9167vw 2.3333vw;
    border-radius: 0.6667vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin: -0.8333vw 0 1.6667vw 0;
  }

  .idle-register-wrapper > main > .pinpad > .pin-entries {
    margin-bottom: 1.4167vw;
  }

  .idle-register-wrapper > main > .pinpad > .pin-entries > .pin-entry {
    width: 1.3333vw;
    height: 1.3333vw;
    margin-left: 0.8333vw;
  }

  .idle-register-wrapper > main > .pinpad > .pin-button-row {
    gap: 1.3333vw;
    margin-bottom: 1.3333vw;
  }

  .idle-register-wrapper > main > .pinpad > .pin-button-row > button {
    width: 6.8333vw;
    height: 6.8333vw;
    border-radius: 0.6667vw;
  }

  .idle-register-wrapper
    > main
    > .pinpad
    > .pin-button-row
    > button.backspace
    > img {
    width: 4.1667vw;
    height: 3.3333vw;
  }

  .idle-register-wrapper > main > .pinpad > .pin-button-row > button > p {
    font-size: 3vw;
    line-height: 3vw;
  }

  .idle-register-wrapper
    > main
    > .pinpad
    > .pin-button-row
    > button
    > .text-row {
    font-size: 0.8333vw;
    line-height: 1.25vw;
    letter-spacing: 0vw;
  }

  .close-register-wrapper > button {
    left: 0.8333vw;
    top: 0.8333vw;
    width: 11.0083vw;
    height: 4.3333vw;
    font-size: 1.6667vw;
    line-height: 2.5vw;
  }

  .close-register-wrapper > button > img {
    width: 0.6833vw;
    height: 1.3667vw;
    margin-right: 0.6667vw;
  }

  .close-register-wrapper > header > img {
    width: 23.0567vw;
    height: 21.0833vw;
    margin-bottom: 1.4167vw;
  }

  .close-register-wrapper > header > .col > p.style1 {
    font-size: 4vw;
    line-height: 5.75vw;
  }

  .close-register-wrapper > header > .col > p.style2 {
    font-size: 2vw;
    line-height: 2.3333vw;
    margin-bottom: 0.0833vw;
  }

  .close-register-wrapper > header > .col > p.style3 {
    font-size: 1.5vw;
    line-height: 2.3333vw;
  }

  .close-register-wrapper > header > .col > .divider {
    width: 21.75vw;
    height: 0.0833vw;
    margin-bottom: 1.1667vw;
  }

  .close-register-wrapper > main > .step1 {
    margin-left: 14.25vw;
  }

  .close-register-wrapper > main > .step1 > p.style1 {
    font-size: 2vw;
    line-height: 2.9167vw;
    margin: 2.1667vw 0 3vw 0;
  }

  .close-register-wrapper > main > .step1 > .divider {
    width: 7.5vw;
    height: 0.0833vw;
    margin-bottom: 3.5833vw;
  }

  .close-register-wrapper > main > .step1 > p.style2 {
    font-size: 1.6667vw;
    line-height: 2.4167vw;
    margin-bottom: 2.0575vw;
  }

  .close-register-wrapper > main > .step1 > .pinpad {
    margin-bottom: 2.1667vw;
  }

  .close-register-wrapper > main > .step1 > .pinpad {
    width: 23.1667vw;
    margin-bottom: 0.8333vw;
  }

  .close-register-wrapper > main > .step1 > .pinpad > p.error-message {
    padding: 0.9167vw 2.3333vw;
    border-radius: 0.6667vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin: -0.8333vw 0 1.6667vw 0;
  }

  .close-register-wrapper > main > .step1 > .pinpad > .pin-entries {
    margin-bottom: 1.4167vw;
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-entries
    > .pin-entry {
    width: 1.3333vw;
    height: 1.3333vw;
    margin-left: 0.8333vw;
  }

  .close-register-wrapper > main > .step1 > .pinpad > .pin-button-row {
    gap: 1.3333vw;
    margin-bottom: 1.3333vw;
  }

  .close-register-wrapper > main > .step1 > .pinpad > .pin-button-row > button {
    width: 6.8333vw;
    height: 6.8333vw;
    border-radius: 0.6667vw;
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-button-row
    > button.backspace
    > img {
    width: 4.1667vw;
    height: 3.3333vw;
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-button-row
    > button
    > p {
    font-size: 3vw;
    line-height: 3vw;
  }

  .close-register-wrapper
    > main
    > .step1
    > .pinpad
    > .pin-button-row
    > button
    > .text-row {
    font-size: 0.8333vw;
    line-height: 1.25vw;
    letter-spacing: 0vw;
  }

  .close-register-wrapper > main > .step2 {
    width: 34.6667vw;
    margin-left: 12.9433vw;
  }

  .close-register-wrapper > main > .step2 > p.style1 {
    font-size: 2vw;
    line-height: 2.9167vw;
    margin: 2.1667vw 0 2.5vw 0;
  }

  .close-register-wrapper > main > .step2 > .divider {
    width: 7.2558vw;
    height: 0.0833vw;
    margin-bottom: 4.0833vw;
  }

  .close-register-wrapper > main > .step2 > p.style2 {
    font-size: 1.3333vw;
    line-height: 2vw;
    margin-bottom: 1.8333vw;
  }

  .close-register-wrapper > main > .step2 > button#openCashDrawer {
    height: 4.1667vw;
    border: 0.1667vw solid #fff;
    border-radius: 0.6667vw;
    font-size: 1.5vw;
    line-height: 2vw;
    margin-bottom: 3.1667vw;
  }

  .close-register-wrapper > main > .step2 > .input-column {
    margin-bottom: 3.1667vw;
  }

  .close-register-wrapper > main > .step2 > .input-column > .input-row {
    margin-top: 1.75vw;
  }

  .close-register-wrapper > main > .step2 > .input-column > .input-row > label {
    font-size: 1.3333vw;
    line-height: 2vw;
  }

  .close-register-wrapper > main > .step2 > .input-column > .input-row > input {
    width: 19.3667vw;
    height: 4.1667vw;
    border-radius: 0.5vw;
    padding: 0 1.5vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .close-register-wrapper > main > .step2 > button#saveCount {
    height: 4.1667vw;
    border-radius: 0.6667vw;
    font-size: 1.5vw;
    line-height: 2vw;
    margin-bottom: 2.1667vw;
  }

  .close-register-wrapper > main > .step3 {
    width: 51.5833vw;
    margin-left: 6.36vw;
  }

  .close-register-wrapper > main > .step3 > p.style1 {
    font-size: 2vw;
    line-height: 2.9167vw;
    margin: 2.1667vw 0 3.1667vw 0;
  }

  .close-register-wrapper > main > .step3 > label {
    font-size: 1.3333vw;
    line-height: 2vw;
    margin-bottom: 0.75vw;
  }

  .close-register-wrapper > main > .step3 > .closing-totals {
    border-radius: 0.6667vw;
    padding: 0 1.25vw;
    margin-bottom: 2.0833vw;
  }

  .close-register-wrapper > main > .step3 > .closing-totals > .tablet-row {
    margin: 1vw 0 1.8333vw 0;
  }

  .close-register-wrapper > main > .step3 > .closing-totals > .tablet-row > p {
    font-size: 1.3333vw;
    line-height: 2vw;
  }

  .close-register-wrapper > main > .step3 > .closing-totals > .col {
    margin-bottom: 1.3333vw;
  }

  .close-register-wrapper > main > .step3 > .closing-totals > .col > p {
    font-size: 1.3333vw;
    line-height: 2vw;
  }

  .close-register-wrapper
    > main
    > .step3
    > .closing-totals
    > .col
    > .row
    > p:last-of-type {
    font-size: 1.3333vw;
    line-height: 2vw;
  }

  .close-register-wrapper > main > .step3 > .closing-totals > .tablet-divider {
    width: calc(100% + 2.5vw);
    height: 0.0833vw;
    margin-left: -1.25vw;
    margin-bottom: 1.3333vw;
  }

  .close-register-wrapper > main > .step3 > .closing-totals > .col.total > p {
    font-size: 1.3333vw;
    line-height: 2vw;
  }

  .close-register-wrapper
    > main
    > .step3
    > .closing-totals
    > .col.total
    > .row
    > p {
    font-size: 1.3333vw;
    line-height: 2vw;
  }

  .close-register-wrapper > main > .step3 > textarea {
    height: 8.75vw;
    border-radius: 0.6667vw;
    padding: 1vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 2.4167vw;
  }

  .close-register-wrapper > main > .step3 > button#printReport {
    width: 20.3333vw;
    height: 4.1667vw;
    border: 0.1667vw solid #fff;
    border-radius: 0.6667vw;
    font-size: 1.5vw;
    line-height: 2vw;
    margin-bottom: 2.4167vw;
  }

  .close-register-wrapper > main > .step3 > button#closeRegister {
    height: 4.1667vw;
    border-radius: 0.6667vw;
    font-size: 1.5vw;
    line-height: 2vw;
    margin-bottom: 2.1667vw;
  }

  .create-userid-wrapper > .header {
    padding: 0.8333vw;
  }

  .create-userid-wrapper > .header > #cancelPinSetup,
  .create-userid-wrapper > .header > #goBackPinSetup {
    padding: 0.8333vw;
    font-size: 1.6667vw;
    line-height: 2.5vw;
  }

  .create-userid-wrapper > .header > #cancelPinSetup > img,
  .create-userid-wrapper > .header > #goBackPinSetup > img {
    width: 0.75vw;
    height: 1.3333vw;
    margin-right: 0.5833vw;
  }

  .create-userid-wrapper > .header > #logoutPinSetup {
    padding: 0.8333vw;
    font-size: 1.6667vw;
    line-height: 2.5vw;
  }

  .create-userid-wrapper > .header > #logoutPinSetup > img {
    width: 2vw;
    height: 2vw;
    margin-right: 0.5833vw;
  }

  .create-userid-wrapper > .body {
    padding-bottom: 1.6667vw;
  }

  .create-userid-wrapper > .body > .col.static > img {
    width: 23.0567vw;
    height: 21.0833vw;
    margin-bottom: 1.6667vw;
  }

  .create-userid-wrapper > .body > .col.static > .text-group > p.style1 {
    font-size: 4vw;
    line-height: 4.3333vw;
    margin-bottom: 0.8333vw;
  }

  .create-userid-wrapper > .body > .col.static > .text-group > p.style2 {
    font-size: 2vw;
    line-height: 2.3333vw;
    margin-bottom: 0.0833vw;
  }

  .create-userid-wrapper > .body > .col.static > .text-group > p.style3 {
    font-size: 1.5vw;
    line-height: 2.3333vw;
  }

  .create-userid-wrapper > .body > .col.static > .text-group > .divider {
    width: 21.75vw;
    height: 0.0833vw;
    margin-bottom: 1.1667vw;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-1 > p {
    font-size: 2vw;
    line-height: 2.9167vw;
    margin-bottom: 1.6667vw;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-1 > button {
    width: 28.9167vw;
    height: 4.1667vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 > p {
    font-size: 1.6667vw;
    line-height: 2.4167vw;
    margin-bottom: 1.9167vw;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 > .id-row {
    width: 23vw;
    margin-bottom: 2.1108vw;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .id-row
    > .id-entry {
    height: 3.6667vw;
    margin-left: 0.8333vw;
    box-shadow: inset 0vw 0.3333vw 0.5vw rgba(0, 0, 0, 0.25);
    border-radius: 0.5vw;
    font-size: 2.0833vw;
    line-height: 3vw;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 > .pin-key-row {
    width: 23vw;
    margin-bottom: 1.3333vw;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .pin-key-row
    > button {
    margin-left: 1.3333vw;
    border-radius: 0.6667vw;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .pin-key-row
    > button.backspace
    > img {
    width: 4.1983vw;
    height: 3.3408vw;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 > .pin-key-row.last {
    margin-bottom: 2.5vw;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .pin-key-row
    > button
    > p {
    font-size: 3vw;
    line-height: 3vw;
  }

  .create-userid-wrapper
    > .body
    > .col.dynamic
    > .step-2
    > .pin-key-row
    > button
    > .letter-row
    > p {
    font-size: 0.8333vw;
    line-height: 1.25vw;
  }

  .create-userid-wrapper > .body > .col.dynamic > .step-2 > button {
    width: 23vw;
    height: 4.1667vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .navbar {
    padding: 1.5vw 1.3333vw 1.6667vw 1.3333vw;
  }

  .navbar.open {
    width: calc(369.4444% + 0.0833vw);
    border-right: 0.0833vw solid var(--oliver-border);
  }

  .navbar > .header-row {
    margin-bottom: 1.6667vw;
  }

  .navbar > .header-row > img.oliver-logo {
    width: 2.4167vw;
    height: 2.5vw;
    margin: 0 1.6667vw 0 0.5vw;
  }

  .navbar > .header-row > img.oliver-text {
    width: 5.5vw;
    height: 1.5vw;
  }

  .navbar > button.page-link {
    height: 3.3333vw;
    padding: 0 0.6667vw;
    border-radius: 0.6667vw;
    margin-bottom: 0.4167vw;
  }

  .navbar > button.page-link > .img-container {
    width: 2vw;
    height: 2vw;
  }

  .navbar > button > p {
    font-size: 1vw;
    line-height: 1.4167vw;
    margin: 0 auto 0 0.75vw;
  }

  .navbar > button > .f-key {
    width: 2vw;
    height: 2vw;
    border-radius: 0.3333vw;
    font-size: 1vw;
    line-height: 1.4167vw;
  }

  .navbar > button.launcher {
    margin-bottom: 1.6667vw;
    padding: 0 0.3333vw;
  }

  .navbar > button.launcher > .img-container {
    width: 2.6667vw;
    height: 2.6667vw;
    border-radius: 0.5vw;
  }

  .navbar > button.launcher#linkLauncherButton {
    margin: auto 0 1.3333vw 0;
  }

  .navbar > button.launcher#navApp3 {
    margin-bottom: 1.5vw;
  }

  .navbar > button.launcher#linkLauncherButton > .img-container > img {
    width: 1.6667vw;
    height: 1.6667vw;
  }

  .navbar > button.launcher#appLauncherButton > .img-container > img {
    width: 1.5833vw;
    height: 1.5833vw;
  }

  .navbar > button.launcher > p {
    margin-left: 1vw;
  }

  .navbar > .divider {
    width: 2.6667vw;
    height: 0.0833vw;
    margin: 0 0 1.3333vw 0.3333vw;
  }

  .navbar > button.toggle-nav {
    padding: 0 0.6667vw;
  }

  .navbar > button.toggle-nav > .img-container {
    width: 2vw;
    height: 2vw;
  }

  .navbar > button.toggle-nav > .img-container > img {
    width: 1.6667vw;
    height: 1.4167vw;
  }

  .navbar > button.toggle-nav > p {
    margin-left: 1.3333vw;
  }

  .nav-cover {
    left: 22.1667vw;
  }

  .app-launcher-wrapper {
    left: 6vw;
    border-left: 0.0833vw solid var(--oliver-border);
  }

  .navbar.open ~ .app-launcher-wrapper {
    left: 22.1667vw;
  }

  .app-launcher-wrapper > .app-launcher {
    width: 31.25vw;
  }

  .app-launcher-wrapper > .app-launcher > .header {
    padding: 2vw 0;
  }

  .app-launcher-wrapper > .app-launcher > .header > p {
    font-size: 1.3333vw;
    line-height: 1.75vw;
    margin-left: 2.4167vw;
  }

  .app-launcher-wrapper > .app-launcher > .body {
    padding: 0 2.4167vw;
  }

  .app-launcher-wrapper > .app-launcher > .body > img {
    width: 21.6667vw;
    height: 13.75vw;
  }

  .app-launcher-wrapper > .app-launcher > .body > button {
    margin-bottom: 1.6667vw;
  }

  .app-launcher-wrapper > .app-launcher > .body > button > .img-container {
    width: 4.5833vw;
    height: 4.5833vw;
    margin-right: 2.1667vw;
    border-radius: 0.8333vw;
  }

  .app-launcher-wrapper > .app-launcher > .body > button > p {
    font-size: 1.3333vw;
    line-height: 2vw;
  }

  .link-launcher-wrapper {
    left: 6vw;
    border-left: 0.0833vw solid var(--oliver-border);
  }

  .navbar.open ~ .link-launcher-wrapper {
    left: 22.1667vw;
  }

  .link-launcher-wrapper > .link-launcher {
    width: 31.25vw;
  }

  .link-launcher-wrapper > .link-launcher > .header {
    padding: 2vw 0;
  }

  .link-launcher-wrapper > .link-launcher > .header > p {
    font-size: 1.3333vw;
    line-height: 1.75vw;
    margin-left: 2.4167vw;
  }

  .link-launcher-wrapper > .link-launcher > .body {
    padding: 0 2.4167vw;
  }

  .link-launcher-wrapper > .link-launcher > .body > img {
    width: 21.4167vw;
    height: 14.6667vw;
  }

  .link-launcher-wrapper > .link-launcher > .body > button {
    margin-bottom: 1.6667vw;
  }

  .link-launcher-wrapper > .link-launcher > .body > button > .img-container {
    width: 4.5833vw;
    height: 4.5833vw;
    margin-right: 2.1667vw;
    border-radius: 0.8333vw;
  }

  .link-launcher-wrapper > .link-launcher > .body > button > .col > p.style1 {
    font-size: 1.3333vw;
    line-height: 2vw;
  }

  .link-launcher-wrapper > .link-launcher > .body > button > .col > p.style2 {
    font-size: 1vw;
    line-height: 1.4167vw;
  }

  .user-info-wrapper {
    left: 6vw;
    top: 5.6667vw;
    width: calc(100% - 6vw);
    height: calc(100% - 5.6667vw);
  }

  .user-info-wrapper > .user-info {
    right: 1.3333vw;
    top: 1.3333vw;
    width: 29.1667vw;
    height: 38.0833vw;
    border-radius: 0.8333vw;
    box-shadow: 0vw 1.6667vw 2.5vw rgba(18, 43, 81, 0.25);
    padding: 1.6667vw 1.6667vw 2.0833vw 1.6667vw;
  }

  .user-info-wrapper > .user-info > .header {
    padding-bottom: 1.5vw;
    border-bottom: 0.0833vw solid var(--oliver-border);
  }

  .user-info-wrapper > .user-info > .header > .avatar {
    width: 5vw;
    height: 5vw;
    margin-right: 1.4167vw;
    font-size: 1.6667vw;
  }

  .user-info-wrapper > .user-info > .header > .col > p.style1 {
    font-size: 1.5vw;
    line-height: 2.0833vw;
  }

  .user-info-wrapper > .user-info > .header > .col > p.style2 {
    font-size: 1.0833vw;
    line-height: 1.5vw;
  }

  .user-info-wrapper > .user-info > .body {
    padding: 1.5vw 0;
  }

  .user-info-wrapper > .user-info > .body > .language-select {
    margin-bottom: 1.6667vw;
  }

  .user-info-wrapper > .user-info > .body > .language-select > p {
    font-size: 1vw;
    line-height: 1.4167vw;
    margin-right: 0.8333vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper {
    height: 2.9167vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > img {
    top: calc(50% - 0.5833vw);
    right: 1vw;
    width: 1.1667vw;
    height: 1.1667vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper.open
    > input {
    border-radius: 0.6667vw 0.6667vw 0 0;
    border-width: 0.1667vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > input {
    border: 0.0833vw solid var(--oliver-base-grey);
    border-radius: 0.6667vw;
    padding: 0 1vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > .option-container {
    border-radius: 0 0 0.6667vw 0.6667vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > .option-container
    > .language-option {
    height: 2.9167vw;
    border: 0.0833vw solid var(--oliver-border);
    padding: 0 1vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > .option-container
    > .language-option
    > p {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    max-width: calc(100% - 1.3333vw);
  }

  .user-info-wrapper
    > .user-info
    > .body
    > .language-select
    > .dropdown-input-wrapper
    > .option-container
    > .language-option:last-child {
    border-radius: 0 0 0.6667vw 0.6667vw;
  }

  .user-info-wrapper > .user-info > .body > button {
    height: 3.3333vw;
    margin-bottom: 0.6667vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .user-info-wrapper > .user-info > .body > button > .img-container {
    width: 2.5vw;
    height: 2.5vw;
    margin-right: 0.8333vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > button#knowledgeBaseButton
    > .img-container
    > img {
    width: 1.8333vw;
    height: 1.8333vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > button#switchUserButton
    > .img-container
    > img {
    width: 1.1667vw;
    height: 1.25vw;
  }

  .user-info-wrapper
    > .user-info
    > .body
    > button#endSessionButton
    > .img-container
    > img {
    width: 2.1667vw;
    height: 1.8333vw;
  }

  .user-info-wrapper > .user-info > .footer {
    padding-top: 1.5vw;
    border-top: 0.0833vw solid var(--oliver-border);
  }

  .user-info-wrapper > .user-info > .footer > .row {
    margin-bottom: 1.0833vw;
  }

  .user-info-wrapper > .user-info > .footer > .row > img {
    width: 1.5833vw;
    height: 1.5833vw;
    margin-right: 0.5833vw;
  }

  .user-info-wrapper > .user-info > .footer > .row > p {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .user-info-wrapper > .user-info > .footer > .button-row > button {
    width: 5.3333vw;
    height: 5.3333vw;
    border-radius: 0.8333vw;
    margin-right: 1.5vw;
  }

  .page-options-wrapper {
    top: 1.8333vw;
    right: 6.3333vw;
  }

  .page-options-wrapper > .page-options > button {
    width: 2vw;
    height: 2vw;
    margin-right: 0.8333vw;
  }

  .page-options-wrapper > .page-options > button > .img-container > .badge {
    border: 0.1667vw solid #fff;
  }

  .notifications-wrapper {
    width: calc(100% - 6vw);
    height: calc(100% - 5.6667vw);
    left: 6vw;
    top: 5.6667vw;
  }

  .notifications-wrapper > .notifications {
    top: 0.0833vw;
    right: 1.3333vw;
    width: 31.25vw;
    height: min(62.5vw, 90%);
    border-radius: 0 0 0.6667vw 0.6667vw;
  }

  .notifications-wrapper > .notifications.settings {
    height: min(20vw, 90%);
  }

  .notifications-wrapper > .notifications > .noti-header {
    height: 4.5vw;
    padding: 0 1.3333vw;
    border-bottom: 0.0833vw solid var(--oliver-border);
  }

  .notifications-wrapper > .notifications > .noti-header > p {
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .notifications-wrapper > .notifications > .noti-header > button {
    padding: 0.8333vw;
    transform: translateX(0.8333vw);
  }

  .notifications-wrapper > .notifications > .noti-header > button > img {
    width: 2.0833vw;
    height: 2.0833vw;
  }

  .notifications-wrapper > .notifications > .noti-body > .date {
    padding: 0.75vw 1.3333vw;
  }

  .notifications-wrapper > .notifications > .noti-body > .date > p {
    font-size: 1.0833vw;
    line-height: 1.4167vw;
  }

  .notifications-wrapper > .notifications > .noti-body > .notification-card {
    height: 6.3333vw;
    padding: 0 1.25vw;
    border-top: 0.0833vw solid var(--oliver-border);
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .icon-wrapper {
    width: 3.3333vw;
    height: 3.3333vw;
    border-radius: 0.6667vw;
    margin-right: 1vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .icon-wrapper
    > img {
    width: 2.6667vw;
    height: 2.6667vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .icon-wrapper.red
    > img {
    width: 2.0833vw;
    height: 2.0833vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .icon-wrapper.grey
    > img {
    width: 2vw;
    height: 2vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .col {
    padding-right: 1vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .col
    > p.style1 {
    font-size: 1.0833vw;
    line-height: 1.4167vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > .col
    > p.style2 {
    font-size: 1vw;
    line-height: 1.3333vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-body
    > .notification-card
    > p {
    font-size: 1.0833vw;
    line-height: 1.4167vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .header {
    padding: 1vw 1.3333vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .header
    > p {
    font-size: 1.3333vw;
    line-height: 1.75vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list {
    padding: 0.8333vw 1.3333vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row {
    padding: 0.4167vw 0;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > img {
    width: 2vw;
    height: 2vw;
    margin-right: 0.8333vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > p {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    width: 16.6667vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > input {
    left: -833.3333vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > .toggle {
    width: 5.3333vw;
    height: 2.6667vw;
    border-radius: 2.6667vw;
    padding: 0.1667vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > .toggle::before {
    width: 2.3333vw;
    height: 2.3333vw;
    border: 0.0833vw solid rgba(0, 0, 0, 0.04);
    box-shadow: 0vw 0.25vw 0.6667vw rgba(0, 0, 0, 0.15),
      0vw 0.25vw 0.0833vw rgba(0, 0, 0, 0.06);
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > .toggle::after {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    right: 0.6667vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > input:checked
    + .toggle::before {
    right: 0.1667vw;
  }

  .notifications-wrapper
    > .notifications
    > .noti-settings-wrapper
    > .noti-settings
    > .settings-list
    > .setting-row
    > label
    > input:checked
    + .toggle::after {
    left: 0.6667vw;
  }

  .homepage-wrapper > .header > .row > img {
    width: 5.5vw;
    height: 1.5vw;
    transform: translateY(-0.1667vw);
  }

  .homepage-wrapper > .header > .row > button#searchButton {
    width: 15vw;
    height: 3vw;
    border-radius: 0.6667vw;
    font-size: 1.0833vw;
    line-height: 1.5vw;
    margin-right: 14.4167vw;
  }

  .homepage-wrapper > .header > .row > button#searchButton > div {
    margin-left: 0.25vw;
  }

  .homepage-wrapper > .header > .row > button#searchButton > img {
    width: 1.6667vw;
    height: 1.6667vw;
    margin-right: 0.4167vw;
  }

  .homepage-wrapper > .header > .row > .options-container {
    margin-right: 1.5vw;
  }

  .homepage-wrapper > .header > .row > .options-container > button {
    width: 2vw;
    height: 2vw;
    margin-right: 0.5833vw;
  }

  .homepage-wrapper > .header > .row > button#userInfoButton {
    width: 3.3333vw;
    height: 3.3333vw;
    margin-right: 1.4167vw;
  }

  .homepage-wrapper > .products > .change-row-count {
    margin-bottom: 0.7813vw;
  }

  .homepage-wrapper > .products > .change-row-count > p {
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .homepage-wrapper > .products > .change-row-count > button:not(#exitEditing) {
    width: 1.9531vw;
    height: 1.9531vw;
    border: 0.0488vw solid var(--oliver-blue);
    border-radius: 0.3906vw;
    margin: 0 0.5859vw;
  }

  .homepage-wrapper > .products > .change-row-count > button > img {
    width: 0.6348vw;
    height: 1.3672vw;
  }

  .homepage-wrapper > .products > .change-row-count > button#exitEditing {
    height: 1.9531vw;
    padding: 0 0.5859vw;
    border: 0.0488vw solid var(--oliver-blue);
    border-radius: 0.3906vw;
    font-size: 0.7813vw;
    line-height: 0.8789vw;
  }

  .homepage-wrapper > .products > .category-header > p {
    font-size: 1.3333vw;
    line-height: 2vw;
  }

  .homepage-wrapper > .products > .category-header > button {
    width: 14.1667vw;
    height: 3.3333vw;
    border-radius: 0.5vw;
    font-size: 1.3333vw;
    line-height: 2vw;
  }

  .homepage-wrapper > .products > .category-header > button > img {
    width: 1.9167vw;
    height: 1.9167vw;
    margin-right: 0.8333vw;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > .no-product-container
    > img {
    width: 3.75vw;
    height: 3.75vw;
    margin-bottom: 1.0833vw;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > .no-product-container
    > p {
    font-size: 1.5vw;
    line-height: 2.25vw;
    margin-bottom: 3.1667vw;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > .no-product-container
    > button {
    width: 11.6667vw;
    height: 3.0833vw;
    border: 0.0833vw solid var(--oliver-blue);
    border-radius: 0.5vw;
    font-size: 1.1667vw;
    line-height: 1.75vw;
  }

  .homepage-wrapper > .products > .products-container > button {
    border-radius: 0.6667vw;
    box-shadow: 0vw 0.5vw 0.8333vw rgba(94, 94, 94, 0.1);
  }

  .homepage-wrapper > .products > .products-container > button.product > .body {
    border-radius: 0.6667vw 0.6667vw 0 0;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button.product
    > .footer {
    border-radius: 0 0 0.6667vw 0.6667vw;
    border-top: 0.0833vw solid var(--oliver-border);
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button.product
    > .footer
    > p {
    font-size: 1.3333vw;
    line-height: 1.6667vw;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button.category
    > p.sub {
    font-size: 1vw;
    line-height: 1.25vw;
    margin-bottom: 1.6667vw;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button.category
    > p.main {
    font-size: 1.3333vw;
    line-height: 1.6667vw;
  }

  .homepage-wrapper > .products > .products-container > button.add-tile {
    border: 0.1667vw dashed var(--oliver-new-grey);
    font-size: 1.3333vw;
    line-height: 1.5vw;
  }

  .homepage-wrapper > .products > .products-container > button.add-tile > img {
    width: 1.6667vw;
    height: 1.6667vw;
    margin-right: 0.5vw;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button
    > .not-supported {
    border-radius: 0.5vw;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button
    > .not-supported
    > img {
    width: 2.3333vw;
    top: 1vw;
    left: 1vw;
  }

  .cart > .body {
    padding: 0 1.3333vw 1vw 1.3333vw;
  }

  .cart > .body > .cart-item {
    padding: 1vw 0;
    border-top: 0.0833vw solid var(--oliver-border);
  }

  .cart > .body > .cart-item > .main-row > .quantity {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-right: 1.25vw;
    margin: 0 1.25vw 0 0.6667vw;
  }

  .cart > .body > .cart-item > .main-row > .content-style {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    padding-right: 0.8333vw;
  }

  .cart > .body > .cart-item > .main-row > .price {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-right: 1.0525vw;
  }

  .cart > .body > .cart-item > .main-row > button.remove-cart-item {
    width: 1.5vw;
    height: 1.5vw;
    margin-right: 0.5525vw;
  }

  .cart > .body > .cart-item > .main-row > .tag {
    padding: 0.4583vw 0.9167vw;
    border-radius: 0.5vw;
    font-size: 0.9167vw;
    line-height: 1.25vw;
    margin-right: 1.1667vw;
  }

  .cart > .body > .cart-item > .secondary-col {
    padding: 0 2.8333vw 0 2.6667vw;
    margin-top: 0.8333vw;
  }

  .cart > .body > .cart-item > .secondary-col > p {
    font-size: 1vw;
    line-height: 1.4167vw;
  }

  .cart > .body > .cart-item > .secondary-col > .row > p {
    font-size: 1vw;
    line-height: 1.4167vw;
  }

  .cart > .footer {
    border-top: 0.0833vw solid var(--oliver-border);
  }

  .cart > .footer > .totals {
    padding: 1.25vw;
  }

  .cart > .footer > .totals > .row {
    margin-top: 0.5833vw;
  }

  .cart > .footer > .totals > .row > p {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .cart > .footer > .totals > .row > p:first-of-type {
    margin-right: 0.5833vw;
  }

  .cart > .footer > .totals > .row > button#editCartDiscount {
    font-size: 1vw;
    line-height: 1.4167vw;
  }

  .cart > .footer > .totals > .row > button#taxesButton {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-right: 0.3333vw;
  }

  .cart > .footer > .checkout-container {
    border-top: 0.0833vw solid var(--oliver-border);
    height: 7vw;
  }

  .cart > .footer > .checkout-container > button {
    padding: 1.25vw;
  }

  .cart > .footer > .checkout-container > button > p {
    border-radius: 0.6667vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .homepage-wrapper {
    grid-template-columns: 6vw auto 25vw;
    grid-template-rows: 5.6667vw auto;
  }

  .homepage-wrapper > .products {
    border-top: 0.0833vw solid var(--oliver-border);
    border-left: 0.0833vw solid var(--oliver-border);
  }

  .homepage-wrapper > .cart {
    border-top: 0.0833vw solid var(--oliver-border);
    border-left: 0.0833vw solid var(--oliver-border);
  }

  .product-wrapper {
    grid-template-columns: 6vw auto 40vw;
    grid-template-rows: 5.6667vw auto 13.3333vw;
  }

  .product-wrapper > .mod-product {
    border-top: 0.0833vw solid var(--oliver-border);
    border-left: 0.0833vw solid var(--oliver-border);
  }

  .product-wrapper > .detailed-product {
    border-top: 0.0833vw solid var(--oliver-border);
    border-left: 0.0833vw solid var(--oliver-border);
  }

  .product-wrapper > .recommended-upsells {
    border-top: 0.0833vw solid var(--oliver-border);
    border-left: 0.0833vw solid var(--oliver-border);
  }

  .product-wrapper > .product-footer {
    border-top: 0.0833vw solid var(--oliver-border);
    border-left: 0.0833vw solid var(--oliver-border);
  }

  .product-wrapper > .header > .main > p.prod-name {
    font-size: 1.6667vw;
    line-height: 2.4167vw;
  }

  .product-wrapper > .header > .main > button#desktopExitProductButton {
    width: 3.8333vw;
    height: 3.8333vw;
    margin-right: 1.1667vw;
  }

  .product-wrapper > .header > .main > button#desktopExitProductButton > img {
    width: 1.5975vw;
    height: 1.5975vw;
  }

  .product-wrapper > .mod-product {
    padding: 1.6667vw 1.6667vw 1.6667vw 1.6667vw;
  }

  .product-wrapper > .mod-product > .img-container > img:first-of-type {
    width: 26.5vw;
    height: 14.75vw;
  }

  .product-wrapper > .mod-product > .img-container > img:last-of-type {
    width: 17vw;
    height: 7.0833vw;
    margin-bottom: 3.1667vw;
  }

  .product-wrapper > .mod-product.show-image > .img-container {
    padding: 5vw 0;
  }

  .product-wrapper > .mod-product > .img-container > button {
    width: 13.9167vw;
    height: 3.0833vw;
    border: 0.0833vw solid var(--oliver-blue);
    border-radius: 0.5vw;
    font-size: 1.1667vw;
    line-height: 1.75vw;
  }

  .product-wrapper > .mod-product > .no-edit-item {
    width: calc(100% + 1.6667vw);
    height: calc(100% + 1.6667vw);
    left: -1.6667vw;
    top: -1.6667vw;
  }

  .product-wrapper > .mod-product > .no-edit-item > .inner-container {
    width: min(33.3333vw, 95%);
    height: min(18.75vw, 95%);
    box-shadow: 0vw 1.25vw 3.3333vw rgba(0, 0, 0, 0.1);
    border-radius: 0.6667vw;
  }

  .product-wrapper
    > .mod-product
    > .no-edit-item
    > .inner-container
    > p.style1 {
    font-size: 1.3333vw;
    line-height: 1.75vw;
    margin-bottom: 1.6667vw;
  }

  .product-wrapper
    > .mod-product
    > .no-edit-item
    > .inner-container
    > p.style2 {
    font-size: 1.1667vw;
    line-height: 1.5vw;
  }

  .product-wrapper > .mod-product > .row {
    height: 3.1667vw;
    margin-bottom: 0.5vw;
  }

  .product-wrapper > .mod-product > .row > p {
    font-size: 1.3333vw;
    line-height: 1.75vw;
  }

  .product-wrapper > .mod-product > .row > button {
    padding: 0.8333vw;
    font-size: 1.1667vw;
    line-height: 1.5vw;
  }

  .product-wrapper > .mod-product > p {
    font-size: 1.1667vw;
    line-height: 1.75vw;
    margin-bottom: 0.75vw;
  }

  .product-wrapper > .mod-product > p.error::after {
    font-size: 1.1667vw;
    line-height: 1.1667vw;
    margin-left: 0.8333vw;
  }

  .product-wrapper > .mod-product > .radio-group {
    margin-bottom: 0.4167vw;
  }

  .product-wrapper > .mod-product > .radio-group > label {
    margin: 0 0.8333vw 0.8333vw 0;
  }

  .product-wrapper > .mod-product > .radio-group > label > input[type="radio"] {
    left: -833.3333vw;
  }

  .product-wrapper > .mod-product > .radio-group > label > .custom-radio {
    padding: 1.5vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.6667vw;
  }

  .product-wrapper > .mod-product > .radio-group > label > .custom-radio > p {
    font-size: 1.1667vw;
    line-height: 1.5vw;
  }

  .product-wrapper
    > .mod-product
    > .radio-group
    > label
    > input[type="radio"]:checked
    ~ .custom-radio {
    border: 0.1667vw solid var(--oliver-blue);
  }

  .product-wrapper > .mod-product > .radio-group + .row {
    margin-top: 3.3333vw;
  }

  .product-wrapper > .mod-product > input {
    width: min(25vw, 100%);
    padding: 1.3333vw 1.5vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.6667vw;
    margin-bottom: 1.25vw;
    font-size: 1.1667vw;
    line-height: 1.1667vw;
  }

  .product-wrapper > .mod-product > input:focus {
    border: 0.1667vw solid var(--oliver-blue);
  }

  .product-wrapper > .mod-product > .increment-input {
    gap: 0.9167vw;
  }

  .product-wrapper > .mod-product > .increment-input > button {
    width: 3.3333vw;
    height: 3.3333vw;
    border-radius: 0.6667vw;
  }

  .product-wrapper > .mod-product > .increment-input > button > img {
    width: 1.3333vw;
    height: 2.5vw;
  }

  .product-wrapper > .mod-product > .increment-input > input {
    width: 6.6667vw !important;
    height: 3.3333vw;
    font-size: 1.1667vw;
    line-height: 1.5vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.6667vw;
  }

  .product-wrapper > .detailed-product {
    padding: 1.6667vw 1.6667vw 0 1.6667vw;
  }

  .product-wrapper > .detailed-product > .row {
    margin-bottom: 1.9167vw;
  }

  .product-wrapper > .detailed-product > .row > .product-image-container {
    border-radius: 0.6667vw;
    margin-right: 1.6667vw;
  }

  .product-wrapper
    > .detailed-product
    > .row
    > .col
    > .group
    > .text-row
    > p.quantity {
    font-size: 4vw;
    line-height: 5.5833vw;
  }

  .product-wrapper > .detailed-product > .row > .col > .group > p.desktop-only {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 1.75vw;
  }

  .product-wrapper > .detailed-product > .row > .col > .group > button {
    width: 13.9167vw;
    height: 3.0833vw;
    border: 0.0833vw solid var(--oliver-blue);
    border-radius: 0.6667vw;
    font-size: 1.1667vw;
    line-height: 1.75vw;
  }

  .product-wrapper > .detailed-product > .col > p.title {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 0.3333vw;
  }

  .product-wrapper > .detailed-product > .col > p.para {
    font-size: 1.0833vw;
    line-height: 1.4167vw;
    margin-bottom: 1.6667vw;
  }

  .product-wrapper > .recommended-upsells {
    border-top: 0.0833vw solid var(--oliver-border);
  }

  .product-wrapper > .recommended-upsells > p {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 0.8333vw;
  }

  .product-wrapper > .recommended-upsells > .button-row > button {
    width: 8.5833vw;
    height: 8.5833vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.6667vw;
    margin-right: 1.3333vw;
  }

  .product-wrapper > .recommended-upsells > .button-row > button > .prod-name {
    height: 1.8333vw;
    border-top: 0.0833vw solid var(--oliver-border);
  }

  .product-wrapper
    > .recommended-upsells
    > .button-row
    > button
    > .prod-name
    > p {
    font-size: 0.8333vw;
    line-height: 1.25vw;
  }

  .product-wrapper > .product-footer > .row {
    height: 7.25vw;
  }

  .product-wrapper > .product-footer > .row:nth-child(1) {
    border-bottom: 0.0833vw solid var(--oliver-border);
  }

  .product-wrapper > .product-footer > .row > button#addProductNote {
    border: 0.0833vw solid var(--oliver-blue);
    border-radius: 0.6667vw;
    font-size: 1.1667vw;
    line-height: 1.1667vw;
  }

  .product-wrapper > .product-footer > .row > button#addProductNote > img {
    width: 1.1667vw;
    height: 1.25vw;
    margin-right: 0.8333vw;
  }

  .product-wrapper > .product-footer > .row > button#addProductDiscount {
    border: 0.0833vw solid var(--oliver-blue);
    border-radius: 0.6667vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .product-wrapper > .product-footer > .row > .increment-input {
    width: 11.8333vw;
    margin-right: 1.9167vw;
  }

  .product-wrapper > .product-footer > .row > .increment-input > button {
    width: 3.3333vw;
    height: 3.3333vw;
    border-radius: 0.6667vw;
  }

  .product-wrapper
    > .product-footer
    > .row
    > .increment-input
    > button:nth-child(1)
    > img {
    width: 1.1667vw;
    height: 2.5vw;
  }

  .product-wrapper
    > .product-footer
    > .row
    > .increment-input
    > button:nth-child(3)
    > img {
    width: 1.3333vw;
    height: 2.5vw;
  }

  .product-wrapper > .product-footer > .row > .increment-input > input {
    height: 3.3333vw;
    font-size: 1.5vw;
    line-height: 2vw;
    border-radius: 0.6667vw;
  }

  .product-wrapper > .product-footer > .row > button#addProductToCart {
    height: 4.5833vw;
    border-radius: 0.6667vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .product-wrapper > .product-footer > .row > button#addProductToCart > img {
    width: 2.4167vw;
    height: 2.4167vw;
    margin-right: 0.6667vw;
  }

  .checkout-wrapper {
    grid-template-columns: 6vw 25vw auto;
    grid-template-rows: 5.6667vw auto;
  }

  .checkout-wrapper > .cart {
    border-left: 0.0833vw solid var(--oliver-border);
    border-top: 0.0833vw solid var(--oliver-border);
  }

  .checkout-wrapper > .checkout-body {
    border-left: 0.0833vw solid var(--oliver-border);
    border-top: 0.0833vw solid var(--oliver-border);
  }

  .checkout-wrapper > .header {
    padding: 0 1.4167vw 0 0.0833vw;
  }

  .checkout-wrapper > .header > button#exitCheckoutButton {
    width: 3.1667vw;
    height: 3.1667vw;
    margin-right: 0.6667vw;
  }

  .checkout-wrapper > .header > p {
    font-size: 1.6667vw;
    line-height: 2.4167vw;
  }

  .checkout-wrapper > .header > button#userInfoButton {
    width: 3.3333vw;
    height: 3.3333vw;
  }

  .checkout-wrapper
    > .page-options-wrapper
    > .page-options
    > button
    > .img-container {
    width: 2vw;
    height: 2vw;
    margin-right: 0.4167vw;
  }

  .checkout-wrapper > .page-options-wrapper > .page-options > button > p {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .checkout-wrapper > .checkout-body {
    padding: 5vw 5vw 3.3333vw 5vw;
  }

  .checkout-wrapper > .checkout-body > .balance-container {
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.6667vw;
    padding: 2.0833vw 2.5vw;
    margin-bottom: 0.75vw;
  }

  .checkout-wrapper > .checkout-body > .balance-container:hover {
    border-width: 0.1667vw;
  }

  .checkout-wrapper > .checkout-body > .balance-container > .row {
    margin-top: 0.25vw;
  }

  .checkout-wrapper > .checkout-body > .balance-container > .row:nth-child(1) {
    margin-bottom: 0.5833vw;
  }

  .checkout-wrapper > .checkout-body > .balance-container p.style1 {
    font-size: 1.6667vw;
    line-height: 2.3333vw;
  }

  .checkout-wrapper > .checkout-body > .balance-container p.style2 {
    font-size: 2.5vw;
    line-height: 3.5vw;
  }

  .checkout-wrapper > .checkout-body > .balance-container p.style3 {
    font-size: 1.3333vw;
    line-height: 1.8333vw;
  }

  .checkout-wrapper > .checkout-body > .balance-container p.style4 {
    font-size: 1.3333vw;
    line-height: 1.8333vw;
  }

  .checkout-wrapper > .checkout-body > #bottomText {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 2.5vw;
  }

  .checkout-wrapper > .checkout-body > p.style2 {
    font-size: 1.3333vw;
    line-height: 2vw;
    margin-bottom: 1vw;
  }

  .checkout-wrapper > .checkout-body > .button-row {
    margin-bottom: 1.5833vw;
  }

  .checkout-wrapper > .checkout-body > .button-row > button {
    height: 4.1667vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.6667vw;
    margin-left: 1.6667vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .checkout-wrapper
    > .checkout-body
    > .button-row
    > button:not(:disabled):hover {
    border-width: 0.1667vw;
  }

  .checkout-wrapper > .checkout-body > .button-row + p.style2 {
    padding-top: 0.9167vw;
  }

  .checkout-wrapper > .checkout-body > p.style3 {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-top: -0.6667vw;
    margin: -0.6667vw 0 1vw 0;
  }

  .checkout-wrapper > .checkout-body > .payment-types {
    margin-top: 2.5vw;
  }

  .checkout-wrapper > .checkout-body > .payment-types > p {
    font-size: 1.3333vw;
    line-height: 1.6667vw;
    margin-bottom: 1.1667vw;
  }

  .checkout-wrapper
    > .checkout-body
    > .payment-types
    > .button-container
    > button {
    width: calc((100% - 5vw) / 4);
    margin: 0 1.6667vw 1.6667vw 0;
    border-radius: 0.6667vw;
    font-size: 2.0833vw;
    line-height: 2.75vw;
  }

  .refund-wrapper {
    grid-template-columns: 6vw 25vw auto;
    grid-template-rows: 5.6667vw auto;
  }

  .refund-wrapper > .cart {
    border-left: 0.0833vw solid var(--oliver-border);
    border-top: 0.0833vw solid var(--oliver-border);
  }

  .refund-wrapper > .refund-body {
    border-left: 0.0833vw solid var(--oliver-border);
    border-top: 0.0833vw solid var(--oliver-border);
  }

  .refund-wrapper > .header {
    padding: 0 1.4167vw 0 0.0833vw;
  }

  .refund-wrapper > .header > button#exitCheckoutButton {
    width: 3.1667vw;
    height: 3.1667vw;
    margin-right: 0.6667vw;
  }

  .refund-wrapper > .header > p {
    font-size: 1.6667vw;
    line-height: 2.4167vw;
  }

  .refund-wrapper > .header > button#userInfoButton {
    width: 3.3333vw;
    height: 3.3333vw;
  }

  .refund-wrapper
    > .page-options-wrapper
    > .page-options
    > button
    > .img-container {
    width: 2vw;
    height: 2vw;
    margin-right: 0.4167vw;
  }

  .refund-wrapper > .page-options-wrapper > .page-options > button > p {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .refund-wrapper > .cart {
    border-bottom: 0.0833vw solid var(--oliver-border);
  }

  .refund-wrapper > .cart > .body > .cart-item > .increment-input {
    width: 10.8333vw;
    height: 3vw;
    margin-top: 0.8333vw;
    padding: 0.4167vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.5vw;
  }

  .refund-wrapper > .cart > .body > .cart-item > .increment-input > button {
    border-radius: 0.5vw;
  }

  .refund-wrapper
    > .cart
    > .body
    > .cart-item
    > .increment-input
    > button
    > img {
    width: 0.8333vw;
    height: 0.8333vw;
  }

  .refund-wrapper > .cart > .body > .cart-item > .increment-input > input {
    font-size: 1.3333vw;
    line-height: 1.75vw;
  }

  .refund-wrapper > .cart > .footer > .totals > .row > button {
    font-size: 1.3333vw;
    line-height: 1.8333vw;
  }

  .refund-wrapper > .cart > .footer > .totals > .row > p.type2 {
    font-size: 1.3333vw;
    line-height: 1.8333vw;
  }

  .refund-wrapper > .refund-body {
    padding: 5vw 5vw 3.3333vw 5vw;
  }

  .refund-wrapper > .refund-body > .balance-container {
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.6667vw;
    padding: 2.0833vw 2.5vw;
    margin-bottom: 0.75vw;
    margin-bottom: 2.5vw;
  }

  .refund-wrapper > .refund-body > .balance-container > .row {
    margin-top: 0.25vw;
  }

  .refund-wrapper > .refund-body > .balance-container > .row:nth-child(1) {
    margin-bottom: 0.5833vw;
  }

  .refund-wrapper > .refund-body > .balance-container p.style1 {
    font-size: 1.6667vw;
    line-height: 2.3333vw;
  }

  .refund-wrapper > .refund-body > .balance-container p.style2 {
    font-size: 2.5vw;
    line-height: 3.5vw;
  }

  .refund-wrapper > .refund-body > .balance-container p.style3 {
    font-size: 1.3333vw;
    line-height: 1.8333vw;
  }

  .refund-wrapper > .refund-body > .balance-container p.style4 {
    font-size: 1.3333vw;
    line-height: 1.8333vw;
  }

  .refund-wrapper > .refund-body > p.style2 {
    font-size: 1.3333vw;
    line-height: 2vw;
    margin-bottom: 1vw;
  }

  .refund-wrapper > .refund-body > .button-row {
    margin-bottom: 1.5833vw;
  }

  .refund-wrapper > .refund-body > .button-row > button {
    height: 4.1667vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.6667vw;
    margin-left: 1.6667vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .refund-wrapper > .refund-body > .button-row > button:not(:disabled):hover {
    border-width: 0.1667vw;
  }

  .refund-wrapper > .refund-body > .button-row + p.style2 {
    padding-top: 0.9167vw;
  }

  .refund-wrapper > .refund-body > p.style3 {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-top: -0.6667vw;
    margin: -0.6667vw 0 1vw 0;
  }

  .refund-wrapper > .refund-body > .payment-types {
    margin-top: 2.5vw;
  }

  .refund-wrapper > .refund-body > .payment-types > p {
    font-size: 1.3333vw;
    line-height: 1.6667vw;
    margin-bottom: 1.1667vw;
  }

  .refund-wrapper > .refund-body > .payment-types > .button-container > button {
    width: calc((100% - 5vw) / 4);
    margin: 0 1.6667vw 1.6667vw 0;
    border-radius: 0.6667vw;
    font-size: 2.0833vw;
    line-height: 2.75vw;
  }

  .sale-complete-wrapper > .main {
    padding: 1.6667vw 0;
  }

  .sale-complete-wrapper > .main > img {
    width: 4.1667vw;
    height: 4.1667vw;
    margin: auto 0 1.5vw 0;
  }

  .sale-complete-wrapper > .main > p {
    font-size: 1.1667vw;
    line-height: 1.5vw;
    margin-bottom: 0.6667vw;
  }

  .sale-complete-wrapper > .main > #loadingDiv {
    margin-bottom: 3.5vw;
  }

  .sale-complete-wrapper > .main > #loadingDiv > img {
    width: 1.9167vw;
    height: 1.9167vw;
    margin-right: 0.6667vw;
  }

  .sale-complete-wrapper > .main > #loadingDiv > p {
    font-size: 1.3333vw;
    line-height: 1.75vw;
  }

  .sale-complete-wrapper > .main > button#openUpdateTransactionStatus {
    padding: 0.6667vw;
    font-size: 1.6667vw;
    line-height: 2.1667vw;
    margin-bottom: 3.5vw;
  }

  .sale-complete-wrapper > .main > button#openUpdateTransactionStatus > img {
    width: 1.25vw;
    height: 1.25vw;
    margin-left: 0.6667vw;
  }

  .sale-complete-wrapper > .main > .change-container {
    width: 40.8333vw;
    height: 8.6667vw;
    border-radius: 0.8333vw;
    margin-bottom: 4.1667vw;
  }

  .sale-complete-wrapper > .main > .change-container > p.style1 {
    font-size: 1.8333vw;
    line-height: 2.4167vw;
    margin-bottom: 0.5833vw;
  }

  .sale-complete-wrapper > .main > .change-container > p.style2 {
    font-size: 1.3333vw;
    line-height: 1.75vw;
  }

  .sale-complete-wrapper > .main > label.email-label {
    margin-bottom: 1.6667vw;
  }

  .sale-complete-wrapper > .main > label.email-label > input {
    width: 40.8333vw;
    height: 4.6667vw;
    border-radius: 0.6667vw;
    padding: 0 5.75vw 0 1.5833vw;
    font-size: 1.1667vw;
    line-height: 1.5vw;
  }

  .sale-complete-wrapper > .main > label.email-label > button {
    right: 0.5vw;
    top: 0.5vw;
    width: 9.8333vw;
    height: 3.6667vw;
    border-radius: 0.5vw;
    font-size: 1.1667vw;
    line-height: 1.5vw;
  }

  .sale-complete-wrapper > .main > label.checkbox-label {
    font-size: 1.3333vw;
    line-height: 1.75vw;
    margin-bottom: 3.5833vw;
  }

  .sale-complete-wrapper > .main > label.checkbox-label > input {
    left: -833.3333vw;
  }

  .sale-complete-wrapper > .main > label.checkbox-label > .custom-checkbox {
    width: 2vw;
    height: 2vw;
    border-radius: 0.5833vw;
    margin-left: 0.6667vw;
    border: 0.1667vw solid #197bc1;
  }

  .sale-complete-wrapper
    > .main
    > label.checkbox-label
    > .custom-checkbox
    > img {
    width: 1.3333vw;
    height: 1.3333vw;
  }

  .sale-complete-wrapper > .main > button {
    width: 19.8333vw;
    height: 4.6667vw;
    border: 0.0833vw solid #fff;
    border-radius: 0.6667vw;
    font-size: 1.3333vw;
    line-height: 2vw;
  }

  .sale-complete-wrapper > .footer {
    padding-top: 1.6667vw;
    padding: 0 3.1667vw 3.1667vw 3.1667vw;
  }

  .sale-complete-wrapper > .footer > .button-container {
    width: 16.4167vw;
  }

  .sale-complete-wrapper > .footer > .button-container > button#endSession {
    width: 16.6667vw;
    height: 4.6667vw;
    border: 0.0833vw solid #fff;
    border-radius: 0.5vw;
    font-size: 1.3333vw;
    line-height: 1.75vw;
  }

  .sale-complete-wrapper
    > .footer
    > .button-container
    > button#endSession
    > img {
    width: 2.1667vw;
    height: 1.8333vw;
    margin-right: 0.4167vw;
  }

  .sale-complete-wrapper > .footer > .button-container > button#newSale {
    width: 16.6667vw;
    height: 4.6667vw;
    border-radius: 0.5vw;
    font-size: 1.6667vw;
    line-height: 2.1667vw;
  }

  .sale-complete-wrapper > .footer > .app-container {
    padding: 1.25vw 1.25vw 0 1.25vw;
    border-radius: 1.25vw;
    max-width: 24.5833vw;
  }

  .sale-complete-wrapper > .footer > .app-container > button {
    width: 4.5833vw;
    height: 4.5833vw;
    border-radius: 0.5833vw;
    margin: 0 1.25vw 1.25vw 0;
  }

  .refund-complete-wrapper > .main > img {
    width: 16.0833vw;
    height: 7.75vw;
    margin: 1.6667vw 0 3.75vw 0;
  }

  .refund-complete-wrapper > .main > label.email-label {
    margin-bottom: 1.6667vw;
  }

  .refund-complete-wrapper > .main > label.email-label > input {
    width: 40.8333vw;
    height: 4.6667vw;
    border-radius: 0.6667vw;
    padding: 0 5.75vw 0 1.5833vw;
    font-size: 1.1667vw;
    line-height: 1.5vw;
  }

  .refund-complete-wrapper > .main > label.email-label > button {
    right: 0.5vw;
    top: 0.5vw;
    width: 9.8333vw;
    height: 3.6667vw;
    border-radius: 0.5vw;
    font-size: 1.1667vw;
    line-height: 1.5vw;
  }

  .refund-complete-wrapper > .main > label.checkbox-label {
    font-size: 1.3333vw;
    line-height: 1.75vw;
    margin-bottom: 3.5833vw;
  }

  .refund-complete-wrapper > .main > label.checkbox-label > input {
    left: -833.3333vw;
  }

  .refund-complete-wrapper > .main > label.checkbox-label > .custom-checkbox {
    width: 2vw;
    height: 2vw;
    border-radius: 0.5833vw;
    margin-left: 0.6667vw;
    border: 0.1667vw solid var(--error);
  }

  .refund-complete-wrapper
    > .main
    > label.checkbox-label
    > .custom-checkbox
    > img {
    width: 1.3333vw;
    height: 1.3333vw;
  }

  .refund-complete-wrapper > .main > button {
    width: 19.8333vw;
    height: 4.6667vw;
    border: 0.0833vw solid #fff;
    border-radius: 0.6667vw;
    font-size: 1.3333vw;
    line-height: 2vw;
  }

  .refund-complete-wrapper > .footer {
    padding: 1.6667vw 3.1667vw;
  }

  .refund-complete-wrapper > .footer > .button-container {
    width: 16.4167vw;
  }

  .refund-complete-wrapper > .footer > .button-container > button#endSession {
    width: 16.6667vw;
    height: 4.6667vw;
    border: 0.0833vw solid #fff;
    border-radius: 0.5vw;
    font-size: 1.3333vw;
    line-height: 1.75vw;
  }

  .refund-complete-wrapper
    > .footer
    > .button-container
    > button#endSession
    > img {
    width: 2.1667vw;
    height: 1.8333vw;
    margin-right: 0.4167vw;
  }

  .refund-complete-wrapper > .footer > .button-container > button#newSale {
    width: 16.6667vw;
    height: 4.6667vw;
    border-radius: 0.5vw;
    font-size: 1.6667vw;
    line-height: 2.1667vw;
  }

  .refund-complete-wrapper > .footer > .app-container {
    padding: 1.25vw 1.25vw 0 1.25vw;
    border-radius: 1.25vw;
    max-width: 24.5833vw;
  }

  .refund-complete-wrapper > .footer > .app-container > button {
    width: 4.5833vw;
    height: 4.5833vw;
    border-radius: 0.5833vw;
    margin: 0 1.25vw 1.25vw 0;
  }

  .customer-view-wrapper {
    grid-template-columns: 6vw max(27.3333vw, 25vw) max(27.3333vw, 25vw) 1fr;
  }

  .customer-view-wrapper > .cv-search {
    border-left: 0.0833vw solid var(--oliver-border);
  }

  .customer-view-wrapper > .cv-list {
    border-left: 0.0833vw solid var(--oliver-border);
  }

  .customer-view-wrapper > .cv-detailed {
    border-left: 0.0833vw solid var(--oliver-border);
  }

  .cv-search > .header {
    height: 5.6667vw;
    padding: 0 1.0833vw 0 1.8333vw;
    border-bottom: 0.0833vw solid var(--oliver-border);
  }

  .cv-search > .header > p {
    font-size: 1.6667vw;
    line-height: 2.4167vw;
  }

  .cv-search > .header > button {
    width: 9.1667vw;
    height: 3vw;
    border-radius: 0.6667vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .cv-search > .header > button > img {
    width: 1.5vw;
    height: 1.5vw;
    margin-right: 0.6667vw;
  }

  .cv-search > .body {
    padding: 1.25vw 1.8333vw;
  }

  .cv-search > .body > .row {
    margin-bottom: 1.0833vw;
  }

  .cv-search > .body > .row > img {
    width: 1.6667vw;
    height: 1.6667vw;
    margin-right: 0.8333vw;
  }

  .cv-search > .body > .row > p {
    font-size: 1.3333vw;
    line-height: 1.9167vw;
  }

  .cv-search > .body > .row > button {
    padding: 0.4167vw;
    transform: translateY(-0.4167vw);
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .cv-search > .body > label {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 0.3333vw;
  }

  .cv-search > .body > input {
    height: 3.6667vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.5vw;
    padding: 0 1vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 1.6667vw;
  }

  .cv-search > .body > button {
    height: 3.6667vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .cv-list > .header {
    height: 5.6667vw;
    padding: 0 1.3333vw;
    border-bottom: 0.0833vw solid var(--oliver-border);
  }

  .cv-list > .header > p {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin: 0 1vw 0 auto;
  }

  .cv-list > .header > .sort-wrapper {
    width: 12.9167vw;
    height: 3vw;
  }

  .cv-list > .header > .sort-wrapper.open {
    box-shadow: 0vw 0.5vw 0.8333vw rgba(94, 94, 94, 0.1);
  }

  .cv-list > .header > .sort-wrapper > img {
    top: calc(50% - 0.5vw);
    right: 0.75vw;
    width: 1.0833vw;
    height: 1.0833vw;
  }

  .cv-list > .header > .sort-wrapper.open > img {
    transform: rotate(180deg) translateY(-0.1667vw);
  }

  .cv-list > .header > .sort-wrapper > input {
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.6667vw;
    padding: 0 2.0833vw 0 0.8333vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .cv-list > .header > .sort-wrapper.open > input {
    border-radius: 0.6667vw 0.6667vw 0 0;
  }

  .cv-list > .header > .sort-wrapper > .option-container > .option {
    height: 3vw;
    border: 0.0833vw solid var(--oliver-border);
    padding: 0 0.8333vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .cv-list > .header > .sort-wrapper > .option-container > .option:last-child {
    border-radius: 0 0 0.6667vw 0.6667vw;
  }

  .cv-list > .body > .no-search-results-list {
    margin: 6.9167vw auto;
  }

  .cv-list > .body > .no-search-results-list > p.style1 {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 0.8333vw;
  }

  .cv-list > .body > .no-search-results-list > p.style2 {
    font-size: 1.0833vw;
    line-height: 1.5vw;
  }

  .cv-list > .body > .filter-name {
    height: 2.5vw;
    border-bottom: 0.0833vw solid var(--oliver-border);
    padding: 0 1.3333vw;
  }

  .cv-list > .body > .filter-name > p {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .cv-list > .body > button.customer-card {
    height: 7.8333vw;
    border-bottom: 0.0833vw solid var(--oliver-border);
    padding: 0 1.3333vw;
  }

  .cv-list > .body > button.customer-card > .avatar {
    width: 3vw;
    height: 3vw;
    margin-right: 1.6667vw;
  }

  .cv-list > .body > button.customer-card > .text-group > p.style1 {
    font-size: 1.3333vw;
    line-height: 2vw;
  }

  .cv-list > .body > button.customer-card > .text-group > p.style2 {
    font-size: 1vw;
    line-height: 1.4167vw;
  }

  .cv-list > .body > button.customer-card > .selected-indicator {
    width: 0.5833vw;
    height: 6.3333vw;
    top: calc(50% - 3.1667vw);
    border-radius: 0 0.3333vw 0.3333vw 0;
  }

  .cv-detailed {
    padding: 0 1.9167vw;
  }

  .cv-detailed > .no-search-results-detailed > p.style1 {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 0.8333vw;
  }

  .cv-detailed > .no-search-results-detailed > p.style2 {
    font-size: 1.0833vw;
    line-height: 1.5vw;
  }

  .cv-detailed > .quick-info {
    padding: 1.25vw;
    margin: 1.6667vw 0 1.4167vw 0;
  }

  .cv-detailed > .quick-info > .avatar {
    width: 3.3333vw;
    height: 3.3333vw;
    margin: 0.3333vw 1.5833vw 0 0;
  }

  .cv-detailed > .quick-info > .text-group > p.style1 {
    font-size: 1.3333vw;
    line-height: 2vw;
  }

  .cv-detailed > .quick-info > .text-group > p.style2 {
    font-size: 1vw;
    line-height: 1.4167vw;
  }

  .cv-detailed > .quick-info > button {
    width: 5.8333vw;
    height: 3vw;
    border: 0.0833vw solid var(--oliver-blue);
    border-radius: 0.6667vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .cv-detailed > .quick-info > button > img {
    width: 1.25vw;
    height: 1.3333vw;
    margin-right: 0.4167vw;
  }

  .cv-detailed > .cust-totals {
    height: 6.9167vw;
    padding-bottom: 1.8333vw;
    border-bottom: 0.0833vw solid var(--oliver-border);
  }

  .cv-detailed > .cust-totals > .col {
    border-left: 0.0833vw solid var(--oliver-border);
  }

  .cv-detailed > .cust-totals > .col > p.style1 {
    font-size: 1.8333vw;
    line-height: 2.25vw;
    margin-bottom: 0.3333vw;
  }

  .cv-detailed > .cust-totals > .col > p.style2 {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .cv-detailed > .cust-totals > .col > button {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .cv-detailed > .cust-totals > .col > button > img {
    width: 1.3333vw;
    height: 1.3333vw;
    margin-left: 0.4167vw;
  }

  .cv-detailed > .bill-ship-info {
    padding: 1.6667vw 0;
    border-bottom: 0.0833vw solid var(--oliver-border);
  }

  .cv-detailed > .bill-ship-info > .col:nth-child(1) {
    margin-right: 3.3333vw;
  }

  .cv-detailed > .bill-ship-info > .col > p.style1 {
    font-size: 1.3333vw;
    line-height: 2vw;
    margin-bottom: 0.6667vw;
  }

  .cv-detailed > .bill-ship-info > .col > p.style2 {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .cv-detailed > .cust-notes {
    width: calc(100% + 3.8333vw);
    min-height: 8.3333vw;
    padding: 1.6667vw 0;
    margin: 0 -1.9167vw;
  }

  .cv-detailed > .cust-notes > .header-row {
    width: calc(100% - 3.8333vw);
    margin-bottom: 0.9167vw;
  }

  .cv-detailed > .cust-notes > .header-row > p {
    font-size: 1.3333vw;
    line-height: 2vw;
  }

  .cv-detailed > .cust-notes > .header-row > button {
    font-size: 1.1667vw;
    line-height: 1.75vw;
  }

  .cv-detailed > .cust-notes > .customer-note {
    width: calc(100% - 3.8333vw);
    margin-top: 1.6667vw;
  }

  .cv-detailed > .cust-notes > .customer-note > .row > p.style1 {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .cv-detailed > .cust-notes > .customer-note > .row > p.style2 {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-right: 0.5vw;
  }

  .cv-detailed > .cust-notes > .customer-note > .row > button {
    width: 1.4167vw;
    height: 1.4167vw;
  }

  .cv-detailed > .cust-notes > .customer-note > .row > button > img {
    width: 1.3333vw;
    height: 1.3333vw;
  }

  .cv-detailed > .cust-notes > .customer-note > p {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin: 0.8333vw 0.5vw 0 0.8333vw;
  }

  .cv-detailed > .footer {
    height: 6.1667vw;
    border-top: 0.0833vw solid var(--oliver-border);
  }

  .cv-detailed > .footer > button {
    height: 3.6667vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .cv-detailed > .footer > button:first-of-type {
    margin-right: 1.0833vw;
  }

  .transactions-wrapper {
    grid-template-columns: 6vw max(27.3333vw, 25vw) max(27.3333vw, 25vw) 1fr;
  }

  .transactions-wrapper > .transactions-search {
    border-left: 0.0833vw solid var(--oliver-border);
  }

  .transactions-wrapper > .transactions-list {
    border-left: 0.0833vw solid var(--oliver-border);
  }

  .transactions-wrapper > .transactions-detailed {
    border-left: 0.0833vw solid var(--oliver-border);
  }

  .transactions-search > .search-header {
    height: 5.6667vw;
    border-bottom: 0.0833vw solid var(--oliver-border);
    padding: 0 1.3333vw 0 2.0833vw;
  }

  .transactions-search > .search-header > p {
    font-size: 1.6667vw;
    line-height: 2.4167vw;
  }

  .transactions-search > .search-header > button {
    width: 4.8333vw;
    height: 3vw;
    border-radius: 0.6667vw;
    padding: 0.3333vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .transactions-search > .search-body {
    padding: 0 1.8333vw;
  }

  .transactions-search > .search-body label {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 0.3333vw;
  }

  .transactions-search > .search-body > label:first-of-type {
    margin-top: 1.0833vw;
  }

  .transactions-search > .search-body input {
    height: 3.6667vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.5vw;
    padding: 0 1vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 1.6667vw;
  }

  .transactions-search > .search-body > input:first-of-type {
    margin-bottom: 0.5833vw;
  }

  .transactions-search > .search-body > p {
    font-size: 1vw;
    line-height: 1.4167vw;
  }

  .transactions-search > .search-body > .divider {
    height: 0.0833vw;
    margin: 1.6667vw 0;
  }

  .transactions-search > .search-body > .dropdown-wrapper {
    height: 3.6667vw;
    margin-bottom: 1.6667vw;
  }

  .transactions-search > .search-body > .dropdown-wrapper.open {
    box-shadow: 0vw 0.8333vw 1.6667vw rgba(119, 135, 147, 0.3);
  }

  .transactions-search > .search-body > .dropdown-wrapper > img {
    right: 1.1667vw;
    top: calc(50% - 0.75vw);
    width: 1.5vw;
    height: 1.5vw;
  }

  .transactions-search > .search-body > .dropdown-wrapper.open > input {
    border-radius: 0.5vw 0.5vw 0 0;
  }

  .transactions-search > .search-body > .dropdown-wrapper > .option-list {
    max-height: 14.6667vw;
    border-radius: 0 0 0.5833vw 0.5833vw;
  }

  .transactions-search
    > .search-body
    > .dropdown-wrapper
    > .option-list
    > .option {
    height: 3.6667vw;
    padding: 0 1vw;
    border: 0.0833vw solid var(--oliver-border);
  }

  .transactions-search
    > .search-body
    > .dropdown-wrapper
    > .option-list
    > .option:last-child {
    border-radius: 0 0 0.5833vw 0.5833vw;
  }

  .transactions-search
    > .search-body
    > .dropdown-wrapper
    > .option-list
    > .option
    > p {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .transactions-search > .search-body > .input-row > .input-col:first-child {
    margin-right: 1.5vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper {
    margin-bottom: 1.6667vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > input {
    padding-right: 2.6667vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > button {
    right: 1vw;
    top: calc(50% - 0.8333vw);
    width: 1.6667vw;
    height: 1.6667vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector {
    padding: 2.0833vw 1.6667vw;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.5vw;
    margin-top: 0.5833vw;
    filter: drop-shadow(0vw 0.8333vw 1.6667vw rgba(119, 135, 147, 0.1));
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .header-row {
    width: 23.3333vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .header-row
    > button {
    height: 2.5vw;
    padding: 0 0.8333vw;
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .header-row
    > button
    > img {
    width: 3.3333vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .header-row
    > div {
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .day-row {
    width: 23.3333vw;
    border-bottom: 0.0833vw solid var(--oliver-border);
    margin-bottom: 0.8333vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .day-row
    > .day {
    height: 3.3333vw;
    font-size: 1.3333vw;
    line-height: 2vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .date-row {
    width: 23.3333vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .date-row
    > button.cell {
    height: 3.3333vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    border-radius: 0.5vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .month-row {
    width: 26.6667vw;
    margin-top: 1.25vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .month-row
    > button.cell {
    height: 3.3333vw;
    font-size: 1.3333vw;
    line-height: 1.6667vw;
    border-radius: 0.5vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .year-row {
    width: 23.3333vw;
    margin-top: 1.25vw;
  }

  .transactions-search
    > .search-body
    > .input-row
    > .input-col
    > .date-selector-wrapper
    > .date-selector
    > .year-row
    > button.cell {
    height: 3.3333vw;
    font-size: 1.3333vw;
    line-height: 1.6667vw;
    border-radius: 0.5vw;
  }

  .transactions-search > .search-body > button {
    height: 3.75vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
    margin: auto 0 1.25vw 0;
  }

  .transactions-list > .header {
    height: 5.6667vw;
    border-bottom: 0.0833vw solid var(--oliver-border);
    padding: 0 1.6667vw;
  }

  .transactions-list > .header > p {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .transactions-list > .header > .sort-wrapper {
    width: 12.9167vw;
    height: 3vw;
  }

  .transactions-list > .header > .sort-wrapper.open {
    box-shadow: 0vw 0.5vw 0.8333vw rgba(94, 94, 94, 0.1);
  }

  .transactions-list > .header > .sort-wrapper > img {
    top: calc(50% - 0.5vw);
    right: 0.75vw;
    width: 1.0833vw;
    height: 1.0833vw;
  }

  .transactions-list > .header > .sort-wrapper.open > img {
    transform: rotate(180deg) translateY(-0.1667vw);
  }

  .transactions-list > .header > .sort-wrapper > input {
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.6667vw;
    padding: 0 2.0833vw 0 0.8333vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .transactions-list > .header > .sort-wrapper.open > input {
    border-radius: 0.6667vw 0.6667vw 0 0;
  }

  .transactions-list > .header > .sort-wrapper > .option-container > .option {
    height: 3vw;
    border: 0.0833vw solid var(--oliver-border);
    padding: 0 0.8333vw;
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .transactions-list
    > .header
    > .sort-wrapper
    > .option-container
    > .option:last-child {
    border-radius: 0 0 0.6667vw 0.6667vw;
  }

  .transactions-list > .body > .no-results {
    margin-top: 4.8333vw;
  }

  .transactions-list > .body > .no-results > p.style1 {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 0.8333vw;
  }

  .transactions-list > .body > .no-results > p.style2 {
    font-size: 1.0833vw;
    line-height: 1.5vw;
  }

  .transactions-list > .body > .filter-name {
    height: 2.5vw;
    border-top: 0.0833vw solid var(--oliver-border);
    padding: 0 1.3333vw;
  }

  .transactions-list > .body > .filter-name > p {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .transactions-list > .body > :last-child {
    border-bottom: 0.0833vw solid var(--oliver-border);
  }

  .transactions-list > .body > button {
    height: 8vw;
    border-top: 0.0833vw solid var(--oliver-border);
    padding: 0.8333vw 1.6667vw 0.8333vw 2vw;
  }

  .transactions-list > .body > button > .col:first-child {
    margin-right: 0.8333vw;
  }

  .transactions-list > .body > button > .col > p.style1 {
    font-size: 1.3333vw;
    line-height: 2vw;
  }

  .transactions-list > .body > button > .col > p.style2 {
    font-size: 1vw;
    line-height: 1.4167vw;
  }

  .transactions-list > .body > button > .col > .row > img {
    width: 1.1667vw;
    height: 1vw;
    margin-right: 0.6667vw;
  }

  .transactions-list > .body > button > .col > .row > p {
    max-width: calc(100% - 1.8333vw);
    font-size: 1vw;
    line-height: 1.4167vw;
  }

  .transactions-list > .body > button > .col > p.style3 {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .transactions-list > .body > button > .col > p.style4 {
    font-size: 1vw;
    line-height: 1.4167vw;
    padding-right: 0.08333vw;
  }

  .transactions-list > .body > button > .selected-indicator {
    top: calc(50% - 3.1667vw);
    width: 0.5833vw;
    height: 6.3333vw;
    border-radius: 0 0.3333vw 0.3333vw 0;
  }

  .transactions-detailed > .no-orders-detailed > p.style1 {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 0.8333vw;
  }

  .transactions-detailed > .no-orders-detailed > p.style2 {
    font-size: 1.0833vw;
    line-height: 1.5vw;
  }

  .transactions-detailed > .scrollable > .quick-info {
    width: calc(100% - 3.3333vw);
    padding: 1.25vw;
    margin-top: 1.25vw;
  }

  .transactions-detailed > .scrollable > .quick-info > .row {
    margin-top: 0.4167vw;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > p.style1 {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > p.style2 {
    font-size: 1.0833vw;
    line-height: 1.5vw;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > p.style3 {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > .group > img {
    width: 1.5vw;
    height: 1.3333vw;
    margin-right: 0.6667vw;
  }

  .transactions-detailed > .scrollable > .quick-info > .row > .group > p {
    font-size: 1.3333vw;
    line-height: 1.75vw;
  }

  .transactions-detailed > .scrollable > .customer-info {
    width: calc(100% - 3.3333vw);
    padding: 1.25vw 0 1.6667vw 0;
    border-bottom: 0.0833vw solid var(--oliver-border);
  }

  .transactions-detailed > .scrollable > .customer-info > .col > p.style1 {
    font-size: 1.3333vw;
    line-height: 2vw;
    margin-bottom: 0.6667vw;
  }

  .transactions-detailed > .scrollable > .customer-info > .col > p.style2 {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-bottom: 0.3333vw;
  }

  .transactions-detailed > .scrollable > .customer-info > button {
    width: 12.8333vw;
    height: 3.3333vw;
    border: 0.0833vw solid var(--oliver-blue);
    border-radius: 0.5vw;
    font-size: 1.3333vw;
    line-height: 2vw;
  }

  .transactions-detailed > .scrollable > .order-details {
    padding: 1.25vw 1.6667vw;
    min-height: 8.3333vw;
  }

  .transactions-detailed > .scrollable > .order-details > p {
    font-size: 1.3333vw;
    line-height: 2vw;
    margin-bottom: 2vw;
  }

  .transactions-detailed > .scrollable > .order-details > .item {
    margin-top: 1.6667vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .img-container {
    width: 5.6667vw;
    height: 5.75vw;
    margin-right: 2vw;
    border-radius: 0.3333vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .img-container
    > .quantity {
    min-width: 2.1667vw;
    height: 2.1667vw;
    padding: 0 0.25vw;
    right: 0vw;
    top: 0vw;
    border-radius: 0.5vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .img-container
    > .quantity
    > p {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .img-container
    > img {
    border-radius: 0.3333vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .main-row
    > p {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .item-fields {
    padding-top: 0.5vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .item-fields
    > p {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .modifier-fields {
    padding: 0.6667vw 0 0 1.0833vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .modifier-fields
    > .row
    > p {
    font-size: 1.1667vw;
    line-height: 1.5833vw;
    padding-right: 0.8333vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .product-total-fields {
    padding-top: 0.6667vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .product-total-fields
    > div {
    padding-top: 0.5vw;
    border-top: 0.0833vw solid var(--oliver-border);
    margin-top: 0.5vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .col
    > .product-total-fields
    > div
    > .row
    > p {
    font-size: 1.1667vw;
    line-height: 1.5833vw;
    padding-right: 0.8333vw;
  }

  .transactions-detailed > .scrollable > .order-details > .custom-fields {
    margin-top: 1.25vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .custom-fields
    > p.style1 {
    font-size: 1.3333vw;
    line-height: 2vw;
    margin-bottom: 0.5833vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .custom-fields
    > p.style2 {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-left: 1.1667vw;
    max-width: calc(100% - 1.1667vw);
    margin-top: 0.25vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper {
    padding: 1.25vw 0;
    border-top: 0.1667vw solid var(--oliver-border);
    margin-top: 1.25vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals {
    width: min(33.3333vw, 75%);
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row {
    padding-bottom: 0.5vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row.border-bottom {
    border-bottom: 0.0833vw solid var(--oliver-border);
    margin-bottom: 0.9167vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row.border-top {
    padding: 0.5vw 0 0 0;
    border-top: 0.0833vw solid var(--oliver-border);
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row
    > p {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .transaction-totals-wrapper
    > .transaction-totals
    > .row
    > p.style2 {
    font-size: 1vw;
    line-height: 1.4167vw;
  }

  .transactions-detailed > .scrollable > .order-details > .refund-header {
    padding: 0.4167vw 0.6667vw;
    margin: 2.0833vw 0 1.25vw 0;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .refund-header
    > p.style1 {
    font-size: 1.1667vw;
    line-height: 1.75vw;
    padding-right: 0.8333vw;
  }

  .transactions-detailed
    > .scrollable
    > .order-details
    > .refund-header
    > p.style2 {
    font-size: 1vw;
    line-height: 1.5vw;
  }

  .transactions-detailed > .footer {
    padding: 1.25vw 1.6667vw;
    border-top: 0.0833vw solid var(--oliver-border);
  }

  .transactions-detailed > .footer > button {
    height: 3.6667vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
    margin-left: 1vw;
  }

  .cash-management-wrapper {
    grid-template-columns: 6vw 33vw 1fr;
  }

  .cash-management-wrapper > .cm-list {
    border-left: 0.0833vw solid var(--oliver-border);
  }

  .cash-management-wrapper > .cm-detailed {
    border-left: 0.0833vw solid var(--oliver-border);
  }

  .cm-list > .cm-list-header-landscape {
    height: 5.6667vw;
    padding: 0 1.6667vw;
    border-bottom: 0.0833vw solid var(--oliver-border);
  }

  .cm-list > .cm-list-header-landscape > p {
    font-size: 1.6667vw;
    line-height: 2.4167vw;
  }

  .cm-list > .cm-list-body > .current-register {
    height: 8.5833vw;
    border-bottom: 0.0833vw solid var(--oliver-border);
    padding: 0 1.6667vw;
  }

  .cm-list > .cm-list-body > .current-register > .text-row {
    margin-bottom: 0.3333vw;
  }

  .cm-list > .cm-list-body > .current-register > .text-row > p {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
    margin-right: 0.5833vw;
  }

  .cm-list > .cm-list-body > .current-register > p.style1 {
    font-size: 1.25vw;
    line-height: 1.75vw;
    margin-bottom: 0.4167vw;
  }

  .cm-list > .cm-list-body > .current-register > p.style2 {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .cm-list > .cm-list-body > .date {
    height: 2.5vw;
    border-bottom: 0.0833vw solid var(--oliver-border);
    padding: 0 1vw;
  }

  .cm-list > .cm-list-body > .date > p {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .cm-list > .cm-list-body > .other-register {
    height: 6.3333vw;
    border-bottom: 0.0833vw solid var(--oliver-border);
    padding: 0 1.8333vw;
  }

  .cm-list > .cm-list-body > .other-register.selected::before {
    width: 0.5833vw;
    height: 5vw;
    top: 0.6667vw;
    border-radius: 0 0.3333vw 0.3333vw 0;
  }

  .cm-list > .cm-list-body > .other-register > .row:nth-child(1) {
    margin-bottom: 0.3333vw;
  }

  .cm-list > .cm-list-body > .other-register > .row > p.style1 {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .cm-list > .cm-list-body > .other-register > .row > p.style2 {
    font-size: 1.1667vw;
    line-height: 1.6667vw;
  }

  .cm-detailed > .detailed-header-landscape {
    width: calc(100% - 3.3333vw);
    height: 5.5833vw;
  }

  .cm-detailed > .detailed-header-landscape > img {
    width: 1.8333vw;
    height: 1.8333vw;
    margin-right: 0.6667vw;
  }

  .cm-detailed > .detailed-header-landscape > p {
    font-size: 1.5vw;
    line-height: 2.25vw;
  }

  .cm-detailed > .detailed-quick-info {
    width: calc(100% - 3.3333vw);
    padding: 1.25vw;
  }

  .cm-detailed > .detailed-quick-info > .row:first-child {
    margin-bottom: 0.8333vw;
  }

  .cm-detailed > .detailed-quick-info > .row > p {
    font-size: 1.1667vw;
    line-height: 1.75vw;
  }

  .cm-detailed > .detailed-quick-info > .row > .row-group > p {
    font-size: 1.3333vw;
    line-height: 2vw;
    margin-right: 0.8333vw;
  }

  .cm-detailed > .detailed-quick-info > .row > .row-group > .status {
    height: 2vw;
    padding: 0 0.6667vw;
    border-radius: 0.5vw;
    font-size: 1vw;
    line-height: 1.5vw;
  }

  .cm-detailed > .detailed-quick-info > .row > .col-group > p.style1 {
    font-size: 1.0833vw;
    line-height: 1.5833vw;
  }

  .cm-detailed > .detailed-quick-info > .row > .col-group > p.style2 {
    font-size: 1.3333vw;
    line-height: 2vw;
  }

  .cm-detailed > .detailed-quick-info > .row > button {
    width: 7.3333vw;
    height: 3.3333vw;
    border: 0.0833vw solid var(--oliver-blue);
    border-radius: 0.6667vw;
    font-size: 1.3333vw;
    line-height: 2vw;
  }

  .cm-detailed > .detailed-quick-info > .row > button > img {
    width: 1.6667vw;
    height: 1.6667vw;
    margin-right: 0.6667vw;
  }

  .cm-detailed > .detailed-body {
    padding: 0 1.6667vw;
  }

  .cm-detailed > .detailed-body > .action {
    padding: 1.25vw 0;
    border-top: 0.0833vw solid var(--oliver-border);
  }

  .cm-detailed > .detailed-body > .action > .header-row > p {
    font-size: 1.1667vw;
    line-height: 1.75vw;
  }

  .cm-detailed > .detailed-body > .action > .header-row > p.style2 {
    font-size: 1.0833vw;
    line-height: 1.75vw;
  }

  .cm-detailed > .detailed-body > .action > .body-col > p {
    font-size: 1.0833vw;
    line-height: 1.6667vw;
  }

  .cm-detailed > .detailed-body > .action > .footer-col {
    margin-top: 0.8333vw;
  }

  .cm-detailed > .detailed-body > .action > .footer-col > p {
    font-size: 1.0833vw;
    line-height: 1.6667vw;
  }

  .cm-detailed > .detailed-footer {
    padding: 1.6667vw 2.5vw;
    border-top: 0.0833vw solid var(--oliver-border);
  }

  .cm-detailed > .detailed-footer > button {
    height: 4.1667vw;
    margin-left: 2.5vw;
    border-radius: 0.5vw;
    font-size: 1.3333vw;
    line-height: 2vw;
  }

  .error-404-wrapper > .error-404-container {
    width: min(100%, 42.25vw);
    height: min(100%, 46.25vw);
    box-shadow: 0vw 0.8333vw 1.6667vw rgba(0, 0, 0, 0.05);
    border-radius: 0.8333vw;
    padding: 1.6667vw;
  }

  .error-404-wrapper > .error-404-container > img {
    width: 10.8333vw;
    height: 7.8333vw;
    margin: auto 0 2.9167vw 0;
  }

  .error-404-wrapper > .error-404-container > p.style1 {
    font-size: 3.3333vw;
    line-height: 4.4167vw;
    margin-bottom: 0.8333vw;
  }

  .error-404-wrapper > .error-404-container > p.style2 {
    font-size: 1.6667vw;
    line-height: 2.1667vw;
    margin-bottom: 0.8333vw;
  }

  .error-404-wrapper > .error-404-container > p.style3 {
    font-size: 1.6667vw;
    line-height: 2.1667vw;
    margin-bottom: 2.9167vw;
  }

  .error-404-wrapper > .error-404-container > button {
    width: 14.4167vw;
    height: 4.1667vw;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    line-height: 2vw;
    margin-bottom: 2.9167vw;
  }

  .error-404-wrapper > .error-404-container > .row > p {
    font-size: 1.3333vw;
    line-height: 1.75vw;
    margin-right: 0.3333vw;
  }

  .error-404-wrapper > .error-404-container > .row > a {
    font-size: 1.3333vw;
    line-height: 1.75vw;
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes animate-svg-fill-1 {
  0%,
  80%,
  100% {
    transform: scale(0.98);
    fill: transparent;
  }

  10%,
  70% {
    transform: scale(1);
    fill: #a1e663;
  }
}

@keyframes animate-svg-fill-2 {
  0%,
  80%,
  100% {
    transform: scale(0.98);
    fill: transparent;
  }

  10%,
  70% {
    transform: scale(1);
    fill: #47d2a5;
  }
}

@keyframes animate-svg-fill-3 {
  0%,
  80%,
  100% {
    transform: scale(0.98);
    fill: transparent;
  }

  10%,
  70% {
    transform: scale(1);
    fill: #1db9ca;
  }
}

@keyframes animate-svg-fill-4 {
  0%,
  80%,
  100% {
    transform: scale(0.98);
    fill: transparent;
  }

  10%,
  70% {
    transform: scale(1);
    fill: #2797e8;
  }
}

@keyframes animate-svg-fill-5 {
  0%,
  80%,
  100% {
    transform: scale(0.98);
    fill: transparent;
  }

  10%,
  70% {
    transform: scale(1);
    fill: #a568bc;
  }
}

@keyframes animate-svg-fill-6 {
  0%,
  80%,
  100% {
    transform: scale(0.98);
    fill: transparent;
  }

  10%,
  70% {
    transform: scale(1);
    fill: #ff525f;
  }
}

@keyframes animate-svg-fill-7 {
  0%,
  80%,
  100% {
    transform: scale(0.98);
    fill: transparent;
  }

  10%,
  70% {
    transform: scale(1);
    fill: #ff8552;
  }
}
